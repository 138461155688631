<template>
  <form @submit.prevent="validateMembershipData" method="post">
    <div
      class="accordion  membership-type"
      role="tablist"
      @change="memberSelected"
    >
      <div class="card-header register-title">
        <h3 class="mb-3">Membership Types</h3>
        <p class="font-13 mb-0 mb-pad-10 mt-2 text-justify" v-html="this.getSiteProps('memberRegistration.description')"></p>
      </div>
      <!-- HTML title specified via default slot -->
      <b-tooltip target="button-2" placement="bottom">
        Choose a membership that’s right for you. Refer to the
        <a
          href="http://www.usawaterski.org/graphics/downloads/MembershipOptions.pdf"
          target="_blank"
          >Membership Options (PDF)</a
        >
        for additional information on our membership type.
      </b-tooltip>
      
      <template v-for="(memberType, index) in propsMemberTypes">
      <b-card no-body class="mb-1" :key="'propType'+index" v-if="memberType.visible != 0">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'mType-' + index" variant="info">
              {{ memberType.name }}
            </b-button>
        </b-card-header>
        <b-collapse :id="'mType-' + index" :accordion="getSiteProps('general.all_tabs_collapsed') ?'mtype-accordion'+index:'mtype-accordion'" role="tabpanel" :visible="getSiteProps('general.all_tabs_collapsed')?false:openCollapse == index">
          <b-card-body>
            <b-card-text>
                <div v-if="memberType.shortDesc">
                    <p class="font-13 text-justify pl-3 pr-3" v-html="memberType.shortDesc"></p>
                </div>
                <div class="">
                    <ul class="mship-type-fld">
                        <li
                            class="member-type-width pl-3 pt-2 mr-0"
                            v-for="(membership, idx) in memberType.memberships"
                            :key="'membership' + membership.id"
                            :class="{ 'disabled' : regStatus == 0 || membership.disabled === 1 || membership.price === 0 || priceFetching }"
                            v-show="membership.visible!=0"
                        >
                            <input
                            @click="
                                collectMemberFee(
                                membership.price,
                                membership.id,
                                membership.type,
                                membership.agree,
                                membership.age,
                                memberType.id,
                                membership.player_price,
                                membership.coach_price,
                                membership.bg_check_price
                                )
                            "
                            type="radio"
                            :id="'cb' + membership.type.replace(' ', '_') + idx"
                            name="form.membership_id"
                            :value="membership.id"
                            :disabled="regStatus == 0 || membership.disabled === 1 || membership.price === 0 || priceFetching"
                            />
                            <label :for="'cb' + membership.type.replace(' ', '_') + idx">
                                <span class="chk-top-text pb-3" v-html="membership.type"></span>
                                <div class="pb-3" v-if="membership.icon">
                                    <img :src="membership.icon" :alt="'icon-'+membership.id" />
                                </div>
                                <template v-if="priceFetching"><i class="fa fa-circle-notch fa-spin"></i></template>
                                <template v-else>
                                  <span class="chk-bot-text pb-2">
                                    <template v-if="membership.price">${{ membership.price }}<span v-if="membership.coach_price != undefined">+ ${{ membership.coach_price }}</span></template>
                                    <template v-else>--</template>
                                  </span>
                                  <span class="chk-box-text pb-2">{{ membership.note }}</span>
                                </template>
                            </label>
                            <div
                                class="grossrootcheck"
                                v-if="membership.agree && form.agree"
                            >
                                <div class="form-group">
                                    <div class="form-check waiverdatepicker">
                                      <h6 v-html="membership.agree.headings"></h6>
                                        <ul class="grassrootul" v-if="membership.agree.info_list">
                                            <li v-for="(item, k) in membership.agree.info_list" :key="'memList-'+k">
                                            <span style="display: block;" class="font-13 align-items-baseline"
                                                ><i class="fas fa-chevron-right"></i><span style="display: contents;" v-html="item"></span></span
                                            >
                                            </li>
                                        </ul>
                                        <div class="width-values-400">
                                            <input
                                            type="checkbox"
                                            id="membership_sign_grossroot"
                                            class="form-check-input"
                                            name="form.membership_sign_grossroot"
                                            v-model="form.membership_agree"
                                            />
                                            <label
                                            for="membership_sign_grossroot"
                                            class="form-check-label font-13"
                                            v-html="membership.agree.check_label"
                                            >
                                            </label
                                            >
                                        </div>
                                    </div>
                                    <span class="text-sm text-danger">{{
                                    errors.membership_agree
                                    }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <span class="text-sm text-danger">{{ errors.membership }}</span>
                </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      </template>

      <div class="col-md-12 pl-4" v-if="this.getSiteProps('general.payment_fee_info')">
        <p class="mt-3 font-13">
          <i
            >All transactions also include a 3% processing/technology fee at
            checkout.</i
          >
        </p>
      </div>     
      <div class="col-md-12"  v-show="overAllError != 0">          
         <p class="text-right text-danger">Please fill the above missing fields.</p>         
      </div>
    </div>
    <div class="col-md-12" v-if="regStatus == 1">
      <div class="text-right topspace">
        <button
          type="submit"
          class="btn btn-outline-primary savebtn next-step-btn"
        >
        
          Next
          <i class="fas fa-chevron-right pl-2"></i>
        </button>
      </div>
    </div>

    <div></div>
  </form>
</template>
<script>
const validateCheckbox = (checkbox) => {
  console.log("resss=" + checkbox);
  if (!checkbox) {
    return { valid: false, error: "Please acknowledge by checking the box" };
  }
  return { valid: true, error: null };
};
const validateRadio = (radio) => {
  if (!radio) {
    return { valid: false, error: "Please select the member type" };
  }
  return { valid: true, error: null };
};
export default {
  name: "membershiptype",
  props: ["memberTypes", "propsMemberTypes", "priceFetching", "regStatus"],
  data() {
    return {
      selectedType: null,
      membership_id:'',
      form: {
        membership_id: "",
        member_type_id: "",
        member_type_fee: 0.0,
        member_type_text: "",
        membership_sign_grossroot: "",
        membership_sign_social: "",
        agree: false,
        membership_agree: "",
        age_criteria: ""
      },
      errors: {},      
      //text: '',
      overAllError: 0,
    };
  },
  methods: {
    memberSelected() {
      /*console.log("--meit");
          console.log(this.form);*/
    },

    collectMemberFee(fee, id, type, agree = null, age = null, member_type_id = "", player_fee = 0, coach_fee = 0, bg_check_fee = 0) {
      console.log(fee + "==" + id + "===+" + type);
      console.log("dararar",player_fee + "==" + coach_fee + "===+"+  bg_check_fee);
      this.form.member_type_text = type;
      this.form.member_type_fee = fee;
      this.form.player_fee = player_fee;
      this.form.coach_fee = coach_fee;
      this.form.bg_check_fee = bg_check_fee;
      this.form.membership_id = id;
      this.membership_id = id;
      console.log("0000000000000");
      console.log(this.form);

      this.form.agree = (agree != null && agree != undefined) ? true : false;

      this.form.membership_agree = 0;

      this.form.age_criteria = age != null && age != undefined ? age : "";

      this.form.member_type_id = member_type_id;
    },

    validateMembershipData() {
      // EVENT LOGIC: If component has no validation errors, then EMIT the EVENT
      if (this.validate()) {
        console.log("valid");
        this.$emit("member-type-selected", this.form);
        return;
      } else {
        console.log("un valid");
      }
    },
    previousStep() {
      this.$emit("previous-step");
      return;
    },
    // Custom Validation Rules
    validate() {
      this.errors = {};
      this.valid = false;
      this.overAllError = 1;
      this.form.membership_id = this.membership_id;
      const validMembershipId = validateRadio(this.form.membership_id);
      this.errors.membership = validMembershipId.error;
     

      const validMembershipSignGrossRoot = validateCheckbox(
        this.form.membership_sign_grossroot
      );
      const validMembershipSignSocial = validateCheckbox(
        this.form.membership_sign_social
      );
      if (this.form.membership_id == 3) {
        this.errors.membership_sign_grossroot =
          "Please acknowledge by checking the box";        
         
      }

      if (this.form.membership_id == 4) {
        this.errors.membership_sign_social =
          "Please acknowledge by checking the box";   
         
      }

      if (validMembershipId.valid) {
        // if (this.form.membership_id == 3) {
        //   if (validMembershipSignGrossRoot.valid) {
        //     this.valid = true;
        //   } else {
        //     this.valid = false;
        //   }
        // } else if (this.form.membership_id == 4) {
        //   if (validMembershipSignSocial.valid) {
        //     this.valid = true;
        //   } else {
        //     this.valid = false;
        //   }
        // } else {
        //   console.log("pass");
        //   this.valid = true;
        // }
        this.errors.membership_agree = "";
        if(this.form.agree){
          const validMembershipAgree = validateCheckbox(
            this.form.membership_agree
          );
         
          if(validMembershipAgree.valid){
          this.valid = true;
          this.overAllError = 0;    
          } 
          else {
            this.errors.membership_agree = "Please acknowledge by checking the box";
            this.valid = false;           
          }
        } else {          
          this.valid = true;          
           this.overAllError = 0;
        }
      }
      return this.valid;
    },
  },
  mounted(){
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      // console.log('collapseId:', collapseId)
      // console.log('isJustShown:', isJustShown)
      this.form.membership_agree = 0;
      this.form.membership_id = "";
      document.querySelector(".membership-type [name='form.membership_id']").checked = false;
    });
  },
  computed: {
    openCollapse(){
      var _index = -1;
      this.propsMemberTypes.forEach((item, index) => {
        if(item.visible != 0 && _index == -1) _index = index;
      });
      return _index;
    }
  }
};
</script>
