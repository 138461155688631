<template>
<div  class="main-footer">
    <div class="footer-inner">
        <div class="row footer-row">
            <div class="col-md-7 pl-3">
                <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">
                    <div v-if="subdomain != 'waterski'" class="">
                        <div class=" ">
                            <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                        </div>
                    </div>
                </div>
            <p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p>
            </div>
            <div class="col-md-5 pr-4">
                <p class="text-right mr-2">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
    name: "loggedindashboardFooter",
     data() {
    return {    
       prop_footer: {},
    };
  },
       updated(){
           
            this.prop_footer = this.getSiteProps('memberRegistration.footer');
        }
}

</script>
