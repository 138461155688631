<template>
<div>
<main class="main" role="main">
    <div class=" bg-light h-100">
        <div class="container">
            <div class="row">
                <div class="col-md-3 left_bg">
                    <banner></banner>
                </div>
                <div class="col-md-9 pl-0 right-wrapper">
                        <div class="border-radius-0 border-0" style="padding:280px 0; background: #F2F2F2;">
                            <div class="login-main-wrapper">
                                <div class="login-wrapper">
                                    <div class="card-header">
                                        <h3>Forgot Password</h3>
                                    </div>
                                    <div class="card card-body">
                                        <form>
                                            <div class="form-group">
                                                <label>Email</label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-append">
                                                            <div class="input-group-text">
                                                                <img src="/dist/img/e-mail.png" alt="email">                                                              
                                                            </div>
                                                        </div>
                                                        <input type="email" placeholder=""  name="email" class="form-control">                                             
                                                    </div>
                                                    <p class="font-12"><i>Quisque non turpis venenatis, convallis nulla et, eleifend nisi. Nam dapibus, risus in tristique interdum, ex ante tincidunt purus, vitae aliquet sapien ipsum et magna.</i></p>
                                            </div>
                                         
                                            <div class="form-button">
                                                <b-button class="btn-primary btn-blue">Send Email</b-button>                                           
                                            </div>                                            
                                        </form>   
                                    </div>
                                </div>                              
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</template>
<script>
export default {
    name: "memberregistraionforgotpw",
}
</script>