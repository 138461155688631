<template>
    <form @submit.prevent="personalData" method="post">
    <div class=" personal-type">
        <div class="card-header register-title">
            <!-- <h3 v-if="membership.id == 5">Head of Household Information</h3>
            <h3 v-else>Personal Information</h3> -->          
            <h3>{{ fields.tab_title || 'Personal Information' }}</h3>
        </div>

        <div class="card-body pr-4 pl-4">
            <div class="row mb-2" v-if="fields.sub_title">
                <div class="col-md-12 text-center">
                    <h5 class="tab_subtitle">{{ fields.sub_title || '' }}</h5>
                </div>
            </div>
            <div class="row">
                <div :class="[fields.title.colClass]" v-if="fields.title">
                    <div class="form-group">
                        <label for="title" class="form-label">{{ fields.title.label }}<sup class="text-danger" v-if="fields.title.required">*</sup></label>
                        <!-- <select name="form.title" id="title" class="form-control" v-model="form.title">
                            <option v-for="(item, k) in fields.title.options" :value="item" :key="'title-'+k">{{ item }}</option>
                        </select> -->
                        <input type="text" class="form-control" :placeholder="fields.title.placeholder" id="title" v-model="form.title" name="form.title" value="">
                        <span class="text-sm text-danger">{{ errors.title}}</span>
                    </div>
                </div>
                <div :class="[fields.firstname.colClass]" v-if="fields.firstname">
                    <div class="form-group">
                        <label for="firstName" class="form-label">
                            {{ fields.firstname.label }}<sup class="text-danger" v-if="fields.firstname.required">*</sup>
                        </label>
                        <input type="text" class="form-control" :placeholder="fields.firstname.placeholder" maxlength="45" id="firstName" v-model="form.first_name" name="form.first_name" value="">
                        <span class="text-sm text-danger">{{ errors.first_name}}</span>
                        <span class="text-sm text-danger" v-if="duplicateError.hasError">{{ duplicateError.errorMessage}}</span>
                      <a href="/member/login" v-if="duplicateError.showLink" target="_blank"> Click here</a>
                    </div>
                </div>
                <div :class="[fields.middlename.colClass]" v-if="fields.middlename">
                    <div class="form-group">
                        <label for="middlename" class="form-label">
                            {{ fields.middlename.label }}<sup class="text-danger" v-if="fields.middlename.required">*</sup>
                        </label>
                        <input type="text" class="form-control" :placeholder="fields.middlename.placeholder" maxlength="3" id="middlename" v-model="form.middle_name" name="form.middle_name" value="">
                    </div>
                  <span class="text-sm text-danger">{{ errors.middle_name}}</span>
                </div>
                <div :class="[fields.lastname.colClass]" v-if="fields.lastname">
                    <div class="form-group">
                        <label for="lastname" class="form-label">{{ fields.lastname.label }}<sup class="text-danger" v-if="fields.lastname.required">*</sup></label>
                        <input type="text" class="form-control" :placeholder="fields.lastname.placeholder" maxlength="45" id="lastname" v-model="form.last_name" name="form.last_name" value="">
                        <span class="text-sm text-danger">{{ errors.last_name}}</span>
                    </div>
                </div>
                <div :class="[fields.suffix.colClass]" v-if="fields.suffix">
                    <div class="form-group">
                        <label for="suffix" class="form-label">{{ fields.suffix.label }}<sup class="text-danger" v-if="fields.suffix.required">*</sup></label>
                        <input type="text" class="form-control" :placeholder="fields.suffix.placeholder" maxlength="45" id="suffix" name="suffix" value="" v-model="form.suffix">
                    </div>
                  <span class="text-sm text-danger">{{ errors.suffix}}</span>
                </div>

                <div :class="[fields.clubandteam.colClass]" v-if="fields.clubandteam">
                    <div class="form-group">
                        <label for="clubandteam" class="form-label">{{ fields.clubandteam.label }}<sup class="text-danger" v-if="fields.clubandteam.required">*</sup></label>
                        <Select2 v-model="form.club_affiliate" :options="options" :placeholder="fields.clubandteam.placeholder" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                        <p class="mt-2 font-13" v-if="fields.clubandteam.description"><i v-html="fields.clubandteam.description"></i></p>
                    </div>
                </div>

                <div :class="[fields.birthday.colClass]" v-if="fields.birthday">
                    <div class="form-group">
                        <label for="birthday" class="form-label">{{ fields.birthday.label }} <sup class="text-danger" v-if="fields.birthday.required">*</sup></label>
                        <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                    <date-pick
                                    v-model="form.dob"
                                        :pickTime="false"
                                        name="form.dob"
                                        :format="'MM/DD/YYYY'"
                                        :selectableYearRange="{from: 1900, to: 2021}"
                                        v-mask="'##/##/####'"
                                        :isDateDisabled="isFutureDate"
                                       :inputAttributes="{placeholder: 'mm/dd/yyyy'}"
                                        @input="getDateValue()"
                                        :parseDate="parseDatePick"
                                    ></date-pick>
                                </div>
                            <span class="text-sm text-danger">{{ errors.dob}}</span></div>
                    </div>
                </div>

                <div :class="[fields.gender.colClass]" v-if="fields.gender">
                    <div class="form-group">
                        <label for="gender" class="form-label">{{ fields.gender.label }} <sup class="text-danger" v-if="fields.gender.required">*</sup></label>
                        <div class="form-group" v-if="fields.gender.choices">
                            <template v-for="(choice, key) in fields.gender.choices">
                                <input type="radio" :id="choice.value" v-model="form.gender" name="form.gender" :value="choice.label" :key="'radio_' + key" @click="messageFor(choice.value)">
                                <label class="gender-label-span" :for="choice.value" :key="'gender_label_'+key">{{ choice.label }}</label>
                                <br :key="'gender_br_'+key">
                            </template>
                            <span class="text-sm text-danger">{{ errors.gender}}</span>
                        </div>
                    </div>
                </div>

                <div :class="[fields.veteran_status.colClass]" v-if="fields.veteran_status">
                    <div class="form-group">
                        <label for="veteran_status" class="form-label">{{ fields.veteran_status.label }} <sup class="text-danger" v-if="fields.veteran_status.required">*</sup></label>
                        <div class="form-group" v-if="fields.veteran_status.choices">
                            <template v-for="(choice, key) in fields.veteran_status.choices">
                                <input type="radio" :id="choice.value" v-model="form.veteran_status" name="form.veteran_status" :value="choice.label" :key="'radio_' + key">
                                <label class="veteran_status-label-span" :for="choice.value" :key="'veteran_status_label_'+key">{{ choice.label }}</label>
                                <br :key="'veteran_status_br_'+key">
                            </template>
                            <span class="text-sm text-danger">{{ errors.veteran_status}}</span>
                        </div>
                    </div>
                </div>
              <div :class="[fields.race.colClass]" v-if="fields.race">
                <div class="form-group radiotext">
                  <label for="race" class="form-label-check"><strong>{{ fields.race.label }}</strong> <sup class="text-danger" v-if="fields.race.required">*</sup></label> <br>
                  <div v-for="diversity in diversities" :key="diversity.name">
                    <label :for="'diversity_id'+diversity.id">
                      <input type="radio" name="form.diversity_id" :value="diversity.id"
                             :id="'diversity_id'+diversity.id"
                             v-model="form.diversity_id">
                      {{diversity.name}}</label> <br>
                  </div>
                  <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                </div>
              </div>
                <div :class="[fields.clubslist.colClass]" v-if="fields.clubslist">
                    <div class="form-group">
                        <label for="clubslist" class="form-label">{{ fields.clubslist.label }}<sup class="text-danger" v-if="fields.clubslist.required">*</sup></label>
                        <Select2 v-model="form.club" :options="options" name="form.club" :settings="{ placeholder: fields.clubslist.placeholder}" />
                        <p class="mt-2 font-13" v-if="fields.clubslist.description"><i v-html="fields.clubslist.description"></i></p>
                    </div>
                </div>
                <div :class="[fields.new_club_name.colClass]" v-if="fields.new_club_name && form.club == '-'">
                    <div class="form-group">
                        <label for="new_club_name" class="form-label">{{ fields.new_club_name.label }}<sup class="text-danger" v-if="fields.new_club_name.required">*</sup></label>
                        <input type="text" v-model="form.new_club_name" class="form-control" id="new_club_name" name="new_club_name" placeholder="" value="">
                        <span class="text-sm text-danger">{{ errors.new_club_name }}</span>
                    </div>
                </div>
                <div :class="[fields.new_club_email.colClass]" v-if="fields.new_club_email && form.club == '-'">
                    <div class="form-group">
                        <label for="new_club_email" class="form-label">{{ fields.new_club_email.label }}<sup class="text-danger" v-if="fields.new_club_email.required">*</sup></label>
                        <input type="text" v-model="form.new_club_email" class="form-control" id="new_club_email" name="new_club_email" placeholder="">
                        <span class="text-sm text-danger">{{ errors.new_club_email }}</span>
                    </div>
                </div>
                <div :class="[fields.new_club_phone.colClass]" v-if="fields.new_club_phone && form.club == '-'">
                    <div class="form-group">
                        <label for="new_club_phone" class="form-label">{{ fields.new_club_phone.label }}<sup class="text-danger" v-if="fields.new_club_phone.required">*</sup></label>
                        <vue-tel-input v-model="form.new_club_phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.new_club_phone }}</span>
                    </div>
                </div>

             <!-- <div class="col-md-12">
                    <div class="form-group">
                        <label for="citizenship" class="form-label">Citizenship <sup class="text-danger">*</sup></label>
                        <select class="form-control" id="country" v-model="form.citizenship" name="form.citizenship">
                            <option value="">Select</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.citizenship}}</span>
                    </div>
                </div> -->

              <div :class="[fields.agl_player_id.colClass]" v-if="isChild && fields.agl_player_id">
                <div class="form-group">
                  <label for="agl_player_id" class="form-label">
                    {{ fields.agl_player_id.label }}<sup class="text-danger" v-if="fields.agl_player_id.required">*</sup>
                  </label>
                  <input type="text" class="form-control" :placeholder="isChild && fields.agl_player_id.placeholder" maxlength="45" id="agl_player_id" v-model="form.agl_player_id" name="form.agl_player_id" value="">
                  <span class="text-sm text-danger">{{ errors.agl_player_id}}</span>
                </div>
              </div>
              <div :class="[fields.team_membership_id.colClass]" v-if="fields.team_membership_id">
                <div class="form-group">
                  <label for="team_membership_id" class="form-label">{{ fields.team_membership_id.label }}<sup class="text-danger" v-if="fields.team_membership_id.required">*</sup></label>
                  <Select2 v-model="form.club" v-if="membership.id !=5" :options="teamOptions" name="form.club" :settings="{ placeholder: 'Select a team'}" />
                  <Select2 v-model="form.club" v-if="membership.id ==5" :options="teamsList" name="form.club" :settings="{ placeholder: 'Select a team'}" />
                  <span class="text-sm text-danger">{{ errors.team}}</span>
                </div>
              </div>
              <div :class="[fields.graduation_year.colClass]" v-if="isChild && fields.graduation_year">
                <div class="form-group">
                  <label for="graduation_year" class="form-label">
                    {{ fields.graduation_year.label }}<sup class="text-danger" v-if="fields.graduation_year.required">*</sup>
                  </label>
                  <select class="form-select form-control" id="graduation_year" name="form.graduation_year" v-model="form.graduation_year">
                    <option value="">Select Graduation Year</option>
                    <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.graduation_year}}</span>
                </div>
              </div>
              <div :class="[fields.jersey_number.colClass]" v-if="isChild && fields.jersey_number">
                <div class="form-group">
                  <label for="jersey_number" class="form-label">
                    {{ fields.jersey_number.label }}<sup class="text-danger" v-if="fields.jersey_number.required">*</sup>
                  </label>
                  <input class="form-control" :placeholder="fields.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="form.jersey_number" name="form.jersey_number" value="">
                  <span class="text-sm text-danger">{{ errors.jersey_number}}</span>
                </div>
              </div>
              <div :class="[fields.primary_position.colClass]" v-if="isChild && fields.primary_position">
                <div class="form-group">
                  <label for="primary_position" class="form-label">{{ fields.primary_position.label }} <sup class="text-danger" v-if="fields.primary_position.required">*</sup></label>
                  <select class="form-control" id="primary_position" v-model="form.primary_position" name="form.primary_position">
                    <option value="">Select</option>
                    <option  v-for="(listOfPrimaryPosition,key) in fields.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.primary_position}}</span>
                </div>
              </div>
              <div :class="[fields.secondary_position.colClass]" v-if="isChild && fields.secondary_position">
                <div class="form-group">
                  <label for="secondary_position" class="form-label">{{ fields.secondary_position.label }} <sup class="text-danger" v-if="fields.secondary_position.required">*</sup></label>
                  <select class="form-control" id="secondary_position" v-model="form.secondary_position" name="form.secondary_position">
                    <option value="">Select</option>
                    <option  v-for="(listOfSecondaryPosition,key) in fields.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.secondary_position}}</span>
                </div>
              </div>
              <div :class="[fields.throw.colClass]" v-if="isChild">
                <div class="form-group">
                  <label for="throws" class="form-label">{{ fields.throw.label }} <sup class="text-danger" v-if="fields.throw.required">*</sup></label>
                  <div class="form-group" v-if="fields.throw.choices">
                    <template v-for="(choice, key) in fields.throw.choices">
                      <input type="radio" :id="choice.value" v-model="form.throws" name="form.throw" :value="choice.value" :key="'radio_' + key">
                      <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                    </template>
                    <span class="text-sm text-danger">{{ errors.throws}}</span>
                  </div>
                </div>
              </div>
              <div :class="[fields.bats.colClass]" v-if="isChild">
                <div class="form-group" v-if="isChild">
                  <label for="bats" class="form-label">{{ fields.bats.label }} <sup class="text-danger" v-if="fields.bats.required">*</sup></label>
                  <div class="form-group" v-if="fields.bats.choices">
                    <template v-for="(choice, key) in fields.bats.choices">
                      <input type="radio" :id="choice.value" v-model="form.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                      <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                    </template>
                    <span class="text-sm text-danger">{{ errors.bats}}</span>
                  </div>
                </div>
              </div>
              <div :class="[fields.college_committed.colClass]" v-if="isChild && fields.college_committed">
                <div class="form-group">
                  <label for="college_committed" class="form-label">{{ fields.college_committed.label }} <sup class="text-danger" v-if="fields.college_committed.required">*</sup></label>
                  <div class="form-group" v-if="fields.college_committed.choices">
                    <template v-for="(choice, key) in fields.college_committed.choices">
                      <input type="radio" :id="choice.value" v-model="form.college_list" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                      <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                    </template>
                    <span class="text-sm text-danger">{{ errors.college_committed}}</span>
                  </div>
                </div>
              </div>
              <div :class="[fields.college_committed.yes_fields.college_list.colClass]" v-if="isChild && fields.college_committed && fields.college_committed.yes_fields.college_list && collegeCommitted == 1">
                <div class="form-group">
                  <label for="college_list" class="form-label" v-if="fields.college_committed.yes_fields.college_list.label !=''">{{ fields.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="fields.college_committed.yes_fields.college_list.required">*</sup></label>
                  <Select2 id="college_list" v-model="form.college_committed" :options="collegeOptions" name="form.college_list" :settings="{ placeholder: 'Click to Select the College'}" />
                </div>
              </div>

              <span v-if="fields.member_optional_text" :class="[fields.member_optional_text.colClass]" :style="fields.member_optional_text.style">{{ fields.member_optional_text.label }}</span>
              <div v-if="fields.member_id" :class="[fields.member_id.colClass]">
                <label for="member_id" class="form-label">
                  {{ fields.member_id.label }}<sup class="text-danger" v-if="fields.member_id.required">*</sup>
                </label>
                <div class="form-group input-group input-group">
                  <input type="text" class="form-control" :placeholder="fields.member_id.placeholder" maxlength="45" id="member_id" v-model="memberLookupForm.member_id" name="form.member_id" value="">
                  <span class="input-group-append">
                    <button type="button"  @click="memberSearch();" class="btn btn-outline-warning btn-flat"><i class="fas fa-search"></i>{{button.SearchButton}}</button>
                  </span>
                </div>
                <span class="text-sm text-danger">{{ errors.member_id}}</span>
              </div>
              <div v-if="fields.member_name" :class="[fields.member_name.colClass]">
                <div class="form-group">
                  <label for="member_name" class="form-label">
                    {{ fields.member_name.label }}<sup class="text-danger" v-if="fields.member_name.required">*</sup>
                  </label>
                  <input type="text" class="form-control" :placeholder="fields.member_name.placeholder" maxlength="45" id="member_name" v-model="memberLookupForm.member_name" name="form.member_id" value="">
                  <span class="text-sm text-danger">{{ errors.member_name}}</span>
                </div>
              </div>
            </div>
        </div>
    </div>
      <div class="col-md-12" v-show="overAllError != 0">
         <p class="text-danger text-right">Please fill the above missing fields.</p>
      </div>

    <div class="col-md-12">
        <div class="text-right topspace">
            <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
            <button type="submit" class="btn btn-outline-primary savebtn next-step-btn" :disabled="checkingDuplicate">
                <span v-if="checkingDuplicate"><i class="fa fa-circle-notch fa-spin"></i></span>
                <span v-else>Next <i class="fas fa-chevron-right pl-2"></i></span>
            </button>
        </div>
    </div>
    </form>
</template>
<script>
// import {TheMask} from 'vue-the-mask'
import DatePick from 'vue-date-pick';
// import datetime from 'vuejs-datetimepicker';
import Select2 from 'v-select2-component';
import axios from 'axios';
import moment from 'moment';

const validateTitle = (title,fieldName) => {
    if (!title.length) {
        return { valid: false, error: "Title is required" };
    } 
    return { valid: true, error: null };
};

const validateName = (name, fieldName = '') => {
    let names = name.trim();
    let fieldText = ( fieldName != '' ? fieldName : "This field");

    if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
    }
    if (!names.match(/^[a-zA-Z\s]*$/))
    {
        return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
    }
    return { valid: true, error: null };    
};
const validateLastName = (name, fieldName = '') => {
  let names = name.trim();
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};
const validateOptionalTextFields = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
  const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
}


if(validTelinput == false && phone.length){
  return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
}

    return { valid: true, error: null };
}

    const validateDob = (dob, fieldDob = '',isAlliance,isAthlete,age) => {
        let fieldDobText = (fieldDob != '' ? fieldDob : "This field");
        if (!dob.length) {
            return { valid: false, error: fieldDobText+" is required" };
        }
        /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
        return { valid: false, error: "Please, enter a valid data of birth." };
        }*/
        return { valid: true, error: null };
    };
    const validateGender = (gender,fieldGender = '') => {
    let genderText = ( fieldGender != '' ? fieldGender : "This field");
    if (!gender) {
    return { valid: false, error: genderText+" is required" };
}
    return { valid: true, error: null };
};
    const validateSelect = (select,fieldTxt) => {
    if (!select.length) {
    return { valid: false, error: fieldTxt + "is required" };
}
    return { valid: true, error: null };
};
    const validateEmail = (email, fieldEmail='') => {
    let emailText= (fieldEmail != '' ? fieldEmail : "This field");
    if (!email.length) {
    return { valid: false, error: emailText+" is required" };
}
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return { valid: false, error: "Please enter a valid email." };
}
    return { valid: true, error: null };
};
    const validatePassword = password => {
    if (!password.length) {
    return { valid: false, error: "This field is required" };
}
    if (password.length < 7) {
    return { valid: false, error: "Password is too short" };
}
    return { valid: true, error: null };
};
const validateRadio =(radio, fieldTxt)  => {
  if (!radio) {
    return { valid: false, error: fieldTxt +' is required'};
  }
  return { valid: true, error: null };
};
const validateJerseyNumber = (number,fieldTxt) => {
  if(!number){
    return {valid: false,error:fieldTxt +" is required"};
  }
  if(!number.match(/^[0-9]+$/)){
    return { valid: false, error: "Please enter a valid Jersey Number." };
  }
  return {valid: true, error: null};
};

export default {

    name: "personalinformation",
    props: ['membership', 'fields', 'duplicateError', 'checkingDuplicate', 'isChild', 'isChildUsers','isTeamRegistration', 'isValidateMemberType','updatedVerifiedDetails','invitedTeam','isAthlete','currentSeason','diversities'],
    components: {DatePick, Select2 },
    data() {
        return {
             overAllError:0,   
            // date: '12-01-2000',
            validTelinput:false,
            mobileProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Mobile Number",
                // maxLen: 30,
            },
            form: {
                title: 'Mr.', 
                first_name:'',
                middle_name:'',
                last_name:'',
                suffix: '',
                gender:'',
                dob:'',
                relation:0,
                citizenship:'',
                affiliate:'',
                veteran_status: '',
                club: '',
                new_club_name: '',
                new_club_email: '',
                new_club_phone: '',
                club_affiliate: '',
                agl_player_id: '',
                jersey_number: '',
                primary_position: '',
                secondary_position: '',
                graduation_year: '',
                bats: '',
                throws: '',
                college_list:'',
                college_committed:''
            },
            yearArr: [],
            valid: false,
            success: false,
            errors: {},            
            message: null,
            options: [],
            teamOptions: [],
            teamList:[],
            teamsList:[],
            collegeCommitted: null,
            disableTeam:false,
            memberLookupForm:{
              member_id:'',
              member_name:''
            },
            memberSerchResultStatus:false,
            memberSearchResults:[],
            memberUpdatedLookup:{
               form:'',
               lookup:''
            },
            button:{
              SearchButton: 'Search'
            },
        }
    },
    methods: {
        setClub(value){
            this.form.affiliate = value;
        },
        personalData() {
            console.log('validate');
console.log("affiliate:"+this.form.club_affiliate);
            // EVENT LOGIC: If component has no validation errors, then EMIT the EVENT
            if (this.validate()) {
              console.log('valid');
              if(!this.memberSerchResultStatus) {
                this.$emit('personal-data', this.form);
              }else{
                this.editPersonalDetails();
                this.$emit('personal-data', this.memberUpdatedLookup);
              }
              return;
            } else {
              console.log('un valid');
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        // Custom Validation Rules
        validate() {
            this.errors = {}
            this.valid = false;
            this.overAllError = 1;
            var validTitle = {valid:true}, validMiddleName ={valid:true}, validSuffix ={valid:true},validPrimaryPosition = {valid:true}, validThrow = {valid:true}, validBats = {valid:true}, validGender = {valid:true}, validJerseyNumber = {valid:true}, validGraduationYear = {valid:true}, validTeam = {valid:true};
            // const validTitle = validateTitle(this.form.title, "Title");
            // this.errors.title = validTitle.error;


            const validFirstName = validateName(this.form.first_name, "First name");
            this.errors.first_name = validFirstName.error;

            validMiddleName = validateOptionalTextFields(this.form.middle_name, "Middle name");
            this.errors.middle_name = validMiddleName.error;
            if(!this.isTeamRegistration || !this.isChild) {
              validSuffix = validateOptionalTextFields(this.form.suffix, "Suffix");
              this.errors.suffix = validSuffix.error;
            }

            validGender = validateGender(this.form.gender, "Gender");
            this.errors.gender = validGender.error;

            const validLastName = validateLastName(this.form.last_name, "Last name" );
            this.errors.last_name = validLastName.error;
            const age = this.getAge(this.form.dob);
            const validDob = validateDob(this.form.dob,"Birth Date",this.isTeamRegistration,this.isAthlete,age);
          const validDiversity = validateRadio(this.form.diversity_id,"Ethnicity");
          this.errors.diversity_id = validDiversity.error;
            this.errors.dob = validDob.error;
            if(this.isChild) {
              validPrimaryPosition = validateSelect(this.form.primary_position, "Primary Position ");
              this.errors.primary_position = validPrimaryPosition.error;

              validThrow = validateRadio(this.form.throws.toString(),"Throws");
              this.errors.throws = validThrow.error;

              validBats = validateRadio(this.form.bats.toString(),"Bats");
              this.errors.bats = validBats.error;


              validJerseyNumber = validateJerseyNumber(this.form.jersey_number,"Jersey Number ");
              this.errors.jersey_number = validJerseyNumber.error;

              validGraduationYear = validateSelect(this.form.graduation_year.toString(),"High School Graduation Year ");
              this.errors.graduation_year = validGraduationYear.error;

              validTeam = validateSelect(this.form.club.toString(),"Team ");
              this.errors.team = validTeam.error;
            }
            if(this.isTeamRegistration && this.membership.id ==5){
              validTeam = validateSelect(this.form.club.toString(),"Team ");
              this.errors.team = validTeam.error;
            }

          if (!this.isTeamRegistration && validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid  && validTitle.valid && validMiddleName.valid && validSuffix.valid && (validDiversity.valid || ![11,12,13].includes(this.membership.id)) ||
              (this.isChild && validFirstName.valid && validLastName.valid && validPrimaryPosition.valid && validDob.valid
                  && validThrow.valid && validBats.valid && validJerseyNumber.valid && validGraduationYear.valid && validMiddleName.valid && validSuffix.valid && validTeam.valid) ||
              (this.isTeamRegistration && this.membership.id ==5 && validFirstName.valid && validLastName.valid && validSuffix.valid && validGender.valid && validDob.valid && validTeam.valid&& validMiddleName.valid) ||
              (this.isTeamRegistration && this.membership.id !=5 && validFirstName.valid && validLastName.valid && validSuffix.valid && validGender.valid && validDob.valid&& validMiddleName.valid) ) {
            var dob = '';
            if(this.isTeamRegistration && this.isChild){
              dob = this.isUnderAgeDivision(this.form.dob,this.membership.age_criteria.value);
            }else{
              dob = this.getAge(this.form.dob);
            }
            console.log(this.membership.id);
            console.log('dob='+dob);

            // if(this.subdomain == "waterski"){
            //     if(this.membership.id == 1 && dob < 25) {
            //         console.log('dob1='+dob);
            //         this.valid = false;
            //         this.errors.dob = 'Individual Active user age should be age 25 or older. Go back and change membership type to U25 Active membership.';
            //     } else if(this.membership.id == 2 && dob >= 25) {
            //         console.log('dob1='+dob);
            //         this.valid = false;
            //         this.errors.dob = 'Individual Under 25 Active user age should be less than 25';
            //     } else if(this.membership.id == 5 && dob < 17) {
            //         console.log('dob3='+dob);
            //         this.valid = false;
            //         this.errors.dob = 'Family Head of Household must be age 18 or above. Please change Head of Household to an adult member of the family or go back and choose another membership type.';
            //     } else {
            //         console.log('dob2='+dob);
            //         this.errors.dob = '';
            //         this.valid = true;
            //     }
            // } else {
            //     this.valid = true;
            // }

            if(!this.validateDate(this.form.dob)){
              this.valid = false;
              this.errors.dob = 'Invalid Date';
            } else {
              this.errors.dob = '';
              if(this.membership.age_criteria){
                if(this.membership.age_criteria.compare=="above" && dob < this.membership.age_criteria.value) {
                  this.valid = false;
                  this.errors.dob = this.membership.age_criteria.error_msg;
                } else if(this.membership.age_criteria.compare=="below" && dob > this.membership.age_criteria.value) {
                  this.valid = false;
                  this.errors.dob = this.membership.age_criteria.error_msg;
                        } else if(!Object.is(Math.abs(dob), +dob)) {
                          this.valid = false;
                          this.errors.dob = 'Invalid Date';
                } else {
                  this.errors.dob = '';
                  this.valid = true;
                  this.overAllError = 0;
                }
              } else {
                this.valid = true;
                this.overAllError = 0;
              }
            }
          }
          // const validSelect = validateSelect(this.form.citizenship);
            // this.errors.citizenship = validSelect.error;

          if(this.isTeamRegistration){
            if(this.membership.age_criteria){
              dob = this.isUnderAgeDivision(this.form.dob,this.membership.age_criteria.value);
              if(this.membership.age_criteria.compare=="above" && dob < this.membership.age_criteria.value) {
                this.valid = false;
                this.errors.dob = this.membership.age_criteria.error_msg;
              } else if(this.membership.age_criteria.compare=="below" && dob > this.membership.age_criteria.value) {
                this.valid = false;
                this.errors.dob = this.membership.age_criteria.error_msg;
              }
            }

          }
            if(document.querySelector(".personal-type [name='form.veteran_status']") != null){
                const validMilitary = validateRadio(this.form.veteran_status, "Military Status");
                this.errors.veteran_status = validMilitary.error;

                if(!validMilitary.valid){
                this.valid = false;
                this.overAllError = 1;
                }
            }

            // if(document.querySelector(".personal-type [name='new_club_name']") != null){
            //     const validNewClubName = validateName(this.form.new_club_name);
            //     this.errors.new_club_name = validNewClubName.error;

            //     if(!validNewClubName.valid) this.valid = false;
            // }
            // if(document.querySelector(".personal-type [name='new_club_email']") != null){
            //     const validNewClubEmail = validateEmail(this.form.new_club_email);
            //     this.errors.new_club_email = validNewClubEmail.error;

            //     if(!validNewClubEmail.valid) this.valid = false;
            // }
            // if(document.querySelector(".personal-type [name='telephone']") != null){
            //     const validNewClubPhone = validatePhone(this.form.new_club_phone, this.validTelinput);
            //     this.errors.new_club_phone = validNewClubPhone.error;

            //     if(!validNewClubPhone.valid) this.valid = false;
            // }

            return this.valid;
        },
        getAge(birth_date) {
            let birth_date_arr = birth_date.split('/');
            console.log(birth_date_arr);
            let today_date = new Date();
            let today_year = today_date.getFullYear();
            let today_month = today_date.getMonth();
            let today_day = today_date.getDate();
            let age = today_year - birth_date_arr[2];

            if ( today_month < (birth_date_arr[0] - 1))
            {
                age--;
            }
            if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1]))
            {
                age--;
            }
            return age;
        },
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },
        getClubs(){
            axios.get(this.basePath + "api/get_teams")
                .then((response) => {
                    var clubs = response.data.data;
                    this.options = clubs;
                })
                .catch(error=>{
                    console.log("error geting clubs list")
                })
        },
        validateTelinput(obj){
            this.validTelinput = obj.valid;
            if(obj.valid){
                this.errors.new_club_phone = '';
            }
        },
        isCheckChildUser(){
          if(this.getAge(this.form.dob) < 18 && this.isChild){
            this.$emit('isChild', true);
            this.$emit('isChildUsers', true);
          }else{
            this.$emit('isChild', false);
            this.$emit('isChildUsers', false);
          }
        },
        isUnderAgeDivision(birth_date,valid_age){
          var today = new Date(this.currentSeason.start_date);
          var birthDate = new Date(birth_date);
          var age = today.getFullYear() - birthDate.getFullYear();
          return age;
        },
        getDateValue(){
          var isCheckAge = true;
          if(this.isChild && this.isTeamRegistration && this.form.dob != ''){
            let age = this.isUnderAgeDivision(this.form.dob);
            if(this.membership.age_criteria){
              if(this.membership.age_criteria.compare=="above" && age < this.membership.age_criteria.value) {
                this.valid = false;
                this.errors.dob = this.membership.age_criteria.error_msg;
              } else if(this.membership.age_criteria.compare=="below" && age > this.membership.age_criteria.value) {
                this.valid = false;
                this.errors.dob = this.membership.age_criteria.error_msg;
                isCheckAge =false;
              } else {
                this.errors.dob = '';
                this.valid = true;
                this.overAllError = 0;
              }
            } else {
              this.valid = true;
              this.overAllError = 0;
            }
            let teamOptions=[];
            let teamOptionsList=[];
            if(isCheckAge) {
              this.teamList.forEach((ageDivisionList, index) => {
                if (this.isAthlete == 2 && ageDivisionList.age != '' && (ageDivisionList.age >= age && ageDivisionList.age <= 12)) {
                  teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
                    if (ageDivisionList.value[field].text != null) {
                      return {
                        id: ageDivisionList.value[field].id,
                        // text: ageDivisionList.age + "U - " +ageDivisionList.value[field].text
                        text: ageDivisionList.value[field].text
                      }
                    }
                  });
                  teamOptionsList.push(teamOptions);
                }
                if (this.isAthlete == 1 && ageDivisionList.age != '' && (ageDivisionList.age >= age && ageDivisionList.age > 12)) {
                  teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
                    if (ageDivisionList.value[field].text != null) {
                      return {
                        id: ageDivisionList.value[field].id,
                        // text: ageDivisionList.age + "U - " +ageDivisionList.value[field].text
                        text: ageDivisionList.value[field].text
                      }
                    }
                  });
                  teamOptionsList.push(teamOptions);
                }
              });
              this.teamOptions = teamOptionsList.flat();
              this.teamOptions = this.teamOptions.filter(function (element) {
                return element !== undefined;
              });
            }
            console.log("data updated")
          }
        },
        getTeamsList(){
          axios.get(this.basePath + "api/get_teams_division?membership_id="+this.membership?.id)
              .then((response) => {
                let teams = response.data.data;
                let teamKey = Object.keys(teams);
                if(teamKey.length){
                  this.teamList = Object.keys(teams).map((field) => {
                    return {
                      age: field.slice(0, -1),
                      value: teams[field]
                    }
                  })
                }
              })
              .catch(error=>{
                console.log("error geting clubs list")
              })
        },
        getAllTeamsList(){
          console.log("data team");
          axios.get(this.basePath + "api/get_all_teams_list")
              .then((response) => {
                this.teamsList = response.data.data;
                  console.log("team views of data", this.teamOptions);
              })
              .catch(error=>{
                console.log("error geting clubs list")
              })
        },
        resetFields(){
          this.errors = {};
          this.form = {
          title: 'Mr.',
          first_name:'',
          middle_name:'',
          last_name:'',
          suffix: '',
          gender:'',
          dob:'',
          relation:0,
          citizenship:'',
          affiliate:'',
          veteran_status: '',
          club: '',
          new_club_name: '',
          new_club_email: '',
          new_club_phone: '',
          club_affiliate: '',
          agl_player_id: '',
          jersey_number: '',
          primary_position: '',
          secondary_position: '',
          graduation_year: '',
          bats: '',
          throws: '',
          college_list:'',
          college_committed:''         
        }
          this.overAllError = 0;
      },
      
        messageFor(val){
          this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
        },
        memberSearch: function () {
        /*if(!this.validate()) {
          return;
        }*/
          if(this.memberLookupForm.member_id == ""){
            this.errors= {};
              this.errors.member_id = 'Member ID is required for search';
              return false;
          }
        console.log(this.memberLookupForm);
        this.button.SearchButton = 'Searching...';
        this.checkingDuplicate = true;
        this.errors= {};
        axios.get(this.basePath+'api/member_lookup?member_id='+this.memberLookupForm.member_id)
            .then((res) => {
              console.log('status='+res.data.status);
              if(res.data.status == 'success') {
                this.button.SearchButton = 'Search';
                this.memberSerchResultStatus = true;
                this.checkingDuplicate = false;
                console.log(res.data.data);
                console.log('-------------');
                this.memberSearchResults = res.data.data;
                console.log('-------1------');
                console.log(this.memberSearchResults);
                this.lookUpUpdateData();
                if(this.memberSearchResults[0].membership.id !=5){
                  this.errors.member_id = 'Member is not a coach';
                  return false;
                }
              } else {
                this.button.SearchButton = 'Search';
                this.memberSerchResultStatus = true;
                this.checkingDuplicate = false;
                this.errors.member_id = 'Member is not found or Invalid Member Id';
                return false;
              }
            })
            .catch((error) => {
              console.log('Error:'+ error);
              this.errors.member_id = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
              //this.step--;
              this.button.SearchButton = 'Search';
              return false;
              // error.response.status Check status code
            }).finally(() => {


            });
        },
        lookUpUpdateData(){
          this.form.first_name = this.memberSearchResults[0].first_name;
          this.form.last_name = this.memberSearchResults[0].last_name;
          this.form.dob = this.memberSearchResults[0].dob;
          this.form.gender = this.memberSearchResults[0].gender;
          this.form.middle_name = this.memberSearchResults[0].middle_name;
          this.form.suffix = this.memberSearchResults[0].suffix;
          this.memberLookupForm.member_name = this.memberSearchResults[0].full_name;
          },
        editPersonalDetails(){
          this.memberSearchResults[0].first_name = this.form.first_name;
          this.memberSearchResults[0].last_name = this.form.last_name;
          this.memberSearchResults[0].middle_name = this.form.middle_name;
          this.memberSearchResults[0].dob = this.form.dob;
          this.memberSearchResults[0].gender = this.form.gender;
          this.memberSearchResults[0].suffix = this.form.suffix;
          this.memberUpdatedLookup.form =this.form;
          this.memberUpdatedLookup.form.id =this.memberSearchResults[0].id;
          this.memberUpdatedLookup.lookup =this.memberSearchResults;
        }
    },
    mounted(){
        console.log('my form m');
        console.log(this.form);
        this.getClubs();
        this.getTeamsList();
        this.getAllTeamsList();
        this.getCollegeCommittedsList();
        if(this.isTeamRegistration){
          this.isCheckChildUser();
        }
        const year = new Date().getFullYear();
        const yrs = year+20;
        const twoDigitYear = yrs.toString().substr(-2);
        const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
        this.yearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
        if(this.updatedVerifiedDetails != undefined && this.updatedVerifiedDetails.personal != undefined){
              this.form.first_name = this.updatedVerifiedDetails.personal.first_name;
              this.form.middle_name = this.updatedVerifiedDetails.personal.middle_name;
              this.form.last_name = this.updatedVerifiedDetails.personal.last_name;
              this.form.suffix = this.updatedVerifiedDetails.personal.suffix;
              this.form.gender = this.updatedVerifiedDetails.personal.gender;
              this.form.dob = this.updatedVerifiedDetails.personal.dob;
              //this.getDateValue();
              this.form.relation =0;
              this.form.citizenship = this.updatedVerifiedDetails.personal.citizenship;
              this.form.affiliate = this.updatedVerifiedDetails.personal.affiliate;
              this.form.veteran_status = this.updatedVerifiedDetails.personal.veteran_status;
              this.form.club = this.updatedVerifiedDetails.personal.club;
              this.form.new_club_name = this.updatedVerifiedDetails.personal.new_club_name;
              this.form.new_club_email = this.updatedVerifiedDetails.personal.new_club_email;
              this.form.new_club_phone = this.updatedVerifiedDetails.personal.new_club_phone;
              this.form.club_affiliate = this.updatedVerifiedDetails.personal.club_affiliate;
              this.form.agl_player_id = this.updatedVerifiedDetails.personal.agl_player_id;
              this.form.jersey_number = this.updatedVerifiedDetails.personal.jersey_number;
              this.form.primary_position = this.updatedVerifiedDetails.personal.primary_position;
              this.form.secondary_position = this.updatedVerifiedDetails.personal.secondary_position;
              this.form.graduation_year = this.updatedVerifiedDetails.personal.graduation_year;
              this.form.bats = this.updatedVerifiedDetails.personal.bats;
              this.form.throws = this.updatedVerifiedDetails.personal.throws;
              this.form.college_list = this.updatedVerifiedDetails.personal.college_list;
              this.form.college_committed = this.updatedVerifiedDetails.personal.college_committed;
              this.collegeCommitted = this.updatedVerifiedDetails.personal.collegeCommitted;
        }
        if(this.isValidateMemberType){
          this.resetFields();
        }
    },
  updated() {
    if(this.invitedTeam){      
      this.form.club=this.invitedTeam;
      this.disableTeam=true;
    }
  }
}


</script>

<style scoped>
.personal-type .tab_subtitle{
    font-weight: 700;
    font-style: italic;
}
</style>
