<template>
    <div class="z-value login-box">
        <Logo></Logo>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <h4 class="login-box-msg ">Enterprise Platform</h4>
                <div class="alert alert-success fade in alert-dismissible show" style="margin-top:18px;" v-bind:class="{ 'd-none': isActiveMessage }">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" >
                        <span aria-hidden="true" style="font-size:20px">×</span>
                    </button>    <strong>Success!</strong> Reset password link sent your mail.
                </div>
                <form @submit.prevent="checkForgot" method="post">
                    <div class="input-group mb-3">
                        <input v-model="email" placeholder="Email" class="form-control"
                               v-bind:class="{ 'is-invalid': isActiveEmail }"
                               name="email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="{ error: isActiveEmail }">{{ errors_email }}</div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block">Reset Password</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

                <p class="mt-3 mb-1">
                    <router-link to="/"><a>Login</a></router-link>
                </p>
                <p class="mb-0">
                    <a href="/" class="text-center">Register a new membership</a>
                </p>
                <Footer></Footer>
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</template>

<script>
import Logo from './AuthHead.vue';
import Footer from './AuthFoot.vue';
import axios from 'axios';

export default {
    name: 'MyComponent',
    components: {
        Logo, Footer
    },
    data() {
        return {
            password: '',
            email: '',
            errors_email: '',
            isActiveEmail: false,
            isActiveMessage: true,
            // reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    methods: {
        checkForgot: function () {
            if (this.email != '') {
                if (!this.reg.test(this.email)) {
                    this.isActiveEmail = true;
                    this.errors_email = "Please valid e-mail address";
                } else {
                    this.errors_email = '';
                    this.isActiveEmail = false;
                    axios.post(process.env.VUE_APP_URL + 'api/forgot_password', {email: this.email}).then(response => {
                        if (response.data.status == 'success') {
                            this.isActiveMessage = false;
                            this.email='';
                        } else {
                            this.errors_email = 'These credentials do not match our records.';
                            this.isActiveEmail = true;
                        }
                    }).catch(error => {

                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }

            } else {
                if (!this.email) {
                    this.errors_email = 'The email field is required';
                    this.isActiveEmail = true;

                } else {

                    this.errors_email = '';
                    this.isActiveEmail = false;
                }

            }

        }

    }

}
</script>
