<template>
  <div>
    <main class="main" role="main">
      <div class=" bg-light h-100">
        <div class="container-fluid">
          <div class="row">
            <div
              class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top"
              id="left"
            >
              <banner></banner>
            </div>
            <div
              class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-5 bg-white"
              style="margin-left: 15.666667% !important;"
            >    
          <div class="row" v-if="subdomain != 'waterski'" >
                <div class="col-md-12 d-flex " style="">
                   <div class="contentscroll  align-self-center w-100" v-html="getTermsAndContent"></div>
                    <!-- <div class="contentscroll  align-self-center w-100" v-html=" this.getSiteProps('privacypolicy.description')">              
                    </div> -->
                </div>                
              </div>
              <div class="row" v-else >
                <div class="col-md-12 d-flex ">
                    <div class="contentscroll"  v-html=" this.getSiteProps('termsandcondition.description')">              
                    </div>
                </div>
              </div>    
             
              <div class="footer-inner">
                <div class="row footer-row">
                  <div class="col-md-7">
                    <p class="text-danger">
                      {{
                        this.getSiteProps("login.copyright") +
                          " " +
                          new Date().getFullYear()
                      }}
                    </p>
                  </div>
                  <div class="col-md-5">
                    <p class="text-right">
                      Powered by <a href="https://www.isportz.co/" target="_blank"><img
                        v-bind:src="
                          '/dist/img/logo.svg'
                        " style="width:80px"
                        alt="footer-img"
                      /></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import banner from "./banner";
import termsandcondition from "./shootingcontent/TermsandCondition.txt";
import alliancetermsandcondition from "./allianceFastpitchContent/TermsandCondition.txt";
export default {
  name: "registerlogin",

  components: { banner },
  data() {
    return {
      getTermsAndContent: "",
      termsAndContent: ""
    };
  },
  methods: {
    getPoemList() {
      if(this.subdomain == 'alliancefastpitch'){
        this.getTermsAndContent = alliancetermsandcondition;
      }else{
        this.getTermsAndContent = termsandcondition;
      }

    },
    forgotPassword: function() {
      window.open(
        process.env.VUE_APP_URL + "registration/forgotpassword",
        "_self"
      );
    },
    register: function() {
      window.open(process.env.VUE_APP_URL + "memberregistration", "_self");
    },
  },
  mounted() {
    this.getPoemList();
  },
};
</script>
