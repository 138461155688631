<template>
<b-modal id="modal-family-update" scrollable v-bind:hide-footer="false" @ok="UpdateMemberInfo" :ok-disabled="btn_submit.load" v-bind:ok-title="btn_submit.text" ref="modal-family-member-update" size="xl">
	<custom-alert v-if="displayAlert==true" :newDismissSecs="newDismissSecs" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
	<template slot="modal-title">Edit Family Member Demographics Informations</template>
	<form ref="form" method="post" @submit.stop.prevent>
		<div class="row">
			<div class="col-xl-4 col-lg-4">
				<b-form-group class="mb-4">
					<h6>Race <span class="text-danger">*</span></h6>
					<b-form-radio-group v-model="FamilyMember.diversity_id" stacked name="FamilyMember.diversity_id" id="FamilyMember.diversity_id"
						:options='diversities' value-field="id" text-field="name" :class="errors.family.diversity_id.valid == false ? 'is-invalid invalid' : ''" v-on:change="validateDiversity()">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.diversity_id.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.diversity_id.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-8 col-lg-8">
				<div class="row">
					<div class="col-xl-6 col-lg-6 col-sm-6">
						<b-form-group class="mb-4">
							<h6>Ethnicity <span class="text-danger">*</span></h6>
							<b-form-radio-group v-model="FamilyMember.ethnicity" stacked name="FamilyMember.ethnicity" id="FamilyMember.ethnicity" :options="ethnicityChoices" value-field="label" text-field="label" :class="errors.family.ethnicity.valid == false ? 'is-invalid invalid' : ''" v-on:change="validateEthnicity()">
							</b-form-radio-group>
							<b-form-invalid-feedback v-if="errors.family.ethnicity.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.ethnicity.message }}</b-form-invalid-feedback>
						</b-form-group>
					</div>
					<div class="col-xl-4 col-lg-4 col-sm-6">
						<b-form-group class="mb-4">
							<h6>Disability Status <span class="text-danger">*</span></h6>
							<b-form-radio-group v-model="FamilyMember.disability" name="FamilyMember.disability" stacked id="FamilyMember.disability" :options='disabilityChoices' value-field="label" text-field="label" :class="errors.family.disability.valid == false ? 'is-invalid invalid' : ''" v-on:change="validateDisability()">
							</b-form-radio-group>
							<b-form-invalid-feedback v-if="errors.family.disability.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.disability.message }}</b-form-invalid-feedback>
						</b-form-group>
					</div>
					<div class="col-xl-4 col-lg-4 col-sm-6" v-if="FamilyMember.relation != 2">
						<b-form-group class="mb-4">
							<h6>Veteran Status <span class="text-danger">*</span></h6>
							<b-form-radio-group v-model="FamilyMember.veteran_status" name="FamilyMember.veteran_status" stacked id="FamilyMember.veteran_status" :options='veteranStatusChoices' value-field="label" text-field="label" :class="errors.family.veteran_status.valid == false ? 'is-invalid invalid' : ''" v-on:change="validateVeteranStatus()">
							</b-form-radio-group>
							<b-form-invalid-feedback v-if="errors.family.veteran_status.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.veteran_status.message }}</b-form-invalid-feedback>
						</b-form-group>
					</div>
				</div>
			</div>
		</div>
	</form>
</b-modal>
</template>
<script>
import moment from "moment";
import axios from 'axios';
import { ToastPlugin } from 'bootstrap-vue';
import CustomAlert from "../../CustomAlert";
import $ from 'jquery';

export default {
	name: "familymemberprofile",
	components: {CustomAlert},
	data() {
		return {
			modalTitle:"Add",
			btn_submit:{load:false, text: 'Update' },
			diversities: [],
			ethnicityChoices:[],
			disabilityChoices:[],
			veteranStatusChoices: [],
			FamilyMember: {
				id:"",
				confirmation_code: "",
				membership_id: 5,
				member_type_id: 3,
				diversity_id: "",
				ethnicity: "",
				disability: "",
				veteran_status:"",
			},
			errors: {
				status: true,
				family: {
					diversity_id: { valid:true, message:"" },
					ethnicity: { valid:true, message:"" },
					disability: { valid:true, message:"" },
					veteran_status: { valid:true, message:"" },
				}
			}
		}
	},
	methods: {
		resetAlert(){
                this.displayAlert = false;
				this.newDismissSecs = 0;
                this.removeDangerBg();
        },
		setDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-success');
                                $('#alert_box').children('div').addClass('alert-danger');
                    }, 100);
        },
		removeDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-danger');
                                $('#alert_box').children('div').addClass('alert-success');
                    }, 100);
        },
		buildOptinos() {
			this.ethnicityChoices = this.getSiteProps('memberRegistration.family.fields.demographic.ethnicity.choices');
			this.disabilityChoices = this.getSiteProps('memberRegistration.family.fields.demographic.disability_status.choices');
			this.veteranStatusChoices = this.getSiteProps('memberRegistration.family.fields.demographic.veteran_status.choices');
		},
		showEditModal(arg) {
			this.buildOptinos();

			for (const [key, value] of Object.entries(this.errors.family)) { this.errors.family[key]['valid'] = true; this.errors.family[key]['message'] = ""; }
			if(Object.keys(arg).length > 0) {
				this.$refs['modal-family-member-update'].show();
				this.btn_submit.load = false;
				this.btn_submit.text = 'Update';
				this.FamilyMember.id = arg.id;
				this.FamilyMember.confirmation_code = arg.confirmation_code;
				this.FamilyMember.hoh_member_id = arg.personal_details.hoh_member_id;
				this.FamilyMember.diversity_id = arg.personal_details.diversity.id;
				this.FamilyMember.ethnicity = arg.personal_details.ethnicity;
				this.FamilyMember.disability = arg.personal_details.disability;
				this.FamilyMember.veteran_status = arg.personal_details.veteran_status;
				this.FamilyMember.relation = arg.personal_details.relation;
				this.FamilyMember.diversity_id = this.FamilyMember.diversity_id == 99 ? '' : this.FamilyMember.diversity_id;
			} else {
				this.setDangerBg();
				this.alertMessage = "Member Information not found....";
                this.displayAlert = true;
				this.newDismissSecs = 8;
			}
		},
		getDiversity() {
			axios.get(this.basePath+"api/getDiversity").then(response => {
				this.diversities = response.data.data;
			}).catch(err => { console.log(err); });
		},
		/*	Start :	Add/Edit Form Validation Rules	*/
		validateDiversity() {
			if(this.FamilyMember.diversity_id == '') {
				this.errors.status = false;
				this.errors.family.diversity_id.valid = false;
				this.errors.family.diversity_id.message = "Select Race";
			} else {
				this.errors.family.diversity_id.valid = true;
				this.errors.family.diversity_id.message = "";
			}
		},
		validateEthnicity() {
			if(this.FamilyMember.zip == '' || !this.ethnicityChoices.some(obj => obj.label === this.FamilyMember.ethnicity)) {
				this.errors.status = false;
				this.errors.family.ethnicity.valid = false;
				this.errors.family.ethnicity.message = "Select Ethnicity";
			} else {
				this.errors.family.ethnicity.valid = true;
				this.errors.family.ethnicity.message = "";
			}
		},
		validateDisability() {
			if(this.FamilyMember.disability == '' || !this.disabilityChoices.some(obj => obj.label === this.FamilyMember.disability)) {
				this.errors.status = false;
				this.errors.family.disability.valid = false;
				this.errors.family.disability.message = "Select disablity status";
			} else {
				this.errors.family.disability.valid = true;
				this.errors.family.disability.message = "";
			}
		},
		validateVeteranStatus() {
			if(this.FamilyMember.veteran_status == '' || !this.veteranStatusChoices.some(obj => obj.label === this.FamilyMember.veteran_status)) {
				this.errors.status = false;
				this.errors.family.veteran_status.valid = false;
				this.errors.family.veteran_status.message = "Select veteran status";
			} else {
				this.errors.family.veteran_status.valid = true;
				this.errors.family.veteran_status.message = "";
			}
		},
		/*	End :	Add/Edit Form Validation Rules	*/
		UpdateMemberValidate() {
			this.errors.status = true;
			if(this.FamilyMember.relation != 2) {
				this.validateVeteranStatus()
			} else { 
				this.errors.family.veteran_status.valid = true;
				this.errors.family.veteran_status.message = "";
			}
			this.validateDiversity(); //Diversity/Race
			this.validateEthnicity() //Ethnicity
			this.validateDisability() //Disablity Status
		},
		UpdateMemberInfo(bvModalEvt){
			bvModalEvt.preventDefault();
			this.UpdateMemberValidate();
			if(this.errors.status == false){
				this.setDangerBg();
				this.alertMessage = "Please fill all mandatory fields";
                this.displayAlert = true;
				this.newDismissSecs = 8;
			} else {
				this.btn_submit.load = true;
				this.btn_submit.text = 'Updating...';
				axios.post(this.basePath+"api/member/family/editdemographic", this.FamilyMember, { headers:this.memberHeaders })
				.then(function (response) {
					if(response.data.status == 'success') {
						this.alertMessage = "Family Member Personal Information updated successfully";
						this.newDismissSecs = 7;
                		this.displayAlert = true;
						setTimeout(() => {
						this.$refs['modal-family-member-update'].hide();
						this.$emit('memfresh', {loader: true, id: this.FamilyMember.id });
						}, 3000);
					} else  {
						alert("Error");
					}
				}.bind(this)).catch(function (error) {
					console.log("Error: "+error);
				});
			}
		},
	},
	mounted() {
		this.getDiversity();
	}
}
</script>