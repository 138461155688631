<template>
   <div>
<clubsidebar></clubsidebar>
<clubnavigation></clubnavigation>
            <div class="content-wrapper">
               <router-view :key="$route.fullPath"></router-view>
            </div>
      <clubdboardfooter></clubdboardfooter>
    </div>
</template>

<script>
import clubnavigation from "../components/Navigation";
import clubsidebar from "../components/Sidebar";
import clubdboardfooter from "../components/Footer";


export default {
    name: "ClubLayout",
     components: {clubnavigation, clubsidebar,clubdboardfooter},
}
</script>
