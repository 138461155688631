
<template>
<div>
<main class="main" role="main">
    <div class=" bg-light h-100">
        <div class="container-fluid h-100">
            <div class="row">
                <div class="left_bg mregistraion col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">
                    <banner></banner>
                </div>
                <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-0 rightside_section">
                    <div class=" mb-3  hvertical border-radius-0 border-0">
                      <b-overlay :show="blockLoader" rounded="sm">
                        <div class="card-header bg-white border-0 p-0">
                                <div class="th_clr season_year text-center">
                                    Membership Registration
                                </div>
                               <!-- <h4 class="text-center font-italic seasonregistr">
                                 Season Registration
                                 </h4>-->
                                <div class="clearfix"></div>
                                <div class="tabbable">
                                    <div class="h-steps ">
                                    <section v-show= "registerationStep1" >
                                       <ul class="header-navigation">
                                            <li><span class="header-steps current-item">Register</span></li>
                                            <li><span class="header-steps">Verify</span></li>
                                            <li v-if="membership.member_type_id == 4 && !isTeamRegistration"><span class="header-steps">Club Agreement</span></li>
                                            <li v-else><span class="header-steps">Waiver/Acknowledgement</span></li>
                                            <li><span class="header-steps">Payment</span></li>
                                            <li><span class="header-steps">Confirmation</span></li>
                                       </ul>
                                    </section>
                                    <section v-show= "registerationStep2" >
                                       <ul class="header-navigation">
                                            <li><span class="header-steps completed">Register</span></li>
                                            <li><span class="header-steps current-item">Verify</span></li>
                                            <li v-if="membership.member_type_id == 4 && !isTeamRegistration"><span class="header-steps">Club Agreement</span></li>
                                            <li v-else><span class="header-steps">Waiver/Acknowledgement</span></li>
                                            <li><span class="header-steps">Payment</span></li>
                                            <li><span class="header-steps">Confirmation</span></li>
                                       </ul>
                                    </section>
                                    <section v-show= "registerationStep3" >
                                       <ul class="header-navigation">
                                            <li><span class="header-steps completed">Register</span></li>
                                            <li><span class="header-steps completed">Verify</span></li>
                                            <li v-if="membership.member_type_id == 4 && !isTeamRegistration"><span class="header-steps current-item">Club Agreement</span></li>
                                            <li v-else><span class="header-steps current-item">Waiver/Acknowledgement</span></li>
                                             <li><span class="header-steps">Payment</span></li>
                                            <li><span class="header-steps">Confirmation</span></li>
                                       </ul>
                                    </section>
                                    <section v-show= "registerationStep4" >
                                       <ul class="header-navigation">
                                            <li><span class="header-steps completed">Register</span></li>
                                            <li><span class="header-steps completed">Verify</span></li>
                                            <li v-if="membership.member_type_id == 4 && !isTeamRegistration"><span class="header-steps completed">Club Agreement</span></li>
                                            <li v-else><span class="header-steps completed">Waiver/Acknowledgement</span></li>
                                            <li><span class="header-steps current-item">Payment</span></li>
                                            <li><span class="header-steps ">Confirmation</span></li>
                                       </ul>
                                    </section>
                                    <section v-show= "registerationStep5" >
                                       <ul class="header-navigation">
                                            <li><span class="header-steps completed">Register</span></li>
                                            <li><span class="header-steps completed">Verify</span></li>
                                            <li v-if="membership.member_type_id == 4 && !isTeamRegistration"><span class="header-steps completed">Club Agreement</span></li>
                                            <li v-else><span class="header-steps completed">Waiver/Acknowledgement</span></li>
                                            <li><span class="header-steps completed">Payment</span></li>
                                            <li><span class="header-steps completed">Confirmation</span></li>
                                       </ul>
                                    </section>
                                    </div>
                                </div>
                                <div class="card-body bg-light form-flds rm-shadow hvertical mbv-height-pad ">
                                    <div class="row"> 
                                              
                                    <div class="col-md-12" v-show="registerationStep1">
                                            <div class="">
                                                        <div class="card-body pb-3 p-0 min-h-350">
                                                          <div class="row">
                                                              <div class="col-md-12 custom-padding" v-if="regStatus == 0">
                                                              <h3 class="reg-status-style" v-html="regClosedMessage"></h3>
                                                              </div>      
                                                          </div>
                                                        <div class="row">
                                                            <div class="col-md-6 left-navigation-inner ">
                                                                <div v-if="steps">
                                                                    <div class="card-header register-title">
                                                                        <h3>Registration</h3>
                                                                    </div>
                                                                    <div class="left-title reg-step-1" :class="step >= '2' ? 'is-red'  :  (step == '1' ?'active' :'') ">
                                                                        <h3>Membership Types</h3>
                                                                    </div>
                                                                    
                                                                    <template v-if="steps">
                                                                        <template v-for="(type, si) in steps.steps">
                                                                            <template v-if="(type.visible == undefined || type.visible == 1) && ((ncsaEnable && type.title == 'Membership Benefits') || type.title != 'Membership Benefits')">
                                                                                <div v-if="membership.member_type_id != 4 ||(membership.member_type_id == 4 && isTeamRegistration)" v-bind:key="si" class="left-title" :class="'reg-step-' + (si+2) + ((si+1) < (currentStep) ? ' is-red ' : ((si+1) == (currentStep) ?' active' :''))">
                                                                                    <h3>{{ type.title }}</h3>
                                                                                </div>
                                                                                <div v-else-if="membership.member_type_id == 4 && !isTeamRegistration" v-bind:key="si" class="left-title" :class="'reg-step-' + (si+9) + ((si+1) < (currentStep) ? ' is-red ' : ((si+1) == (currentStep) ?' active' :''))">
                                                                                    <h3>{{ type.title }}</h3>
                                                                                </div>
                                                                            </template>
                                                                            <!-- <div v-bind:key="si" class="left-title" :class="'reg-step-' + (type.step) + ((type.step) < step ? ' is-red ' : ((type.step) == step ?' active' :''))">
                                                                                <h3>{{ type.title }}</h3>
                                                                            </div> -->
                                                                        </template>
                                                                    </template>
                                                                    
                                                                    <!-- <div v-if="membership.id != 6" class="left-title reg-step-2" :class="step >= '3' ? 'is-red ' : (step == '2' ?'active' :'')">
                                                                        <h3>Personal Information</h3>
                                                                    </div>
                                                                    <div v-if="membership.id != 6" class="left-title reg-step-3" :class="step >= '4' ? 'is-red ' : (step == '3' ?'active' :'')">
                                                                        <h3>Contact Information</h3>
                                                                    </div>
                                                                    <div v-if="membership.id != 6" class="left-title reg-step-4" :class="step >= '5' ? 'is-red ' : (step == '4' ?'active' :'')">
                                                                        <h3>General Information</h3>
                                                                    </div>
                                                                    <div v-if="membership.id != 6" class="left-title reg-step-5" :class="step >= '6' ? 'is-red ' : (step == '5' ?'active' :'')">
                                                                        <h3>Demographic Information</h3>
                                                                    </div>
                                                                   <div v-if="membership.id != 6 && membership.id == 5" class="left-title reg-step-6" :class="step >= '7' ? 'is-red ' : (step == '6' ?'active' :'')">
                                                                        <h3>Family Member</h3>
                                                                    </div> -->


                                                                    <!-- <div v-if="membership.id ==  6" class="left-title reg-step-9" :class="step >= '10' || step == 7 ? 'is-red ' : (step == '9' ?'active' :'')">
                                                                        <h3>Club Information</h3>
                                                                    </div>
                                                                    <div v-if="membership.id ==  6" class="left-title reg-step-10" :class="step >= '11' || step == 7 ? 'is-red ' : (step == '10' ?'active' :'')">
                                                                        <h3>Primary Contact Information</h3>
                                                                    </div>
                                                                    <div v-if="membership.id ==  6" class="left-title reg-step-11" :class="step >= '12' || step == 7 ? 'is-red ' : (step == '11' ?'active' :'')">
                                                                        <h3>Club Officer Information</h3>
                                                                    </div>
                                                                     <div v-if="membership.id ==  6" class="left-title reg-step-12" :class="step >= '13' || step == 7 ? 'is-red ' : (step == '12' ?'active' :'')">
                                                                        <h3>Junior Development Leader Information</h3>
                                                                    </div>

                                                                     <div v-if="membership.id ==  6" class="left-title reg-step-13" :class="step >= '14' || step == 7 ? 'is-red ' : (step == '13' ?'active' :'')">
                                                                        <h3>Show Director/Assistant Show Director Information</h3>
                                                                    </div>
                                                                    <div v-if="membership.id ==  6" class="left-title reg-step-14" :class="step >= '15' || step == 7 ? 'is-red ' : (step == '14' ?'active' :'')">
                                                                        <h3>Purchase Certificate of Insurance</h3>
                                                                    </div> -->
                                                                    <!-- <div class="left-title reg-step-7" :class="((membership.id !=  6 && step >= '8') || (membership.id ==  6 && step == '15')) ? 'is-red ' : (step == '7' || step == 15 ?'active' :'')">
                                                                        <h3>Donation</h3>
                                                                    </div>
                                                                    <div v-if="membership.id != 6" class="left-title reg-step-8" :class="step >= '9' ? 'is-red ' : (step == '8' ?'active' :'')">
                                                                        <h3>Merchandise</h3>
                                                                    </div> -->

                                                                </div>
                                                                <div v-else class="text-center h-100 p-5">
                                                                    <i class="fa fa-circle-notch fa-spin fa-2x"></i><br>
                                                                    <strong>Please wait...</strong>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6 right-navigation-inner ">
                                                            <div class="">
                                                                <section v-show = "step1">
                                                                <p v-if="errors.length">
                                                                     <b>Please correct the following error(s):</b>
                                                                         <span v-for="(error, index) in errors" :key="index" class="text-danger">{{ error }}</span>
                                                                </p>
                                                                    <membershiptype @member-type-selected="recordMembershipType($event)" v-bind:basePath="basePath" :memberTypes="memberTypes" @previous-step="previousStep()" :propsMemberTypes="prop_membertypes" :priceFetching="priceFetching" :regStatus="regStatus"></membershiptype>

                                                                </section>

                                                                <section v-show = "step2">
                                                                <p v-if="errors.length">
                                                                     <b>Please correct the following error(s):</b>
                                                                         <span v-for="(error,i) in errors" :key="i" class="text-danger">{{ error }}</span>
                                                                </p>
                                                                    <personalinformation @personal-data="recordPersonalData($event)" @previous-step="previousStep()" v-bind:membership="membership" v-bind:fields="fields.personal" v-bind:checkingDuplicate="checkingDuplicate" v-bind:duplicateError="duplicateError" :isChild="isChild" :isTeamRegistration="isTeamRegistration" :diversities="diversities" v-bind:isValidateMemberType="isValidateMemberType" v-bind:updated-verified-details="updatedValues" ref="personalinformation" :key="personal_render" v-bind:invitedTeam="invitedTeam" v-bind:isAthlete="isAthlete" v-bind:currentSeason="currentSeason"></personalinformation>
                                                                </section>
                                                                <section v-show = "step3">
                                                                <p v-if="errors.length">

                                                                         <span v-for="(error,i) in errors" :key="i" class="text-danger">{{ error }}</span>
                                                                </p>
                                                                    <contactinfo @contact-data="recordContactData($event)" @previous-step="previousStep()" v-bind:listOfCountries="listOfCountries" v-bind:listOfStates="listOfStates" v-bind:fields="fields.contact" :listOfCountry="listOfCountryWithLimit" :isChildUsers="isChildUsers" :age="age" v-bind:isValidateMemberType="isValidateMemberType" v-bind:updatedVerifiedDetails="updatedValues" :key="contact_render" v-bind:invitedEmail="invitedEmail" :isChild="isChild" :isAlliance="isTeamRegistration" :memberContactLookup="memberLookupContact" ></contactinfo>
                                                                </section>

                                                                <section v-show = "step4">
                                                                    <generalinfo @general-info-data="recordGeneralInfoData($event)" v-bind:basePath="basePath" :disciplines="disciplines" :groups="groups" @previous-step="previousStep()" v-bind:membership="membership" v-bind:fields="fields.general" :age="age" v-bind:isValidateMemberType="isValidateMemberType"></generalinfo>
                                                                </section>
                                                                <section v-show = "step5">
                                                                    <demographicinformation v-if="!isTeamRegistration" @demographic-data="recordDemographicData($event)" :diversities="diversities" v-bind:basePath="basePath" @previous-step="previousStep()"  v-bind:membership="membership" v-bind:fields="fields.demographic" :categories="categories" :age="age"></demographicinformation>
                                                                    <TeamInformation v-else @team-data="recordTeamData($event)" :diversities="diversities" v-bind:basePath="basePath" @previous-step="previousStep()"  v-bind:membership="membership" v-bind:fields="fields.club_info" :categories="categories" :currentRegContacts="currentRegContacts" :isTeamRegistration="isTeamRegistration" :age="age" :age_division_list="divisions_list" :league_list="memberLeagueSelect2" v-bind:isValidateMemberType="isValidateMemberType" v-bind:updatedVerifiedDetails="updatedValues" :key="team_render" v-bind:checkingDuplicate="checkingDuplicate" v-bind:duplicateError="duplicateError" v-bind:duplicateErrorMessage="duplicateErrorMessage" v-bind:isTeamType="isTeam"></TeamInformation>
                                                                </section>

                                                                <section v-show = "step6">
                                                                    <familymembers @family-data="recordFamilyInfo($event)" :player_informations="player_informations" :diversities="diversities" v-bind:members="this.form.members" :disciplines="disciplines" :currentRegContacts="currentRegContacts" @family-remove-data="removeMembers($event)" @previous-step="previousStep()" @next-step="nextStep()" v-bind:fields="fieldsAd" :groups="groups" :age_divisionArr="isTeamOptions" :age_division="form.age_division" :membersList="form.members" :college_commited_list="collegeOptions" v-bind:isValidateMemberType="isValidateMemberType" v-bind:updatedVerifiedDetails="updatedValues" :key="family_render" v-bind:membershipType="form.membership_id" v-bind:currentSeason="currentSeason"></familymembers>
                                                                </section>

                                                                <section v-show = "step7">
                                                                    <donation v-if="!addons" @donation-amount-data ="recordDonationAmountData($event)" @previous-step="previousStep()" v-bind:button="button" v-bind:fields="fields.donation"></donation>
                                                                    <addons v-if="addons" @addons-amount-data ="recordAddonsAmountData($event)" @previous-step="previousStep()" v-bind:button="button" v-bind:fields="fields.addons" v-bind:isValidateMemberType="isValidateMemberType" v-bind:updatedVerifiedDetails="updatedValues" :key="addons_render"></addons>
                                                                </section>
                                                                <section v-show = "step8">
                                                                    <merchandise v-bind:regId="this.regId" v-bind:merchandiseDataMen="this.merchandiseDataMen" v-bind:merchandiseDataWomen="this.merchandiseDataWomen" v-bind:merchandiseDataAccessories="this.merchandiseDataAccessories" @added-to-cart ="recordAddedToCart($event)" v-bind:basePath="basePath" @previous-step="previousStep()" v-bind:buttonMain="button" @cart-products="cartProducts($event)" @placeOrder="placeOrderApi()" v-bind:fields="fields.merchandise"></merchandise>
                                                                </section>

                                                                <section v-show = "step9">
                                                                <ClubInformation @club-data="recordClubinformation($event)" @previous-step="previousStep()" v-bind:listOfCountries="listOfCountries" v-bind:listOfStates="listOfStates" :fields="fields.club_info" v-bind:duplicateError="duplicateError"></ClubInformation>
                                                                </section>

                                                                <section v-show = "step10">

                                                                <PrimaryContact @club-primary-data="recordClubprimaryinfo($event)" @club-contact-info="recordClubContactInfo($event)" v-bind:basePath="basePath" @previous-step="previousStep()" @next-step="nextStep()" v-bind:listOfStates="listOfStates" v-bind:fields="fields.club_contact"></PrimaryContact>

                                                                </section>

                                                                <section v-show = "step11">
                                                                    <ClubOfficers @club-officers-data="recordclubOfficersInfo($event);" v-bind:basePath="basePath" v-bind:clubMemberContactLength="clubMemberContactLength" @club-remove-data="removeClubMembers($event)" @previous-step="previousStep()" @next-step="nextStep()" v-bind:listOfStates="listOfStates" v-bind:fields="fields.club_officer"></ClubOfficers>
                                                                </section>

                                                                <section v-show = "step12">
                                                                    <SafesportCoordinator @safe-sport-coordinator-data="recordSSCInfo($event)" v-bind:basePath="basePath" v-bind:clubMemberContactLength="clubMemberContactLength" @club-remove-data="removeClubMembers($event)" @previous-step="previousStep()" @next-step="nextStep()" v-bind:listOfStates="listOfStates" v-bind:clubInfoData="clubInfoData" v-bind:fields="fields.safe_sport_coordinator"></SafesportCoordinator>
                                                                </section>

                                                                <section v-show = "step13">
                                                                <juniordevelopmentinfo @junior-development-data="recordJuniorDevlopmentInfo($event)" v-bind:basePath="basePath" :clubMemberContactLength="clubMemberContactLength" @club-remove-data="removeClubMembers($event)" @previous-step="previousStep()" @next-step="nextStep()" v-bind:listOfStates="listOfStates" v-bind:clubInfoData="clubInfoData" v-bind:fields="fields.club_junior"></juniordevelopmentinfo>
                                                                <!--<SecondaryContact @club-secondary-data="recordSecondaryInfo($event)"></SecondaryContact>-->
                                                                </section>

                                                                <section v-show = "step14">
                                                                    <skidirectorinfo @club-ski-directors-data="recordSkiDirectorsInfo($event);" v-bind:basePath="basePath" :clubMemberContactLength="clubMemberContactLength" @club-remove-data="removeClubMembers($event)" @previous-step="previousStep()" @next-step="nextStep()" v-bind:listOfStates="listOfStates" v-bind:clubInfoData="clubInfoData" v-bind:fields="fields.club_director"></skidirectorinfo>
                                                                </section>

                                                              <section v-show = "step15">
                                                                <SanctionInformation @sanction-data="recordSanctionInfo($event)" @previous-step="previousStep()" @next-step="nextStep()" v-bind:clubInfoData="clubInfoData" @insurance-remove-data="deleteSanctionInfo($event)" v-bind:button="button" v-bind:clubRegError="errors.clubRegError" v-bind:fields="fields.club_sanction_fields" v-bind:sanctionFee="sanctionFee"></SanctionInformation>
                                                              </section>

                                                              <section v-show = "step16">
                                                                 <InsurenceInformation :insuranceFee="insuranceFee" @insurance-data="recordInsuranceInfo($event)" @previous-step="previousStep()" v-bind:listOfStates="listOfStates" @next-step="nextStep()" v-bind:clubInfoData="clubInfoData" @insurance-remove-data="deleteInsuranceInfo($event)" v-bind:button="button" v-bind:clubRegError="errors.clubRegError" v-bind:fields="fields.club_insurance"></InsurenceInformation>
                                                                </section>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="large-screen">
                                                    </div>

                                                        </div>
                                                </div>
                                    </div>

<!--                                    <div class="col-md-12" v-show="registerationStep1">
                                        <div class="text-right topspace">
                                            <button v-if="step == 8" @click.prevent="previousStep" class="btn btn-outline-primary savebtn">Previous</button>
                                            <button v-if="step == 8" @click.prevent="nextStep" :disabled="nextButtonDisabled" class="btn btn-outline-primary savebtn next-step-btn">
                                            {{ button.nextButton }}</button>
                                        </div>
                                    </div>-->


                                        <b-modal ok-only v-show="registerationStep2 == true || registerationStep3 == true || registerationStep4 == true && (membership.member_type_id != 4)"  ref="modal"
                                                  @show="resetModal"
                                                  ok-title="Update"
                                                  @hidden="resetModal" @ok="handlePersonalEditOk" id="modal-personal-edit" size="lg" centered :title=" 'Personal Information'">
                                            <div class="popupbody">
                                                <div class="row" v-if="fields.personal.title">
                                                    <div class="col-md-4" v-if="fields.personal.title">
                                                        <div class="form-group">
                                                            <label for="title" class="form-label">{{ fields.personal.title.label }}<sup class="text-danger" v-if="fields.personal.title.required">*</sup></label>
                                                            <!-- <select name="verifyPersonal.title" class="form-control" v-model="verifyData.members.title">
                                                                <option v-for="(item, k) in fields.personal.title.options" :value="item" :key="'title-'+k">{{ item }}</option>
                                                            </select> -->
                                                            <input type="text" class="form-control" id="title" v-model="verifyData.members.title" name="verifyPersonal.title" value="">
                                                            <span class="text-sm text-danger">{{ errors.title}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">First Name <sup class="text-danger">*</sup></label>
                                                            <input type="hidden" class="form-control" name="verifyPersonal.relation" v-model="verifyData.members.relation">
                                                            <input type="hidden" class="form-control" name="verifyPersonal.citizenship" v-model="verifyData.members.citizenship">
                                                            <input type="text" class="form-control" maxlength="45" name="verifyPersonal.first_name" v-model="verifyData.members.first_name">
                                                            <span class="text-sm text-danger">{{ errors.first_name}}</span>
                                                            <span class="text-sm text-danger">{{ duplicateErrorForPersonal}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Middle Initial</label>
                                                            <input type="text" class="form-control" maxlength="3" name="verifyPersonal.middle_name" v-model="verifyData.members.middle_name">
                                                        </div>
                                                      <span class="text-sm text-danger">{{ errors.middle_name}}</span>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Last Name <sup class="text-danger">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" name="verifyPersonal.last_name" v-model="verifyData.members.last_name">
                                                            <span class="text-sm text-danger">{{ errors.last_name}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="!isChild">
                                                        <div class="form-group">
                                                            <label class="form-label">Suffix</label>
                                                            <input type="text" class="form-control" maxlength="45" name="verifyPersonal.suffix" v-model="verifyData.members.suffix">
                                                            <span class="text-sm text-danger">{{ errors.suffix}}</span>
                                                        </div>
                                                    </div>
                                                    <div :class="[fields.personal.birthday.colClass]">
                                                        <div class="form-group">
                                                            <label class="form-label">{{ fields.personal.birthday.label || 'Date of Birth' }} <sup class="text-danger">*</sup></label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <!-- <i class="fas fa-calendar-alt"></i> -->
                                                                    <date-pick
                                                                        v-model="verifyData.members.dob"
                                                                        :value="verifyData.members.dob | formatDob"
                                                                        :pickTime="false"
                                                                        :selectableYearRange="{from: 1900, to: 2021}"
                                                                        name="verifyData.members.dob"
                                                                        :format="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :isDateDisabled="isFutureDate"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                      <!--<datetime format="MM/DD/YYYY" v-model="verifyData.members.dob"></datetime>-->
                                                                </div>
                                                                <span class="text-sm text-danger">{{ errors.dob}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
<!--                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="citizenship" class="form-label">Citizenship <sup class="text-danger">*</sup></label>
                                                            <select class="form-control" id="citizenship" name="verifyPersonal.citizenship" v-model="verifyData.members.citizenship">
                                                                <option value="USA">USA</option>
                                                                <option value="Canada">Canada</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.citizenship}}</span>
                                                        </div>
                                                    </div>-->
                                                    <div class="col-md-6">
                                                        <!-- <div class="form-group">
                                                            <label class="form-label">Gender <sup class="text-danger">*</sup></label><br>
                                                            <input type="radio" id="verify_gender_male" name="verifyPersonal.gender" v-model="verifyData.members.gender" value="Male">
                                                            <label class="gender-label-span" for="verify_gender_male">Male</label><br>
                                                            <input type="radio" id="verify_gender_female" name="verifyPersonal.gender" v-model="verifyData.members.gender" value="Female">
                                                            <label class="gender-label-span" for="verify_gender_female">Female</label><br>
                                                            <input type="radio" id="other" name="verifyPersonal.gender" v-model="verifyData.members.gender"  value="Other">
                                                            <label class="gender-label-span" for="other">Other</label><br>
                                                            <input type="radio" id="verify_gender_prefernottosay" name="verifyPersonal.gender" v-model="verifyData.members.gender" value="Prefer not to say">
                                                            <label class="gender-label-span" for="verify_gender_prefernottosay">Prefer not to say</label><br>
                                                            <span class="text-sm text-danger">{{ errors.gender}}</span>
                                                        </div> -->
                                                        <div class="form-group" v-if="fields.personal.gender && fields.personal.gender.choices">
                                                            <label class="form-label">Gender <sup class="text-danger">*</sup></label><br>
                                                            <template v-for="(choice, key) in fields.personal.gender.choices">
                                                                <input type="radio" :id="choice.value" v-model="verifyData.members.gender" name="verifyPersonal.gender" :value="choice.label" :key="'v-radio_' + key" @click="messageFor(choice.value)">
                                                                <label class="gender-label-span" :for="choice.value" :key="'v-gender_label_'+key">{{ choice.label }}</label>
                                                                <br :key="'v-gender_br_'+key">
                                                            </template>
                                                            <span class="text-sm text-danger">{{ errors.gender}}</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6" v-if="fields.personal.veteran_status">
                                                        <div class="form-group">
                                                            <label for="veteran_status" class="form-label">{{ fields.personal.veteran_status.label }} <sup class="text-danger" v-if="fields.personal.veteran_status.required">*</sup></label>
                                                            <div class="form-group" v-if="fields.personal.veteran_status.choices">
                                                                <template v-for="(choice, key) in fields.personal.veteran_status.choices">
                                                                    <input type="radio" :id="choice.value" v-model="verifyData.veteran_status" name="verifyPersonal.veteran_status" :value="choice.label" :key="'radio_' + key">
                                                                    <label class="veteran_status-label-span" :for="choice.value" :key="'veteran_status_label_'+key">{{ choice.label }}</label>
                                                                    <br :key="'veteran_status_br_'+key">
                                                                </template>
                                                                <span class="text-sm text-danger">{{ errors.veteran_status}}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6" v-if="fields.demographic.veteran_status">
                                                        <div class="form-group radiotext">
                                                        <label for="veteranstatus" class="form-label-check"><strong>{{ fields.demographic.veteran_status.label }}</strong><sup class="text-danger" v-if="fields.demographic.veteran_status.required">*</sup></label>
                                                        <div class="form-group" v-if="fields.demographic.veteran_status.choices">
                                                            <template v-for="(choice, k) in fields.demographic.veteran_status.choices">
                                                                    <input type="radio" :id="choice.id" name="verifyPersonal.veteran_status" :value="choice.label" v-model="verifyData.veteran_status" :key="'ski-vs_'+k">
                                                                    <label :for="choice.id" :key="'verify-vs_label_'+k">{{ choice.label }}</label>
                                                                    <br :key="'verify_vs_br_'+k">
                                                            </template>
                                                            <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6" v-if="fields.general.sport_discipline.home_federation">
                                                        <div class="form-group">
                                                            <label for="home_federation" class="form-label">{{ fields.general.sport_discipline.home_federation.label }} <sup class="text-danger" v-if="fields.general.sport_discipline.home_federation.required">*</sup></label>
                                                            <select class="form-control" id="home_federation" name="verifyPersonal.home_federation"
                                                                    v-model="verifyData.members.home_federation">
                                                                <option value="">Select</option>
                                                                <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                                                            </select>
                                                            <span class="font-13 text-danger">{{ errors.home_federation }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.general.sport_discipline.skier_license">
                                                        <div v-if="verifyData.members.home_federation !='USA'">
                                                            <div class="form-group">
                                                                <label for="skier_license" class="form-label">{{ fields.general.sport_discipline.skier_license.label }} <sup class="text-danger" v-if="fields.general.sport_discipline.skier_license.required">*</sup></label>
                                                                <input type="text" id="skier_license" class="form-control" maxlength="9" @keypress="isNumber($event)"
                                                                    placeholder="" name="form.skier_license"
                                                                    v-model="verifyData.members.skier_license">
                                                                <span class="font-13 text-danger">{{ errors.skier_license }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div :class="[fields.general.sport_discipline.first_choice.multiple ? 'col-md-12 full-width-select2' : 'col-md-6']" v-if="fields.general.sport_discipline.first_choice && !isTeamRegistration && !(subdomain=='shooting'&& membership.member_type_id == 7)">
                                                        <div class="form-group">
                                                            <label for="first-choice" class="form-label">{{ fields.general.sport_discipline.first_choice.label }} <sup class="text-danger" v-if="fields.general.sport_discipline.first_choice.required">*</sup></label>
                                                            <Select2 v-if="fields.general.sport_discipline.first_choice.multiple" v-model="verifyData.first_choice" name="verifyData.first_choice" id="verifyData-disciplines" :options="disciplines_list" :settings="{ multiple: true, placeholder: 'Click to select one or multiple', width: '100%' }" />
                                                            <select class="form-control" id="verifyData-first-choice" name="verifyData.first_choice"
                                                                    v-model="verifyData.members.first_choice" v-else>
                                                                <option value="">Select</option>
                                                                <option v-for="(discipline, index) in disciplines" :value="index"
                                                                        :key="'types'+index">{{ discipline }}
                                                                </option>
                                                            </select>
                                                            <span class="font-13 text-danger">{{ errors.first_choice }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.general.sport_discipline.second_choice">
                                                        <div class="form-group">
                                                            <label for="first-choice" class="form-label">{{ fields.general.sport_discipline.second_choice.label }} <sup class="text-danger" v-if="fields.general.sport_discipline.second_choice.required">*</sup></label>
                                                            <select class="form-control" id="verifyData-first-choice" name="verifyData.second_choice"
                                                                    v-model="verifyData.members.second_choice">
                                                                <option value="">Select</option>
                                                                <option v-for="(discipline, index) in disciplines" :value="index"
                                                                        :key="'types'+index">{{ discipline }}
                                                                </option>
                                                            </select>
                                                            <span class="font-13 text-danger">{{ errors.second_choice }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="fields.personal.clubslist&&!(subdomain=='shooting'&&membership.member_type_id == 7)">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="clubslist" class="form-label">{{ fields.personal.clubslist.label }}<sup class="text-danger" v-if="fields.personal.clubslist.required">*</sup></label>
                                                            <Select2 v-model="verifyData.club" :options="clubOptions" name="verifyPersonal.club" :settings="{ placeholder: fields.personal.clubslist.placeholder}" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="verifyData.club == '-'">
                                                    <div class="col-md-4" v-if="fields.personal.new_club_name">
                                                        <div class="form-group">
                                                            <label for="new_club_name" class="form-label">New Club Name<sup class="text-danger" v-if="fields.personal.new_club_name.required">*</sup></label>
                                                            <input type="text" v-model="verifyData.new_club_name" class="form-control" id="verify-new_club_name" name="verifyData.new_club_name" placeholder="" value="">
                                                            <span class="text-sm text-danger">{{ errors.new_club_name }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.personal.new_club_email">
                                                        <div class="form-group">
                                                            <label for="new_club_email" class="form-label">New Club E-Mail<sup class="text-danger" v-if="fields.personal.new_club_email.required">*</sup></label>
                                                            <input type="text" v-model="verifyData.new_club_email" class="form-control" id="verify-new_club_email" name="verifyData.new_club_email" placeholder="">
                                                            <span class="text-sm text-danger">{{ errors.new_club_email }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.personal.new_club_phone">
                                                        <div class="form-group">
                                                            <label for="new_club_phone" class="form-label">New Club Phone<sup class="text-danger" v-if="fields.personal.new_club_phone.required">*</sup></label>
                                                            <vue-tel-input v-model="verifyData.new_club_phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                                            <span class="text-sm text-danger">{{ errors.new_club_phone }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="isChild && isTeamRegistration || (isTeamRegistration  && this.membership.id == 5)">
                                                  <div class="col-md-6" v-if="isChild && isTeamRegistration && fields.personal.agl_player_id">
                                                    <div class="form-group">
                                                      <label for="agl_player_id" class="form-label">
                                                        {{ fields.personal.agl_player_id.label }}<sup class="text-danger" v-if="fields.personal.agl_player_id.required">*</sup>
                                                      </label>
                                                      <input type="text" class="form-control" :placeholder="fields.personal.agl_player_id.placeholder" maxlength="45" id="agl_player_id" v-model="verifyData.members.agl_player_id" name="form.agl_player_id" value="">
                                                      <span class="text-sm text-danger">{{ errors.agl_player_id}}</span>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-6" v-if="isChild && isTeamRegistration ||(isTeamRegistration && this.membership.id ==5)">
                                                    <div class="form-group">
                                                      <label for="team_membership_id" class="form-label">{{ fields.personal.team_membership_id.label }}<sup class="text-danger" id="team" v-if="fields.personal.team_membership_id.required">*</sup></label>
                                                      <Select2 v-model="verifyData.club" v-if="isTeamRegistration && membership.id !=5" :options="teamOptions" name="team" :settings="{ placeholder: 'Select a team'}" />
                                                      <Select2 v-model="verifyData.club" v-if="isTeamRegistration && membership.id ==5" :options="teamsList" id="team" name="team" :settings="{ placeholder: 'Select a team'}" />
                                                      <span class="text-sm text-danger">{{ errors.organization}}</span>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.graduation_year.colClass]" v-if="isChild && isTeamRegistration && fields.personal.graduation_year">
                                                    <div class="form-group">
                                                      <label for="graduation_year" class="form-label">
                                                        {{ fields.personal.graduation_year.label }}<sup class="text-danger" v-if="fields.personal.graduation_year.required">*</sup>
                                                      </label>
                                                      <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="verifyData.members.graduation_year">
                                                        <option value="">Select Graduation Year</option>
                                                        <option v-for="year in GraduationyearArr" :value="year" :key="year">{{ year }}</option>
                                                      </select>
                                                      <span class="text-sm text-danger">{{ errors.graduation_year}}</span>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.jersey_number.colClass]" v-if="isChild && isTeamRegistration && fields.personal.jersey_number">
                                                    <div class="form-group">
                                                      <label for="jersey_number" class="form-label">
                                                        {{ fields.personal.jersey_number.label }}<sup class="text-danger" v-if="fields.personal.jersey_number.required">*</sup>
                                                      </label>
                                                      <input class="form-control" :placeholder="fields.personal.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="verifyData.members.jersey_number" name="form.jersey_number" value="">
                                                      <span class="text-sm text-danger">{{ errors.jersey_number}}</span>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.primary_position.colClass]" v-if="isChild && isTeamRegistration && fields.personal.primary_position">
                                                    <div class="form-group">
                                                      <label for="primary_position" class="form-label">{{ fields.personal.primary_position.label }} <sup class="text-danger" v-if="fields.personal.primary_position.required">*</sup></label>
                                                      <select class="form-control" id="primary_position" v-model="verifyData.members.primary_position" name="form.primary_position">
                                                        <option value="">Select</option>
                                                        <option  v-for="(listOfPrimaryPosition,key) in fields.personal.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
                                                      </select>
                                                      <span class="text-sm text-danger">{{ errors.primary_position}}</span>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.secondary_position.colClass]" v-if="isChild && isTeamRegistration && fields.personal.secondary_position">
                                                    <div class="form-group">
                                                      <label for="secondary_position" class="form-label">{{ fields.personal.secondary_position.label }} <sup class="text-danger" v-if="fields.personal.secondary_position.required">*</sup></label>
                                                      <select class="form-control" id="secondary_position" v-model="verifyData.members.secondary_position" name="form.secondary_position">
                                                        <option value="">Select</option>
                                                        <option  v-for="(listOfSecondaryPosition,key) in fields.personal.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
                                                      </select>
                                                      <span class="text-sm text-danger">{{ errors.secondary_position}}</span>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.throw.colClass]" v-if="isChild && isTeamRegistration">
                                                    <div class="form-group">
                                                      <label for="throw" class="form-label">{{ fields.personal.throw.label }} <sup class="text-danger" v-if="fields.personal.throw.required">*</sup></label>
                                                      <div class="form-group" v-if="fields.personal.throw.choices">
                                                        <template v-for="(choice, key) in fields.personal.throw.choices">
                                                          <input type="radio" :id="choice.value" v-model="verifyData.members.throws" name="form.throw" :value="choice.value" :key="'radio_' + key">
                                                          <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                                                        </template>
                                                        <span class="text-sm text-danger">{{ errors.throw}}</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.bats.colClass]" v-if="isChild && isTeamRegistration">
                                                    <div class="form-group" v-if="isChild">
                                                      <label for="bats" class="form-label">{{ fields.personal.bats.label }} <sup class="text-danger" v-if="fields.personal.bats.required">*</sup></label>
                                                      <div class="form-group" v-if="fields.personal.bats.choices">
                                                        <template v-for="(choice, key) in fields.personal.bats.choices">
                                                          <input type="radio" :id="choice.value" v-model="verifyData.members.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                                                          <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                                                        </template>
                                                        <span class="text-sm text-danger">{{ errors.bats}}</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.college_committed.colClass]" v-if="isChild">
                                                    <div class="form-group">
                                                      <label for="college_committed" class="form-label">{{ fields.personal.college_committed.label }} <sup class="text-danger" v-if="fields.personal.college_committed.required">*</sup></label>
                                                      <div class="form-group" v-if="fields.personal.college_committed.choices">
                                                        <template v-for="(choice, key) in fields.personal.college_committed.choices">
                                                          <input type="radio" :id="choice.value" v-model="college_status" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                                                          <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                                                        </template>
                                                        <span class="text-sm text-danger">{{ errors.college_committed}}</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div :class="[fields.personal.college_committed.yes_fields.college_list.colClass]" v-if="isChild && fields.personal.college_committed.yes_fields.college_list && college_status == 1">
                                                    <div class="form-group">
                                                      <label for="college_list" class="form-label" v-if="fields.personal.college_committed.yes_fields.college_list.label !=''">{{ fields.personal.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="fields.personal.college_committed.yes_fields.college_list.required">*</sup></label>
                                                      <Select2 id="college_list" v-model="verifyData.members.college_committed" :options="collegeOptions" name="form.college_list" :settings="{ placeholder: 'Click to Select the College'}" />
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </b-modal>
                                        <b-modal ok-only v-show="addons && registerationStep2 == true && (membership.member_type_id != 4)"  ref="modal"
                                               @show="resetModal"
                                               ok-title="Update"
                                               @hidden="resetModal" @ok="handleAddonsEditOk" id="modal-addons-edit" size="lg" v-if="addons" centered :title="fields.addons.sub_title || 'Add Ons Information'">
                                        <div class="popupbody" v-if="addons">
                                          <div class="row" v-if="addons && fields.addons.title">
                                            <div class="col-md-4" v-if="fields.addons.title">
                                              <div class="form-group">
                                                <label for="title" class="form-label">{{ fields.personal.title.label }}<sup class="text-danger" v-if="fields.personal.title.required">*</sup></label>
                                                <!-- <select name="verifyPersonal.title" class="form-control" v-model="verifyData.members.title">
                                                    <option v-for="(item, k) in fields.personal.title.options" :value="item" :key="'title-'+k">{{ item }}</option>
                                                </select> -->
                                                <input type="text" class="form-control" id="title" v-model="verifyData.members.title" name="verifyPersonal.title" value="">
                                                <span class="text-sm text-danger">{{ errors.title}}</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="row" v-if="addons && fields.addons.alfa_donation && verifyData.addons!=undefined">
                                              <h4 class="subtitle">{{ fields.addons.alfa_donation.title || 'Donations'}}</h4>
                                              <div  class="imgwithinline">
                                                <img v-if="fields.addons.alfa_donation.banner && fields.addons.alfa_donation.banner.element && fields.addons.alfa_donation.banner.element == 'img'" :src="fields.addons.alfa_donation.banner.attributes.src" :class="fields.addons.alfa_donation.banner.class">
                                                <div class="form-text">
                                                  <span v-html="fields.addons.alfa_donation.content"></span>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <div :class="[fields.addons.alfa_donation.colClass]">
                                                  <div class="form-group">
                                                    <label for="alfa_donation" class="form-label">{{ fields.addons.alfa_donation.label }}</label>
                                                    <div class="form-group" v-if="fields.addons.alfa_donation.choices">
                                                      <template v-for="(choice, key) in fields.addons.alfa_donation.choices">
                                                        <div class="inl-item" :key="'alfa_donation_br_'+key">
                                                        <input type="radio" :id="choice.value" v-model="verifyData.donation_amount" name="form.alfa_donation" :value="choice.value" @click="CheckDonation(choice.value)" :key="'radio_' + key">
                                                        <label class="alfa_donation-label-span" :for="choice.value" :key="'ncsa_label_'+key">{{ choice.label }}</label>
                                                        <br :key="'alfa_donation_br_'+key">
                                                        </div>
                                                      </template>
                                                      <input class="custom-input" v-if="donationInputSelected=='null'" v-show="donationInputSelected=='null'" :key="'alfa_donation_input_'+key" type="text" v-model="verifyData.added_donation_amount">
                                                      <span class="text-sm text-danger">{{ errors.alfa_donation}}</span>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                            <div class="row" v-if="addons && fields.addons.agl_package && verifyData.addons!=undefined && getSiteProps('general.addons_enable')">
                                              <h4 class="subtitle">{{ fields.addons.agl_package.title || 'AGL Package'}}</h4>
                                              <div class="imgwithinline">
                                                <img v-if="fields.addons.agl_package.banner && fields.addons.agl_package.banner.element && fields.addons.agl_package.banner.element == 'img'" :src="fields.addons.agl_package.banner.attributes.src" :class="fields.addons.agl_package.banner.class">
                                                <div class="form-text mb-2">
                                                  <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla dignissim efficitur. Cras vehicula, massa nec facilisis aliquet, velit libero varius ex, id pretium risus quam eu eros. Cras at fermentum massa, sit amet sodales elit. Nunc et quam ante. Vestibulum vel molestie metus. Sed ac ultricies mi. Sed varius enim at lobortis condimentum.</p></span>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <div :class="[fields.addons.agl_package.colClass]">
                                                  <div class="form-group">
                                                    <label for="agl_package" class="form-label">{{ fields.addons.agl_package.label }}</label>
                                                    <div class="form-group" v-if="fields.addons.agl_package.choices">
                                                      <div class="row">
                                                        <div class="mb-1" :class="'col-md-2'" v-for="(choice, key) in fields.addons.agl_package.choices" :key="'agl_package_'+key">
                                                          <input type="radio" :id="choice.value" v-model="verifyData.addons.agl_amt" name="form.agl_package" :value="choice.value" :key="'radio_' + key">
                                                          <label class="agl_package-label-span" :for="choice.value" :key="'agl_package_'+key">{{ choice.label }}</label>
                                                        </div>
                                                      </div>
                                                      <span class="text-sm text-danger">{{ errors.agl_package}}</span>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                            <div class="row" v-if="addons && fields.addons.usssa_membership_package && verifyData.addons!=undefined && getSiteProps('general.addons_enable')">
                                              <h4 class="subtitle">{{ fields.addons.usssa_membership_package.title || 'USSSA Membership Package'}}</h4>
                                              <div class="imgwithinline">
                                                <img v-if="fields.addons.usssa_membership_package.banner && fields.addons.usssa_membership_package.banner.element && fields.addons.usssa_membership_package.banner.element == 'img'" :src="fields.addons.usssa_membership_package.banner.attributes.src" :class="fields.addons.usssa_membership_package.banner.class">
                                                <div class="form-text mb-2">
                                                  <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla dignissim efficitur. Cras vehicula, massa nec facilisis aliquet, velit libero varius ex, id pretium risus quam eu eros. Cras at fermentum massa, sit amet sodales elit. Nunc et quam ante. Vestibulum vel molestie metus. Sed ac ultricies mi. Sed varius enim at lobortis condimentum.</p></span>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <div :class="[fields.addons.usssa_membership_package.colClass]">
                                                  <div class="form-group">
                                                    <label for="usssa_membership_package" class="form-label">{{ fields.addons.usssa_membership_package.label }}</label>
                                                    <div class="form-group" v-if="fields.addons.usssa_membership_package.choices">
                                                      <div class="row">
                                                        <div class="mb-1" :class="'col-md-2'" v-for="(choice, key) in fields.addons.usssa_membership_package.choices" :key="'usssa_membership_package_'+key">
                                                          <input type="radio" :id="choice.value" v-model="verifyData.addons.usssa_amt" name="form.usssa_membership_package" :value="choice.value" :key="'radio_' + key">
                                                          <label class="usssa_membership_package-label-span" :for="choice.value" :key="'usssa_membership_package_'+key">{{ choice.label }}</label>
                                                        </div>
                                                      </div>
                                                      <span class="text-sm text-danger">{{ errors.usssa_membership_package}}</span>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                            <div class="row" v-if="addons && fields.addons.team_insurance && verifyData.addons!=undefined && getSiteProps('general.addons_enable')">
                                              <h4 class="subtitle">{{ fields.addons.team_insurance.title || 'USSSA Membership Package'}}</h4>
                                              <!-- <div class="imgwithinline">
                                               <img v-if="fields.team_insurance.banner && fields.team_insurance.banner.element && fields.team_insurance.banner.element == 'img'" :src="fields.team_insurance.banner.attributes.src" :class="fields.team_insurance.banner.class">
                                               <div class="form-text mb-2">
                                                   <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla dignissim efficitur. Cras vehicula, massa nec facilisis aliquet, velit libero varius ex, id pretium risus quam eu eros. Cras at fermentum massa, sit amet sodales elit. Nunc et quam ante. Vestibulum vel molestie metus. Sed ac ultricies mi. Sed varius enim at lobortis condimentum.</p></span>
                                               </div>
                                             </div> -->
                                              <div class="col-md-12">
                                                <div :class="[fields.addons.team_insurance.colClass]">
                                                  <div class="form-group">
                                                    <input type="checkbox" class="" :id="'team-insurance'" v-model="verifyData.addons.insurance_amt" @change="isCheck($event)">
                                                    <label for="team_insurance" class="form-label ml-2">{{ fields.addons.team_insurance.label }}</label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </b-modal>
                                        <b-modal ok-only v-show="registerationStep2 || registerationStep3 || registerationStep4" v-if="membership.member_type_id == 4 && !isTeamRegistration" ref="modal"
                                                  @show="resetModal"
                                                  ok-title="Update"
                                                  @hidden="resetModal" @ok="handleClubEditOk" id="modal-club-edit" size="lg" centered title="Club Information">
                                            <div class="popupbody">
                                                <div class="row">
                                                    <div class="col-md-6" v-if="fields.club_info && fields.club_info.clubname">
                                                        <div class="form-group">
                                                            <label for="clubname" class="form-label">Club Name  <sup class="text-danger">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="clubname" placeholder="Enter Club Name" v-model="verifyClubInfoEditData.name" name="verifyClubInfoData.name">
                                                            <span class="text-sm text-danger">{{ errors.name }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.club_info && fields.club_info.primary_club_type">
                                                        <div class="form-group">
                                                            <label for="clubname"  class="form-label">Club Type</label><br>
                                                            <select name="club-type" class="form-control" v-model="verifyClubInfoEditData.type">
                                                                <option value="">Select</option>
                                                                <option v-for="(typ, val) in clubType" :value="val" :key="val" :selected="verifyClubInfoEditData.type == val">
                                                                    {{ typ }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_website">
                                                        <div class="form-group">
                                                        <label class="form-label" for="clubwebsite">Club Website</label>
                                                        <input
                                                            type="text"
                                                            id="clubwebsite"
                                                            v-model="verifyClubInfoEditData.website"
                                                            placeholder="clubnew.com"
                                                            class="form-control"
                                                        />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_visible_type">
                                                        <div class="form-group">
                                                            <label for="club_visible_type" class="control-label">{{fields.club_info.club_visible_type.label || 'Club Type'}}<sup class="text-danger" v-if="fields.club_info.club_visible_type.required">*</sup></label>
                                                            <select name="verifyClubInfoEditData.club_visible_type" id="club_visible_type" class="form-control" v-model="verifyClubInfoEditData.visibility_type">
                                                                <option value="">Select</option>
                                                                <option v-for="(item, k) in fields.club_info.club_visible_type.choices" :key="'club_visible_type'+k">{{ item }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.club_visible_type }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.club_info && fields.club_info.club_type">
                                                        <div class="form-group">
                                                            <label for="club_type" class="control-label">{{fields.club_info.club_type.label || 'Club Type'}}<sup class="text-danger" v-if="fields.club_info.club_type.required">*</sup></label>
                                                            <Select2 v-if="fields.club_info.club_type.multiple" v-model="verifyClubInfoEditData.club_type" name="form.club_type" id="club_type" :options="fields.club_info.club_type.choices" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                                            <select name="form.club_type" id="club_type" class="form-control" v-model="verifyClubInfoEditData.club_type" v-else>
                                                                <option value="">Select</option>
                                                                <option v-for="(item, k) in fields.club_info.club_type.choices" :key="'club_type'+k">{{ item }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.club_type }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_discipilines">
                                                        <div class="form-group discipilineitem">
                                                            <label for="" class="form-label">{{ fields.club_info.club_discipilines.label || 'Club Disciplines' }}<sup class="text-danger" v-if="fields.club_info.club_discipilines">*</sup></label>
                                                            <div class="row mb-2">
                                                                <div class="col-md-12">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input club_discipilines_check" type="checkbox" id="verify-clubdisciplines-all"
                                                                        name="verify-club_disciplines_all" @change="verifyTriggerSelectDisciplinesAll">
                                                                        <label class="d-flex mr-1" for="verify-clubdisciplines-all">Select all that apply</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-4" v-for="(name, id) in clubDisciplinesList" :key="'cd_'+id">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input club_discipilines_check" type="checkbox" :id="'verify-clubdisciplines-'+id" name="verifyClubInfoEditData.club_disciplines" :value="id" v-model="verifyClubInfoEditData.club_disciplines">
                                                                        <label class="d-flex mr-1" :for="'verify-clubdisciplines-'+id">{{ name }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span class="text-sm text-danger">{{ errors.club_discipilines }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_admin_member_id">
                                                        <div class="form-group">
                                                            <label for="v-clubname" class="form-label">{{ fields.club_info.club_admin_member_id.label }}  <sup class="text-danger" v-if="fields.club_info.club_admin_member_id.required">*</sup></label>
                                                            <div class="input-group">
                                                                <input type="number" class="form-control" maxlength="45" id="v-clubname" aria-describedby="club_admin_member_id" name="v-club_admin_member_id" v-model="verifyClubInfoEditData.club_admin_member_id">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-primary" type="button" @click="searchMember"><i class="fa fa-search"></i> Search</button>
                                                                </div>
                                                            </div>
                                                            <span class="text-sm text-danger">{{ errors.club_admin_member_id }}</span>
                                                        </div>
                                                        <label class="text-danger" v-if="clubAdminSearch.searched && verifyClubInfoEditData.club_admin_name == ''">No Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
                                                    </div>
                                                    <div class="col-md-12" v-if="fields.club_info && fields.club_info.club_admin_name">
                                                        <div class="form-group">
                                                            <label for="clubname" class="form-label">{{ fields.club_info.club_admin_name.label }}  <sup class="text-danger" v-if="fields.club_info.club_admin_name.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_name" name="form.club_admin_name" v-model="verifyClubInfoEditData.club_admin_name" :readonly="fields.club_info.club_admin_name.readonly" :placeholder="fields.club_info.club_admin_name.placeholder">
                                                            <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-modal>
                                        <b-modal id="edit-club-contact" ok-only v-show="registerationStep2 || registerationStep3 || registerationStep4"
                                        v-if="membership.member_type_id == 4 && !isTeamRegistration" size="lg" ok-title="Update" @ok="updateClubContact"
                                        centered title="Contact Information" >
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-md-12" v-if="fields.club_contact.email">
                                                        <div class="form-group">
                                                            <label for="email" class="form-label">{{ fields.club_contact.email.label }} <sup class="text-danger" v-if="fields.club_contact.email.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" id="email" v-model="verifyClubInfoEditData.email" name="form.email">
                                                            <span class="text-sm text-danger">{{ errors.email}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12" v-if="fields.club_contact.retype_email">
                                                        <div class="form-group">
                                                            <label for="retype_email" class="form-label">{{ fields.club_contact.retype_email.label }} <sup class="text-danger" v-if="fields.club_contact.retype_email.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="verifyClubInfoEditData.retype_email" name="form.retype_email">
                                                            <span class="text-sm text-danger">{{ errors.retype_email}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" v-if="fields.club_contact.club_address">
                                                        <div class="form-group">
                                                            <label for="firstname" class="form-label">{{ fields.club_contact.club_address.label }} <sup class="text-danger" v-if="fields.club_contact.club_address.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="100" id="club_address" v-model="verifyClubInfoEditData.primary_contact.address1" name="form.club_address">
                                                            <span class="text-sm text-danger">{{ errors.club_address }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.club_contact.club_city">
                                                        <div class="form-group">
                                                            <label for="club_city" class="form-label">{{ fields.club_contact.club_city.label }} <sup class="text-danger" v-if="fields.club_contact.club_city.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" id="club_city"  v-model="verifyClubInfoEditData.primary_contact.city" name="form.club_city">
                                                            <span class="text-sm text-danger">{{ errors.club_city }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.club_contact.club_state">
                                                        <div class="form-group">
                                                            <label for="club_state" class="form-label">{{ fields.club_contact.club_state.label }} <sup class="text-danger" v-if="fields.club_contact.club_state.required">*</sup></label>
                                                            <select class="form-control" id="club_state" v-model="verifyClubInfoEditData.primary_contact.state_code" name="form.club_state" >
                                                                <option value="">Select</option>
                                                                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.club_state }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.club_contact.club_zip">
                                                        <div class="form-group">
                                                            <label for="club_zip" class="form-label">{{ fields.club_contact.club_zip.label }} <sup class="text-danger" v-if="fields.club_contact.club_zip.required">*</sup></label>
                                                            <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="club_zip" v-model="verifyClubInfoEditData.primary_contact.zip" name="form.club_zip">
                                                            <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" v-if="fields.club_contact.club_physical_address">
                                                        <div class="form-group">
                                                            <label for="club_physical_address" class="form-label">{{ fields.club_contact.club_physical_address.label }} <sup class="text-danger" v-if="fields.club_contact.club_physical_address.required">*</sup></label>
                                                            <div class="form-check mt-2 mb-3" v-if="fields.club_contact.club_physical_address.same_check">
                                                                <input type="checkbox" id="v-same_as_mailing_address" name="verifyForm.same_as_mailing_address" class="form-check-input" value="1" v-model="verifyClubInfoEditData.same_as_mailing_address" @change="changeSameAddress">
                                                                <label for="v-same_as_mailing_address" class="d-flex mr-1">Same as Mailing Address</label>
                                                            </div>
                                                            <input type="text" class="form-control" maxlength="100" id="club_physical_address" v-model="verifyClubInfoEditData.primary_contact.physical_address" name="form.club_physical_address" :readonly="verifyClubInfoEditData.same_as_mailing_address">
                                                            <span class="text-sm text-danger">{{ errors.club_physical_address }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.club_contact.club_physical_city">
                                                        <div class="form-group">
                                                            <label for="club_physical_city" class="form-label">{{ fields.club_contact.club_physical_city.label }} <sup class="text-danger" v-if="fields.club_contact.club_physical_city.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" id="club_physical_city"  v-model="verifyClubInfoEditData.primary_contact.physical_city" name="form.club_physical_city" :readonly="verifyClubInfoEditData.same_as_mailing_address">
                                                            <span class="text-sm text-danger">{{ errors.club_physical_city }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.club_contact.club_physical_state">
                                                        <div class="form-group">
                                                            <label for="club_physical_state" class="form-label">{{ fields.club_contact.club_physical_state.label }} <sup class="text-danger" v-if="fields.club_contact.club_physical_state.required">*</sup></label>
                                                            <select class="form-control" id="club_physical_state" v-model="verifyClubInfoEditData.primary_contact.physical_state_code" name="form.club_physical_state" :disabled="verifyClubInfoEditData.same_as_mailing_address">
                                                                <option value="">Select</option>
                                                                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.club_physical_state }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.club_contact.club_physical_zip">
                                                        <div class="form-group">
                                                            <label for="club_physical_zip" class="form-label">{{ fields.club_contact.club_physical_zip.label }} <sup class="text-danger" v-if="fields.club_contact.club_physical_zip.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="15" id="club_physical_zip" v-model="verifyClubInfoEditData.primary_contact.physical_zip" name="form.club_physical_zip" :readonly="verifyClubInfoEditData.same_as_mailing_address">
                                                            <span class="text-sm text-danger">{{ errors.club_physical_zip }}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12" v-if="fields.club_contact.mobilephone">
                                                            <div class="form-group ">
                                                                <div class="phone-nu-wrapper">
                                                                    <label for="country_code_h" class="form-label">{{ fields.club_contact.mobilephone.label }} <sup class="text-danger" v-if="fields.club_contact.mobilephone.required">*</sup></label>
                                                                    <vue-tel-input v-model="verifyClubInfoEditData.primary_contact.phone_1" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                                                    <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </b-modal>

                                    <div v-show="registerationStep2" class="col-md-12">
                                      <b-overlay :show="showLoader" no-wrap></b-overlay>
                                        <div v-if="this.membership.member_type_id == 4 && !this.isTeamRegistration" class="row verify-view mb-verify-view">
                                            <div class="col-md-6">
                                                <div class="card-body card">
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <h3>Membership Type</h3>
                                                        </div>
                                                        <!--<div class="col-md-3 text-right">
                                                         <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>
                                                        </div>-->
                                                    </div>
                                                    <span class="Individual" >{{ membership.type | filterBr }}</span>
                                                </div>
                                                <div class="card-body card">
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <h3>Club Information</h3>
                                                        </div>
                                                        <div class="col-md-3 text-right">

                                                            <div class="modal-btn mb-modal-btn">
                                                                <b-button v-b-modal.modal-club-edit @click.prevent="personalEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="row" v-if="subdomain == 'shooting'">
                                                        <div class="col-md-6">
                                                            <label>{{ fields.club_info.clubname.label || 'Club Name' }}</label>
                                                            <span>{{ verifyClubInfoData.name }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.club_website.label || 'Club Website'}}</label>
                                                            <span>{{ verifyClubInfoData.web }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.club_visible_type.label || 'Type'}}</label>
                                                            <span>{{ verifyClubInfoData.visibility_type }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.club_type.label || 'Club Type'}}</label>
                                                            <span>{{ verifyClubInfoData.club_type }}</span>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label>{{fields.club_info.club_discipilines.label || 'Club Disciplines'}}</label>
                                                            <ul v-if="verifyClubInfoData.club_disciplines">
                                                                <li v-for="(item, k) in verifyClubInfoData.club_disciplines" :key="'cb-'+k">{{ clubDisciplinesList[item] }}</li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-6" v-if="verifyClubInfoData.club_admin_member_id">
                                                            <label>Club Admin Member ID</label>
                                                            <span>{{ verifyClubInfoData.club_admin_member_id }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Club Admin Name</label>
                                                            <span>{{ verifyClubInfoData.club_admin_name }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-else>
                                                        <div class="col-md-6">
                                                            <label>{{ membership.type }}</label>
                                                            <span>{{ verifyClubInfoData.name }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Club Type</label>
                                                            <span>{{ clubType[verifyClubInfoData.type] }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="card-body card" v-if="verifyClubInfoData.team_sites">

                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <h3>Site Information</h3>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-for="(sites,i) in verifyClubInfoData.team_sites" :key="i">
                                                        <div class="col-md-12">
                                                            <label>Specific site/body of water of all club activities</label>
                                                            <span>{{ sites.school}}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Mailing Address</label>
                                                            <span>{{ sites.address || "N/A" }}</span>
                                                        </div>
                                                      <div class="col-md-6">
                                                        <label>Address 2</label>
                                                        <span>{{ sites.address2 || "N/A" }}</span>
                                                      </div>
                                                        <div class="col-md-6">
                                                            <label>City</label>
                                                            <span>{{ sites.city || "N/A"}}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>State</label>
                                                            <span v-if="sites.country == 'USA'">{{ sites.state_code  || 'N/A'}}</span>
                                                            <span v-else>{{ sites.state || "N/A"}}</span>
                                                        </div>
                                                        <!-- <div class="col-md-6">
                                                            <label>Country</label>
                                                            <span>{{ sites.country || "N/A"}}</span>
                                                        </div> -->
                                                        <div class="col-md-6">
                                                            <label>Zip</label>
                                                            <span>{{ sites.zip || "N/A"}}</span>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                </div>
                                                <div class="card-body card" v-if="subdomain == 'shooting' && verifyClubInfoData.primary_contact">
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <h3>Contact Information</h3>
                                                        </div>
                                                        <div class="col-md-3 text-right">
                                                            <div class="modal-btn mb-modal-btn">
                                                                <b-button v-b-modal.edit-club-contact>
                                                                    <img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                             <label>E-Mail</label>
                                                            <span>{{ verifyClubInfoData.email }}</span>
                                                        </div>
                                                        <div class="col-md-12">
                                                             <label>Mailing Address</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.address1 }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>City</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.city }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>State</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.state || verifyClubInfoData.primary_contact.state_code }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Country</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.country }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Zip/Postal Code</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.zip }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3" v-if="verifyClubInfoData.primary_contact.physical_address">
                                                        <div class="col-md-12">
                                                             <label>Physical Address</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.physical_address }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>City</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.physical_city || 'N/A' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>State</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.physical_state || verifyClubInfoData.primary_contact.physical_state_code || 'N/A' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Country</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.country || 'N/A' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Zip/Postal Code</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.physical_zip || 'N/A' }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                             <label>Phone Number</label>
                                                            <span>{{ verifyClubInfoData.primary_contact.phone_1 }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="this.membership.member_type_id != 4 || (this.membership.member_type_id == 4 && this.isTeamRegistration)" class="row verify-view mb-verify-view">
                                            <div class="col-md-6 mb-view-pl mb-view-pr">
                                                <div class="card-body card">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <h3>Membership Type</h3>
                                                    </div>
                                                    <!--<div class="col-md-3 text-right">
                                                     <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>
                                                    </div>-->
                                                </div>
                                                    <span class="Individual" >{{ verifyEditData.membership_type }} Membership</span>
                                                </div>
                                                <div class="card-body card">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <h3 v-if="registerationStep2 && fields.personal.sub_title">{{ fields.personal.sub_title }}</h3>
                                                        <h3 v-else>{{ registerationStep2 && fields.personal.tab_title ? fields.personal.tab_title : 'Personal Information' }}</h3>
                                                    </div>
                                                    <div class="col-md-3 text-right">

                                                <div class="modal-btn mb-modal-btn">
                                                <b-button v-b-modal.modal-personal-edit @click="resetFormData()"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                                </div>

                                                    </div>
                                                </div>
                                                    <div class="row">
                                                        <div class="col-md-6 col-sm-6" v-if="registerationStep2 && fields.personal && fields.personal.title">
                                                             <label>Title</label>
                                                             <span>{{ tempverifyData.members.title || 'N/A' }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6">
                                                             <label>First Name</label>
                                                             <span>{{ tempverifyData.members.first_name }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6">
                                                             <label>Middle Initial</label>
                                                             <span>{{ tempverifyData.members.middle_name || 'N/A' }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6">
                                                             <label>Last Name</label>
                                                             <span>{{ tempverifyData.members.last_name }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="!isChild">
                                                             <label>Suffix</label>
                                                             <span>{{ tempverifyData.members.suffix || 'N/A' }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6">
                                                            <label>
                                                                 <!-- <img v-bind:src="'/dist/img/memberregistration/birthday.png'" alt="birthday_icon"> -->
                                                                 {{ registerationStep2 && fields.personal.birthday.label ? fields.personal.birthday.label : 'Birthday' }}
                                                            </label>
                                                             <span>{{ tempverifyData.members.dob }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="!isChild">
                                                            <label>
                                                                <!-- <img v-bind:src="'/dist/img/memberregistration/gender.png'" alt="gender_icon"> -->
                                                                Gender
                                                            </label>
                                                             <span>{{ tempverifyData.members.gender }}</span>
                                                        </div>
                                                        <template v-if="registerationStep2 && fields.general.sport_discipline && fields.general.sport_discipline.home_federation">
                                                         <div v-if="verifyEditData.members.home_federation" class="col-md-6 col-sm-6">
                                                             <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="cityzenship_icon">Federation</label>
                                                             <span>{{ tempverifyData.members.home_federation }}</span>
                                                        </div>
                                                         <div v-if="verifyEditData.members.home_federation != 'USA' && verifyEditData.members.skier_license" class="col-md-6">
                                                             <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="relationship">Foreign Federation ID</label>
                                                             <span>{{ tempverifyData.members.skier_license }}</span>
                                                        </div>
                                                        </template>
                                                        <div v-if="verifyEditData.members.veteran_status" class="col-md-6 col-sm-6">
                                                             <label>{{ registerationStep2 && fields.personal.veteran_status && fields.personal.veteran_status.label ? fields.personal.veteran_status.label : 'Veteran Status' }}</label>
                                                             <span>{{ tempverifyData.members.veteran_status }}</span>
                                                        </div>
                                                         <div v-if="verifyEditData.affiliate && !isTeamRegistration" class="col-md-6">
                                                             <label>{{ subdomain != 'shooting' ? 'Affiliated Clubs/Teams' : 'Club / Team' }}</label>
                                                             <span>{{ tempverifyData.affiliate }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="verifyEditData.disciplines != undefined && registerationStep2 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.first_choice && !isTeamRegistration && !(subdomain=='shooting' && membership.member_type_id == 7)">
                                                            <label>{{ registerationStep2 && fields.general.sport_discipline.first_choice && fields.general.sport_discipline.first_choice.label ? fields.general.sport_discipline.first_choice.label : 'Disciplines of Interest' }}</label>
                                                            <ul class="pl-3" v-if="verifyEditData.disciplines.length">
                                                                <li v-for="(item, i) in verifyEditData.disciplines" :key="'discipline-show'+i">
                                                                    {{ item.name }}
                                                                </li>
                                                            </ul>
                                                            <span v-else>{{ tempverifyData.members.first_choice_txt }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="verifyEditData.disciplines != undefined && registerationStep2 && fields.general && fields.general.sport_discipline && fields.general.sport_discipline.second_choice">
                                                            <template v-if="verifyEditData.disciplines.length || verifyEditData.members.second_choice_txt">
                                                                <label>{{ registerationStep2 && fields.general.sport_discipline.second_choice && fields.general.sport_discipline.second_choice.label ? fields.general.sport_discipline.second_choice.label : 'Disciplines of Interest' }}</label>
                                                                <ul class="pl-3" v-if="verifyEditData.disciplines.length">
                                                                    <li v-for="(item, i) in verifyEditData.disciplines" :key="'discipline-show'+i">
                                                                        {{ item.name }}
                                                                    </li>
                                                                </ul>
                                                                <span v-else>{{ tempverifyData.members.second_choice_txt || "N/A" }}</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-show="verifyEditData.club == '-' ">
                                                        <div class="col-md-12 mt-3">
                                                            <h3>New Club Information</h3>
                                                        </div>
                                                        <div v-if="verifyEditData.new_club_name" class="col-md-6">
                                                             <label>Club Name</label>
                                                             <span>{{ verifyEditData.new_club_name }}</span>
                                                        </div>
                                                        <div v-if="verifyEditData.new_club_email" class="col-md-6">
                                                             <label>Club E-Mail</label>
                                                             <span>{{ verifyEditData.new_club_email }}</span>
                                                        </div>
                                                        <div v-if="verifyEditData.new_club_phone" class="col-md-6">
                                                             <label>Club Phone</label>
                                                             <span>{{ verifyEditData.new_club_phone }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="isChild && isTeamRegistration ||(isTeamRegistration && this.membership.id ==5)">
                                                      <div class="col-md-6 col-sm-6" v-if="fields.personal.agl_player_id && isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.agl_player_id && fields.personal.agl_player_id.label ? fields.personal.agl_player_id.label : 'AGL Player Id' }}</label>
                                                        <span v-if="verifyEditData.members.agl_player_id!=undefined">{{ verifyEditData.members.agl_player_id }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.team_membership_id && fields.personal.team_membership_id.label ? fields.personal.team_membership_id.label : 'Team' }}</label>
                                                        <span >{{ verifyEditData.club_name }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.graduation_year && fields.personal.graduation_year.label ? fields.personal.graduation_year.label : 'Graduation Year' }}</label>
                                                        <span >{{ verifyEditData.members.graduation_year }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.jersey_number.label ? fields.personal.jersey_number.label : 'Jersey Number' }}</label>
                                                        <span>{{ verifyEditData.members.jersey_number }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.primary_position && fields.personal.primary_position.label ? fields.personal.primary_position.label : 'Primary Position' }}</label>
                                                        <span>{{ verifyEditData.members.primary_position }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.secondary_position && fields.personal.secondary_position.label ? fields.personal.secondary_position.label : 'Secondary Position' }}</label>
                                                        <span>{{ verifyEditData.members.secondary_position }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.bats && fields.personal.bats.label ? fields.personal.bats.label : 'Bats' }}</label>
                                                        <span>{{ verifyEditData.members.bats==1?'Left':'Right' }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{ registerationStep2 && isTeamRegistration && fields.personal.throw && fields.personal.throw.label ? fields.personal.throw.label : 'Throw' }}</label>
                                                        <span>{{ verifyEditData.members.throws==1?'Left':'Right' }}</span>
                                                      </div>
                                                      <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && this.membership.id !=5">
                                                        <label>{{registerationStep2 && isTeamRegistration && fields.personal.college_committed && fields.personal.college_committed.label ? fields.personal.college_committed.label : 'College Committed' }}</label>
                                                        <span v-if="verifyEditData.members!=undefined && verifyEditData.members.college_details != undefined">{{ verifyEditData.members.college_details.name || 'NA' }}</span>
                                                        <span v-else>NA</span>
                                                      </div>
                                                    </div>

                                                </div>
                                                <div class="card-body card"  v-if="!isChild && addons && this.membership.id !=5">

                                                <div class="row">
                                                  <div class="col-md-9">
                                                    <h3>{{ registerationStep2 && fields.contact.tab_title ? fields.contact.tab_title : 'Contact Information'}}</h3>
                                                  </div>
                                                  <div class="col-md-3 text-right">
                                                    <div class="modal-btn mb-modal-btn">
                                                      <b-button v-b-modal.modal-coach-contact-edit><img
                                                          v-bind:src="'/dist/img/memberregistration/edit.png'"
                                                          alt="edit_icon"> Edit
                                                      </b-button>
                                                      <b-modal ok-only ref="modal"
                                                               @show="resetModal"
                                                               ok-title="Update"
                                                               @hidden="resetModal"
                                                               @ok="handlePersonalHeadCoachContactEditOk"
                                                               id="modal-coach-contact-edit" size="lg" centered
                                                               :title="fields.contact.tab_title ? fields.contact.tab_title : 'Contact Information'">
                                                        <div class="popupbody">
                                                          <div class="row">
                                                            <div class="col-md-12">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="email"
                                                                       class="form-label">Email <sup
                                                                    class="text-danger">*</sup></label>
                                                                <input type="text"
                                                                       class="form-control"
                                                                       maxlength="45" id="email"
                                                                       v-model="verifyData.head_contact.email"
                                                                       name="verifyData.email">
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.email }}</span>
                                                              </div>
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="retype_email"
                                                                       class="form-label">Retype
                                                                  Email <sup
                                                                      class="text-danger">*</sup></label>
                                                                <input type="text"
                                                                       class="form-control"
                                                                       maxlength="45"
                                                                       id="retype_email"
                                                                       v-model="verifyData.head_contact.retype_email"
                                                                       name="verifyData.retype_email">
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.retype_email }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12" v-if="verifyData.head_contact != undefined && fields.contact.address_type">
                                                              <div class="form-group">
                                                                <label for="address_type" class="form-label">{{ fields.contact.address_type.label }}<sup class="text-danger" v-if="fields.contact.address_type.required">*</sup></label>
                                                                <select name="verifyData.address_type" id="address_type" class="form-control" v-model="verifyData.head_contact.address_type">
                                                                  <option v-for="(item, k) in fields.contact.address_type.options" :key="'address-type-'+k" :value="item.value">{{ item.label }}</option>
                                                                </select>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12" v-if="verifyData.head_contact != undefined">
                                                              <div class="form-group">
                                                                <label for="verifyData_firstname"
                                                                       class="form-label">{{ fields.contact.address1.label }} <sup
                                                                    class="text-danger">*</sup></label>
                                                                <input type="text"
                                                                       class="form-control"
                                                                       maxlength="100"
                                                                       id="verifyData_address1"
                                                                       v-model="verifyData.head_contact.address1"
                                                                       name="verifyData.address1">
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.address1 }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="verifyData_firstname"
                                                                       class="form-label">{{ fields.contact.address2.label }}</label>
                                                                <input type="text"
                                                                       class="form-control"
                                                                       maxlength="45"
                                                                       id="verifyData_address2"
                                                                       v-model="verifyData.head_contact.address2"
                                                                       name="verifyData.address2">
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="Country"
                                                                       class="form-label">Country
                                                                  <sup class="text-danger">*</sup></label>
                                                                <select class="form-control"
                                                                        id="verifyData_country"
                                                                        v-model="verifyData.head_contact.country"
                                                                        @change="removeVerifyCoachStateCode"
                                                                        name="verifyData.country" v-if="!listOfCountryWithLimit">
                                                                  <option
                                                                      v-bind:value="listOfCountry.country"
                                                                      v-for="listOfCountry in listOfCountries"
                                                                      :key="listOfCountry.country"
                                                                      :selected="verifyData.head_contact.country == listOfCountry.country">
                                                                    {{ listOfCountry.country }}
                                                                  </option>
                                                                </select>
                                                                <select class="form-control"
                                                                        id="verifyData_country"
                                                                        v-model="verifyData.head_contact.country"
                                                                        @change="removeVerifyCoachStateCode"
                                                                        name="verifyData.country" v-else>
                                                                  <option
                                                                      v-bind:value="listOfCountry.country"
                                                                      v-for="listOfCountry in fields.contact.country.options"
                                                                      :key="listOfCountry.country"
                                                                      :selected="verifyData.head_contact.country == listOfCountry.country">
                                                                    {{ listOfCountry.country }}
                                                                  </option>
                                                                </select>
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.country }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 "
                                                                 v-if="verifyData.head_contact != undefined && verifyData.head_contact.country == 'USA' ">
                                                              <div class="form-group">
                                                                <label for="state_code"
                                                                       class="form-label">State <sup
                                                                    class="text-danger">*</sup></label>
                                                                <select class="form-control"
                                                                        id="verifyData_state_code"
                                                                        v-model="verifyData.head_contact.state_code"
                                                                        name="verifyData.state_code">
                                                                  <option
                                                                      v-for="listOfState in listOfStates"
                                                                      :key="listOfState.state_code"
                                                                      :value="listOfState.state_code"
                                                                      :selected="verifyData.head_contact.state_code == listOfState.state_code">
                                                                    {{ listOfState.state }}
                                                                  </option>
                                                                </select>
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.state_code }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 "
                                                                 v-else-if="verifyData.head_contact != undefined && verifyData.head_contact.country == 'Canada' ">
                                                              <div class="form-group">
                                                                <label for="state_code"
                                                                       class="form-label">State <sup
                                                                    class="text-danger">*</sup></label>
                                                                <select class="form-control"
                                                                        id="verifyData_state_code"
                                                                        v-model="verifyData.head_contact.state_code"
                                                                        name="verifyData.state_code">
                                                                  <option
                                                                      v-for="listOfState in fields.contact.state_code.option"
                                                                      :key="listOfState.state_code"
                                                                      :value="listOfState.state_code"
                                                                      :selected="verifyData.head_contact.state_code == listOfState.state_code">
                                                                    {{ listOfState.state }}
                                                                  </option>
                                                                </select>
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.state_code }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 " v-else>
                                                              <div class="form-group" v-if="verifyData.head_contact">
                                                                <label for="state_code" class="form-label">State <sup
                                                                    class="text-danger">*</sup></label>
                                                                <select class="form-control"
                                                                        id="verifyData_state_code"
                                                                        v-model="verifyData.head_contact.state_code"
                                                                        name="verifyData.state_code">
                                                                  <option
                                                                      v-for="listOfState in fields.contact.state_code.option"
                                                                      :key="listOfState.state_code"
                                                                      :value="listOfState.state_code"
                                                                      :selected="verifyData.state_code == listOfState.state_code">
                                                                    {{ listOfState.state }}
                                                                  </option>
                                                                </select>
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.state_code }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 ">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="city"
                                                                       class="form-label">City <sup
                                                                    class="text-danger">*</sup></label>
                                                                <input type="text"
                                                                       class="form-control"
                                                                       maxlength="45"
                                                                       id="verifyData_city"
                                                                       v-model="verifyData.head_contact.city"
                                                                       name="verifyData.city">
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.city }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 ">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="zip" class="form-label">Postal
                                                                  Code <sup
                                                                      class="text-danger">*</sup></label>
                                                                <input type="text"
                                                                       class="form-control"
                                                                       :maxlength="getSiteProps('general.zip_code_length')"
                                                                       id="verifyData_zip"
                                                                       v-model="verifyData.head_contact.zip"
                                                                       name="verifyData.zip">
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.zip }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <label for="verifyData_citizenship"
                                                                       class="form-label">Citizenship
                                                                  <sup class="text-danger">*</sup></label>
                                                                <select class="form-control"
                                                                        id="verifyData_citizenship"
                                                                        v-model="verifyData.head_contact.citizenship"
                                                                        name="verifyData.country" v-if="listOfCountryWithLimit">
                                                                  <option
                                                                      v-bind:value="listOfCountry.country"
                                                                      v-for="listOfCountry in listOfCountries"
                                                                      :key="listOfCountry.country"
                                                                      :selected="verifyData.citizenship == listOfCountry.country">
                                                                    {{ listOfCountry.country }}
                                                                  </option>
                                                                </select>
                                                                <span
                                                                    class="text-sm text-danger">{{ errors.citizenship }}</span>
                                                              </div>
                                                            </div>
                                                            <template v-if="verifyData.head_contact != undefined && verifyData.address_type == 'Work'">
                                                              <div :class="[fields.contact.work_address_company_name.colClass]" v-if="fields.contact.work_address_company_name">
                                                                <div class="form-group">
                                                                  <label for="work_address_company_name" class="form-label">{{ fields.contact.work_address_company_name.label }}<sup class="text-danger" v-if="fields.contact.work_address_company_name.required">*</sup></label>
                                                                  <input type="text" class="form-control" id="work_address_company_name" v-model="verifyData.head_contact.company_name">
                                                                  <span class="text-sm text-danger">{{ errors.company_name }}</span>
                                                                </div>
                                                              </div>
                                                              <div :class="[fields.contact.work_address_company_phone_extension.colClass]" v-if="fields.contact.work_address_company_phone_extension">
                                                                <div class="form-group">
                                                                  <label for="work_address_company_phone" class="form-label">{{ fields.contact.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="fields.contact.work_address_company_phone_extension.required">*</sup></label>
                                                                  <div class="row">
                                                                    <div class="col-md-9">
                                                                      <vue-tel-input ref="phone" @validate="validateTelinput" v-model="verifyData.head_contact.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                                                                      <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                                                                    </div>
                                                                    <div class="col-md-3">
                                                                      <input type="text" class="form-control" v-model="verifyData.company_phone_extension" name="verifyData.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                                                                      <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </template>
                                                            <div class="col-md-6 ">
                                                              <div class="form-group " v-if="verifyData.head_contact != undefined">
                                                                <div class="phone-nu-wrapper">
                                                                  <label for="verifyData_phone_1"
                                                                         class="form-label">{{ fields.contact.homephone.label || 'Country Code with Home phone' }} <sup
                                                                      class="text-danger">*</sup></label>
                                                                  <vue-tel-input
                                                                      id="verifyData_phone_1"
                                                                      v-model="verifyData.head_contact.phone_1"
                                                                      @validate="validateTelinput"
                                                                      ref="phone1" maxlength="15"
                                                                      v-bind="homeProps"
                                                                      :validCharactersOnly="true"></vue-tel-input>
                                                                  <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
                                                                   <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                                                                  <span
                                                                      class="text-sm text-danger">{{
                                                                      errors.phone_1
                                                                    }}</span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                              <div class="form-group" v-if="verifyData.head_contact != undefined">
                                                                <div class="phone-nu-wrapper">
                                                                  <label for="verifyData_phone_2"
                                                                         class="form-label">{{ fields.contact.mobilephone.label || 'Country Code with Mobile' }}</label>
                                                                  <vue-tel-input
                                                                      id="verifyData_phone_2"
                                                                      v-model="verifyData.head_contact.phone_2"
                                                                      ref="phone2" maxlength="20"
                                                                      v-bind="mobileProps" @validate="validateTelinput2"
                                                                      :validCharactersOnly="true"></vue-tel-input>
                                                                  <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
                                                                  <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                                                                   <span
                                                                      class="text-sm text-danger">{{
                                                                          errors.phone_2
                                                                      }}</span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </b-modal>
                                                    </div>
                                                    <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>{{ registerationStep2 && fields.contact.homephone.label ? fields.contact.homephone.label : 'Phone' }}</label>
                                                    <span>{{ verifyEditData.head_contact.phone_1 }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>{{ registerationStep2 && fields.contact.mobilephone.label ? fields.contact.mobilephone.label : 'Mobile' }}</label>
                                                    <span>{{ verifyEditData.head_contact.phone_2 }}</span>
                                                  </div>
                                                  <div class="col-md-12" v-if="verifyEditData.head_contact != undefined">
                                                    <label>Email</label>
                                                    <span>{{ verifyEditData.head_contact.email }}</span>
                                                  </div>
                                                  <div class="col-md-12" v-if="verifyEditData.head_contact != undefined" v-show="verifyEditData.head_contact && verifyEditData.head_contact.address_type">
                                                    <label>Address Type</label>
                                                    <span>{{ verifyEditData.head_contact.address_type }} Address</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>{{ registerationStep2 && fields.contact.address1.label ? fields.contact.address1.label : 'Mailing Address' }}</label>
                                                    <span>{{ verifyEditData.head_contact.address1 }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>{{ registerationStep2 && fields.contact.address2.label ? fields.contact.address2.label : 'Address 2' }}</label>
                                                    <span>{{ verifyEditData.head_contact.address2 }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>City</label>
                                                    <span>{{ verifyEditData.head_contact.city }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>State</label>
                                                    <span v-if="verifyEditData.head_contact != undefined && (verifyEditData.head_contact.country == 'USA' || verifyEditData.head_contact.country == 'Canada')">{{
                                                        verifyEditData.head_contact.state_code
                                                      }}</span>
                                                    <span v-else>{{ verifyEditData.head_contact.state }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>Postal Code</label>
                                                    <span>{{ verifyEditData.head_contact.zip }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>Country</label>
                                                    <span>{{ verifyEditData.head_contact.country }}</span>
                                                  </div>
                                                  <div class="col-md-6" v-if="verifyEditData.head_contact != undefined">
                                                    <label>Citizenship</label>
                                                    <span>{{ verifyEditData.head_contact.citizenship }}</span>
                                                  </div>
                                                  <template v-if="verifyEditData.head_contact != undefined && verifyEditData.head_contact.address_type == 'Work'">
                                                    <div class="col-md-6" v-show="verifyEditData.head_contact.company_name">
                                                      <label>Company Name</label>
                                                      <span>{{ verifyEditData.head_contact.company_name }}</span>
                                                    </div>
                                                    <div class="col-md-6" v-if="verifyEditData.head_contact != undefined" v-show="verifyEditData.head_contact && verifyEditData.head_contact.company_phone">
                                                      <label>Company Phone</label>
                                                      <span>{{ verifyEditData.head_contact.company_phone }} | Ext: {{ verifyEditData.head_contact.company_phone_extension }}</span>
                                                    </div>
                                                  </template>
                                                </div>
                                              </div>
                                              <div class="card-body card" v-if="addons && isTeamRegistration">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <h3 v-if="addons && registerationStep2">{{ fields.addons.sub_title || 'Add Ons Information' }}</h3>
                                                    </div>
                                                    <div class="col-md-3 text-right">

                                                <div class="modal-btn mb-modal-btn">
                                                <b-button v-b-modal.modal-addons-edit @click.prevent="addonsEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                                </div>

                                                    </div>
                                                </div>
                                                    <div class="row" v-if="isTeamRegistration && addons">
                                                        <div class="col-md-6 col-sm-6" v-if="registerationStep2 && fields.addons.alfa_donation && fields.addons.alfa_donation.title">
                                                             <label>{{ fields.addons.alfa_donation.label }}</label>
                                                             <span>${{ donated.amount || 0 | fullNumber}}</span>
                                                        </div>
                                                      <div class="col-md-6 col-sm-6" v-for="(value, index) in verifyData.addons" v-bind:key="index" >
                                                          <div  v-if="verifyData.addons != undefined && verifyData.addons.length > 0 && registerationStep2">
                                                          <div  v-if="registerationStep2 && fields.addons.agl_package && fields.addons.agl_package.title">
                                                            <label v-if="value.addon_id == 1">{{ fields.addons.agl_package.label }}</label>
                                                            <label v-if="value.addon_id == 2">{{ fields.addons.usssa_membership_package.label }}</label>
                                                            <label v-if="value.addon_id == 3">{{ fields.addons.team_insurance.label }}</label>
                                                            <span v-if="value.addon_id == 1 && value.amount">{{ value.amount != 0? "Yes":"No" }}</span>
                                                            <span v-if="value.addon_id == 2 && value.amount">{{ value.amount != 0? "Yes":"No" }}</span>
                                                            <span v-if="value.addon_id == 3 && value.amount">{{ value.amount != 0? "Yes":"No" }}</span>
                                                        </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="card-body card" v-if="!isTeamRegistration">

                                                     <div class="row">
                                                    <div class="col-md-9">
                                                       <h3>{{ registerationStep2 && fields.demographic.tab_title ? fields.demographic.tab_title : 'Demographic Information'}}</h3>
                                                    </div>
                                                    <div class="col-md-3 text-right">
                                            <div class="modal-btn mb-modal-btn">
                                                <b-button v-b-modal.modal-demographic-edit @click.prevent="demographicEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                                <b-modal ref="modal" ok-only
                                                          @show="resetModal"
                                                          ok-title="Update"
                                                          @hidden="resetModal" @ok="handlePersonalDemographicEditOk" id="modal-demographic-edit" size="lg" centered :title="fields.demographic && fields.demographic.tab_title || 'Demographic Information'">
                                                    <div class="popupbody">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group radiotext">
                                                                <label for="race" class="form-label"><strong>{{ fields.demographic.race.label || 'Race'}}</strong><sup class="text-danger">*</sup></label>
                                                                    <div v-for="(diversity,i) in diversities" :key="i">
                                                                            <input type="radio" :id="'diversity'+diversity.name" name="verifyPersonal.diversity_id" :value="diversity.id" v-model="verifyData.diversity_id">
                                                                            <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                                                                    </div>
                                                                    <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="fields.demographic.ethnicity">
                                                                <div class="form-group radiotext ">
                                                                    <label for="ethnicitystatus" class="form-label"><strong>Ethnicity</strong><sup  class="text-danger">*</sup></label>
                                                                    <div class="form-group">

                                                                        <label for="hispanic-or-latino"><input type="radio" id="hispanic-or-latino" name="ethnicity" value="Hispanic or Latino" v-model="verifyData.ethnicity"> Hispanic or Latino</label><br>

                                                                        <label for="non-hispanic-or-latino"> <input type="radio" id="non-hispanic-or-latino" name="ethnicity" value="Non-Hispanic or Latino" v-model="verifyData.ethnicity"> Non-Hispanic or Latino</label><br>

                                                                        <label for="ethnicity-Prefer-not-to-say"> <input type="radio" id="ethnicity-Prefer-not-to-say" name="ethnicity" value="Prefer not to say" v-model="verifyData.ethnicity"> Prefer not to say</label><br>
                                                                        <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="fields.demographic.disability_status">
                                                                <div class="form-group radiotext ">
                                                                    <label for="disabilitystatus" class="form-label"><strong>Disability Status</strong>  <sup  class="text-danger">*</sup></label>
                                                                    <div class="form-group">

                                                                        <label for="normal"> <input type="radio" id="normal" name="verifyPersonal.disability" value="Persons without Disability" v-model="verifyData.disability"> Persons without Disability</label><br>

                                                                        <label for="Persons-with-Disability"><input type="radio" id="Persons-with-Disability" name="verifyPersonal.disability" value="Persons with Disability" v-model="verifyData.disability"> Persons with Disability</label><br>

                                                                        <label for="disability-Prefer-not-to-say"><input type="radio" id="disability-Prefer-not-to-say" name="verifyPersonal.disability" value="Prefer not to say" v-model="verifyData.disability"> Prefer not to say</label><br>
                                                                        <span class="text-sm text-danger">{{ errors.disability }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                             <div class="col-md-6" v-if="fields.demographic.veteran_status" >
                                                                <div class="form-group radiotext " v-if="membership.id !=2 && membership.id !=3">
                                                                    <label for="veteranstatus" class="form-label"><strong>Veteran Status</strong> <sup class="text-danger">*</sup></label>
                                                                    <div class="form-group">
                                                                        <div>

                                                                        <label for="veteran"><input type="radio" id="veteran" name="verifyPersonal.veteran_status" value="Veteran" v-model="verifyData.veteran_status"> Veteran</label><br>
                                                                        </div>
                                                                        <div>

                                                                        <label for="non-Veteran">  <input type="radio" id="non-Veteran" name="verifyPersonal.veteran_status" value="Non-Veteran" v-model="verifyData.veteran_status"> Non-Veteran</label><br>
                                                                        </div>
                                                                        <div>

                                                                        <label for="Active-or-Reserve"> <input type="radio" id="Active-or-Reserve" name="verifyPersonal.veteran_status" value="Active or Reserve" v-model="verifyData.veteran_status"> Active or Reserve</label><br>

                                                                        </div>
                                                                        <div>

                                                                        <label for="vet-prefer-not-to-say"> <input type="radio" id="vet-prefer-not-to-say" name="verifyPersonal.veteran_status" value="Prefer not to say" v-model="verifyData.veteran_status"> Prefer not to say</label><br>
                                                                        </div>

                                                                        <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6" v-if="fields.demographic.primary_area_interest">
                                                                <div class="form-group">
                                                                    <label for="area_of_interest" class="form-label">
                                                                        {{ fields.demographic.primary_area_interest.label }}
                                                                        <sup class="text-danger" v-if="fields.demographic.primary_area_interest.required">*</sup>
                                                                    </label>
                                                                    <select name="verifyPersonal.area_of_interest" id="verify-area_of_interest" class="form-control" v-model="verifyData.members.primary_area_interest">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(item, k) in fields.demographic.primary_area_interest.options" :key="'v-pai'+k" :value="item">{{ item }}</option>
                                                                    </select>
                                                                    <span class="text-sm text-danger">{{ errors.primary_area_interest }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="fields.demographic.category">
                                                                <div class="form-group">
                                                                    <label for="category" class="form-label">{{ fields.demographic.category.label }}<sup class="text-danger" v-if="fields.demographic.category.required">*</sup></label>
                                                                    <select name="verifyPersonal.category" id="verify-category" class="form-control" v-model="verifyData.members.category">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(item, k) in categories" :key="'verify-catd-'+k" :value="item.id">{{ item.name }}</option>
                                                                    </select>
                                                                    <span class="text-sm text-danger">{{ errors.category }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="fields.demographic.issf">
                                                                <div class="form-group">
                                                                    <label for="issf" class="form-label">{{ fields.demographic.issf.label }}<sup class="text-danger" v-if="fields.demographic.issf.required">*</sup></label>
                                                                    <input type="text" class="form-control" name="verifyPersonal.issf" v-model="verifyData.members.issf">
                                                                    <span class="text-sm text-danger">{{ errors.issf }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="fields.demographic.hear_about">
                                                                <div class="form-group">
                                                                    <label for="hear_about" class="form-label">{{ fields.demographic.hear_about.label }} <sup class="text-danger" v-if="fields.demographic.hear_about.required">*</sup></label>
                                                                    <select id="verify_hear_about" class="form-control" name="verifyPersonal.hear_about" v-model="verifyData.members.hear_about">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(item, k) in fields.demographic.hear_about.options" :key="'verify-hear-about-'+k" :value="item">{{ item }}</option>
                                                                    </select>
                                                                    <span class="text-sm text-danger">{{ errors.hear_about }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-modal>
                                                </div>
                                                    <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                                                    </div>
                                                </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                             <label>{{ fields.demographic.race.label || 'Race'}}</label>
                                                             <span class="mb-0"
                                                             v-for="(diversity,i) in diversities" :key="i">
                                                                 <p v-if="diversity.id == verifyEditData.diversity_id">
                                                                 {{diversity.name}}
                                                                 </p>
                                                             </span>
                                                        </div>
                                                        <div class="col-md-6" v-if="fields.demographic.ethnicity">
                                                            <label>{{ fields.demographic.ethnicity.label || 'Ethnicity'}}</label>
                                                            <span>{{ verifyEditData.ethnicity }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="fields.demographic.disability_status">
                                                            <label>{{ fields.demographic.disability_status.label || 'Disability'}}</label>
                                                            <span>{{ verifyEditData.disability }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="fields.demographic.veteran_status">
                                                            <div v-if="membership.id !=2 && membership.id !=3">
                                                                <label>{{ fields.demographic.veteran_status.label || 'Veteran Status'}}</label>
                                                                <span>{{ verifyEditData.veteran_status }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="verifyEditData.members.primary_area_interest">
                                                            <label>Primary Role of Interest</label>
                                                            <span>{{ verifyEditData.members.primary_area_interest }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="verifyEditData.category">
                                                             <label>Category</label>
                                                             <span>{{ verifyEditData.category.name }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="verifyEditData.members.hear_about">
                                                             <label>How did you hear about {{ getSiteProps('general.site_name') }} ?</label>
                                                             <span>{{ verifyEditData.members.hear_about }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="verifyEditData.members.issf">
                                                             <label>ISSF License Certification Number</label>
                                                             <span>{{ verifyEditData.members.issf }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="verifyEditData.members.shirt_size">
                                                             <label>Shirt Size</label>
                                                             <span>{{ verifyEditData.members.shirt_size }}</span>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6" v-if="verifyEditData.members.code_of_conduct">
                                                             <label>Code of Conduct</label>
                                                             <span>{{ verifyEditData.members.code_of_conduct ? 'Yes' : 'No' }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-view-pl mb-view-pr">
                                                <div class="card-body card">

                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <h3>{{isChild && isTeamRegistration && isChildUsers ?'Parent ':'' }} {{!isChild && isTeamRegistration && this.membership.id !=5 ?'Team ':'' }} {{registerationStep2 && fields.contact.tab_title ? fields.contact.tab_title : 'Contact Information'}}</h3>
                                                        </div>
                                                        <div class="col-md-3 text-right">
                                                            <div class="modal-btn mb-modal-btn">
                                                                <b-button v-b-modal.modal-contact-edit @click="parentContactresetData()"><img
                                                                    v-bind:src="'/dist/img/memberregistration/edit.png'"
                                                                    alt="edit_icon"> Edit
                                                                </b-button>
                                                                <b-modal ok-only ref="modal"
                                                                         @show="resetModal"
                                                                         ok-title="Update"
                                                                         @hidden="resetModal"
                                                                         @ok="handlePersonalContactEditOk"
                                                                         id="modal-contact-edit" size="lg" centered
                                                                         :title="fields.contact.tab_title ? fields.contact.tab_title : 'Contact Information'">
                                                                    <div class="popupbody">
                                                                        <div class="row" v-if="isChild && isChildUsers && this.isTeamRegistration && this.membership.id != 5">
                                                                          <div :class="[fields.contact.firstname.colClass]">
                                                                            <div class="form-group">
                                                                              <label class="form-label" v-if="fields.contact.firstname">{{ fields.contact.firstname.label }} <sup class="text-danger">*</sup></label>
                                                                              <input type="text" class="form-control" maxlength="45" name="verifyContact.first_name" v-model="verifyData.first_name">
                                                                              <span class="text-sm text-danger">{{ errors.parent_first_name}}</span>
                                                                            </div>
                                                                          </div>
                                                                          <div :class="[fields.contact.middlename.colClass]">
                                                                            <div class="form-group">
                                                                              <label class="form-label" v-if="fields.contact.middlename">{{ fields.contact.middlename.label }}</label>
                                                                              <input type="text" class="form-control" maxlength="3" name="verifyContact.middle_name" v-model="verifyData.middle_name">
                                                                            </div>
                                                                          </div>
                                                                          <div :class="[fields.contact.lastname.colClass]">
                                                                            <div class="form-group">
                                                                              <label class="form-label" v-if="fields.contact.lastname">{{fields.contact.lastname.label }} <sup class="text-danger">*</sup></label>
                                                                              <input type="text" class="form-control" maxlength="45" name="verifyContact.last_name" v-model="verifyData.last_name">
                                                                              <span class="text-sm text-danger">{{ errors.parent_last_name}}</span>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group" v-if="isChild || !this.isTeamRegistration || (this.isTeamRegistration && this.membership.id == 5)">
                                                                                    <label for="email"
                                                                                           class="form-label">Email <sup
                                                                                        class="text-danger">*</sup></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           maxlength="45" id="email"
                                                                                           v-model="verifyData.email"
                                                                                           name="verifyData.email">
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.email }}</span>
                                                                                </div>
                                                                                <div class="form-group" v-if="isChild || !this.isTeamRegistration || (this.isTeamRegistration && this.membership.id == 5)">
                                                                                    <label for="retype_email"
                                                                                           class="form-label">Retype
                                                                                        Email <sup
                                                                                            class="text-danger">*</sup></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           maxlength="45"
                                                                                           id="retype_email"
                                                                                           v-model="verifyData.retype_email"
                                                                                           name="verifyData.retype_email">
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.retype_email }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12" v-if="fields.contact.address_type">
                                                                                <div class="form-group">
                                                                                    <label for="address_type" class="form-label">{{ fields.contact.address_type.label }}<sup class="text-danger" v-if="fields.contact.address_type.required">*</sup></label>
                                                                                    <select name="verifyData.address_type" id="address_type" class="form-control" v-model="verifyData.address_type">
                                                                                        <option v-for="(item, k) in fields.contact.address_type.options" :key="'address-type-'+k" :value="item.value">{{ item.label }}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12">
                                                                                <div class="form-group">
                                                                                    <label for="verifyData_firstname"
                                                                                           class="form-label">{{ fields.contact.address1.label }} <sup
                                                                                            class="text-danger">*</sup><span v-if="fields.contact.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ fields.contact.address1.charLimitText }}</span></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           :maxlength=fields.contact.address1.charLimitValue
                                                                                           id="verifyData_address1"
                                                                                           v-model="verifyData.address1"
                                                                                           name="verifyData.address1">
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.address1 }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12">
                                                                                <div class="form-group">
                                                                                    <label for="verifyData_firstname"
                                                                                           class="form-label">{{ fields.contact.address2.label }}<span v-if="fields.contact.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ fields.contact.address2.charLimitText }}</span></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           :maxlength=fields.contact.address2.charLimitValue
                                                                                           id="verifyData_address2"
                                                                                           v-model="verifyData.address2"
                                                                                           name="verifyData.address2">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div class="form-group" >
                                                                                    <label for="Country"
                                                                                           class="form-label">Country
                                                                                        <sup class="text-danger">*</sup></label>
                                                                                    <select class="form-control"
                                                                                            id="verifyData_country"
                                                                                            v-model="verifyData.country"
                                                                                            @change="removeVerifyStateCode"
                                                                                            name="verifyData.country" v-if="!listOfCountryWithLimit">
                                                                                        <option
                                                                                            v-bind:value="listOfCountry.country"
                                                                                            v-for="listOfCountry in listOfCountries"
                                                                                            :key="listOfCountry.country"
                                                                                            :selected="verifyData.country == listOfCountry.country">
                                                                                            {{ listOfCountry.country }}
                                                                                        </option>
                                                                                    </select>
                                                                                  <select class="form-control"
                                                                                          id="verifyData_country"
                                                                                          v-model="verifyData.country"
                                                                                          @change="removeVerifyStateCode"
                                                                                          name="verifyData.country" v-else>
                                                                                    <option
                                                                                        v-bind:value="listOfCountry.country"
                                                                                        v-for="listOfCountry in fields.contact.country.options"
                                                                                        :key="listOfCountry.country"
                                                                                        :selected="verifyData.country == listOfCountry.country">
                                                                                      {{ listOfCountry.country }}
                                                                                    </option>
                                                                                  </select>
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.country }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 "
                                                                                 v-if="verifyData.country == 'USA' ">
                                                                                <div class="form-group">
                                                                                    <label for="state_code"
                                                                                           class="form-label">State <sup
                                                                                        class="text-danger">*</sup></label>
                                                                                    <select class="form-control"
                                                                                            id="verifyData_state_code"
                                                                                            v-model="verifyData.state_code"
                                                                                            @change="stateCode"
                                                                                            name="verifyData.state_code">
                                                                                        <option
                                                                                            v-for="listOfState in listOfStates"
                                                                                            :key="listOfState.state_code"
                                                                                            :value="listOfState.state_code"
                                                                                            :selected="verifyData.state_code == listOfState.state_code">
                                                                                            {{ listOfState.state }}
                                                                                        </option>
                                                                                    </select>
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.state_code }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 "
                                                                                 v-else-if="this.isTeamRegistration && verifyData.country == 'Canada' ">
                                                                              <div class="form-group">
                                                                                <label for="state_code"
                                                                                       class="form-label">State <sup
                                                                                    class="text-danger">*</sup></label>
                                                                                <select class="form-control"
                                                                                        id="verifyData_state_code"
                                                                                        v-model="verifyData.state_code"
                                                                                        @change="stateCode"
                                                                                        name="verifyData.state_code">
                                                                                  <option
                                                                                      v-for="listOfState in fields.contact.state_code.option"
                                                                                      :key="listOfState.state_code"
                                                                                      :value="listOfState.state_code"
                                                                                      :selected="verifyData.state_code == listOfState.state_code">
                                                                                    {{ listOfState.state }}
                                                                                  </option>
                                                                                </select>
                                                                                <span
                                                                                    class="text-sm text-danger">{{ errors.state_code }}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div class="col-md-6 "
                                                                                 v-else-if="!this.isTeamRegistration && verifyData.country == 'Canada' ">
                                                                              <div class="form-group">
                                                                                <label for="state"
                                                                                       class="form-label">State <sup
                                                                                    class="text-danger">*</sup></label>
                                                                                <select class="form-control"
                                                                                        id="verifyData_state"
                                                                                        v-model="verifyData.state"
                                                                                        name="verifyData.state">
                                                                                  <option
                                                                                      v-for="listOfState in listOfCAStates"
                                                                                      :key="listOfState.state"
                                                                                      :value="listOfState.state"
                                                                                      :selected="verifyData.state == listOfState.state">
                                                                                    {{ listOfState.state }}
                                                                                  </option>
                                                                                </select>
                                                                                <span
                                                                                    class="text-sm text-danger">{{ errors.state }}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div class="col-md-6" v-else>
                                                                                <div class="form-group">
                                                                                    <label for="state_code"
                                                                                           class="form-label">Other
                                                                                        State <sup
                                                                                            class="text-danger">*</sup></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           maxlength="45"
                                                                                           v-model="verifyData.state"
                                                                                           name="verifyData.state">
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.state }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 ">
                                                                                <div class="form-group">
                                                                                    <label for="city"
                                                                                           class="form-label">City <sup
                                                                                        class="text-danger">*</sup></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           maxlength="45"
                                                                                           id="verifyData_city"
                                                                                           v-model="verifyData.city"
                                                                                           name="verifyData.city">
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.city }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 ">
                                                                                <div class="form-group">
                                                                                    <label for="zip" class="form-label">Postal
                                                                                        Code <sup
                                                                                            class="text-danger">*</sup></label>
                                                                                    <input type="text"
                                                                                           class="form-control"
                                                                                           :maxlength="getSiteProps('general.zip_code_length')"
                                                                                           id="verifyData_zip"
                                                                                           v-model="verifyData.zip"
                                                                                           name="verifyData.zip">
                                                                                    <span
                                                                                        class="text-sm text-danger">{{ errors.zip }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <template v-if="verifyData.address_type == 'Work'">
                                                                                <div :class="[fields.contact.work_address_company_name.colClass]" v-if="fields.contact.work_address_company_name">
                                                                                    <div class="form-group">
                                                                                        <label for="work_address_company_name" class="form-label">{{ fields.contact.work_address_company_name.label }}<sup class="text-danger" v-if="fields.contact.work_address_company_name.required">*</sup></label>
                                                                                        <input type="text" class="form-control" id="work_address_company_name" v-model="verifyData.company_name">
                                                                                        <span class="text-sm text-danger">{{ errors.company_name }}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div :class="[fields.contact.work_address_company_phone_extension.colClass]" v-if="fields.contact.work_address_company_phone_extension">
                                                                                    <div class="form-group">
                                                                                        <label for="work_address_company_phone" class="form-label">{{ fields.contact.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="fields.contact.work_address_company_phone_extension.required">*</sup></label>
                                                                                        <div class="row">
                                                                                            <div class="col-md-9">
                                                                                                <vue-tel-input ref="phone" @validate="validateTelinput" v-model="verifyData.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                                                                                                <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                                                                                            </div>
                                                                                            <div class="col-md-3">
                                                                                                <input type="text" class="form-control" v-model="verifyData.company_phone_extension" name="verifyData.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                                                                                                <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                            <div class="col-md-6" v-if="isChild && this.isTeamRegistration && this.membership.id == 5">
                                                                            <div class="form-group" >
                                                                              <label for="Country"
                                                                                     class="form-label">Citizenship
                                                                                <sup class="text-danger">*</sup></label>
                                                                              <select class="form-control"
                                                                                      id="verifyData_country"
                                                                                      v-model="verifyData.citizenship"
                                                                                      name="verifyData.country" v-if="listOfCountryWithLimit">
                                                                                <option
                                                                                    v-bind:value="listOfCountry.country"
                                                                                    v-for="listOfCountry in listOfCountries"
                                                                                    :key="listOfCountry.country"
                                                                                    :selected="verifyData.citizenship == listOfCountry.country">
                                                                                  {{ listOfCountry.country }}
                                                                                </option>
                                                                              </select>
                                                                              <select class="form-control"
                                                                                      id="verifyData_country"
                                                                                      v-model="verifyData.country"
                                                                                      @change="removeVerifyStateCode"
                                                                                      name="verifyData.country" v-else>
                                                                                <option
                                                                                    v-bind:value="listOfCountry.country"
                                                                                    v-for="listOfCountry in fields.contact.country.options"
                                                                                    :key="listOfCountry.country"
                                                                                    :selected="verifyData.country == listOfCountry.country">
                                                                                  {{ listOfCountry.country }}
                                                                                </option>
                                                                              </select>
                                                                              <span
                                                                                  class="text-sm text-danger">{{ errors.country }}</span>
                                                                            </div>
                                                                          </div>
                                                                            <div class="col-md-6 "  v-if="isChild || !this.isTeamRegistration || (this.isTeamRegistration && this.membership.id ==5)">
                                                                                <div class="form-group ">
                                                                                    <div class="phone-nu-wrapper">
                                                                                        <label for="verifyData_phone_1"
                                                                                               class="form-label">{{ fields.contact.homephone.label || 'Country Code with Home phone' }} <sup
                                                                                                class="text-danger">*</sup></label>
                                                                                        <vue-tel-input
                                                                                            id="verifyData_phone_1"
                                                                                            v-model="verifyData.phone_1"
                                                                                            @validate="validateTelinput"
                                                                                            ref="phone1" maxlength="20"
                                                                                            v-bind="homeProps"
                                                                                            :validCharactersOnly="true"></vue-tel-input>
                                                                                        <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
                                                                                         <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                                                                                        <span
                                                                                            class="text-sm text-danger">{{
                                                                                                errors.phone_1
                                                                                            }}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6"  v-if="isChild || !this.isTeamRegistration || (this.isTeamRegistration && this.membership.id ==5)">
                                                                                <div class="form-group">
                                                                                    <div class="phone-nu-wrapper">
                                                                                        <label for="verifyData_phone_2"
                                                                                               class="form-label">{{ fields.contact.mobilephone.label || 'Country Code with Mobile' }}</label>
                                                                                        <vue-tel-input
                                                                                            id="verifyData_phone_2"
                                                                                            v-model="verifyData.phone_2"
                                                                                            ref="phone2" maxlength="20"
                                                                                            v-bind="mobileProps"  @validate="validateTelinput2"
                                                                                            :validCharactersOnly="true"></vue-tel-input>
                                                                                        <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
                                                                                        <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                                                                                         <span
                                                                                            class="text-sm text-danger">{{
                                                                                                errors.phone_2
                                                                                            }}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div :class="[fields.club_info.member_league.colClass]" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.member_league && this.membership.id != 5">
                                                                              <div class="form-group">
                                                                                <label for="member_league" class="form-label">{{ fields.club_info.member_league.label }} <sup class="text-danger" v-if="fields.club_info.member_league.required">*</sup></label>
                                                                                <input type="text" class="form-control" maxlength="100" id="member_league" v-model="verifyData.member_league_name" v-bind:readonly="true" name="form.member_league">
                                                                                <span class="text-sm text-danger">{{ errors.member_league}}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.club_info.age_division.colClass]" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.age_division && this.membership.id != 5">
                                                                              <div class="form-group">
                                                                                <label for="age_division" class="form-label">{{ fields.club_info.age_division.label }} <sup class="text-danger" v-if="fields.club_info.age_division.required">*</sup></label>
                                                                                <select class="form-control" id="age_division" v-model="verifyData.age_division" @change="getDivisionTeamList($event)" name="form.age_division" >
                                                                                  <option value="">Select</option>
                                                                                  <option  v-for="(listOfAgeDivision,key) in ageDivisionOPt" :key="key" :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.age_division}}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.club_info.add_new_organization.colClass]" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.add_new_organization && this.membership.id !=5">
                                                                              <div class="form-group">
                                                                                <label for="organization" class="form-label">{{ fields.club_info.organization.label }} <sup class="text-danger" v-if="fields.club_info.organization.required">*</sup></label>
                                                                                <input type="text" class="form-control" maxlength="100" id="add_new_organization" v-model="verifyData.name" name="form.add_new_organization">
                                                                                <span class="text-sm text-danger">{{ errors.add_new_organization}}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.club_info.agl_team.colClass]" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.agl_team && this.membership.id != 5">
                                                                              <div class="form-group">
                                                                                <label for="agl_team" class="form-label">{{ fields.club_info.agl_team.label }} <sup class="text-danger" v-if="fields.club_info.agl_team.required">*</sup></label>
                                                                                <input type="text" class="form-control" maxlength="100" id="agl_team" v-model="verifyData.agl_team_id" name="form.agl_team">
                                                                                <span class="text-sm text-danger">{{ errors.agl_team}}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.contact.parent_citizenship.colClass]" v-if="this.isTeamRegistration && isChild && fields.contact.parent_citizenship && this.membership.id != 5">
                                                                              <div class="form-group">
                                                                                <label for="parent_citizenship" class="form-label">{{ fields.contact.parent_citizenship.label }} <sup class="text-danger" v-if="fields.contact.parent_citizenship.required">*</sup></label>
                                                                                <div class="form-group" v-if="fields.contact.parent_citizenship.choices">
                                                                                  <template v-for="(choice, key) in fields.contact.parent_citizenship.choices">
                                                                                    <input type="radio" :id="choice.value" v-model="form.is_parent_citizenship" name="form.parent_citizenship" :value="choice.value" @click="parentCitizenship = choice.value" :key="'radio_' + key">
                                                                                    <label class="parent_citizenship-label-span mr-3" :for="choice.value" :key="'parent_citizenship_label_'+key">{{ choice.label }}</label>
                                                                                  </template>
                                                                                  <span class="text-sm text-danger">{{ errors.is_parent_citizenship}}</span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.contact.parent_citizenship.yes_fields.citizenship.colClass]" v-if="this.isTeamRegistration && isChild && fields.contact.parent_citizenship && fields.contact.parent_citizenship.yes_fields.citizenship && parentCitizenship == 1 && this.membership.id != 5" v-show="parentCitizenship == 1">
                                                                              <div class="form-group">
                                                                                <label for="citizenship" class="form-label" v-if="fields.contact.parent_citizenship.yes_fields.citizenship.label !=''">{{ fields.contact.parent_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.contact.parent_citizenship.yes_fields.citizenship.required">*</sup></label>
                                                                                <select class="form-control" id="parent_citizenship" v-model="verifyData.parent_citizenship" name="form.parent_citizenship">
                                                                                  <option value="">Select citizenship</option>
                                                                                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.parent_citizenship}}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.contact.athlete_citizenship.colClass]" v-if="this.isTeamRegistration && isChild && fields.contact.athlete_citizenship && this.membership.id != 5">
                                                                              <div class="form-group">
                                                                                <label for="athlete_citizenship" class="form-label">{{ fields.contact.athlete_citizenship.label }} <sup class="text-danger" v-if="fields.contact.athlete_citizenship.required">*</sup></label>
                                                                                <div class="form-group" v-if="fields.contact.athlete_citizenship.choices">
                                                                                  <template v-for="(choice, key) in fields.contact.athlete_citizenship.choices">
                                                                                    <input type="radio" :id="choice.value" v-model="form.is_athlete_citizenship" name="form.athlete_citizenship" :value="choice.value" @click="athleteCitizenship = choice.value" :key="'radio_' + key">
                                                                                    <label class="athlete_citizenship-label-span mr-3" :for="choice.value" :key="'athlete_citizenship_label_'+key">{{ choice.label }}</label>
                                                                                  </template>
                                                                                  <span class="text-sm text-danger">{{ errors.is_athlete_citizenship}}</span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.contact.athlete_citizenship.yes_fields.citizenship.colClass]" v-if="this.isTeamRegistration && isChild && fields.contact.athlete_citizenship && fields.contact.athlete_citizenship.yes_fields.citizenship && athleteCitizenship == 1 && this.membership.id != 5" v-show="athleteCitizenship == 1">
                                                                              <div class="form-group">
                                                                                <label for="citizenship" class="form-label" v-if="fields.contact.athlete_citizenship.yes_fields.citizenship.label !=''">{{ fields.contact.athlete_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.contact.athlete_citizenship.yes_fields.citizenship.required">*</sup></label>
                                                                                <select class="form-control" id="athlete_citizenship" v-model="verifyData.athlete_citizenship" name="form.athlete_citizenship">
                                                                                  <option value="">Select citizenship</option>
                                                                                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.athlete_citizenship}}</span>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.contact.eligible_citizenship.colClass]" v-if="this.isTeamRegistration && isChild && fields.contact.eligible_citizenship && this.membership.id != 5" v-show="parentCitizenship == 2 || athleteCitizenship == 2">
                                                                              <div class="form-group">
                                                                                <label for="eligible_citizenship" class="form-label">{{ fields.contact.eligible_citizenship.label }} <sup class="text-danger" v-if="fields.contact.eligible_citizenship.required">*</sup></label>
                                                                                <div class="form-group" v-if="fields.contact.eligible_citizenship.choices">
                                                                                  <template v-for="(choice, key) in fields.contact.eligible_citizenship.choices">
                                                                                    <input type="radio" :id="choice.value" v-model="form.is_eligible_citizenship" name="form.eligible_citizenship" :value="choice.value" @click="eligibleCitizenship = choice.value" :key="'radio_' + key">
                                                                                    <label class="eligible_citizenship-label-span mr-3" :for="choice.value" :key="'eligible_citizenship_label_'+key">{{ choice.label }}</label>
                                                                                  </template>
                                                                                  <span class="text-sm text-danger">{{ errors.is_eligible_citizenship}}</span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <div :class="[fields.contact.eligible_citizenship.yes_fields.citizenship.colClass]" v-if="this.isTeamRegistration && isChild && fields.contact.eligible_citizenship && fields.contact.eligible_citizenship.yes_fields.citizenship && eligibleCitizenship == 1 && (parentCitizenship == 2 || athleteCitizenship == 2) && this.membership.id != 5" v-show="eligibleCitizenship == 1 && (parentCitizenship == 2 || athleteCitizenship == 2)">
                                                                              <div class="form-group">
                                                                                <label for="citizenship" class="form-label" v-if="fields.contact.eligible_citizenship.yes_fields.citizenship.label !=''">{{ fields.contact.eligible_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.contact.eligible_citizenship.yes_fields.citizenship.required">*</sup></label>
                                                                                <select class="form-control" id="eligible_citizenship" v-model="verifyData.eligible_citizenship" name="form.eligible_citizenship">
                                                                                  <option value="">Select citizenship</option>
                                                                                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.eligible_citizenship}}</span>
                                                                              </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-modal>
                                                            </div>
                                                            <!-- <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>-->
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="isChild && isChildUsers && this.isTeamRegistration">
                                                    <div class="col-md-6">
                                                      <label>{{ registerationStep2 && fields.contact.firstname.label ? fields.contact.firstname.label : 'First Name' }}</label>
                                                      <span>{{ tempverifyData.first_name || 'NA' }}</span>
                                                    </div>
                                                    <div class="col-md-6">
                                                      <label>{{ registerationStep2 && fields.contact.middlename.label ? fields.contact.middlename.label : 'Middle Name' }}</label>
                                                      <span>{{ tempverifyData.middle_name || 'NA' }}</span>
                                                    </div>
                                                    <div class="col-md-12">
                                                      <label>{{ registerationStep2 && fields.contact.lastname.label ? fields.contact.lastname.label : 'Last Name' }}</label>
                                                      <span>{{ tempverifyData.last_name || 'NA' }}</span>
                                                    </div>
                                                  </div>
                                                    <div class="row">
                                                        <div class="col-md-6" v-if="isChild || !this.isTeamRegistration ||  (this.isTeamRegistration && this.membership.id == 5)">
                                                            <label>{{ registerationStep2 && fields.contact.homephone.label ? fields.contact.homephone.label : 'Phone' }}</label>
                                                            <span>{{ tempverifyData.phone_1 || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="isChild || !this.isTeamRegistration ||  (this.isTeamRegistration && this.membership.id == 5)">
                                                            <label>{{ registerationStep2 && fields.contact.mobilephone.label ? fields.contact.mobilephone.label : 'Mobile' }}</label>
                                                            <span>{{ tempverifyData.phone_2 || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-12" v-if="isChild || !this.isTeamRegistration ||  (this.isTeamRegistration && this.membership.id == 5)">
                                                            <label>Email</label>
                                                            <span>{{ tempverifyData.email || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-12" v-show="verifyEditData.address_type">
                                                            <label>Address Type</label>
                                                            <span>{{ tempverifyData.address_type }} Address</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{ registerationStep2 && fields.contact.address1.label ? fields.contact.address1.label : 'Mailing Address' }}</label>
                                                            <span>{{ tempverifyData.address1  || 'NA'}}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{ registerationStep2 && fields.contact.address2.label ? fields.contact.address2.label : 'Address 2' }}</label>
                                                            <span>{{ tempverifyData.address2 || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>City</label>
                                                            <span>{{ tempverifyData.city || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>State</label>
                                                            <span v-if="isTeamRegistration && subdomain == 'alliancefastpitch' && (verifyEditData.country == 'USA' || verifyEditData.country == 'Canada')">{{
                                                                tempverifyData.state_code
                                                                }}</span>
                                                                <span v-else-if="verifyEditData.country == 'USA'">{{
                                                                tempverifyData.state_code
                                                                }}</span>
                                                            <span v-else>{{ tempverifyData.state || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Postal Code</label>
                                                            <span>{{ tempverifyData.zip || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Country</label>
                                                            <span>{{ tempverifyData.country || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="isChild && isTeamRegistration">
                                                          <label>Citizenship</label>
                                                          <span>{{ tempverifyData.citizenship || 'NA' }}</span>
                                                        </div>
                                                         <div class="col-md-6" v-if="isChild && isTeamRegistration">
                                                          <label>Parent/Guardian Citizenship</label>
                                                          <span>{{ tempverifyData.parent_citizenship || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="isChild && isTeamRegistration">
                                                          <label>Child/Athlete Citizenship</label>
                                                          <span>{{ tempverifyData.athlete_citizenship || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="isChild && isTeamRegistration">
                                                          <label>Eligible Child/Athlete Citizenship</label>
                                                          <span>{{ tempverifyData.eligible_citizenship || 'NA' }}</span>
                                                        </div>
                                                        <template v-if="verifyEditData.address_type == 'Work'">
                                                            <div class="col-md-6" v-show="verifyEditData.company_name">
                                                                <label>Company Name</label>
                                                                <span>{{ tempverifyData.company_name }}</span>
                                                            </div>
                                                            <div class="col-md-6" v-show="verifyEditData.company_phone">
                                                                <label>Company Phone</label>
                                                                <span>{{ tempverifyData.company_phone }} | Ext: {{ verifyEditData.company_phone_extension }}</span>
                                                            </div>
                                                        </template>
                                                        <div class="col-md-6" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.member_league && this.membership.id != 5">
                                                          <label>{{fields.club_info.member_league.label}}</label>
                                                          <span>{{ verifyEditData.member_league_name || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.age_division && this.membership.id != 5">
                                                            <label>{{fields.club_info.age_division.label}}</label>
                                                            <span>{{ tempverifyData.age_division_name || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.organization && this.membership.id != 5">
                                                            <label>{{fields.club_info.organization.label}}</label>
                                                            <span>{{ tempverifyData.name || 'NA' }}</span>
                                                        </div>
                                                        <div class="col-md-6" v-if="this.isTeamRegistration && !isChild && fields.club_info && fields.club_info.agl_team && this.membership.id != 5">
                                                          <label>{{fields.club_info.agl_team.label}}</label>
                                                          <span>{{ tempverifyData.agl_team_id || 'NA' }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="verifyData.families.length > 0">
                                                  <b-modal id="modal-center3" size="lg" ok-only centered :title="fieldsAd.popup.title || 'Family Member Details'"
                                                           ok-title="Save"  @ok="saveMember">
                                                    <div class="popupbody">
                                                      <div class="row">
                                                        <div class="col-md-6" v-if="fieldsAd.popup.relation">
                                                          <label class="form-label">{{ fieldsAd.popup.relation.label }} <sup class="text-danger">*</sup></label><br>
                                                          <div class="d-flex">
                                                            <template v-for="(choice,k) in fieldsAd.popup.relation.choices">
                                                              <div :class="[choice.containerClass]" :key="'relation'+k">
                                                                <input type="radio" :id="choice.id" name="familyForm.relation" :value="choice.value" v-model="familyForm.relation">
                                                                <span :for="choice.id" class="gender-label-span">{{ choice.label }}</span>
                                                              </div>
                                                            </template>
                                                          </div>
                                                          <p class="text-sm text-danger mb-1">{{ errors.relation }}</p>
                                                          <p class="text-sm text-success" v-if="fieldsAd.popup.relation && familyForm.relation == 2">{{ fieldsAd.popup.relation.choices[1].description }}</p>
                                                        </div>
                                                        <div class="col-md-6" v-if="fieldsAd.popup.email && !this.isTeamRegistration">
                                                          <div class="form-group">
                                                            <label for="email" class="form-label">{{ fieldsAd.popup.email.label }} <sup class="text-danger" v-if="fieldsAd.popup.email.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" name="familyForm.email" v-model="familyForm.email" >
                                                            <span class="text-sm text-danger">{{ errors.email }}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="row">
                                                        <div class="col-md-4" v-if="fieldsAd.popup.firstName">
                                                          <div class="form-group">
                                                            <label for="firstName" class="form-label">{{ fieldsAd.popup.firstName.label }} <sup class="text-danger" v-if="fieldsAd.popup.firstName.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" name="familyForm.first_name" v-model="familyForm.first_name" >
                                                            <span class="text-sm text-danger">{{ errors.first_name }}</span>
                                                            <span class="text-sm text-danger" v-if="duplicateError.hasError">{{ duplicateError.errorMessage}}</span>
                                                          </div>
                                                          <input type="hidden" v-model="familyForm.member_id">
                                                        </div>
                                                        <div class="col-md-2" v-if="fieldsAd.popup.middleInitial">
                                                          <div class="form-group">
                                                            <label for="middleInitial" class="form-label">Middle Initial</label>
                                                            <input type="text" maxlength="3" name="familyForm.middle_name" v-model="familyForm.middle_name" class="form-control">
                                                            <span class="text-sm text-danger">{{ errors.middle_name }}</span>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4" v-if="fieldsAd.popup.LastName">
                                                          <div class="form-group">
                                                            <label for="LastName" class="form-label">{{ fieldsAd.popup.LastName.label }} <sup class="text-danger" v-if="fieldsAd.popup.LastName.required">*</sup></label>
                                                            <input type="text" class="form-control" maxlength="45" name="familyForm.first_name" v-model="familyForm.last_name" >
                                                            <span class="text-sm text-danger">{{ errors.last_name }}</span>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-6 form-group" v-if="fieldsAd.popup.email && this.isTeamRegistration">
                                                          <label for="email"
                                                                 class="form-label">Email <sup
                                                              class="text-danger">*</sup></label>
                                                          <input type="text"
                                                                 class="form-control"
                                                                 maxlength="45" id="email"
                                                                 v-model="familyForm.email"
                                                                 name="verifyData.email">
                                                          <span
                                                              class="text-sm text-danger">{{ errors.email }}</span>
                                                        </div>
                                                        <div class="col-md-6 form-group" v-if="fieldsAd.popup.email && this.isTeamRegistration">
                                                          <label for="retype_email"
                                                                 class="form-label">Retype
                                                            Email <sup
                                                                class="text-danger">*</sup></label>
                                                          <input type="text"
                                                                 class="form-control"
                                                                 maxlength="45"
                                                                 id="retype_email"
                                                                 v-model="familyForm.retype_email"
                                                                 name="verifyData.retype_email">
                                                          <span
                                                              class="text-sm text-danger">{{ errors.retype_email }}</span>
                                                        </div>
<!--                                                        <div class="col-md-6" v-if="fieldsAd.popup.relation">-->
<!--                                                          <div class="form-group">-->
<!--                                                            <label for="LastName" class="form-label">{{ fieldsAd.popup.LastName.label }} <sup class="text-danger" v-if="fieldsAd.popup.LastName.required">*</sup></label>-->
<!--                                                            <input type="text" maxlength="45" name="familyForm.last_name" v-model="familyForm.last_name" class="form-control">-->
<!--                                                            <span class="text-sm text-danger">{{ errors.last_name }}</span>-->
<!--                                                          </div>-->
<!--                                                        </div>-->
                                                        <div class="col-md-6" v-if="fieldsAd.popup.birthDay">
                                                          <label for="birthDay" class="form-label">{{ fieldsAd.popup.birthDay.label }} <sup class="text-danger" v-if="fieldsAd.popup.birthDay.required">*</sup></label>
                                                          <div class="input-group birthdate">
                                                            <div class="birthdaypicker">
                                                              <!-- <b-form-datepicker id="family-datepicker" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="familyForm.dob" class="mb-2"></b-form-datepicker> -->
                                                              <!-- <i class="fas fa-calendar-alt"></i> -->
                                                              <date-pick
                                                                  v-model="familyForm.dob"
                                                                  :pickTime="false"
                                                                  name="familyForm.dob"
                                                                  :selectableYearRange="{from: 1900, to: 2021}"
                                                                  :format="'MM/DD/YYYY'"
                                                                  v-mask="'##/##/####'"
                                                                  :isDateDisabled="isFutureDate"
                                                                  :parseDate="parseDatePick"
                                                              ></date-pick>
                                                            </div>
                                                            <span class="text-sm text-danger">{{ errors.dob }}</span>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-6" v-if="fieldsAd.popup.gender">
                                                          <label for="gender" class="form-label">{{ fieldsAd.popup.gender.label }} <sup class="text-danger" v-if="fieldsAd.popup.gender.required">*</sup></label>
                                                          <div class="form-group">
                                                            <div class="row">
                                                              <div class="mb-1" :class="k < 3 ? 'col-md-3' : 'col-md-5'" v-for="(choice, k) in fieldsAd.popup.gender.choices" :key="'gender_'+k">
                                                                <input type="radio" :id="choice.id" v-model="familyForm.gender" name="familyForm.gender" :value="choice.label"  @click="messageFor(choice.id)">
                                                                <span class="gender-label-span" :for="choice.id">{{ choice.label }}</span>
                                                              </div>
                                                            </div>
                                                            <span class="text-sm text-danger">{{ errors.gender }}</span>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-6" v-if="fieldsAd.popup.homephone">
                                                          <div class="form-group ">
                                                            <div class="phone-nu-wrapper">
                                                              <label for="country_code_h" class="form-label">{{ fieldsAd.popup.homephone.label }}<sup class="text-danger" v-if="fieldsAd.popup.homephone.required">*</sup></label>
                                                              <vue-tel-input :inputOptions="phoneOptions" @validate="validateTelinput" v-model="familyForm.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                                                              <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
                                                              <!--<input type="text" class="form-control"  id="country_code_h" name="country_code_h" placeholder="+1">
                                                               <input type="tel" class="form-control" id="phone_1" v-model="familyForm.phone_1"  name="familyForm.phone_1">-->
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-6" v-if="fieldsAd.popup.mobile">
                                                          <div class="form-group">
                                                            <div class="phone-nu-wrapper">
                                                              <label for="phone_2" class="form-label">{{ fieldsAd.popup.mobile.label }}</label>
                                                              <vue-tel-input @validate="validateTelinput2" :inputOptions="phoneOptions" v-model="familyForm.phone_2" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                                              <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
                                                              <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1">
                                                              <input type="tel" class="form-control" id="phone_2" v-model="familyForm.phone_2"  name="familyForm.phone_2">-->
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="row" v-if="fieldsAd.popup.sport_discipline">
                                                        <div class="col-md-12" v-if="fieldsAd.popup.sport_discipline.label">
                                                          <label class="form-check-label form-label">{{ fieldsAd.popup.sport_discipline.label }}</label>
                                                        </div>
                                                        <div :class="first_choice_class" v-if="fieldsAd.popup.sport_discipline && fieldsAd.popup.sport_discipline.first_choice">
                                                          <div class="form-group">
                                                            <label for="first-choice" class="form-label">{{ fieldsAd.popup.sport_discipline.first_choice.label }} <sup class="text-danger" v-if="fieldsAd.popup.sport_discipline.first_choice.required">*</sup></label>
                                                            <Select2 v-if="fieldsAd.popup.sport_discipline.first_choice.multiple" v-model="familyForm.discipline_1"
                                                                     name="familyForm.discipline_1" id="first-choice" :options="disciplines_list"
                                                                     :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" @change="collectDisciplines" />
                                                            <select class="form-control" id="first-choice" name="familyForm.discipline_1" @change="collectDisciplines" v-model="familyForm.discipline_1" v-else>
                                                              <option value="">Select</option>
                                                              <option v-for="(discipline, index) in disciplines" :value="index"
                                                                      :key="'types'+index">{{ discipline }}
                                                              </option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.discipline_1 }}</span>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-6" v-if="fieldsAd.popup.sport_discipline && fieldsAd.popup.sport_discipline.second_choice">
                                                          <div class="form-group">
                                                            <label for="secondchoice" class="form-label">{{ fieldsAd.popup.sport_discipline.second_choice.label }}</label>
                                                            <select class="form-control" id="secondchoice" name="familyForm.discipline_2" v-model="familyForm.discipline_2" @change="collectSecondChoice">
                                                              <option value="">Select</option>
                                                              <option v-for="(discipline, index) in disciplines" :value="index"
                                                                      :key="'types2-'+index">{{ discipline }}
                                                              </option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.discipline_2 }}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    <div class="row">
                                                      <div :class="[fieldsAd.popup.clubandteam.colClass]" v-if="fieldsAd.popup.clubandteam">
                                                        <div class="form-group">
                                                          <label for="clubandteam" class="form-label">{{ fieldsAd.popup.clubandteam.label }}<sup class="text-danger"
                                                                                                                                               v-if="fieldsAd.popup.clubandteam.required">*</sup></label>
                                                          <Select2 v-model="familyForm.clubandteam" :options="clubOptions"
                                                                   :placeholder="fieldsAd.popup.clubandteam.placeholder"/>
                                                          <p class="mt-2 font-13" v-if="fieldsAd.popup.clubandteam.description"><i
                                                              v-html="fieldsAd.popup.clubandteam.description"></i></p>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.address1.colClass]" v-if="fieldsAd.popup.address1">
                                                        <div class="form-group">
                                                          <label for="address1" class="form-label">{{ fieldsAd.popup.address1.label }} <sup class="text-danger" v-if="fieldsAd.popup.address1.required">*</sup></label>
                                                          <input type="text" class="form-control" maxlength="100" id="address1" v-model="familyForm.address1" name="familyForm.address1">
                                                          <span class="text-sm text-danger">{{ errors.address1}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.address2.colClass]" v-if="fieldsAd.popup.address2">
                                                        <div class="form-group">
                                                          <label for="address2" class="form-label">{{ fieldsAd.popup.address2.label }}</label>
                                                          <input type="text" class="form-control" maxlength="45" id="address2" v-model="familyForm.address2" name="familyForm.address2">
                                                          <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.country.colClass]" v-if="!listOfCountryWithLimit && fieldsAd.popup.country">
                                                        <div class="form-group">
                                                          <label for="Country" class="form-label">{{ fieldsAd.popup.country.label }} <sup class="text-danger" v-if="fieldsAd.popup.country.required">*</sup></label>
                                                          <select class="form-control" id="country" @change="removeFamilyStateCode" v-model="familyForm.country" name="familyForm.country">
                                                            <option value="">Select</option>
                                                            <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                                          </select>
                                                          <span class="text-sm text-danger">{{ errors.country}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.country.colClass]" v-if="listOfCountryWithLimit && fieldsAd.popup.country">
                                                        <div class="form-group">
                                                          <label for="Country" class="form-label">{{ fieldsAd.popup.country.label }} <sup class="text-danger" v-if="fieldsAd.popup.country.required">*</sup></label>
                                                          <select class="form-control" id="country" @change="removeFamilyStateCode" v-model="familyForm.country" name="familyForm.country">
                                                            <option value="">Select</option>
                                                            <option :value="listOfCountry.country" v-for="listOfCountry in fieldsAd.popup.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                                          </select>
                                                          <span class="text-sm text-danger">{{ errors.country}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.city.colClass]" v-if="fieldsAd.popup.city">
                                                        <div class="form-group">
                                                          <label for="city" class="form-label">{{ fieldsAd.popup.city.label }} <sup class="text-danger" v-if="fieldsAd.popup.city.required">*</sup></label>
                                                          <input type="text" class="form-control" maxlength="45" id="city"  v-model="familyForm.city" name="familyForm.city">
                                                          <span class="text-sm text-danger">{{ errors.city}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.state_code.colClass]" v-if="(familyForm.country == 'USA' || familyForm.country == '') && fieldsAd.popup.state_code">
                                                        <div class="form-group">
                                                          <label for="state_code" class="form-label">{{ fieldsAd.popup.state_code.label }} <sup class="text-danger" v-if="fieldsAd.popup.state_code.required">*</sup></label>
                                                          <select class="form-control" id="state_code" v-model="familyForm.state_code" name="familyForm.state_code" >
                                                            <option value="">Select</option>
                                                            <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                          </select>
                                                          <span class="text-sm text-danger">{{ errors.state_code}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.state_code.colClass]" v-else-if="listOfCountryWithLimit && familyForm.country == 'Canada' && fieldsAd.popup.state_code.option">
                                                        <div class="form-group">
                                                          <label for="state_code" class="form-label">{{ fieldsAd.popup.state_code.label }} <sup class="text-danger" v-if="fieldsAd.popup.state_code.required">*</sup></label>
                                                          <select class="form-control" id="state_code" v-model="familyForm.state_code" name="familyForm.state_code" >
                                                            <option value="">Select</option>
                                                            <option  v-for="listOfState in fieldsAd.popup.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                          </select>
                                                          <span class="text-sm text-danger">{{ errors.state_code}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.state_code.colClass]" v-else-if="familyForm.country == 'Canada' && fieldsAd.popup.state_code">
                                                        <div class="form-group">
                                                          <label for="state_code" class="form-label">{{ fieldsAd.popup.state_code.label }} <sup class="text-danger" v-if="fieldsAd.popup.state_code.required">*</sup></label>
                                                          <select class="form-control" id="state_code" v-model="familyForm.state" name="familyForm.state" >
                                                            <option value="">Select</option>
                                                            <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                                                          </select>
                                                          <span class="text-sm text-danger">{{ errors.state}}</span>
                                                        </div>
                                                      </div>
                                                      <div  v-else>
                                                        <div v-if="fieldsAd.popup.other_state" :class="[fieldsAd.popup.other_state.colClass]">
                                                        <div class="form-group">
                                                          <label for="state_code" class="form-label">{{ fieldsAd.popup.other_state.label }} <sup class="text-danger" v-if="fieldsAd.popup.other_state.required">*</sup></label>
                                                          <input type="text" class="form-control" maxlength="45" v-model="familyForm.state" name="familyForm.state">
                                                          <span class="text-sm text-danger">{{ errors.state}}</span>
                                                        </div>
                                                        </div>
                                                      </div>

                                                      <div :class="[fieldsAd.popup.zip.colClass]" v-if="fieldsAd.popup.zip">
                                                        <div class="form-group">
                                                          <label for="zip" class="form-label">{{ fieldsAd.popup.zip.label }} <sup class="text-danger" v-if="fieldsAd.popup.zip.required">*</sup></label>
                                                          <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" v-model="familyForm.zip" name="familyForm.zip">
                                                          <span class="text-sm text-danger">{{ errors.zip}}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.home_federation.colClass]" v-if="fieldsAd.popup.home_federation">
                                                        <div class="form-group">
                                                          <label for="home_federation" class="form-label">{{ fieldsAd.popup.home_federation.label }} <sup v-if="fieldsAd.popup.home_federation.required">*</sup></label>
                                                          <select class="form-control" id="home_federation" name="familyForm.home_federation"
                                                                  v-model="familyForm.home_federation">
                                                            <option value="">Select</option>
                                                            <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                                                          </select>
                                                          <span class="text-sm text-danger">{{ errors.home_federation }}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.skier_license.colClass]" v-if="fieldsAd.popup.skier_license">
                                                        <div v-if="familyForm.home_federation !='USA'">
                                                          <div class="form-group">
                                                            <label for="skier_license" class="form-label">{{ fieldsAd.popup.skier_license.label }}<sup v-if="fieldsAd.popup.skier_license.required">*</sup></label>
                                                            <input type="text" id="skier_license" class="form-control" maxlength="9" @keypress="isNumber($event)"
                                                                   placeholder="" name="familyForm.skier_license"
                                                                   v-model="familyForm.skier_license">
                                                            <span class="text-sm text-danger">{{ errors.skier_license }}</span>
                                                            <div class="text-sm text-success" v-if="fieldsAd.popup.skier_license.description" v-html="fieldsAd.popup.skier_license.description"></div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <!-- Demographic start-->
                                                      <div :class="[fieldsAd.popup.race.colClass]" v-if="fieldsAd.popup.race">
                                                        <div class="form-group radiotext">
                                                          <label for="race" class="form-label-check"><strong>{{ fieldsAd.popup.race.label }}</strong> <sup
                                                              class="text-danger" v-if="fieldsAd.popup.race.required">*</sup></label> <br>
                                                          <div v-for="diversity in diversities" :key="diversity.name">
                                                            <label :for="'diversity_id'+diversity.id">
                                                              <input type="radio" name="familyForm.diversity_id" :value="diversity.id"
                                                                     :id="'diversity_id'+diversity.id"
                                                                     v-model="familyForm.diversity_id" @change="saveDiversityTxt(diversity.name)">
                                                              {{ diversity.name }}</label> <br>
                                                          </div>
                                                          <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.ethnicity.colClass]" v-if="fieldsAd.popup.ethnicity">
                                                        <div class="form-group radiotext">
                                                          <label for="ethnicitystatus" class="form-label-check"><strong>{{ fieldsAd.popup.ethnicity.label }}</strong><sup
                                                              class="text-danger" v-if="fieldsAd.popup.ethnicity.required">*</sup></label>
                                                          <div class="form-group" v-if="fieldsAd.popup.ethnicity.choices">
                                                            <template v-for="(choice, k) in fieldsAd.popup.ethnicity.choices">
                                                              <input type="radio" :id="choice.id" name="ethnicity" :value="choice.label" v-model="familyForm.ethnicity"
                                                                     :key="'ethnicity_'+k">
                                                              <label :for="choice.id" :key="'ethnicity_label_'+k">{{ choice.label }}</label>
                                                              <br :key="'ethnicity_br_'+k">
                                                            </template>
                                                            <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.disability_status.colClass]" v-if="fieldsAd.popup.disability_status">
                                                        <div class="form-group radiotext">
                                                          <label for="disabilitystatus" class="form-label-check"><strong>{{
                                                              fieldsAd.popup.disability_status.label
                                                            }}</strong> <sup class="text-danger" v-if="fieldsAd.popup.disability_status.required">*</sup></label>
                                                          <div class="form-group" v-if="fieldsAd.popup.disability_status.choices">
                                                            <template v-for="(choice, k) in fieldsAd.popup.disability_status.choices">
                                                              <input type="radio" :id="choice.id" name="disability" :value="choice.label"
                                                                     v-model="familyForm.disability" :key="'ds_'+k">
                                                              <label :for="choice.id" :key="'ds_label_'+k">{{ choice.label }}</label>
                                                              <br :key="'ds_br_'+k">
                                                            </template>
                                                            <span class="text-sm text-danger">{{ errors.disability }}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div :class="[fieldsAd.popup.veteran_status.colClass]" v-if="fieldsAd.popup.veteran_status && familyForm.relation ==1">
                                                        <div class="form-group radiotext">
                                                          <label for="veteranstatus" class="form-label-check"><strong>{{
                                                              fieldsAd.popup.veteran_status.label
                                                            }}</strong><sup class="text-danger" v-if="fieldsAd.popup.veteran_status.required">*</sup></label>
                                                          <div class="form-group" v-if="fieldsAd.popup.veteran_status.choices">
                                                            <template v-for="(choice, k) in fieldsAd.popup.veteran_status.choices">
                                                              <input type="radio" :id="choice.id" name="veteran_status" :value="choice.label"
                                                                     v-model="familyForm.veteran_status" :key="'vs_'+k">
                                                              <label :for="choice.id" :key="'vs_label_'+k">{{ choice.label }}</label>
                                                              <br :key="'vs_br_'+k">
                                                            </template>
                                                            <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      </div>
                                                      <div class="row" v-if="this.isTeamRegistration">
                                                        <div :class="[fieldsAd.popup.agl_player_id.colClass]" v-if="fieldsAd.popup.agl_player_id">
                                                          <div class="form-group">
                                                            <label for="agl_player_id" class="form-label">
                                                              {{ fieldsAd.popup.agl_player_id.label }}<sup class="text-danger" v-if="fieldsAd.popup.agl_player_id.required">*</sup>
                                                            </label>
                                                            <input type="text" class="form-control" :placeholder="fieldsAd.popup.agl_player_id.placeholder" maxlength="45" id="agl_player_id" v-model="familyForm.agl_player_id" name="form.agl_player_id" value="">
                                                            <span class="text-sm text-danger">{{ errors.agl_player_id}}</span>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.graduation_year.colClass]" v-if="fieldsAd.popup.graduation_year">
                                                          <div class="form-group">
                                                            <label for="graduation_year" class="form-label">
                                                              {{ fieldsAd.popup.graduation_year.label }}<sup class="text-danger" v-if="fieldsAd.popup.graduation_year.required">*</sup>
                                                            </label>
                                                            <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="familyForm.graduation_year">
                                                              <option value="">Select Graduation Year</option>
                                                              <option v-for="year in GraduationyearArr" :value="year" :key="year">{{ year }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.graduation_year}}</span>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.jersey_number.colClass]" v-if="familyForm.jersey_number && fieldsAd.popup.jersey_number">
                                                          <div class="form-group">
                                                            <label for="jersey_number" class="form-label">
                                                              {{ fieldsAd.popup.jersey_number.label }}<sup class="text-danger" v-if="fieldsAd.popup.jersey_number.required">*</sup>
                                                            </label>
                                                            <input class="form-control" :placeholder="fieldsAd.popup.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="familyForm.jersey_number" name="form.jersey_number" value="">
                                                            <span class="text-sm text-danger">{{ errors.jersey_number}}</span>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.primary_position.colClass]" v-if="fieldsAd.popup.primary_position">
                                                          <div class="form-group">
                                                            <label for="primary_position" class="form-label">{{ fieldsAd.popup.primary_position.label }} <sup class="text-danger" v-if="fieldsAd.popup.primary_position.required">*</sup></label>
                                                            <select class="form-control" id="primary_position" v-model="familyForm.primary_position" name="form.primary_position">
                                                              <option value="">Select</option>
                                                              <option  v-for="(listOfPrimaryPosition,key) in fieldsAd.popup.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.primary_position}}</span>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.secondary_position.colClass]" v-if="fieldsAd.popup.secondary_position">
                                                          <div class="form-group">
                                                            <label for="secondary_position" class="form-label">{{ fieldsAd.popup.secondary_position.label }} <sup class="text-danger" v-if="fieldsAd.popup.secondary_position.required">*</sup></label>
                                                            <select class="form-control" id="secondary_position" v-model="familyForm.secondary_position" name="form.secondary_position">
                                                              <option value="">Select</option>
                                                              <option  v-for="(listOfSecondaryPosition,key) in fieldsAd.popup.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
                                                            </select>
                                                            <span class="text-sm text-danger">{{ errors.secondary_position}}</span>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.throw.colClass]" v-if="fieldsAd.popup.throw">
                                                          <div class="form-group">
                                                            <label for="throw" class="form-label">{{ fieldsAd.popup.throw.label }} <sup class="text-danger" v-if="fieldsAd.popup.throw.required">*</sup></label>
                                                            <div class="form-group" v-if="fieldsAd.popup.throw.choices">
                                                              <template v-for="(choice, key) in fieldsAd.popup.throw.choices">
                                                                <input type="radio" :id="choice.value" v-model="familyForm.throws" name="form.throws" :value="choice.value" :key="'radio_' + key">
                                                                <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                                                              </template>
                                                              <span class="text-sm text-danger">{{ errors.throw}}</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.bats.colClass]">
                                                          <div class="form-group">
                                                            <label for="bats" class="form-label">{{ fieldsAd.popup.bats.label }} <sup class="text-danger" v-if="fieldsAd.popup.bats.required">*</sup></label>
                                                            <div class="form-group" v-if="fieldsAd.popup.bats.choices">
                                                              <template v-for="(choice, key) in fieldsAd.popup.bats.choices">
                                                                <input type="radio" :id="choice.value" v-model="familyForm.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                                                                <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                                                              </template>
                                                              <span class="text-sm text-danger">{{ errors.bats}}</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.college_committed.colClass]">
                                                          <div class="form-group">
                                                            <label for="college_committed" class="form-label">{{ fieldsAd.popup.college_committed.label }} <sup class="text-danger" v-if="fieldsAd.popup.college_committed.required">*</sup></label>
                                                            <div class="form-group" v-if="fieldsAd.popup.college_committed.choices">
                                                              <template v-for="(choice, key) in fieldsAd.popup.college_committed.choices">
                                                                <input type="radio" :id="choice.value" v-model="familyForm.college_list" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                                                                <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                                                              </template>
                                                              <span class="text-sm text-danger">{{ errors.college_committed}}</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div :class="[fieldsAd.popup.college_committed.yes_fields.college_list.colClass]" v-if="fieldsAd.popup.college_committed.yes_fields.college_list && collegeCommitted == 1">
                                                          <div class="form-group">
                                                            <label for="college_list" class="form-label" v-if="fieldsAd.popup.college_committed.yes_fields.college_list.label !=''">{{ fieldsAd.popup.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="fieldsAd.popup.college_committed.yes_fields.college_list.required">*</sup></label>
                                                            <Select2 id="college_list" v-model="familyForm.college_committed" :options="collegeOptions" name="form.college_list" :settings="{ placeholder: 'Click to Select the College'}" />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </b-modal>
                                                  <div v-for="(family_member, findex) in verifyData.families" :key="findex">
                                                    <b-card no-body class="mb-1 players-toggle" :key="'propType'+findex" v-if="(isTeamRegistration && family_member.personal_details!=undefined && family_member.personal_details.relation == 1) || !isTeamRegistration">
                                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                                      <b-button block v-b-toggle="'mType-' + findex" variant="info">
                                                        {{ (family_member.relation && !isTeamRegistration)? relations[family_member.relation] : (isTeamRegistration && family_member.personal_details!=undefined)?teamRelations[family_member.personal_details.relation]:''}} Information
                                                      </b-button>
                                                      <div class="d-flex float-right">
                                                        <button type="button" class="btn-edit mr-1" @click="editFamily(findex)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</button>
                                                      </div>
                                                    </b-card-header>
                                                    <b-collapse :id="'mType-' + findex" accordion="mtype-accordion" role="tabpanel" :visible="openCollapse == findex">
                                                      <b-card-body>
                                                        <b-card-text>
                                                          <div class="row">
                                                            <div class="col-md-6 col-sm-6">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.firstName.label ? fieldsAd.popup.firstName.label : 'First Name' }}</label>
                                                              <span v-if="family_member.first_name">{{ family_member.first_name }}</span>
                                                              <span v-if="isTeamRegistration && family_member.personal_details!=undefined">{{ family_member.personal_details.first_name }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.middleInitial.label ? fieldsAd.popup.middleInitial.label : 'Middle Initial' }}</label>
                                                              <span v-if="family_member.middle_name">{{ family_member.middle_name || 'N/A' }}</span>
                                                              <span v-if="isTeamRegistration && family_member.personal_details!=undefined">{{ family_member.personal_details.middle_name || 'N/A' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.LastName.label ? fieldsAd.popup.LastName.label : 'Last Name' }}</label>
                                                              <span v-if="family_member.last_name">{{ family_member.last_name }}</span>
                                                              <span v-if="isTeamRegistration && family_member.personal_details!=undefined">{{ family_member.personal_details.last_name }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.relation">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.relation.label ? fieldsAd.popup.relation.label : 'Relationship' }}</label>
                                                              <span v-if="family_member.relation">{{ family_member.relation == 1 ? 'Spouse' : 'Child' }}</span>
                                                              <span v-if="isTeamRegistration && family_member.personal_details!=undefined">{{ family_member.personal_details.relation == 1 ? 'Player' : '' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.birthDay">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.birthDay.label ? fieldsAd.popup.birthDay.label : 'Birthday' }}</label>
                                                              <span v-if="family_member.dob">{{ family_member.dob }}</span>
                                                              <span v-if="isTeamRegistration && family_member.personal_details!=undefined">{{ family_member.personal_details.dob }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.gender">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.gender.label ? fieldsAd.popup.gender.label : 'Gender' }}</label>
                                                              <span v-if="family_member.gender">{{ family_member.gender }}</span>
                                                              <span v-if="isTeamRegistration && family_member.personal_details!=undefined">{{ family_member.personal_details.gender }}</span>
                                                            </div>
                                                            <div class="col-sm-12" v-if="fieldsAd.popup.email">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.email.label ? fieldsAd.popup.email.label : 'Email' }}</label>
                                                              <span>{{ family_member.contact_details.email }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.homephone">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.homephone.label ? fieldsAd.popup.homephone.label : 'Home Phone' }}</label>
                                                              <span>{{ family_member.contact_details.phone_1 }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.mobile">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.mobile.label ? fieldsAd.popup.mobile.label : 'Mobile Phone' }}</label>
                                                              <span>{{ family_member.contact_details.phone_2 || 'N/A' }}</span>
                                                            </div>
                                                            <div v-if="registerationStep2 && fieldsAd.popup.clubandteam" class="col-md-6">
                                                              <label>{{ subdomain != 'shooting' ? registerationStep2 && fieldsAd.popup.clubandteam.label : 'Club / Team' }}</label>
                                                              <span>{{ family_member.affiliated_clubs_names }}</span>
                                                              <!-- {{family_member}} -->
                                                            </div>
                                                            <div v-if="registerationStep2 && fieldsAd.popup.home_federation" class="col-md-6">
                                                              <div class="form-group">
                                                                <label class="form-label">{{ fieldsAd.popup.home_federation.label? fieldsAd.popup.home_federation.label : 'Federation' }}</label>
                                                                <span>{{ family_member.home_federation || "N/A" }}</span>
                                                              </div>
                                                            </div>
                                                            <div v-if="registerationStep2 && fieldsAd.popup.skier_license" class="col-md-6">
                                                              <div class="form-group">
                                                                <label class="form-label">{{ fieldsAd.popup.skier_license.label ? fieldsAd.popup.skier_license.label : 'Foreign Federation ID' }}</label>
                                                                <span>{{ family_member.skier_license || "N/A" }}</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="registerationStep2 && fieldsAd.popup.sport_discipline && fieldsAd.popup.sport_discipline.first_choice && fieldsAd.popup.sport_discipline.first_choice.multiple == undefined">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.sport_discipline.first_choice.label ? fieldsAd.popup.sport_discipline.first_choice.label : 'First Choice'}}</label>
                                                              <span>{{ family_member.first_choice_txt }}</span>
                                                            </div>
                                                            <div v-if="registerationStep2 && fieldsAd.popup.sport_discipline && fieldsAd.popup.sport_discipline.second_choice" class="col-md-6">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.sport_discipline.second_choice.label ? fieldsAd.popup.sport_discipline.second_choice.label : 'Second Choice'}}</label>
                                                              <span>{{ family_member.second_choice_txt }}</span>
                                                            </div>
                                                            <div class="col-md-6" v-if="family_member.member && family_member.member.disciplines">
                                                              <label><strong>{{ registerationStep2 && fields.general.sport_discipline && fields.general.sport_discipline.first_choice && fields.general.sport_discipline.first_choice.label ? fields.general.sport_discipline.first_choice.label : 'Disciplines' }}</strong></label>
                                                              <ul v-if="family_member.member.disciplines" class="pl-3">
                                                                <li v-for="(item, k) in family_member.member.disciplines" :key="'v-disp'+k">
                                                                  {{ item.name }}
                                                                </li>
                                                              </ul>
                                                            </div>
                                                            <div class="col-md-6" v-if="fieldsAd.popup.race">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.race.label ? fieldsAd.popup.race.label : 'Race' }}</label>
                                                              <span>{{ family_member.diversity_txt || 'N/A' }}</span>
                                                            </div>
                                                            <div class="col-md-6" v-if="registerationStep2 && fieldsAd.popup.ethnicity">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.ethnicity.label ? fieldsAd.popup.ethnicity.label : 'Ethnicity'}}</label>
                                                              <span>{{ family_member.ethnicity }}</span>
                                                            </div>
                                                            <div class="col-md-6" v-if="registerationStep2 && fieldsAd.popup.disability_status">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.disability_status.label ? fieldsAd.popup.disability_status.label : 'Disability'}}</label>
                                                              <span>{{ family_member.disability }}</span>
                                                            </div>
                                                            <div class="col-md-6" v-if="registerationStep2 && fieldsAd.popup.veteran_status && family_member.relation != 2">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.veteran_status.label ? fieldsAd.popup.veteran_status.label : 'Veteran Status'}}</label>
                                                              <span>{{ family_member.veteran_status }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.agl_player_id && fieldsAd.popup.agl_player_id.label ? fieldsAd.popup.agl_player_id.label : 'AGL Player Id' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.agl_player_id }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.graduation_year && fieldsAd.popup.graduation_year.label ? fieldsAd.popup.graduation_year.label : 'Graduation Year' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.graduation_year }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.jersey_number.label ? fieldsAd.popup.jersey_number.label : 'Jersey Number' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.jersey_number }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.primary_position && fieldsAd.popup.primary_position.label ? fieldsAd.popup.primary_position.label : 'Primary Position' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.primary_position }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.secondary_position && fieldsAd.popup.secondary_position.label ? fieldsAd.popup.secondary_position.label : 'Secondary Position' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.secondary_position }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.bats && fieldsAd.popup.bats.label ? fieldsAd.popup.bats.label : 'Bats' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.bats==1?'left':'Right' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.throw && fieldsAd.popup.throw.label ? fieldsAd.popup.throw.label : 'Throw' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined">{{ family_member.personal_details.throws==1?'left':'Right' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                              <label>{{ registerationStep2 && isTeamRegistration && fieldsAd.popup.college_committed && fieldsAd.popup.college_committed.label ? fieldsAd.popup.college_committed.label : 'College Committed' }}</label>
                                                              <span v-if="family_member.personal_details!=undefined && family_member.personal_details.college_details != undefined">{{ family_member.personal_details.college_details.name }}</span>
                                                              <span v-else>NA</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.address1">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.address1.label ? fieldsAd.popup.address1.label : 'Mailing Address' }}</label>
                                                              <span>{{ family_member.contact_details.address1 || 'N/A' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.address2">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.address2.label ? fieldsAd.popup.address2.label : 'Address 2' }}</label>
                                                              <span>{{ family_member.contact_details.address2 || 'N/A' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.city">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.city.label ? fieldsAd.popup.city.label : 'City' }}</label>
                                                              <span>{{ family_member.contact_details.city || 'N/A' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.country">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.country.label ? fieldsAd.popup.country.label : 'Country' }}</label>
                                                              <span>{{ family_member.contact_details.country || 'N/A' }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.state_code ">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.state_code.label ? fieldsAd.popup.state_code.label : 'State' }}</label>
                                                              <span v-if="family_member.contact_details.country == 'USA' || family_member.contact_details.country == 'Canada'">{{
                                                                  family_member.contact_details.state_code  || 'N/A'
                                                                }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="fieldsAd.popup.zip ">
                                                              <label>{{ registerationStep2 && fieldsAd.popup.zip.label ? fieldsAd.popup.zip .label : 'Postal Code ' }}</label>
                                                              <span>{{ family_member.contact_details.zip  || 'N/A' }}</span>
                                                            </div>
                                                          </div>
                                                        </b-card-text>
                                                      </b-card-body>
                                                    </b-collapse>
                                                    </b-card>
                                                  </div>
                                                </div>
                                              <span class="text-sm text-danger d-block text-right">{{ verifyErrors }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-show="registerationStep3" class="col-md-12 mb-view-pl mb-view-pr">
                                    <div class="card waiver" v-for="(waiver, waiver_index) in waiverData" :key="'waiver_'+waiver_index">
                                            <div :id="waiver_index" v-if="waiver.user_info.length">
                                                <div v-show="waiversList[waiver_index] == true" :data-value="waiversList[waiver_index]">
                                                    <div v-if="waiver.type == 'SafeSport'" class="card-header register-title">
                                                        <h3>Safe Sport Acknowledgment </h3>
                                                    </div>
                                                    <div v-else-if="(membership.member_type_id == 4 && !isTeamRegistration) && waiver.type != 'SafeSport'" class="card-header register-title">
                                                        <h3>Club Agreement </h3>
                                                    </div>
                                                  <div v-else-if="(membership.member_type_id == 4 && isTeamRegistration)" class="card-header register-title">
                                                    <h3>RELEASE AND WAIVER OF LIABILITY </h3>
                                                  </div>
                                                    <div v-else class="card-header register-title">
                                                        <h3>Participant Waiver and Release of Liability, Assumption of Risk and Indemnity Agreement</h3>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                                <form @submit.prevent="waiverFormSubmit" ref="waiverFormSubmit" name="waiverFormSubmit" method="post">
                                                                    <div class="col-md-12 text-justify">
                                                                        <div class="text-center mb-1" v-if="waiver.type != 'SafeSport'">
                                                                          <strong>This <span v-if="membership.member_type_id == 4 && !isTeamRegistration">club agreement</span>
                                                                          <span v-else>waiver</span> is for your membership effective from
                                                                            <span v-if="verifyEditData.membership_period != 999 && !isTeamRegistration">{{ waiver.user_info[0].start_date }} to {{ waiver.user_info[0].expires_at }}</span>
                                                                            <span v-else-if="!isTeamRegistration">{{ waiver.user_info[0].start_date }} till Lifetime</span>
                                                                            <span v-if="verifyEditData.membership_period != 999 && isTeamRegistration">{{ waiver.user_info[0].start_date }} to {{ waiver.user_info[0].expires_at }}</span>
                                                                        </strong>
                                                                        </div>
<!--                                                                        <div class="text-center mb-1" v-if="membership.id != 6"><strong>Member name : {{ waiver.user_info.member_name }}</strong></div>-->
<!--                                                                        <div  class="text-center mb-1" v-else><strong>Club name : {{ verifyClubInfoData.name }}</strong></div>-->
                                                                        <div v-html="waiver.waivers_content" class="mb-5"></div>
                                                                        <div v-if="subdomain == 'waterski'">

                                                                            <div v-if="membership.member_type_id == 4 && !isTeamRegistration">
                                                                                <span class="mb-2" style="display:block;">I hereby acknowledge that:</span>
                                                                                <ul>
                                                                                    <li>I am authorized to sanction an event on behalf of the Local Organizing Club (LOC); and</li>
                                                                                    <li>I have reviewed the sanctioning agreement and that the LOC agrees to its terms.</li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <div :class="subdomain=='alliancefastpitch'?'d-flex align-items-start mt-3':''" v-for="(waiver_user_info, waiver_user_info_index) in waiver.user_info" :key="waiver_user_info_index">
                                                                          <template v-if="membership.member_type_id == 4 &&!isTeamRegistration">
                                                                            <input type="checkbox" class="" :id="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" :name="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" v-model="waiverDataAgree[waiver_index].agree[waiver_user_info_index]">
                                                                            <label class="form-check-label">I
                                                                              <!-- <input type="text" maxlength="2" v-on:keypress="isLetter($event)" style="width: 40px" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials">  -->
                                                                              have read and accept the {{ prop_general.site_name }} club agreement dated on: <input type="text" class="datepicks" disabled :value="currentDate"></label>
                                                                          </template>
                                                                          <template v-if="membership.member_type_id != 4 && !isTeamRegistration">
                                                                            <input type="checkbox" class="" :id="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" :name="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" v-model="waiverDataAgree[waiver_index].agree[waiver_user_info_index]">
                                                                            <div class="waiverinner">
                                                                            <input v-if="membership.member_type_id != 4 || (membership.member_type_id == 4 && isTeamRegistration)" class="waivertext" v-on:keypress="isLetter($event)" type="text" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" maxlength="2">
                                                                            </div>
                                                                            <label v-if="membership.member_type_id != 4" class="form-check-label dinline">I,
                                                                                <span v-if="waiver.type == 'Minor' && membership.id == 2 || (waiver.type == 'SafeSport' && membership.id == 2 && waiverData[0].type =='Minor')">
                                                                                    <input class="waivertext fullnameofwaiver" v-on:keypress="isLetter($event)" type="text" :id="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member" :name="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].signed_member" maxlength="2">
                                                                                </span>
                                                                                <span v-else-if="waiver.type == 'Minor'">{{waiver.user_info[waiver_user_info_index].signed_member}}</span>
                                                                                <span v-else>{{ waiver.user_info[waiver_user_info_index].member_name }}</span>

                                                                                <span v-if="waiver.type == 'SafeSport'">, have read and accept  the {{ prop_general.site_name }} Safesport Acknowledgment dated on: </span>
                                                                                <span v-else-if="waiver.type == 'Minor'">, accept the above waiver for {{ waiver.user_info[waiver_user_info_index].member_name }} on this date: </span>
                                                                                <span v-else>, accept the above waiver on this date: </span>
                                                                            <input type="text" class="datepicks" disabled :value="currentDate"></label>
                                                                            <label v-else class="form-check-label">I
                                                                                <!-- <input type="text" maxlength="2" v-on:keypress="isLetter($event)" style="width: 40px" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials">  -->
                                                                                have read and accept the {{ prop_general.site_name }} club agreement dated on: <input type="text" class="datepicks" disabled :value="currentDate"></label>
                                                                          </template>
                                                                          <template v-if="fields.waivers && isTeamRegistration">
                                                                            <input type="checkbox" class="mt-2" :id="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" :name="waiverDataAgree[waiver_index].agree[waiver_user_info_index]" v-model="waiverDataAgree[waiver_index].agree[waiver_user_info_index]">
                                                                            <div class="waiverinner d-flex">
                                                                              <input v-if="isTeamRegistration" class="waivertext" v-on:keypress="isLetter($event)" type="text" :id="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" :name="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" v-model="waiverData[waiver_index].user_info[waiver_user_info_index].member_initials" maxlength="2">
                                                                              <label v-if="isTeamRegistration" class="form-check-label" v-html="waiverContent[waiver_user_info_index].coach_acknowledgement">I</label>
                                                                            </div>
                                                                          </template>
<!--                                                                          <template v-if="fields.waivers && isTeamRegistration">
                                                                            <br>
                                                                            <input type="checkbox" class="" :id="waiverDataAgree[0].agree[1]" :name="waiverDataAgree[0].agree[1]" v-model="waiverDataAgree[0].agree[1]">
                                                                            <div class="waiverinner">
                                                                              <input v-if="isTeamRegistration" class="waivertext" v-on:keypress="isLetter($event)" type="text" :id="waiverData[0].user_info[0].member_initials" :name="waiverData[0].user_info[0].member_initials" v-model="waiverData[0].user_info[0].member_initials" maxlength="2">
                                                                              <label v-if=" isTeamRegistration" class="form-check-label" v-html="fields.waivers.coach_acknowledgement_II">I</label>
                                                                            </div>
                                                                          </template>-->
                                                                            <!-- <p class="font-13 mt-4 text-danger"><em>Please check the box and initial in the space provided.</em></p> -->
                                                                            <p class="text-sm text-danger mt-3">{{ waiverErrors[waiver_index].agree[waiver_user_info_index] }}</p>
                                                                            <p class="text-sm text-danger mt-3">{{ waiverErrors[waiver_index].initials[waiver_user_info_index] }}</p>
                                                                            <p class="text-sm text-danger mt-3">{{ waiverErrors[waiver_index].signed_member[waiver_user_info_index] }}</p>
                                                                        </div>
                                                                      <div class="row mt-3" v-if="membership.member_type_id == 4 && !isTeamRegistration">
                                                                        <div class="col-md-12">
                                                                          <label><strong>Submitted by</strong></label>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                          <div class="form-group">
                                                                            <label class="form-label">First Name</label>
                                                                            <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="clubSubmittedBy.first_name" v-model="clubSubmittedBy.first_name">
                                                                            <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_first_name }}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                          <div class="form-group">
                                                                            <label class="form-label">Last Name</label>
                                                                            <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="clubSubmittedBy.last_name" v-model="clubSubmittedBy.last_name">
                                                                            <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_last_name }}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                          <div class="form-group">
                                                                            <label class="form-label">Club Position</label>
                                                                            <input type="text" class="form-control" maxlength="45" placeholder="President" name="clubSubmittedBy.position" v-model="clubSubmittedBy.position">
                                                                            <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_position }}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                          <div class="form-group">
                                                                            <label class="form-label">Email</label>
                                                                            <input type="text" class="form-control" maxlength="45" placeholder="" name="clubSubmittedBy.email" v-model="clubSubmittedBy.email">
                                                                            <span class="text-sm text-danger">{{ waiverClubErrors.club_submitted_email }}</span>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                      <b-modal id="SpouseWaiverInfoModal" title="Spouse Waiver" ok-only>
                                        <p>Spouses (age 18 and over) will need to log in to their membership dashboard after the completion of the family registration to sign their waiver.</p>
                                      </b-modal>

                                    <div v-show="registerationStep4" class="col-md-12">
                                         <div class="row payment-view">
                                             <b-overlay :show="showLoader" no-wrap></b-overlay>
                                            <div class="col-md-6 mb-view-pr mb-view-pl">
                                                <div class="card-body card">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h3>Total Amount</h3>
                                                    </div>
                                                    <div class="col-md-12">
                                                    <ul class="list-group mb-3 payment-details">
                                                        <li class="d-flex justify-content-between lh-sm">
                                                        <div>
                                                        <h6 class="my-0" v-if="!isTeamRegistration"><strong>{{ membership.type | filterBr }}</strong></h6>
                                                        <h6 class="my-0" v-if="!isChild && isTeamRegistration && (coach_count == 0) && CheckoutText && CheckoutText.registration_text && membership.id !=5"><strong>{{ CheckoutText.registration_text | filterBr }}</strong></h6>
                                                        <h6 class="my-0" v-if="isChild && isTeamRegistration && CheckoutText && CheckoutText.individual_registration_text || (isTeamRegistration && membership.id ==5)"><strong>{{ CheckoutText.individual_registration_text | filterBr }}</strong></h6>
                                                        </div>
                                                        <span class="text-muted" v-if="!isTeamRegistration"><strong>${{ membership.fee }}</strong></span>
                                                        <span class="text-muted" v-else-if="isChild"><strong>${{ individual_registration_bg_fee }}</strong></span>
                                                        <span class="text-muted" v-else-if="coach_count == 0 || membership.id ==5"><strong>${{ registration_bg_fee }}</strong></span>
                                                        </li>
                                                      <li class="d-flex justify-content-between lh-sm" v-if="!isChild && isTeamRegistration && (team_count == 0) && CheckoutText && CheckoutText.team_registration_text && membership.id !=5">
                                                        <div>
                                                          <h6 class="my-0"><strong>{{ CheckoutText.team_registration_text | filterBr }}</strong></h6>
                                                        </div>
                                                        <span class="text-muted"><strong>${{ membership.fee }}</strong></span>
                                                      </li>
                                                      <li class="d-flex justify-content-between lh-sm" v-if="!isChild && isTeamRegistration && CheckoutText && CheckoutText.player_registration_text && player_registration_total_fee !=0">
                                                        <div>
                                                          <h6 class="my-0"><strong>{{ CheckoutText.player_registration_text | filterBr }}</strong></h6>
                                                        </div>
                                                        <span class="text-muted"><strong>${{ player_registration_total_fee }}</strong></span>
                                                      </li>
                                                        <!--<li class=" d-flex justify-content-between lh-sm">
                                                        <div>
                                                        <h6 class="my-0">Affiliate</h6>
                                                        </div>
                                                        <span class="text-muted"><strong>$10.00</strong></span>
                                                        </li>-->
                                                        <li v-if="donated.amount != '' && !isTeamRegistration" class=" d-flex justify-content-between lh-sm">
                                                        <div class="cartitemcard">
                                                            <span class="remove" @click="removeDonation()"><i class="fas fa-trash-alt"></i></span>
                                                        <!-- <h6 class="my-0" v-if="donated.type != ''">{{ listOfDonationSportDiscipline[donated.type] }}</h6> -->
                                                        <h6 class="my-0" v-if="donated.type != '' && fields['donation']['disciplines']['options'][donated.type]">{{ fields['donation']['disciplines']['options'][donated.type] }}</h6>
                                                        <h6 class="my-0" v-else>Donation</h6>
                                                        </div>
                                                            <span class="text-muted"><strong>${{ donated.amount | fullNumber }} </strong></span>
                                                        </li>
                                                        <li v-if="addons && donated.amount != ''" class=" d-flex justify-content-between lh-sm">
                                                          <div class="cartitemcard">
                                                           <span class="remove" @click="removeDonationBasic()"><i class="fas fa-trash-alt"></i></span>
                                                            <!-- <h6 class="my-0" v-if="donated.type != ''">{{ listOfDonationSportDiscipline[donated.type] }}</h6> -->
                                                            <h6 class="my-0" v-if="addons && donated.amount != ''">{{ fields.addons.alfa_donation.label }}</h6>
                                                            <h6 class="my-0" v-else>Donation</h6>
                                                          </div>
                                                          <span class="text-muted"><strong>${{ donated.amount | fullNumber }} </strong></span>
                                                        </li>
                                                        <li v-if="addons && agl_amt != '' && agl_amt != 0" class=" d-flex justify-content-between lh-sm">
                                                          <div class="cartitemcard">
                                                            <span class="remove" @click="removeAddons(isCheckAddonsExist(1,true))"><i class="fas fa-trash-alt"></i></span>
                                                            <!-- <h6 class="my-0" v-if="donated.type != ''">{{ listOfDonationSportDiscipline[donated.type] }}</h6> -->
                                                            <h6 class="my-0" v-if="addons && agl_amt != ''">{{ fields.addons.agl_package.label }}</h6>
                                                            <h6 class="my-0" v-else>Donation</h6>
                                                          </div>
                                                          <span class="text-muted"><strong>${{ verifyData.addons.agl_amt  }} </strong></span>
                                                        </li>
                                                        <li v-if="addons && usssa_amt != '' && usssa_amt != 0" class=" d-flex justify-content-between lh-sm">
                                                          <div class="cartitemcard">
                                                            <span class="remove" @click="removeAddons(isCheckAddonsExist(2,true))"><i class="fas fa-trash-alt"></i></span>
                                                            <!-- <h6 class="my-0" v-if="donated.type != ''">{{ listOfDonationSportDiscipline[donated.type] }}</h6> -->
                                                            <h6 class="my-0" v-if="addons && usssa_amt != '' ">{{ fields.addons.usssa_membership_package.label }}</h6>
                                                            <h6 class="my-0" v-else>Donation</h6>
                                                          </div>
                                                          <span class="text-muted"><strong>${{ verifyData.addons.usssa_amt }} </strong></span>
                                                        </li>
                                                        <li v-if="addons && insurance_amt != ''  && insurance_amt != 0" class=" d-flex justify-content-between lh-sm">
                                                          <div class="cartitemcard">
                                                            <span class="remove" @click="removeAddons(isCheckAddonsExist(3,true))"><i class="fas fa-trash-alt"></i></span>
                                                            <!-- <h6 class="my-0" v-if="donated.type != ''">{{ listOfDonationSportDiscipline[donated.type] }}</h6> -->
                                                            <h6 class="my-0" v-if="addons && insurance_amt != ''">{{ fields.addons.team_insurance.label }}</h6>
                                                            <h6 class="my-0" v-else>Donation</h6>
                                                          </div>
                                                          <span class="text-muted"><strong>${{ verifyData.addons.insurance_amt }} </strong></span>
                                                        </li>
                                                        <template v-for="(vinsurance,i) in verifyClubInfoData.insured_members">
                                                        <li v-if="verifyClubInfoData.insured_members.length > 0" class=" d-flex justify-content-between lh-sm" :key="i">
                                                            <div class="cartitemcard">
                                                                <span class="remove" @click="removeInsurance(vinsurance.id)"><i class="fas fa-trash-alt"></i></span>
                                                                <h6 class="my-0"><strong>Certificate of Insurance - {{ vinsurance.company_name }}</strong></h6>
                                                            </div>
                                                            <span class="text-muted"><strong>${{ insuranceFee | fullNumber }}</strong></span>
                                                        </li>
                                                        </template>
                                                        <hr v-if="cartProductData.length >= 1">
                                                        <template v-for="(cartProduct,i) in cartProductData">
                                                        <li v-if="cartProductData.length >= 1"  class="d-flex justify-content-between lh-sm" :key="i">
                                                            <div class="cartitemcard">
                                                                <span class="remove" @click="removeFromCart(cartProduct.id)"><i class="fas fa-trash-alt"></i></span>
                                                                <h6 class="my-0"><strong>{{ cartProduct.name+' '+cartProduct.variant_name  }}  ${{cartProduct.price}} x {{ cartProduct.quantity }}</strong></h6>


                                                            </div>

                                                            <span class="text-muted"><strong>${{ cartProduct.total }}</strong></span>
                                                        </li>
                                                        </template>
                                                        <hr v-if="clubInfoData.club_sanction_data.selected == true">
                                                        <li v-if="clubInfoData.club_sanction_data.selected == true" class="d-flex justify-content-between lh-sm">
                                                          <div>
                                                            <h6 class="my-0"><strong>Purchase Practice/Exhibition Sanctioning </strong></h6>
                                                          </div>
                                                          <span class="text-muted"><strong> ${{ sanctionFee }}</strong></span>
                                                        </li>
                                                        <hr>
                                                        <li class=" d-flex justify-content-between  ">
                                                            <div class="coupon-inner mb-3">
                                                                <h6 class="my-0"><strong>Coupon</strong></h6>
                                                            </div>
                                                            <div class="">
                                                                <input type="text" class="form-control coupon-text" v-model="couponApplied.coupon_code"><button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{ button.coupon }}</button>
                                                                <span class="text-sm text-danger">{{ couponErrors.error}}</span>
                                                                <span class="text-sm text-success">{{ couponErrors.success}}</span>
                                                            </div>
                                                        </li>
                                                        <!-- <hr v-if="cartProductData.length >= 1"> -->
                                                        <!-- <li v-if="this.placeOrderData.registration_id >= 1" class="d-flex justify-content-between lh-sm">
                                                            <div>
                                                                <h6 class="my-0 "><strong>Merchandise Total</strong></h6>
                                                            </div>
                                                            <span class="text-muted"><strong>${{ totals.merchandise_total }}</strong></span>
                                                        </li> -->
                                                        <hr v-if="this.placeOrderData.registration_id >= 1">
                                                        <li v-if="this.placeOrderData.registration_id >= 1" class="d-flex justify-content-between lh-sm">
                                                          <div>
                                                            <h6 class="my-0">Shipping Charge - {{ cartProductCount }} Item(s)</h6>
                                                          </div>
                                                          <span class="text-muted"><strong>${{ totals.shipping_total }}</strong></span>
                                                        </li>
                                                        <hr v-if="this.placeOrderData.registration_id >= 1">
                                                        <li v-if="couponApplied.status == 1" class="d-flex justify-content-between lh-sm">
                                                        <div>
                                                        <h6 class="my-0"><strong><a href="javascript:void(0);" class="text-danger" @click="removeCoupon()"><i class="fas fa-trash-alt"></i></a> Discount - {{ couponApplied.text }} </strong></h6>
                                                        </div>
                                                        <span class="text-muted"><strong>-${{ couponApplied.amount }}</strong></span>
                                                        </li>
                                                        <hr v-if="couponApplied.status == 1">
                                                        <li class="d-flex justify-content-between lh-sm">
                                                            <div>
                                                                <h6 class="my-0"><strong>Sub Total</strong></h6>
                                                            </div>
                                                            <span class="text-muted"><strong>${{ totals.sub_total }}</strong></span>
                                                        </li>
                                                        <hr>
                                                        <template v-if="processingFeePercent">
                                                            <li class=" d-flex justify-content-between lh-sm">
                                                                <div class="processing-tooltip">
                                                                    <h6 class="my-0"><strong>Technology/Processing Fee ({{this.processingFeePercent}}%) <b-button v-b-tooltip.hover :title="this.getSiteProps('general.processingFeeInfo')"><img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></b-button></strong></h6>
                                                                </div>
                                                                <span class="text-danger"><strong>${{ processingFee }}</strong></span>
                                                            </li>
                                                            <hr>
                                                        </template>
                                                        <template v-if="stripeIntent.enable && totals.sub_total > 0">
                                                          <li class=" d-flex justify-content-between lh-sm">
                                                            <div class="processing-tooltip">
                                                              <h6 class="my-0"><strong>Technology & processing fee  <b-button v-b-tooltip.hover :title="this.getSiteProps('general.processingFeeInfo')"><img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></b-button></strong></h6>
                                                            </div>
                                                            <span class="text-danger"><strong>${{ this.stripeIntent.viewisportzFeePercent }}</strong></span>
                                                          </li>
                                                          <hr>
                                                          <li class=" d-flex justify-content-between lh-sm">
                                                            <div class="processing-tooltip">
                                                              <h6 class="my-0"><strong>Card processing fee  <b-button :title="this.getSiteProps('general.processingFeeInfo')"></b-button></strong></h6>
                                                            </div>
                                                            <span class="text-danger"><strong>${{ this.stripeIntent.viewprocessingFeePercent }}</strong></span>
                                                          </li>
                                                          <hr>
                                                        </template>
                                                        <li class=" d-flex justify-content-between lh-sm">
                                                        <div>
                                                        <h6 class="my-0"><strong>Grand Total</strong></h6>
                                                        </div>
                                                        <span class="text-danger"><strong>${{ totals.grand_total }}</strong></span>
                                                        </li>
                                                    </ul>
                                                    </div>


                                                </div>

                                                </div>
                                                <div class="card-body card" v-if="membership.member_type_id == 4 && !isTeamRegistration">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <h3>Club Details</h3>
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            <div class="modal-btn mb-modal-btn" v-show="subdomain != 'shooting'">
                                                                <b-button v-b-modal.modal-club-edit @click.prevent="personalEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="subdomain == 'shooting'">
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.clubname.label || 'Club Name'}}</label>
                                                            <span>{{ verifyClubInfoData.name }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.club_website.label || 'Club Website'}}</label>
                                                            <span>{{ verifyClubInfoData.web }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.club_visible_type.label || 'Type'}}</label>
                                                            <span>{{ verifyClubInfoData.visibility_type }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{fields.club_info.club_type.label || 'Club Type'}}</label>
                                                            <span>{{ verifyClubInfoData.club_type }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-else>
                                                        <div class="col-md-6">
                                                            <label>Club Name</label>
                                                            <span>{{ verifyClubInfoData.name }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Club Type</label>
                                                            <span>{{ clubType[verifyClubInfoData.type] }}</span>
                                                        </div>
                                                    </div>
<!--                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label>Club Name</label>
                                                            <span>{{ verifyClubInfoData.name }}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Full Name</label>
                                                             <span>{{ verifyClubInfoData.primary_contact.first_name }} {{ verifyClubInfoData.primary_contact.last_name }}</span>
                                                        </div>
                                                    </div>-->
                                                </div>
                                                <div class="card-body card" v-if="membership.member_type_id != 4">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <h3 v-if="!this.isTeamRegistration">Member Details</h3>
                                                            <h3 v-if="this.isTeamRegistration && membership.id !=5">Player Details</h3>
                                                            <h3 v-if="this.isTeamRegistration && membership.id ==5">Coach Details</h3>
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            <div class="modal-btn mb-modal-btn" v-show="subdomain != 'shooting'">
                                                                <b-button v-b-modal.modal-personal-edit @click.prevent="checkOutPersonalEditmodal"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                            <div class="col-md-6 col-sm-6">
                                                                <label>First Name</label>
                                                                <span>{{ tempverifyData.members.first_name }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6">
                                                                <label>Middle Initial</label>
                                                                <span>{{ tempverifyData.members.middle_name }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6">
                                                                <label>Last Name</label>
                                                                <span>{{ tempverifyData.members.last_name }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6">
                                                                <label><img v-bind:src="'/dist/img/memberregistration/birthday.png'" alt="birthday_icon">{{ registerationStep4 && fields.personal.birthday.label ? fields.personal.birthday.label : 'Birthday' }}</label>
                                                                <span>{{ tempverifyData.members.dob }}</span>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6" v-if="!isChild">
                                                                <label><img v-bind:src="'/dist/img/memberregistration/gender.png'" alt="gender_icon">Gender</label>
                                                                <span>{{ tempverifyData.members.gender }}</span>
                                                            </div>
<!--                                                            <div class="col-md-6 col-sm-6  ">
                                                                <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="cityzenship_icon">Citizenship</label>
                                                                <span>{{ verifyEditData.members.citizenship }}</span>
                                                            </div>-->

                                                    </div>
                                                    <div class="row" v-if="isChild && isTeamRegistration ||(isTeamRegistration && membership.id == 5)">
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.agl_player_id && fields.personal.agl_player_id.label ? fields.personal.agl_player_id.label : 'AGL Player Id' }}</label>
                                                      <span v-if="verifyEditData.members.agl_player_id!=undefined">{{ tempverifyData.members.agl_player_id }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration">
                                                      <label>{{isTeamRegistration && fields.personal.team_membership_id && fields.personal.team_membership_id.label ? fields.personal.team_membership_id.label : 'Team' }}</label>
                                                      <span>{{ tempverifyData.club_name }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.graduation_year && fields.personal.graduation_year.label ? fields.personal.graduation_year.label : 'Graduation Year' }}</label>
                                                      <span v-if="verifyEditData.members.graduation_year!=undefined">{{ tempverifyData.members.graduation_year }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.jersey_number.label ? fields.personal.jersey_number.label : 'Jersey Number' }}</label>
                                                      <span v-if="verifyEditData.members.jersey_number!=undefined">{{ tempverifyData.members.jersey_number }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.primary_position && fields.personal.primary_position.label ? fields.personal.primary_position.label : 'Primary Position' }}</label>
                                                      <span v-if="verifyEditData.members.primary_position!=undefined">{{ tempverifyData.members.primary_position }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.secondary_position && fields.personal.secondary_position.label ? fields.personal.secondary_position.label : 'Secondary Position' }}</label>
                                                      <span v-if="verifyEditData.members.secondary_position!=undefined">{{ tempverifyData.members.secondary_position }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.bats && fields.personal.bats.label ? fields.personal.bats.label : 'Bats' }}</label>
                                                      <span v-if="verifyEditData.members.bats!=undefined">{{ tempverifyData.members.bats==1?'Left':'Right' }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.throw && fields.personal.throw.label ? fields.personal.throw.label : 'Throw' }}</label>
                                                      <span v-if="verifyEditData.members!=undefined">{{ tempverifyData.members.throws==1?'Left':'Right' }}</span>
                                                    </div>
                                                    <div class="col-md-6 col-sm-6" v-if="isTeamRegistration && membership.id != 5">
                                                      <label>{{isTeamRegistration && fields.personal.college_committed && fields.personal.college_committed.label ? fields.personal.college_committed.label : 'College Committed' }}</label>
                                                      <span v-if="verifyEditData.members!=undefined && verifyEditData.members.college_details != undefined">{{ verifyEditData.members.college_details.name || 'NA' }}</span>
                                                      <span v-else>NA</span>
                                                    </div>
                                                  </div>

                                                </div>
<!--                                                <div class="card-body card">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <h3>Member 2 Details</h3>
                                                        </div>
                                                        <div class="d-flex col-md-6 text-right">
                                                           <button class="btn-edit"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon">Edit</button>
                                                        <button class="btn-remove"><img v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon">Remove</button>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                             <label>First Name</label>
                                                             <span>William</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label><img v-bind:src="'/dist/img/memberregistration/birthday.png'" alt="birthday_icon">Birthday</label>
                                                             <span>12/31/2010</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Last Name</label>
                                                             <span>Smith</span>
                                                        </div>

                                                        <div class="col-md-6">
                                                             <label><img v-bind:src="'/dist/img/memberregistration/gender.png'" alt="gender_icon">Gender</label>
                                                             <span>Male</span>
                                                        </div>

                                                         <div class="col-md-6">
                                                             <label><img v-bind:src="'/dist/img/memberregistration/cityzenship.png'" alt="relationship">Cityzenship</label>
                                                             <span>child</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                             <label>Club</label>
                                                             <span>Association of Ohio</span>
                                                        </div>
                                                    </div>

                                                </div>-->
                                            </div>
                                                <div class="col-md-6 mb-view-pl mb-view-pr">
                                                        <div class="card-body card card-wrapper">
                                                                    <div class="row">
                                                                        <template v-if="paymentForm.visible">
                                                                        <div class="col-md-12 card-info">
                                                                            <h3>Card Information</h3>
                                                                            <div class="text-right">
<!--                                                                            <img v-bind:src="'/dist/img/memberregistration/stripe.png'" alt="stripe">-->
                                                                            </div>
                                                                        </div>
                                                                        <template v-if="getSiteProps('general.paymentGateway') == 'stripe'">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group mb-0">
                                                                                    <label class="form-label">Card Number / Expiry Month and Year / CVC<sup class="text-danger">*</sup></label>
                                                                                    <stripe-element-card
                                                                                        v-show="registerationStep4"
                                                                                        ref="stripeForm"
                                                                                        :pk="stripeKey"
                                                                                        :hidePostalCode="true"
                                                                                        iconStyle="solid"
                                                                                        @token="stripeTokenCreated"
                                                                                        @error="captureStripeErrors"
                                                                                        @element-change="stripeFormChanges"
                                                                                    />
                                                                                    <!-- <span class="text-sm text-danger" v-if="stripeError">{{ stripeError }}</span> -->
                                                                                </div>
                                                                            </div>
                                                                        </template>
                                                                        <template v-else>
                                                                            <div class="col-md-12">
                                                                                <div class="form-group">
                                                                                    <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                                                                                    <input type="hidden" name="paymentForm.amount" v-model="paymentForm.amount">
                                                                                    <input type="hidden" name="paymentForm.registration_id" v-model="paymentForm.registration_id">
                                                                                    <input type="text" maxlength="16" placeholder="#### #### #### ####" class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" @keypress="isNumber($event)">
    <!--                                                                                <input class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" v-cardformat:formatCardNumber>-->
                                                                                    <span class="text-sm text-danger">{{ errors.cardNumber}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-5">
                                                                            <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                                                                                <select class="form-control form-select" id="expiration-month" required="" name="paymentForm.expiration_month" v-model="paymentForm.expiration_month">
                                                                                <option value="">Month</option>
                                                                                <option>01</option>
                                                                                <option>02</option>
                                                                                <option>03</option>
                                                                                <option>04</option>
                                                                                <option>05</option>
                                                                                <option>06</option>
                                                                                <option>07</option>
                                                                                <option>08</option>
                                                                                <option>09</option>
                                                                                <option>10</option>
                                                                                <option>11</option>
                                                                                <option>12</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.expiration_month}}</span>
                                                                                <span class="text-sm text-danger">{{ errors.expiration_month_valid}}</span>
                                                                            </div>
                                                                            <div class="col-md-3 mp-l-r pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
                                                                                <select class="form-select form-control" id="expiration-year" required="" name="paymentForm.expiration_year" v-model="paymentForm.expiration_year">
                                                                                <option value="">Year</option>
                                                                                <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.expiration_year}}</span>
                                                                            </div>
                                                                            <div class="col-md-3 classcvv">
                                                                                <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                                                                                <input style="margin-top:3px;" type="password" class="form-control" name="paymentForm.cvv" maxlength="4" v-model="paymentForm.cvv" @keypress="isNumber($event)">
                                                                                    <span class="text-sm text-danger">{{ errors.cvv}}</span>
                                                                            </div>
                                                                        </template>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                                                                                <input type="text" placeholder="William" class="form-control" id="billing_firstname" name="paymentForm.billing_firstname" v-model="paymentForm.billing_firstname">
                                                                                <span class="text-sm text-danger">{{ errors.billing_firstname}}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-12" v-if="this.getSiteProps('general.auto_renewal') && verifyEditData.membership_period != 999 && (membership.member_type_id != 4 || this.getSiteProps('general.club_auto_renewal_option'))">
                                                                         <div  class="form-check mt-3">
                                                                         <input type="checkbox" id="autorenewall" class="form-check-input" value="1" v-model="paymentForm.auto_renew">
                                                                             <label for="autorenewall" class="form-check-label light-font">
                                                                                 <div v-if="membership.member_type_id != 4">Membership</div><div v-else-if="membership.member_type_id == 4 && this.isTeamRegistration">Team</div><div v-else>Club membership</div> fees will auto-renew and credit card charged upon expiration</label>
                                                                             <span class="text-sm text-danger">{{ errors.auto_renew}}</span>
                                                                             </div>


                                                                        </div>
                                                                        <hr />
                                                                        <div class="col-md-12">
                                                                        <h3>Billing Address</h3>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label >Mailing Address<sup class="text-danger">*</sup></label>
                                                                                <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_addressline1" v-model="paymentForm.billing_addressline1">
                                                                                <span class="text-sm text-danger">{{ errors.billing_addressline1}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label>City<sup class="text-danger">*</sup></label>
                                                                                <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_city" v-model="paymentForm.billing_city">
                                                                                <span class="text-sm text-danger">{{ errors.billing_city}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 pr-0" v-if="paymentForm.billing_country == 'USA'">
                                                                            <div class="form-group">
                                                                                <label>State<sup class="text-danger">*</sup></label>
                                                                                <select class="form-control" id="state_code" name="paymentForm.billing_state_code" v-model="paymentForm.billing_state_code">
                                                                                  <option value="">Select</option>
                                                                                  <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code" :selected="paymentForm.billing_state_code == listOfState.state_code">{{ listOfState.state }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.billing_state_code}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 pr-0" v-else-if="isTeamRegistration && paymentForm.billing_country == 'Canada'">
                                                                          <div class="form-group">
                                                                            <label>State<sup class="text-danger">*</sup></label>
                                                                            <select class="form-control" id="state_code" name="paymentForm.billing_state_code" v-model="paymentForm.billing_state_code">
                                                                              <option  v-for="listOfState in fields.contact.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code" :selected="paymentForm.billing_state_code == listOfState.state_code">{{ listOfState.state }}</option>
                                                                            </select>
                                                                            <span class="text-sm text-danger">{{ errors.billing_state_code}}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div class="col-md-6 pr-0" v-else-if="paymentForm.billing_country == 'Canada'">
                                                                          <div class="form-group">
                                                                            <label>State<sup class="text-danger">*</sup></label>
                                                                            <select class="form-control" id="state_code" name="paymentForm.billing_state" v-model="paymentForm.billing_state">
                                                                              <option value="">Select</option>
                                                                              <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state" :selected="paymentForm.billing_state == listOfState.state">{{ listOfState.state }}</option>
                                                                            </select>
                                                                            <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div class="col-md-6" v-else>
                                                                          <div class="form-group">
                                                                              <label for="state_code" class="form-label">State</label>
                                                                              <input type="text" class="form-control" maxlength="45" v-model="paymentForm.billing_state" name="paymentForm.billing_state">
                                                                              <span class="text-sm text-danger">{{ errors.billing_state}}</span>
                                                                          </div>
                                                                        </div>
                                                                        <div class="col-md-6 pr-0">
                                                                            <div class="form-group">
                                                                                <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                                                                                <input type="text"  class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="paymentForm.billing_zip" v-model="paymentForm.billing_zip">
                                                                                <span class="text-sm text-danger">{{ errors.billing_zip}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group" v-if="!this.isTeamRegistration">
                                                                                <label>Country<sup class="text-danger">*</sup></label>
                                                                                <select class="form-control" id="country" @change="removeStateCode" v-model="paymentForm.billing_country" name="paymentForm.billing_country">
                                                                                    <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :value="listOfCountry.country" :selected="paymentForm.billing_country == listOfCountry.country">{{ listOfCountry.country }}</option>
                                                                                </select>
                                                                                <span class="text-sm text-danger">{{ errors.billing_country}}</span>
                                                                            </div>
                                                                            <div class="form-group" v-else>
                                                                              <label>Country<sup class="text-danger">*</sup></label>
                                                                              <select class="form-control" id="country" v-model="paymentForm.billing_country" name="paymentForm.billing_country">
                                                                                <option v-for="listOfCountry in fields.contact.country.options" :key="listOfCountry.country" :value="listOfCountry.country" :selected="paymentForm.billing_country == listOfCountry.country">{{ listOfCountry.country }}</option>
                                                                              </select>
                                                                              <span class="text-sm text-danger">{{ errors.billing_country}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12" v-if="this.getSiteProps('general.payment_fee_info')">
                                                                            <!-- <p class="mt-3 font-13" style="font-size:11px;margin-bottom:0;"><i>All transactions also include a 3% processing/technology fee.</i></p> -->
                                                                        </div>
                                                                            <hr />
                                                                        </template>
                                                                        <div class="col-md-12 payment-conf confirmsc">
                                                                            <h3>Confirm</h3>
                                                                                <!-- <p style="font-13">By submitting this information, you agree to the <router-link class-active="active" target="_blank" to="/privacy-policy">privacy policy</router-link> and <router-link target="_blank" class-active="active" to="/terms-and-conditions">Terms &amp; Conditions</router-link> policy, including
                                                                                AUTO-RENEWAL and understand that there are NO REFUNDS. </p> -->
                                                                                <p style="font-13" v-if="prop_confirmation.label" v-html="prop_confirmation.label"></p>
                                                                            <!-- <div  class="form-check "><input type="checkbox" id="payment_agree" class="form-check-input" v-model="paymentForm.agree" name="paymentForm.agree">
                                                                             <label for="payment_agree" class="form-check-label">  <input type="text" class="chkboxinput" v-model="paymentForm.sign" name="paymentForm.sign" maxlength="2" @keypress="isAlphabets($event)">
                                                                                <span :style="{ 'display': 'inline-block' }" v-if="prop_confirmation.agree" v-html="prop_confirmation.agree"></span>
                                                                             </label> -->
                                                                             <div  class=" ">
                                                                            <div class="d-flex">
                                                                                <input style="display:inline-block;" type="checkbox" id="payment_agree" class="" v-model="paymentForm.agree" name="paymentForm.agree">
                                                                            <div class="pay-screen" style="width:60px;">
                                                                                <input type="text" class="chkboxinput ml-2" v-model="paymentForm.sign" name="paymentForm.sign" maxlength="2" @keypress="isAlphabets($event)">
                                                                            </div>
                                                                             <label for="payment_agree" class="form-check-label ml-2">
                                                                                <span :style="{ 'display': 'inline-block' }" v-if="prop_confirmation.agree" v-html="prop_confirmation.agree"></span>
                                                                             </label>
                                                                            </div>
                                                                                <span v-if="prop_confirmation.note" v-html="prop_confirmation.note"></span>
                                                                                <span class="text-sm text-danger">{{ errors.payment_agree}}</span>
                                                                                <span class="text-sm text-danger">{{ errors.payment_sign}}</span>


                                                                             </div>
                                                                        </div>
                                                                    </div>
                                                        </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div v-show="registerationStep5" class="col-md-12">
                                        <div class="card-body confirmation">
                                            <div class="row">
                                                <div class="col-md-12 pt-5" v-if="registration_message_flag">
                                                    <h3 class="text-center text-success"> {{ this.registration_message_text.title }}</h3>
                                                    <div class="form-group text-center" v-html="this.registration_message_text.content">

                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-5" v-else>
                                                    <h3 class="text-center text-success" v-if="!isTeamRegistration">Thanks for the Registration.</h3>
                                                    <h3 class="text-center text-success" v-else>Thank you for joining the Alliance.</h3>
                                                    <div class="form-group text-center">
<!--                                                        <label class="form-label">Transaction Id</label>-->
                                                    <span>{{ paymentData }}</span>
                                                    <p>You will receive an email shortly regarding this transaction along with your receipt and membership information.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-md-12 text-center">
                                                    <!-- <a :href="receipt_url" class="btn btn-outline-primary mr-3">
                                                        <i class="far fa-file-pdf"></i> Download PDF
                                                    </a> -->
                                                    <router-link to="/member/login" class="btn btn-outline-dark mr-3" v-if="membership.member_type_id != 4">
                                                        <i class="fas fa-lock"></i> Login as Member
                                                    </router-link>
                                                    <router-link to="/club/login" class="btn btn-outline-dark mr-3" v-if="membership.member_type_id == 4 && !isTeamRegistration">
                                                        <i class="fas fa-lock"></i> Login as Club
                                                    </router-link>
                                                    <a href="/memberregistration" class="btn btn-outline-dark">
                                                        <i class="fas fa-user-plus"></i> Register New Member
                                                    </a>
                                                                                                    
                                                </div>
                                            </div>
                                            <div v-if="teamAddonsBtn.enable && !isChild" class="row mt-3">
                                              <div class="col-md-12 addons-confirmation-page">
                                                <template v-for="(addon, id) in teamAddonsBtn.addons" class="mt-3">
                                                  <p :key="id" class="text-bold">
                                                    {{ addon.title }}
                                                  </p>
                                                  <p :key="id" v-html="addon.link"></p>
                                                </template>  
                                              </div>
                                            </div>  
                                        </div>
                                    </div>

                                    <div class="col-md-12 text-right pad-50 mb-next-prev" v-if="registerationStep  != 1 && registerationStep  != 5" style="height:20vh;">

                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <b-overlay :show="showLoader" no-wrap></b-overlay>
                                            <button type="button" v-if="registerationStep  != 1 && registerationStep  != 5" @click.prevent="registrationPrevstep"  class="btn btn-outline-primary cancelbtn btn-previious  "><i class="fas fa-chevron-left pr-2"></i>Previous Step</button>
                                            <button type="button" v-if="registerationStep  != 1 && registerationStep  != 5"  @click.prevent="registrationNextstep"  class="btn btn-secondary btnnextstp btndisabled btn-next-success">{{ button.nextStepButton }}<i class="fas fa-chevron-right pl-2"></i></button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                      </b-overlay>
                    </div>
                    <div class="footer-inner">
                        <div class="row footer-row">
                            <div class="col-md-7 pl-3">
                                <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">
                                    <div class="">
                                        <div class=" ">
                                            <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                                        </div>
                                    </div>
                                </div>
                              <ul class="footerulli">
                                <li><p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p></li>
                                <li><div v-html="this.getSiteProps('login.faq')"></div></li>
                              </ul>
                            </div>
                            <div class="col-md-5 pr-4">
                                <p class="text-right mr-2">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</template>

<style src="./style.css"></style>

<script>
const validateWaiverInitials = name => {
        if (!name) {
                return { valid: false, error: "Please enter your Initials to proceed" };
            } else {
                if(name.length != 2) {
                    return { valid: false, error: "You must enter two letters to initial" };
                }
        }
    return { valid: true, error: null };
};
const validateWaiverCheckbox = checkbox => {
    console.log("resss="+checkbox);
    if (!checkbox) {
        return { valid: false, error: "Please acknowledge by checking the box" };
    }
    return { valid: true, error: null };
};
const validateRadio = radio => {
    if (!radio) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSignedUserName = (name, fieldName = 'This field') => {
  if (name == undefined) {
    return { valid: false, error: fieldName + " is required" };
  } else if(name == "") {
    return { valid: false, error: fieldName + " is required" };
  }
  return { valid: true, error: null };
};
const validateSignedFirstName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateSignedUserLastName = (name, fieldName = 'This field') => {
  if (!name.length || name == "") {
    return { valid: false, error: fieldName + " is required" };
  }
  if(!name.match(/^[a-zA-Z-\s]*$/)){
    return { valid: false, error: fieldName +"must be letters and hyphens only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateTitle = title => {
    if (!title.length) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateCardName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "Card Name");
  if (!name.length || name == "") {
    return { valid: false, error: "This field is required" };
  }
  if(!name.match(/^[a-zA-Z\s]*$/)){
    return { valid: false, error: fieldText +" must be valid." };
  }
  return { valid: true, error: null };
};
const validateClubName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (name ==  undefined || !name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  return { valid: true, error: null };
};
const validateName = (name, fieldName = '',optional=false) => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!optional && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '',optional=false) => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!optional && !names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};
const validateParentName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }

  if (!name.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  contactText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateParentLastName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }

  if (!name.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  contactText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};
const validateStateName = name => {
    console.log('v state');
    console.log(name);
    if (name == null || name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validatePhone2 = (phone, validTelinput2) => {
  if(validTelinput2 == false && phone != '' && phone != null){
    return { valid: false, error: 'Enter the Exact phone number.(Example: +XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}
const validateCardNumber = (cardNumber, fieldTxt) => {
    if (!cardNumber.length || cardNumber == "") {
        return { valid: false, error: fieldTxt+" is required" };
    }
    if ((cardNumber.replace(/\s/g,'')).length < 14) {
        return { valid: false, error: "Card number must be greater than 13 digits" };
    }
    return { valid: true, error: null };
};
const validateCvv = (cvv, fieldTxt) => {
    if (!cvv.length || cvv == "") {
        return { valid: false, error: fieldTxt+ " is required" };
    }
    if (!cvv.match(/^[0-9]{3,4}$/)) {
        return { valid: false, error: "CVV must be 3 or 4 digits"};
    }
    return { valid: true, error: null };
};
const validateSelect = (select,fieldTxt = "This") => {
    console.log('m select');
    console.log(select);
    if (select == "" || select == null) {
        return { valid: false, error: fieldTxt +" is required" };
    }
    return { valid: true, error: null };
};
const validateEmail = email => {
    if (!email.length) {
        return {valid: false, error: "This field is required"};
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        return {valid: false, error: "Please enter a valid email."};
    }
    return { valid: true, error: null };
};
const validateDob = dob => {
    if (!dob.length) {
        return { valid: false, error: "This field is required" };
    }
    /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please, enter a valid data of birth." };
    }*/
    return { valid: true, error: null };
};
const validateGender = gender => {
    if (!gender) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateCheck = choices => {
    if(choices.length) return { valid: true, error: null };
    return { valid: false, error: "This field is required" };
}
const validatePhone = (phone, validTelinput, phoneField= '' ) => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (!phone.length) {
        return { valid: false, error: phoneText+' is required .' };
    }
    if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example: +XX XX XXXX XXXX).' };
    }
    return { valid: true, error: null };
}

const validateFedration = name => {
  if (!name) {
    return {valid: false, error: "This field is required"};
  } else if (isNaN(name.trim())) {
    return {valid: false, error: "This field must be a numeric value"};
  }
//    else if (name.length != 9) {
//     return {valid: false, error: "Foreign federation ID must be 9 characters"};
//   }
  return {valid: true, error: null};
};
const validateJerseyNumber = number => {
  if(!number){
    return {valid: false,error:"This field is required"};
  }
  if(!number.match(/^[0-9]+$/)){
    return { valid: false, error: "Please enter a valid Jersey Number." };
  }
  return {valid: true, error: null};
};
const validateGraduationYear = number => {
  if(!number){
    return {valid: false,error:"This field is required"};
  }

  return {valid: true, error: null};
};
const validateZip = (zipCode, country) => {
  if (zipCode == null || !zipCode.length)
  {
    return { valid: false, error: "Zip code is required" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
    return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
  }
  else if(zipCode.length  < 5){
         return { valid: false, error: "Zip code should be 5 character" };
    }
  return { valid: true, error: null };
};
const validateOptionalTextFields = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (name == null || !name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};

const validateExpireMonth = (month,year) =>{
  let date = new Date();
  let month_i = (date.getMonth() + 1).toString().padStart(2, "0");
  let year_i = date.getFullYear();
  console.log(month_i);
  console.log(month);
  if(year == year_i && month < month_i){
    return { valid: false, error: "Expiration Date is invalid" };
  }
  /*let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (names.length && !names.match(/^[a-zA-Z'-]+$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }*/
  return { valid: true, error: null };
};

const validatePlayerDob = (dob, fieldDob = '',isPlayer,age_divisionArr,age_division_id,age,membershipType) => {
  let fieldDobText = (fieldDob != '' ? fieldDob : "This field");
  if (!dob.length) {
    return { valid: false, error: fieldDobText+" is required" };
  }

  if(isPlayer && age_division_id !=0){
    var valObj = age_divisionArr.filter(function(elem){
      if(elem.id == age_division_id) return elem.value;
    });

    if(valObj.length > 0) {
      age = Math.floor(age);
      const division = valObj[0].validAge;
      if(age > division){
        return { valid: false, error: fieldDobText+" must be under the age of "+division };
      }
      if(!Object.is(Math.abs(age), +age)){
        return { valid: false, error: "Invalid Date" };
      }

    }
  }
  return { valid: true, error: null };
};

// import Navbar from "../PortalHead";
import banner from "./banner"
import membershiptype from "./register/membershiptype";
import generalinfo from "./register/generalinfo";
import personalinformation from "./register/personalinformation";
import demographicinformation from  "./register/demographicinformation";
import contactinfo from "./register/contactinfo";
import donation from "./register/donation";
import merchandise from "./register/merchandise";
import familymembers from "./register/familymembers";
import ClubInformation from "../clubregistration/ClubInformation";
import ClubOfficers from "../clubregistration/ClubOfficers";
import PrimaryContact from "../clubregistration/PrimaryContact";
// import SecondaryContact from "../clubregistration/SecondaryContact";
import InsurenceInformation from "../clubregistration/InsurenceInformation";
import juniordevelopmentinfo from "../clubregistration/JuniorDevelopmentInfo";
import SafesportCoordinator from "../clubregistration/SafesportCoordinator";
import skidirectorinfo from "../clubregistration/skishowdirector";
import SanctionInformation from "../clubregistration/SanctionInformation";
import TeamInformation from "./register/teaminfo";
import addons from "./register/addons";
import DatePick from 'vue-date-pick';
import {TheMask} from "vue-the-mask";
import moment from 'moment';
// import datetime from 'vuejs-datetimepicker';
import Vue from 'vue';

// import VueCardFormat from 'vue-credit-card-validation';
import axios from 'axios';
// Vue.use(VueCardFormat);
Vue.filter('formatDob', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

import { StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
    name: "Dashboard",
    //props: ['myName'],
    data()
    {
        return {
          regStatus: 1,
          regClosedMessage: '',
          resetData:'',
          validTelinput2: false,
          tempverifyData:{
            members: {},
            families: [],
            team_addons:[],
            form_type:''
          },
          college_status:2,
          priceFetching: true,
          duplicateErrorForPersonal:'',
            dupResult:true,
            checkingDuplicate: false,
            stripeToken: null,
            stripeError: "",
            stripeValidate: false,
            formDataIsValid: false,
            nextButtonDisabled: false,
            processingFeePercent: 0,
            processingFee: 0,
            insuranceFee: 0,
            baseShipping: 4.99,
            additionalShipping: 1.99,
            sanctionFee: 0,
            step : 1,
            totalSteps : 16,
            showModal: false,
            response : true,
            blockLoader: false,
            memberTypes:[],
            categories: [],
            disciplines:[],
            disciplines_list:[],
            groups:[],
            diversities:[],
            errors: [],
            waiverErrors: [{
                agree: [],
                initials: [],
                signed_member: []
            },{
                agree: [],
                initials: [],
                signed_member: []
            },{
                agree: [],
                initials: [],
                signed_member: []
            }],
            waiverClubErrors: {},
            couponErrors: {},
            currentWaiver: 0,
            registerationStep: 1,
            registerationStep1: true,
            registerationStep2: false,
            registerationStep3: false,
            registerationStep4: false,
            registerationStep5: false,
            registerTotalstep : 5,
            button: {
                nextButton: 'Next',
                nextStepButton: 'Next Step',
                coupon: 'Apply Coupon'
            },
            age: '',
            form: {
                "membership_id": '',
                "email": '',
                "retype_email": '',
                "address1": '',
                "address": '',
                "address2": '',
                "zip": '',
                "state_code": '',
                "state": '',
                "city": '',
                "country": '',
                "phone_1": '',
                "phone_2": '',
                "diversity_id": '',
                "members": [{
                    first_name: '',
                    last_name: '',
                    membership_id: '',
                    contact_type: ''
                },],
                'donation_amount': 0,
                'donation_id': '',
                'disability': '',
                'veteran_status': '',
                "team_addons":[],
                "first_name":'',
                "middle_name":'',
                "last_name":'',
                "age_division":0,
                "added_donation_amount":null,
                "invite_players":[],
                "form_type":'',
            },
            regId: '',
            team_addons_list:[],
            addedToCart: 0,
            verifyData: {
                members: {},
                families: [],
                team_addons:[],
                form_type:''
            },
            paymentData: '',
            registration_message_flag: false,
            registration_message_text: [],
            receipt_url: '#',
            races: [
                "American India or Alaska Native",
                "Asian",
                "Black/African American",
                "Hispanic or Latino",
                "Native Hawaiian or Pacific Islander",
                "White (Not of Hispanic Origin)",
                "Two or More Races"
            ],
            paymentForm: {
                "stripeToken": '',
                "cardNumber": '',
                "expiration_year": '',
                "expiration_month": '',
                "cvv": '',
                "amount": '',
                "owner": "",
                "name_on_card": "",
                "registration_id": '',
                "billing_firstname": "",
                "billing_lastname": "",
                "billing_company": "",
                "billing_addressline1": "",
                "billing_zip": "",
                "billing_state": "",
                "billing_state_code": "",
                "billing_city": "",
                "billing_country": "",
                "billing_phone1": "",
                auto_renew: 1,
                visible: true
            },
            merchandiseData: [],
            cartProductCount: 0,
            orderPlaceData: {
                registration_id: '',
            },
            placeOrderData: {
                registration_id: '',
            },
            clubInfoData:{
                membership_id: '',
                name: '',
                type: '',
                secondary_type: '',
                website: '',
                exclude: '',
                email: '',
                address: '',
                city: '',
                state: '',
                state_code: '',
                country: 'USA',
                zip: '',
                members: [],
                insured_members: [],
                team_sites: [],
                donation_amount: 0,
                donation_id: '',
                club_sanction: this.club_sanction,
                club_sanction_data: {
                  selected: false,
                  practise_with_guest: 0,
                  practise_without_guest: 0,
                  exhibition: 0,
                  practise_agree: 0,
                  exhibition_agree: 0,
                  practise_sign_name: '',
                  exhibition_sign_name: ''
                }
            },
            couponApplied: {
                status: 0,
                coupon_code: '',
                amount: 0.00,
                text: ''
            },
            totals: {
                merchandise_total: 0.00,
                shipping_total: 0.00,
                sub_total: 0.00,
                grand_total: 0.00,
            },
            membership: {
                id: '',
                type: '',
                fee: 0.00,
                age_criteria: "",
                member_type_id: '',
                totalMembershipFee: 0.00
            },
            donated: {
                type: '',
                amount: 0.00,

            },
            verifyClubInfoData: {
                members: [],
                primary_contact: {},
                insured_members: []
            },
            clubInsurances: [{
                name: '',
                fee: 0
            }],
            verifyPersonal: {},
            waiverData: [{
                agree:[],
                user_info: [{
                    start_data: '',
                    expires_at: ''
                }]
            }],
            waiverDataAgree: [{
              agree: []
            },{
              agree: []
            },{
              agree: []
            },{
              agree: []
            }],
            waiverType: true,
            waiverForm: {
                waivers_id: '',
                agree: '',
                initials: '',
                /*cardNumber: '',
                expiration_year: '',
                expiration_month: '',
                cvv: '',
                registration_id: '',
                billing_firstname: '',
                billing_addressline1: '',
                billing_zip: '',
                billing_state: '',
                billing_state_code: '',
                billing_city: '',
                billing_country: '',
                auto_renew: ''*/
            },
            currentDate: moment().format("MM/DD/YYYY H:mm"),
            valid: false,
            homeProps: {
              mode: "international",
              defaultCountry: "US",
              inputOptions:{
                autocomplete:"on",
                autofocus:false,
                id:"",
                maxlength:20,
                name:"telephone",
                placeholder:"Home Number",
                readonly:false,
                required:false,
                showDialCode:false,
                styleClasses:"",
                tabindex:0,
                type:"tel"
              }

            },
            mobileProps: {
              mode: "international",
              defaultCountry: "US",
              inputOptions:{
                autocomplete:"on",
                autofocus:false,
                id:"",
                maxlength:20,
                name:"telephone",
                placeholder:"Mobile Number",
                readonly:false,
                required:false,
                showDialCode:false,
                styleClasses:"",
                tabindex:0,
                type:"tel"
              }
            },
            signedWaiverData: {
                registration_id: '',
                waivers: []
            },
            step1: true,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            step6: false,
            step7: false,
            step8: false,
            step9: false,
            step10: false,
            step11: false,
            step12: false,
            step13: false,
            step14: false,
            step15: false,
            step16: false,
            merchandiseDataMen: [],
            merchandiseDataWomen: [],
            merchandiseDataAccessories: [],
            cartProductData: [],
            waiversList: [],
            clubSubmittedBy: {
                first_name: '',
                last_name: '',
                position: '',
                email: ''
            },
            loadWaiver: true,
          yearArr: [],
          GraduationyearArr: [],
          currentRegContacts:[],
          steps: [],
          currentStep: 0,
          prop_type: "",
          fields: {},
          prop_membertypes: [],
          prop_footer: {},
          prop_confirmation: {},
          verifyClubInfoEditData: {
              club_disciplines: [],
              club_type: [],
              primary_contact: {}
          },
          clubAdminSearch: {
            searched: false,
            memberIDSearched: ''
          },
          validTelinput: false,
          familyForm: {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            gender: '',
            dob: '',
            relation: '0',
            citizenship: '',
            clubandteam: '',
            discipline_1: '',
            discipline_2: '',
            index: 1,
            phone_1: '',
            phone_2: '',
            displines_names: [],
            secondChoiceNames: [],
            home_federation: 'USA',
            skier_license: '',
            diversity_id: '',
            diversity_txt: '',
            ethnicity: '',
            disability: '',
            veteran_status: '',
            reg_member_id: '',
            address1: '',
            address2: '',
            zip: '',
            state_code: '',
            state: '',
            city: '',
            country: 'USA',
          },
          spouseAdded: {},
          relations: {
            1: 'Spouse',
            2: 'Child'
          },
          duplicateError:{
            hasError:false,
            showLink:false,
            errorMessage:''
          },
          duplicateErrorMessage:{
            hasError:false,
            errorMessage:''
          },
          team_informations:false,
          player_informations:false,
          addons:false,
          fieldsAd:'',
          isTeamRegistration:false,
          isTeamOptions:false,
          CheckoutText:false,
          registration_bg_fee:0,
          individual_registration_bg_fee:0,
          agl_amt:0,
          listOfCountryWithLimit:false,
          usssa_amt:0,
          insurance_amt:0,
          player_registration_total_fee:0,
          isChild:false,
          donationInputSelected:null,
          isChildUsers:false,
          isHeadCoachContact:false,
          isAddonsValues:false,
          ageDivisionOPt: [
            {"id":"4","text":"12U"},
            {"id":"5","text":"10U"}
          ],
          teamList:[],
          teamOptions:[],
          teamRelations: {
            1: 'Player'
          },
          openCollapse:1,
          previouslySelected:null,
          old_member_type_id:null,
          old_membership_id:null,
          isValidateMemberType:false,
          invitedTeam:'',
          invitedEmail:'',
          updatedValues:{},
          addons_render:0,
          personal_render:0,
          contact_render:0,
          family_render:0,
          team_render:0,
          verifyErrors:'',
          verifyOldData:{},
          waiverContent: [{
            coach_acknowledgement: ""
          },{
            coach_acknowledgement: ""
          }],
          teamAddonsBtn:{
            enable:false,
            addons:[]
          },
          stripeIntent:{
            enable:false,            
            isportzFeePercent:0.00,
            isportzFeeCents:0.00,
            processingFeePercent:0.00,
            processingFeeCents:0.00,
            totalCost:0.00,
            viewprocessingFeePercent:0.00,
            viewisportzFeePercent:0.00,
            grandTotal:0.00,            
          },
          team_count:0,
          coach_count:0,
          parentCitizenship:0,
          athleteCitizenship:0,
          eligibleCitizenship:0,
          freeRegistration:false,
          divisions_lists:[],
          divisions_list:[],
          isAthlete:false,
          teamExist:false,
          teamsList:[],
          memberLookupContact:{},
          checkOutPersonalEdit:false,
        }


    },
    components: {
        // Navbar,
        banner,
        membershiptype,
        generalinfo,
        personalinformation,
        demographicinformation,
        contactinfo,
        donation,
        merchandise,
        ClubInformation,
        ClubOfficers,
        PrimaryContact,
        // SecondaryContact,
        familymembers,
        InsurenceInformation,
        juniordevelopmentinfo,
        SafesportCoordinator,
        skidirectorinfo,
        SanctionInformation,
        DatePick,
        // moment,
        // VueCardFormat,
        // datetime

        StripeElementCard,
        TeamInformation,
        addons
    },

    methods:{
         previousStep: function(){
           this.duplicateError = {
             hasError: false,
             showLink: false,
             errorMessage: ''
           };
            this.currentStep--;
            // if(this.currentStep == 1) this.currentStep = 0;
            this.step--;
             console.log('-------');
             console.log(this.step);
             if(this.step == 1) {
                 this.step2=false;
                 this.step1=true;
             } else if(this.step == 2) {
                 this.step3=false;
                 this.step2=true;
             } else if(this.step == 3) {
                 this.step4=false;
                 this.step3=true;
                 this.$refs.personalinformation.getDateValue();
             } else if(this.step == 4) {
                 this.step5=false;
                 this.step4=true;
             } else if(this.step == 5) {
                 if(this.membership.id == 5) {
                   this.step6 = false;
                   this.step5 = true;
                 }else if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) != -1) {
                     this.step6 = false;
                     this.step5 = true;
                 } else {
                   this.step6 = false;
                     this.step7 = false;
                     this.step5 = true;
                 }
             } else if(this.step == 6) {
                 if(this.membership.id == 5 && !this.isTeamRegistration) {
                     this.step7=false;
                     this.step6=true;
                 } else if(this.subdomain == "shooting"&&[11,12,13].includes(this.membership.id)){
                   this.step7=false;
                   this.step3=true;
                   this.step = 3;
                 } else if ((this.subdomain == "alliancefastpitch" && [1, 2].includes(this.membership.id) && !this.ncsaEnable)) {
                   this.step7=false;
                   this.step3=true;
                   this.step = 3;
                   this.currentStep--;
                 }
                 else if([5].indexOf(this.membership.id) != -1 && this.getSiteProps('memberRegistration.coach')!= undefined){
                     this.step7=false;
                     this.step3=true;
                     this.step = 3;
                     this.addons = this.getSiteProps('memberRegistration.team.fields.addons.isVisible');
                 }else if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                     this.step7=false;
                     if(this.subdomain != "shooting"){
                        this.step16=true;
                        this.step = 16;
                     } else {
                        this.step10=true;
                        this.step = 10;
                     }
                 }else if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) != -1) {
                   this.step7 = false;
                   this.step6 = true;
                   //this.step = 5;
                 }else if([1,2].indexOf(this.membership.id) != -1 && this.getSiteProps('memberRegistration.individual.fields.addons')!= undefined){
                   this.step7 = false;
                   this.step4 = true;
                   this.step = 4;
                 }else {
                     this.step7 = false;
                     this.step5 = true;
                     this.step = 5;
                 }
             } else if(this.step == 7) {
                 this.step8=false;
                 this.step7=true;
             } else if(this.step == 8) {
                 this.step9=false;
                 if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                    this.step = 1;
                    this.step1 = true;
                 } else {
                    //  if(this.getSiteProps('general.merchandise')){
                         this.step8=true;
                    //  } else {
                    //      this.step8=false;
                    //      this.step7=true;
                    //      this.step--;
                    //  }
                 }
             } else if(this.step == 9) {
                 this.step10=false;
                 this.step9=true;
             } else if(this.step == 10) {
                 this.step11=false;
                 this.step10=true;
             } else if(this.step == 11) {
                 this.step12=false;
                 this.step11=true;
             } else if(this.step == 12) {
                 this.step13=false;
                 this.step12=true;
             } else if(this.step == 13) {
                 this.step14=false;
                 this.step13=true;
             } else if(this.step == 14) {
                 this.step15=false;
                 this.step14=true;
             } else if(this.step == 15) {
                 this.step16=false;
                 this.step15=true;
             } else if(this.step == 16) {
               this.step7=false;
               this.step16=true;
             }
             console.log('r='+this.step);
             document.getElementById('app').scrollIntoView();
        },
        nextStep: function(){
             console.log('submit this step'+this.step);
           // console.log('dfdsfd'+this.step);
          if(this.step == 1)
           {
               if(this.membership.member_type_id == 4) {
                   if([3,4].indexOf(this.membership.id) != -1){
                     this.step1=false;
                     this.step2=true;
                   }else {
                     this.step = 9;
                     this.step1 = false;
                     this.step9 = true;
                     document.getElementById('app').scrollIntoView();
                     this.currentStep++;
                     return;
                   }
               } else {
                   this.step1=false;
                   this.step2=true;
               }
            } else if(this.step == 2) {
              if(this.isChild||(this.subdomain=='alliancefastpitch'&&this.form.membership_id==5)){
                this.checkDuplicate();
              }else if(this.isTeamRegistration){
                this.step2=false;
                this.step3=true;
                this.currentStep++;
                this.step++;
              }else{
                this.checkDuplicate();
              }
              return ;
            } else if(this.step == 3) {
              this.step3=false;
              if([5].indexOf(this.membership.id) != -1 && this.getSiteProps('memberRegistration.coach')!= undefined){
                this.step7=true;
                this.step = 6;
                this.addons = this.getSiteProps('memberRegistration.team.fields.addons.isVisible');
              } else if(this.subdomain == "shooting"&&[11,12,13].includes(this.membership.id)){
                this.step7=true;
                this.step = 6;
              } else if (this.subdomain == "alliancefastpitch" && [1, 2].includes(this.membership.id) && !this.ncsaEnable) {
                this.step7=true;
                this.step = 6;
                this.addons = this.getSiteProps('memberRegistration.team.fields.addons.isVisible');
                this.currentStep++;
              }
              else {
                this.step4=true;
              }
            } else if(this.step == 4) {
              this.step4=false;
              this.step5=true;
              if([3,4].indexOf(this.membership.id) != -1 && this.getSiteProps('memberRegistration.team.fields.club_info.isVisible')!= undefined){
                this.team_informations = this.getSiteProps('memberRegistration.team.fields.club_info.isVisible');
              }
              if([1,2].indexOf(this.membership.id) != -1 && this.getSiteProps('memberRegistration.individual.fields.addons')!= undefined){
                this.step4=false;
                this.step5=false;
                this.step7=true;
                this.step = 6;
                this.addons = this.getSiteProps('memberRegistration.team.fields.addons.isVisible');
              }
            } else if(this.step == 5) {
              if(this.membership.id == 5 || this.getSiteProps('memberRegistration.team.fields.player_information')!= undefined) {
                  this.player_informations = this.getSiteProps('memberRegistration.team.fields.player_information.isVisible');
                this.fieldsAd = (this.player_informations == undefined)?this.fields.family:this.fields.player_information;
                  if(this.isTeamRegistration && !this.isChild){
                    this.checkTeamExist();
                    return;
                  }
                  if(!this.isTeamRegistration){
                    this.step5=false;
                    this.step6=true;
                  }
              } else {
                  this.step5=false;
                  this.step7=true;
                  this.step = 7;
                  document.getElementById('app').scrollIntoView();
                  this.currentStep++;
                  return;
              }
            } else if(this.step == 6) {
              this.step6=false;
              this.step7=true;
            console.log("player",this.getSiteProps('memberRegistration.team.fields.addons'));
              if(this.getSiteProps('memberRegistration.team.fields.addons')!= undefined) {
                this.addons = this.getSiteProps('memberRegistration.team.fields.addons.isVisible');
              }
            }  else if(this.step == 7) {
               console.log('step 6 is working on');
               if(this.membership.member_type_id != 4) {
                   this.submitForm();
               } else {
                   this.clubInformationApi();
               }
                console.log('response--'+this.response);
                this.currentStep++;
                return;
            } else if(this.step == 8) {
                if(this.addedToCart == 1) {
                    console.log('submit this step' + this.step);
                    this.placeOrderApi();
                    this.registrationNextstep();
                    document.getElementById('app').scrollIntoView();
                    this.currentStep++;
                    return;
                } else {
                    this.calculateTotals();
                    this.registrationNextstep();
                }
                console.log('merchi this step' + this.step);
                console.log('registerationStep this step' + this.registerationStep);
                //console.log('response'+this.response);
                console.log('response--'+this.response);
                this.currentStep++;
                return;
            } else if(this.step == 9) {
                this.checkDuplicate();
                return ;
            } else if(this.step == 10) {
                if(this.subdomain != "shooting"){
                    this.step10=false;
                    this.step11=true;
                } else {
                    this.step10=false;
                    this.step7=true;
                    this.step = 7;
                    this.currentStep++;
                    return;
                }
            } else if(this.step == 11) {
              this.step11=false;
              this.step12=true;
            } else if(this.step == 12) {
              this.step12=false;
              this.step13=true;
            } else if(this.step == 13) {
              this.step13=false;
              this.step14=true;
            } else if(this.step == 14) {
              this.step14=false;
              this.step15=true;
            } else if(this.step == 15) {
              this.step15=false;
              this.step16=true;
            } else if(this.step == 16){
              console.log('this steps is equal to  : '+this.step);
              this.step16=false;
              this.step7=true;
              this.step = 7;
              this.currentStep++;
              return;
            }
            console.log('oouts stesi'+this.step);
             if(this.step < 16) {
            this.step++;

            }
            document.getElementById('app').scrollIntoView();
            this.currentStep++;
        },
        registrationPrevstep: function(){
            this.currentStep--;
            if(this.step == 8) this.currentStep = 6;

            if(this.getSiteProps('general.merchandise') == false) {
                if(this.membership.member_type_id == 3) this.currentStep = 6;
                else this.currentStep = 5;
            }
            //alert('prvious');
            console.log('f prev step'+this.registerationStep);
            console.log('f current waiver'+this.currentWaiver);
            if(this.registerationStep == 2) {
                this.registerationStep2 = false;
                this.registerationStep1 = true;
                if(this.step == 15) {
                    this.step = 7;
                }
            } else if(this.registerationStep == 3) {
                if(this.currentWaiver == 0) {
                    this.registerationStep3 = false;
                    this.registerationStep2 = true;
                } else {
                    console.log('1 f last current waiver'+this.currentWaiver);
                    console.log(this.waiversList);
                    this.waiversList[this.currentWaiver] = false;
                    this.waiversList[this.currentWaiver - 1] = true;
                    this.currentWaiver = this.currentWaiver - 1;
                  document.getElementById('app').scrollIntoView();
                    console.log('2 f last current waiver'+this.currentWaiver);
                    console.log(this.waiversList);
                    //return;
                    this.registerationStep++;
                }
            } else if(this.registerationStep == 4) {
                this.registerationStep4 = false;
                // if(this.membership.id == 4) {
                if(this.ignoreWaiverMembership.length && this.ignoreWaiverMembership.includes(this.membership.id)) {
                    this.registerationStep2 = true;
                    this.registerationStep--;
                } else {
                    console.log('pay previous');
                    this.currentWaiver = this.currentWaiver-1;
                    this.registerationStep3 = true;
                  if(this.waiverData[0].user_info[0].spouse_alert==1) {
                    this.$nextTick(() => {
                      this.$bvModal.show('SpouseWaiverInfoModal');
                    });
                  }
                }
                document.getElementById('app').scrollIntoView();
            }
            console.log('prev step'+this.registerationStep);
            this.registerationStep--;
            console.log('step'+this.registerationStep);
            console.log('step'+this.step);
            console.log('f last current waiver'+this.currentWaiver);
            console.log(this.waiversList);
            document.getElementById('app').scrollIntoView();

            if(this.registerationStep2){ //Fetch club list for verify screen edit
                this.getClubs();
            }

        },
        registrationNextstep: function(){
        //alert('prvious');
            if(this.registerationStep == 1) {
                this.registerationStep1 = false;
                this.registerationStep2 = true;
            } else if(this.registerationStep == 2) {
                // console.log('membership = '+this.membership.id);
                this.registerationStep2 = false;
                // if(this.membership.id == 4) {
                if(this.ignoreWaiverMembership.length && this.ignoreWaiverMembership.includes(this.membership.id)) {
                    this.registerationStep4 = true;
                    if(this.membership.type.includes("Lifetime") || (this.membership.member_type_id == 4 && this.getSiteProps('general.club_auto_renewal_option') == false)) document.querySelector(".auto_renew_text_sign").style.display= 'none';
                    else document.querySelector(".auto_renew_text_sign").style.display= 'inline-block';
                    this.registerationStep++;
                } else {
                    this.registerationStep3 = true;
                    console.log("waiver view data ",this.waiverData[0]);
                    if(this.waiverData[0].user_info[0].spouse_alert==1) {
                      this.$nextTick(() => {
                        this.$bvModal.show('SpouseWaiverInfoModal');
                      });
                    }
                }

              if (this.isTeamRegistration && this.membership.id != 5 && (this.verifyData.address1 == null || this.verifyData.city == null || this.verifyData.state_code == null || this.verifyData.country == null || this.verifyData.zip == null)) {
                this.registerationStep3 = false;
                this.registerationStep2 = true;
                this.registerationStep--;
                this.verifyErrors = "Need to update the Team contact details";
                Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);
              }else{
                if(this.isTeamRegistration) {
                  this.registerationStep2 = false;
                  this.verifyErrors = '';
                  this.registerationStep3 = true;
                }
              }
                document.getElementById('app').scrollIntoView();
            } else if(this.registerationStep == 3) {
                this.signedWaiverData.registration_id = this.regId
                if(this.validateWaiver()) {
                    console.log('waiver valid');
                    console.log('-----111-------');
                    console.log(this.waiverForm);
                    console.log('-----2222-------');
                    console.log(this.waiverData);
                    console.log(this.currentWaiver+' =waiver= '+this.waiverData.length);
                    if(this.currentWaiver == this.waiverData.length) {
                        console.log('------------');
                        this.addFilledWaiverData();
                        this.submitWaiverForm();
                        this.registerationStep3 = false;
                        this.registerationStep4 = true;
                        if(this.membership.type.includes("Lifetime") || (this.membership.member_type_id == 4 && this.getSiteProps('general.club_auto_renewal_option') == false)) document.querySelector(".auto_renew_text_sign").style.display= 'none';
                        else document.querySelector(".auto_renew_text_sign").style.display= 'inline-block';
                    } else {
                        console.log('-----333-------');
                        this.addFilledWaiverData();
                        this.waiverForm = {};
                        this.waiversList[this.currentWaiver-1] = false;
                        this.waiversList[this.currentWaiver] = true;
                        console.log("regstep="+this.registerationStep);
                        document.getElementById('app').scrollIntoView();
                        return;
                    }
                    console.log("regstep="+this.registerationStep);
                    console.log('-----444-------');
                    console.log(this.waiverForm);
                    console.log('-----555-------');
                    console.log(this.signedWaiverData);
                } else {
                    console.log("regstep="+this.registerationStep);
                    console.log('waiver un valid');
                    //document.getElementById('app').scrollIntoView();
                    return;
                }
            } else if(this.registerationStep == 4) {
                if(this.totals.grand_total == 0.0 && (this.couponApplied.status == 1 || this.freeRegistration)){
                    this.paymentApi();
                    return;
                } else {
                    if(this.validatePayment()) {
                        console.log("regstep="+this.registerationStep);
                        console.log('payment valid');

                        console.log("Stripe Form:", this.$refs.stripeForm);
                        if(this.$refs.stripeForm != undefined){
                            //Stripe Token generation
                            this.showLoader=true;
                            this.$refs.stripeForm.submit();
                        } else {
                            this.paymentApi();
                        }
                        console.log(this.registerationStep);

                        return;
                    } else {
                        console.log("regstep="+this.registerationStep);
                        console.log('payment un valid');
                        document.getElementById('app').scrollIntoView();
                        return;
                    }
                }
            }
            console.log("regstep="+this.registerationStep);
            this.registerationStep++;
            document.getElementById('app').scrollIntoView();
            console.log('this registrationNextstep end'+ this.registerationStep);

            if(this.registerationStep2){ //Fetch club list for verify screen edit
                this.getClubs();
            }

        },
        waiverFormSubmit() {
            if(this.validateWaiver()) {
                console.log('waiver valid');
                this.registerationStep++;
                document.getElementById('app').scrollIntoView();
                return;
            } else {
                console.log('waiver un valid');
            }
        },
        validatePayment() {
            this.errors = {}
            this.valid = false;

            const validPaymentFirstName = validateCardName(this.paymentForm.billing_firstname);
            this.errors.billing_firstname = validPaymentFirstName.error;

            let validCardNumber = { valid: true, error: null };
            let validExpirationYear = { valid: true, error: null };
            let validExpirationMonth = { valid: true, error: null };
            let validExpireMonth = { valid: true, error: null };
            let validCvv = { valid: true, error: null };
            if(this.getSiteProps('general.paymentGateway') == 'authorizenet' || this.getSiteProps('general.paymentGateway') == 'stripe_intent'){
                validCardNumber = validateCardNumber(this.paymentForm.cardNumber, "Card Number");
                this.errors.cardNumber = validCardNumber.error;

                validExpirationYear = validateSelect(this.paymentForm.expiration_year, "Year");
                this.errors.expiration_year = validExpirationYear.error;

                validExpirationMonth = validateSelect(this.paymentForm.expiration_month, "Month");
                this.errors.expiration_month = validExpirationMonth.error;

                validCvv = validateCvv(this.paymentForm.cvv, "CVV/CVC");
                this.errors.cvv = validCvv.error;

                validExpireMonth = validateExpireMonth(this.paymentForm.expiration_month,this.paymentForm.expiration_year);
                this.errors.expiration_month_valid = validExpireMonth.error;
            }

            // if(this.getSiteProps('general.paymentGateway') == 'stripe'){
            //     if(this.stripeValidate){
            //         this.stripeError = "";
            //     } else {
            //         this.stripeError = "Please enter the Card information";
            //     }
            // }

            const validAddress1 = validateAddressName(this.paymentForm.billing_addressline1);
            this.errors.billing_addressline1 = validAddress1.error;

            const validCountry = validateSelect(this.paymentForm.billing_country);
            this.errors.billing_country = validCountry.error;

            const validStateCodeSelect = validateSelect(this.paymentForm.billing_state_code,"State");
            const validStateCodeInput = validateStateName(this.paymentForm.billing_state);

            if(validCountry.valid && this.paymentForm.billing_country == 'USA') {
                this.errors.billing_state_code = validStateCodeSelect.error;
            } else {
                this.errors.billing_state = validStateCodeInput.error;
            }

            const validCity = validateCardName(this.paymentForm.billing_city, "City Name");
            this.errors.billing_city = validCity.error;

            const validZip = validateZip(this.paymentForm.billing_zip, this.paymentForm.billing_country);
            this.errors.billing_zip = validZip.error;

            const validPaymentSign = validateWaiverInitials(this.paymentForm.sign);
            this.errors.payment_sign = validPaymentSign.error;

            const validPaymentAgree = validateWaiverCheckbox(this.paymentForm.agree);
            this.errors.payment_agree = validPaymentAgree.error;

            if (validPaymentFirstName.valid && validCardNumber.valid
                && validExpirationYear.valid && validExpirationMonth.valid && validCvv.valid && validAddress1.valid && validCountry.valid
                && validCity.valid && validZip.valid && validPaymentAgree.valid && validPaymentSign.valid && this.stripeError == "" && validExpireMonth.valid) {
                this.valid = true;
                if(this.isTeamRegistration && (this.paymentForm.billing_country == 'USA' || this.paymentForm.billing_country == 'Canada')) {
                    this.paymentForm.billing_state = '';
                    if(!validStateCodeSelect.valid) {
                        this.valid = false;
                    }
                }else if(!this.isTeamRegistration && this.paymentForm.billing_country == 'USA') {
                    this.paymentForm.billing_state = '';
                    if(!validStateCodeSelect.valid) {
                        this.valid = false;
                    }
                }else if(!validStateCodeInput.valid) {
                    this.paymentForm.billing_state_code = '';
                    this.valid = false;
                }
            }

            return this.valid;
        },
        validateWaiver() {
            let ischild=false;
            if(this.membership.id==5)
            {
                this.form.members.forEach((value, index) => {
                    let fmemberdob = this.getAge(value.dob);
                    if(fmemberdob < 18)
                    {
                        ischild = true;
                    }
                });
            }

            if( ischild == false)
            {
                let minorindx = 2;
                this.waiverData.forEach((value, index) => {
                if (value.type == 'Minor' && value.user_info.length==0) {
                    minorindx = index;
                    this.waiverData.splice(minorindx,1);
                }
                });
            }

             console.log('this waiver'+this.currentWaiver);
             console.log(this.waiverDataAgree);
             console.log("this",this.waiverDataAgree[0].agree[0]);
             console.log(this.waiverData);
            this.errors = {}
            this.valid = false;
            let currentWaiverLength ='';
            if(!this.isTeamRegistration){
              currentWaiverLength = this.waiverData[this.currentWaiver].user_info.length;
            }else{
              currentWaiverLength = 2;
            }
            let validWaiverAgree = {};
            let validWaiverSign = {};
            let validWaiverSignedMember = {};
            console.log('-----------------------'+currentWaiverLength);
            if(!this.isTeamRegistration) {
              for (let i = 0; i < currentWaiverLength; i++) {
                console.log('-----------------------');
                console.log(this.waiverDataAgree[this.currentWaiver]);
                console.log(this.waiverData[this.currentWaiver]);
                validWaiverAgree = validateWaiverCheckbox(this.waiverDataAgree[this.currentWaiver].agree[i]);

                //console.log('this waiver validWaiverAgree' + this.waiverDataAgree[this.currentWaiver].agree[i]);
                //console.log('this waiver validWaiverSign' + this.waiverData[this.currentWaiver].user_info[i].member_initials);
                if (this.membership.member_type_id != 4) {
                  validWaiverSignedMember = validateSignedUserName(this.waiverData[this.currentWaiver].user_info[i].signed_member);
                  this.waiverErrors[this.currentWaiver].signed_member[i] = validWaiverSignedMember.error;
                  console.log("inside agreement");
                  validWaiverSign = validateWaiverInitials(this.waiverData[this.currentWaiver].user_info[i].member_initials);
                } else {
                  validWaiverSignedMember.valid = true;
                }
                this.waiverErrors[this.currentWaiver].agree[i] = validWaiverAgree.error;
                this.waiverErrors[this.currentWaiver].initials[i] = validWaiverSign.error;
              }
            }else{
              for (let i = 0; i < currentWaiverLength; i++) {
                console.log('-----------------------');
                console.log(this.waiverDataAgree[this.currentWaiver]);
                console.log(this.waiverData[this.currentWaiver]);
                this.waiverDataAgree[this.currentWaiver].agree[i] = (this.waiverDataAgree[this.currentWaiver].agree[i]==undefined)?false:this.waiverDataAgree[this.currentWaiver].agree[i];
                console.log("-------------------test",this.waiverDataAgree[this.currentWaiver].agree[i]);
                validWaiverAgree = validateWaiverCheckbox(this.waiverDataAgree[this.currentWaiver].agree[i]);

                //console.log('this waiver validWaiverAgree' + this.waiverDataAgree[this.currentWaiver].agree[i]);
                //console.log('this waiver validWaiverSign' + this.waiverData[this.currentWaiver].user_info[i].member_initials);
                if (this.membership.member_type_id != 4) {
                  validWaiverSignedMember = validateSignedUserName(this.waiverData[this.currentWaiver].user_info[0].signed_member);
                  this.waiverErrors[this.currentWaiver].signed_member[0] = validWaiverSignedMember.error;
                  console.log("inside agreement");
                  validWaiverSign = validateWaiverInitials(this.waiverData[this.currentWaiver].user_info[0].member_initials);
                } else {
                  validWaiverSignedMember.valid = true;
                }
                this.waiverErrors[this.currentWaiver].agree[i] = validWaiverAgree.error;
                this.waiverErrors[this.currentWaiver].initials[i] = validWaiverSign.error;
              }
            }

            if (!this.isTeamRegistration && validWaiverAgree.valid && ((this.membership.member_type_id != 4 && validWaiverSign.valid) || this.membership.member_type_id == 4) && validWaiverSignedMember.valid) {
                console.log('pass');
                console.log('pass',this.membership.member_type_id);
                this.valid = true;
                if(this.membership.member_type_id == 4)
                {
                    //const validWaiverSignedUser = validateSignedUserName(this.waiverData[this.currentWaiver].user_info.signed_user);
                    const validClubWaiverFirstName = validateSignedFirstName(this.clubSubmittedBy.first_name, 'First Name');
                    const validClubWaiverLastName = validateSignedUserLastName(this.clubSubmittedBy.last_name, 'Last Name');
                    const validClubWaiverPosition = validateSignedUserName(this.clubSubmittedBy.position, 'Club Position');
                    const validClubWaiverEmail = validateSignedUserName(this.clubSubmittedBy.email, 'E-Mail');
                    //this.waiverErrors.signed_user = validWaiverSignedUser.error;
                    this.waiverClubErrors.club_submitted_first_name = validClubWaiverFirstName.error;
                    this.waiverClubErrors.club_submitted_last_name = validClubWaiverLastName.error;
                    this.waiverClubErrors.club_submitted_position = validClubWaiverPosition.error;
                    this.waiverClubErrors.club_submitted_email = validClubWaiverEmail.error;
                    if(!validClubWaiverFirstName.valid || !validClubWaiverLastName.valid || !validClubWaiverPosition.valid || !validClubWaiverEmail.valid) {
                        this.valid = false;
                        console.log("Allince");
                        return this.valid;
                    }
                }
                console.log(this.currentWaiver+' === '+this.waiverData.length);
                if(this.currentWaiver < this.waiverData.length) {
                    console.log(this.currentWaiver+' < '+this.waiverData.length);
                    this.currentWaiver = this.currentWaiver + 1;
                    console.log(this.currentWaiver+' < '+this.waiverData.length);
                }
            }
          if (this.waiverDataAgree[this.currentWaiver].agree[0] && this.waiverDataAgree[this.currentWaiver].agree[1] && this.isTeamRegistration && validWaiverAgree.valid && ((this.membership.member_type_id != 4 && validWaiverSign.valid) || this.membership.member_type_id == 4) && validWaiverSignedMember.valid) {
            console.log('pass');
            console.log('pass',this.membership.member_type_id);
            this.valid = true;
            console.log(this.currentWaiver+' === '+this.waiverData.length);
            if(this.currentWaiver < this.waiverData.length) {
              console.log(this.currentWaiver+' < '+this.waiverData.length);
              this.currentWaiver = this.currentWaiver + 1;
              console.log(this.currentWaiver+' < '+this.waiverData.length);
            }
          }
            return this.valid;
        },
        submitForm() {
          this.showLoader=true;
            console.log("Form submitted!");
                this.button.nextButton = 'Registration is in progress...';
                var slug = "api/member_registration";
                if(this.regId == "") slug = 'api/member_registration';
                else slug = 'api/update/member_reg/'+this.regId;
                if(this.subdomain == 'shooting'&&[11,12,13].includes(this.membership.id))this.form.diversity_id = this.form.members[0].diversity_id
                console.log(this.form);
                axios.post(this.basePath+slug, this.form)
                      .then((res) => {
                          if(res.data.status == 'success') {
                              //Perform Success Action
                              //alert('success')
                              //console.log('success');
                              //console.log('success');
                              //this.step++;
                              this.button.nextButton = 'Next';
                              this.regId = res.data.data.id;
                              this.paymentForm.registration_id = this.regId;
                            //   alert(this.getSiteProps('general.merchandise'));
                              if(this.getSiteProps('general.merchandise')){
                                  this.step = 8;
                                  this.step8 = true;
                                  this.step7 = false;
                              } else {
                                    this.registrationNextstep();
                                    document.getElementById('app').scrollIntoView();
                                    // this.currentStep++;
                              }
                              this.verifyApiData();
                              //console.log(this.verifyData);
                              return res;
                          } else {
                              this.button.nextButton = 'Next';
                              return res;
                          }
                     })
                     .catch((error) => {
                         console.log('Error:'+ error);
                         //this.step--;
                         this.button.nextButton = 'Next';
                         return false;
                          // error.response.status Check status code
                      }).finally(() => {
                        console.log('Finally');
                     });
        },

        clubInformationApi()
        {
          this.showLoader=true;
          var data='';
          data = this.clubInfoData;
          if(this.subdomain == "alliancefastpitch"){
            data = this.form;
          }
            console.log(this.clubInfoData);
            this.button.nextButton = 'Registration is in progress...';
            var slug = "api/club_registration";
             if(this.regId == "") slug = 'api/club_registration';
             else slug = 'api/update/club_reg/'+this.regId;
                axios.post(this.basePath+slug, data)
                      .then((res) => {
                          if(res.data.status == 'success') {
                            if(this.subdomain == "alliancefastpitch"){
                              this.button.nextButton = 'Next';
                              this.regId = res.data.data.registration_id;
                              this.paymentForm.registration_id = this.regId;
                              this.calculateTotals();
                              this.registrationNextstep();
                              document.getElementById('app').scrollIntoView();

                              this.verifyApiData();
                              return res;
                            }else {
                              this.showLoader=false;
                              this.button.nextButton = 'Next';
                              this.verifyClubInfoData = res.data.data;
                              this.regId = res.data.data.registration_id;
                              this.setClubData(this.verifyClubInfoData);
                              this.calculateTotals();
                              this.registrationNextstep();
                              this.step = 15;
                              return res;
                            }
                          } else {
                              this.showLoader=false;
                              this.button.nextButton = 'Next';
                              this.errors.clubRegError = res.data.message;
                          }

                     })
                     .catch((error) => {
                         console.log(error);
                         this.button.nextButton = 'Next';
                         this.errors.clubRegError = error.errors;
                         return false;
                      }).finally(() => {
                        console.log('Club Finally');
                      if(this.subdomain != "alliancefastpitch") {
                        this.waiverApi();
                      }
                     });

        },
        clubVerifyUpdateApi()
        {
            console.log(this.clubInfoData);
            this.button.nextButton = 'Registration is in progress...';
            this.verifyClubInfoEditData.members = [];
            //this.verifyClubInfoData.name = this.verifyClubInfoData.club_name;
            //this.verifyClubInfoData.type = this.verifyClubInfoData.club_type;
            this.verifyClubInfoEditData.members[0] = this.verifyClubInfoEditData.primary_contact;
            axios.put(this.basePath+'api/club_registration/'+this.regId, this.verifyClubInfoEditData)
                .then((res) => {
                    if(res.data.status == 'success') {
                        console.log('success');
                        //this.step++;
                        this.button.nextButton = 'Next';
                        console.log('===' + this.registerationStep);
                        this.verifyClubInfoData = res.data.data;
                        this.setClubData(this.verifyClubInfoData);
                        //this.verifyClubApiData();
                        this.calculateTotals();
                        //this.regId = res.data.data.id;
                        //this.paymentForm.registration_id = this.regId;
                        //this.registerationStep++;
                        console.log('membership id=' + this.membership.id);
                        console.log('another===' + this.registerationStep);
                        return res;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
                console.log('Finally');
                //this.verifyApiData();
            });

        },
        clubSubmitUpdateApi()
        {
            console.log(this.clubInfoData);
            this.button.nextButton = 'Registration is in progress...';
            this.clubInfoData.id = this.verifyClubInfoData.id;
            //Update Insurance
            this.clubInfoData.insured_members.forEach((value, index) => {
                this.clubInfoData.insured_members[index].id = this.verifyClubInfoData.insured_members[index].id;
                this.clubInfoData.insured_members[index].team_id = this.clubInfoData.id;
            });
            //Update TeamSites
            this.clubInfoData.team_sites.forEach((value, index) => {
                this.clubInfoData.team_sites[index].id = this.verifyClubInfoData.team_sites[index].id;
                this.clubInfoData.team_sites[index].team_id = this.clubInfoData.id;
            });
            //Update Members
            this.clubInfoData.members.forEach((value, index) => {
                if(this.clubInfoData.members.contact_type == 0) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 1) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 2) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 3) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 4) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 5) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 6) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                } else if(this.clubInfoData.members.contact_type == 7 ) {
                    this.clubInfoData.members[index].id = this.verifyClubInfoData.primary_contact.id;
                }
                this.clubInfoData.members[index].team_id = this.clubInfoData.id;
            });
            axios.put(this.basePath+'api/club_registration/'+this.regId, this.clubInfoData)
                .then((res) => {
                    if(res.data.status == 'success') {
                        console.log('success');
                        //this.step++;
                        this.button.nextButton = 'Next';
                        console.log('===' + this.registerationStep);
                        this.verifyClubInfoData = res.data.data;
                        this.setClubData(this.verifyClubInfoData);

                        this.calculateTotals();
                        //this.paymentForm.registration_id = this.regId;
                        console.log('membership id=' + this.membership.id);
                        console.log('another===' + this.registerationStep);
                        this.registrationNextstep();
                        return res;
                    } else {
                        this.button.nextButton = 'Next';
                        this.errors.clubRegError = res.data.message;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    this.errors.clubRegError = error.errors;
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
                console.log('Finally');
                //this.verifyApiData();
            });

        },
        verifyClubApiData() {
            console.log("Verify!");
            axios.get(this.basePath+'api/club_registration/'+ this.regId)
                .then((res) => {
                    //Perform Success Action
                    //alert('success')
                    console.log('verify success');
                    this.verifyClubInfoData = res.data.data;
                    this.setClubData(this.verifyClubInfoData);
                    console.log('verify data');
                    console.log(this.verifyClubInfoData);
                    this.calculateTotals();
                    if(this.loadWaiver == true) {
                        this.waiverApi();
                    }

                    //this.merchandiseDataApi();
                    //console.log(this.verifyData);
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
            });
        },
        setClubData(verifyClubInfoData) {
            this.verifyClubInfoData.membership_id = verifyClubInfoData.membership_type_id;

            //Shooting Club info
            var props = Object.keys(verifyClubInfoData);
            if(props.length)
                props.map(prop => this.verifyClubInfoEditData[prop] = verifyClubInfoData[prop]);

            // this.verifyClubInfoEditData.name = this.verifyClubInfoData.name;
            this.verifyClubInfoEditData.website = verifyClubInfoData.web;

            if(Object.prototype.hasOwnProperty.call(verifyClubInfoData, 'club_type'))
                this.verifyClubInfoEditData.club_type = verifyClubInfoData.club_type.split(",");

            // if(Object.prototype.hasOwnProperty.call(verifyClubInfoData, 'club_disciplines') && verifyClubInfoData.club_disciplines.length)
            //     this.verifyClubInfoEditData.club_disciplines = verifyClubInfoData.club_disciplines.map(item => item.id);

            if(Object.prototype.hasOwnProperty.call(verifyClubInfoData, 'email'))
                this.verifyClubInfoEditData.retype_email = verifyClubInfoData.email;

            this.verifyClubInfoEditData.address = verifyClubInfoData.address1;
            this.verifyClubInfoEditData.phone_1 = verifyClubInfoData.primary_contact.phone_1;


            //primary contact
            this.verifyClubInfoData.primary_contact.address1 = verifyClubInfoData.address1;
            this.verifyClubInfoData.primary_contact.city = verifyClubInfoData.city;
            this.verifyClubInfoData.primary_contact.state = verifyClubInfoData.state;
            this.verifyClubInfoData.primary_contact.state_code = verifyClubInfoData.state_code;
            this.verifyClubInfoData.primary_contact.country = verifyClubInfoData.country;
            this.verifyClubInfoData.primary_contact.zip = verifyClubInfoData.zip;

            //Payment form
            this.paymentForm.registration_id = this.regId;
            this.paymentForm.billing_addressline1 = verifyClubInfoData.address1;
            this.paymentForm.billing_city = verifyClubInfoData.city;
            this.paymentForm.billing_state = verifyClubInfoData.state;
            this.paymentForm.billing_state_code = verifyClubInfoData.state_code;
            this.paymentForm.billing_country = verifyClubInfoData.country;
            this.paymentForm.billing_zip = verifyClubInfoData.zip;
            this.paymentForm.owner = verifyClubInfoData.name;
            this.donated.amount = verifyClubInfoData.donation_amount;
            this.donated.type = verifyClubInfoData.donation_id;
        },
        async verifyApiData() {
            console.log("Verify!");
            await axios.get(this.basePath+'api/verify/'+ this.regId)
                .then((res) => {
                    //Perform Success Action
                    //alert('success')
                    console.log('verify success');
                    this.verifyData = res.data.data;
                    this.tempverifyData.first_name =  this.verifyData.first_name;
                    this.tempverifyData.last_name =  this.verifyData.last_name;
                    this.tempverifyData.middle_name =  this.verifyData.middle_name;
                    this.tempverifyData.phone_1 =  this.verifyData.phone_1;
                    this.tempverifyData.phone_2 =  this.verifyData.phone_2;
                    this.tempverifyData.email =  this.verifyData.email;
                    this.tempverifyData.retype_email =  this.verifyData.retype_email;
                    this.tempverifyData.address_type =  this.verifyData.address_type;
                    this.tempverifyData.address1 =  this.verifyData.address1;
                    this.tempverifyData.address2 =  this.verifyData.address2;
                    this.tempverifyData.city =  this.verifyData.city;
                    this.tempverifyData.state =  this.verifyData.state;
                    this.tempverifyData.state_code =  this.verifyData.state_code;
                    this.tempverifyData.zip =  this.verifyData.zip;
                    this.tempverifyData.country =  this.verifyData.country;
                    this.tempverifyData.citizenship =  this.verifyData.citizenship;
                    this.tempverifyData.parent_citizenship =  this.verifyData.parent_citizenship;
                    this.tempverifyData.athlete_citizenship =  this.verifyData.athlete_citizenship;
                    this.tempverifyData.eligible_citizenship =  this.verifyData.eligible_citizenship;
                    this.tempverifyData.company_name =  this.verifyData.company_name;
                    this.tempverifyData.member_league_name =  this.verifyData.member_league_name;
                    this.tempverifyData.age_division_name =  this.verifyData.age_division_name;
                    this.tempverifyData.name =  this.verifyData.name;
                    this.tempverifyData.agl_team_id =  this.verifyData.agl_team_id;
                    this.tempverifyData.members.first_name =  this.verifyData.members.first_name;
                    this.tempverifyData.members.last_name =  this.verifyData.members.last_name;
                    this.tempverifyData.members.middle_name =  this.verifyData.members.middle_name;
                    this.tempverifyData.members.suffix =  this.verifyData.members.suffix;
                    this.tempverifyData.members.dob =  this.verifyData.members.dob;
                    this.tempverifyData.members.gender =  this.verifyData.members.gender;
                    this.tempverifyData.members.home_federation =  this.verifyData.members.home_federation;
                    this.tempverifyData.members.skier_license =  this.verifyData.members.skier_license;
                    this.tempverifyData.members.veteran_status  =  this.verifyData.members.veteran_status ;
                    this.tempverifyData.members.affiliate  =  this.verifyData.members.affiliate ;
                    this.tempverifyData.members.first_choice_txt  =  this.verifyData.members.first_choice_txt ;
                    this.tempverifyData.members.second_choice_txt  =  this.verifyData.members.second_choice_txt ;
                  this.tempverifyData.members.first_choice  =  this.verifyData.members.first_choice ;
                  this.tempverifyData.members.second_choice  =  this.verifyData.members.second_choice;
                  this.tempverifyData.members.college_committed = this.verifyData.members.college_committed;
                  this.tempverifyData.members.college_status = this.college_status;
                  this.tempverifyData.club_name = this.verifyData.club;
                  this.tempverifyData.members.graduation_year = this.verifyData.members.graduation_year;
                  this.tempverifyData.members.jersey_number = this.verifyData.members.jersey_number;
                  this.tempverifyData.members.primary_position = this.verifyData.members.primary_position;
                    this.verifyData.membership_id = this.verifyData.membership_type_id;
                    this.verifyData.retype_email = this.verifyData.email;
                    //this.verifyData.diversity_id = 1;
                    this.verifyData.members.relation = 0;
                    this.verifyData.members.reg_member_id = this.verifyData.members.id;
                    this.verifyData.diversity_id = this.verifyData.members.diversity_id;
                    this.verifyData.ethnicity = this.verifyData.members.ethnicity;
                    this.verifyData.disability = this.verifyData.members.disability;
                    this.verifyData.veteran_status = this.verifyData.members.veteran_status;

                    // validation for the alliance team contact details
                    if(this.isTeamRegistration && this.verifyData.address1 != null && this.verifyData.city != null
                        && this.verifyData.state_code != null && this.verifyData.country != null && this.verifyData.zip != null){
                      console.log("contact validation true",this.registerationStep);
                      this.verifyErrors = '';
                      this.registerationStep3 = false;
                    }
                    //Payment form
                    this.paymentForm.billing_addressline1 = this.verifyData.address1;
                    this.paymentForm.billing_city = this.verifyData.city;
                    this.paymentForm.billing_state = this.verifyData.state;
                    this.paymentForm.billing_state_code = this.verifyData.state_code;
                    this.paymentForm.billing_country = this.verifyData.country;
                    this.paymentForm.billing_zip = this.verifyData.zip;
                    this.paymentForm.owner = this.verifyData.members.first_name+' '+this.verifyData.members.last_name;
                    //uncheck auto renewal for lifetime
                    this.paymentForm.auto_renew = this.getSiteProps('general.auto_renewal') && this.verifyData.membership_period != 999 && (this.verifyData.member_type_id != 4 || this.getSiteProps('general.club_auto_renewal_option')) ? 1 : 0;
                    this.donated.amount = this.verifyData.donation_amount;
                    this.donated.type = this.verifyData.donation_id;
                    this.verifyData.form_type = ''
                    console.log('verify data');
                    console.log(this.verifyData);
                    console.log('payment data');
                    console.log(this.paymentForm);

                    if(this.loadWaiver == true) {
                      this.waiverApi();
                    }
                    if(this.isChild && this.isTeamRegistration){
                      this.age =  this.getAge(this.verifyData.members.dob);
                      if(this.age < 18 ){
                        this.isChildUsers=true;
                      }else{
                        this.isChildUsers=false;
                      }
                    }

                    if(this.isTeamRegistration && this.form.added_donation_amount != null){
                      this.verifyData.added_donation_amount = this.verifyData.donation_amount;
                      this.donationInputSelected = 'null';
                      this.verifyData.donation_amount = 'null'
                    }

                    if(!this.isChild && this.isTeamRegistration && this.membership.id !=5) {
                        this.verifyData.team_addons = [];
                        if(this.verifyData.addons) {
                          this.verifyData.addons.forEach((value, index) => {
                            if (value.amount == 0) {
                              if (value.addon_id == 1) this.verifyData.addons.agl_amt = 0;
                              if (value.addon_id == 2) this.verifyData.addons.usssa_amt = 0;
                              if (value.addon_id == 3) this.verifyData.addons.insurance_amt = 0;
                            } else {
                              if (value.addon_id == 1) this.verifyData.addons.agl_amt = value.amount;
                              if (value.addon_id == 2) this.verifyData.addons.usssa_amt = value.amount;
                              if (value.addon_id == 3) this.verifyData.addons.insurance_amt = value.amount;
                            }
                          });
                          this.verifyData.addons.donation = this.donated.amount;
                          this.agl_amt =  this.isCheckAddonsExist(1,true,true);
                          this.usssa_amt = this.isCheckAddonsExist(2,true,true);
                          this.insurance_amt = this.isCheckAddonsExist(3,true,true);

                        }
                        if(this.verifyData.families.length > 1){
                          this.freeRegistration = (this.form.age_division_name.slice(0, -1) <= 10)?true:false;
                        }
                      this.updatedValues.families = this.verifyData.families;
                    }
                    if(this.isChild && this.isTeamRegistration){
                      this.stateCode();
                      if(this.isTeamRegistration && this.membership.id != 4){
                        this.ageDivisionOPt = [];
                        this.ageDivisionOPt = this.divisions_list;
                      }
                      this.getDateValue();
                      this.freeRegistration = (this.age <= 10 && this.verifyData.ageDivision.slice(0, -1) <= 10)?true:false;
                    }
                    if(this.verifyData.head_contact != undefined){
                      //Payment form alliancefastpitch
                      if(this.verifyData.address1 != null && this.verifyData.city != null && this.verifyData.state_code !=null && this.verifyData.country != null && this.verifyData.zip != null) {
                        this.verifyOldData.address1 = this.verifyData.address1;
                        this.verifyOldData.address2 = this.verifyData.address2;
                        this.verifyOldData.zip = this.verifyData.zip;
                        this.verifyOldData.state_code = this.verifyData.state_code;
                        this.verifyOldData.city = this.verifyData.city;
                        this.verifyOldData.country = this.verifyData.country;
                        this.stateCode();
                      }else{
                         this.getTeamDetails(this.verifyData.id);
                      }
                      if(this.isTeamRegistration){
                        this.ageDivisionOPt = [];
                        this.ageDivisionOPt = this.divisions_list;
                      }
                      this.getDateValue();
                      this.verifyData.team_name = this.verifyData.name;
                      /*if(this.form.id != '' && this.form.id != null){
                        this.verifyData.name = '';
                      }*/
                      //this.verifyData.member_league_name = this.form.member_league_name;
                      this.verifyData.head_contact.retype_email = this.verifyData.head_contact.email;
                      this.paymentForm.billing_addressline1 = this.verifyData.head_contact.address1;
                      this.paymentForm.billing_city = this.verifyData.head_contact.city;
                      this.paymentForm.billing_state = this.verifyData.head_contact.state;
                      this.paymentForm.billing_state_code = this.verifyData.head_contact.state_code;
                      this.paymentForm.billing_country = this.verifyData.head_contact.country;
                      this.paymentForm.billing_zip = this.verifyData.head_contact.zip;
                    }

                    if(this.verifyData.families.length > 2){
                      this.openCollapse = null;
                    }

                    if(this.verifyData.membership_type_id == 5 && (this.verifyData.club ==null || this.verifyData.club =='FA')){
                      this.verifyData.club_name = 'Free Agent';
                      this.verifyData.club = 'FA';
                    }
                    this.calculateTotals();
                    //this.merchandiseDataApi();
                    //console.log(this.verifyData);
                    this.showLoader=false;
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
            });
        },
        async verifyUpdateApi() {
            console.log("Verify update!");
          if(this.isTeamRegistration && this.verifyData.added_donation_amount != null && this.verifyData.added_donation_amount != ''){
            this.verifyData.donation_amount = this.verifyData.added_donation_amount;
          }
          if(this.isTeamRegistration && this.verifyData.added_donation_amount == null || this.verifyData.added_donation_amount == ''){
            this.form.added_donation_amount = null;
          }
          if(this.isTeamRegistration && this.isChild){
            if(this.parentCitizenship==2)
              this.verifyData.parent_citizenship ='';

            if(this.athleteCitizenship==2)
              this.verifyData.athlete_citizenship ='';

            if(this.eligibleCitizenship==2)
              this.verifyData.eligible_citizenship ='';
          }
          if(this.verifyData.head_contact != undefined){
            this.verifyData.email = this.verifyData.head_contact.email;
            this.verifyData.retype_email = this.verifyData.head_contact.retype_email;
          }
          console.log("data families",this.verifyData);
            await axios.put(this.basePath+'api/verify/'+ this.regId, this.verifyData)
                .then((res) => {
                    if(res.data.status == 'success') {
                        //Perform Success Action
                        //alert('success')
                        console.log('verify success');
                        this.verifyData = res.data.data;
                        this.verifyData.membership_id = this.verifyData.membership_type_id;
                        this.verifyData.retype_email = this.verifyData.email;
                        if(this.step == 7) {
                            this.button.nextButton = 'Next';
                            this.regId = res.data.data.id;
                            if(!this.isTeamRegistration){
                              this.step = 8;
                              this.step7 = false;
                              this.step8 = true;
                            }else if(this.isTeamRegistration && !this.isChild && this.membership.id !=5){
                              this.regId = res.data.data.registration_id;
                            }
                            this.paymentForm.registration_id = this.regId;

                            this.verifyApiData();
                        }
                        //this.merchandiseDataApi();
                        //console.log(this.verifyData);
                        this.showLoader=false;
                        return res;
                    } else {
                        this.showLoader=false;
                        return res;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
                    this.verifyApiData();
            });
        },
        verifyUpdateFormApi() {
            console.log("Verify form update!");
            if(this.membership.member_type_id != 4) {
                this.form.members[0].reg_member_id = this.verifyData.members.reg_member_id;
            }
            axios.put(this.basePath+'api/verify/'+ this.regId, this.form)
                .then((res) => {
                    if(res.data.status == 'success') {
                        //Perform Success Action
                        //alert('success')
                        console.log('verify success');
                        this.verifyData = res.data.data;
                        this.verifyData.membership_id = this.verifyData.membership_type_id;
                        this.verifyData.retype_email = this.verifyData.email;
                        if(this.step == 7) {
                            this.button.nextButton = 'Next';
                            this.regId = res.data.data.id;
                            this.paymentForm.registration_id = this.regId;
                            this.step = 8;
                            this.step7 = false;
                            this.step8 = true;
                            this.verifyApiData();
                        }
                        //this.merchandiseDataApi();
                        //console.log(this.verifyData);
                        return res;
                    } else {
                        return res;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
                this.verifyApiData();
            });
        },
        submitWaiverForm() {
            console.log("Waiver Form submitted!");
            console.log("Waiver Form submitted!",this.signedWaiverData);
            //this.button.nextButton = 'Registration is in progress...';
            axios.post(this.basePath+'api/waivers/'+ this.regId, this.signedWaiverData)
                .then((res) => {
                    if(res.data.status == 'success') {
                        //Perform Success Action
                        //alert('success')
                        //console.log('success');
                        //console.log('success');
                        //this.step++;
                        //this.button.nextButton = 'Next';
                        //this.regId = res.data.data.id;
                        //this.paymentForm.registration_id = this.regId;
                        //console.log(this.verifyData);
                    }
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
                console.log('Finally');
                //this.verifyApiData();

            });
        },
        paymentApi() {
            this.showLoader=true;
            console.log("Payment submitted!");
            this.button.nextStepButton = 'Payment is in progress...';
            this.paymentForm.cardNumber = this.paymentForm.cardNumber.replace(/\s/g,'');
            this.paymentForm.name_on_card = this.paymentForm.billing_firstname;
            axios.post(this.basePath+'api/paynow/'+ this.regId, this.paymentForm)
                .then((res) => {
                    if(res.data.status == 'success') {
                        //Perform Success Action
                        //alert('success')
                        this.showLoader=false;
                        console.log('payment success');
                        this.button.nextStepButton = 'Next Step';
                        console.log(res.data.message);
                        this.paymentData = res.data.message;
                        this.registration_message_flag = res.data.data.registration_message_flag;
                        this.registration_message_text = res.data.data.registration_message_text;
                        //console.log(this.verifyData);
                        console.log('Finally');
                        console.log("f="+this.registerationStep);
                        this.registerationStep++;
                        console.log("s="+this.registerationStep);
                        this.registerationStep4 = false;
                        this.registerationStep5 = true;
                        this.verifyApiData();
                        document.getElementById('app').scrollIntoView();
                        return res;
                    } else {
                        this.showLoader=false;
                        this.errors.payment_agree = res.data.errors;
                        this.button.nextStepButton = 'Next Step';
                    }
                })
                .catch((error) => {
                    this.showLoader=false;
                    console.log('Error:'+ error);
                    this.errors.payment_agree = error.errors;
                    //this.step--;
                    this.button.nextStepButton = 'Next Step';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {

            });
        },
        merchandiseDataMenApi() {
            console.log("Merchandise!");
            axios.get(this.basePath+'api/merchandise_product_list?category_id='+1)
                .then((res) => {
                    //Perform Success Action
                    //alert('success')
                    console.log('merchandise success');
                    this.merchandiseDataMen = res.data.data;
                    console.log(this.merchandiseDataMen);
                    console.log('=======1====');
                    console.log(this.merchandiseDataMen[0].images[0].url);
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {


            });
        },
        merchandiseDataWomenApi() {
            console.log("Merchandise!");
            axios.get(this.basePath+'api/merchandise_product_list?category_id='+2)
                .then((res) => {
                    //Perform Success Action
                    //alert('success')
                    console.log('merchandise success');
                    this.merchandiseDataWomen = res.data.data;
                    //console.log(this.merchandiseData[0]);
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {


            });
        },
        merchandiseDataAccessoriesApi() {
            console.log("Merchandise!");
            axios.get(this.basePath+'api/merchandise_product_list?category_id='+3)
                .then((res) => {
                    //Perform Success Action
                    //alert('success')
                    console.log('merchandise success');
                    this.merchandiseDataAccessories = res.data.data;
                    //console.log(this.merchandiseData[0]);
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {


            });
        },
        placeOrderApi() {
            console.log("Place order submitted!");
            console.log('----------------------');
            this.button.nextButton = 'Place order is in progress...';
            this.orderPlaceData.registration_id = this.regId;
            axios.post(this.basePath+'api/merchandise_place_order', this.orderPlaceData)
                .then((res) => {
                    console.log('----------'+res.data.status);
                    if(res.data.status == 'success') {
                        //Perform Success Action
                        //Perform Success Action
                        //alert('success')
                        console.log('placeOrder success');
                        //this.step++;
                        this.button.nextButton = 'Next';
                        this.placeOrderData = res.data.data;
                        this.cartProductCount = this.placeOrderData.cart_product_count;
                        this.totals.merchandise_total = this.placeOrderData.sub_total;
                        this.totals.shipping_total = this.placeOrderData.shipping_cost;
                        console.log('===========');
                        console.log(this.totals);
                        this.calculateTotals();
                        console.log('myloog');
                        console.log(this.placeOrderData.registration_id);
                        //console.log(this.verifyData);
                        return res;
                    } else if(res.data.status == 'error') {
                        this.button.nextButton = 'Next';
                        this.placeOrderData = [];
                        this.cartProductCount = 0;
                        this.totals.merchandise_total = 0.00;
                        this.totals.shipping_total = 0.00;
                        this.calculateTotals();
                        return res;
                    } else {
                        this.button.nextButton = 'Next';
                        this.placeOrderData = [];
                        this.cartProductCount = 0;
                        this.totals.merchandise_total = 0.00;
                        this.totals.shipping_total = 0.00;
                        this.calculateTotals();
                        console.log('placeOrder success'+res.data.status);
                        return res;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
                console.log('Finally');
            });
        },
        waiverApi() {
            console.log("Waiver!");
            axios.get(this.basePath + 'api/waivers/' + this.regId)
                .then((res) => {
                    //Perform Success Action
                    //alert('success')
                    console.log('waiver success');
                    //this.waiverData = res.data.data;
                    console.log('waiver data');
                    console.log(this.waiverData);
                    //this.merchandiseDataApi();
                    //console.log(this.verifyData);
                  if(!this.checkOutPersonalEdit) {
                    this.waiversList[0] = true;
                  }
                    if(this.membership.id == 2) {
                        this.waiverData = [];
                        res.data.data.forEach((value, index) => {
                            console.log('waiver type=' + value.type);
                            console.log(value);
                            let tempWaiver = value;
                            if (value.type == 'Minor') {
                                tempWaiver.user_info[0].member_initials = '';
                                tempWaiver.user_info[0].signed_member = '';
                                console.log(tempWaiver.user_info[0]);
                                console.log('mn1e=' + tempWaiver.user_info[0].signed_member);
                            } else if (index != 0 && value.type == 'SafeSport' && res.data.data[0].type == 'Minor') {
                                tempWaiver.user_info[0].member_initials = '';
                                tempWaiver.user_info[0].signed_member = '';
                                console.log(tempWaiver.user_info[0]);
                                console.log('mn2e=' + tempWaiver.user_info[0].signed_member);
                            }
                            console.log('--------------');
                            console.log(tempWaiver);
                            this.waiverData.push(tempWaiver);
                        });
                    } else {
                        this.waiverData = res.data.data;
                    }
                    console.log('------------');
                    console.log(this.waiverData);
                    console.log('------------');
                    console.log(this.waiversList);
                    if(this.isTeamRegistration){
                      //Alliance Fast Pitch to be add the multiple signInitial for the waiver sing
                      this.waiverData[0].user_info.push(this.waiverData[0].user_info[0]);
                      console.log('------------');
                      console.log(this.waiverData);
                    }
                    if(this.checkOutPersonalEdit){
                      let waiverNumber = 0;
                      let currentWaiverLength = this.waiverData.length;
                      console.log("data waivers",currentWaiverLength);
                      this.signedWaiverData.waivers =[];
                      let waiverCount = this.waiverData.length;
                      for(let i = 0; i < currentWaiverLength; i++) {
                        this.addFilledWaiverUpdateData(waiverNumber);
                        waiverNumber ++;
                      }
                      console.log("data waivers count value",waiverNumber == waiverCount);
                      if(waiverNumber == waiverCount){
                        this.submitWaiverForm();
                      }
                    }
                    return res;
                })
                .catch((error) => {
                    console.log('Error:' + error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
            });
        },
        removeFromCart(cart_id) {
            let cartData = {};
            cartData.cart_id = cart_id;
            cartData.registration_id = this.regId;
            console.log('---------------');
            console.log(cartData);
            axios.post(this.basePath+'api/merchandise_remove_from_cart', cartData)
                .then((res) => {
                   if(res.data.status == 'success') {
                       this.cartProductData = res.data.data;
                       console.log('cart--length--' + this.cartProductData.length);
                       console.log('addedToCart----' + this.addedToCart);
                       this.placeOrderApi();
                   }
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
            });
        },
        removeDonation() {
            let regData = {};
            regData.registration_id = this.regId;
            console.log('---------------');
            console.log(regData);
            axios.post(this.basePath+'api/remove_donation', regData)
                .then((res) => {
                    if(res.data.status == 'success') {
                        this.loadWaiver = false;
                        if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                            this.verifyClubApiData();
                        } else {
                            this.verifyApiData();
                        }
                    }
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
            });
        },
        removeDonationBasic() {
        let regData = {};
        regData.registration_id = this.regId;
        console.log('---------------');
        console.log(regData);
        axios.post(this.basePath+'api/remove_donation', regData)
            .then((res) => {
              if(res.data.status == 'success') {
                this.loadWaiver = false;
                if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                  this.verifyClubApiData();
                } else {
                  this.verifyApiData();
                }
              }
              return res;
            })
            .catch((error) => {
              console.log('Error:'+ error);
              //this.step--;
              //this.button.nextButton = 'Next';
              return false;
              // error.response.status Check status code
            }).finally(() => {
        });
      },
        removeAddons(id) {
        let regData = {};
        regData.addon_id =id;
        console.log('---------------');
        console.log(regData);
        axios.post(this.basePath+'api/remove_addon', regData)
            .then((res) => {
              if(res.data.status == 'success') {
                this.loadWaiver = false;
                if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                  this.verifyClubApiData();
                } else {
                  this.verifyApiData();
                }
              }
              return res;
            })
            .catch((error) => {
              console.log('Error:'+ error);
              //this.step--;
              //this.button.nextButton = 'Next';
              return false;
              // error.response.status Check status code
            }).finally(() => {
        });
      },
        applyCoupon() {
            let regData = {};
            regData.registration_id = this.regId;
            regData.coupon_code = this.couponApplied.coupon_code;
            regData.coupon_type_id = 1;
            this.button.coupon = 'Applying Coupon...';
            this.couponErrors = {};
            console.log('---------------');
            console.log(regData);
            axios.post(this.basePath+'api/apply_coupon', regData)
                .then((res) => {
                    if(res.data.status == 'success') {
                      if(res.data.data.couponType==999)
                      {
                        this.couponApplied.status = 1;
                        //this.couponApplied.amount = res.data.data.value;
                        let membershipFee = this.membership.fee;                         
                        let playerFee = !this.freeRegistration ? this.membership.player_fee : process.env.VUE_APP_AFP_10U_FEE;
                        let coachFee = this.membership.coach_fee;
                        let playersFee = 0;
                        let playerCount =0;
                        membershipFee = this.team_count > 0?0:membershipFee;

                        if(this.isTeamRegistration && this.verifyData.families.length > 0){
                          let is_coach = this.coach_count>0?0:1;
                          playersFee = playerFee * (this.verifyData.families.length - is_coach);
                          playerCount = (this.verifyData.families.length - is_coach);
                        }else{
                          playersFee = playerFee * this.verifyData.families.length;
                          playerCount =this.verifyData.families.length;
                        }
                        let coachesFee = this.coach_count > 0?0:parseFloat(this.removeCommaFromNumber(coachFee)).toFixed(2);
                        let totalMembershipFee = parseFloat(this.removeCommaFromNumber(membershipFee) + this.removeCommaFromNumber(playersFee) + this.removeCommaFromNumber(coachesFee)).toFixed(2);

                        let couponValue=0;
                        let membershipDiscount=0;
                        let playerDiscount=0;
                        let coachDiscount=0;
                        //let couponValue = res.data.data.discountType == 1?(parseFloat(res.data.data.discountValue).toFixed(2)):(parseFloat(totalMembershipFee/100 * res.data.data.discountValue).toFixed(2));
                        if( res.data.data.discountType == 1)
                        {
                          let discountValue = parseFloat(res.data.data.discountValue).toFixed(2);
                          if (parseFloat(discountValue) >= parseFloat(membershipFee)) {
                            membershipDiscount = membershipFee;
                          } else {
                            membershipDiscount = discountValue;
                          }
                          if(this.removeCommaFromNumber(playersFee) > 0)
                          {
                            if (parseFloat(discountValue) >= parseFloat(playersFee)) {
                              playerDiscount = playersFee;
                            } else if (this.removeCommaFromNumber(playerCount) > 0) {
                              playerDiscount = playerCount * parseFloat(discountValue);
                            } else {
                              playerDiscount = discountValue;
                            }

                          }
                          if(this.removeCommaFromNumber(coachesFee) > 0)
                          {
                            if (parseFloat(discountValue) >= parseFloat(coachesFee)) {
                              coachDiscount = coachesFee;
                            } else {
                              coachDiscount = discountValue;
                            }
                          }
                        }else
                        {
                          membershipDiscount = parseFloat(totalMembershipFee/100 * res.data.data.discountValue).toFixed(2);
                        }
                        let totalDiscount = parseFloat(membershipDiscount) + parseFloat(playerDiscount) + parseFloat(coachDiscount);
                        this.couponApplied.amount = parseFloat(totalDiscount).toFixed(2);

                        this.membership.totalMembershipFee = totalMembershipFee;
                        this.calculateTotals();                        

                        this.couponApplied.text = res.data.data.text+' off membership ('+this.couponApplied.coupon_code+')';
                        this.couponErrors.success = res.data.message;
                        if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                            this.verifyClubApiData();
                        } else {
                            this.verifyApiData();
                        }
                      }else
                      {
                        this.loadWaiver = false;
                        this.couponApplied.status = 1;
                        this.couponApplied.amount = (res.data.data.value).toFixed(2);
                        this.couponApplied.text = res.data.data.text+' off membership ('+this.couponApplied.coupon_code+')';
                        this.couponErrors.success = res.data.message;
                        if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                            this.verifyClubApiData();
                        } else {
                            this.verifyApiData();
                        }
                      }
                    } else {
                        this.couponErrors.error = res.data.message;
                        this.couponApplied.status = 0;
                        this.couponApplied.amount = 0.00;
                        this.couponApplied.text = '';
                        this.calculateTotals();
                    }
                    this.button.coupon = 'Apply Coupon';
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    this.button.coupon = 'Apply Coupon';
                    return false;
                }).finally(() => {
            });
        },
        removeCoupon(){
            this.couponApplied = {
                status: 0,
                coupon_code: '',
                amount: 0.00,
                text: ''
            };
            let data = {
                registration_id : this.regId
            }
            axios.post(this.basePath+'api/remove-coupon', data, { headers: this.memberHeaders })
            .then((res) => {
            })
            .catch((err) => {
                console.log(err)
            });
            this.couponErrors = {};
            if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                this.verifyClubApiData();
            } else {
                this.verifyApiData();
            }
            this.calculateTotals();
        },
        removeInsurance(insurance_id) {
            let regData = {};
            regData.registration_id = this.regId;
            regData.insurance_id = insurance_id;
            console.log('---------------');
            console.log(regData);
            axios.post(this.basePath+'api/remove_club_insurance', regData)
                .then((res) => {
                    if(res.data.status == 'success') {
                        this.loadWaiver = false;
                        this.verifyClubApiData();
                    }
                    return res;
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    //this.step--;
                    //this.button.nextButton = 'Next';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {
            });
        },
        recordMembershipType(type) {
             console.log(type);
            if(type.membership_id == 6) {
                console.log('--club membership.fee' + this.membership.fee);
                console.log('--club membership.id' + type.membership_id);
                this.membership.id = type.membership_id;
                this.membership.fee = parseFloat(type.member_type_fee).toFixed(2);
                this.membership.type = type.member_type_text;
                console.log('--club membership fee' + parseFloat(this.membership.fee).toFixed(2));
            } else {
                console.log('--individual membership.fee' + this.membership.fee);
                this.form.membership_id = type.membership_id;
                this.membership.id = type.membership_id;
                this.membership.fee = parseFloat(type.member_type_fee).toFixed(2);
                this.membership.player_fee = parseFloat(type.player_fee).toFixed(2);
                this.membership.coach_fee = parseFloat(type.coach_fee).toFixed(2);
                this.membership.bg_check_fee = parseFloat(type.bg_check_fee).toFixed(2);
                this.membership.type = type.member_type_text;
                console.log('--membership fee' + parseFloat(this.membership.fee).toFixed(2));
            }

            this.membership.age_criteria = type.age_criteria;
            this.membership.member_type_id = type.member_type_id;
            if(this.old_membership_id != this.membership.id){
              this.divisions_list =[];
              this.divisions_lists =[];
            }
            this.getDivisions(this.membership.id);

          if(this.old_member_type_id != this.membership.member_type_id){
            this.addons_render++;
            this.personal_render++;
            this.contact_render++;
            this.family_render++;
            this.team_render++;
            this.membership.isValidateMemberType = 'personal';
            this.form.membership_id = type.membership_id;
            this.old_member_type_id = type.member_type_id;
          }else{
            this.old_member_type_id = type.member_type_id;
            this.old_membership_id = type.membership_id;
          }
            this.nextStep();
            document.getElementById('app').scrollIntoView();
        },

        recordPersonalData(personal) {
            console.log(personal);
          let lookup = '';
            if(personal.lookup != undefined) {
              lookup = personal.lookup[0];
              personal = personal.form;
            }
            this.age = this.getAge(personal.dob);
          // alliance fast pitch of member registration
            if(this.isChild && this.age < 18){
              this.isChildUsers = true;
            }else{
              this.isChildUsers = false;
            }
            // Event was succesfully emitted with VALIDATED data.
            // console.log("Persnal Info Event Emmited!");
            if(personal.affiliate != undefined){
                this.form.affiliate = personal.affiliate;
                Vue.delete(personal, 'affiliate');
            }

            if(personal.club_affiliate != undefined){
                this.form.affiliate = personal.club_affiliate;
            }

            //shooting
            if(personal.club != undefined && personal.club != ""){
                this.form.affiliate = personal.club == '-' ? personal.club : [personal.club];
                // Vue.delete(personal, 'club');
            }

            if(personal.new_club_name != undefined){
                this.form.new_club_name = personal.new_club_name;
                // Vue.delete(personal, 'new_club_name');
            }
            if(personal.new_club_phone != undefined){
                this.form.new_club_phone = personal.new_club_phone;
                // Vue.delete(personal, 'new_club_phone');
            }
            if(personal.new_club_email != undefined){
                this.form.new_club_email = personal.new_club_email;
                // Vue.delete(personal, 'new_club_email');
            }
            if(personal.veteran_status != undefined){
                this.form.veteran_status = personal.veteran_status;
            }
            //shooting

            //alliance_fast_pitch
            if(this.isTeamRegistration && personal.college_list != null){
              this.college_status = personal.college_list;
              this.collegeCommitted = personal.collegeCommitted;
            }

            /*if(this.isTeamRegistration && this.membership.id == 5){

              this.form.id = this.form.affiliate[0];
            }*/
            // SAVE valid data into form object
            this.form.members[0] = personal;

            if(this.isTeamRegistration && personal.id != undefined){
              this.memberLookupContact = lookup.contact_details;
              this.contact_render++;
            }
            this.nextStep();
            // document.getElementById('app').scrollIntoView();
        },

        // familyMembers(){
        //     this.$refs['my-modal'].show();
        // },
        demographicEditmodal(){
             this.$refs['my-modal'].show();
        },
        recordContactData(contact) {
            // this.form.email = contact.email;
            // this.form.retype_email = contact.retype_email;
            // this.form.printed_magazine = contact.printed_magazine;
            // this.form.address1 = contact.address1;
            // this.form.address2 = contact.address2;
            // this.form.zip = contact.zip;
            // this.form.state_code = contact.state_code;
            // this.form.state = contact.state;
            // this.form.city = contact.city;
            // this.form.country = contact.country;
            // this.form.phone_1 = contact.phone_1;
            // this.form.phone_2 = contact.phone_2;

            let fields = Object.keys(contact);
            fields.map((field, index) => {
                this.form[field] = contact[field];
            });

            this.currentRegContacts = contact;
            if(this.isChildUsers){
              this.form.first_name = this.currentRegContacts.first_name;
              this.form.last_name = this.currentRegContacts.last_name;
              this.form.middle_name = this.currentRegContacts.middle_name;
            }
            if(this.isChild && this.isTeamRegistration){
              this.parentCitizenship = contact['is_parent_citizenship'];
              this.eligibleCitizenship = contact['is_eligible_citizenship'];
              this.athleteCitizenship = contact['is_athlete_citizenship'];``
              this.form.members[0].citizenship = this.currentRegContacts.citizenship;
            }
            if(this.isTeamRegistration && this.membership.id == 5){
              this.form.members[0].email = this.currentRegContacts.email;
            }
            this.nextStep();
            //console.log(contact);
            //this.form.push(contact);
        },
        recordGeneralInfoData(general) {
            // this.form.safe_sport_status = general.safe_sport_status;
            // this.form.safe_sport_user_name = general.safe_sport_user_name;
            // this.form.safe_sport_email = general.safe_sport_email;
            // this.form.first_choice = general.first_choice;
            // this.form.second_choice = general.second_choice;
            // this.form.home_federation = general.home_federation;
            // this.form.skier_license = general.skier_license;

            let fields = Object.keys(general);
            fields.map((field, index) => {
              this.form[field] = general[field];
            });
            // if(this.isTeamRegistration) {
            //   this.form.membership_benefits = this.form.membership_benefits;
            //   this.form.camp_signup = this.form.camp_signup;
            // }

            this.currentRegContacts.discipline_1 = general.first_choice;
            this.currentRegContacts.discipline_2 = general.second_choice;
            this.nextStep();
        },
        personalEditmodal() {
        //this.$refs['my-modal'].show();
        },
        checkOutPersonalEditmodal() {
           this.checkOutPersonalEdit = true;
          this.errors={};
          this.verifyData.members.first_name =  this.tempverifyData.members.first_name;
          this.verifyData.members.last_name =  this.tempverifyData.members.last_name;
          this.verifyData.members.middle_name =  this.tempverifyData.members.middle_name;
          this.verifyData.members.suffix =  this.tempverifyData.members.suffix;
          this.verifyData.members.dob =  this.tempverifyData.members.dob;
          this.verifyData.members.gender =  this.tempverifyData.members.gender;
          this.verifyData.members.home_federation =  this.tempverifyData.members.home_federation;
          this.verifyData.members.skier_license =  this.tempverifyData.members.skier_license;
          this.verifyData.veteran_status  =  this.tempverifyData.members.veteran_status ;
          this.verifyData.members.affiliate  =  this.tempverifyData.members.affiliate ;
          this.verifyData.members.first_choice_txt =  this.tempverifyData.members.first_choice_txt;
          this.verifyData.members.second_choice_txt  =  this.tempverifyData.members.second_choice_txt;
          this.verifyData.members.first_choice =  this.tempverifyData.members.first_choice;
          this.verifyData.members.second_choice  =  this.tempverifyData.members.second_choice;
          this.verifyData.members.home_federation = this.tempverifyData.members.home_federation  ;
          this.verifyData.members.graduation_year = this.tempverifyData.members.graduation_year ;
          this.verifyData.members.jersey_number = this.tempverifyData.members.jersey_number ;
          this.verifyData.members.primary_position = this.tempverifyData.members.primary_position;
          this.verifyData.members.secondary_position = this.tempverifyData.members.secondary_position;
          this.verifyData.members.agl_player_id = this.tempverifyData.members.agl_player_id;
          this.verifyData.club =   this.tempverifyData.club_name;
          //this.$refs['my-modal'].show();
        },
      parentContactresetData(){
        this.errors={};
        this.verifyData.first_name =  this.tempverifyData.first_name;
        this.verifyData.last_name =  this.tempverifyData.last_name;
        this.verifyData.middle_name =  this.tempverifyData.middle_name;
        this.verifyData.phone_1 =  this.tempverifyData.phone_1;
        this.verifyData.phone_2 =  this.tempverifyData.phone_2;
        this.verifyData.email =  this.tempverifyData.email;
        this.verifyData.retype_email =  this.tempverifyData.retype_email;
        this.verifyData.retype_email = this.verifyData.email;
        this.verifyData.address_type =  this.tempverifyData.address_type;
        this.verifyData.address1 =  this.tempverifyData.address1;
        this.verifyData.address2 =  this.tempverifyData.address2;
        this.verifyData.city =  this.tempverifyData.city;
        this.verifyData.state =  this.tempverifyData.state;
        this.verifyData.state_code =  this.tempverifyData.state_code;
        this.verifyData.zip =  this.tempverifyData.zip;
        this.verifyData.country =  this.tempverifyData.country;
        this.verifyData.citizenship =  this.tempverifyData.citizenship;
        this.verifyData.parent_citizenship =  this.tempverifyData.parent_citizenship;
        this.verifyData.athlete_citizenship =  this.tempverifyData.athlete_citizenship;
        this.verifyData.eligible_citizenship =  this.tempverifyData.eligible_citizenship;
        this.form.is_eligible_citizenship = this.tempverifyData.form.is_eligible_citizenship;
        this.form.is_athlete_citizenship = this.tempverifyData.form.is_athlete_citizenship;
        this.form.is_parent_citizenship = this.tempverifyData.form.is_parent_citizenship;
        this.verifyData.company_name =  this.tempverifyData.company_name;
        this.verifyData.member_league_name =  this.tempverifyData.member_league_name;
        this.verifyData.age_division_name =  this.tempverifyData.age_division_name;
        this.verifyData.name =  this.tempverifyData.name;
        this.verifyData.agl_team_id =  this.tempverifyData.agl_team_id;
      },
      resetFormData(){
        this.errors={};
        this.verifyData.members.first_name =  this.tempverifyData.members.first_name;
        this.verifyData.members.last_name =  this.tempverifyData.members.last_name;
        this.verifyData.members.middle_name =  this.tempverifyData.members.middle_name;
        this.verifyData.members.suffix =  this.tempverifyData.members.suffix;
        this.verifyData.members.dob =  this.tempverifyData.members.dob;
        this.verifyData.members.gender =  this.tempverifyData.members.gender;
        this.verifyData.members.home_federation =  this.tempverifyData.members.home_federation;
        this.verifyData.members.skier_license =  this.tempverifyData.members.skier_license;
        this.verifyData.veteran_status  =  this.tempverifyData.members.veteran_status ;
        this.verifyData.members.affiliate  =  this.tempverifyData.members.affiliate ;
        this.verifyData.members.first_choice_txt =  this.tempverifyData.members.first_choice_txt;
        this.verifyData.members.second_choice_txt  =  this.tempverifyData.members.second_choice_txt;
        this.verifyData.members.first_choice =  this.tempverifyData.members.first_choice;
        this.verifyData.members.second_choice  =  this.tempverifyData.members.second_choice;
        this.verifyData.members.home_federation = this.tempverifyData.members.home_federation  ;
        this.verifyData.members.graduation_year = this.tempverifyData.members.graduation_year ;
        this.verifyData.members.jersey_number = this.tempverifyData.members.jersey_number ;
        this.verifyData.members.primary_position = this.tempverifyData.members.primary_position;
        this.verifyData.members.secondary_position = this.tempverifyData.members.secondary_position;
        this.verifyData.members.agl_player_id = this.tempverifyData.members.agl_player_id;
        this.verifyData.club =   this.tempverifyData.club_name;
        this.verifyData.members.college_committed = this.tempverifyData.members.college_committed;
        this.college_status = this.tempverifyData.members.college_status;
      },
        recordDemographicData(demographic) {
            //this.form = demographic;
            //this.form.push(demographic);
            this.form.diversity_id = demographic.diversity_id;
            this.form.ethnicity = demographic.ethnicity;
            this.form.disability = demographic.disability;

            if(demographic.veteran_status != undefined && demographic.veteran_status != '')
                this.form.veteran_status = demographic.veteran_status;

            let personalData = {
                primary_area_interest: demographic.primary_area_interest,
                category: demographic.category,
                category_name: demographic.category_name,
                hear_about: demographic.hear_about,
                issf: demographic.issf,
                cmp_no: demographic.cmp_no,
                nra_no: demographic.nra_no,
                sasp_no: demographic.sasp_no,
                sctp_no: demographic.sctp_no,
                shirt_size: demographic.shirt_size,
                code_of_conduct: demographic.code_of_conduct,
                code_of_conduct_label: demographic.code_of_conduct_label
            };

            this.form.members[0] = {
                ... this.form.members[0],
                ... personalData
            }

            console.log('membership type id='+this.membership.id);
            this.nextStep();
        },
        recordTeamData(team){
          let fields = Object.keys(team);
          fields.map((field, index) => {
            this.form[field] = team[field];
          });

          if(this.isTeamRegistration){
            this.form.members[0].address1 = this.currentRegContacts.address1;
            this.form.members[0].address2 = this.currentRegContacts.address2;
            this.form.members[0].state_code = this.currentRegContacts.state_code;
            this.form.members[0].state = this.currentRegContacts.state;
            this.form.members[0].city = this.currentRegContacts.city;
            this.form.members[0].zip = this.currentRegContacts.zip;
            this.form.members[0].country = this.currentRegContacts.country;
            this.form.members[0].phone_1 = this.currentRegContacts.phone_1;
            this.form.members[0].phone_2 = this.currentRegContacts.phone_2;
            this.form.members[0].email = this.currentRegContacts.email;
            this.form.members[0].citizenship = this.currentRegContacts.citizenship;
            this.form.members[0].phone = this.currentRegContacts.phone_1;
            this.form.members[0].team_id = team['id'];
            this.form.phone = this.currentRegContacts.phone_1;
          }

          this.nextStep();
        },

        // ,
        // hideModal() {
        recordDonationAmountData(donationData) {
             if(this.membership.member_type_id != 4) {
                 this.form.donation_amount = donationData.donation_amount != '' ? parseFloat(donationData.donation_amount).toFixed(2) : 0;
                 this.form.donation_id = donationData.donation_discipline != '' ? donationData.donation_discipline : '';
             } else {
                 this.clubInfoData.donation_amount = donationData.donation_amount != '' ? parseFloat(donationData.donation_amount).toFixed(2) : 0;
                 this.clubInfoData.donation_id = donationData.donation_discipline != '' ? donationData.donation_discipline : '';
             }
            this.donated.amount = this.form.donation_amount;
            this.donated.type = donationData.donation_discipline;
            console.log('donation steps='+this.step);
            this.nextStep();
            console.log('donation steps='+this.step);
        },
      recordAddonsAmountData(donationData) {
           console.log("data view",donationData.added_donation_amount);
        if((!isNaN(donationData.donation_amount) || donationData.donation_amount=="null") && donationData.donation_amount!=''){
          if(donationData.added_donation_amount != null && donationData.added_donation_amount != ''){
            this.form.donation_amount = donationData.added_donation_amount != '' ? parseFloat(donationData.added_donation_amount).toFixed(2) : 0;
            this.form.added_donation_amount = donationData.added_donation_amount != '' ? parseFloat(donationData.added_donation_amount).toFixed(2) : 0;
            console.log("data view",this.form.donation_amount);
            console.log("data view",this.form.added_donation_amount);
          }else{
            this.form.donation_amount = donationData.donation_amount != '' ? parseFloat(donationData.donation_amount).toFixed(2) : 0;
          }
          this.form.donation_id = 1;
          this.donated.amount = this.form.donation_amount;
        }
        if(this.form.team_addons.length < 3 || (donationData.agl_package_amount == 0 || donationData.usssa_membership_package_amount == 0 || donationData.team_insurance_amount == 0)) {
          if (!isNaN(donationData.agl_package_amount) && donationData.agl_package_amount!='' && !this.isChild) {
            var aglPackage_flag = false;
            var aglPackage_count = 0;
            if (donationData.agl_package_amount != 0 && this.isCheckAddonsExist(1,false)) {
              this.form.team_addons.push({addon_id: 1, amount: donationData.agl_package_amount});
            } else {
              if(this.form.team_addons.length > 0){
                this.form.team_addons.forEach((value,index)=>{
                  if(value.addon_id == 1){
                    value.amount = donationData.agl_package_amount;
                    aglPackage_flag = true;
                  }else{
                    aglPackage_count ++;
                  }
                });
              }
              if(this.form.team_addons.length == 0 || (donationData.agl_package_amount != '' && aglPackage_count >0 &&
                !aglPackage_flag)){
                this.form.team_addons.push({addon_id: 1, amount: donationData.agl_package_amount});
              }
            }
          }
          if (!isNaN(donationData.usssa_membership_package_amount) && donationData.usssa_membership_package_amount!=''  && !this.isChild) {
            var usssaMembership_flag = false;
            var usssaMembership_count = 0;
            if (donationData.usssa_membership_package_amount != 0 && this.isCheckAddonsExist(2,false)) {
              this.form.team_addons.push({addon_id: 2, amount: donationData.usssa_membership_package_amount});
            } else {
              if (this.form.team_addons.length > 0) {
                this.form.team_addons.forEach((value, index) => {
                  if (value.addon_id == 2) {
                    value.amount = donationData.usssa_membership_package_amount;
                    usssaMembership_flag = true;
                  }else{
                    usssaMembership_count ++;
                  }
                });
              }
              if(this.form.team_addons.length == 0 || (donationData.usssa_membership_package_amount!='' && usssaMembership_count >0 && !usssaMembership_flag)){
                this.form.team_addons.push({addon_id: 2, amount: donationData.usssa_membership_package_amount});
              }
            }
          }
          if (!isNaN(donationData.team_insurance_amount) && !this.isChild) {
            var insuranceAmount_flag = false;
            var insuranceAmount_count = 0;
            if (donationData.team_insurance_amount != 0 && this.isCheckAddonsExist(3,false)) {
              this.form.team_addons.push({addon_id: 3, amount: donationData.team_insurance_amount});
            }else {
              if(this.form.team_addons.length > 0) {
                this.form.team_addons.forEach((value, index) => {
                  if (value.addon_id == 3) {
                    value.amount = donationData.team_insurance_amount;
                    insuranceAmount_flag = true;
                  }else{
                    insuranceAmount_count ++;
                  }
                });
              }
             /* if(this.form.team_addons.length == 0 || (donationData.team_insurance_amount != '' && !insuranceAmount_flag
                 && insuranceAmount_count >0) ){
                this.form.team_addons.push({addon_id: 3, amount: donationData.team_insurance_amount});
              }*/

            }
          }
        }
        if(this.form.team_addons.length > 2){
          this.form.team_addons.forEach((value,index)=>{
            if(value.addon_id == 1){
              value.amount = donationData.agl_package_amount;
            }
            if(value.addon_id == 2){
              value.amount = donationData.usssa_membership_package_amount;
            }
            if(value.addon_id == 3){
              value.amount = donationData.team_insurance_amount;
            }
          });
        }
        this.nextStep();

      },
      isCheckAddonsExist(addon_id,isVerify,getAmt=false){
        let flag = true;
        var check = '';
        if(isVerify){
          check = this.verifyData.addons.filter(function (elm) {
            if (elm.addon_id == addon_id && elm.id != undefined) {
              return elm;
            }
          });
        }else{
          check = this.form.team_addons.filter(function (elm) {
            if (elm.addon_id == addon_id) {
              return elm;
            }
          });
        }


        if(check.length > 0 && isVerify && !getAmt){
          return check[0].id;
        }else if(getAmt) {
          if(check.length > 0){
            return check[0].amount;
          }else{
            return 0;
          }
        }else if (check.length > 0) {
          flag = false;
        }

        return flag;
      },
        recordAddedToCart(status) {
             console.log('addedtocart-'+status);
             this.addedToCart = status;
             this.nextStep();
        },
        recordClubinformation(clubinfo){
            console.log('=====club first====="');
            console.log(clubinfo);
            if(this.subdomain == "shooting"){
                this.clubInfoData.name = clubinfo.name;
                this.clubInfoData.website = clubinfo.website;
                this.clubInfoData.visibility_type = clubinfo.club_visible_type;
                this.clubInfoData.club_type = clubinfo.club_type;
                this.clubInfoData.club_disciplines = clubinfo.club_discipilines;
                this.clubInfoData.club_admin_member_id = clubinfo.club_admin_member_id;
                this.clubInfoData.club_admin_name = clubinfo.club_admin_name;
                this.clubInfoData.membership_id = this.membership.id;
            } else {
                this.clubInfoData.name = clubinfo.name;
                this.clubInfoData.type = clubinfo.type;
                this.clubInfoData.website = clubinfo.website;
                this.clubInfoData.team_sites = clubinfo.team_sites;
                this.clubInfoData.exclude = clubinfo.exclude;
                this.clubInfoData.secondary_type = clubinfo.secondary_type;
                this.clubInfoData.email = clubinfo.email;
                this.clubInfoData.address = clubinfo.address;
                this.clubInfoData.city = clubinfo.city;
                this.clubInfoData.state = clubinfo.state;
                this.clubInfoData.state_code = clubinfo.state_code;
                this.clubInfoData.zip = clubinfo.zip;
                this.clubInfoData.membership_id = this.membership.id;
                this.clubInfoData.club_sanction_data = clubinfo.club_sanction;
              console.log('=--------------');
              console.log(this.clubInfoData.team_sites);
                //this.clubInfoData.members = [];
                //this.clubInfoData.insured_members = [];
            }
            console.log(this.clubInfoData);
            this.nextStep();
        },
         recordClubprimaryinfo(clubprimaryinfo)
         {
             console.log('=====club primary====="');
              console.log(clubprimaryinfo);
              this.clubInfoData.members[0]=clubprimaryinfo;
              console.log(this.clubInfoData);
              console.log('------------------');
              console.log(this.clubMemberContactLength.length);
         },

        recordJuniorDevlopmentInfo(clubjuniorinfo){
            console.log('=====club junior parent====="');
            console.log(clubjuniorinfo);
            console.log('=====club junior index parent====="');
            console.log(clubjuniorinfo.index);
            this.clubInfoData.members[clubjuniorinfo.index] = clubjuniorinfo;
            console.log(this.clubInfoData);
            //this.clubMemberContactLength = this.clubInfoData.members;
        },

        recordSecondaryInfo(clubsecondaryinfo)
        {
            console.log('=====club recordSecondaryInfo====="');
        console.log(clubsecondaryinfo);
            this.clubInfoData.members[1]=clubsecondaryinfo;
        console.log(this.clubInfoData);
        },
        recordclubOfficersInfo(clubofficers)
        {
            console.log('=====club officers====="'+clubofficers.index);
            console.log(clubofficers);
            this.clubInfoData.members[clubofficers.index]=clubofficers;
            console.log('=====clubInfoData====="');
            console.log(this.clubInfoData);
            //this.clubMemberContactLength = this.clubInfoData.members;
        },
        recordSSCInfo(sscInfo)
        {
            // console.log('=====club officers====="'+clubofficers.index);
            // console.log(clubofficers);
            this.clubInfoData.members[sscInfo.index]=sscInfo;
            // console.log('=====clubInfoData====="');
            // console.log(this.clubInfoData);
        },
        removeSSC(event){
            this.clubInfoData.sscmember =  null;
        },
        recordSkiDirectorsInfo(skidirectiors)
        {
            this.clubInfoData.members.push(skidirectiors);
        },
        recordInsuranceInfo(insurance)
        {
            this.clubInfoData.insured_members[insurance.index] = insurance;
        },
        recordSanctionInfo(sanction)
        {
          this.clubInfoData.club_sanction_data = sanction;
        },
        deleteSanctionInfo()
        {
          this.clubInfoData.club_sanction_data = {};
        },
        deleteInsuranceInfo(index)
        {
            this.clubInfoData.insured_members.splice(index, 1);
        },
        calculateTotals() {
             console.log('calculate total');
             let membershipFee = this.membership.fee;
              console.log('calculate total amount',this.membership.fee);
             let playerFee = !this.freeRegistration ? this.membership.player_fee : process.env.VUE_APP_AFP_10U_FEE;
             let coachFee = this.membership.coach_fee;
             //let bg_check_fee = this.membership.bg_check_fee;
             let playerBgCheckFee = 0;
            membershipFee = (this.membership.totalMembershipFee == 0.00) ? this.membership.fee : this.membership.totalMembershipFee;
            if(this.couponApplied.status == 1) {
                membershipFee = membershipFee - this.couponApplied.amount;
                membershipFee = membershipFee < 0 ? 0 : membershipFee;
                console.log("couponWithMembershipfee", membershipFee)
             }            
            this.totals.sub_total = parseFloat(this.removeCommaFromNumber(membershipFee) + this.removeCommaFromNumber(this.donated.amount) + this.removeCommaFromNumber(this.totals.merchandise_total) + this.removeCommaFromNumber(this.totals.shipping_total)).toFixed(2);
            if(this.verifyClubInfoData.insured_members != undefined && this.verifyClubInfoData.insured_members.length > 0) {
                console.log('insurance length'+this.verifyClubInfoData.insured_members.length);
                 this.totals.sub_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total) + (parseInt(this.verifyClubInfoData.insured_members.length) * this.removeCommaFromNumber(this.insuranceFee))).toFixed(2);
             }
          console.log('sanction'+this.clubInfoData.club_sanction_data);
          console.log('sanction Fee'+this.sanctionFee);
          console.log('Total-'+this.totals.sub_total);
            if(this.clubInfoData.club_sanction_data.selected == true) {

              this.totals.sub_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total) + parseFloat(this.removeCommaFromNumber(this.sanctionFee))).toFixed(2);
              this.sanctionFee = parseFloat(this.removeCommaFromNumber(this.sanctionFee)).toFixed(2);
            }

          if(this.isTeamRegistration && this.verifyData.families.length > 0){
            this.player_registration_total_fee = playerFee * (this.verifyData.families.length - 1);
          }else if(this.isTeamRegistration && this.membership.id !=5){
            this.player_registration_total_fee = playerFee * this.verifyData.families.length;
            this.player_registration_total_fee = parseFloat(this.removeCommaFromNumber(this.player_registration_total_fee)).toFixed(2);
          }
          if(this.isTeamRegistration && this.membership.totalMembershipFee == 0.00) {
            membershipFee = this.team_count > 0?0:membershipFee;
            this.registration_bg_fee = this.coach_count > 0?0:parseFloat(this.removeCommaFromNumber(coachFee)).toFixed(2);
            let coachRegFee = this.registration_bg_fee;
            if(this.couponApplied.status == 1 && this.team_count > 0) {
              coachRegFee -= this.couponApplied.amount;
            }
            if(this.isTeamRegistration && this.membership.id ==5){
              this.registration_bg_fee = parseFloat(this.removeCommaFromNumber(this.membership.fee)).toFixed(2);
            }
            this.totals.sub_total = parseFloat(this.removeCommaFromNumber(coachRegFee) + this.removeCommaFromNumber(membershipFee) + this.removeCommaFromNumber(this.player_registration_total_fee) + this.removeCommaFromNumber(this.agl_amt) + this.removeCommaFromNumber(this.insurance_amt) + this.removeCommaFromNumber(this.usssa_amt) + this.removeCommaFromNumber(this.donated.amount)).toFixed(2);
          }
          if(this.isTeamRegistration && this.isChild && this.membership.totalMembershipFee == 0.00) {
            membershipFee = this.freeRegistration?process.env.VUE_APP_AFP_10U_FEE:membershipFee; // if you individual user as chosen of under 10 age division to be set as payment as zero
            //this.membership.fee = (this.freeRegistration?0:membershipFee;
            if(this.freeRegistration){
              this.membership.fee = process.env.VUE_APP_AFP_10U_FEE;
            }
            this.individual_registration_bg_fee = parseFloat(this.removeCommaFromNumber(this.membership.fee)).toFixed(2);
            this.totals.sub_total = parseFloat(this.removeCommaFromNumber(membershipFee)  + this.removeCommaFromNumber(this.donated.amount)).toFixed(2);
          }

            this.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.totals.sub_total))/100 * this.removeCommaFromNumber(this.processingFeePercent)).toFixed(2);
            this.totals.grand_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total)+this.removeCommaFromNumber(this.processingFee)).toFixed(2);


            if(this.stripeIntent.enable && this.totals.sub_total > 0)
            {   
              this.stripeIntent.viewisportzFeePercent = parseFloat((this.totals.sub_total/100 * this.stripeIntent.isportzFeePercent) + this.stripeIntent.isportzFeeCents).toFixed(2);           
              
              let grandTotal = parseFloat(parseFloat(this.stripeIntent.viewisportzFeePercent) + parseFloat(this.totals.sub_total)).toFixed(2)

              this.stripeIntent.viewprocessingFeePercent = parseFloat(((grandTotal)/100 * this.stripeIntent.processingFeePercent) + this.stripeIntent.processingFeeCents).toFixed(2);
              
              this.totals.grand_total = parseFloat(parseFloat(this.stripeIntent.viewprocessingFeePercent) + parseFloat(this.stripeIntent.viewisportzFeePercent) + parseFloat(this.totals.sub_total)).toFixed(2);
              
            }


            this.totals.sub_total = this.totals.sub_total < 0 ? 0 : this.totals.sub_total;
            this.totals.grand_total = this.totals.grand_total < 0 ? 0 : this.totals.grand_total;

            if(this.totals.grand_total == 0.0){
                this.paymentForm.visible = false;
                this.paymentForm.auto_renew = 0;
            } else {
                this.paymentForm.visible = true;
                this.paymentForm.auto_renew = this.getSiteProps('general.auto_renewal') && (this.verifyData.member_type_id != 4 || this.getSiteProps('general.club_auto_renewal_option')) ? 1:0;
            }
        },
        getMemberType()
        {
            axios.get(this.basePath+"api/getMemberType")
                .then(response => {
                    this.memberTypes = response.data.data;
                    console.log(this.memberTypes);
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getDiversity()
        {
            axios.get(this.basePath+"api/getDiversity")
                .then(response => {
                    this.diversities = response.data.data;
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getDiscipline()
        {
            axios.get(this.basePath+"api/getDisciplines")
                .then(response => {
                    this.disciplines = response.data.data;
                    let discipline_codes = Object.keys(this.disciplines);
                    if(discipline_codes.length && this.disciplines_list.length == 0){
                        discipline_codes.forEach(code => {
                            this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
                        });
                    }
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getGroup()
        {
            axios.get(this.basePath+'api/getGroup')
                .then(response => {
                    this.groups = response.data.data;
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        recordFamilyInfo(family)
        {
            console.log('family index='+family.index);
            if(!family.invite) {
              this.form.members[family.index] = family;
              if (this.isTeamRegistration) {
                this.form.members[0].address1 = this.currentRegContacts.address1;
                this.form.members[0].address2 = this.currentRegContacts.address2;
                this.form.members[0].state_code = this.currentRegContacts.state_code;
                this.form.members[0].state = this.currentRegContacts.state;
                this.form.members[0].city = this.currentRegContacts.city;
                this.form.members[0].zip = this.currentRegContacts.zip;
                this.form.members[0].country = this.currentRegContacts.country;
                this.form.members[0].phone_1 = this.currentRegContacts.phone_1;
                this.form.members[0].phone_2 = this.currentRegContacts.phone_2;
                this.form.members[0].citizenship = this.currentRegContacts.citizenship;
              }
            }else{
              this.form.invite_players = family.invite_players;
            }
        },
        removeMembers(i){
            this.form.members.splice(i, 1);
        },
        removeClubMembers(i){
            console.log('dsfs=+'+i);
            console.log('dsfs=+'+this.clubInfoData.members.length);
            this.clubInfoData.members.splice(i, 1);
            console.log('dsfs1=+'+this.clubInfoData.members.length);
        },
        resetModal() {
            //this.verifyClubInfoData = {}
            //this.company = {}
            //this.member = {}
        },
        handlePersonalEditOk(bvModalEvt) {
          // Prevent modal from closing
          bvModalEvt.preventDefault();
          this.checkDuplicateForEdit();
        },
        handlePersonalEditOkAfterCheckDuplication(){
          
          if(this.dupResult){
            if(this.validateVerifyPersonal()) {
              if(this.isTeamRegistration){
                this.verifyData.form_type = "personal";
              }
                this.UpdateVerifiedDetails('personal');
                // Trigger submit handler
                this.showLoader=true;
                this.handleSubmit()
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-personal-edit');
                    this.duplicateErrorForPersonal = '';
                });
            }
            }else{
              if(this.verifyData.members.member_type_id == 4) {
                          return this.duplicateErrorForPersonal= "Looks like this club is already registered for current year. For more information please contact member service.";
                        }else{
                          return this.duplicateErrorForPersonal= "Looks like this person is already registered for current year. For more information please contact member service.";
                        }
            }
        },
        handleAddonsEditOk(bvModalEvt) {
        // Prevent modal from closing
          if(!this.isChild && this.membership.id !=5) {
            if (!isNaN(this.verifyData.donation_amount) || this.verifyData.added_donation_amount != null) {
              if(this.verifyData.added_donation_amount != null){
                this.verifyData.donation_amount = this.verifyData.added_donation_amount != '' ? parseFloat(this.verifyData.added_donation_amount).toFixed(2) : parseFloat(this.verifyData.donation_amount).toFixed(2);
              }else{
                this.verifyData.donation_amount = this.verifyData.donation_amount != '' ? parseFloat(this.verifyData.donation_amount).toFixed(2) : 0;
              }
              this.verifyData.donation_id = 1;
              this.donated.amount = this.verifyData.donation_amount;
            }

            if (!isNaN(this.verifyData.addons.agl_amt) && this.verifyData.addons.agl_amt != null) {
              if (this.verifyData.addons.agl_amt != 0) {
                if(this.verifyData.addons.length < 3 && this.verifyData.addons.agl_amt !=''){
                  var agl_count = 0;
                  var agl_flag = false;
                  if(this.verifyData.addons.length >0) {
                    this.verifyData.addons.forEach((value, index) => {
                      if (value.addon_id == 1) {
                        value.amount = this.verifyData.addons.agl_amt;
                        agl_flag = true;
                      }else{
                        agl_count ++;
                      }
                    });
                  }
                  if(this.verifyData.addons.length == 0 || (agl_count > 0 && !agl_flag)) {
                    this.verifyData.addons.push({
                      addon_id: 1,
                      amount: this.verifyData.addons.agl_amt,
                      regId: this.regId
                    });
                  }
                }else{
                  if(this.verifyData.addons.length >0) {
                    this.verifyData.addons.forEach((value, index) => {
                      if (value.addon_id == 1) {
                        value.amount = this.verifyData.addons.agl_amt;
                      }
                    });
                  }
                }
              } else {
                var agl_count1 = 0;
                var agl_flag1 = false;
                if(this.verifyData.addons.length >0) {
                  this.verifyData.addons.forEach((value, index) => {
                    if (value.addon_id == 1) {
                      value.amount = this.verifyData.addons.agl_amt;
                      agl_flag1 = true;
                    }else{
                      agl_count1 ++;
                    }
                  });
                }
                if(this.verifyData.addons.length == 0 || (agl_count1 > 0 && !agl_flag1)) {
                  this.verifyData.addons.push({
                    addon_id: 1,
                    amount: this.verifyData.addons.agl_amt,
                    regId: this.regId
                  });
                }
              }
              this.agl_amt = this.verifyData.addons.agl_amt;
            }
            if (!isNaN(this.verifyData.addons.usssa_amt) && this.verifyData.addons.usssa_amt != null) {
            if (this.verifyData.addons.usssa_amt != 0){
              if(this.verifyData.addons.length < 3 && this.verifyData.addons.usssa_amt !=''){
                var usssa_count = 0;
                var usssa_flag = false;
                if(this.verifyData.addons.length >0) {
                  this.verifyData.addons.forEach((value, index) => {
                    if (value.addon_id == 2) {
                      value.amount = this.verifyData.addons.usssa_amt;
                      usssa_flag = true;
                    }else{
                      usssa_count ++;
                    }
                  });
                }

                if(this.verifyData.addons.length == 0 || (usssa_count >0 && !usssa_flag)) {
                  this.verifyData.addons.push({
                    addon_id: 2,
                    amount: this.verifyData.addons.usssa_amt,
                    regId: this.regId
                  });
                }
              }else{
                if(this.verifyData.addons.length >0) {
                  this.verifyData.addons.forEach((value, index) => {
                    if (value.addon_id == 2) {
                      value.amount = this.verifyData.addons.usssa_amt;
                    }
                  });
                }
              }
            } else {
              var usssa_count1 = 0;
              var usssa_flag1 = false;
              if(this.verifyData.addons.length >0) {
                this.verifyData.addons.forEach((value, index) => {
                  if (value.addon_id == 2) {
                    value.amount = this.verifyData.addons.usssa_amt;
                    usssa_flag1 = true;
                  }else{
                    usssa_count1 ++;
                  }
                });
              }

              if(this.verifyData.addons.length == 0 || (usssa_count1 >0 && !usssa_flag1)) {
                this.verifyData.addons.push({
                  addon_id: 2,
                  amount: this.verifyData.addons.usssa_amt,
                  regId: this.regId
                });
              }
            }
              this.usssa_amt = this.verifyData.addons.usssa_amt;
            }
            if (!isNaN(this.verifyData.addons.insurance_amt) && this.verifyData.addons.insurance_amt != null ) {
              if (this.verifyData.addons.insurance_amt != 0){
                if(this.verifyData.addons.length < 3 && this.verifyData.addons.insurance_amt !=''){
                  var insurance_count = 0;
                  var insurance_flag = false;
                  if(this.verifyData.addons.length >0) {
                    this.verifyData.addons.forEach((value, index) => {
                      if (value.addon_id == 3) {
                        value.amount = this.verifyData.addons.insurance_amt;
                        insurance_flag = true;
                      }else{
                        insurance_count ++;
                      }
                    });
                  }
                  if(this.verifyData.addons.length == 0 || (insurance_count > 0 && !insurance_flag)) {
                    this.verifyData.addons.push({
                      addon_id: 3,
                      amount: this.verifyData.addons.insurance_amt,
                      regId: this.regId
                    });
                  }
                }else{
                  if(this.verifyData.addons.length >0) {
                    this.verifyData.addons.forEach((value, index) => {
                      if (value.addon_id == 3) {
                        value.amount = this.verifyData.addons.insurance_amt;
                      }
                    });
                  }
                }
              } else {
                var insurance_count1 = 0;
                var insurance_flag1 = false;
                if(this.verifyData.addons.length >0) {
                  this.verifyData.addons.forEach((value, index) => {
                    if (value.addon_id == 3) {
                      value.amount = this.verifyData.addons.insurance_amt;
                      insurance_flag1 = true;
                    }else{
                      insurance_count1 ++;
                    }
                  });
                }
                if(this.verifyData.addons.length == 0 || (insurance_count1 > 0 && !insurance_flag1)) {
                  this.verifyData.addons.push({
                    addon_id: 3,
                    amount: this.verifyData.addons.insurance_amt,
                    regId: this.regId
                  });
                }
              }
              this.insurance_amt = this.verifyData.addons.insurance_amt;
            }
          }else{
            if (!isNaN(this.verifyData.donation_amount) || this.verifyData.added_donation_amount != null) {
              console.log("data donation",this.verifyData.added_donation_amount);
              console.log("data donation",this.verifyData.donation_amount);
              if(this.verifyData.added_donation_amount != null){
                console.log("data donation",this.verifyData.added_donation_amount != '');
                this.verifyData.donation_amount = this.verifyData.added_donation_amount != '' ? parseFloat(this.verifyData.added_donation_amount).toFixed(2) : this.verifyData.donation_amount;
              }else{
                this.verifyData.donation_amount = this.verifyData.donation_amount != '' ? parseFloat(this.verifyData.donation_amount).toFixed(2) : 0;
              }
              this.verifyData.donation_id = 1;
              console.log("data",this.verifyData.donation_amount);

              this.donated.amount = this.verifyData.donation_amount;
              this.form.donation_amount = this.verifyData.donation_amount;
              console.log("data",this.form.donation_amount);
            }
          }
          this.showLoader=true;
          if(this.isTeamRegistration){
            this.verifyData.form_type = "addons";
          }
        bvModalEvt.preventDefault()
          //this.UpdateVerifiedDetails('addons');
          // Trigger submit handler
          this.handleSubmit()
          this.UpdateVerifiedDetails('addons');
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-addons-edit')
          });
      },
        handlePersonalDemographicEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if(this.validateVerifyDemographic()) {
                // Trigger submit handler
                this.showLoader=true;
                this.handleSubmit()
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-demographic-edit')
                });
            }
        },
        handlePersonalContactEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if(this.verifyData.head_contact != undefined){
              this.isHeadCoachContact = true;
            }else{
              this.isHeadCoachContact = false;
            }
            if(this.validateVerifyContact()) {
                if(this.isTeamRegistration){
                  this.verifyData.form_type = "contact";
                }
                // Trigger submit handler
                this.showLoader=true;
                this.handleSubmit()
                this.UpdateVerifiedDetails('contact');
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-contact-edit')
                });
            }
        },
        handlePersonalHeadCoachContactEditOk(bvModalEvt) {
          // Prevent modal from closing
          bvModalEvt.preventDefault()
          if(this.verifyData.head_contact != undefined){
            this.isHeadCoachContact = true;
          }else{
            this.isHeadCoachContact = false;
          }
          if(this.validateVerifyHeadCoachContact()) {
            if(this.isTeamRegistration){
              this.verifyData.form_type = "head contact";
            }
            this.UpdateVerifiedDetails('contact',this.isHeadCoachContact);
            // Trigger submit handler
            this.showLoader=true;
            this.handleSubmit()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-coach-contact-edit')
            });
            if(this.verifyData.age_division != null && this.isTeamRegistration && !this.isChild) {
              this.team_render++
            }
          }
        },
        handleClubEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if(this.validateVerifyClubInfo()) {
                // Trigger submit handler
                this.handleClubSubmit()
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-club-edit')
                });
            }
        },
        handleClubContactEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if(this.validateVerifyClubContact()) {
                // Trigger submit handler
                this.handleClubSubmit()
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-club-contact-edit')
                });
            }
        },
        handleClubSubmit() {
            this.clubVerifyUpdateApi();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            /*if (!this.checkFormValidity()) {
                return
            }
            if(this.member.first_name !='' && this.member.last_name !='') {
                this.form.name = this.member.first_name + ' ' + this.member.last_name;
            } else if(this.company.company_name != '') {
                this.form.name = this.company.company_name;
            }*/
            console.log("-----------");
            console.log(this.verifyData);
            console.log("-------1----");
            console.log(this.verifyEditData);
            console.log("----u-------");
            //console.log(this.verifyEditData);
            console.log("----z-------");
            //this.verifyEditData.members = [];
            console.log(this.verifyEditData);
            //console.log(this.verifyData.members);
            console.log(this.verifyEditData.membership_id);
            if(this.verifyData.membership_id == 5 && !this.isTeamRegistration) {
              console.log('-------1------');
              let HohMember = [this.verifyData.members];
              console.log(HohMember);
              this.verifyEditData.members = HohMember.concat(this.verifyData.families);
              console.log(this.verifyData.families);
              console.log(this.verifyEditData.members);
            } else {
              console.log('-------2------');
              this.verifyEditData.members = [this.verifyData.members];
            }
            console.log("----y-------");
            console.log(this.verifyEditData.members);
            this.verifyUpdateApi();
            //this.$emit('insurance-data', this.form);
        },
        getAge(birth_date) {
            let birth_date_arr = birth_date.split('/');
            console.log(birth_date_arr);
            let today_date = (this.subdomain == 'alliancefastpitch')? new Date(this.currentSeason.start_date) : new Date();
            let today_year = today_date.getFullYear();
            let today_month = today_date.getMonth();
            let today_day = today_date.getDate();
            let age = today_year - birth_date_arr[2];

            if(this.subdomain != 'alliancefastpitch') {
              if (today_month < (birth_date_arr[0] - 1)) {
                age--;
              }
              if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1])) {
                age--;
              }
            }
            return age;
        },
        ageGroup(birth_date) {
             console.log(birth_date);
          let age = this.getAge(birth_date);
            console.log(age);
          if(age > 17) {
              return 'Minor';
          } else if(age <= 17) {
              return 'Adult';
          }
        },
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateVerifyClubInfo() {
            this.errors = {}
            this.valid = false;

            const validName = validateClubName(this.verifyClubInfoEditData.name);
            this.errors.name = validName.error;

            if (validName.valid) {
                this.valid = true;
            }

            //Shooting
            var clubVisibleType = "";
            if(this.fields.club_info != undefined
            && this.fields.club_info.club_visible_type != undefined  && this.fields.club_info.club_visible_type.required != undefined
            && this.fields.club_info.club_visible_type.required){
                clubVisibleType = validateSelect(this.verifyClubInfoEditData.visibility_type);
                this.errors.club_visible_type = clubVisibleType.error;
            }
            var clubType2 = "";
            if(this.fields.club_info != undefined
            && this.fields.club_info.club_type != undefined && this.fields.club_info.club_type.required != undefined
            && this.fields.club_info.club_type.required ){
                clubType2 = validateSelect(this.verifyClubInfoEditData.club_type);
                if(Array.isArray(this.verifyClubInfoEditData.club_type)){
                    if(this.verifyClubInfoEditData.club_type.length == 0){
                        clubType2.valid = false;
                        clubType2.error = "This field is required";
                    } else {
                        clubType2.valid = true;
                        clubType2.error = "";
                    }
                }
                this.errors.club_type = clubType2.error;
            }
            var clubdisciplines = "";
            if(this.fields.club_info != undefined
            && this.fields.club_info.club_discipilines != undefined && this.fields.club_info.club_discipilines.required != undefined
            && this.fields.club_info.club_discipilines.required ){
                clubdisciplines = validateCheck(this.verifyClubInfoEditData.club_disciplines);
                this.errors.club_discipilines = clubdisciplines.error;
            }
            var clubAdminID = "";
            if(this.fields.club_info != undefined
            && this.fields.club_info.club_admin_member_id != undefined && this.fields.club_info.club_admin_member_id.required != undefined
            && this.fields.club_info.club_admin_member_id.required ){
                clubAdminID = validateName(this.verifyClubInfoEditData.club_admin_member_id.toString(),'',true);
                this.errors.club_admin_member_id = clubAdminID.error;
            }
            var clubAdminName = "";
            if(this.fields.club_info != undefined
            && this.fields.club_info.club_admin_name != undefined && this.fields.club_info.club_admin_name.required != undefined
            && this.fields.club_info.club_admin_name.required ){
                clubAdminName = validateName(this.verifyClubInfoEditData.club_admin_name);
                this.errors.club_admin_name = clubAdminName.error;
            }

            if(clubVisibleType != "" && clubType2 != "" && clubdisciplines != "" && clubAdminID != "" && clubAdminName != ""){
                if(clubVisibleType.valid && clubType2.valid && clubdisciplines.valid && clubAdminID.valid && clubAdminName.valid && validName.valid)
                    this.valid = true;
                else
                    this.valid = false;
            }

            //Shooting

            return this.valid;
        },
        validateVerifyClubContact() {
            this.errors = {}
            this.valid = false;

            const validCountry = validateSelect(this.verifyClubInfoData.primary_contact.country);
            this.errors.country = validCountry.error;

            const validStateCode = validateSelect(this.verifyClubInfoData.primary_contact.state_code);
            const validState = validateStateName(this.verifyClubInfoData.primary_contact.state);

            if(this.isTeamRegistration && this.subdomain == 'alliancefastpitch' &&  validCountry.valid && (this.verifyClubInfoData.primary_contact.country != 'USA' || this.verifyClubInfoData.primary_contact.country != 'Canada')) {
                this.errors.state = validState.error;
            }else if(validCountry.valid && this.verifyClubInfoData.primary_contact.country != 'USA') {
                this.errors.state = validState.error;
            } else {
                this.errors.state_code = validStateCode.error;
            }
            const validCity = validateName(this.verifyClubInfoData.primary_contact.city);
            this.errors.city = validCity.error;

            const validZip = validateZip(this.verifyClubInfoData.primary_contact.zip);
            this.errors.zip = validZip.error;

            if (validCountry.valid && validCity.valid && validZip.valid) {
                this.valid = true;
                if(this.isTeamRegistration && this.subdomain == 'alliancefastpitch' && (this.verifyClubInfoData.primary_contact.country == 'USA' || this.verifyClubInfoData.primary_contact.country == 'Canada') ) {
                    this.verifyClubInfoData.primary_contact.state = '';
                    if(!validStateCode.valid) {
                        this.valid = false;
                    }
                }else if(this.verifyClubInfoData.primary_contact.country == 'USA') {
                    this.verifyClubInfoData.primary_contact.state = '';
                    if(!validStateCode.valid) {
                        this.valid = false;
                    }
                } else if(!validState.valid) {
                    this.verifyClubInfoData.primary_contact.state_code = '';
                    this.valid = false;
                }
            }
            return this.valid;
        },
        validateVerifyPersonal() {
            this.errors = {}
            var validPrimaryPosition = "", validThrow = "", validBats = "", validJerseyNumber = "", validGraduationYear ="";
            var validTitle = {valid:true}, validMiddleName = {valid:true}, validSuffix = {valid:true}, validFederationId ={valid:true},validHomeFederationId={Valid:true},validTeam = {valid:true};
            // const validTitle = validateTitle(this.verifyData.members.title);
            // this.errors.title = validTitle.error;

            const validFirstName = validateParentName(this.verifyData.members.first_name);
            this.errors.first_name = validFirstName.error;

            validMiddleName = validateOptionalTextFields(this.verifyData.members.middle_name, "Middle name");
            this.errors.middle_name = validMiddleName.error;
            if(!this.isTeamRegistration || !this.isChild) {
              validSuffix = validateOptionalTextFields(this.verifyData.members.suffix, "Suffix");
              this.errors.suffix = validSuffix.error;

              validFederationId = validateFedration(this.verifyData.members.skier_license,"Federation ID");
              this.errors.skier_license = validFederationId.error;

              validHomeFederationId = validateSelect(this.verifyData.members.home_federation,"Federation");
              this.errors.home_federation = validHomeFederationId.error;
            }
            const validLastName = validateParentLastName(this.verifyData.members.last_name);
            this.errors.last_name = validLastName.error;

            const validDob = validateDob(this.verifyData.members.dob);
            this.errors.dob = validDob.error;

            const validGender = { valid: true, error: null };

            if(!this.isChild) {
              const validGender = validateGender(this.verifyData.members.gender);
              this.errors.gender = validGender.error;
            }

            if(this.isChild && this.isTeamRegistration){
              validTeam = validateSelect(this.verifyData.club.toString());
              this.errors.organization = validTeam.error;
            }
            if(this.isChild && this.isTeamRegistration){
              validPrimaryPosition = validateSelect(this.verifyData.members.primary_position,"Primary");
              this.errors.primary_position = validPrimaryPosition.error;

              validThrow = validateRadio(this.verifyData.members.throws.toString());
              this.errors.throw = validThrow.error;

              validBats = validateRadio(this.verifyData.members.bats.toString());
              this.errors.bats = validBats.error;

              validJerseyNumber = validateJerseyNumber(this.verifyData.members.jersey_number);
              this.errors.jersey_number = validJerseyNumber.error;

              validGraduationYear = validateGraduationYear(this.verifyData.members.graduation_year);
              this.errors.graduation_year = validGraduationYear.error;
            }
          if (this.verifyData.members.home_federation != 'USA' && !validFederationId.valid) {
            this.errors.skier_license = 'Federation ID is required';
          } else if (this.verifyData.members.home_federation == 'USA') {
            this.verifyData.members.skier_license = '';
          }
            /*const validSelect = validateSelect(this.verifyData.members.citizenship);
            this.errors.citizenship = validSelect.error;*/
            if (validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid && validMiddleName.valid && validHomeFederationId.valid && (this.verifyData.members.skier_license != 'USA'?validFederationId.valid:true) && validSuffix.valid  ||
                (validFirstName.valid && validLastName.valid && validPrimaryPosition.valid && validDob.valid
                    && validThrow.valid && validBats.valid && validJerseyNumber.valid && validGraduationYear.valid && validMiddleName.valid && validSuffix.valid) ||
                (this.isTeamRegistration && this.membership.id == 5 && validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid && validMiddleName.valid && validSuffix.valid && validTeam.valid  )) {
                let dob = this.getAge(this.verifyData.members.dob);
                console.log(this.membership.id);
                console.log('dob='+dob);

                // if(this.membership.id == 1 && dob < 25) {
                //     console.log('dob1='+dob);
                //     this.valid = false;
                //     this.errors.dob = 'Individual Active user age should be age 25 or older. Go back and change membership type to U25 Active membership.';
                // } else if(this.membership.id == 2 && dob >= 25) {
                //     console.log('dob1='+dob);
                //     this.valid = false;
                //     this.errors.dob = 'Individual Under 25 Active user age should be less than 25';
                // } else if(this.membership.id == 5 && dob < 25) {
                //     console.log('dob3='+dob);
                //     this.valid = false;
                //     this.errors.dob = 'Family Head of house age should be greater than 25';
                // } else {
                //     console.log('dob2='+dob);
                //     this.errors.dob = '';
                //     this.valid = true;
                // }

                if(!this.validateDate(this.verifyData.members.dob)){
                    this.valid = false;
                    this.errors.dob = 'Invalid Date';
                } else {
                    this.errors.dob = '';
                    if(this.membership.age_criteria){
                        if(this.membership.age_criteria.compare=="above" && dob < this.membership.age_criteria.value) {
                            this.valid = false;
                            this.errors.dob = this.membership.age_criteria.error_msg;
                        } else if(this.membership.age_criteria.compare=="below" && dob > this.membership.age_criteria.value) {
                            this.valid = false;
                            this.errors.dob = this.membership.age_criteria.error_msg;
                        } else if(!Object.is(Math.abs(dob), +dob)) {
                          this.valid = false;
                          this.errors.dob = 'Invalid Date';
                        } else {
                            this.errors.dob = '';
                            this.valid = true;
                        }
                    } else {
                        this.valid = true;
                    }
                }
            }

            if(this.fields.personal != undefined && this.fields.personal.veteran_status != undefined){
                const validMilitary = validateRadio(this.verifyData.veteran_status, "Military Status");
                this.errors.veteran_status = validMilitary.error;

                if(!validMilitary.valid) this.valid = false;
            }
            if(!this.isChild && !this.isTeamRegistration) {
              if (this.fields.general != undefined && this.fields.general.sport_discipline != undefined && this.fields.general.sport_discipline.first_choice != undefined) {
                const validFirstChoice = validateSelect(this.verifyData.first_choice);
                if (Array.isArray(this.verifyData.first_choice)) {
                  if (this.verifyData.first_choice.length == 0) {
                    validFirstChoice.valid = false;
                    validFirstChoice.error = "This field is required";
                  } else {
                    validFirstChoice.valid = true;
                    validFirstChoice.error = "";
                  }
                }

                this.errors.first_choice = validFirstChoice.error;
                if (!validSuffix.valid  || !validFirstChoice.valid&&!(this.subdomain=='shooting'&&this.membership.member_type_id == 7)) this.valid = false;
              }
            }
            if(this.isChild && this.isTeamRegistration){
              if(!validFirstName.valid || !validLastName.valid || !validPrimaryPosition.valid || !validDob.valid
                  || !validThrow.valid || !validBats.valid || !validJerseyNumber.valid || !validGraduationYear.valid || !validSuffix.valid){
                this.valid = false;
              }
            }else{
              if(!validFirstName.valid || !validLastName.valid || !validSuffix.valid){
                this.valid = false;
              }
            }
            if(this.valid&&this.college_status==2){
              this.verifyData.members.college_committed='';
              this.tempverifyData.members.college_committed='';
            }
            return this.valid;
        },
        validateVerifyDemographic() {
            this.errors = {}

            const validDiversity = validateRadio(this.verifyData.diversity_id);
            this.errors.diversity_id = validDiversity.error;

            const validEthnicity = validateRadio(this.verifyData.ethnicity);
            if(document.querySelector("#modal-demographic-edit [name='ethnicity']") != null){
                this.errors.ethnicity = validEthnicity.error;
            } else {
                validEthnicity.valid = true;
            }

            const validDisability = validateRadio(this.verifyData.disability);
            if(document.querySelector("#modal-demographic-edit [name='ethnicity']") != null){
                this.errors.disability = validDisability.error;
            } else {
                validDisability.valid = true;
            }

            const validVeteranStatus = validateRadio(this.verifyData.veteran_status);
            if(document.querySelector("#modal-demographic-edit [name='ethnicity']") != null){
                this.errors.veteran_status = validVeteranStatus.error;
            } else {
                validVeteranStatus.valid = true;
            }

            if (validDiversity.valid && validEthnicity.valid && validDisability.valid && validVeteranStatus.valid) {
                this.valid = true;
            }

            if(this.fields.demographic != undefined && this.fields.demographic.primary_area_interest != undefined){
                const validPrimaryAreaInterest = validateSelect(this.verifyData.members.primary_area_interest);
                this.errors.primary_area_interest = validPrimaryAreaInterest.error;
                if(!validPrimaryAreaInterest.valid) this.valid = false;
            }

            if(this.fields.demographic != undefined && this.fields.demographic.category != undefined){
                const validCategory = validateSelect(this.verifyData.members.category.toString());
                this.errors.category = validCategory.error;
                if(!validCategory.valid) this.valid = false;
            }

            if(this.fields.demographic != undefined && this.fields.demographic.hear_about != undefined){
                const validHearAbout = validateSelect(this.verifyData.members.hear_about.toString());
                this.errors.hear_about = validHearAbout.error;
                if(!validHearAbout.valid) this.valid = false;
            }

            return this.valid;
        },
        validateVerifyContact() {
            this.errors = {}
            this.valid = false;
            var validFirstName = "", validLastName = "", validEmail = "",validRetypeEmail= "", validPhone1 = {valid:true}, validPhone2 = {valid:true};
            var validCheckParentCitizenship = {valid:true}, validCheckAthleteCitizenship = {valid:true}, validCheckEligibleCitizenship = {valid:true};
            var validParentCitizenship = {valid:true}, validAthleteCitizenship = {valid:true}, validEligibleCitizenship = {valid:true};
            console.log('----------');
            console.log(this.verifyData);

            const validAddress1 = validateAddressName(this.verifyData.address1);
            this.errors.address1 = validAddress1.error;

            // const validAddress2 = validateName(this.verifyData.address2);
            // this.errors.address2 = validAddress2.error;

            const validCountry = validateSelect(this.verifyData.country);
            this.errors.country = validCountry.error;
            const validStateCode = validateSelect(this.verifyData.state_code);
            const validState = validateStateName(this.verifyData.state);

            if(validCountry.valid && (this.verifyData.country != 'USA' && this.verifyData.country !='Canada') && this.isTeamRegistration && this.subdomain == 'alliancefastpitch') {
                this.errors.state = validState.error;  
            }else if(validCountry.valid && this.verifyData.country != 'USA' && this.subdomain != 'alliancefastpitch') {
                this.errors.state = validState.error;  
            } else {
                this.errors.state_code = validStateCode.error;
            }

            const validCity = validateName(this.verifyData.city);
            this.errors.city = validCity.error;

            const validZip = validateZip(this.verifyData.zip, this.verifyData.country);
            this.errors.zip = validZip.error;
            if(this.isChild || !this.isTeamRegistration || (this.isTeamRegistration && this.membership.id ==5))
            {
              validPhone1 = validatePhone(this.verifyData.phone_1, this.validTelinput);
              this.errors.phone_1 = validPhone1.error;

              validPhone2 = validatePhone2(this.verifyData.phone_2,this.validTelinput2);
              this.errors.phone_2 = validPhone2.error;
            }

            if(!this.isHeadCoachContact && this.isChild){
              validEmail = validateEmail(this.verifyData.email);
              this.errors.email = validEmail.error;

              validRetypeEmail = validateEmail(this.verifyData.retype_email);
              this.errors.retype_email = validRetypeEmail.error;
              validPhone1 = validatePhone(this.verifyData.phone_1, this.validTelinput);
              this.errors.phone_1 = validPhone1.error;

              validCheckParentCitizenship = validateRadio(this.form.is_parent_citizenship.toString(),"Parent/Guardian");
              this.errors.is_parent_citizenship = validCheckParentCitizenship.error;

              validCheckAthleteCitizenship = validateRadio(this.form.is_athlete_citizenship.toString(),"Child/Athlete");
              this.errors.is_athlete_citizenship = validCheckAthleteCitizenship.error;
              if (this.parentCitizenship==1) {
                validParentCitizenship = validateSelect(this.verifyData.parent_citizenship, "Parent/Guardian");
                this.errors.parent_citizenship = validParentCitizenship.error;
              }
              if (this.athleteCitizenship==1) {
                validAthleteCitizenship = validateSelect(this.verifyData.athlete_citizenship, "Child/Athlete");
                this.errors.athlete_citizenship = validAthleteCitizenship.error;
              }

              if(this.athleteCitizenship == 2 || this.parentCitizenship == 2) {
                validCheckEligibleCitizenship = validateRadio(this.form.is_eligible_citizenship.toString(),"Child/Athlete Eligible");
                this.errors.is_eligible_citizenship = validCheckEligibleCitizenship.error;

                if (this.eligibleCitizenship==1) {
                  validEligibleCitizenship = validateSelect(this.verifyData.eligible_citizenship, "Child/Athlete Eligible");
                  this.errors.eligible_citizenship = validEligibleCitizenship.error;
                }
              }


                if (validEmail.valid && validRetypeEmail.valid && validAddress1.valid && validCountry.valid
                  && validCity.valid && validZip.valid && validPhone1.valid && validCheckParentCitizenship.valid &&
                    validCheckAthleteCitizenship.valid && validCheckEligibleCitizenship.valid && validParentCitizenship.valid && validAthleteCitizenship.valid && validEligibleCitizenship.valid && validPhone2.valid) {
                  this.valid = true;
                  if(this.isChild && this.isChildUsers){
                    validFirstName = validateParentName(this.verifyData.first_name,"First Name");
                    this.errors.parent_first_name = validFirstName.error;
                    validLastName = validateParentLastName(this.verifyData.last_name,"Last Name");
                    this.errors.parent_last_name = validLastName.error;
                    if (!validFirstName.valid || !validLastName.valid) {
                      this.valid= false;
                    }
                  }
                if(this.verifyData.email != this.verifyData.retype_email) {
                  this.errors.retype_email = 'The email id does not match';
                  this.valid = false;
                }
                if((this.verifyData.country == 'USA' || this.verifyData.country == 'Canada') && this.isTeamRegistration && this.subdomain == 'alliancefastpitch') {
                  this.verifyData.state = '';
                  if(!validStateCode.valid) {
                    this.valid = false;
                  }
                } else if(this.verifyData.country == 'USA') {
                  this.verifyData.state = '';
                  if(!validStateCode.valid) {
                    this.valid = false;
                  }
                } else if(!validState.valid) {
                  this.verifyData.state_code = '';
                  this.valid = false;
                }
              }
            }else{
              if (validAddress1.valid && validCountry.valid
                  && validCity.valid && validZip.valid && validPhone1.valid && validPhone2.valid ) {
                this.valid = true;

                if(this.isTeamRegistration  && this.subdomain == 'alliancefastpitch' && (this.verifyData.country == 'USA' || this.verifyData.country == 'Canada') ) {
                  this.verifyData.state = '';
                  if(!validStateCode.valid) {
                    this.valid = false;
                  }
                } else if(this.verifyData.country == 'USA' || this.isTeamRegistration) {
                  this.verifyData.state = '';
                  if(!validStateCode.valid) {
                    this.valid = false;
                  }
                } else if(!this.isTeamRegistration && !validStateCode.valid && this.verifyData.country == 'USA') {
                  this.verifyData.state = '';
                  this.valid = false;
                } else if(!this.isTeamRegistration && !validState.valid) {
                  this.verifyData.state_code = '';
                  this.valid = false;
                }
              }
            }

            // const validPhone2 = validateName(this.verifyData.phone_2);
            // this.errors.phone_2 = validPhone2.error;
            return this.valid;
        },
        validateVerifyHeadCoachContact() {
        this.errors = {}
        this.valid = false;
        var validFirstName = "", validLastName = "";

        const validEmail = validateEmail(this.verifyData.head_contact.email);
        this.errors.email = validEmail.error;

        const validRetypeEmail = validateEmail(this.verifyData.head_contact.retype_email);
        this.errors.retype_email = validRetypeEmail.error;

        const validAddress1 = validateAddressName(this.verifyData.head_contact.address1);
        this.errors.address1 = validAddress1.error;

        // const validAddress2 = validateName(this.verifyData.address2);
        // this.errors.address2 = validAddress2.error;

        const validCountry = validateSelect(this.verifyData.head_contact.country);
        this.errors.country = validCountry.error;

        const validStateCode = validateSelect(this.verifyData.head_contact.state_code);
        const validState = validateStateName(this.verifyData.head_contact.state);

        if(this.isTeamRegistration && this.subdomain == 'alliancefastpitch' && validCountry.valid && (this.verifyData.head_contact.country != 'USA' && this.verifyData.head_contact.country != 'Canada')) {
          this.errors.state = validState.error;
        }else if(validCountry.valid && this.verifyData.head_contact.country != 'USA' && this.subdomain != 'alliancefastpitch') {
          this.errors.state = validState.error;
        }else{
          this.errors.state_code = validStateCode.error;
        }

        const validCity = validateParentName(this.verifyData.head_contact.city);
        this.errors.city = validCity.error;

        const validZip = validateZip(this.verifyData.head_contact.zip, this.verifyData.head_contact.country);
        this.errors.zip = validZip.error;

        const validPhone1 = validatePhone(this.verifyData.head_contact.phone_1, this.validTelinput);
        this.errors.phone_1 = validPhone1.error;
        console.log("validPhone1dsfsdf", validPhone1);

        const validPhone2 = validatePhone2(this.verifyData.head_contact.phone_2,this.validTelinput2);
        this.errors.phone_2 = validPhone2.error;

        if (validEmail.valid && validRetypeEmail.valid && validAddress1.valid && validCountry.valid
            && validCity.valid && validZip.valid && validPhone1.valid && validPhone2.valid) {
          this.valid = true;
          if(this.verifyData.head_contact.email != this.verifyData.head_contact.retype_email) {
            this.errors.retype_email = 'The email id does not match';
            this.valid = false;
          }
          if(this.isTeamRegistration && this.subdomain == 'alliancefastpitch' && (this.verifyData.head_contact.country == 'USA' || this.verifyData.head_contact.country == 'Canada')) {
            this.verifyData.head_contact.state = '';
            if(!validStateCode.valid) {
              this.valid = false;
            }
          }else if(this.verifyData.head_contact.country == 'USA') {
            this.verifyData.head_contact.state = '';
            if(!validStateCode.valid) {
              this.valid = false;
            }
          } else if(!validState.valid) {
            this.verifyData.head_contact.state_code = '';
            this.valid = false;
          }
        }
        return this.valid;
      },
        format_dob(value){
            if (value) {
                return moment(String(value)).format('MM/DD/YYYY')
            }
        },
        cartProducts(products) {
             this.cartProductData = products;
             console.log(products);
             console.log('--------------cart------------');
             console.log(this.cartProductData);
             this.calculateTotals();
        },
        addFilledWaiverData()
        {
            let waiverNumber = this.currentWaiver-1;
            let currentWaiverLength = this.waiverData[waiverNumber].user_info.length;
            for(let i = 0; i < currentWaiverLength; i++) {
                this.waiverForm = {};
                console.log('init waiver');
                console.log(this.waiverForm);
                this.waiverForm.waivers_id = this.waiverData[waiverNumber].waivers_id;
                this.waiverForm.initials = this.waiverData[waiverNumber].user_info[i].member_initials;
                this.waiverForm.signed_for = this.waiverData[waiverNumber].user_info[i].member_name;
                this.waiverForm.signed_user = this.waiverData[waiverNumber].user_info[i].signed_member;
                if (this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
                    this.waiverForm.club = this.clubSubmittedBy;
                }
              console.log('======================a==============');
                console.log(this.waiverForm);

                this.signedWaiverData.waivers.push(this.waiverForm)

            }
        },
        mountSteps(){
           console.log("data coach",this.membership.id);
            let type = "individual";
            if(this.subdomain.indexOf("waterski") != -1){
                if([1,2,3].indexOf(this.membership.id) != -1) type = "individual";
                else if(this.membership.id == 4) type = "support";
                else if(this.membership.id == 5) type = "family";
                else if(this.membership.id == 6) type = "club";
            } else {
                if([1,2,3].indexOf(this.membership.id) != -1) type = "individual";
                else if(this.membership.id == 4) type = "support";
                else if(this.membership.id == 5) type = "family";
                else if(this.membership.id == 6) type = "club";
                else if(this.membership.id == 9) type = "club";
                else if([11,12,13].includes(this.membership.id)) type = "fan";
            }

            if(this.subdomain.indexOf("alliancefastpitch") != -1) {

              type = "team";
              if([1,2].indexOf(this.membership.id) != -1) {
                type = "individual";
                this.isAthlete = this.membership.id;
                this.isChild = true;
              }else if([5].indexOf(this.membership.id) != -1){
                type = "coach";
                this.isCoach = this.membership.id;
                this.isChild = false;
              }else if([3,4].indexOf(this.membership.id) != -1){
                type = "team";
                this.isTeam = this.membership.id;
                this.isChild = false;
              }
            }

            this.prop_type = type;


            const retry = setInterval(() => {
                this.steps = this.getSiteProps('memberRegistration.' + type);
                if(this.steps != "" && this.steps.fields != undefined){
                    let sections = Object.keys(this.steps.fields);

                    if(sections && sections.length){
                        sections.forEach(name => {
                            this.fields[name] = this.getSiteProps('memberRegistration.' + type + '.fields.' + name);
                        });
                    }

                    clearInterval(retry);
                }
            }, 200);
        },
        mountPropMemberTypes(){
            this.prop_membertypes = this.getSiteProps("membershipTypes");
            axios.get(this.basePath + "api/get_memberships")
            .then(response => {
              let memberships = response.data.data;
              let membershipsArray = Object.keys(memberships);
              let prop_membertypes3 = Object.keys(this.prop_membertypes);
                prop_membertypes3.forEach(type1 => {
                  let prop_keys = Object.keys(this.prop_membertypes[type1].memberships);
                  prop_keys.forEach(type2 => {
                      membershipsArray.forEach(membershipId =>{
                        if(memberships[membershipId].id == this.prop_membertypes[type1].memberships[type2].id){
                          this.prop_membertypes[type1].memberships[type2].price = memberships[membershipId].price;
                          if(this.prop_membertypes[type1].memberships[type2].coach_price !== undefined){
                            this.prop_membertypes[type1].memberships[type2].coach_price = memberships[membershipId].add_on_price;
                          }
                        }
                      });
                  });
                });
                this.priceFetching = false;
            })
            .catch(error => {
                console.log("Error in getting categories: ", JSON.stringify(error));
            });
        },
        mountPropFooter(){
          this.prop_confirmation = this.getSiteProps('memberRegistration.confirmation');
          this.prop_footer = this.getSiteProps('memberRegistration.footer');
        },
        recordClubContactInfo(contact){
            this.clubInfoData.email = contact.email;
            this.clubInfoData.address = contact.club_address;
            this.clubInfoData.city = contact.club_city;
            this.clubInfoData.state_code = contact.club_state;
            this.clubInfoData.zip = contact.club_zip;
            this.clubInfoData.physical_address = contact.club_physical_address;
            this.clubInfoData.physical_city = contact.club_physical_city;
            this.clubInfoData.physical_state_code = contact.club_physical_state;
            this.clubInfoData.physical_zip = contact.club_physical_zip;
            this.clubInfoData.phone_1 = contact.mobilephone;

            this.nextStep();
        },
        getCategories(){
            axios.get(this.basePath + "api/categories")
            .then(response => {
                this.categories = response.data.data;
            })
            .catch(error => {
                console.log("Error in getting categories: ", JSON.stringify(error));
            });
        },
        verifyTriggerSelectDisciplinesAll(event){
            if(event.target.checked){
                var ids = Object.keys(this.clubDisciplinesList);
                if(ids.length){
                    this.verifyClubInfoEditData.club_disciplines = [];
                    ids.forEach(id => {
                        this.verifyClubInfoEditData.club_disciplines.push(id);
                    });
                }
            } else {
                this.verifyClubInfoEditData.club_disciplines = [];
            }
        },
        searchMember(){
          this.clubAdminSearch.searched = false;
          this.clubAdminSearch.memberIDSearched = "";
          if(this.verifyClubInfoEditData.club_admin_member_id){
              axios.get(this.basePath + "api/member_lookup?member_id="+this.verifyClubInfoEditData.club_admin_member_id).then(res => {
                  if(res.data.data != undefined && res.data.data.length){
                      this.errors.club_admin_name = "";
                      this.verifyClubInfoEditData.club_admin_name = res.data.data[0].personal_details.full_name
                  } else {
                      this.verifyClubInfoEditData.club_admin_name = "";
                  }
              }).catch(err => {
                  console.log(err);
                  this.clubAdminSearch.searched = true;
                  this.clubAdminSearch.memberIDSearched = this.verifyClubInfoEditData.club_admin_member_id;

              }).finally(() => {
                  this.clubAdminSearch.searched = true;
                  this.clubAdminSearch.memberIDSearched = this.verifyClubInfoEditData.club_admin_member_id;
              });
          }
        },
        updateClubContact(bvModalEvt){
            bvModalEvt.preventDefault();
            if(this.validateContact()){
                this.clubVerifyUpdateApi()
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('edit-club-contact')
                });
            }
        },
      validateContact(){
          this.errors = {};

        const validEmail = validateEmail(this.verifyClubInfoEditData.email,"E-Mail");
        this.errors.email = validEmail.error;

        const validRetypeEmail = validateEmail(this.verifyClubInfoEditData.retype_email, "Retype E-Mail");
        this.errors.retype_email = validRetypeEmail.error;

        const validAddress1 = validateAddressName(this.verifyClubInfoEditData.primary_contact.address1, "Club Address");
        this.errors.club_address = validAddress1.error;

        const validCity = validateName(this.verifyClubInfoEditData.primary_contact.city,"City");
        this.errors.club_city = validCity.error;

        const validState = validateSelect(this.verifyClubInfoEditData.primary_contact.state_code,"State");
        this.errors.club_state = validState.error;

        const validZip = validateZip(this.verifyClubInfoEditData.primary_contact.zip,"Zip/Postal Code");
        this.errors.club_zip = validZip.error;

        const validPhone1 = validatePhone(this.verifyClubInfoEditData.primary_contact.phone_1, this.validTelinput);
        this.errors.mobilephone = validPhone1.error;

        if(
            this.errors.email == null &&
            this.errors.retype_email == null &&
            this.errors.club_address == null &&
            this.errors.club_city == null &&
            this.errors.club_state == null &&
            this.errors.club_zip == null &&
            this.errors.mobilephone == null
        ){
            if(this.verifyClubInfoEditData.email != this.verifyClubInfoEditData.retype_email) {
                this.errors.retype_email = 'The email id does not match';
                return false;
            }
            return true;
        }

        return false;
      },
      changeSameAddress(event){
        if(this.verifyClubInfoEditData.same_as_mailing_address){
            this.verifyClubInfoEditData.primary_contact.physical_address = this.verifyClubInfoEditData.primary_contact.address1;
            this.verifyClubInfoEditData.primary_contact.physical_city = this.verifyClubInfoEditData.primary_contact.city;
            this.verifyClubInfoEditData.primary_contact.physical_state_code = this.verifyClubInfoEditData.primary_contact.state_code;
            this.verifyClubInfoEditData.primary_contact.physical_zip = this.verifyClubInfoEditData.primary_contact.zip;
        }
      },
      validateTelinput(obj){
          this.validTelinput = obj.valid;
          console.log(obj);
          if(obj.valid){
              this.errors.mobilephone = '';
          }
      },
      isLetter(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex
        else e.preventDefault(); // If not match, don't add to input text
      },
      saveMember(bvModalEvt) {
        bvModalEvt.preventDefault();
        //this.checkTeamDuplicate(true);
        if (!this.validateFamily()) {
          return;
        }
        // alert(this.form.relation != this.members[this.form.index].relation);
        if(
            (this.action == 1 && this.familyForm.relation == 1) ||
            (this.action == 2 && this.familyForm.relation == 1 && this.familyForm.relation != this.verifyData.families[this.familyForm.index].relation)
        ) {
          this.spouseAdded.valid = true;
        }

        if(this.action == 2 && this.familyForm.relation != 1 && this.verifyData.families[this.familyForm.index].relation == 1){
          this.spouseAdded.valid = false;
        }
        if(this.isTeamRegistration){
          this.familyForm.personal_details.member_id = this.familyForm.member_id;
          this.familyForm.personal_details.relation = this.familyForm.relation;
          this.familyForm.personal_details.first_name = this.familyForm.first_name;
          this.familyForm.personal_details.first_name = this.familyForm.first_name;
          this.familyForm.personal_details.middle_name = this.familyForm.middle_name;
          this.familyForm.personal_details.last_name = this.familyForm.last_name;
          this.familyForm.contact_details.email = this.familyForm.email;
          this.familyForm.personal_details.gender = this.familyForm.gender;
          this.familyForm.personal_details.dob = this.familyForm.dob;
          this.familyForm.personal_details.relation = this.familyForm.relation;
          this.familyForm.personal_details.citizenship = this.familyForm.citizenship;
          this.familyForm.personal_details.clubandteam = this.familyForm.clubandteam;
          this.familyForm.personal_details.discipline_1 = this.familyForm.discipline_1;
          this.familyForm.personal_details.discipline_2 = this.familyForm.discipline_2;
          this.familyForm.contact_details.phone_1 = this.familyForm.phone_1;
          this.familyForm.contact_details.phone_2 = this.familyForm.phone_2;
          this.familyForm.personal_details.displines_names = this.familyForm.displines_names;
          this.familyForm.personal_details.secondChoiceNames = this.familyForm.secondChoiceNames;
          this.familyForm.personal_details.home_federation = this.familyForm.home_federation;
          this.familyForm.personal_details.skier_license = this.familyForm.skier_license;
          this.familyForm.personal_details.diversity_id = this.familyForm.diversity_id;
          this.familyForm.personal_details.diversity_txt = this.familyForm.diversity_txt;
          this.familyForm.personal_details.ethnicity = this.familyForm.ethnicity;
          this.familyForm.personal_details.disability = this.familyForm.disability;
          this.familyForm.personal_details.veteran_status = this.familyForm.veteran_status;
          this.familyForm.personal_details.league_age = this.familyForm.league_age;
          this.familyForm.personal_details.agl_player_id = this.familyForm.agl_player_id;
          this.familyForm.personal_details.jersey_number = this.familyForm.jersey_number;
          this.familyForm.personal_details.primary_position = this.familyForm.primary_position;
          this.familyForm.personal_details.secondary_position = this.familyForm.secondary_position;
          this.familyForm.personal_details.graduation_year = this.familyForm.graduation_year;
          this.familyForm.personal_details.bats = this.familyForm.bats;
          this.familyForm.personal_details.throws = this.familyForm.throws;
          this.familyForm.contact_details.retype_email = this.familyForm.retype_email;
          this.familyForm.contact_details.address1 = this.familyForm.address1;
          this.familyForm.contact_details.address2 = this.familyForm.address2;
          this.familyForm.contact_details.city = this.familyForm.city;
          this.familyForm.contact_details.country = this.familyForm.country;
          this.familyForm.contact_details.state_code = this.familyForm.state_code;
          this.familyForm.contact_details.state = this.familyForm.state;
          this.familyForm.contact_details.zip = this.familyForm.zip;
          this.familyForm.personal_details.college_list = this.familyForm.college_list;
          this.familyForm.personal_details.college_committed = this.familyForm.college_committed;
        }
        if(this.isTeamRegistration){
          this.verifyData.form_type = "family";
        }
        this.verifyData.families[this.familyForm.index] = this.familyForm;
        console.log("families",this.verifyData.families[this.familyForm.index]);
        this.showLoader=true;
        this.handleSubmit();
        this.UpdateVerifiedDetails('families');
        this.errors = {};
        this.$nextTick(() => {
          this.$bvModal.hide('modal-center3')
        });

      },
      editFamily(i) {
        this.errors = {};
        this.familyForm.index = i;
        var props = Object.keys(this.verifyData.families[i]);
        console.log(props);
        if(props.length) {
          props.map(prop => this.familyForm[prop] = this.verifyData.families[i][prop]);
          this.familyForm.reg_member_id = this.familyForm.id;
          if(!this.isTeamRegistration) {
            this.familyForm.discipline_1 = this.familyForm.first_choice;
            this.familyForm.discipline_2 = this.familyForm.second_choice;
            this.familyForm.email = this.familyForm.contact_details.email;
            this.familyForm.phone_1 = this.familyForm.contact_details.phone_1;
            this.familyForm.phone_2 = this.familyForm.contact_details.phone_2;
            this.familyForm.address1 = this.familyForm.contact_details.address1;
            this.familyForm.address2 = this.familyForm.contact_details.address2;
            this.familyForm.state_code = this.familyForm.contact_details.state_code;
            this.familyForm.state = this.familyForm.contact_details.state;
            this.familyForm.city = this.familyForm.contact_details.city;
            this.familyForm.zip = this.familyForm.contact_details.zip;
            this.familyForm.country = this.familyForm.contact_details.country;
          }
          if(this.familyForm.personal_details != undefined) {
            this.familyForm.member_id = this.familyForm.personal_details.member_id;
            this.familyForm.college_committed = this.familyForm.personal_details.college_committed;
            this.familyForm.relation = this.familyForm.personal_details.relation;
            this.familyForm.first_name = this.familyForm.personal_details.first_name;
            this.familyForm.middle_name = this.familyForm.personal_details.middle_name;
            this.familyForm.last_name = this.familyForm.personal_details.last_name;
            this.familyForm.dob = this.familyForm.personal_details.dob;
            this.familyForm.agl_player_id = this.familyForm.personal_details.agl_player_id;
            this.familyForm.jersey_number = this.familyForm.personal_details.jersey_number;
            this.familyForm.primary_position = this.familyForm.personal_details.primary_position;
            this.familyForm.secondary_position = this.familyForm.personal_details.secondary_position;
            this.familyForm.graduation_year = this.familyForm.personal_details.graduation_year;
            this.familyForm.bats = this.familyForm.personal_details.bats;
            this.familyForm.throws = this.familyForm.personal_details.throws;
            this.familyForm.email = this.familyForm.contact_details.email;
            this.familyForm.address1 = this.familyForm.contact_details.address1;
            this.familyForm.address2 = this.familyForm.contact_details.address2;
            this.familyForm.city = this.familyForm.contact_details.city;
            this.familyForm.country = this.familyForm.contact_details.country;
            this.familyForm.state_code = this.familyForm.contact_details.state_code;
            this.familyForm.state = this.familyForm.contact_details.state;
            this.familyForm.zip = this.familyForm.contact_details.zip;
            this.familyForm.retype_email = this.familyForm.contact_details.email;
            this.familyForm.phone_1 = this.familyForm.contact_details.phone_1;
            this.familyForm.phone_2 = this.familyForm.contact_details.phone_2;
            this.familyForm.college_list = this.familyForm.personal_details.college_details != undefined ? 1:'';
          }
        }
        console.log(this.familyForm);
        this.$bvModal.show('modal-center3')
        this.action = 2;
        this.collectDisciplines();
        this.collectSecondChoice();
      },
      collectDisciplines(){
        this.familyForm.displines_names = [];
        if(Array.isArray(this.familyForm.discipline_1) && this.familyForm.discipline_1.length){
          this.familyForm.discipline_1.forEach(id => {
            if(this.disciplines[id] != undefined) this.familyForm.displines_names.push(this.disciplines[id]);
          });
        } else {
          if(this.familyForm.discipline_1 != ''){
            if(this.disciplines[this.familyForm.discipline_1] != undefined)
              this.familyForm.displines_names.push(this.disciplines[this.familyForm.discipline_1]);
          }
        }
      },
      collectSecondChoice(){
        this.familyForm.secondChoiceNames = [];
        if(this.familyForm.discipline_2 != ''){
          if(this.disciplines[this.familyForm.discipline_2] != undefined)
            this.familyForm.secondChoiceNames.push(this.disciplines[this.familyForm.discipline_2]);
        }
      },
// Custom Validation Rules
      validateFamily() {
        this.errors = {}
        this.valid = false;
        console.log('-------------');
        console.log(this.familyForm);

        var validTitle = {valid:true}, validGender = "",validDiscipline1 = "",validRelation = "",validPhone1 = "",validPhone2 = "",validFederation = "",validSafeSportForeignFederationId = "",validDiversity = "",validFamilyAddress1 = "",validFamilyCountry = "",validFamilyStateCode = "",validFamilyState = "",validFamilyCity = "",validFamilyZip ="", validPrimaryPosition = "", validThrow = "", validBats = "", validJerseyNumber = "", validGraduationYear = "", validMiddleName ={valid:true}, validDob="{valid:true}";

        const validFirstName = validateName(this.familyForm.first_name);
        this.errors.first_name = validFirstName.error;

        validMiddleName = validateOptionalTextFields(this.familyForm.middle_name, "Middle name");
        this.errors.middle_name = validMiddleName.error;

        const validLastName = validateLastName(this.familyForm.last_name);
        this.errors.last_name = validLastName.error;

        const validEmail = validateEmail(this.familyForm.email);
        this.errors.email = validEmail.error;

        validFamilyAddress1 = validateAddressName(this.familyForm.address1,"Address");
        this.errors.address1 = validFamilyAddress1.error;



        validFamilyStateCode = validateSelect(this.familyForm.state_code,"State");
        validFamilyState = validateName(this.familyForm.state);
        if(validFamilyCountry.valid && this.familyForm.country != 'USA') {
          this.errors.state = validFamilyState.error;
        } else {
          this.errors.state_code = validFamilyStateCode.error;
        }
        validFamilyCity = validateName(this.familyForm.city,"City");
        this.errors.city = validFamilyCity.error;

        validFamilyZip = validateZip(this.familyForm.zip,this.familyForm.country);
        this.errors.zip = validFamilyZip.error;

        if(!this.isTeamRegistration){

          validDob = validateDob(this.familyForm.dob);
          this.errors.dob = validDob.error;

          validGender = validateRadio(this.familyForm.gender);
          this.errors.gender = validGender.error;

          validDiscipline1 = validateSelect(this.familyForm.discipline_1);
          this.errors.discipline_1 = validDiscipline1.error;

          validRelation = validateRadio(this.familyForm.relation);
          // console.log("test"+this.familyForm.relation);
          this.errors.relation = validRelation.error;
          // console.log("what"+this.errors.relation);

          validFederation = validateSelect(this.familyForm.home_federation,"Federation");
          this.errors.home_federation = validFederation.error;

          validSafeSportForeignFederationId = validateFedration(this.familyForm.skier_license);
          this.errors.skier_license = validSafeSportForeignFederationId.error;

          validDiversity = validateRadio(this.familyForm.diversity_id.toString());
          this.errors.diversity_id = validDiversity.error;

          validFamilyAddress1 = validateAddressName(this.familyForm.address1,"Address");
          this.errors.address1 = validFamilyAddress1.error;

          //const validAddress2 = validateName(this.familyForm.address2);
          // this.errors.address2 = validAddress2.error;

          validFamilyCountry = validateSelect(this.familyForm.country,"Country");
          this.errors.country = validFamilyCountry.error;

          validFamilyStateCode = validateSelect(this.familyForm.state_code,"State");
          validFamilyState = validateName(this.familyForm.state);
          if(validFamilyCountry.valid && this.familyForm.country != 'USA') {
            this.errors.state = validFamilyState.error;
          } else {
            this.errors.state_code = validFamilyStateCode.error;
          }
          validFamilyCity = validateName(this.familyForm.city,"City");
          this.errors.city = validFamilyCity.error;

          // validZip = validateName(this.familyForm.zip,"Postal Code");
          // this.errors.zip = validZip.error;


          validFamilyZip = validateZip(this.familyForm.zip,this.familyForm.country);
          this.errors.zip = validFamilyZip.error;
        }

        validPhone1 = validatePhone(this.familyForm.phone_1, this.validTelinput);
        this.errors.phone_1 = validPhone1.error;

        validPhone2 = validatePhone2(this.familyForm.phone_2, this.validTelinput2);
        this.errors.phone_2 = validPhone2.error;

        if(this.isTeamRegistration) {

          let playerDob = this.getAge(this.familyForm.dob);
          validDob = validatePlayerDob(this.familyForm.dob,"Dob", this.player_informations,this.isTeamOptions,this.verifyData.age_division,playerDob,this.verifyData.membership_id);
          this.errors.dob = validDob.error;

          validPrimaryPosition = validateSelect(this.familyForm.primary_position, "Primary");
          this.errors.country = validPrimaryPosition.error;

          validThrow = validateRadio(this.familyForm.throws.toString());
          this.errors.diversity_id = validThrow.error;

          validBats = validateRadio(this.familyForm.bats.toString());
          this.errors.diversity_id = validBats.error;


          validJerseyNumber = validateJerseyNumber(this.familyForm.jersey_number);
          this.errors.jersey_number = validJerseyNumber.error;

          validGraduationYear = validateGraduationYear(this.familyForm.graduation_year);
          this.errors.graduation_year = validGraduationYear.error;

          validFamilyCountry = validateSelect(this.familyForm.country,"Country");
          this.errors.country = validFamilyCountry.error;
        }

        if(!this.isTeamRegistration && this.action == 2 && this.familyForm.relation == 1 && this.verifyData.families[this.familyForm.index].relation != this.familyForm.relation == 1){
          let spouseFound = this.verifyData.families.filter(item => item.relation == 1);
          if(spouseFound.length){
            this.errors.relation = 'You can add only one spouse';
            this.spouseAdded.current = false;
            validRelation.valid = false;
          }
        } else {
          this.spouseAdded.current = true;
        }

        if(!this.isTeamRegistration && this.familyForm.relation == 0){
          this.errors.relation = 'This field is required.';
          validRelation.valid = false;
        }

        let dob = this.getAge(this.familyForm.dob);
        // console.log('dob='+dob);
        var range = this.fieldsAd.popup.birthDay.min_range || 18;
        var childRange = this.fieldsAd.popup.birthDay.child_age || 18;
        if(!this.isTeamRegistration && this.familyForm.relation == 1 && dob < range) {
          // console.log('dob1='+dob);
          this.errors.dob = 'Spouse age should be '+ range + ' and above';
          validDob.valid = false;

        } else if(this.familyForm.relation == 2 && dob >= childRange) {
          // console.log('dob1='+dob);
          this.errors.dob = 'Child age should be less than ' + childRange;
          validDob.valid = false;
        }

        if(!this.validateDate(this.familyForm.dob)){
          this.errors.dob = 'Invalid Date';
          validDob.valid = false;
        }
        if (this.familyForm.home_federation != 'USA' && !validSafeSportForeignFederationId.valid) {
          this.errors.skier_license = 'Federation ID is required';
        } else if (this.familyForm.home_federation == 'USA') {
          this.familyForm.skier_license = '';
          //   this.valid = true;
        } else {
          //   this.valid = true;
        }

        if (validTitle.valid &&  validFirstName.valid && validLastName.valid && validEmail.valid && validDob.valid && validGender.valid
            && validDiscipline1.valid && validRelation.valid
            && validPhone1.valid && validPhone2.valid && this.spouseAdded.current && validFederation.valid && (this.familyForm.home_federation != 'USA'?validSafeSportForeignFederationId.valid:true) && validDiversity.valid && validFamilyAddress1.valid  && validFamilyCountry.valid && ((this.familyForm.country != 'USA'&&validFamilyState.valid)||(this.familyForm.country == 'USA'&&validFamilyStateCode.valid)) && validFamilyCity.valid && validFamilyZip.valid && validMiddleName.valid ||
            (validFirstName.valid && validLastName.valid && validEmail.valid && validDob.valid && validPrimaryPosition.valid
                && validThrow.valid && validBats.valid
                && validJerseyNumber.valid && validGraduationYear.valid && validPhone1.valid && validMiddleName.valid && validPhone2.valid && validFamilyAddress1.valid  && validFamilyCountry.valid &&(validFamilyStateCode.valid || validFamilyState.valid)&& validFamilyCity.valid && validFamilyZip.valid)) {
          this.valid = true;
        }
        // if(Object.keys(this.errors).length == 0) this.valid = false;
        // else this.valid = true;

        // var errorOnly = Object.keys(this.errors).filter(k => this.errors[k] != null);
        // console.log(errorOnly);
        return this.valid;
      },
      stripeTokenCreated(res){
        this.stripeError = "";
        this.stripeToken = res.id;
        this.paymentForm.stripeToken = res.id;
        this.paymentApi();
        // this.registerationStep++;
        // document.getElementById('app').scrollIntoView();
      },
      captureStripeErrors(errors){
          this.stripeError = errors;
          console.log("===========>",errors);
          this.showLoader=false;
      },
      stripeFormChanges(obj){
          this.stripeValidate = false;
          if(obj.complete){
              this.stripeError = "";
              this.stripeValidate = true;
          }
      },
      checkDuplicate()
      {
        let checkData = this.form;
        if(this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1 && !this.isTeamRegistration) {
          checkData = this.clubInfoData;
        }
        this.checkingDuplicate = true;
        axios.post(this.basePath + "api/checkDuplicate/member", checkData)
            .then((response) => {
              let count = response.data.data.count;
              if(count > 0){
                this.duplicateError.hasError = true;
                if(this.membership.member_type_id == 4) {
                  this.duplicateError.errorMessage = 'Your account is already in the system, please login and renew using the link given below. ';
                  this.duplicateError.showLink = true;
                }else if(response.data.data.expiredFamilymemberFamilyRegistration) {
                  this.duplicateError.errorMessage = 'You are already a member of a family, so please login and renew using the link provided.';
                  this.duplicateError.showLink = true;
                }
                else{
                  this.duplicateError.errorMessage = 'Your account is already in the system, please login and renew using the link given below.';
                  this.duplicateError.showLink = true;
                }

              }else{
                if(this.membership.member_type_id == 4) {
                  this.step9=false;
                  this.step10=true;
                }else{
                  this.step2=false;
                  this.step3=true;
                }
                this.duplicateError.hasError = false;
                this.duplicateError.showLink = false;
                this.duplicateError.errorMessage = '';
                this.currentStep++;
                this.step++;
              }
              this.checkingDuplicate = false;
              document.getElementById('app').scrollIntoView();
            })
            .catch(error=>{
              console.log("error geting clubs list")
              this.checkingDuplicate = false;
            })
      },
      checkDuplicateForEdit()
      {
        let checkData = {'membership_id':this.verifyData.membership_id, 'members':[{'member_id':this.verifyData.members.id,'first_name':this.verifyData.members.first_name,'last_name':this.verifyData.members.last_name,'dob':this.verifyData.members.dob,}],'name':this.verifyData.name,'isVerified':false};
        // this.checkingDuplicate = true;
        axios.post(this.basePath + "api/checkDuplicate/member", checkData)
            .then((response) => {
              let count = response.data.data.count;
              this.dupResult = true;
              if(count >= 1){
                this.dupResult =  false;
              }
              this.handlePersonalEditOkAfterCheckDuplication();
            })
            .catch(error=>{
              console.log(error);
            })
      },
      removeStateCode(){
        this.paymentForm.billing_state = '';
        this.paymentForm.billing_state_code = '';
      },
      removeVerifyStateCode(){
        this.verifyData.state_code = '';
        this.verifyData.state = '';
      },
      removeVerifyCoachStateCode(){
        this.verifyData.head_contact.state_code = '';
        this.verifyData.head_contact.state = '';
      },
      removeFamilyStateCode(){
        this.familyForm.state_code = '';
        this.familyForm.state = '';
      },
      checkTeamDuplicate(isCheckEdit = false)
      {
        let checkData = this.form.members[0];
        if(isCheckEdit){
          checkData = this.familyForm;
        }

        this.checkingDuplicate = true;
        axios.post(this.basePath + "api/checkDuplicate/Teammember", checkData)
            .then((response) => {
              let count = response.data.data.count;
              if(count > 0){
                this.duplicateError.hasError = true;
                  this.duplicateError.errorMessage = 'Looks like this person is already registered for current year. For more information please contact member service.';

              }else{
                if(this.membership.member_type_id == 4 && !isCheckEdit) {
                  this.step2=false;
                  this.step3=true;
                }
                this.duplicateError.hasError = false;
                this.duplicateError.errorMessage = '';
                this.currentStep++;
                this.step++;
              }
              this.checkingDuplicate = false;
              document.getElementById('app').scrollIntoView();
            })
            .catch(error=>{
              console.log("error geting clubs list")
              this.checkingDuplicate = false;
            })
      },
      checkCoachDuplicate()
      {
        let checkData = this.form.members[0];
        console.log("duplicateCheck",checkData);


        this.checkingDuplicate = true;
        axios.post(this.basePath + "api/checkDuplicate/coach", checkData)
            .then((response) => {
              let team_count = response.data.data.existing_team;
              let coach_count = response.data.data.existing_coach;
              if(team_count > 0 && coach_count > 0){
                this.duplicateError.hasError = true;
                this.duplicateError.errorMessage = 'Looks like this person and team is already registered for current year. For more information please contact member service.';

              }else{
                if(this.membership.member_type_id == 4) {
                  this.step5=false;
                  this.step6=true;
                }
                this.team_count = team_count;
                this.coach_count = coach_count;
                this.duplicateError.hasError = false;
                this.duplicateError.errorMessage = '';
                if(!this.coachMembership){
                  this.currentStep++;
                  this.step++;
                }

              }
              this.checkingDuplicate = false;
              document.getElementById('app').scrollIntoView();
            })
            .catch(error=>{
              console.log("error geting clubs list")
              this.checkingDuplicate = false;
            })
      },
      isCheck(e){
        this.$nextTick(() => {
          if(e.target.checked){
            this.verifyData.addons.insurance_amt = this.fields.addons.team_insurance.amount;
          }
        })
      },
      CheckDonation(val){
           console.log("donation one",this.verifyData.donation_amount);
           console.log("donation previous",this.previouslySelected);
        if (val == this.verifyData.donation_amount) {
          this.verifyData.donation_amount = false
        } else {
          this.verifyData.donation_amount = val
        }

        if(this.verifyData.donation_amount){
          this.verifyData.added_donation_amount = '';
          this.donationInputSelected = val;
        }else{
          this.verifyData.added_donation_amount = this.form.added_donation_amount;
        }
        console.log("donation",this.verifyData.donation_amount);
      },
      getTeamsList(){
        axios.get(this.basePath + "api/get_teams_division")
            .then((response) => {
              let teams = response.data.data;
              let teamKey = Object.keys(teams);
              if(teamKey.length){
                this.teamList = Object.keys(teams).map((field) => {
                  return {
                    age: field.slice(0, -1),
                    value: teams[field]
                  }
                })
              }
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })
      },
      getDivisionTeamList(event){
         if(event.target != undefined){
           this.verifyData.age_division_name = event.target.options[event.target.options.selectedIndex].text;
           let age = event.target.options[event.target.options.selectedIndex].text.slice(0, -1);
           let teamOptions=[];
           let teamOptionsList=[];
           this.teamList.forEach((ageDivisionList, index) => {
             if(ageDivisionList.age != '' && ageDivisionList.age <= age){
               teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
                 if(ageDivisionList.value[field].text != null) {
                   return {
                     id: ageDivisionList.value[field].id,
                     value: ageDivisionList.value[field].text
                   }
                 }
               });
               teamOptionsList.push(teamOptions);
             }
           });
           this.teamOptions = teamOptionsList.flat();
           this.teamOptions = this.teamOptions.filter(function( element ) {
             return element !== undefined;
           });
         }else{
           let age = event;
           let teamOptions=[];
           let teamOptionsList=[];
           this.teamList.forEach((ageDivisionList, index) => {
             if(ageDivisionList.age != '' && ageDivisionList.age <= age){
               teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
                 if(ageDivisionList.value[field].text != null) {
                   return {
                     id: ageDivisionList.value[field].id,
                     value: ageDivisionList.value[field].text
                   }
                 }
               });
               teamOptionsList.push(teamOptions);
             }
           });
           this.teamOptions = teamOptionsList.flat();
           this.teamOptions = this.teamOptions.filter(function( element ) {
             return element !== undefined;
           });
         }
      },
      getDateValue(){
        var isCheckAge = true;
        if(this.isChild && this.isTeamRegistration && this.verifyData.members.dob != ''){
          let age = this.isUnderAgeDivision(this.verifyData.members.dob);
          if(this.membership.age_criteria){
            if(this.membership.age_criteria.compare=="above" && age < this.membership.age_criteria.value) {
              this.valid = false;
              this.errors.dob = this.membership.age_criteria.error_msg;
            } else if(this.membership.age_criteria.compare=="below" && age > this.membership.age_criteria.value) {
              this.valid = false;
              this.errors.dob = this.membership.age_criteria.error_msg;
              isCheckAge =false;
            } else {
              this.errors.dob = '';
              this.valid = true;
              this.overAllError = 0;
            }
          } else {
            this.valid = true;
            this.overAllError = 0;
          }
          let teamOptions=[];
          let teamOptionsList=[];
          if(isCheckAge) {
            this.teamList.forEach((ageDivisionList, index) => {
              if (this.isAthlete == 2 && ageDivisionList.age != '' && (ageDivisionList.age >= age && ageDivisionList.age <= 12)) {
                teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
                  if (ageDivisionList.value[field].text != null) {
                    return {
                      id: ageDivisionList.value[field].id,
                      text: ageDivisionList.value[field].text
                    }
                  }
                });
                teamOptionsList.push(teamOptions);
              }
              if (this.isAthlete == 1 && ageDivisionList.age != '' && (ageDivisionList.age >= age && ageDivisionList.age > 12)) {
                teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
                  if (ageDivisionList.value[field].text != null) {
                    return {
                      id: ageDivisionList.value[field].id,
                      text: ageDivisionList.value[field].text
                    }
                  }
                });
                teamOptionsList.push(teamOptions);
              }
            });
            this.teamOptions = teamOptionsList.flat();
            this.teamOptions = this.teamOptions.filter(function (element) {
              return element !== undefined;
            });
          }
        }
      },
      isUnderAgeDivision(birth_date,valid_age){
        var today = new Date(this.currentSeason.start_date);
        var birthDate = new Date(birth_date);
        var age = today.getFullYear() - birthDate.getFullYear();
        return age;
      },
      stateCode(){
        let state_code = this.verifyData.state_code;
        this.memberLeagueSelect2.forEach((leagueList, index) => {
          if(leagueList.value.includes(state_code)){
            this.verifyData.member_league_name=leagueList.label;
            this.verifyData.member_league=leagueList.id;
            return;
          }
        });
      },
      teamChange(){
        let team_choose = this.form.id.toString();
        if(team_choose.length){
          this.form.name='';
        }
      },
      isNewTeam(){
        let team = this.form.name;
        if(team.toString().length){
          this.$delete(this.form, 'id')
        }
      },
      resetFields() {
        this.form = {
          "membership_id": '',
          "email": '',
          "retype_email": '',
          "address1": '',
          "address": '',
          "address2": '',
          "zip": '',
          "state_code": '',
          "state": '',
          "city": '',
          "country": '',
          "phone_1": '',
          "phone_2": '',
          "diversity_id": '',
          "members": [{
            first_name: '',
            last_name: '',
            membership_id: '',
            contact_type: ''
          },],
          'donation_amount': 0,
          'donation_id': '',
          'disability': '',
          'veteran_status': '',
          "team_addons": [],
          "first_name": '',
          "middle_name": '',
          "last_name": '',
          "age_division": 0,
          "added_donation_amount": null,
          "invite_players": []
        }
      },
      UpdateVerifiedDetails(updatedPage, isHeadCoach){
        switch(updatedPage) {
          case 'personal':
            this.updatedValues.personal={
              first_name: this.verifyData.members.first_name,
              last_name: this.verifyData.members.last_name,
              middle_name: this.verifyData.members.middle_name,
              dob: this.verifyData.members.dob,
              suffix: this.verifyData.members.suffix,
              gender: this.verifyData.members.gender,
              relation:0,
              citizenship:'',
              affiliate:'',
              veteran_status: '',
              club: this.verifyData.club,
              new_club_name: '',
              new_club_email: '',
              new_club_phone: '',
              club_affiliate: '',
              agl_player_id: this.verifyData.members.agl_player_id,
              jersey_number: this.verifyData.members.jersey_number,
              primary_position: this.verifyData.members.primary_position,
              secondary_position: this.verifyData.members.secondary_position,
              graduation_year: this.verifyData.members.graduation_year,
              bats: this.verifyData.members.bats,
              throws: this.verifyData.members.throws,
              college_committed:this.verifyData.members.college_committed,
              college_list:this.verifyData.members.college_committed?1:'',
              collegeCommitted:this.verifyData.members.college_committed?1:2
            }
            this.personal_render++
            break;
          case 'addons':
            var agl_package = '',usssa_package = '',team_insurance = '';
            if(this.isTeamRegistration && !this.isChild && this.membership.id !=5){
              this.verifyData.addons.filter(addons => {
                if(addons.addon_id == 1){
                  agl_package = addons.amount;
                }
                if(addons.addon_id == 2){
                  usssa_package = addons.amount;
                }
                if(addons.addon_id == 3){
                  team_insurance = addons.amount;
                }
              });
              console.log("addons Render", agl_package,usssa_package,team_insurance);
            }
            console.log("addons",this.verifyData.added_donation_amount == '');
            console.log("addons vew",this.donationInputSelected);
            this.updatedValues.addons={
              donation_amount: (this.verifyData.added_donation_amount == null || this.verifyData.added_donation_amount === '')?parseInt(this.verifyData.donation_amount):this.donationInputSelected,
              added_donation_amount:this.verifyData.added_donation_amount,
              donationInputSelected:this.donationInputSelected,
              agl_package:agl_package,
              usssa_package:usssa_package,
              team_insurance:team_insurance,
            }
            this.addons_render++
            break;
          case 'contact':
            if(this.isChild && !this.isHeadCoachContact) {
              this.updatedValues.contact = {
                email: this.verifyData.email,
                retype_email: this.verifyData.retype_email,
                printed_magazine: this.verifyData.printed_magazine ?? '',
                address_type: this.verifyData.address_type,
                address1: this.verifyData.address1,
                address: this.verifyData.address1,
                address2: this.verifyData.address2,
                zip: this.verifyData.zip,
                state_code: this.verifyData.state_code,
                state: this.verifyData.state,
                city: this.verifyData.city,
                country: this.verifyData.country,
                phone_1: this.verifyData.phone_1,
                phone_2: this.verifyData.phone_2,
                company_name: this.verifyData.company_name,
                company_phone: this.verifyData.company_phone,
                company_phone_extension: this.verifyData.company_phone_extension,
                first_name: this.verifyData.first_name,
                last_name: this.verifyData.last_name,
                middle_name: this.verifyData.middle_name,
                citizenship: this.verifyData.citizenship

              }
              this.contact_render++
            }
            if(this.isTeamRegistration && this.isHeadCoachContact) {
              this.updatedValues.contact = {
                email: this.verifyData.head_contact.email,
                retype_email: this.verifyData.head_contact.retype_email,
                printed_magazine: this.verifyData.head_contact.printed_magazine ?? '',
                address_type: this.verifyData.head_contact.address_type,
                address1: this.verifyData.head_contact.address1,
                address: this.verifyData.head_contact.address1,
                address2: this.verifyData.head_contact.address2,
                zip: this.verifyData.head_contact.zip,
                state_code: this.verifyData.head_contact.state_code,
                city: this.verifyData.head_contact.city,
                country: this.verifyData.head_contact.country,
                phone_1: this.verifyData.head_contact.phone_1,
                phone_2: this.verifyData.head_contact.phone_2,
                company_name: this.verifyData.head_contact.company_name,
                company_phone: this.verifyData.head_contact.company_phone,
                company_phone_extension: this.verifyData.head_contact.company_phone_extension,
                first_name: this.verifyData.head_contact.first_name,
                last_name: this.verifyData.head_contact.last_name,
                middle_name: this.verifyData.head_contact.middle_name,
                citizenship: this.verifyData.head_contact.citizenship


              }
              this.contact_render++
            }
            if(this.verifyData.age_division != null && this.isTeamRegistration && !this.isChild) {
              var ask_address = '';
              if(this.verifyOldData.address1 != this.verifyData.address1 || this.verifyOldData.address2 != this.verifyData.address2
              || this.verifyOldData.zip != this.verifyData.zip || this.verifyOldData.state_code != this.verifyData.state_code
              || this.verifyOldData.city != this.verifyData.city || this.verifyOldData.country != this.verifyData.country){
                ask_address = "1";
              }
              console.log("data team");
              this.updatedValues.team = {
                address_type: this.verifyData.address_type,
                address1: this.verifyData.address1,
                address: this.verifyData.address1,
                address2: this.verifyData.address2,
                zip: this.verifyData.zip,
                state_code: this.verifyData.state_code,
                city: this.verifyData.city,
                country: this.verifyData.country,
                phone_1: this.verifyData.phone_1,
                phone_2: this.verifyData.phone_2,
                name: (this.form.name != '')?this.verifyData.name:'',
                member_league: this.verifyData.member_league,
                member_league_name: this.verifyData.member_league_name,
                age_division: this.verifyData.age_division.toString(),
                agl_team_id: this.verifyData.agl_team_id,
                ask_address: ask_address,
                age_division_name: this.verifyData.age_division_name,
                team_id:(this.form.name == undefined || this.form.name == '')? this.verifyData.id:'',

              }
              this.team_render++

            }
            break;
          case 'families':
            if(this.isTeamRegistration) {
              this.form.members[this.familyForm.index] = {
                first_name: this.familyForm.first_name,
                middle_name: this.familyForm.middle_name,
                last_name: this.familyForm.last_name ?? '',
                email: this.familyForm.email,
                gender: this.familyForm.gender,
                dob: this.familyForm.dob,
                relation: this.familyForm.relation,
                citizenship: this.familyForm.citizenship,
                clubandteam: this.familyForm.clubandteam,
                discipline_1: this.familyForm.discipline_1,
                discipline_2: this.familyForm.discipline_2,
                index: this.familyForm.index,
                phone_1: this.familyForm.phone_1,
                phone_2: this.familyForm.phone_2,
                displines_names: this.familyForm.displines_names,
                secondChoiceNames: this.familyForm.secondChoiceNames,
                home_federation: this.familyForm.home_federation,
                skier_license: this.familyForm.skier_license,
                diversity_id: this.familyForm.diversity_id,
                diversity_txt: this.familyForm.diversity_txt,
                ethnicity: this.familyForm.ethnicity,
                disability: this.familyForm.disability,
                veteran_status: this.familyForm.veteran_status,
                address1: this.familyForm.address1,
                address2: this.familyForm.address2,
                zip: this.familyForm.zip,
                state_code: this.familyForm.state_code,
                state: this.familyForm.state,
                city: this.familyForm.city,
                country: this.familyForm.country,
                league_age: this.familyForm.league_age,
                agl_player_id: this.familyForm.agl_player_id,
                jersey_number: this.familyForm.jersey_number,
                primary_position: this.familyForm.primary_position,
                secondary_position: this.familyForm.secondary_position,
                graduation_year: this.familyForm.graduation_year,
                bats: this.familyForm.bats,
                throws: this.familyForm.throws,
                retype_email: this.familyForm.retype_email,
                college_list: this.familyForm.college_list,
                invite: this.familyForm.invite,
                college_committed: this.familyForm.college_committed,
                college_name: this.familyForm.college_name

              }
              this.family_render++
            }
            break;
          default:
            // code block
        }
      },
      getTeamDetails(id){
        axios.get(this.basePath + "api/get_teams_league/"+id)
            .then((response) => {
              let teams = response.data.data;
              this.verifyData.member_league_name=teams.name;
              this.verifyData.member_league=teams.id;
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })
      },
      getDivisions(id)
      {
        axios.get(this.basePath+"api/getDivisions/"+id)
            .then(response => {
              this.divisions_lists = response.data.data;
              let discipline_codes = Object.keys(this.divisions_lists);
              if(discipline_codes.length && this.divisions_list.length == 0){
                discipline_codes.forEach(code => {
                  this.divisions_list.push({ id: code, text: this.divisions_lists[code] == "NA - N/A" ? "N/A" : this.divisions_lists[code] });
                });
              }
              console.log("division",this.divisions_list);
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      checkTeamExist()
      {
        let checkData = this.form;
        console.log("duplicateCheck",checkData);

        if(this.form.team_check) {
          this.checkingDuplicate = true;
          axios.post(this.basePath + "api/checkDuplicate/team", checkData)
              .then((response) => {
                let count = response.data.data.count;
                if (count > 0) {
                  this.duplicateErrorMessage.hasError = true;
                  this.duplicateErrorMessage.errorMessage = 'Looks like this team is already registered for current year. For more information please contact member service.';
                  this.teamExist = false;
                } else {
                  this.duplicateErrorMessage.hasError = false;
                  this.duplicateErrorMessage.errorMessage = '';
                  this.teamExist = true;
                  this.checkCoachDuplicate();
                }
                this.checkingDuplicate = false;
                document.getElementById('app').scrollIntoView();
              })
              .catch(error => {
                console.log("error geting clubs list")
                this.checkingDuplicate = false;
              })
        }else{
          this.checkCoachDuplicate();
        }
      },
      messageFor(val){
        this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
      },
      getAllTeamsList(){
        console.log("data team");
        axios.get(this.basePath + "api/get_all_teams_list")
            .then((response) => {
              this.teamsList = response.data.data;
              console.log("team views of data", this.teamOptions);
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })
      },
      validateTelinput2(obj){
        this.validTelinput2 = obj.valid;
        console.log(obj);
        if(obj.valid){
          this.errors.phone_2 = '';
        }
      },
      addFilledWaiverUpdateData(waiverNumbers){
        let waiverNumber = this.currentWaiver-1;
        let currentWaiverLength = this.waiverData[waiverNumber].user_info.length;
        if(this.checkOutPersonalEdit) {
          waiverNumber = waiverNumbers;
        }
        if(this.isTeamRegistration){
          currentWaiverLength =currentWaiverLength-1;
        }
        for(let i = 0; i < currentWaiverLength; i++) {
          this.waiverForm = {};
          console.log('init waiver');
          console.log(this.waiverForm);
          this.waiverForm.waivers_id = this.waiverData[waiverNumber].waivers_id;
          this.waiverForm.initials = this.waiverData[waiverNumber].user_info[i].member_initials;
          this.waiverForm.signed_for = this.waiverData[waiverNumber].user_info[i].member_name;
          this.waiverForm.signed_user = this.waiverData[waiverNumber].user_info[i].signed_member;
          if (this.membership.member_type_id == 4 && [3,4].indexOf(this.membership.id) == -1) {
            this.waiverForm.club = this.clubSubmittedBy;
          }
          console.log('======================a==============');
          console.log(this.waiverForm);
          if(this.checkOutPersonalEdit) {
            this.signedWaiverData.waivers.push(this.waiverForm);
            waiverNumber++;
          }
        }
      },
      getregSetting(){
          axios.get(this.basePath+"api/get_reg_status").then(response => {
            let getRegStatus = response.data.data;
            if(getRegStatus) {
              this.regStatus = getRegStatus.reg_disable_status;
              this.regClosedMessage = getRegStatus.reg_disable_message;
            }
          })
          .catch(err => {
            console.log(err);
          })
        },
    },
    mounted() {
        this.getregSetting();
        this.merchandiseDataMenApi();
        this.merchandiseDataWomenApi();
        if(this.subdomain == 'waterski') this.merchandiseDataAccessoriesApi();
        this.getMemberType();
        this.getDiversity();
        this.getDiscipline();
        if(this.subdomain == 'alliancefastpitch') 
        {
          const retry1 = setInterval(() => {
              this.teamAddonsBtn = this.getSiteProps('teamAddonsBtn');
              if(this.teamAddonsBtn != undefined || this.teamAddonsBtn ==''){               
                clearInterval(retry1);
              }
          }, 200);
          this.getTeamsList();
          this.getCollegeCommittedsList();
          this.getLeagues();
          this.getCurrentSeason();
          if(this.$route.query.token)
          {
            this.blockLoader = true;
            axios.get(this.basePath+"api/invitedPlayer/"+this.$route.query.token)
            .then(res => {
              console.log("invplayerdata", res.data);
              if(res.data.status == 'success') {
                this.invitedTeam = res.data.data.team_id;                
                this.invitedEmail = res.data.data.email;
                console.log("this.invitedEmail ",this.invitedEmail );
              } else if (res.data.status == 'error' && res.data.message == 'Invite link expired') {
                window.open("/invitelinkexpired", "_self");
              }
              this.blockLoader = false;
            })
            .catch(err => {
                this.blockLoader = false;
                console.log(err);
            })
          }
          this.getAllTeamsList();
        }
        this.getGroup();       
      const year = new Date().getFullYear();
      this.yearArr = Array.from({length: 12}, (value, index) => year + index);
      this.getClubType();

      const yrs = year+20;
      const twoDigitYear = yrs.toString().substr(-2);
      const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
      this.GraduationyearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);

      const retry = setInterval(() => {
          if(this.fields.demographic != undefined && this.fields.demographic.category != undefined){
              this.getCategories();
              clearInterval(retry);
          }
      }, 200);

        if(this.subdomain == "shooting"){
            this.getClubDisciplines();
        }

      const retry2 = setInterval(() => {
        let other_pricing = this.getSiteProps('general.other_pricing');
        if(other_pricing !== undefined){
          axios.get(this.basePath+"api/other-pricing/current-prices")
          .then(res => {
            let data = res.data.data;
            if ( Object.prototype.hasOwnProperty.call(data, "club_sanction") ) {
              this.sanctionFee = data.club_sanction;
            } else {
              this.sanctionFee = 0;
            }
            if ( Object.prototype.hasOwnProperty.call(data, "club_insurance") ) {
              this.insuranceFee = data.club_insurance;
            } else {
              this.insuranceFee = 0;
            }
          })
          .catch(err => {
            console.log(err);
          })
          clearInterval(retry2);
        }
      }, 200);
    },
    computed: {
        verifyEditData() {
            return this.verifyData;
        },
        ncsaEnable: function(){
          return this.getSiteProps("general.ncsa_enable");
        },
        clubMemberContactLength() {
            return this.clubInfoData.members;
        },
        club_sanction() {
          return this.club_sanction_data.selected;
        },
        shippingCosts: function () {
          return this.getSiteProps('merchandise_shipping');
        },
        shippingTotal: function(){
          let shippingCost = 0.00;
          if(this.cartLength >= 6) {
            shippingCost =  parseFloat(this.shippingCosts.shipping_above_six_item).toFixed(2);
          } else if(this.cartLength <= 5 && this.cartLength >= 3) {
            shippingCost =  parseFloat(this.shippingCosts.shipping_four_to_six_item).toFixed(2);
          } else if(this.cartLength <= 2 ) {
            shippingCost =  parseFloat(this.shippingCosts.shipping_first_three_item).toFixed(2);
          }
          return shippingCost;
        },

    },
    updated(){
        this.mountSteps();
        this.mountPropMemberTypes();
        this.mountPropFooter();

        //Init processing fee percent from property file
        if(this.processingFeePercent == 0){
            let registration_fee_percent = this.getSiteProps("general.registration_fee_percent");
            if(registration_fee_percent != undefined && !isNaN(registration_fee_percent)){
                this.processingFeePercent = registration_fee_percent;
            }
        }
        if(this.getSiteProps("general.paymentGateway") == 'stripe_intent')
        {
          this.stripeIntent.enable = true;
          this.stripeIntent.isportzFeePercent = this.getSiteProps("general.isportz_fee_percent");
          this.stripeIntent.isportzFeeCents = this.getSiteProps("general.isportz_fee_cents");
          this.stripeIntent.processingFeePercent = this.getSiteProps("general.processing_fee_percent");
          this.stripeIntent.processingFeeCents = this.getSiteProps("general.processing_fee_cents");
        }

        if(this.subdomain.indexOf("alliancefastpitch") != -1 && [1,2,3,4,5].indexOf(this.membership.id) != -1){
          this.isTeamRegistration = true;
          this.player_informations = this.getSiteProps('memberRegistration.team.fields.player_information.isVisible');
          this.fieldsAd = (!this.player_informations)?this.fields.family:this.fields.player_information;
          this.CheckoutText = this.getSiteProps('team_registrations');
          this.listOfCountryWithLimit = true;
          this.isTeamOptions = this.fields.club_info.age_division.options;
          if([1,2].indexOf(this.membership.id) != -1){
            this.isChild = true;
          }
          this.waiverContent[0].coach_acknowledgement=this.fields.waivers.coach_acknowledgement_I;
          this.waiverContent[1].coach_acknowledgement=this.fields.waivers.coach_acknowledgement_II;
        }
    },
    filters: {
        filterBr(value){
            return value.replace("<br>", " ");
        },
        fullNumber(value){
            return value.toFixed(2);
        }
    }
}
</script>

<style scoped>
.inl-item{
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.95rem;
  vertical-align: middle;;
}
h4.subtitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.inl-item label {
  margin-bottom: 0;
}
.imgwithinline img {
  width: 100% !important;
  float: left;
  max-width: 163px;
  height: 100px;
  margin-right: 19px;
}
.imgwithinline span p {
  text-align: justify;
}
</style>