<template>
    <form @submit.prevent="clubData" method="post">
      <div class="card club-info">
   <div class="card-header register-title">
      <h3> {{ fields.title || 'Purchase Practice/Exhibition Sanctioning (Optional)'}}</h3>
<!--      <p class="mb-0 mt-3 font-13">{{ fields.description }}</p>-->
   </div>
   <div class="card-body pr-4 pl-4 clubinfo">
      <div class="row" v-if="sanctionFee">
        <div class="col-md-12">
          <p dir="ltr"><span>An important benefit USA Water Ski &amp; Wake Sports (USA-WSWS) provides to its affiliated clubs is the ability to host and sanction practices and exhibitions. As part of practice/exhibition sanctioning, USA-WSWS provides General Liability and Participant Accident insurance coverage to clubs that adhere to the requirements outlined below:</span></p>
          <ul class="grassrootul">
            <li>
             <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Organizing club must be a current USA-WSWS affiliated club for the calendar year in which they are sanctioning.</span></span>
            </li>
            <li>
             <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Event site must be on file with USA-WSWS in advance of the event.</span></span>
            </li>
            <li>
              <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>from the organizing club must be present for the duration of the event.</span></span>
            </li>
            <li>
              <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>All participants must be current USA-WSWS Active or GrassRoots Active members or USA-WSWS Guest Members.</span></span>
            </li>
            <li>
              <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>All drivers towing skiers during sanctioned practices/exhibitions must be a current USA-WSWS Trained Driver or sport discipline-rated driver.</span></span>
            </li>
            <li>
              <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>A minimum of one USA-WSWS safety official with a rating of Coordinator, State, Regional, or National Safety must be present at all exhibitions and sanctioned practices where guest members are participating.</span></span>
            </li>
            <li v-if="sanctionFee"><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i>Cost:<span>&nbsp;${{ sanctionFee }} (one-time annual fee for unlimited practices/exhibitions</span></span></li>
          </ul>
          <p><br></p>          
        </div>
        <div :class="[fields.fields.sanction_types.colClass]" v-if="fields.fields.sanction_types">
          <h3 style="font-size: 20px;line-height: 25px;margin-bottom: 0;color: #05213e;font-weight: 700;font-style: italic;" :for="fields.fields.sanction_types.label" class="">{{ fields.fields.sanction_types.label }}<sup v-if="fields.fields.sanction_types.required">*</sup></h3><br>
          <div class="form-group ml-4" :key="'sanction_types'+k" v-for="(choice,k) in fields.fields.sanction_types.choices">
            <div class="grossrootcheck">
              <input v-if="choice.id=='without_guest'" type="checkbox" class="form-check-input" :id="choice.id" name="sanctionForm.practise_without_guest" value="1" v-model="sanctionForm.practise_without_guest">
              <input v-if="choice.id=='with_guest'" type="checkbox" class="form-check-input" :id="choice.id" name="sanctionForm.practise_with_guest" value="1" v-model="sanctionForm.practise_with_guest">
              <input v-if="choice.id=='exhibition'" type="checkbox" class="form-check-input" :id="choice.id" name="sanctionForm.exhibition" value="1" v-model="sanctionForm.exhibition">
              <label class="form-check-label" :for="'sanction_types'+k">{{ choice.label }}</label>
            </div>
          </div>
        </div>
        <div
            class="col-md-12 accordion  membership-type"
            role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'sanct_1'" variant="info">
                Sanction Acknowledgment
              </b-button>
            </b-card-header>
            <b-collapse  id="sanct_1" accordion="mtype-accordion" visible  role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <div class="col-md-12">
                    <p class="font-13"><span>Practice/Exhibition Acknowledgment</span></p>
                    <p class="font-13"><span>What type of events will you be hosting (check all that apply)?</span></p>
                    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                      <li v-if="sanctionForm.practise_without_guest == 1" aria-level="1" dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Practices (with only USA-WSWS members)</span></p>
                      </li>
                      <li v-if="sanctionForm.practise_with_guest == 1" aria-level="1" dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Practices with guest members&nbsp;</span></p>
                      </li>
                      <li v-if="sanctionForm.exhibition == 1" aria-level="1" dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Exhibitions (e.g., Ski Shows)</span></p>
                      </li>
                    </ul>
                    <p><br></p>
                    <p class="font-13"><span>All club and team practices or exhibitions are considered &ldquo;sanctioned&rdquo; by USA Water Ski &amp; Wake Sports if&nbsp;</span><span class="font-13">ALL&nbsp;</span><span class="font-13">the below criteria are met.&nbsp;</span></p>
                    <p class="font-13"><span>I understand that for practice or exhibition sanctioning that:</span></p>
                   <ul class="grassrootul">
                                <li>
                                <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>All participants must be current USA-WSWS Active or GrassRoots Active members or USA-WSWS Guest Members. All participants, or their parent/legal guardian, in the case of a minor, must have a signed Adult-Minor Annual Waiver on file with USA-WSWS prior to participating. &nbsp;Guest members are not eligible to jump over a ramp.</span></span>
                                </li>
                                <li>
                                <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>All drivers towing skiers during sanctioned practices/exhibitions must be a current USA-WSWS Trained Driver or sport discipline-rated driver. NSSA (show ski) club practices/exhibitions require exclusive use of NSSA-rated drivers.</span></span>
                                </li>
                                <li>
                                <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>A minimum of one USA-WSWS safety official with a rating of Coordinator, State, Regional, or National Safety must be present at all exhibitions and sanctioned practices where guest members are participating.</span></span>
                                </li>
                                <li>
                                <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Club practices or exhibitions must take place during normal and reasonable water ski hours (typically from dusk until dawn). Practice/exhibition sanctioning does not constitute 24-hour on the water insurance coverage. For example, water ski activity at 1 a.m. would not be considered normal water ski hours and would not be deemed a sanctioned activity.</span></span>
                                </li>
                                <li>
                                <span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Practice/Exhibition sanctioning does not include Basic Skills Clinics or Learn to Ski events where instruction is taking place. Basic Skills Clinics need to be to be sanctioned as individual events and are not considered a sanctioned practice or exhibition.</span></span>
                                </li>
                              </ul>
                    <div  class="">
                      <label class="form-check-label">
                        <input type="checkbox" id="practise_agree" class="form-check-input" :value="1" v-model="sanctionForm.practise_agree" name="sanctionForm.practise_agree">
                        <span class="font-13" :style="{ 'display': 'inline-block' }" v-if="fields.fields.practise_agree" v-html="fields.fields.practise_agree"></span>
                      </label>
                      <label>Name : <input type="text" v-on:keypress="isLetter($event)" class="onlybor-bot" style="width: 40px" id="practise_sign_name" name="sanctionForm.practise_sign_name" v-model="sanctionForm.practise_sign_name"></label><br>
                      <label>Date : <input type="text" class="datepicks" disabled :value="currentDate"></label>
                      <span class="text-sm text-danger">{{ errors.practise_agree}}</span>
                      <span class="text-sm text-danger">{{ errors.practise_sign_name}}</span>
                    </div>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- <b-card no-body class="mb-1" v-show="currentAgreement == 2 || sanctionForm.exhibition == 1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'sanct_2'" variant="info">
                Event Sanctioning Agreement
              </b-button>
            </b-card-header>
            <b-collapse visible	 id="sanct_2" accordion="mtype-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <div class="col-md-12" >
                    <p class="font-13"><span>This is a contract (the &ldquo;Agreement&rdquo;) between USA Water Ski &amp; Wake Sports (&ldquo;USA-WSWS:&rdquo;), a New Jersey not-for-profit corporation, the event promoter(s) or director(s) and all other persons or entities connected with the management and production of the Event as reflected in this Agreement and/or the Event sanction application and documents (who are referred to in this Agreement individually and collectively as the &ldquo;Local Organizing Club.&rdquo;). This Agreement is a part of the Sanction for the Event, which also includes the Local Organizing Club&rsquo;s application to sanction the Event and any other agreements between USA-WSWS and the Local Organizing Club relating to the Event, and may include activities on different dates as set forth in the sanction application and documents. Please read this Agreement, the sanction application and all other documents and agreements carefully before completing the sanction application process and executing this Agreement. This Agreement and all other sanction documents must be approved and executed by someone authorized to do so on behalf of the Local Organizing Club. By agreeing to these terms, the Local Organizing Club is agreeing that the Event shall meet the minimum event sanctioning requirements as outlined in this Agreement and all other sanction agreements and documents. In consideration of the mutual promises and terms of this Agreement, the adequacy of which is hereby acknowledged, USA-WSWS and the Local Organizing Club agree as follows:&nbsp;</span></p>
 <ul class="grassrootul">
    <li><span >1. &nbsp;Event Administration. Local Organizing Club agrees, represents and warrants that Local Organizing Club shall:</span>
    <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      <li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Ensure that the Event complies with all USA-WSWS&rsquo;s safety requirements and guidelines, Rated Driver requirements, rules, regulations, policies, directives, decisions and all procedures set forth by USA-WSWS as are in effect at the time of the Event (see USA-WSWS website for further information);&nbsp;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Collect, as trustee on behalf of USA-WSWS, all required USA-WSWS guest membership fees and annual membership fees, release forms and results (for tournaments only), and shall deliver those items to USA-WSWS within the time required by USA-WSWS policies or this Agreement;&nbsp;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Reserve/obtain permission and/or permits from local jurisdiction(s) to use the course(s), facilities venues etc.;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Conduct the Event in accordance with all applicable current competitive rules as specified in the applicable USA-WSWS sport discipline rulebook or International Water Ski and Wakeboard Federation (IWWF) rules;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Accept full responsibility for the management and promotion of this Event and any parties it employs or recruits to assist with the management and production of said Event, including but not limited to, the supervision of paid and non-paid employees and volunteers. Local Organizing Club is solely responsible for complying with all applicable statutes, rules and regulations. Local Organizing Club shall provide to USA-WSWS all Event permits, certificates, contracts and other similar documents or agreements, upon request and within a reasonable time.&nbsp;</span></span>
      </li>
    </ol>
</li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>2. Safety Guidelines. Local Organizing Club agrees to meet all safety requirements as specified in the USA-WSWS Official Safety Manual &amp; Policy Handbook or otherwise established by USA-WSWS as are in effect at the time of the Event (see USA-WSWS website for further information). &nbsp;Local Organizing Club acknowledges that safety considerations may require that additional precautions or measures be taken beyond the minimum requirements established by USA-WSWS, and Local Organizing Club agrees to determine (in its sole discretion) and implement reasonable measures to promote and conduct the event in a safe manner. Local Organizing Club shall appoint a Safety Director or Coordinator as required under applicable USA-WSWS rules or policies, in addition to such other personnel as may be required to carry out the event administration duties. Local Organizing Club shall not conduct the event if Local Organizing Club is not able to accept or provide for the performance of these responsibilities.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>3. Sanction Fee. Local Organizing Club shall promptly submit the sanction fee according to the fee schedule in the applicable USA-WSWS sport discipline rulebook. The appropriate sanction fee shall be submitted prior to sanction approval. In the event of a weather-related cancellation, a refund of the sanction fee shall be made to Local Organizing Club provided that written notification of cancellation of the Event is provided to USA-WSWS as required by this Agreement. A $25 administration fee and any applicable late fees are non-refundable in all cases.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>4. Financial Responsibilities. All outstanding debts to USA-WSWS, International Water Ski and Wakeboard Federation (IWWF) or any other USA-WSWS affiliates shall be paid prior to sanction approval. Local Organizing Club is financially responsible for all costs incurred in association with the Event.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>5. Sanction Applications. Local Organizing Club shall complete and submit all information needed to complete the Event sanction application and approval process in a timely manner. Local Organizing Club warrants that all of the minimum event sanctioning requirements for USA-WSWS shall be complied with, that all changes to an already submitted sanction application shall be submitted in writing and that all other changes shall be communicated to USA-WSWS prior to the start of the Event (with the exception of a weekend Event cancellation, in which case Local Organizing Club shall notify USA-WSWS of cancellation the next business day). Falsifying, concealing or misrepresenting information in the event sanction application to USA-WSWS may result in either voiding or reducing the applicable insurance coverage made available to Local Organizing Club with respect to the Event.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>6. Membership and Waiver and Release Requirements. Local Organizing Club shall confirm that all participants and volunteers in the Event are members in good standing with USA-WSWS and shall ensure that all participants and volunteers have completed the required USA-WSWS membership forms, signed the mandatory waivers and release forms, and paid the mandatory membership fees. For those persons who are not legally competent by virtue of age (i.e. a minor under eighteen (18) years of age) or mental incapacity (i.e. a mentally challenged person) to sign the required USA-WSWS membership and participant waiver forms, the Local Organizing Club shall ensure that a qualified parent or legal guardian completes and signs the required forms on behalf of such individuals before the Local Organizing Club allows these individuals to participate in the Event. For IWWF World Championship and World Cup events conducted in the United States, members of foreign federations are not required to become USA Water Ski members; however, all foreign federation members are required to sign the mandatory waiver and release forms.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>7. Insurance. As part of sanctioning, USA-WSWS provides General Liability, Umbrella Liability and Participant Accident coverage for the protection and benefit of its Event Organizers, clubs, associations, sport disciplines, divisions and individual members in connection with USA-WSWS sanctioned events. Please refer to the Insurance Fact Summary posted on the USA-WSWS website for a summary of the USA-WSWS insurance programs, for information about how to request certificates of insurance, and for instructions about how to report incidents and claims. Upon approval of the sanction application, certificates of insurance can be requested through USA-WSWS using the Certificate Request Form posted on the USA-WSWS website. Certificates of insurance will be issued upon receipt of the completed form along with the appropriate payment. USA-WSWS is not acting as an insurer or insurance agent. Local Organizing Club shall be bound by the written terms of the insurance policy and certificates of insurance. The insurance provided by USA-WSWS as part of sanctioning is not all inclusive, and coverage is subject to the terms, conditions and exclusions of the policies, including communicable disease. Local Organizing Club is solely responsible for and shall determine the necessity of purchasing additional insurance policies and/or limits of coverage appropriate for the Event. Insurance is a benefit of sanctioning, and exclusion, separation or division cannot be made for either.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Any non-traditional events, or any ancillary activities, functions or events in association with the Event, shall not be covered by USA-WSWS insurance unless such non-traditional events, activities, functions or events have been disclosed and prior approval has been granted by USA-WSWS (and the applicable insurance underwriters, as appropriate). As an example, hang gliding and similar aerial type acts within a show ski show are not approved for USA Water Ski insurance coverage; see NSSA&rsquo;s specific rules addressing these activities. Local Organizing Club is solely responsible for procuring any other insurance, and for costs and liabilities not covered by USA-WSWS insurance.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>8. Post Event Materials. Local Organizing Club shall ensure that all post -Event reports, results and other materials are electronically posted or submitted to USA Water Ski within the specified deadlines. Future sanctions will be withheld from those Local Organizing Clubs with ANY and ALL outstanding paperwork. These LOCs will be allowed to submit future sanction applications, but the tournament will not be published on the website nor sanction approval granted until all outstanding post-tournament reports are received from prior Events. Post event materials include, but are not limited to, collected USA-WSWS membership monies, completed USA-WSWS membership forms, signed participant waiver forms, results (with the exception of clinics, practices and exhibitions that are excluded from submitting results), incident report forms, USA-WSWS officials&rsquo; records, Safe Sport MAAPP compliance documents and safety director reports.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>9. Incident Reporting/Management. Local Organizing Club shall promptly report all incidents and claims in accordance with the guidelines set forth in the Incident Reporting Procedures and Medical Claim Filing Instructions and forms posted on the USA-WSWS website.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>10. Legal Claims and Event Records. Local Organizing Club hereby covenants and agrees to cooperate in good faith with USA-WSWS and its agents in the event of any claims or other legal actions arising out of the Event; to maintain all business records for the Event for at least seven (7) years after the Event; and to provide USAWS access to such records. Business records include, but are not limited to, participant waiver and release forms (except for waiver forms signed electronically through USA-WSWS&rsquo; online registration system), facility use agreements, agency permits/documents, vendor certificates of insurance, event participant census data, and accounting records. All records of incidents at the Event including incident reports and police reports shall be kept for a minimum of seven (7) years.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>11. Advertising. Included in the cost of sanctioning, USA-WSWS shall advertise the Event on its website calendar. Additionally, Local Organizing Club may display USA-WSWS banners and may advertise that the Event is sanctioned by USA-WSWS upon receiving written notice of approval from USA-WSWS.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>12. Limited Use of USA-WSWS Trademark. Local Organizing Club shall not use the USA-WSWS logo in such a way as to imply that USA-WSWS is a sponsor.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>13. Media Release. Local Organizing Club hereby agrees to give USA-WSWS and its authorized agents the right to use the Local Organizing Club and Event names on any written, photographic, audio or videotaped materials relating to the Event for advertising, promotional, commercial, educational or other purposes, and Local Organizing Club hereby waives any right to additional consideration or compensation with respect to any such use. Local Organizing Club is prohibited from making any public/formal statements regarding an incident at the Event, but instead the Local Organizing Club should direct questions from the media or others to USA-WSWS&rsquo;s Executive Director or to USA-WSWS&rsquo;s Communications Director for comment.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>14. Drug Testing. Local Organizing Club hereby acknowledges that the Event may be selected for, drug testing by USA-WSWS, IWWF, USOC, or United States Anti-Doping Agency (USADA). In such case, Local Organizing Club shall permit drug testing to proceed and shall comply with the Doping Control Event Packet and other protocol developed by USADA. Through USA-WSWS&rsquo;s agreement with the USOC, drug testing rights are given to USADA. Local Organizing Club shall not conduct any drug testing without the prior written approval of USAWS and supervision of USADA.&nbsp;</span></span></li>
<li><span class="font-13"><i class="fas fa-chevron-right mt-2"></i><span class="insdiespan">15. Safe Sport MAAPP Policy- Local Organizing Club acknowledges that it has read and will follow the Safe Sport MAAPP (<a target="_blank" href="https://www.usawaterski.org/graphics/downloads/USAWSWSMAAPPolicies.pdf" style="text-decoration:none;">Minor Athlete Abuse Prevention Polices</a>) and acknowledges the MAAPP protocol found in the&nbsp;<a target="_blank" href="http://www.usawaterski.org/graphics/downloads/USAWSWSSafeSportHandbook.pdf" style="text-decoration:none;">USA-WSWS Safe Sport Handbook</a>&nbsp;for reporting suspected or alleged MAAPP violations. Local Organizing Club acknowledges that is has reviewed the USA-WSWS&nbsp;<a target="_blank" href="https://www.teamusa.org/-/media/USA_Waterski/Membership-Docs/USAWSWSSuspendedMembers.pdf" style="text-decoration:none;">Suspended Members List</a>&nbsp;and will actively prevent any individual on the list from participating as a registered athlete, official, or volunteer at the event. &nbsp;Future sanctions can be withheld from those LOCs for failure to implement and comply with protocol outlined in the Safe Sport MAAPP and SafeSport Handbook.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>16. Right to Terminate. USA-WSWS may terminate the Event&rsquo;s sanction at any time if Local Organizing Club fails to comply with any of the terms of this Agreement or other USA-WSWS requirements. If USA-WSWS terminates Event&rsquo;s sanction, Local Organizing Club shall be reimbursed for the sanction fee, less late fees, less any USAWS expenses and less any outstanding monies owed to USA-WSWS or its affiliates by Local Organizing Club (collectively, &ldquo;USA-WSWS Expenses&rdquo;).&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>17. Probation/Sanction Refusal. USA-WSWS has the right to impose probation or other penalties of sanction if there is reasonable evidence of Local Organizing Club&rsquo;s failure to meet minimum sanctioning requirements, or for outstanding monies owed to USA-WSWS or its affiliates, or for safety concerns, or for any other reason(s) that may apply.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>18. Indemnification. Local Organizing Club and the entities responsible for the management and promotion of the Event, hereby, jointly and severally, indemnify and agree to defend and hold harmless USA-WSWS, its Associations, Sport Disciplines and Divisions, and each of their respective officers, directors, employees, volunteers and agents (collectively, the &ldquo;USA-WSWS Parties&rdquo;) for and against any and all claims, liabilities, demands, obligations, damages, costs of litigation, attorney&rsquo;s fees or other expenses related to or arising out of the management or promotion of any Event that does not meet the minimum sanctioning requirements outlined in this Agreement. Local Organizing Club HEREBY WAIVES, RELEASES, and FOREVER DISCHARGES the USA-WSWS Parties from any and all claims, causes of action, damages, and losses arising out of Local Organizing Club&apos;s breach or failure to abide by any part of this Sanction Agreement.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>19. No Agency. Local Organizing Club is not the agent of USA-WSWS. There is no intent to create an agency, partnership or joint venture relationship between USA-WSWS and Local Organizing Club of the Event. It is understood that Local Organizing Club is not employed by USA-WSWS and therefore USA-WSWS shall not provide worker&rsquo;s compensation coverage or any other benefits that would be provided to USA-WSWS employees.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>20. Modifications. This Agreement represents the entire agreement between both parties with respect to the sanctioning of the Event. No modification or amendment of this Agreement or waiver of any provision of this Agreement shall be valid unless it is set forth in writing and signed by all parties to this Agreement. Local Organizing Club shall not modify, nor shall Local Organizing Club allow anyone, including participants, to modify any other USA-WSWS documents, including, but not limited to the athlete&rsquo;s participant waiver and release form.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>21. Severability. If any provision of this Agreement shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>22. Headings. The sections and other headings contained in this Agreement are intended solely for convenience of reference and are not intended to be part of or affect the meaning or interpretation of this Agreement.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>23. Other. Local Organizing Club voluntarily agrees to these terms and acknowledges that it understands and accepts of all terms and conditions of this Agreement. This Agreement and its benefits are not transferable. WHEREFORE, intending to be legally bound by the terms hereof, the Local Organizing Club agrees to the terms of this Agreement on the date as indicated by his/her/its approval of this Agreement.&nbsp;</span></span></li>
</ul>
          
          
          <div  class="">
            <label class="form-check-label">
              <input type="checkbox" id="sanction_exhib_agree" class="form-check-input " :value="1" v-model="sanctionForm.exhibition_agree" name="sanctionForm.exhibition_agree">
              <span :style="{ 'display': 'inline-block' }" v-if="fields.fields.exhibition_agree_i" v-html="fields.fields.exhibition_agree_i"></span>
              <input class="onlybor-bot" type="text" v-on:keypress="isLetter($event)" style="width: 40px" id="club_sanction_exhibition_sign_name" name="sanctionForm.exhibition_sign_name" v-model="sanctionForm.exhibition_sign_name">
              <span :style="{ 'display': 'inline-block' }" v-if="fields.fields.exhibition_agree" v-html="fields.fields.exhibition_agree"></span><input type="text" class="datepicks" disabled :value="currentDate">
            </label>
            <span class="text-sm text-danger">{{ errors.exhibition_agree}}</span>
            <span class="text-sm text-danger">{{ errors.exhibition_sign_name}}</span>
          </div>
        </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card> -->
          <div>



  <div>
     <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button v-b-toggle.collapse-3 class="m-1 btn btn-info btn-block" variant="info">
                Event Sanctioning Agreement
              </b-button>
            </b-card-header>



    <!-- <b-button v-b-toggle.collapse-3 class="m-1" variant="info">
                Event Sanctioning Agreement
              </b-button> -->



  <!-- <b-button v-b-toggle.collapse-3 class="m-1">Event Sanctioning Agreement</b-button> -->
  <b-collapse visible id="collapse-3">
    <b-card>
      <b-card-body>
                <b-card-text>
                  <div class="col-md-12" >
                    <p class="font-13"><span>This is a contract (the &ldquo;Agreement&rdquo;) between USA Water Ski &amp; Wake Sports (&ldquo;USA-WSWS:&rdquo;), a New Jersey not-for-profit corporation, the event promoter(s) or director(s) and all other persons or entities connected with the management and production of the Event as reflected in this Agreement and/or the Event sanction application and documents (who are referred to in this Agreement individually and collectively as the &ldquo;Local Organizing Club.&rdquo;). This Agreement is a part of the Sanction for the Event, which also includes the Local Organizing Club&rsquo;s application to sanction the Event and any other agreements between USA-WSWS and the Local Organizing Club relating to the Event, and may include activities on different dates as set forth in the sanction application and documents. Please read this Agreement, the sanction application and all other documents and agreements carefully before completing the sanction application process and executing this Agreement. This Agreement and all other sanction documents must be approved and executed by someone authorized to do so on behalf of the Local Organizing Club. By agreeing to these terms, the Local Organizing Club is agreeing that the Event shall meet the minimum event sanctioning requirements as outlined in this Agreement and all other sanction agreements and documents. In consideration of the mutual promises and terms of this Agreement, the adequacy of which is hereby acknowledged, USA-WSWS and the Local Organizing Club agree as follows:&nbsp;</span></p>
 <ul class="grassrootul">
    <li><span >1. &nbsp;Event Administration. Local Organizing Club agrees, represents and warrants that Local Organizing Club shall:</span>
    <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      <li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Ensure that the Event complies with all USA-WSWS&rsquo;s safety requirements and guidelines, Rated Driver requirements, rules, regulations, policies, directives, decisions and all procedures set forth by USA-WSWS as are in effect at the time of the Event (see USA-WSWS website for further information);&nbsp;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Collect, as trustee on behalf of USA-WSWS, all required USA-WSWS guest membership fees and annual membership fees, release forms and results (for tournaments only), and shall deliver those items to USA-WSWS within the time required by USA-WSWS policies or this Agreement;&nbsp;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Reserve/obtain permission and/or permits from local jurisdiction(s) to use the course(s), facilities venues etc.;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Conduct the Event in accordance with all applicable current competitive rules as specified in the applicable USA-WSWS sport discipline rulebook or International Water Ski and Wakeboard Federation (IWWF) rules;</span></span>
      </li>
      <li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Accept full responsibility for the management and promotion of this Event and any parties it employs or recruits to assist with the management and production of said Event, including but not limited to, the supervision of paid and non-paid employees and volunteers. Local Organizing Club is solely responsible for complying with all applicable statutes, rules and regulations. Local Organizing Club shall provide to USA-WSWS all Event permits, certificates, contracts and other similar documents or agreements, upon request and within a reasonable time.&nbsp;</span></span>
      </li>
    </ol>
</li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>2. Safety Guidelines. Local Organizing Club agrees to meet all safety requirements as specified in the USA-WSWS Official Safety Manual &amp; Policy Handbook or otherwise established by USA-WSWS as are in effect at the time of the Event (see USA-WSWS website for further information). &nbsp;Local Organizing Club acknowledges that safety considerations may require that additional precautions or measures be taken beyond the minimum requirements established by USA-WSWS, and Local Organizing Club agrees to determine (in its sole discretion) and implement reasonable measures to promote and conduct the event in a safe manner. Local Organizing Club shall appoint a Safety Director or Coordinator as required under applicable USA-WSWS rules or policies, in addition to such other personnel as may be required to carry out the event administration duties. Local Organizing Club shall not conduct the event if Local Organizing Club is not able to accept or provide for the performance of these responsibilities.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>3. Sanction Fee. Local Organizing Club shall promptly submit the sanction fee according to the fee schedule in the applicable USA-WSWS sport discipline rulebook. The appropriate sanction fee shall be submitted prior to sanction approval. In the event of a weather-related cancellation, a refund of the sanction fee shall be made to Local Organizing Club provided that written notification of cancellation of the Event is provided to USA-WSWS as required by this Agreement. A $25 administration fee and any applicable late fees are non-refundable in all cases.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>4. Financial Responsibilities. All outstanding debts to USA-WSWS, International Water Ski and Wakeboard Federation (IWWF) or any other USA-WSWS affiliates shall be paid prior to sanction approval. Local Organizing Club is financially responsible for all costs incurred in association with the Event.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>5. Sanction Applications. Local Organizing Club shall complete and submit all information needed to complete the Event sanction application and approval process in a timely manner. Local Organizing Club warrants that all of the minimum event sanctioning requirements for USA-WSWS shall be complied with, that all changes to an already submitted sanction application shall be submitted in writing and that all other changes shall be communicated to USA-WSWS prior to the start of the Event (with the exception of a weekend Event cancellation, in which case Local Organizing Club shall notify USA-WSWS of cancellation the next business day). Falsifying, concealing or misrepresenting information in the event sanction application to USA-WSWS may result in either voiding or reducing the applicable insurance coverage made available to Local Organizing Club with respect to the Event.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>6. Membership and Waiver and Release Requirements. Local Organizing Club shall confirm that all participants and volunteers in the Event are members in good standing with USA-WSWS and shall ensure that all participants and volunteers have completed the required USA-WSWS membership forms, signed the mandatory waivers and release forms, and paid the mandatory membership fees. For those persons who are not legally competent by virtue of age (i.e. a minor under eighteen (18) years of age) or mental incapacity (i.e. a mentally challenged person) to sign the required USA-WSWS membership and participant waiver forms, the Local Organizing Club shall ensure that a qualified parent or legal guardian completes and signs the required forms on behalf of such individuals before the Local Organizing Club allows these individuals to participate in the Event. For IWWF World Championship and World Cup events conducted in the United States, members of foreign federations are not required to become USA Water Ski members; however, all foreign federation members are required to sign the mandatory waiver and release forms.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>7. Insurance. As part of sanctioning, USA-WSWS provides General Liability, Umbrella Liability and Participant Accident coverage for the protection and benefit of its Event Organizers, clubs, associations, sport disciplines, divisions and individual members in connection with USA-WSWS sanctioned events. Please refer to the Insurance Fact Summary posted on the USA-WSWS website for a summary of the USA-WSWS insurance programs, for information about how to request certificates of insurance, and for instructions about how to report incidents and claims. Upon approval of the sanction application, certificates of insurance can be requested through USA-WSWS using the Certificate Request Form posted on the USA-WSWS website. Certificates of insurance will be issued upon receipt of the completed form along with the appropriate payment. USA-WSWS is not acting as an insurer or insurance agent. Local Organizing Club shall be bound by the written terms of the insurance policy and certificates of insurance. The insurance provided by USA-WSWS as part of sanctioning is not all inclusive, and coverage is subject to the terms, conditions and exclusions of the policies, including communicable disease. Local Organizing Club is solely responsible for and shall determine the necessity of purchasing additional insurance policies and/or limits of coverage appropriate for the Event. Insurance is a benefit of sanctioning, and exclusion, separation or division cannot be made for either.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>Any non-traditional events, or any ancillary activities, functions or events in association with the Event, shall not be covered by USA-WSWS insurance unless such non-traditional events, activities, functions or events have been disclosed and prior approval has been granted by USA-WSWS (and the applicable insurance underwriters, as appropriate). As an example, hang gliding and similar aerial type acts within a show ski show are not approved for USA Water Ski insurance coverage; see NSSA&rsquo;s specific rules addressing these activities. Local Organizing Club is solely responsible for procuring any other insurance, and for costs and liabilities not covered by USA-WSWS insurance.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>8. Post Event Materials. Local Organizing Club shall ensure that all post -Event reports, results and other materials are electronically posted or submitted to USA Water Ski within the specified deadlines. Future sanctions will be withheld from those Local Organizing Clubs with ANY and ALL outstanding paperwork. These LOCs will be allowed to submit future sanction applications, but the tournament will not be published on the website nor sanction approval granted until all outstanding post-tournament reports are received from prior Events. Post event materials include, but are not limited to, collected USA-WSWS membership monies, completed USA-WSWS membership forms, signed participant waiver forms, results (with the exception of clinics, practices and exhibitions that are excluded from submitting results), incident report forms, USA-WSWS officials&rsquo; records, Safe Sport MAAPP compliance documents and safety director reports.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>9. Incident Reporting/Management. Local Organizing Club shall promptly report all incidents and claims in accordance with the guidelines set forth in the Incident Reporting Procedures and Medical Claim Filing Instructions and forms posted on the USA-WSWS website.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>10. Legal Claims and Event Records. Local Organizing Club hereby covenants and agrees to cooperate in good faith with USA-WSWS and its agents in the event of any claims or other legal actions arising out of the Event; to maintain all business records for the Event for at least seven (7) years after the Event; and to provide USAWS access to such records. Business records include, but are not limited to, participant waiver and release forms (except for waiver forms signed electronically through USA-WSWS&rsquo; online registration system), facility use agreements, agency permits/documents, vendor certificates of insurance, event participant census data, and accounting records. All records of incidents at the Event including incident reports and police reports shall be kept for a minimum of seven (7) years.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>11. Advertising. Included in the cost of sanctioning, USA-WSWS shall advertise the Event on its website calendar. Additionally, Local Organizing Club may display USA-WSWS banners and may advertise that the Event is sanctioned by USA-WSWS upon receiving written notice of approval from USA-WSWS.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>12. Limited Use of USA-WSWS Trademark. Local Organizing Club shall not use the USA-WSWS logo in such a way as to imply that USA-WSWS is a sponsor.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>13. Media Release. Local Organizing Club hereby agrees to give USA-WSWS and its authorized agents the right to use the Local Organizing Club and Event names on any written, photographic, audio or videotaped materials relating to the Event for advertising, promotional, commercial, educational or other purposes, and Local Organizing Club hereby waives any right to additional consideration or compensation with respect to any such use. Local Organizing Club is prohibited from making any public/formal statements regarding an incident at the Event, but instead the Local Organizing Club should direct questions from the media or others to USA-WSWS&rsquo;s Executive Director or to USA-WSWS&rsquo;s Communications Director for comment.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>14. Drug Testing. Local Organizing Club hereby acknowledges that the Event may be selected for, drug testing by USA-WSWS, IWWF, USOC, or United States Anti-Doping Agency (USADA). In such case, Local Organizing Club shall permit drug testing to proceed and shall comply with the Doping Control Event Packet and other protocol developed by USADA. Through USA-WSWS&rsquo;s agreement with the USOC, drug testing rights are given to USADA. Local Organizing Club shall not conduct any drug testing without the prior written approval of USAWS and supervision of USADA.&nbsp;</span></span></li>
<li><span class="font-13"><i class="fas fa-chevron-right mt-2"></i><span class="insdiespan">15. Safe Sport MAAPP Policy- Local Organizing Club acknowledges that it has read and will follow the Safe Sport MAAPP (<a target="_blank" href="https://www.usawaterski.org/graphics/downloads/USAWSWSMAAPPolicies.pdf" style="text-decoration:none;">Minor Athlete Abuse Prevention Polices</a>) and acknowledges the MAAPP protocol found in the&nbsp;<a target="_blank" href="http://www.usawaterski.org/graphics/downloads/USAWSWSSafeSportHandbook.pdf" style="text-decoration:none;">USA-WSWS Safe Sport Handbook</a>&nbsp;for reporting suspected or alleged MAAPP violations. Local Organizing Club acknowledges that is has reviewed the USA-WSWS&nbsp;<a target="_blank" href="https://www.teamusa.org/-/media/USA_Waterski/Membership-Docs/USAWSWSSuspendedMembers.pdf" style="text-decoration:none;">Suspended Members List</a>&nbsp;and will actively prevent any individual on the list from participating as a registered athlete, official, or volunteer at the event. &nbsp;Future sanctions can be withheld from those LOCs for failure to implement and comply with protocol outlined in the Safe Sport MAAPP and SafeSport Handbook.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>16. Right to Terminate. USA-WSWS may terminate the Event&rsquo;s sanction at any time if Local Organizing Club fails to comply with any of the terms of this Agreement or other USA-WSWS requirements. If USA-WSWS terminates Event&rsquo;s sanction, Local Organizing Club shall be reimbursed for the sanction fee, less late fees, less any USAWS expenses and less any outstanding monies owed to USA-WSWS or its affiliates by Local Organizing Club (collectively, &ldquo;USA-WSWS Expenses&rdquo;).&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>17. Probation/Sanction Refusal. USA-WSWS has the right to impose probation or other penalties of sanction if there is reasonable evidence of Local Organizing Club&rsquo;s failure to meet minimum sanctioning requirements, or for outstanding monies owed to USA-WSWS or its affiliates, or for safety concerns, or for any other reason(s) that may apply.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>18. Indemnification. Local Organizing Club and the entities responsible for the management and promotion of the Event, hereby, jointly and severally, indemnify and agree to defend and hold harmless USA-WSWS, its Associations, Sport Disciplines and Divisions, and each of their respective officers, directors, employees, volunteers and agents (collectively, the &ldquo;USA-WSWS Parties&rdquo;) for and against any and all claims, liabilities, demands, obligations, damages, costs of litigation, attorney&rsquo;s fees or other expenses related to or arising out of the management or promotion of any Event that does not meet the minimum sanctioning requirements outlined in this Agreement. Local Organizing Club HEREBY WAIVES, RELEASES, and FOREVER DISCHARGES the USA-WSWS Parties from any and all claims, causes of action, damages, and losses arising out of Local Organizing Club&apos;s breach or failure to abide by any part of this Sanction Agreement.</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>19. No Agency. Local Organizing Club is not the agent of USA-WSWS. There is no intent to create an agency, partnership or joint venture relationship between USA-WSWS and Local Organizing Club of the Event. It is understood that Local Organizing Club is not employed by USA-WSWS and therefore USA-WSWS shall not provide worker&rsquo;s compensation coverage or any other benefits that would be provided to USA-WSWS employees.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>20. Modifications. This Agreement represents the entire agreement between both parties with respect to the sanctioning of the Event. No modification or amendment of this Agreement or waiver of any provision of this Agreement shall be valid unless it is set forth in writing and signed by all parties to this Agreement. Local Organizing Club shall not modify, nor shall Local Organizing Club allow anyone, including participants, to modify any other USA-WSWS documents, including, but not limited to the athlete&rsquo;s participant waiver and release form.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>21. Severability. If any provision of this Agreement shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>22. Headings. The sections and other headings contained in this Agreement are intended solely for convenience of reference and are not intended to be part of or affect the meaning or interpretation of this Agreement.&nbsp;</span></span></li>
<li><span class="font-13 align-items-baseline"><i class="fas fa-chevron-right mt-2"></i><span>23. Other. Local Organizing Club voluntarily agrees to these terms and acknowledges that it understands and accepts of all terms and conditions of this Agreement. This Agreement and its benefits are not transferable. WHEREFORE, intending to be legally bound by the terms hereof, the Local Organizing Club agrees to the terms of this Agreement on the date as indicated by his/her/its approval of this Agreement.&nbsp;</span></span></li>
</ul>
          
          
          <div  class="">
            <label class="form-check-label">
              <input type="checkbox" id="sanction_exhib_agree" class="form-check-input " :value="1" v-model="sanctionForm.exhibition_agree" name="sanctionForm.exhibition_agree">
              <span :style="{ 'display': 'inline-block' }" v-if="fields.fields.exhibition_agree_i" v-html="fields.fields.exhibition_agree_i"></span>
              <input class="onlybor-bot" type="text" v-on:keypress="isLetter($event)" style="width: 40px" id="club_sanction_exhibition_sign_name" name="sanctionForm.exhibition_sign_name" v-model="sanctionForm.exhibition_sign_name">
              <span :style="{ 'display': 'inline-block' }" v-if="fields.fields.exhibition_agree" v-html="fields.fields.exhibition_agree"></span><input type="text" class="datepicks" disabled :value="currentDate">
            </label>
            <span class="text-sm text-danger">{{ errors.exhibition_agree}}</span>
            <span class="text-sm text-danger">{{ errors.exhibition_sign_name}}</span>
          </div>
        </div>
                </b-card-text>
              </b-card-body>
    </b-card>
  </b-collapse>
</div>
          </div>
      </div>
   </div>
      <div v-else>
        <p class="text-sm text-danger"><br>Practice/Exhibition Sanctioning purchase is disabled now.</p>
      </div>
     </div>

<div class="col-md-12">
        <div class="text-right topspace">
          <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
          <button type="button" @click="SanctionAgreementCheck" class="btn btn-outline-primary savebtn mr-0 next-step-btn">{{ button.nextButton }}<i class="fas fa-chevron-right pl-2"></i></button>
          <span class="text-sm text-danger"><br>{{ clubSancError }}</span>
        </div>
    </div>
</div>
    </form>
</template>

<script>
import axios from 'axios';
import moment from "moment";
const validateName = name => {
    if (!name.length) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateStateName = name => {
    console.log('v state');
    console.log(name);
    if (name == null || name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    console.log('lenghth='+select);
    if (!select) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateCheck = choices => {
    if(choices.length) return { valid: true, error: null };
    return { valid: false, error: "This field is required" };
}

export default {
    name: "SanctionInformation",
    props: ['fields','button','sanctionFee'],
    data() {
        return{
          currentDate: moment().format("M/D/YYYY H:mm"),
          currentAgreement: 1,
          sanctionForm: {
            selected: false,
            practise_with_guest: 0,
            practise_without_guest: 0,
            exhibition: 0,
            practise_agree: 0,
            exhibition_agree: 0,
            practise_sign_name: '',
            exhibition_sign_name: '',
          },
          valid: false,
          success: false,
          errors: {},
          clubSancError: ''
        }
    },

     methods: {
         isLetter(e) {
           let char = String.fromCharCode(e.keyCode); // Get the character
           if(/^[A-Za-z ]+$/.test(char)) return true; // Match with regex
           else e.preventDefault(); // If not match, don't add to input text
         },
        setCurrentAgreement(value) {
          this.currentAgreement
        },
         previousStep() {
             this.$emit('previous-step');

             return;

         },
         SanctionAgreementCheck: function(){
           this.errors = {}
           let practise = true;
           let exhibition = true;
           let sanction = false;
           this.sanctionForm.selected = false;
           console.log('mmmmmmmmmmmmmmmmm');
           console.log(this.sanctionForm);
           if(this.sanctionForm.practise_without_guest == 1 || this.sanctionForm.practise_with_guest == 1 || this.sanctionForm.exhibition == 1) {
             sanction = true;
           }
           if(sanction == true && this.sanctionForm.practise_agree == 0) {
             this.errors.practise_agree = "Please acknowledge by checking the box";
             practise = false;
           }
           if(sanction == true && this.sanctionForm.practise_sign_name == '') {
               this.errors.practise_sign_name = "Please enter your name to proceed";
               practise = false;
             }
           if(sanction == true && this.sanctionForm.exhibition_agree == 0) {
             this.errors.exhibition_agree = "Please acknowledge by checking the box";
             exhibition = false;
           }
           if(sanction == true && this.sanctionForm.exhibition_sign_name == '') {
             this.errors.exhibition_sign_name = "Please enter your name to proceed";
             exhibition = false;
           }
           if(practise && exhibition) {
             if(this.sanctionForm.practise_without_guest == 1 || this.sanctionForm.practise_with_guest == 1 || this.sanctionForm.exhibition == 1) {
               this.sanctionForm.selected = true;
             }
             this.$emit('sanction-data', this.sanctionForm);
             this.$emit('next-step');
           }
         }
    },
    mounted() {
      this.getClubType();
      if(this.subdomain == "shooting"){
          this.getClubDisciplines();
      }
    }
}


</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
