<template>
  <form @submit.prevent="addonsAmountData" method="post">
    <div class="card addons">
      <div class="card-header register-title">
        <h3>{{ fields.tab_title || 'Add ons'}} (Optional)</h3>
      </div>
      <div class="card-body pr-4 pl-4">
        <div class="row">
          <div class="row" v-if="fields.alfa_donation">
            <h4 class="subtitle">{{ fields.alfa_donation.title || 'Donation'}}</h4>
            <div  class="imgwithinline">
              <img v-if="fields.alfa_donation.banner && fields.alfa_donation.banner.element && fields.alfa_donation.banner.element == 'img'" :src="fields.alfa_donation.banner.attributes.src" :class="fields.alfa_donation.banner.class">
                <div class="form-text">
                  <span v-html="fields.alfa_donation.content"></span>
                </div>              
            </div>
            <div class="col-md-12">
              <div :class="[fields.alfa_donation.colClass]">
                <div class="form-group">
                  <label for="alfa_donation" class="form-label">{{ fields.alfa_donation.label }}</label>
                  <div class="form-group " v-if="fields.alfa_donation.choices">
                    <template v-for="(choice, key) in fields.alfa_donation.choices">
                      <div class="inl-item" :key="'alfa_donation_br_'+key">
                      <input type="radio" :id="choice.value" v-model="form.donation_amount" name="form.alfa_donation" :value="choice.value" @click="CheckDonation(choice.value)" :key="'radio_' + key">
                      <label class="alfa_donation-label-span" :for="choice.value" :key="'alfa_donation_label_'+key">{{ choice.label }}</label>
                       <br :key="'alfa_donation_br_'+key"> 
                      </div>
                    </template>
                    <input class="custom-input" v-if="donationInputSelected=='null'" v-show="donationInputSelected=='null'" :key="'alfa_donation_input_'+key" type="text" v-model="form.added_donation_amount">
                    <span class="text-sm text-danger">{{ errors.alfa_donation}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row" v-if="fields.agl_package && getSiteProps('general.addons_enable')">
            <h4 class="subtitle">{{ fields.agl_package.title || 'AGL Package'}}</h4>
            <div class="imgwithinline">
              <img v-if="fields.agl_package.banner && fields.agl_package.banner.element && fields.agl_package.banner.element == 'img'" :src="fields.agl_package.banner.attributes.src" :class="fields.agl_package.banner.class">
              <div class="form-text mb-2">
                 <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla dignissim efficitur. Cras vehicula, massa nec facilisis aliquet, velit libero varius ex, id pretium risus quam eu eros. Cras at fermentum massa, sit amet sodales elit. Nunc et quam ante. Vestibulum vel molestie metus. Sed ac ultricies mi. Sed varius enim at lobortis condimentum.</p></span>
              </div> 
            </div>
            <div class="col-md-12">
              <div :class="[fields.agl_package.colClass]">
                <div class="form-group">
                  <label for="agl_package" class="form-label">{{ fields.agl_package.label }}</label>
                  <div class="form-group" v-if="fields.agl_package.choices">
                    <div class="row">
                      <div class="mb-1" :class="'col-md-2'" v-for="(choice, key) in fields.agl_package.choices" :key="'agl_package_'+key">
                        <input type="radio" :id="choice.value" v-model="form.agl_package_amount" name="form.agl_package" :value="choice.value" :key="'radio_' + key">
                        <label class="agl_package-label-span" :for="choice.value" :key="'agl_package_'+key">{{ choice.label }}</label>
                      </div>
                    </div>
                    <span class="text-sm text-danger">{{ errors.agl_package}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row" v-if="fields.usssa_membership_package && getSiteProps('general.addons_enable')">
            <h4 class="subtitle">{{ fields.usssa_membership_package.title || 'USSSA Membership Package'}}</h4>
            <div class="imgwithinline">
              <img v-if="fields.usssa_membership_package.banner && fields.usssa_membership_package.banner.element && fields.usssa_membership_package.banner.element == 'img'" :src="fields.usssa_membership_package.banner.attributes.src" :class="fields.usssa_membership_package.banner.class">
              <div class="form-text mb-2">
                   <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla dignissim efficitur. Cras vehicula, massa nec facilisis aliquet, velit libero varius ex, id pretium risus quam eu eros. Cras at fermentum massa, sit amet sodales elit. Nunc et quam ante. Vestibulum vel molestie metus. Sed ac ultricies mi. Sed varius enim at lobortis condimentum.</p></span>
              </div> 
            </div>
            <div class="col-md-12">
              <div :class="[fields.usssa_membership_package.colClass]">
                <div class="form-group">
                  <label for="usssa_membership_package" class="form-label">{{ fields.usssa_membership_package.label }}</label>
                  <div class="form-group" v-if="fields.usssa_membership_package.choices">
                    <div class="row">
                      <div class="mb-1" :class="'col-md-2'" v-for="(choice, key) in fields.usssa_membership_package.choices" :key="'usssa_membership_package_'+key">
                        <input type="radio" :id="choice.value" v-model="form.usssa_membership_package_amount" name="form.usssa_membership_package" :value="choice.value" :key="'radio_' + key">
                        <label class="usssa_membership_package-label-span" :for="choice.value" :key="'usssa_membership_package_'+key">{{ choice.label }}</label>
                      </div>
                    </div>
                    <span class="text-sm text-danger">{{ errors.usssa_membership_package}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>          
          <div class="row" v-if="fields.team_insurance && getSiteProps('general.addons_enable')">
            <h4 class="subtitle">{{ fields.team_insurance.title || 'Team Insurance'}}</h4>
            <!-- <div class="imgwithinline">
              <img v-if="fields.team_insurance.banner && fields.team_insurance.banner.element && fields.team_insurance.banner.element == 'img'" :src="fields.team_insurance.banner.attributes.src" :class="fields.team_insurance.banner.class">
              <div class="form-text mb-2">
                  <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla dignissim efficitur. Cras vehicula, massa nec facilisis aliquet, velit libero varius ex, id pretium risus quam eu eros. Cras at fermentum massa, sit amet sodales elit. Nunc et quam ante. Vestibulum vel molestie metus. Sed ac ultricies mi. Sed varius enim at lobortis condimentum.</p></span>
              </div>
            </div> -->
            <div class="col-md-12">
              <div :class="[fields.team_insurance.colClass]">
                <div class="form-group">
                  <input type="checkbox" class="" :id="'team-insurance'" v-model="form.team_insurance_amount" @change="isCheck($event)">
                  <label for="team_insurance" class="form-label ml-2">{{ fields.team_insurance.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 pr-0">
          <div class="text-right topspace">
            <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
            <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">{{ button.nextButton }}<i class="fas fa-chevron-right pl-2"></i></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
const validateAmount = name => {
  if (!name.length) {
    return { valid: false, error: "This field is required" };
  } else if(name == '') {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};

const validateSelect = select => {
  if (!select) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
export default {
  name: "addons",
  props: ['button', 'fields','updatedVerifiedDetails'],
  data() {
    return {
      form: {
        donation_amount: '',
        donation_discipline: '0',
        team_insurance_amount: 0,
        agl_package_amount: '',
        usssa_membership_package_amount: '',
        added_donation_amount:null
      },
      valid: false,
      success: false,
      errors: {},
      message: null,
      donationInputSelected:false,
      previouslySelected:null
    }
  },
  methods: {
    addonsAmountData() {

      if(this.validate()) {
        console.log('valid');
        /*if(this.form.added_donation_amount !== null){
          this.form.donation_amount=this.form.added_donation_amount;
        }*/
        //this.button.nextStepButton = 'Payment is in progress...';
        this.$emit('addons-amount-data', this.form);
        //this.button = 'Next';
        return;
      } else {
        console.log('un valid');
      }
    },
    previousStep() {
      this.$emit('previous-step');
      return;
    },
    isCheck(e){
      this.$nextTick(() => {
        if(e.target.checked){
          this.form.team_insurance_amount = this.fields.team_insurance.amount;
        }
      })
    },
    // Custom Validation Rules
    validate() {
      this.errors = {};
      this.valid = true;
      let insurance = 0.0;
      return this.valid;
    },
    amountFormat() {
      let s = this.form.addons_amount;
      var rgx = /^[0-9]*\.?[0-9]*$/;
      this.form.donation_amount = s.match(rgx);
    },
    CheckDonation(val){

      if (val === this.previouslySelected) {
        this.form.donation_amount = false;
      }
      this.donationInputSelected = val;
      console.log("txt",this.donationInputSelected)
      this.previouslySelected = this.form.donation_amount;
    }
  },
  mounted() {
    if(this.updatedVerifiedDetails != undefined && this.updatedVerifiedDetails.addons != undefined){
      this.form.donation_amount = this.updatedVerifiedDetails.addons.donation_amount;
      this.form.added_donation_amount = this.updatedVerifiedDetails.addons.added_donation_amount;
      this.form.agl_package_amount = this.updatedVerifiedDetails.addons.agl_package;
      this.form.usssa_membership_package_amount = this.updatedVerifiedDetails.addons.usssa_package;
      this.form.team_insurance_amount = this.updatedVerifiedDetails.addons.team_insurance;
      this.donationInputSelected = this.updatedVerifiedDetails.addons.donationInputSelected;
    }
    this.addonsEnable = this.getSiteProps('team_registrations.addons_enable');
  }
}
</script>

<style scoped>
.inl-item{
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.95rem;
    vertical-align: middle;;
}
h4.subtitle {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
}
.inl-item label {
    margin-bottom: 0;
}
.imgwithinline img {
    width: 100% !important;
    float: left;
    max-width: 163px;
    height: 100px;
    margin-right: 19px;
}
.imgwithinline span p {
    text-align: justify;
}
</style>