<template>
    <div class="row privacy_sec">
        <div class="col-md-12">
            <div class="text-sm-center text-md-left float-left">
                <router-link to="/"><a>Privacy policy</a></router-link>
            </div>
            <div class="text-sm-center text-md-right float-right">
                <router-link to="/"><a>Terms and Condition</a></router-link>
            </div>
            <div class="clearfix"></div>
            <p class="mt-2 mb-0">©{{new Date().getFullYear()}} Integrass. All rights reserved.</p>
        </div>
    </div>
</template>

<script>
export default {
name: "AuthFoot"
}
</script>

<style scoped>

</style>
