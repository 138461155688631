<template>
    <div class="dashboard-wrapper">
        <Sidebar></Sidebar>
        <Navigation></Navigation>
         <div class="content-wrapper table-area">
              <div class="row pt-4">
                <div class="col-md-12">
                  
                  <div class="card card-body">
            <div class="row">
                <div class="col-md-12">
                  <h1 class="m-0 text-dark pt-2 pb-2">Reset Password</h1>
                  <div class="form-group"><label class="form-label">Existing Password<sup class="text-danger">*</sup></label> <input type="password" class="form-control"></div>
                </div>
                <div class="col-md-12">
                  <div class="form-group"><label class="form-label">Change Password<sup class="text-danger">*</sup></label> <input type="password" class="form-control"></div>
                </div>
                <div class="col-md-12">
                  <div class="form-group"><label class="form-label">Confirm Password<sup class="text-danger">*</sup></label> <input type="password" class="form-control"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="text-left topspace"><button class="btn btn-outline-primary savebtn">Cancel</button> <button class="btn btn-outline-primary savebtn next-step-btn">Save</button></div>
                </div>
            </div>
          </div>
                  </div>
              </div>
          </div>
          <Footer></Footer>
      </div>


</template>

<script>
  import Sidebar from "./components/Sidebar";
  import Navigation from "./components/Navigation";
  import Footer from "../Member/Dashboard/components/Footer";

  export default {
      components: { Sidebar, Navigation, Footer },
    data() {
      return {

            isBusy: false,
          primarycontactfields: [
          {
            key: 'name',
            sortable: true
          },

          {
            key: 'email',
            sortable: true,
          },
          {
            key: 'role',
            sortable: true,
          },
          {
            key: 'actions',
            sortable: false,
          },
        ],
        items: [
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},

          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},
          { name: 'Dickerson', email: 'admin@integrass.com', role:'Super Admin'},

                  ]
        }
     },
     methods: {

     }
    }

</script>
