<template>
<div class="card club-info">
   <div class="card-header register-title">
      <h3>{{ fields.tab_title || 'Club Leadership'}}</h3>
   </div>

<div class="card-body addnewbutn pr-4 pl-4">
      <span class="text-justify mb-2 d-block" v-if="fields.description" v-html="fields.description"></span>

<b-button v-b-modal.modal-center-ski-club-officer v-if="clubOfficerContact.presidentContactAdded == false" @click="captureCurrentContact(presidentContact.contact_type)">+ Add President</b-button>
<div v-if="clubOfficerContact.presidentContactAdded == true" class="memberdetail-section">
    <div class="row tab-cont">
        <div class="col-md-6">
            <h3 class="mb-3">{{ fields.president.title }}</h3>
        </div>
        <div  class="d-flex col-md-6 text-right">
            <button type="button" class="btn-remove" @click="removeClubOfficerContact(presidentContact.contact_type)">
                <img src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove
            </button>
        </div>
    </div>
    <div  class="row juniordevlopment">
        <div  class="col-md-12">
            <div class="form-group">
                <label class="form-label">{{ fields.president.search_by_member.member_id.label }}</label>
                <span class="title">{{ presidentContact.membership_id }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.president.search_by_name.first_name.label }}</label>
                <span class="title">{{ presidentContact.first_name }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.president.search_by_name.last_name.label }}</label>
                <span class="title">{{ presidentContact.last_name }}</span>
            </div>
        </div>
    </div>
</div>


<b-button v-b-modal.modal-center-ski-club-officer v-if="clubOfficerContact.vicePresidentContactAdded == false" @click="captureCurrentContact(vicePresidentContact.contact_type)">+ Add Vice President</b-button>
<div v-if="clubOfficerContact.vicePresidentContactAdded == true" class="memberdetail-section">
    <div  class="row tab-cont">
        <div  class="col-md-6">
            <h3  class="mb-3">{{ fields.vice_president.title }}</h3>
        </div>
        <div  class="d-flex col-md-6 text-right">
            <button type="button"  class="btn-remove" @click="removeClubOfficerContact(vicePresidentContact.contact_type)"><img  src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove</button>
        </div>
    </div>
    <div  class="row juniordevlopment">
        <div  class="col-md-12">
            <div class="form-group">
                <label class="form-label">{{ fields.vice_president.search_by_member.member_id.label }}</label>
                <span class="title">{{ vicePresidentContact.membership_id }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.vice_president.search_by_name.first_name.label }}</label>
                <span class="title">{{ vicePresidentContact.first_name }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.vice_president.search_by_name.last_name.label }}</label>
                <span class="title">{{ vicePresidentContact.last_name }}</span>
            </div>
        </div>
    </div>
</div>


<b-button v-b-modal.modal-center-ski-club-officer v-if="clubOfficerContact.secretaryContactAdded == false" @click="captureCurrentContact(secretaryContact.contact_type)">+ Add Secretary</b-button>
<div v-if="clubOfficerContact.secretaryContactAdded == true" class="memberdetail-section">
    <div  class="row tab-cont">
        <div  class="col-md-6">
            <h3  class="mb-3">{{ fields.secretary.search_by_member.member_id.label }}</h3>
        </div>
        <div  class="d-flex col-md-6 text-right">
            <button type="button"  class="btn-remove" @click="removeClubOfficerContact(secretaryContact.contact_type)"><img  src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove</button>
        </div>
    </div>
    <div  class="row juniordevlopment">
        <div  class="col-md-12">
            <div class="form-group">
                <label class="form-label">{{ fields.secretary.search_by_name.first_name.label }}</label>
                <span class="title">{{ secretaryContact.membership_id }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.secretary.search_by_name.first_name.label }}</label>
                <span class="title">{{ secretaryContact.first_name }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.secretary.search_by_name.last_name.label }}</label>
                <span class="title">{{ secretaryContact.last_name }}</span>
            </div>
        </div>
    </div>
</div>



<b-button v-b-modal.modal-center-ski-club-officer v-if="clubOfficerContact.treasurerContactAdded == false" @click="captureCurrentContact(treasurerContact.contact_type)">+ Add Treasurer</b-button>
    <span class="text-sm text-danger">{{ errors.primary }}</span>
    <div v-if="clubOfficerContact.treasurerContactAdded == true" class="memberdetail-section">
    <div  class="row tab-cont">
        <div  class="col-md-6">
            <h3  class="mb-3">{{ fields.treasurer.search_by_member.member_id.label }}</h3>
        </div>
        <div  class="d-flex col-md-6 text-right">
            <button type="button"  class="btn-remove" @click="removeClubOfficerContact(treasurerContact.contact_type)"><img  src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove</button>
        </div>
    </div>
    <div  class="row juniordevlopment">
        <div  class="col-md-12">
            <div class="form-group">
                <label class="form-label">{{ fields.treasurer.search_by_name.first_name.label }}</label>
                <span class="title">{{ treasurerContact.membership_id }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.treasurer.search_by_name.first_name.label }}</label>
                <span class="title">{{ treasurerContact.first_name }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
                <label class="form-label">{{ fields.treasurer.search_by_name.last_name.label }}</label>
                <span class="title">{{ treasurerContact.last_name }}</span>
            </div>
        </div>
    </div>
</div>




<b-modal  ref="modal"
          @show="resetModal"
          @hidden="resetModal" id="modal-center-ski-club-officer" size="xl" v-bind:hide-footer="true" centered :title="fields.title || 'Club Leadership'">
<div class="card-body">
<div class="row tab-cont">
    <div class="col-md-4">
        <label><strong>Search by Member#</strong></label>
        <div class="form-group">
            <div class="search-left">
                <label class="form-label"> Member#</label>
                <input type="text" class="form-control" id="searchbyname"  name="memberLookupForm.member_id" v-model="memberLookupForm.member_id" >
                <span class="text-sm text-danger">{{ errors.member_id }}</span>
            </div>
        </div>
    </div>
    <div class="col-md-12 text-center">
        <strong>OR</strong>
    </div>
    <div class="col-md-12">
    <label><strong>Search by Name</strong></label>
    </div>
    <div class="col-md-3">

        <div class="form-group">
            <label for="firstname" class="form-label">First Name</label>
            <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="memberLookupForm.first_name" v-model="memberLookupForm.first_name" >
            <span class="text-sm text-danger">{{ errors.first_name }}</span>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <label for="lastname" class="form-label">Last Name</label>
            <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="memberLookupForm.last_name" v-model="memberLookupForm.last_name" >
            <span class="text-sm text-danger">{{ errors.last_name }}</span>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <label for="city" class="form-label">City</label>
            <input type="text" class="form-control" maxlength="45" placeholder="New Alessiaview" name="memberLookupForm.city" v-model="memberLookupForm.city" >
            <span class="text-sm text-danger">{{ errors.city }}</span>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
        <label for="state" class="form-label">State</label>
            <select class="form-control" id="state" name="memberLookupForm.state" v-model="memberLookupForm.state">
                <option value="">Please select</option>
                <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
            </select>
            <span class="text-sm text-danger">{{ errors.state }}</span>
        </div>
    </div>
    <div class="col-md-12 modal-searchbtn text-center">
        <div class="form-group">
        <button type="button" @click="memberSearchClubOfficer();" class="btn-primary btn btn-blue">{{ button.SearchButton }}</button>
        </div>
        <div class="text-sm text-danger">{{ errors.not_found }}</div>
    </div>
    </div>
</div>
<div>
    <div v-if="memberSerchResultStatus == true" class="card-body primary-contact">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Member#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(member, r) in memberSearchClubOfficerResults" :key="r">
                    <th scope="row">{{ member.confirmation_code }}</th>
                    <td>{{ member.personal_details.first_name }}</td>
                    <td>{{ member.personal_details.last_name }}</td>
                    <td>{{ member.contact_details.email }}</td>
                    <td>{{ member.contact_details.phone_1 }}</td>
                    <td>{{ member.contact_details.city }}</td>
                    <td>{{ member.contact_details.state_code }}</td>
                  <td>{{ member.membership_status_text }}</td>
                    <td><button class="btn btn-primary btn-success" @click="pushSelectedMember(member)">Add</button></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</b-modal>
</div>
    <div class="">
        <div class="col-md-12">
            <div class="text-right topspace">
                <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                <button type="button" @click="ClubContactCheck" class="btn btn-outline-primary savebtn next-step-btn">Next<i class="fas fa-chevron-right pl-2"></i></button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';

const validateName = name => {
    if (name == undefined) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateSelect = select => {
    console.log('lenghth='+select);
    if (select == undefined) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
export default {
    name: "ClubOfficers",
    props: ['clubMemberContactLength', 'fields'],
data() {
        return{
            button: {
                SearchButton: 'Search'
            },
            memberLookupForm: {
                member_id: '',
                first_name: '',
                last_name: '',
                city: '',
                state: ''
            },
            memberSerchResults: [],
            memberSerchResultStatus: false,
            presidentContactAdded: false,
            vicePresidentContactAdded: false,
            secretaryContactAdded: false,
            treasurerContactAdded: false,
            presidentContact : {
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                membership_id: '',
                contact_type: 2
            },
            vicePresidentContact : {
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                membership_id: '',
                contact_type: 3
            },
            secretaryContact : {
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                membership_id: '',
                contact_type: 4
            },
            treasurerContact : {
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                membership_id: '',
                contact_type: 5
            },
            form: [],
            currentContact: '',
            clubOfficerContact: {
                presidentContactAdded: false,
                vicePresidentContactAdded: false,
                secretaryContactAdded: false,
                treasurerContactAdded: false
            },
            clubOfficerData: {},
            clubMemberContactLengthLocal: 0,
            valid: true,
            success: false,
            errors: {},
            message: null
        }
    },
     methods: {
         memberSearchClubOfficer: function () {
             if(!this.validate()) {
                 return;
             }
             console.log(this.memberLookupForm);
             this.button.SearchButton = 'Searching...';
             this.errors= {};
             axios.get(this.basePath+'api/member_lookup?member_id='+this.memberLookupForm.member_id+'&first_name='+this.memberLookupForm.first_name+'&last_name='+this.memberLookupForm.last_name+'&city='+this.memberLookupForm.city+'&state='+this.memberLookupForm.state)
                 .then((res) => {
                     console.log('status='+res.data.status);
                     if(res.data.status == 'success') {
                         this.button.SearchButton = 'Search';
                         this.memberSerchResultStatus = true;
                         console.log(res.data.data);
                         console.log('-------------');
                         this.memberSearchClubOfficerResults = res.data.data;
                         console.log('-------1------');
                         console.log(this.memberSearchClubOfficerResults);
                         //Perform Success Action
                         //alert('success')
                         /*console.log('verify success');
                         this.verifyData = res.data.data;
                         //this.merchandiseDataApi();
                         //console.log(this.verifyData);
                         return res;*/
                     } else {
                         this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                         this.button.SearchButton = 'Search';
                         return false;
                     }
                 })
                 .catch((error) => {
                     console.log('Error:'+ error);
                     this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                     //this.step--;
                     this.button.SearchButton = 'Search';
                     return false;
                     // error.response.status Check status code
                 }).finally(() => {


             });
         },
         pushSelectedMember: function(memberData){
             console.log("member length="+this.clubMemberContactLength.length);
             console.log('----my m;embetd---------');
             console.log(memberData);
             this.getOfficerContact(memberData);
             console.log('----my clubOfficerData---------');
             console.log(this.clubOfficerData);
             this.$emit('club-officers-data', this.clubOfficerData);
             this.valid = true;
             this.$nextTick(() => {
                 this.$bvModal.hide('modal-center-ski-club-officer')
             })
             this.errors.primary = '';
         },
         ClubContactCheck: function(){
             this.errors = {}
             console.log('this contact check');
             console.log(this.valid);
             if(this.valid) {
                 console.log("1"+this.valid);
                 this.errors.primary = '';
                 this.$emit('next-step');
                 return;
             } else {
                 console.log("2"+this.valid);
                 this.errors.primary = 'Club Leadership contact is required!';
             }
         },
         captureCurrentContact(contactType) {
             this.currentContact = contactType;
         },
         removeClubOfficerContact(contactType) {
             console.log('----removed ---------'+contactType);
             console.log(this.clubOfficerData);
             this.removeOfficerContact(contactType);
             console.log('----removed -sdfsd data-');
             console.log(this.clubOfficerData);
             this.$emit('club-officers-data', this.clubOfficerData);
         },
         getOfficerContact(memberDataDetail) {
             console.log('----44----');
             console.log(this.clubMemberContactLength.length);
             console.log('----55----');
             console.log(this.currentContact);
             console.log('----66----');

             if(this.currentContact == 2) {
                 this.clubOfficerContact.presidentContactAdded = true;
                 let memberContact = memberDataDetail.contact_details;
                 this.presidentContact.membership_id = memberDataDetail.confirmation_code;
                 this.presidentContact.first_name = memberDataDetail.personal_details.first_name;
                 this.presidentContact.last_name = memberDataDetail.personal_details.last_name;
                 this.presidentContact.email = memberContact.email;
                 this.presidentContact.address1 = memberContact.address1;
                 this.presidentContact.address2 = memberContact.address2;
                 this.presidentContact.city = memberContact.city;
                 this.presidentContact.state = memberContact.state;
                 this.presidentContact.state_code = memberContact.state_code;
                 this.presidentContact.country = memberContact.country;
                 this.presidentContact.zip = memberContact.zip;
                 this.presidentContact.phone_1 = memberContact.phone_1;
                 this.presidentContact.phone_2 = memberContact.phone_2;
                 this.clubOfficerData = this.presidentContact;
                 this.clubOfficerData.index = this.clubMemberContactLength.length;
             } else if(this.currentContact == 3) {
                 this.clubOfficerContact.vicePresidentContactAdded = true;
                 let memberContact = memberDataDetail.contact_details;
                 this.vicePresidentContact.membership_id = memberDataDetail.confirmation_code;
                 this.vicePresidentContact.first_name = memberDataDetail.personal_details.first_name;
                 this.vicePresidentContact.last_name = memberDataDetail.personal_details.last_name;
                 this.vicePresidentContact.email = memberContact.email;
                 this.vicePresidentContact.address1 = memberContact.address1;
                 this.vicePresidentContact.address2 = memberContact.address2;
                 this.vicePresidentContact.city = memberContact.city;
                 this.vicePresidentContact.state = memberContact.state;
                 this.vicePresidentContact.state_code = memberContact.state_code;
                 this.vicePresidentContact.country = memberContact.country;
                 this.vicePresidentContact.zip = memberContact.zip;
                 this.vicePresidentContact.phone_1 = memberContact.phone_1;
                 this.vicePresidentContact.phone_2 = memberContact.phone_2;
                 this.clubOfficerData = this.vicePresidentContact;
                 this.clubOfficerData.index = this.clubMemberContactLength.length;
             } else if(this.currentContact == 4) {
                 this.clubOfficerContact.secretaryContactAdded = true;
                 let memberContact = memberDataDetail.contact_details;
                 this.secretaryContact.membership_id = memberDataDetail.confirmation_code;
                 this.secretaryContact.first_name = memberDataDetail.personal_details.first_name;
                 this.secretaryContact.last_name = memberDataDetail.personal_details.last_name;
                 this.secretaryContact.email = memberContact.email;
                 this.secretaryContact.address1 = memberContact.address1;
                 this.secretaryContact.address2 = memberContact.address2;
                 this.secretaryContact.city = memberContact.city;
                 this.secretaryContact.state = memberContact.state;
                 this.secretaryContact.state_code = memberContact.state_code;
                 this.secretaryContact.country = memberContact.country;
                 this.secretaryContact.zip = memberContact.zip;
                 this.secretaryContact.phone_1 = memberContact.phone_1;
                 this.secretaryContact.phone_2 = memberContact.phone_2;
                 this.clubOfficerData = this.secretaryContact;
                 this.clubOfficerData.index = this.clubMemberContactLength.length;
             } else if(this.currentContact == 5) {
                 this.clubOfficerContact.treasurerContactAdded = true;
                 let memberContact = memberDataDetail.contact_details;
                 this.treasurerContact.membership_id = memberDataDetail.confirmation_code;
                 this.treasurerContact.first_name = memberDataDetail.personal_details.first_name;
                 this.treasurerContact.last_name = memberDataDetail.personal_details.last_name;
                 this.treasurerContact.email = memberContact.email;
                 this.treasurerContact.address1 = memberContact.address1;
                 this.treasurerContact.address2 = memberContact.address2;
                 this.treasurerContact.city = memberContact.city;
                 this.treasurerContact.state = memberContact.state;
                 this.treasurerContact.state_code = memberContact.state_code;
                 this.treasurerContact.country = memberContact.country;
                 this.treasurerContact.zip = memberContact.zip;
                 this.treasurerContact.phone_1 = memberContact.phone_1;
                 this.treasurerContact.phone_2 = memberContact.phone_2;
                 this.clubOfficerData = this.treasurerContact;
                 this.clubOfficerData.index = this.clubMemberContactLength.length;
             }
         },
         removeOfficerContact(contactType) {
             console.log('----441----');
             console.log(contactType);
             console.log('----551----');
             console.log(this.currentContact);
             console.log('----661----');
             if(contactType == 2) {
                 this.clubOfficerContact.presidentContactAdded = false;
                 this.presidentContact = {};
                 console.log(this.presidentContact);
                 this.clubOfficerData = this.presidentContact;
             } else if(contactType == 3) {
                 this.clubOfficerContact.vicePresidentContactAdded = false;
                 this.vicePresidentContact = {};
                 this.clubOfficerData = this.vicePresidentContact;
                 console.log(this.vicePresidentContact);
             } else if(contactType == 4) {
                 this.clubOfficerContact.secretaryContactAdded = false;
                 this.secretaryContact = {};
                 this.clubOfficerData = this.secretaryContact;
                 console.log(this.secretaryContact);
             } else if(contactType == 5) {
                 this.clubOfficerContact.treasurerContactAdded = false;
                 this.treasurerContact = {};
                 this.clubOfficerData = this.treasurerContact;
                 console.log(this.treasurerContact);
             }
         },
         resetModal() {
             this.memberSerchResultStatus = false;
             this.memberSearchClubOfficerResults = [];
             this.memberLookupForm = {}
         },
         previousStep() {
             this.$emit('previous-step');
             return;
         },
         // Custom Validation Rules
         validate() {
             this.errors = {}
             console.log('------validate-------');
             console.log(this.memberLookupForm);
             console.log(this.memberLookupForm.member_id);
             const member_id = this.memberLookupForm.member_id;
             const first_name = this.memberLookupForm.first_name;
             const last_name = this.memberLookupForm.last_name;
             const city = this.memberLookupForm.city;
             const state = this.memberLookupForm.state;
             if(member_id == undefined && first_name == undefined && last_name == undefined && city == undefined && state == undefined) {
                 this.errors.member_id = 'Plesse Enter Either Member Id or Below Member Details';

                 return this.valid;
             }
             if(member_id == undefined && (first_name == undefined || last_name == undefined || city == undefined || state == undefined)) {
                 const validFirstName = validateName(this.memberLookupForm.first_name);
                 this.errors.first_name = validFirstName.error;

                 const validLastName = validateName(this.memberLookupForm.last_name);
                 this.errors.last_name = validLastName.error;

                 const validCity = validateSelect(this.memberLookupForm.city);
                 this.errors.city = validCity.error;

                 const validStateCode = validateSelect(this.memberLookupForm.state);
                 this.errors.state = validStateCode.error;

                 return this.valid;
             }

             this.valid = true;
             this.errors= {}

             return this.valid;
         }
     }

}
</script>

<style scoped>

</style>
