<template>
<div class="freezeheader">
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
    <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">  
        <a class="nav-link" href="/admin/dashboard">Home</a>        
          
     </li> 
      <li class="nav-item" v-if="getSiteProps('general.global_search')">
        <div class="navbar-search-block pt-1 mr-3">
          <b-form @submit="globalSearch">
            <div class="input-group input-group-sm">
              <b-form-input v-model="qs" placeholder="Search" class="form-control form-control-navbar" aria-label="Search" id="globalSearch"></b-form-input>
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </b-form>
        </div>
      </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto rightnavbar">
            <li class="nav-item top-barnav w-phone-hide" v-if="subdomain == 'waterski'">
                <a class="nav-link logout" data-widget="control-sidebar"  href="tel:863-324-4341" >
                <img style="width:18px;" src="/dist/img/ico-phone.svg"> 863-324-4341
                </a>
            </li>
            <li class="nav-item top-barnav w-email-hide" v-if="subdomain == 'waterski'">
                <a class="nav-link logout" data-widget="control-sidebar" href="mailto:memberservices@usawaterski.org">
                <img style="width:18px;" src="/dist/img/icon-mail.svg"> memberservices@usawaterski.org
                </a>
            </li>
            <!-- <li class="nav-item" >
                <div class="user-panel pt-1 pb-1 d-flex">
                    <div class="image">
                        <img src="/dist/img/avatarimg.png" alt="User Image" class="img-circle">
                    </div>
                  <div class="info">
                        <a href="#" class="d-block text-capitalize">{{ adminInfoData }}</a>
                  </div>
               </div>
            </li>
            <li class="nav-item">
                <a class="nav-link logout" data-widget="control-sidebar" @click="logout" data-slide="true" href="#" role="button">
                <img style="width:18px;" src="/dist/img/ico-logout.svg"> Log Out
                </a> 
            </li>   -->
    </ul>  
          <div class="d-flex align-items-center profile-dropdown">
        <div class="image">
            <b-avatar src="/dist/img/avatarimg.png" size="2em"></b-avatar> 
        </div>
        <b-dropdown id="dropdown-1" :text="adminInfoData" right  class="m-md-2">
            <a class="nav-link logout" data-widget="control-sidebar" @click="logout" data-slide="true" href="#" role="button"><img style="width:18px;" src="/dist/img/ico-logout.svg"> Log Out</a> 
        </b-dropdown>
      </div>
</nav>

</div>
</template>

<script>
import moment from "moment";

export default{
    name: 'adminNavigation',
  data() {
    return {
      adminInfoData: '',
      prop_footer: {},
      qs:'',
      urlSegment: ''
    }
  },
    methods:{
        logout() {
            localStorage.removeItem('admintoken');
            localStorage.removeItem('admin');
            localStorage.removeItem('impersonateId');
            this.$router.push('/login');
        },
        globalSearch(event){
          event.preventDefault();
          let redirectURL = this.getSiteProps("globalSearch.redirect");
          const url = window.location.href;
          const segments = url.split('/'); // Split the URL into segments
          var valindex = 2;
          if(segments.length == 5){
            valindex = valindex-1;
          }
          else if (url.indexOf('?') !== -1) {
            valindex = valindex+1;
          }
          this.urlSegment = segments[segments.length - valindex];
          if(['club-registrations', 'club','team-registrations', 'team'].includes(this.urlSegment)) {
            redirectURL = this.getSiteProps("globalSearch.clubredirect");
          }else if(['campski-registrations', 'campski'].includes(this.urlSegment)){
            redirectURL = this.getSiteProps("globalSearch.campskiredirect");
          }
          this.$router.push({
            path: redirectURL,
            query: { term: this.qs.trim() },
          });
        }
    },
    mounted() {
        if(localStorage.getItem('admintoken') == null){
            this.$router.push('/login');
        }

        this.adminInfoData = JSON.parse(localStorage.getItem('admin'));
         this.prop_footer = this.getSiteProps('memberRegistration.footer');
    }
}
</script>
<style>
.freezeheader {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 999; /* Set a high z-index to ensure the header appears on top of other elements */
}
</style>