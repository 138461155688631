<template>
  <div class="z-value">
    <div class=" bg-light h-100">
      <div class="container-fluid">
        <div class="row">
          <div
              class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top"
          >
            <banner></banner>
          </div>
          <div
              class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-5 bg-white rightside_section"
          >
            <div
                class=" mb-3  border-radius-0 border-0 hvertical"
                style="padding:100px 0 0 0;background: #fff;"
            >
              <div class="">
                <div class="card-body">
                  <div class="svg-align">
                    <svg width="150px" height="150px"  viewBox="0 0 48 48" version="1"
                         xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
                      <circle fill="#263238" cx="17" cy="17" r="14"/>
                      <circle fill="#eee" cx="17" cy="17" r="11"/>
                      <rect x="16" y="8" width="2" height="9"/>
                      <rect x="18.2" y="16" transform="matrix(-.707 .707 -.707 -.707 46.834 19.399)" width="2.4" height="6.8"/>
                      <circle cx="17" cy="17" r="2"/>
                      <circle fill="#263238" cx="17" cy="17" r="1"/>
                      <path fill="#FFC107" d="M11.9,42l14.4-24.1c0.8-1.3,2.7-1.3,3.4,0L44.1,42c0.8,1.3-0.2,3-1.7,3H13.6C12.1,45,11.1,43.3,11.9,42z"/>
                      <path fill="#263238" d="M26.4,39.9c0-0.2,0-0.4,0.1-0.6s0.2-0.3,0.3-0.5s0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.1s0.5,0,0.7,0.1 s0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.6s0,0.4-0.1,0.6s-0.2,0.3-0.3,0.5s-0.3,0.2-0.5,0.3s-0.4,0.1-0.7,0.1 s-0.5,0-0.6-0.1s-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.5S26.4,40.1,26.4,39.9z M29.2,36.8h-2.3L26.5,27h3L29.2,36.8z"/>
                    </svg>
                  </div>
                  <h2 class="text-center">
                    Invite Link Expired
                  </h2>
                  <div class="text-center">
                    <a class="text-danger" href="/" target="_self"
                    >Back to Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  class="main-footer">
            <div class="footer-inner">
              <div class="row footer-row">
                <div class="col-md-7 pl-3">
                  <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">
                    <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                  </div>
                  <p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p>
                </div>
                <div class="col-md-5 pr-4">
                  <p class="text-right mr-2">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import banner from "../memberregistration/banner.vue";
export default {
  name: "InviteLinkExpired",
  data() {
    return {
      prop_footer: {},
    };
  },
  updated(){
    this.prop_footer = this.getSiteProps('memberRegistration.footer');
  }
};
</script>
<style scoped>
.svg-align {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>