<template>
<div id="landing-page">
    <nav class="navbar navbar-default">
        <div class="container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
        <a href="#"> <img :src="this.getSiteProps('landingpage.logo')" v-if="this.getSiteProps('landingpage.logo')" style="width:100px;"></a>
        </div>
        <div class="pull-right">
        <router-link class="icon-with-text hide" to="/login"><i class="fas fa-sign-in-alt"></i> Login</router-link>
        <router-link class="icon-with-text" to="/help-and-support" v-if="subdomain != 'shooting'"><i class="fa fa-headset"></i> Support</router-link>
        </div>
        </div>
    </nav>

<div id="banner">
    <div class="content">
        <h3 class="text-white">2021-2022 Season</h3>
         <h5 class="text-white">Member Registration</h5>
         <p class="text-white">Join as a player, coach, club</p> 
        <div class="buttons">
            <router-link to="/memberregistration" class="btn btn-primary btn-lg btn-red btn-font ">Register Now</router-link>

        </div><!-- buttons  -->


    </div><!-- title-wrapper -->

    <div id="mobile-image"></div>
    <div id="my-pics" class="carousel slide" data-ride="carousel">
       <img :src="this.getSiteProps('landingpage.banner')" v-if="this.getSiteProps('landingpage.banner')">
      
        <div class="carousel-inner" role="listbox">

            <!-- Slide 1 -->
            <div class="item active">
                <img src="" alt="Sunset over beach" style="width: 100%;" />
            </div>

            <!-- Slide 2 -->
            <div class="item">
                <img src="" alt="Rob Roy Glacier" style="width: 100%;" />
            </div>


        </div>
    </div>


</div>


<div class="container">

    <section id="welcome" class="row">

        <div class="col-md-12">
            <h2>About Integrass Sports</h2>
        </div>

        <div class=" col-md-12">
            <p class="text-center">Integrass Sports provides the foundation for the sport in America; helps young people become
                leaders, even Olympic heroes;<br> and connects the game at every level while promoting a lifelong love of
                the sport.</p>
            <!--<a href="#" class="btn btn-primary btn-lg btn-font hide">Request Duplicate</a>
               
               
            <a href="#" class="btn btn-primary btn-lg btn-font hide">Update Your Email Address</a>-->
	
    
        </div>

    </section>

   
    <section id="benefits" class="">
       
        <h2 class="text-center">Membership Benefits</h2>       
        <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active">
                <a href="#all" aria-controls="all" role="tab" data-toggle="tab">All
                    Members</a>
                    </li>

            <li role="presentation">
                <a href="#youth" aria-controls="youth" role="tab" data-toggle="tab">Youth
                    Players</a>
                    </li>
            <li role="presentation">
                <a href="#parents" aria-controls="parents" role="tab" data-toggle="tab">Parents of
                    Youth Players</a>
            </li>
            <li role="presentation">
                <a href="#coaching" aria-controls="coaching" role="tab"
                                       data-toggle="tab">Coaches</a>
                                       </li>
            <li role="presentation">
                <a href="#officiating" aria-controls="coaching" role="tab"
                                       data-toggle="tab">Officials</a>
                                       </li>
            <li role="presentation">
                <a href="#adults" aria-controls="adults" role="tab" data-toggle="tab">Adult
                    Players</a>
            </li>
            <li role="presentation">
                <a href="#admin" aria-controls="admin" role="tab"
                                       data-toggle="tab">Administrators</a>
                                       </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
            <div role="tabpanel" class="tab-pane active" id="all">
<div class="row">
        <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4 col-lg-3">
 <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
        </div>

                <div class="col-sm-6 col-md-6 col-lg-5 right-col">

                    <ul class="benefits-list">
                        <li>
                            <h4><i class="fas fa-book-open"></i> Subscription to <strong style="font-style: italic">Integrass
                                    Sports Magazine</strong></h4>
                            <ul>
                                <li><span>The most widely distributed sport publication in the world</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="far fa-life-ring"></i> Comprehensive <strong>SAFETY</strong> Program
                                Including:</h4>
                            <ul>
                                <li><span>Official playing rules to keep the game safe and fun</span></li>
                                <li><span>Commitment to research and education</span></li>
                                <li><span>Integrass Sports SafeSport, an initiative dedicated to off-ice safety, which includes an
                                        online education course</span>
                                </li>
                                <li><span>Commitment to concussion research, awareness and
                                        education program</span>
                                </li>
                                <li><span>Extensive risk management program</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-medkit"></i> World-Class Insurance Coverage</h4>
                            <ul>
                                <li><span>To provide peace-of-mind for all involved in the sport</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-flag-usa"></i> Connection to Team USA</h4>
                            <ul>
                                <li><span>Olympic and Paralympic Winter Games</span></li>
                                <li><span>World Championships at all levels</span></li>
                                <li><span>Other international events</span></li>
                            </ul>
                        </li>
                        <li class="hide">
                            <h4><i class="fas fa-clipboard-list"></i> Official Playing Rules</h4>
                            <p>To provide consistency for all participants.</p>
                        </li>

                        <li>
                            <h4><i class="fas fa-hockey-puck"></i> Exclusive Offers From</h4>
                            <ul>
                                <li><span>Official partners of Integrass Sports</span></li>
                                <li><span>Professional hockey teams</span></li>
                                <li><span>Equipment manufacturers</span></li>
                            </ul>
                        </li>
                    </ul>

                </div>
</div>
                


            </div><!-- all -->
            <div role="tabpanel" class="tab-pane" id="adults">
                <div class="row">                
                <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4  col-lg-3">
                  <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
                </div>

                <div class="col-sm-6 col-md-6 col-lg-5 right-col">
                    <ul class="benefits-list">
                        <li>
                            <h4><i class="fas fa-book-open"></i> Unique Playing Opportunities Including:</h4>
                            <ul>
                                <li><span>National Championships</span></li>
                                <li><span>Classic Tournaments</span></li>
                                <li><span>NHL-Sponsored Tournaments</span></li>
                                <li><span>World-renowned Pond Hockey Championships</span></li>
                                <li><span>State Tournaments</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fab fa-accessible-icon"></i> Disabled Athlete Opportunities </h4>
                            <ul>
                                <li><span>Growing Opportunities for Disabled Athletes through
                                        six disciplines of disabled hockey</span>

                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-skating"></i> Adult Equipment Program </h4>
                            <ul>
                                <li><span>A First Goal adult equipment program to assist those
                                        players just getting started in the game</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-chalkboard-teacher"></i> Skills Clinics</h4>
                            <ul>
                                <li><span>Access to adult skills clinics for those of all ability levels</span></li>
                            </ul>
                        </li>
                        
                    </ul>
                </div>
                </div>

            </div>
            <div role="tabpanel" class="tab-pane" id="youth">
            <div class="row">
                <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4 col-lg-3">
                  <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
                </div>

                <div class="col-sm-6 col-md-6 col-lg-5 right-col">

                    <ul class="benefits-list ">
                        <li>
                            <h4><i class="fas fa-child"></i> Age-Appropriate Learning</h4>
                            <ul>
                                <li><span>Training utilizing the American Development Model which provides
                                        age-appropriate learning in a fun environment</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fab fa-accessible-icon"></i> Disabled Hockey</h4>
                            <ul>
                                <li><span>Growing opportunities for disabled athletes through six disciplines of
                                        disabled hockey</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-female"></i> Female Players</h4>
                            <ul>
                                <li><span>A focus on addressing the specific needs of female players</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-trophy"></i> Tournaments</h4>
                            <ul>
                                <li><span>Opportunity to play in district and national tournaments</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-flag-usa"></i> Opportunity to be selected for:</h4>
                            <ul>
                                <li><span>Regional and National Player Development Camps and Festivals</span>
                                </li>
                                <li><span>Select Teams</span></li>
                                <li><span>National Team Development Program</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-user-graduate"></i> Junior Program</h4>
                            <ul>
                                <li><span>An extensive junior program for players to develop in preparation for
                                    the collegiate and professional ranks
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="parents">
            <div class="row">
                <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4 col-lg-3">
                    <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6 right-col">

                    <ul class="benefits-list">
                        <li>
                            <h4><i class="far fa-life-ring"></i> Secure Environment</h4>

                            <p>Peace-of-mind, knowing your child is part of a national organization which:</p>
                            <ul>
                                <li><span>Has <strong>safety as its top priority</strong> with cutting-edge
                                        programs for both on and off-ice safety</span>

                                </li>
                                <li><span>Requires background screening and completed
                                        SafeSport training for all adults with access to youth players</span>
                                </li>
                                <li><span>All coaches are certified and have taken educational
                                        modules pertinent to the age of players they’re coaching</span>
                                </li>
                                <li><span>All officials are certified to ensure appropriate
                                        knowledge of rules and understanding of Integrass Sports’s focus on the standard of play</span>
                                </li>
                                <li><span>Includes a world-class insurance program to provide
                                   assistance if the need arises</span>
                                </li>
                                <li><span>Access to educational materials, including monthly
                                    age-appropriate newsletters during the season
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="officiating">
            <div class="row">
                <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4 col-lg-3">
                   <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
                </div>

                <div class="col-sm-6 col-md-6 col-lg-5 right-col">

                    <ul class="benefits-list">
                        <li>
                            <h4><i class="fas fa-chalkboard-teacher"></i> Training, Clinics and Certification</h4>
                            <ul>
                                <li><span>Instructional training, clinics and certification to help ensure a
                                        consistent standard across the country</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-mobile-alt"></i> Mobile Rulebook App</h4>
                            <ul>
                                <li><span>Exclusive access to Integrass Sports Mobile Rulebook App</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-chart-bar"></i> Game Report System</h4>
                            <ul>
                                <li><span>Access to the Integrass Sports online Game Report System</span></li>
                            </ul>
                        </li>

                    </ul>

                </div>
            </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="coaching">
                <div class="row">
                <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4  col-lg-3">
                    <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
                </div>

                <div class="col-sm-6 col-md-6 col-lg-5 right-col">

                    <ul class="benefits-list">
                        <li>
                            <h4><i class="fas fa-clipboard-check"></i>Coaching Education Program</h4>
                            <ul>
                                <li><span>State-of-the-art, gold-standard education program to ensure
                                        coaches have the most current material to assist players in reaching their full potential</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-mobile-alt"></i> Mobile Coach App</h4>
                            <ul>
                                <li><span>Exclusive access to Integrass Sports Mobile Coach App that provides
                                        cutting-edge resources for coaches on smartphones and tablets</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-users"></i> Age-Specific Training</h4>
                            <ul>
                                <li><span>Online educational modules specific to the age level of players you
                                        are coaching</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h4><i class="fas fa-list-ul"></i> Age-Specific Practice Plans</h4>
                            <ul>
                                <li><span>And other numerous coaching resources</span></li>
                            </ul>
                        </li>
                    </ul>

                </div>
                </div>

            </div><!-- coaching -->
            <div role="tabpanel" class="tab-pane" id="admin">

                <div class="row">                
                <div class="col-sm-offset-2 col-sm-4 col-md-offset-2 col-md-4 col-lg-3">
                    <img :src="this.getSiteProps('landingpage.tabimg')" v-if="this.getSiteProps('landingpage.tabimg')">
                </div>

                <div class="col-sm-6 col-md-6 col-lg-5 right-col">

                    <ul class="benefits-list">
                        <li>
                            <ul>
                                <li>
                                    <span>
                                    Free use of online Integrass Sports registration portal to
                                     help manage local program
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Opportunity for grants through The Integrass Sports
                                    Foundation
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    A multitude of free educational publications and
                                    brochures
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Access to national programs, which include free and
                                    easy-to-use toolkits, to
                                    assist in
                                    growing local participation
                                    </span>

                                </li>
                                <li>
                                   <span> Access to Club Excellence, an online portal system
                                       to help youth hockey leaders in running a successful youth hockey association</span>
                                </li>
                            </ul>
                        </li>
                    </ul>


                </div>
                </div>

            </div>

        </div>


    </section>
    <section id="welcome" class="row">


        <div class="ml-c-12 col-md-9">
            <p>In addition, all members of Integrass Sports receive countless benefits from the organization’s strong partner
                relationships with key groups, including the United States Olympic Committee.</p>
            <div class="logo nhl "></div>
            <div class="logo usoc"></div>
            <div class="logo iihf "></div>
        </div>

    </section>


   


</div>

<footer>
    <div class="info-bar" id="usa-support">
        <div>
            <img :src="this.getSiteProps('landingpage.logo')" v-if="this.getSiteProps('landingpage.logo')">
            </div>

        <h3>Contact us</h3>

        <a class="icon-with-text" :href="'tel:'+getSiteProps('general.phone')" v-if="getSiteProps('general.phone')">
            <i class="fas fa-phone"></i>
            <span>{{ getSiteProps("general.phone") }}</span>
        </a>

        <a class="icon-with-text" href="mailto:memberservices@integrasssports.com">
            <i class="fas fa-envelope"></i>
            <span>{{ getSiteProps("general.email") || 'memberservices@integrasssports.com' }}</span>
        </a>

        <a class="icon-with-text" href="/help-and-support" style="color: #ffffff;" v-if="subdomain != 'shooting'">
            <i class="fas fa-question"></i>
            <span>Support</span>
        </a>
        <a class="icon-with-text" target="_blank" href="https://www.surveymonkey.com">
            <i class="fas fa-comment"></i>
            <span>Site Feedback</span>
        </a>
      
        <div class="clearfix"></div>
        <h3>Follow us</h3>
        <ul class="social-media">
            <li>
                <a href="https://www.facebook.com/IntegrassGlobal/"  target="_blank">
                    <i class="fab fa-facebook-f"></i></a>
           </li>
            <li>
                <a href="https://twitter.com/abmcgllc" target="_blank"><i
                            class="fab fa-twitter"></i></a>
                            </li>
            <li>
                <a href="https://www.linkedin.com/company/integrass-global" target="_blank"><i class="fab fa-linkedin"></i></a>
                   </li>
            <li>
                <a href="#" title="#"
                   target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
            <li>
                <a href="#" title="#" target="_blank">
                    <i class="fas fa-shopping-cart"></i></a>
            </li>
        </ul>

    </div>

    <div class="rights-bar">
        <p>iSportz All Rights Reserved.</p>
        <p>Terms of Use Privacy Policy</p>
    </div>
</footer>
</div>
</template>
<script>
import VueBreadcrumbs from 'vue-2-breadcrumbs';
export default {
    name: "home",
}

</script>


<style scoped>










@import url('https://fonts.googleapis.com/css?family=Palanquin:200,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Rambla:700i,400i');


/*BOOTSTRAP OVERRIDES--------------------------*/

body {
    font-size: 20px;
    color: #4D4D4D;
    font-family: 'Palanquin', sans-serif;
    font-weight: 200;
}

small {
    font-family: 'Palanquin', sans-serif;
    font-weight: 200;
}

/*Buttons*/
.btn-primary {
    background: #d32f2e;
    padding: 10px 20px 15px;
}

.btn-primary:hover {
    background: #fb1e1e;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:visited {
    background: #d32f2e !important;
}

.btn {
    border-radius: 0;
    font-size: 24px;
    margin-top: 20px;
    border: none;
}

.btn:active {
    transform: translateY(2px);
}

.btn-link {
    color: #9F9F9F;
    line-height: 33px;
}

h1, h2, h3 {
    font-family: 'Rambla', sans-serif;
    font-weight: 700;
    font-style: italic;
}

h3 {
    font-family: 'Rambla', sans-serif;
    font-weight: 400;
    font-style: italic;
}

.tooltip-inner {
    background-color: #07213F;
    font-size: 18px;
    font-family: 'Palanquin', sans-serif;
    padding-bottom: 5px;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #07213F;
}

/*NAVBAR--------------------------*/

.navbar-default {
    background-color: #07213f;
    color: white;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    height: 71px;
    border-bottom: 1px solid #9c9b9b;
}

.navbar-default .navbar-nav > li > a {
    color: white;
}

.navbar-default .navbar-nav > li > a:hover {
    color: white;
    text-decoration: underline;
}

.navbar-default img {
    width: 100px;
    margin-top: 0px;
    margin-left: 11px;
}

.navbar-default .navbar-right {
    margin-top: 10px;
}

/*FOOTER--------------------------*/

footer .info-bar {
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* IE6-9 */
    color: white;
    text-align: center;
    padding: 16px 0 6px;
    background: #07213F;
}

/* footer .info-bar .usa-hokey-logo {
    width: 280px;
} */

footer .info-bar h3 {
    margin-bottom: 8px;
    font-size: 22px;
}

footer .info-bar .social-media {
    margin-bottom: 15px;
    margin-top: 18px;
    padding-left: 0;
}

footer .info-bar .social-media li {
    display: inline-block;
    margin: 0 10px;
}

footer .info-bar .social-media li i {
    color: white;
    background: transparent;
    border: 2px solid white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 48px;
    font-size: 21px;
}

footer .info-bar .contact {
    /*border-top: 1px solid white;*/
    margin-top: 22px;
    margin-left: 30px;
    margin-right: 30px;
}

footer .info-bar .contact i {
    display: block;
    font-size: 26px;
    margin-bottom: 9px;
}

footer .info-bar .contact a {
    color: white;
    font-size: 18px;
    margin-bottom: 9px;
    display: block;
}

footer .rights-bar {
    background: #05182f;
    color: white;
    font-size: 14px;
    padding: 15px 0;
    text-align: center;
    border-top: 1px solid #9c9b9b;
}

footer .rights-bar a {
    text-decoration: underline;
    color: white;
    margin: 0 1px;
    cursor: pointer;
}



a.icon-with-text {
    color: white;
    margin: 0 10px;
}

a.icon-with-text:hover {
    text-decoration: none;
}

a.icon-with-text i {
    border: 2px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 27px;
    text-align: center;
    font-size: .7em;
    margin-right: 4px;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.pull-right a.icon-with-text {
    margin: 0 0 0 20px;
}



#landing-page .navbar {
    position: fixed;
    z-index: 2;
    width: 100%;
    background: none;
    border-bottom: 1px solid #9c9b9b;
    background: #07213f;
}

#landing-page .navbar .pull-right {
    margin-top: 10px;
    margin-right: 10px;
}

#landing-page .navbar.sticky {
    height: 50px;
}

#landing-page .navbar.sticky img {
    width: 70px;
    margin-top: 0px;
}

#landing-page .navbar.sticky .pull-right {
    margin-top: 9px;
}


#banner {
    width: 100%;
    height: 550px;
    background: gray;
    overflow: hidden;
    position: relative;
    text-align: center;
}

#banner .content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 70px;

    background: -moz-linear-gradient(top, rgba(7, 33, 63, 1) 0%, rgba(7, 33, 63, 0) 54%, rgba(7, 33, 63, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(7, 33, 63, 1) 0%, rgba(7, 33, 63, 0) 54%, rgba(7, 33, 63, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(7, 33, 63, 1) 0%, rgba(7, 33, 63, 0) 54%, rgba(7, 33, 63, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#07213f', endColorstr='#0007213f', GradientType=0); /* IE6-9 */
}

#banner .content h1 {
    color: white;
    font-size: 2.3em;
    margin: 0 0 30px;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
}

#banner .content h1 div {
    font-size: 1.3em;
    margin-bottom: -9px;
}

#banner .content h1 small {
    color: white;
    display: block;
    margin-top: 20px;
    font-size: 0.7em;
}



#banner .content .buttons .btn {
    display: inline-block;
    margin: 0 7px;
}

#banner video {
    width: 100%;

}



#landing-page section {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin:0;
}



#landing-page section h2 {
    color: #e10001;
    font-size: 2.5em;
    margin-bottom: 20px;
}



#welcome {
    margin-top: 30px;
    margin-bottom: 30px;
}


#register-now {
    /* background: url(../img/join-background.jpg) center center no-repeat; */
    background-size: cover;
    color: white;
    background-attachment: fixed;
    padding: 100px 0 !important;
}

#register-now.red-theme {
    /* background: url(../img/join-background-red.jpg) center center no-repeat; */
    background-size: cover;
    color: white;
    background-attachment: fixed;
    padding: 100px 0 !important;
}

#register-now.red-theme.gradient {
    /* background: url(../img/join-background-red-gradient.jpg) center center no-repeat; */
    background-size: cover;
    color: white;
    background-attachment: fixed;
    padding: 100px 0 !important;
}

#register-now h2 {
    color: white !important;
}

#register-now.red-theme .btn {
    background: white;
    color: #E9516E;
}



#benefits {
    background: #F1F1F1;
}

#benefits img {
    width: 100%;
}

.left-col {
    text-align: right;
}

.benefits-list {
    list-style: none;
    text-align: left;
    padding: 0;
}

.benefits-list > li {
    margin-bottom: 18px;
}

.benefits-list li i {
   
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -0.3em;
    width: 23px;
}

.benefits-list li h4 {
    display: inline-block;
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 500;
}

.benefits-list li p {
    margin-left: 42px;
}

.benefits-list li ul {
    list-style: disc;
    color: #e10001;
}

.benefits-list li ul li span {
    color: #4D4D4D;
}


.benefits-list li ul li {
    line-height: 1.3em;
    margin: 10px 20px;
}

.benefits-list li ul li:before {
    /*content: "\2022";  Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #e10001; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 21px; /* Also needed for space (tweak if needed) */
    /* Also needed for space (tweak if needed) */
    font-size: 1.8em;
    position: relative;
    top: 0.2em;
    height: 0;
}

.tab-content > .active {
    display: block;
    padding-top: 25px;
    background: #f1f1f1;
}

.nav-tabs {
    text-align: center;
 
    border-bottom: 1px solid #dee2e6;

}

.nav-tabs > li {
    float: none;
    display: inline-block;
    min-width: 10%;
    margin: 0 -4px;
    padding: 9px 0px;
}

.nav-tabs > li > a {
    color: #4D4D4D;
    padding-left: 25px;
    padding-right: 25px;
    font-family: 'Rambla', sans-serif;
    font-weight: 400;
    font-style: italic;
    background: transparent !important;
    border: none !important;
    /* border-bottom: 1px solid #b3b3b3 !important; */
}

.nav-tabs > li.active a {
    background: transparent !important;
    border-radius: 0;
    border-bottom: 3px solid #07213F !important;
    color: #4D4D4D;
    padding-bottom: 9px;
}

.show_mobile {
    display: none;
}



@media (min-width: 993px) {

    #landing-page .row {
        margin-left: 0;
        margin-right: 0;
    }

    #benefits {
        padding-bottom: 60px !important;
    }

    #register-now {
        margin: 0;
    }

    footer .info-bar .contact {
        display: none;
    }

    #banner #mobile-image {
        display: none;
    }

    .ml-c-12 {
        margin-left: 13.666667%;
    }

    .mb-mt-10 {
        margin-top: 0;
    }

    .mb-flt {
        float: none;
    }

    .mb-mb-15 {
        margin-bottom: 0;
    }

}



@media (min-width: 768px) {

    #landing-page .container {
        width: 100%;
        padding: 0;
        max-width: 100%;
    }

    .nav-tabs {
        margin: 0 10%;
    }

    .update-container {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

}



@media (max-width: 992px) {

    #banner .content {
     
        background: -moz-linear-gradient(top, rgba(7, 33, 63, 1) 0%, rgba(7, 33, 63, 0.56) 50%, rgba(7, 33, 63, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(7, 33, 63, 1) 0%, rgba(7, 33, 63, 0.56) 50%, rgba(7, 33, 63, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(7, 33, 63, 1) 0%, rgba(7, 33, 63, 0.56) 50%, rgba(7, 33, 63, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#07213f', endColorstr='#0007213f', GradientType=0); /* IE6-9 */
    }

    #banner .content .buttons .btn {
        margin-bottom: 18px;
    }

    #banner .content h1 {
        color: white;
        font-size: 1.7em;
        margin: 0 0 30px;
        width: 80%;
    }

    #banner .content h1 div {
        font-size: 1.5em;
        line-height: 0.9em;
        margin-bottom: 4px;
        font-weight: 700;
    }

    #banner .content h1 div span {
        display: block;
    }

    #banner .content h1 small {
        color: white;
        font-size: 0.8em;
    }

    #landing-page section h2 {
        font-size: 1.8em;
    }

    #landing-page .navbar .pull-right .item span {
        display: none;
    }

    #landing-page .navbar .pull-right {
        position: absolute;
        top: 1px;
        right: 4px;
    }

    #landing-page .navbar .icon-with-text {
        font-size: 18px;
        margin-left: 5px;
    }

    .navbar-default img {
        width: 90px;
        margin-top: 10px;
        margin-left: 12px;
    }

    #banner video {
        display: none;
    }

    #banner #mobile-image {
        width: 100%;
        height: 100%;
        /* background: url("../img/banner-mobile.jpg") no-repeat center center; */
        background-size: cover;
    }

    #benefits {
        padding-left: 10px;
        padding-right: 10px;
    }

    #benefits img {
        margin-bottom: 30px;
    }

    .benefits-list li ul {
        padding: 0;
    }

    .benefits-list li p {
        margin-left: 0px;
    }

    #benefits .nav-tabs li > a > span {
        display: none;
    }

    footer .info-bar .social-media li {
        margin: 0 4px;
    }

    footer .info-bar .social-media li i {
        width: 45px;
        height: 45px;
        line-height: 46px;
    }

    footer .icon-with-text {
        display: block;
        margin-bottom: 20px;
    }

    footer .icon-with-text:last-of-type {
        border-bottom: 1px solid white;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 15px;
        margin-bottom: -5px;
    }

    footer .icon-with-text span {
        display: block;
    }

    .nav-tabs > li {
        width: 50%;
    }

    .nav-tabs {
        margin-left: 10px;
        margin-right: 10px;
    }

    .btn-font {
        font-size: 14px;
    }

    .initial_ag {
        top: 32px !important;
    }

    .initial_ag_tab {
        left: 106px !important;
    }

    .benefits-list li ul li {
        margin: 10px 52px;
    }

    .ml-16-em-v, .ml-c-12 {
        margin-left: 0 !important;
    }

}

@media (max-width: 575.98px) {
    #steps-bar {
        /* background: url(../img/form-background-mobile.jpg) #404040 top center no-repeat !important; */
        text-align: center;
        position: relative;
        background-attachment: fixed;
    }

    .h-menu {
        z-index: 1000;
        position: relative;
        background: #07213f;
    }

    .mb-mt-10 {
        left: 55px;
        position: absolute;
        top: 33px;
    }

    .mb-mt-w-10 {
        left: 40px;
        position: absolute;
        top: 33px;
    }


    .concussion-sm {
        top:75px !important;
    }
    .safeport-sm {
        top:80px !important;
    }
    .ml-14-em {
        margin-left: 0em !important;
        font-size: 9px !important;
    }
    .ml-23-wa-em {
        margin-left: 1em !important;
        font-size: 9px !important;
    }

    .ml-14-em {
        margin-left: 1em !important;
        font-size: 9px !important;
    }

    .ml-13-em {
        margin-left: 0em !important;
        font-size: 9px !important;
    }

    .mb-flt {
        float: left;
    }

    .mb-mb-15 {
        margin-bottom: 15% !important;
    }

    .show_mobile {
        display: block;
    }
    .vp-center {

        display: flex;
        align-items: unset !important;
        justify-content: unset !important;
    }
    .player {
        width: 50% !important;
    }
    .p-mobile-coupon {
        margin-bottom: 40px !important;
    }

    #registrationForm .btn {
        font-size: 14px !important;
    }
    .ml-23-cn-em {
        margin-left: 0em !important;
        font-size: 9px !important;
    }
}

@media (min-width: 600.98px) and (max-width: 765.98px) {
    .ml-23-em {
        margin-left: 9em !important;
        font-size: 9px !important;
    }

    .ml-14-em {
        margin-left: -1em !important;
        margin-top: -2em;
    }

    .ml-13-em {
        margin-left: -1em !important;
    }
}

@media (min-width: 768px) and (max-width: 977.98px) {
    .ml-23-em {
        margin-left: 9em !important;
        font-size: 9px !important;
    }

    .ml-14-em {
        margin-left: -1em !important;
        margin-top: -2em;
    }

    .ml-13-em {
        margin-left: -1em !important;
    }

    .initial-left-safe {
        left: 74px !important;
    }

    .ml-16-em-v {
        margin-left: 7em !important;
    }

}

@media (min-width: 978px) and (max-width: 1024px) {
    .ml-23-em {
        margin-left: 15em !important;
    }

    .ml-14-em {
        margin-left: 5em !important;
    }

    .ml-13-em {
        margin-left: 4em !important;
    }

    .ml-16-em-v {
        margin-left: 9em !important;
    }
}



#registrationForm {
    font-size: 18px;
}



#registrationForm .navbar-default .container-fluid {
    width: 900px;
    height: 100%;
    position: relative;
}

#registrationForm .navbar-default h1 {
    text-align: center;
    font-size: 26px;
    position: absolute;
    margin: 0;
    top: 12px;
    left: 50%;
    margin-left: -81px;
    color: white;
}

#registrationForm .navbar-default h1 small {
    font-size: 0.7em;
    display: block;
    color: white;
}

#registrationForm .navbar-default .navbar-right {
    margin-top: 10px;
}



#steps-bar {
    /* background: url(../img/form-background.jpg) #404040 top center no-repeat; */
    text-align: center;
    position: relative;
    background-attachment: fixed;
}

#steps-bar .steps-container {
    width: 950px;
    margin: 0 auto;
    padding: 10px 0;
}

#steps-bar .step {
    display: inline-block;
    color: white;
    text-align: center;
    position: relative;
    z-index: 1;
    width: 115px;
    float: none;
    vertical-align: middle;
}

#steps-bar .step.last {
    margin: 0;
}

#steps-bar .step .number {
    display: block;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 2px;    
    font-family: 'Rambla', sans-serif;
}

#steps-bar .step i {
    width: 45px;
    height: 45px;
    background: #bbbbbb;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 44px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
}

#steps-bar .step.active i {
    background: #e10001;
}

#steps-bar .step.complete i {
    background: #0659b5;
}

#steps-bar .step .name {
    margin-bottom: 0;
    display: block;
    font-weight: 200;
    width: 150px;
}

#steps-bar .steps-progress {
    position: absolute;
    top: 50px;
    height: 10px;
    background: #B3B3B3;
    width: 750px;
    box-shadow: none;
    display: block;
    margin-left: calc(50% - 540px);
}


.step-bar {
    background: #efefef;
    height: 15px;
    width: 10%;
    margin-top: -5px;
    margin-left: -42px;
    margin-right: -42px;
    display: inline-block;
    vertical-align: middle;
}


.step-bar .step-progress {
    background: #e10001;
    width: 0%;
    height: 100%;
}

.step-bar .step-progress.p-1 {
    width: 0%
}

.step-bar .step-progress.p-2 {
    width: 7.7%
}

.step-bar .step-progress.p-3 {
    width: 15.4%
}

.step-bar .step-progress.p-4 {
    width: 23.1%
}

.step-bar .step-progress.p-5 {
    width: 30.8%
}

.step-bar .step-progress.p-6 {
    width: 38.5%
}

.step-bar .step-progress.p-7 {
    width: 46.2%
}

.step-bar .step-progress.p-8 {
    width: 53.9%
}

.step-bar .step-progress.p-9 {
    width: 61.6%
}

.step-bar .step-progress.p-10 {
    width: 69.3%
}

.step-bar .step-progress.p-11 {
    width: 77%
}

.step-bar .step-progress.p-12 {
    width: 84.7%
}

.step-bar .step-progress.p-13 {
    width: 92.4%
}

.step-bar .step-progress.complete {
    width: 100%;
    background: #0659b5;
}

.step-bar.step-1 {
    width: 30%;
}



#registrationForm .container {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 35px;
    min-height: calc(100vh - 182px);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-right: 25px;
    padding-left: 25px;
}

#registrationForm .container .question-icon i {
    color: #424242;
    width: 60px;
    height: 60px;
    border: 3px solid #424242;
    border-radius: 50%;
    font-size: 28px;
    line-height: 55px;
}

#registrationForm .container .question-icon h2 {
    margin-bottom: 0;
}

#registrationForm .container .question-icon p {
    text-align: center;
    margin-bottom: 30px;
}

#registrationForm .container .question-icon .gender {
    width: 60px;
    height: 60px;
    display: inline-block;
    border-radius: 50%;
    border: 3px solid #424242;
}

#registrationForm .container .question-icon .gender i {
    width: initial;
    border: initial;
    color: #424242;
}

#registrationForm .container .question-icon .gender i.male {
    position: relative;
    left: 2px;
}

#registrationForm .container .question-icon .icon-wrapper {
    width: 60px;
    height: 60px;
    border: 3px solid #424242;
    border-radius: 50%;
    display: inline-block;
}

#registrationForm .container .question-icon .icon-wrapper i {
    border: none;
    position: relative;
    left: 2px;
}

#registrationForm .container h2 {
    color: #e10001;
    text-align: center;
    margin: 10px 0 28px;
    font-style: normal;
    font-weight: normal;
}

#registrationForm p {
    text-align: left;
    margin-bottom: 15px;
}

#registrationForm .text-justify p {
    text-align: justify;
}

#registrationForm .container .form-group {
    text-align: left;
}

.form-group label {
    font-weight: 200;
    margin-bottom: 2px;
    color: #7d7d7d;
    min-height: 20px;
    display: inline-block;
}

.form-group .form-control,
.form-group select {
    border-radius: 0 !important;
    border: 1px solid lightgray;
    box-shadow: none;
    background: white;
    padding-top: 7px;
}

#registrationForm .container .input-group .form-control {
    border-left: 0;
    padding-left: 0px;
}

#registrationForm .container .input-group .input-group-addon {
    border-radius: 0;
    background: white;
    border-right: none;
    position: relative;
    z-index: 3;
}

#registrationForm .container .input-group .input-group-addon i {
    font-size: 24px;
}

#registrationForm .required {
    color: #e10001;
}



#registrationForm .btn-primary {
    padding: 10px 50px;
}

#registrationForm .btn {
    font-size: 18px;
    margin-top: 4px;
    height: 46px;
}


#registrationForm .note {
    background: #eaeaea;
    text-align: left;
    padding: 10px 22px;
    margin-top: 15px;
    margin-bottom: 5px;
}

#registrationForm .note h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

#registrationForm .container a {
    color: #004ba5;
    text-decoration: underline;
    cursor: pointer;
}

#registrationForm .note p {
    text-align: left;
}



.object {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
    display: inline-block;
    font-weight: 200;
}

.object input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 28px;
    width: 28px;
    border: 2px solid #b3b3b3;
    background: none;
    transform: translateY(-50%);
}

.object:hover input ~ .checkmark {
    background-color: #b6b6b6;
}

.object input:checked ~ .checkmark {
    background-color: #004ea4;
    border-color: #004ea4;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    margin-top: 1px;
}

.object input:checked ~ .checkmark:after {
    display: block;
}

.object .checkmark:after {
    left: 8px;
    top: 2px;
    width: 9px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


.checkbox label, .radio label {
    padding-left: 7px;
    font-weight: 200;
}

.object.radio .checkmark {
    border-radius: 50%;
}

.object.radio .checkmark:after {
    top: 6px;
    left: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
}


#donations {
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: -15px;
}

#donations label {
    display: block;
    margin-bottom: 15px;
}

#donations label span {
    font-family: sans-serif;
}

.horizontal-radio-group {
    margin-bottom: 5px;
}

.horizontal-radio-group .radio {
    margin-right: 20px;
    margin-bottom: 11px !important;
}

.horizontal-radio-group label:not(.radio) {
    font-weight: 200;
    margin-bottom: 4px;
    color: #7d7d7d;
    display: block;
}


.birthdate .form-group {
    display: inline-block;
    width: calc(33.333333% - 13px);
}

.birthdate select {
    text-align: right !important;
}

.birthdate span {
    width: 10px;
    display: inline-block;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#referee-video {
    padding: 0 15px;
    margin-top: 20px;
    margin-bottom: 30px;
}



#registrationForm .wrapper {
    background-color: #F2F2F2;
    padding: 30px 30px 17px;
}

#registrationForm .wrapper .divider {
    border-top: 1px solid gray;
}

#registrationForm .data {
    line-height: 16px;
    margin-bottom: 20px;
}


#registrationForm .wrapper .birthdate {
    display: none;
}

#registrationForm .wrapper .horizontal-radio-group {
    display: none;
}

#registrationForm .wrapper hr {
    border-top: 1px solid #d0d0d0;
    margin-top: 3px;
    margin-bottom: 16px;
}

button.action, button.action:visited, button.action:focus {
    color: inherit;
    text-decoration: none !important;
}

button.action:hover {
    color: #6d6c6c;
}

button.action i {
    font-size: 1.3em;
}

#registrationForm .wrapper button.action {
    margin: 7px 0 0;
}


#registrationForm .wrapper.edit .data {
    display: none;
}

#registrationForm .wrapper.edit .form-control {
    display: inline-block;
}

#registrationForm .wrapper.edit .birthdate {
    display: block;
}

#registrationForm .wrapper.edit .horizontal-radio-group {
    display: block;
}

#registrationForm .wrapper.edit .no-edit {
    display: none;
}

#registrationForm .wrapper .save {
    display: none;
}

#registrationForm .wrapper .cancel {
    display: none;
}

#registrationForm .wrapper.edit .save {
    display: inline-block;
}

#registrationForm .wrapper.edit .cancel {
    display: inline-block;
}

#registrationForm .wrapper.edit .edit {
    display: none;
}

#registrationForm .wrapper.edit .delete {
    display: none;
}

#registrationForm .block-container {
    background-color: #F2F2F2;
    text-align: left;
    padding: 20px 30px 9px;
    margin-bottom: 30px;
}

#registrationForm .block-container .pull-right {
    font-family: sans-serif;
}

#registrationForm .block-container .pull-right.red {
    font-family: sans-serif;
    color: #DD0A37;
}

#registrationForm .block-container h3 {
    margin: 0 0 20px;
}

#registrationForm .block-container hr {
    border-top: 1px solid #d0d0d0;
}

#registrationForm .narrow-gap .col-md-3,
#registrationForm .narrow-gap .col-md-6,
#registrationForm .narrow-gap .col-md-12 {
    padding-left: 5px;
    padding-right: 5px;
}

#registrationForm .narrow-gap .row {
    margin-left: -5px;
    margin-right: -5px;
}



#registrationForm label.label-row {
    width: 100%;
    text-align: left;
    font-weight: 200;
    color: #7d7d7d;
    display: block;
}

#registrationForm .narrow-gap label.label-row {
    margin-left: 5px;
}

.payment .step-progress.p-1 {
    width: 0%
}

.payment .step-progress.p-2 {
    width: 25%
}

.payment .step-progress.p-3 {
    width: 50%
}

.payment .step-progress.p-4 {
    width: 75%
}


button.vertical-icon, button.vertical-icon:visited, button.vertical-icon:focus {
    color: inherit;
    text-decoration: none !important;
    height: initial !important;
    margin-top: 0 !important;
}

button.vertical-icon:hover {
    color: #6d6c6c;
}

button.vertical-icon i {
    font-size: 2.5em;
    display: block;
}

#registrationForm .wrapper button.vertical-icon {
    margin: 7px 0 0;
}

#barcode {
    width: 100%;
    max-width: 250px;
    margin-top: 7px;
}

.white-row {
    background-color: white;
    height: 340px;
    padding-top: 12px;
    padding-bottom: 2px;
    border: 1px solid #d0d0d0;
    margin-bottom: 7px;
}

.white-row-confirmation {
    background-color: white;
    padding-top: 12px;
    padding-bottom: 2px;
    margin-bottom: 7px;
}

#registrationForm .white-row .data {
    margin-bottom: 0px;
}

.white-row p:last-child,
.white-row-confirmation p:last-child {
    border-top: 1px solid #d0d0d0;
    margin-left: 15px;
    width: calc(100% - 30px);
    padding: 0;
    padding-top: 12px;
}

.white-row h3 {
    margin-bottom: 6px !important;
}



#gran-total p {
    margin: 9px 0 3px;
}

.ml-150 {
    margin-left: 150px;
}

.btn-mbl {
    font-size: 16px;
}


.ml-150 {
    margin-left: 0;
}

@media (min-width: 993px) {

    #registrationForm .container:not(.expanded) {
        width: 600px;
    }

    #registrationForm .container.medium {
        width: 900px;
    }

    #registrationForm footer .info-bar .contact {
        display: block;
    }

    footer .info-bar .contact .item {
        display: inline-block;
        margin: 0 20px;
        margin-top: -2px;
    }

    #registrationForm .wrapper {
        width: 80%;
        margin-left: 10%;
    }

    .col-md-6:first-child .white-row {
        border-right: none;
    }

    .white-row h3 {
        display: none;
    }

    #registrationForm .person {
        margin-bottom: 10px;
    }

}


.navbar-default .navbar-toggle {
    margin-top: 18px;
    margin-right: 16px;
}

.navbar-default .navbar-toggle .icon-bar {
    background: white;
}

@media (max-width: 992px) {

    #registrationForm .navbar-default .container-fluid {
        width: 100%;
    }

    #registrationForm .navbar-default img {
        width: 100px;
        margin-top: 7px;
    }

    #registrationForm h1 {
        font-size: 26px;
        margin-left: -68px;
    }

    #steps-bar .steps-container {
        width: 100%;
    }

    #steps-bar .step {
        width: 80px;
    }

    #steps-bar .step.active {
    }

    #steps-bar .step .name {
        font-size: 14px;
    }

    #steps-bar .steps-progress {
        width: 300px;
        margin-left: calc(50% - 150px);
    }

    .step-bar {
        width: 10%;
        margin-top: 0px;
        margin-left: -25px;
        margin-right: -25px;
    }

    .step-bar.active {
        width: 20%;
    }

    #registrationForm .btn-primary {
        min-width: 150px;
        padding: 10px 20px;
    }

    footer .rights-bar span {
        display: block;
    }

    footer .info-bar .contact {
        display: block;
    }

    .single-line-mobile {
        display: block;
    }

    #barcode {
        margin-top: 0;
        margin-bottom: 18px;
    }

    .white-row {
        height: auto;
        margin-bottom: 0;
    }

    .col-md-6:last-child .white-row {
        border-top: none;
        margin-bottom: 15px;
    }

    .block-container > .row > div > h3 {
        display: none;
    }

    #gran-total {
        margin-bottom: 15px;
    }

    .horizontal-radio-group .radio {
        margin-bottom: 25px;
        display: block;
    }

    .step-bar.step-1 {
        width: 17%;
    }

}


#registrationForm a.btn-link {
    color: #9F9F9F !important;
    line-height: 33px;
    text-decoration: none !important;
}

#registrationForm a.btn-primary {
    color: #ffffff !important;
    background: #DD0A37;
    padding: 10px 50px;
    text-decoration: none !important;
}

#membership-requirements-checkbox,
.js-membership-requirement {
    display: none;
}


.text-justify {
    text-align: justify;
}

input.sign-with-initials {
    width: 3em;
    opacity: 1;
    height: initial;
    border: 2px solid #b3b3b3;
    text-align: center;
    text-transform: uppercase;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid black;
    font-weight: bolder;
    font-style: italic;
}

.sign-with-initials + label {
    padding-left: 4em;
}


.has-error .checkmark {
    border-color: #a94442;
}

#waiver .checkmark,
#concussion .checkmark {
    top: initial;
    height: 39px;
    width: 39px;
    transform: translateY(0);
}

/* add gap to last buttons in form
 */
#registrationForm .row:last-child {
    padding-top: 2em;
}

.has-error input,
.has-error select {
    /*padding: 0.5em 1em;*/
    /*box-shadow: 0 0 3px 1px #a94442;*/
    border: 1px solid #a94442;
}

.small, small {
    font-size: 75%;
}

.ml-70 {
    margin-left: 60px;
}

.mt-15 {
    margin-top: 2em !important;
}

.w-30 {
    width: 30px !important;
}

.h-30 {
    height: 30px !important;
}

.h-34 {
    height: 34px !important;
}

.text-left {
    text-align: left;
}

.btn-blue {
    background-color: #003876;
}

.btn-blue, .btn-blue:hover, .btn-blue:focus {
    color: #ffffff;
}

.error-msg {
    color: #a94442;
}

.ml-20 {
    margin-left: 20%;
}


.wrapper {
    width: 70%;
}

@media (max-width: 992px) {
    .wrapper {
        width: 100%;
    }
}

.panel-heading {
    padding: 0;
    border: 0;
}

.panel-title > a, .panel-title > a:active {
    display: block;
    padding: 15px;
    color: #555;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    word-spacing: 3px;
    text-decoration: none;
}

.panel-heading a:before {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    transition: all 0.5s;
}

.panel-heading.active a:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}
#landing-page .logo {
    width: 100px;
    height: 100px;
    background: no-repeat center;
    background-size: contain;
    display: inline-block;
    margin-left: 10px;
    margin-top: 45px;
}
section#welcome {
    background: #fff;
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}
#landing-page .logo.nhl {
     background-image: url("/dist/img/landing/award1.png"); 
}

#landing-page  .logo.usoc {
    background-image: url("/dist/img/landing/usoc.png"); 
}

#landing-page  .logo.iihf {
     background-image: url("/dist/img/landing/award2.png"); 
}

.mt-15 {
    margin-top: 15px;
}

.btn-link:hover {
    text-decoration: none;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.w-50 {
    width: 50%;
}

.fnt-14 {
    font-size: 14px;
}

.fnt-14 {
    font-size: 14px;
}

.fnt-bold {
    font-weight: bold;
}

.btn-link:focus, .btn-link:hover {
    text-decoration: none;
}

.mb-60 {
    margin-bottom: 60px;
}

.let-go-box {
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    padding: 15px;
}

.br-0 {
    border-right: 0;
}

.border-0 {
    border: 0;
}

.bg-f5 {
    background-color: #f5f5f5;
}

.lest-go-ul > li {
    margin-bottom: 5px !important;
    list-style-type: disc;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-60 {
    margin-bottom: 60px;
}

.bl-0 {
    border-left: 0;
}

.pb-63 {
    padding-bottom: 63px;
}

.ml-23 {
    margin-left: 23px;
}

.list-type-none {
    list-style-type: none;
}

#verify .form-control[disabled], fieldset[disabled] .form-control {
    background: #F2F2F2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 30px 2px 2px;
    border: none;
    box-shadow: none;
}

.list-type-none {
    list-style-type: none;
}

.font-16 {
    font-size: 16px;
}

#verify span.required {
    display: none;
}

#verify .wrapper input:placeholder-shown {
    display: none;
}

#verify .wrapper .birthday-picker input[disabled] {
    padding: 0;
}

#verify .wrapper.edit span.required,
#verify .wrapper.edit input:placeholder-shown {
    display: initial;
}

.initial_ag {
    position: absolute;
    left: 55px;
    top: 33px;
}

.h-50 {
    height: 50px !important;
}

.mln-23 {
    margin-left: -22px;
}

.lest-go-a {
    color: #4D4D4D;
}


.filter:before, .filter:after {
    content: "";
    display: block;
    position: absolute;
}

.filter:before {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    border: 3px solid #9e9e9f;
    top: 45px;
    right: 30px;
}

.filter:after {
    width: 5px;
    height: 11px;
    background: #9e9e9f;
    border-radius: 5px;
    top: 55px;
    right: 26px;
    transform: rotate(135deg);
    transition: ease all 300ms;
}

.filter.active:before {
    top: 45px;
    width: 15px;
    height: 15px;
    border: 3px dotted #999;
    animation: searching 2s infinite;
}

.filter.active:after {
    opacity: 0;
}

.wrapper .filter:before,
.wrapper .filter:after {
    content: '';
    opacity: 0;
}

@keyframes searching {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@-webkit-keyframes searching {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

#registrationForm input[readonly] {
    background: lightgrey;
}

.fnt-12 {
    font-size: 12px;
}

.ml-n-20 {
    margin-left: -20px;
}

.ml-23-em {
    margin-left: 23em;
}

.ml-14-em {
    margin-left: 14em;
}

.ml-13-em {
    margin-left: 13em;
}

.ml-16-em-v, .ml-16-em {
    margin-left: 16em;
}

_html[xmlns*=""]:root input.sign-with-initials {
    padding-bottom: 15px;
}


@media all and (-ms-high-contrast: none) {
    .mb-e-10 {
        margin-bottom: 20px;
    }


    *::-ms-backdrop, .mb-e-10 {
        margin-bottom: 20px;
    }


    .mtn-e-10 {
        margin-top: -8px;
    }

 
    *::-ms-backdrop, .mtn-e-10 {
        margin-top: -8px;
    }

 
    .mtn-e-15 {
        margin-top: -15px;
    }


    *::-ms-backdrop, .mtn-e-15 {
        margin-top: -15px;
    }

   
}

.form-control:focus {
    box-shadow: #003975 0px 0px 3px 0px;
}

.mt-nt-13 {
    margin-top: -13px;
}

.remove-btn {
    padding: 9px 10px;
    height: 35px;;
    font-size: 12px;
    background-color: #F2F2F2;
    color: #636363 !important;
    text-decoration: none !important;
}

.remove-btn:hover {
    background-color: #e10001;
    color: #ffffff !important;
}

.remove-btn-i {
    font-size: 11px;
    margin-right: 5px;
}

.pb-23-ie {
    padding-bottom: 25px;
}

.disabled, select.disabled, .form-group select.disabled {
    background: lightgray;

}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: #fff;
    background-color: transparent;
}

.readonly select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ml-em-n1 {
    margin-left: -1em;
}

.text-justify p {
    text-align: justify;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.white-border-bg {
    background-color: #fff;
    height: auto;
    border: 1px solid #ccc;
}

.border-right {
    border-right: 1px solid #ccc;
}

.pb-2 {
    padding-bottom: 15px;
}

.concussion-sm {
    position: absolute;
    left: 2px;
    top: 48px;
}
.safeport-sm {
    position: absolute;
    left: 2px;
    top: 48px;
}
.official-video iframe {
    width:100% !important;
}
.nav-tabs > li {
    float: none;
    display: inline-block;
    min-width: 10%;
    margin: 0 -4px;
}

.nav-tabs > li > a {
    color: #4D4D4D;
    padding-left: 25px;
    padding-right: 25px;
    font-family: 'Rambla', sans-serif;
    font-weight: 400;
    font-style: italic;
    background: transparent !important;
    border: none !important;
    /* border-bottom: 1px solid #b3b3b3 !important; */
}

.nav-tabs > li.active a {
    background: transparent !important;
    border-radius: 0;
    border-bottom: 3px solid #07213F !important;
    color: #4D4D4D;
    padding-bottom: 9px;
}


.col-md-offset-2{
    margin-left: 16.66666667%;
}

.btn-red.btn-font {
    background: #d01717 !important;
}
</style>
