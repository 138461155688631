<template>
   <div class="admin-area">
        <sidebar></sidebar>
        <navigation></navigation>
            <div class="content-wrapper">
               <router-view></router-view>
            </div>
        <adminfooter></adminfooter>
    </div>
</template>

<script>
import navigation from "../components/Navigation.vue";
import sidebar from "../components/Sidebar.vue";
import adminfooter from "../components/Footer.vue";


export default {
    name: "admincommonlayout",
    components: {navigation, sidebar,adminfooter},
}
</script>
