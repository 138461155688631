<template>
	<section class="content">
		<div class="myprofile" style="background: #f1f2f4 !important;">
			<div class="container-fluid">
				<div class="row mt-3 mb-3">
					<div class="col-sm-8">
						<h1 class="m-0 text-dark">Family Profile
							<!-- <b-button class="btn btn-secondary btn-lg py-0" @click="addFamilyMember()" v-if="(subdomain== 'waterski' && totmember < 5 && (memberInfoData.hoh_member_id == null || memberInfoData.hoh_member_id == '')) || (subdomain== 'shooting' && totmember < 7 && (memberInfoData.hoh_member_id == null || memberInfoData.hoh_member_id == '')) && btn_enable.add == true"><i class="fa fa-plus"></i> Add</b-button> -->
						</h1>
					</div>         
					<div class="col-md-4">
						<div class="text-right">
							<ol class="breadcrumb float-sm-right p-0">
								<li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
								<li class="breadcrumb-item"><Breadcrumbs/></li>
							</ol>
						</div>                  
					</div>
				</div>
			</div>
			<div class="profile-body user-details">
				<p class="text-center overlay" v-if="cardrefresh.loader == true && cardrefresh.id == null"><i class="fas fa-2x fa-circle-notch fa-spin"></i></p>
				<div class="p-0" v-else>
					<b-tabs content-class="mt-0" justified  pills card>
						<b-tab 
							v-for="(member, index) in familyData" 
							:key="'familyMember-'+index"
							:title="member.personal_details.full_name+' ('+relationship[member.personal_details.relation]+')'" 
							:active="0==index" class="p-0">
							<div class="profile-header profile-bg">
								<div class="row">
									<div class="col-md-8">
										<div class="profiler-detail pl-4 pr-4">
											<h3>
												{{member.personal_details.full_name ? member.personal_details.full_name : ''}}
												<!-- <b-button class="btn btn-sm btn-danger" v-if="subdomain == 'waterski' && btn_enable.add == true" v-b-modal.modal-family-delete @click="deletemember(member.id)"><i class="fas fa-trash"></i> Delete </b-button> -->
												<span class="text-danger text-capitalize family-cat-txt">{{ getFamilyRelationTxt(member.personal_details.relation) || "" }}</span>
											</h3>
											<div class="d-flex">
												<div class="left d-flex">
													<h5 class="text-white text-right d-flex"  style="align-items: center;" ><span class="lable text-white mr-2">Membership# </span>  {{member.confirmation_code || "N/A"}}</h5>
												</div>
												<div class="right" style="margin-left:auto;align-items: center;">                                   
													<a href="javascript:void(0)" @click="refreshCard(member.id)" class="refreshCardLink"><i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card</a>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-4 pl-0 pr-5"> <!-- Temp condition for shooting -->
										<div class="mcard">
											<membercard :member-id="member.member_id" :familyMemberID="member.member_id" :key="'member-card-' + member.id"></membercard>
										</div>
									</div>
								</div>
							</div>
							<div class="row pt-2">
								<div class="col-lg-6">
									<div class="card">
										<div class="card-header">
											<div class="modal-btn float-right">
												<b-button @click="editFamilyMemberPersonal(index)" v-if="subdomain == 'waterski' && ((memberInfoData.hoh_member_id != '' && memberInfoData.hoh_member_id != null && memberInfoData.hoh_member_id == member.confirmation_code) || (memberInfoData.hoh_member_id == null || memberInfoData.hoh_member_id == ''))"><i class="far fa-edit"></i> Edit </b-button>
											</div>
											<h3>Personal Information</h3>
										</div>
										<div class="card-body">
											<div class="row">
												<div  class="col-md-4" v-if="prop_fields.firstName">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.firstName.label || 'First Name' }}</label>
														<span >{{ member.personal_details.first_name || "N/A" }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="prop_fields.middleInitial">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.middleInitial.label || 'Middle Initial' }}</label>
														<span>{{ member.personal_details.middle_name || "" }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="prop_fields.LastName">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.LastName.label || 'Last Name' }}</label>
														<span >{{ member.personal_details.last_name || "N/A" }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="prop_fields.birthDay">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.birthDay.label || 'Birthday' }}</label>
														<span >{{ member.personal_details.dob | formatDob }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="prop_fields.gender">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.gender.label || 'Gender' }}</label>
														<span >{{ member.personal_details.gender || "N/A" }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="subdomain == 'waterski' && prop_fields.relation">
													<div class="form-group">
														<label class="form-label">Affiliated Clubs/Teams</label>
														<span>{{ member.affiliated_clubs_names || "N/A"}}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.sport_discipline && prop_fields.sport_discipline.first_choice">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.sport_discipline.first_choice.label || 'Disciplines of Intrest' }}</label>
														<ul v-if="member.disciplines && member.disciplines.length">
															<li v-for="(item, k) in member.disciplines" :key="'memDis-'+k">{{ item.name }}</li>
														</ul>
														<span v-else>{{ sportDisciplines[member.personal_details.first_choice] || "NP - No Preference" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.sport_discipline && prop_fields.sport_discipline.second_choice">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.sport_discipline.second_choice.label || '2nd Choice' }}</label>
														<span >{{ sportDisciplines[member.personal_details.second_choice] || "NP - No Preference" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="subdomain == 'waterski' && member.personal_details.home_federation">
													<div class="form-group">
														<label class="form-label">Federation</label>
														<span>{{ member.personal_details.home_federation || "N/A" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="subdomain == 'waterski' && member.personal_details.skier_license">
													<div class="form-group">
														<label class="form-label">Foreign Federation ID</label>
														<span>{{ member.personal_details.skier_license || "N/A" }}</span>
													</div>
												</div>
												<div  class="col-md-4">
													<div class="form-group">
														<label class="form-label">Member ID</label>
														<span >{{ member.confirmation_code || "N/A" }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="prop_fields.relation">
													<div class="form-group">
														<label class="form-label">Relationship</label>
														<span>{{ relationship[member.personal_details.relation] }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div v-if="subdomain=='waterski'">
										<div class="card">
											<div class="card-header">
												<div class="modal-btn float-right">
													<b-button @click="editFamilyMemberDemographic(index)" v-if="subdomain == 'waterski' && ((memberInfoData.hoh_member_id != '' && memberInfoData.hoh_member_id != null && memberInfoData.hoh_member_id == member.confirmation_code) || (memberInfoData.hoh_member_id == null || memberInfoData.hoh_member_id == ''))"><i class="far fa-edit"></i> Edit </b-button>
												</div>	
												<h3>Demographic Information</h3>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label">Race</label>
															<!-- <span v-for="(diversity,i) in diversities" :key="i">
																<p v-if="diversity.id == member.personal_details.diversity_id">{{diversity.name}}</p>
															</span> -->
															<span>{{ member.personal_details.diversity_txt || "N/A" }}</span>
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label">Ethnicity</label>
															<span>{{ member.personal_details.ethnicity || "N/A" }}</span>
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label">Disability</label>
															<span>{{ member.personal_details.disability || "N/A" }}</span>
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label">Veteran Status</label>
															<span>{{ member.personal_details.veteran_status || "N/A" }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="card card-primary card-outline">
										<div class="card-header">
											<div class="modal-btn float-right">
												<b-button @click="editFamilyMemberSportDiscipline(index)" v-if="subdomain == 'waterski' && ((memberInfoData.hoh_member_id != '' && memberInfoData.hoh_member_id != null && memberInfoData.hoh_member_id == member.confirmation_code) || (memberInfoData.hoh_member_id == null || memberInfoData.hoh_member_id == ''))"><i class="far fa-edit"></i> Edit </b-button>
											</div>
											<h3>Contact Information</h3>
										</div>
										<div class="card-body">
											<div class="row">
												<div  class="col-md-4" v-if="prop_fields.homephone">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.homephone.label || 'Phone 1' }}</label>
														<span >{{ member.contact_details.phone_1 || "N/A" }}</span>
													</div>
												</div>
												<div  class="col-md-4" v-if="prop_fields.mobile">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.mobile.label || 'Phone 2' }}</label>
														<span >{{ member.contact_details.phone_2 || "N/A" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.email">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.email.label || 'Email' }}</label>
														<span >{{ member.contact_details.email || "N/A" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.address1">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.address1.label || 'Address Type'}}</label>
														<span >{{ member.contact_details.address1 || "N/A"}}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.address2">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.address1.labe2 || 'Address 2'}}</label>
														<span >{{ member.contact_details.address2 || "" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.city">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.city.label || 'City'}}</label>
														<span >{{ member.contact_details.city || "" }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.state_code">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.state_code.label || 'City'}}</label>
														<span >{{ member.contact_details.state || member.contact_details.state_code }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.country">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.country.label || 'City'}}</label>
														<span >{{ member.contact_details.country }}</span>
													</div>
												</div>
												<div class="col-md-4" v-if="prop_fields.zip">
													<div class="form-group">
														<label class="form-label">{{ prop_fields.zip.label || 'Zip'}}</label>
														<span >{{ member.contact_details.zip }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="overlay dark" v-if="cardrefresh.loader == true && cardrefresh.id == member.id"><i class="fas fa-2x fa-circle-notch fa-spin"></i></div>
						</b-tab>
					</b-tabs>
				</div>
			</div>

			<b-modal id="modal-family-delete" hide-footer="true" ref="modal-family-member-delete" size="sm" title="Warning...">
				<p class="font-weight-bold text-center">Are you sure to delete the family member?</p>
				<p class="text-center" v-if="!btn_deleting"><b-button class="btn-danger" @click="confirmDelete()"><i class="fas fa-trash-alt"></i> Delete</b-button></p>
				<p class="text-center" v-if="btn_deleting"><b-button class="btn-danger disabled" disabled><i class="fas fa-circle-notch fa-spin"></i> Deleting...</b-button></p>
			</b-modal>
			<familymemberprofileadd ref="familyMemberProfileAddComponent" @memfresh="loadMembersInfo(); memrefresh($event)" ></familymemberprofileadd>
			<familymemberpersonalinfo ref="FamilyMemberPersonalInfo" @memfresh="loadMembersInfo(); memrefresh($event)" ></familymemberpersonalinfo>
			<familymemberdemographicinfo ref="FamilyMemberDemographicInfo" @memfresh="loadMembersInfo(); memrefresh($event)" ></familymemberdemographicinfo>
			<familymembercontact ref="FamilyMemberContact" @memfresh="loadMembersInfo(); memrefresh($event)" ></familymembercontact>
		</div>
	</section>
</template>

<script>

// import navigation from "./components/Navigation";
// import sidebar from "./components/Sidebar";
import dashboardfooter from "./components/Footer";
import familymemberprofileadd from "./FamilyMemberProfileAdd";
import familymemberpersonalinfo from "./FamilyMemberPersonalInfo";
import familymemberdemographicinfo from "./FamilyMemberDemographicInfo";
import familymembercontact from "./FamilyMemberContact";
import moment from "moment";
import Vue from "vue";
import axios from 'axios';
import membercard from "../../Member/Dashboard/profile/membercard.vue";

Vue.filter('formatDob', function(value) {
	if (value) {
		return moment(String(value)).format('MM/DD/YYYY')
	}
});

export default {
	name: "familyprofile",
	components: { membercard, familymemberprofileadd, familymemberpersonalinfo, familymemberdemographicinfo, familymembercontact },
	data() {
		return {
			totmember: 5,
			spouseCount:0,
			cardrefresh: {loader:false, id:null},
			btn_enable:{add:false,edit:{personal:false, demographic:false, sportdecipline:false}},
			btn_deleting:false,
			delete_memberid: null,
			currentdate: new Date().getFullYear(),
			memberInfoData:"",
			familyData: "",
			relationship: {
				'0': "HOH",
				'1': "Spouse",
				'2': "Child",
				'3': "Dependent"
			},
			diversities:[],
			regInfo:[],
			prop_fields: null,
		}
	},
	methods: {
		btnEnableDisable(){
			this.btn_enable.add = (this.memberInfoData.relation == 0 ? true: false);
			this.btn_enable.edit.personal = (this.memberInfoData.relation == 0 ? true: false);
			this.btn_enable.edit.demographic = (this.memberInfoData.relation == 0 ? true: false);
			this.btn_enable.edit.sportdecipline = (this.memberInfoData.relation == 0 ? true: false);
		},
		memrefresh (g) {
			this.cardrefresh.loader = g.loader;
			this.cardrefresh.id = g.id;
		},
		deletemember(memid) {
			this.delete_memberid = memid;
		},
		confirmDelete() {
			this.btn_deleting = true;

			axios.post(this.basePath+"api/member/family/remove", { data: {member_id:this.delete_memberid} }, { headers:this.memberHeaders }).then(function (response) {
				if(response.data.status == 'success') {
					this.$bvToast.toast('Family Member Information deleted successfully', { title:'Success....', variant: "success", solid: true, toaster: 'b-toaster-top-center', autoHideDelay: 8000, appendToast: true, 'header-class':'bg-success text-white' });
					this.$refs['modal-family-member-delete'].hide();
					this.btn_deleting = false;
					this.loadMembersInfo();
					this.memrefresh ({loader:true, id:this.delete_memberid});
				} else  {
					this.$bvToast.toast('Family Member Information not deleted', { title:'Error....', variant: "danger", solid: true, toaster: 'b-toaster-top-center', autoHideDelay: 8000, appendToast: true, 'header-class':'bg-danger' });
					this.btn_deleting = false;	
				}
			}).catch(function (error) {
				this.$bvToast.toast('Something went wrong', { title:'Error....', variant: "danger", solid: true, toaster: 'b-toaster-top-center', autoHideDelay: 8000, appendToast: true, 'header-class':'bg-danger' });
				this.btn_deleting = false;
			});
		},
		handleSubmit(requestType, url) {
			return false;
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault();
			alert('Modal Hide Prevented');
			return false;
		},
		loadMembersInfo(){
			this.memrefresh({loader: true, id: null});
			axios.get(this.basePath+"api/family_profile",{ headers:this.memberHeaders })
				.then((response)=>{
					const resp  = response.data.data;
					this.regInfo = resp;
					this.familyData = resp.families;
					this.totmember = this.familyData.length;
					this.cardrefresh.loader = false;
					this.cardrefresh.id = null;
					this.spouseCount = 0;
					let count = 0;
					this.familyData.forEach(function(v, i) {
						(v.personal_details.relation == 1 ? count++ : '');
					});
					this.spouseCount = count;
				}).catch((error) =>{
					this.cardrefresh.loader = false;
					this.cardrefresh.id = null;
			});
		},
		getDiversity() {
			axios.get(this.basePath+"api/getDiversity")
				.then(response => {
					this.diversities = response.data.data;
				})
				.catch(err => {
					console.log(err);
				})
		},
		loadPropFields(){
			const retry = setInterval(()=>{
				this.prop_fields = this.getSiteProps('memberRegistration.family.fields.family.popup');
				if(this.prop_fields) clearTimeout(retry);
			}, 200);
		},
		addFamilyMember(id) {
			let arg = (id == null ? {} : this.familyData[id]);
			this.$refs.familyMemberProfileAddComponent.showModal(arg);
		},
		editFamilyMemberPersonal(id) {
			let arg = (id == null ? {} : this.familyData[id]);
			this.$refs.FamilyMemberPersonalInfo.showEditModal(arg);
		},
		editFamilyMemberDemographic(id) {
			let arg = (id == null ? {} : this.familyData[id]);
			this.$refs.FamilyMemberDemographicInfo.showEditModal(arg);
		},
		editFamilyMemberSportDiscipline(id) {
			let arg = (id == null ? {} : this.familyData[id]);
			this.$refs.FamilyMemberContact.showEditModal(arg);
		},
		getFamilyRelationTxt(relation){
			let cats = { 0: 'HOH', 1: 'Spouse', 2: 'Child', 3: "Dependent" };
			return cats[relation];
		},
		refreshCard(member_id){
			this.refreshProgress = true;
			// let member_id = this.userProfileData.id;
			this.displayAlert = false;
			axios.post(this.basePath+"api/member/refresh-card", { member: member_id })
			.then(res => {
				this.refreshProgress = false;
				this.loadMembersInfo();
			})
			.catch(err => {
				console.log(err);
				this.refreshProgress = false;
			})
		},
	},
	mounted() {
		this.memberInfoData = JSON.parse(localStorage.getItem('member'));
		this.btnEnableDisable();
		this.loadMembersInfo();
		this.getDiversity();
		this.loadPropFields();
		this.getDiscipline(1);
	}
}
</script>
<style>
	.profile-body .card-header {
		background: #fff !important;
		padding: 10px;
	}
</style>