<template>
    <form @submit.prevent="clubData" method="post">
<div class="card club-info">
   <div class="card-header register-title">
      <h3> {{ fields.tab_title || 'Club Information'}}</h3>
   </div>
   <div class="card-body pr-4 pl-4 clubinfo">
      <div class="row">
         <div :class="[fields.clubname.colClass]" v-if="fields.clubname">
            <div class="form-group">
               <label for="clubname" class="form-label">{{ fields.clubname.label }}  <sup class="text-danger" v-if="fields.clubname.required">*</sup></label>
               <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="clubname" placeholder="Enter Club Name" v-model="form.name" name="form.name">
                <span class="text-sm text-danger">{{ errors.name }}</span>
              <span class="text-sm text-danger" v-if="duplicateError.hasError">{{ duplicateError.errorMessage}}</span>
              <a href="/club/login" v-if="duplicateError.showLink" target="_blank"> Click here</a>
            </div>
         </div>
         <div :class="[fields.primary_club_type.colClass]" v-if="fields.primary_club_type">
            <div class="form-group">
               <label for="clubname"  class="form-label">{{ fields.primary_club_type.label }} <sup class="text-danger" v-if="fields.primary_club_type.required">*</sup></label><br>
              <select name="club_type" class="form-control" v-model="form.type">
                <option value="">Select</option>
                <option v-for="(typ, val) in clubType" :value="val" :key="val">{{ typ }}</option>
              </select>
               <span class="text-sm text-danger">{{ errors.type }}</span>
            </div>
         </div>
          <div :class="[fields.secondary_club_type.colClass]" v-if="fields.secondary_club_type">
              <div class="form-group">
                  <label for="clubname"  class="form-label">{{ fields.secondary_club_type.label }}</label><br>
                    <select name="club_type" class="form-control" v-model="form.secondary_type">
                      <option value="">Select</option>
                      <option v-for="(typ, val) in clubType" :value="val" :key="val">{{ typ }}</option>
                    </select>
              </div>
          </div> 
      <div :class="[fields.club_website.colClass]" v-if="fields.club_website">
         <div class="form-group">
            <label for="clubweb"  class="form-label ">{{ fields.club_website.label }}</label>
            <div class="clearfix"></div>
            <div class="left-field">
               <input type="text" class="form-control" maxlength="45" id="clubweb" aria-describedby="clubweb" placeholder="Enter Club Website" name="form.website" v-model="form.website">
            </div>
         </div>
      </div>
      <!-- <div class="col-md-12">
        <div class="form-group">
            <label for="phonenumber" class="form-label">Phone Number <sup class="text-danger">*</sup></label>
            <input type="text" class="form-control" placeholder="Phone Number"  id="phonenumber"  name="" value="">      
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
            <label for="faxnumber" class="form-label">Fax Number <sup class="text-danger">*</sup></label>
            <input type="text" class="form-control"  placeholder="Fax Number" id="faxnumber"  name="" value="">      
        </div>
      </div> -->
      <div :class="[fields.include_directory.colClass]" v-if="fields.include_directory">
          <div class="form-group">
              <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="directoryYes" :value="0" v-model="form.exclude" name="form.exclude">
                  <label class="form-check-label" for="directoryYes">{{ fields.include_directory.label }}</label>
              </div>
          </div>
      </div>
          <div :class="[fields.email.colClass]" v-if="fields.email">
              <div class="form-group">
                  <label for="email" class="form-label">{{ fields.email.label }}<sup class="text-danger" v-if="fields.email.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="email" v-model="form.email" name="form.email">
                  <span class="text-sm text-danger">{{ errors.email}}</span>
              </div>
          </div>
          <div :class="[fields.club_address.colClass]" v-if="fields.club_address">
              <div class="form-group">
                  <label for="club_address" class="form-label">{{ fields.club_address.label }} <sup class="text-danger" v-if="fields.club_address.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="club_address" name="form.address" v-model="form.address">
                  <span class="text-sm text-danger">{{ errors.club_address }}</span>
              </div>
          </div>
        <div :class="[fields.club_address2.colClass]" v-if="fields.club_address2">
          <div class="form-group">
            <label for="club_address" class="form-label">{{ fields.club_address2.label }} <sup class="text-danger" v-if="fields.club_address2.required">*</sup></label>
            <input type="text" class="form-control" maxlength="45" id="club_address" name="form.address" v-model="form.address2">
            <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
          </div>
        </div>
          <div :class="[fields.club_city.colClass]" v-if="fields.club_city">
              <div class="form-group">
                  <label for="club_city" class="form-label">{{ fields.club_city.label }} <sup class="text-danger" v-if="fields.club_city.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="club_city" name="form.city" v-model="form.city">
                  <span class="text-sm text-danger">{{ errors.club_city }}</span>
              </div>
          </div>
          <div :class="[fields.club_state.colClass]"  v-if="form.country == 'USA' && fields.club_state ">
              <div class="form-group">
                  <label for="club_state" class="form-label">{{ fields.club_state.label }}<sup class="text-danger" v-if="fields.club_state.required">*</sup></label>
                  <select class="form-control" id="club_state" name="form.state_code" v-model="form.state_code">
                      <option value="">Select</option>
                      <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.club_state_code }}</span>
              </div>
          </div>
          <div :class="[fields.club_state_code.colClass]" v-else-if="fields.club_state_code">
              <div class="form-group" v-if="fields.club_state_code">
                  <label for="club_state_code" class="form-label">{{ fields.club_state_code.label }}<sup class="text-danger" v-if="fields.club_state_code.required">*</sup></label>
                  <input type="text" class="form-control" id="club_state_code" maxlength="45" v-model="form.state" name="form.state">
                  <span class="text-sm text-danger">{{ errors.club_state }}</span>
              </div>
          </div>
          <div :class="[fields.club_zip.colClass]" v-if="fields.club_zip">
              <div class="form-group">
                  <label for="club_zip" class="form-label">{{ fields.club_zip.label }}<sup class="text-danger" v-if="fields.club_zip.required">*</sup></label>
                  <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="club_zip"  name="form.zip" v-model="form.zip">
                  <span class="text-sm text-danger">{{ errors.club_zip }}</span>
              </div>
          </div>
        <div :class="[fields.club_mail_address.colClass]" v-if="fields.club_mail_address">
            <div class="form-group">
                <label for="mailingaddress" class="form-label">{{ fields.club_mail_address.label || 'Club Mailing Address'}} <sup class="text-danger" v-if="fields.club_mail_address.required">*</sup></label>
                <input type="text" class="form-control" id="mailingaddress"  name="" value="">      
            </div>
        </div>
        <div :class="[fields.club_mail_city.colClass]" v-if="fields.club_mail_city">
            <div class="form-group">
                <label for="city" class="form-label">{{ fields.club_mail_city.label || 'City'}} <sup class="text-danger" v-if="fields.club_mail_city.required">*</sup></label>
                <input type="text" class="form-control"  id="mailingcity" placeholder="City" name="" value="">      
            </div>
        </div>
        <div :class="[fields.club_mail_state.colClass]" v-if="fields.club_mail_state">
            <div class="form-group">
                <label for="mailingstate" class="form-label">{{ fields.club_mail_state.label || 'State'}} <sup class="text-danger" v-if="fields.club_mail_state.required">*</sup></label>
                <input type="text" class="form-control"  id="mailingstate"  name="" value="">      
            </div>
        </div>
        <div :class="[fields.club_mail_zip.colClass]" v-if="fields.club_mail_zip">
            <div class="form-group">
                <label for="mailingzip" class="form-label">{{ fields.club_mail_zip.label || 'Zip'}} <sup class="text-danger" v-if="fields.club_mail_zip.required">*</sup></label>
                <input type="text" class="form-control"  id="mailingzip"  name="" value="">      
            </div>
        </div>
      <!-- multiple club start-->
        <div class="col-md-12" v-if="fields.club_info">
          <div class="ins-description form-group m-0">
            <label class="form-label">{{ fields.club_info.description }}</label>
          </div>
        </div>
          <div v-if="clubAddresses.length > 0" class="col-md-12">
              <div class="col-md-12 school-repeat mb-3 p-2 border" v-for="(showAddress, y) in clubAddresses" :key="y">

                  <div class="row oneline-style">
                    <div  class="col-md-6">
                      <label class="form-label">
                          <span class="text-success" v-if="showAddress.is_primary"><i class="fa fa-check fa-lg"></i> Primary / Home Ski</span>
                      </label>
                    </div>
                    <div  class="col-md-6 text-right">
                        <div class="d-flex float-right">
                            <button  type="button" class="btn-edit mr-1" @click="editAddress(y)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</button>
                            <button  type="button" class="btn-remove  ml-1" @click="removeAddress(y)"><img src="/dist/img/memberregistration/remove.png" alt="remove_icon"> Remove</button>
                        </div>

                    </div>
                  <div class="col-md-12">
                      <div class="form-group mt-2">
                         <label class="form-label">{{ fields.club_info.school.label }}</label><br>
                        <span class="title">{{ showAddress.school }}</span>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label class="form-label">{{ fields.club_info.address.label }}</label><br>
                        <span class="title">{{ showAddress.address }}</span>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label class="form-label">{{ fields.club_info.city.label }}</label><br>
                          <span class="title">{{ showAddress.city }}</span>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label class="form-label">{{ fields.club_info.state.label }}</label><br>
                          <span class="title" >{{ showAddress.state_code }}</span>
                          <!-- <span class="title">{{ showAddress.state }}</span> -->
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label class="form-label">{{ fields.club_info.zip.label }}</label><br>
                        <span class="title">{{ showAddress.zip }}</span>
                      </div>
                  </div>

                </div>
              </div>
          </div>
          <div class="col-md-12">
              <span style="display:block;" class="text-sm text-danger">{{ errors.team_sites }}</span>
          </div>
          <div class="" v-if="fields.club_info">
              <div class="col-md-12 addnewname">
                  <b-button v-b-modal.modal-club-address @click.prevent="clubAddressInfoModal" class="mt-1"> + Add site information</b-button>
                  <span class="text-sm text-danger"><br>{{ errors.primary }}</span>
                  <b-modal ok-only
                  ok-title="Add Site Information"
                   ref="modal"
                           @show="resetModalClubAddress"
                           @hidden="resetModalClubAddress"
                           @ok="handleOkClubAddress" id="modal-club-address" size="lg" centered title="Site Information">
                      <form ref="form" @submit.stop.prevent="handleSubmitClubAddress">
                          <div class="popupbody row">
                              <div class="col-md-6" v-if="fields.club_info.school">
                                  <div class="form-group">
                                      <label for="school" class="form-label">{{ fields.club_info.school.label }} <i class="text-danger"  v-if="fields.club_info.school.required">*</i></label>
                                      <input type="text" class="form-control" maxlength="45" id="school" aria-describedby="school" placeholder="Enter Specific site" name="form.school" v-model="address.school">
                                      <span class="text-sm text-danger">{{ errors.school }}</span>
                                  </div>
                              </div>

                            <div class="col-md-6" v-if="fields.club_info.membership_sign_social">
                              <div class="form-check mt-4">
                                <input type="checkbox" id="membership_sign_social" name="form.membership_sign_social" class="form-check-input" v-model="address.is_primary" value="1" checked>
                                <label for="membership_sign_social" class="form-check-label font-13">{{ fields.club_info.membership_sign_social.label }} <i class="text-danger"  v-if="fields.club_info.membership_sign_social.required">*</i></label>
                              </div>
                            </div>
                              <div class="col-md-6" v-if="fields.club_info.address">
                                  <div class="form-group">
                                      <label for="address" class="form-label">{{ fields.club_info.address.label }} <i class="text-danger"  v-if="fields.club_info.address.required">*</i> </label>
                                      <input type="text" class="form-control" maxlength="45" id="address" name="address.address" v-model="address.address">
                                      <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
                                  </div>
                              </div>
                              <div class="col-md-6" v-if="fields.club_info.address2">
                                <div class="form-group">
                                  <label for="address" class="form-label">{{ fields.club_info.address2.label }} <i class="text-danger"  v-if="fields.club_info.address2.required">*</i> </label>
                                  <input type="text" class="form-control" maxlength="45" id="address2" name="address.address2" v-model="address.address2">
                                  <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
                                </div>
                              </div>
                              <div class="col-md-6" v-if="fields.club_info.city">
                                  <div class="form-group">
                                      <label for="city" class="form-label">{{ fields.club_info.city.label }}  <i class="text-danger"  v-if="fields.club_info.city.required">*</i></label>
                                      <input type="text" class="form-control" maxlength="45" id="city" name="form.city" v-model="address.city">
                                      <span class="text-sm text-danger">{{ errors.city }}</span>
                                  </div>
                              </div>

                              <div class="col-md-6" v-if="fields.club_info.state">
                                  <div class="form-group">
                                      <label for="state" class="form-label">{{ fields.club_info.state.label }} <i class="text-danger" v-if="fields.club_info.state.required">*</i></label>
                                      <select class="form-control" id="state" name="form.state_code" v-model="address.state_code">
                                          <option value="">Select</option>
                                          <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                      </select>
                                      <span class="text-sm text-danger">{{ errors.state_code }}</span>
                                  </div>
                              </div>

                              <div class="col-md-6" v-if="fields.club_info.zip">
                                  <div class="form-group">
                                      <label for="zip" class="form-label">{{ fields.club_info.zip.label }} <i class="text-danger" v-if="fields.club_info.zip.required">*</i></label>
                                      <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip"  name="form.zip" v-model="address.zip">
                                      <span class="text-sm text-danger">{{ errors.zip }}</span>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </b-modal>
              </div>
          </div>
      <!-- multiple club end-->

        <!-- For shooting -->
        <div :class="[fields.club_visible_type.colClass]" v-if="fields.club_visible_type">
            <div class="form-group">
                <label for="club_visible_type" class="control-label">{{fields.club_visible_type.label || 'Club Type'}}<sup class="text-danger" v-if="fields.club_visible_type.required">*</sup></label>
                <select name="form.club_visible_type" id="club_visible_type" class="form-control" v-model="form.club_visible_type">
                    <option value="">Select</option>
                    <option v-for="(item, k) in fields.club_visible_type.choices" :key="'club_visible_type'+k">{{ item }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.club_visible_type }}</span>
            </div>
        </div>
        <div :class="[fields.club_type.colClass]" v-if="fields.club_type">
            <div class="form-group">
                <label for="club_type" class="control-label">{{fields.club_type.label || 'Club Type'}}<sup class="text-danger" v-if="fields.club_type.required">*</sup></label>
                <Select2 v-if="fields.club_type.multiple" v-model="form.club_type" name="form.club_type" id="club_type" :options="fields.club_type.choices" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                <select name="form.club_type" id="club_type" class="form-control" v-model="form.club_type" v-else>
                    <option value="">Select</option>
                    <option v-for="(item, k) in fields.club_type.choices" :key="'club_type'+k">{{ item }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.club_type }}</span>
            </div>
        </div>
        <div :class="[fields.club_discipilines.colClass]" v-if="fields.club_discipilines">
            <div class="form-group discipilineitem">
                <label for="" class="form-label">{{ fields.club_discipilines.label || 'Club Disciplines' }}<sup class="text-danger" v-if="fields.club_discipilines">*</sup></label>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <div class="form-check">
                            <input class="form-check-input club_discipilines_check" type="checkbox" id="clubdisciplines-all" 
                            name="club_disciplines_all" @change="triggerSelectDisciplinesAll">
                            <label class="d-flex mr-1" for="clubdisciplines-all">Select all that apply</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4" v-for="(name, id) in clubDisciplinesList" :key="'cd_'+id">
                        <div class="form-check">
                            <input class="form-check-input club_discipilines_check" type="checkbox" :id="'clubdisciplines-'+id" name="form.club_disciplines" :value="id" v-model="form.club_discipilines">
                            <label class="d-flex mr-1" :for="'clubdisciplines-'+id">{{ name }}</label>
                        </div>
                    </div>
                </div>
                <span class="text-sm text-danger">{{ errors.club_discipilines }}</span>
            </div>
        </div>
        <div :class="[fields.club_admin_member_id.colClass]" v-if="fields.club_admin_member_id">
            <div class="form-group">
                <label for="clubname" class="form-label">{{ fields.club_admin_member_id.label }}  <sup class="text-danger" v-if="fields.club_admin_member_id.required">*</sup></label>
                <div class="input-group">
                    <input type="number" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_member_id" name="form.club_admin_member_id" v-model="form.club_admin_member_id">
                    <div class="input-group-append">
                        <button class="btn btn-outline-primary" type="button" @click="searchMember"><i class="fa fa-search"></i> Search</button>
                    </div>
                </div>
                <span class="text-sm text-danger">{{ errors.club_admin_member_id }}</span>
            </div>
            <label class="text-danger" v-if="clubAdminSearch.searched && form.club_admin_name == ''">No Member Found with Member ID #{{ clubAdminSearch.memberIDSearched }}</label>
         </div>
         <div :class="[fields.club_admin_name.colClass]" v-if="fields.club_admin_name">
            <div class="form-group">
                <label for="clubname" class="form-label">{{ fields.club_admin_name.label }}  <sup class="text-danger" v-if="fields.club_admin_name.required">*</sup></label>
                <input type="text" class="form-control" maxlength="45" id="clubname" aria-describedby="club_admin_name" name="form.club_admin_name" v-model="form.club_admin_name" :readonly="fields.club_admin_name.readonly" :placeholder="fields.club_admin_name.placeholder">
                <span class="text-sm text-danger">{{ errors.club_admin_name }}</span>
            </div>
         </div>
        <!-- For shooting -->

      </div>
   </div>
        <div class="col-md-12" v-show="overAllError != 0">
        <p class="text-danger text-right">Please fill the above missing fields.</p>
        </div>
<div class="col-md-12">
        <div class="text-right topspace">
            <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
            <button type="submit" class="btn btn-outline-primary savebtn next-step-btn" :disabled="checkingDuplicate">
                <span v-if="checkingDuplicate"><i class="fa fa-circle-notch fa-spin"></i></span>
                <span v-else>Next <i class="fas fa-chevron-right pl-2"></i></span>
            </button>
        </div>
    </div>
</div>
    </form>
</template>

<script>
import axios from 'axios';
const validateName = name => {
    if ( name == undefined || !name.length || name.trim() == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateStateName = name => {
    console.log('v state');
    console.log(name);
    if (name == null || name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    console.log('lenghth='+select);
    if (!select) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateCheck = choices => {
    if(choices.length) return { valid: true, error: null };
    return { valid: false, error: "This field is required" };
}
const validateZip = (zipCode) => {
  if(zipCode == undefined || !zipCode.length || zipCode.trim() == "")
  { 
    return { valid: false, error: "Zip code is required" };
  }
  else if(zipCode.length < 5 ){
         return { valid: false, error: "Zip code should be 5 character" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: "Zip Code Should Be 5 Number Values." };
  }

  return { valid: true, error: null };
}

export default {
    name: "ClubInformation",
    props: ['fields', 'duplicateError', 'checkingDuplicate'],
    data() {
        return{
           inputs: [],
            form: {
                name: '',
                type: '',
                secondary_type: '',
                website: '',
                team_sites: [],
                exclude: 1,
                email: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                state_code: '',
                country: 'USA',
                zip: '',
                club_visible_type: "",
                club_type: "",
                club_discipilines: [],
                club_admin_member_id: "",
                club_admin_name: "",
                club_sanction: 0,
            },
            address: {
                school: '',
                is_primary: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                state_code: '',
                country: 'USA',
                zip: '',
                index: 0
            },
            addressValid: false,
            valid: false,
            success: false,
            errors: {},
            message: null,
            edit: false,
            clubAddresses: [],
            clubAdminSearch: {
                searched: false,
                memberIDSearched: ''
            },
            isPrimarySiteAdded: false,
            overAllError:0,
        }
    },

     methods: {
         clubAddressInfoModal() {
             this.edit = false;
         },
         resetModalClubAddress() {
             this.address = {
               school: '',
               is_primary: '',
               address: '',
               city: '',
               state: '',
               state_code: '',
               country: 'USA',
               zip: '',
               index: 0
             }
         },
      clubData() {
             if(this.validate()) {
                 console.log('valid');
                 this.$emit('club-data', this.form);
                 return;
             } else {
                 console.log('un valid');
             }
         },
         previousStep() {
             this.$emit('previous-step');

             return;

         },
         handleOkClubAddress(bvModalEvt) {
             // Prevent modal from closing
             bvModalEvt.preventDefault()
             // Trigger submit handler
             this.handleSubmitClubAddress()
         },
         handleSubmitClubAddress() {
             // Exit when the form isn't valid
             if (!this.addressValidate()) {
                 return;
             }
           this.address.primary_club = 'No';
           if(this.address.is_primary == true)
           {
             this.address.primary_club = 'Yes';
             this.clubAddresses.forEach((value, index) => {
               this.clubAddresses[index].is_primary = false;
             });
           }
             if(this.edit == false) {
                 this.address.index = this.form.team_sites.length;
             }
             if(this.edit == false) {
                 this.form.team_sites.push(this.address);
                 this.clubAddresses.push(this.address);
             } else {
                 this.form.team_sites[this.address.index] = this.address;
                 this.clubAddresses[this.address.index] = this.address;
                 this.edit = true;
             }
             this.isPrimarySiteAdded = false;
             let primarySite = this.clubAddresses.filter(site => site.is_primary == true);
             if(primarySite.length) this.isPrimarySiteAdded = true;
             this.addressValid = true;
             // Hide the modal manually
             this.$nextTick(() => {
                 this.$bvModal.hide('modal-club-address')
             })
         },
         // Custom Validation Rules
         validate() {
             this.errors = {}
             this.valid = false;
             this.overAllError = 1;
             const validName = validateName(this.form.name);
             this.errors.name = validName.error;

             const clubType = validateSelect(this.form.type);
             this.errors.type = clubType.error;

             const clubEmail = validateName(this.form.email);
             this.errors.email = clubEmail.error;

             const clubAddress = validateName(this.form.address);
             this.errors.club_address = clubAddress.error;

             const clubCity = validateName(this.form.city);
             this.errors.club_city = clubCity.error;

             const clubStateCode = validateSelect(this.form.state_code);
             this.errors.club_state_code = clubStateCode.error;

             const clubZip = validateZip(this.form.zip);
             this.errors.club_zip = clubZip.error;

            if(this.subdomain == 'waterski'){
                const addressLengthValid = this.clubAddresses.length;
                if(addressLengthValid == 0) {
                    this.errors.team_sites = 'Add at least one ski site where club activities take place.';
                } else {
                    if(this.isPrimarySiteAdded == false) {
                        this.errors.team_sites = 'Add at least one primary / ski site';
                        this.addressValid = false;
                    } else {
                        this.addressValid = true;
                        this.errors.team_sites = '';
                    }
                }
            } else {
                this.addressValid = true;
            }

            if(document.querySelector(".clubinfo #clubname") == null) validName.valid = true;
            if(document.querySelector(".clubinfo [name='club_type']") == null) clubType.valid = true;
            if(document.querySelector(".clubinfo #email") == null) clubEmail.valid = true;

            if (validName.valid && this.addressValid && clubType.valid && clubEmail.valid && clubZip.valid && clubAddress.valid && clubCity.valid && clubStateCode.valid) {
                this.valid = true;
                this.overAllError = 0;
            }

            //Shooting
            var clubVisibleType = "";
            if(document.querySelector(".clubinfo #club_visible_type") != null && this.fields != undefined
            && this.fields.club_visible_type != undefined && this.fields.club_visible_type.required != undefined
            && this.fields.club_visible_type.required ){
                clubVisibleType = validateSelect(this.form.club_visible_type);
                this.errors.club_visible_type = clubVisibleType.error;
            }
            var clubType2 = "";
            if(document.querySelector(".clubinfo #club_type") != null && this.fields != undefined
            && this.fields.club_type != undefined && this.fields.club_type.required != undefined
            && this.fields.club_type.required ){
                clubType2 = validateSelect(this.form.club_type);
                if(Array.isArray(this.form.club_type)){
                    if(this.form.club_type.length == 0){
                        clubType2.valid = false;
                        clubType2.error = "This field is required";
                    } else {
                        clubType2.valid = true;
                        clubType2.error = "";
                    }
                }
                this.errors.club_type = clubType2.error;
            }
            var clubdisciplines = "";
            if(document.querySelector(".clubinfo [name='form.club_disciplines']") != null && this.fields != undefined
            && this.fields.club_discipilines != undefined && this.fields.club_discipilines.required != undefined
            && this.fields.club_discipilines.required ){
                clubdisciplines = validateCheck(this.form.club_discipilines);
                this.errors.club_discipilines = clubdisciplines.error;
            }
            var clubAdminID = "";
            if(document.querySelector(".clubinfo [name='form.club_admin_member_id']") != null && this.fields != undefined
            && this.fields.club_admin_member_id != undefined && this.fields.club_admin_member_id.required != undefined
            && this.fields.club_admin_member_id.required ){
                clubAdminID = validateName(this.form.club_admin_member_id);
                this.errors.club_admin_member_id = clubAdminID.error;
            }
            var clubAdminName = "";
            if(document.querySelector(".clubinfo [name='form.club_admin_name']") != null && this.fields != undefined
            && this.fields.club_admin_name != undefined && this.fields.club_admin_name.required != undefined
            && this.fields.club_admin_name.required ){
                clubAdminName = validateName(this.form.club_admin_name);
                this.errors.club_admin_name = clubAdminName.error;
            }

            if(clubVisibleType != "" && clubType2 != "" && clubdisciplines != "" && clubAdminID != "" && clubAdminName != ""){
                if(clubVisibleType.valid && clubType2.valid && clubdisciplines.valid && clubAdminID.valid && clubAdminName.valid && validName.valid) {
                    this.valid = true;
                    this.overAllError = 0;
                }
                else
                this.valid = false;
            }

            //Shooting

             return this.valid;
         },
         addressValidate() {
             this.errors = {}
             this.valid = false;
             const validSchool = validateStateName(this.address.school);
             this.errors.school = validSchool.error;

             //const validAddress = validateStateName(this.address.address);
             //this.errors.address = validAddress.error;

             const validStateCode = validateSelect(this.address.state_code);
            this.errors.state_code = validStateCode.error;
             const validCity = validateStateName(this.address.city);
             this.errors.city = validCity.error;

             const validZip = validateZip(this.address.zip);
             this.errors.zip = validZip.error;
            console.log('process.env.VUE_APP_WATERSKI_EMS_URL',process.env.VUE_APP_WATERSKI_EMS_URL);
             if (validSchool.valid && validStateCode.valid && validCity.valid && validZip.valid) {
                 this.addressValid = true;
             }else{
                 this.addressValid = false;
             }

             return this.addressValid;
         },
         removeAddress(i) {
             console.log("remove index="+i);
             console.log(this.form.team_sites.length);
             this.form.team_sites.splice(i,1);
             this.clubAddresses.splice(i,1);
             if(this.form.team_sites.length == 0) {
                 this.valid = false;
                 this.addressValid = false;
             }
         },
         editAddress(i) {
             console.log('address index' + i);
             console.log(this.form.team_sites[i]);
             this.edit = true;
             this.$bvModal.show('modal-club-address');
             this.address = {
                is_primary: this.form.team_sites[i].is_primary || false,
                school: this.form.team_sites[i].school,
                address: this.form.team_sites[i].address,
                city: this.form.team_sites[i].city,
                state: this.form.team_sites[i].state,
                state_code: this.form.team_sites[i].state_code,
                country: this.form.team_sites[i].country,
                zip: this.form.team_sites[i].zip,
                index: i
             }
             console.log(this.address);
             console.log(this.address.school);
         },
        triggerSelectDisciplinesAll(event){
            if(event.target.checked){
                var ids = Object.keys(this.clubDisciplinesList);
                if(ids.length){
                    ids.forEach(id => {
                        this.form.club_discipilines.push(id);
                    });
                }
            } else {
                this.form.club_discipilines = [];
            }
        },
        searchMember(){
            this.clubAdminSearch.searched = false;
            this.clubAdminSearch.memberIDSearched = "";
            if(this.form.club_admin_member_id){
                axios.get(this.basePath + "api/member_lookup?member_id="+this.form.club_admin_member_id).then(res => {
                    if(res.data.data != undefined && res.data.data.length){
                        this.errors.club_admin_name = "";
                        this.form.club_admin_name = res.data.data[0].personal_details.full_name
                    } else {
                        this.form.club_admin_name = "";
                    }
                }).catch(err => {
                    console.log(err);
                    this.clubAdminSearch.searched = true;
                    this.clubAdminSearch.memberIDSearched = this.form.club_admin_member_id;
                    
                }).finally(() => {
                    this.clubAdminSearch.searched = true;
                    this.clubAdminSearch.memberIDSearched = this.form.club_admin_member_id;
                });
            }
        }
    },
    mounted() {
      this.getClubType();
      if(this.subdomain == "shooting"){
          this.getClubDisciplines();
      }
    }
}


</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
