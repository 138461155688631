<template>
    <div class="login-logo" id="Logo">
        <a href="/"><img :src="this.getSiteProps('landingpage.banner')" v-if="this.getSiteProps('landingpage.banner')"  alt="Logo"></a>
    </div>
</template>

<script>
export default {
    name: "AuthHead",
    data() {
        return {
            image: '/dist/img/Intsports-logo-transperent.png'
        }
    }

}
</script>

<style scoped>

</style>
