<template>
  <div>
    <main class="main" role="main">
      <div class="bg-white">
        <div class="container-fluid">
          <div class="row">
            <div class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">
              <banner></banner>
            </div>
            <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-5 bg-white rightside_section">
             
               <div class="row" v-if="subdomain != 'waterski'" >
                <div class="col-md-12 d-flex " style="">
                   <div class="contentscroll  align-self-center w-100" v-html="getPrivacyContent"></div>
                    <!-- <div class="contentscroll  align-self-center w-100" v-html=" this.getSiteProps('privacypolicy.description')">              
                    </div> -->
                </div>                
              </div>
              <div class="row" v-else >
                <div class="col-md-12 d-flex ">
                    <div class="contentscroll" v-html=" this.getSiteProps('privacypolicy.description')">              
                    </div>
                </div>
              </div>       

              <div class="footer-inner">
                <div class="row footer-row">
                  <div class="col-md-7">
                    <p class="text-danger">
                      {{
                        this.getSiteProps("login.copyright") +
                          " " +
                          new Date().getFullYear()
                      }}
                    </p>
                  </div>
                  <div class="col-md-5">
                    <p class="text-right">
                      Powered by <a href="https://www.isportz.co/" target="_blank"><img
                        v-bind:src="
                          '/dist/img/logo.svg'
                        "
                        style="width:80px"
                        alt="footer-img"
                      /></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import banner from "./banner";
import privacypolicy from './shootingcontent/PrivacyPolicy.txt';
import allianceprivacypolicy from './allianceFastpitchContent/PrivacyPolicy.txt';
export default {
  name: "registerlogin",
  components: { banner },
  data(){
    return{
      getPrivacyContent:""
    }
  },
  methods: {
    getPoemList ()
    {
      if(this.subdomain == 'alliancefastpitch'){
        this.getPrivacyContent = allianceprivacypolicy;
      }else {
        this.getPrivacyContent = privacypolicy;
      }
    },

    forgotPassword: function() {
      window.open(
        process.env.VUE_APP_URL + "registration/forgotpassword",
        "_self"
      );
    },
    register: function() {
      window.open(process.env.VUE_APP_URL + "memberregistration", "_self");
    },
  },
  mounted(){
    this.getPoemList (); 
  },

};
</script>

