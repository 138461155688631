<template>
  <form @submit.prevent="teamInfoData" method="post">
    <div class=" Contact-info">
      <div class="card-header register-title">
        <h3>{{ fields.tab_title || 'Contact Information' }}</h3>
      </div>
      <div class="card-body pr-4 pl-4">
        <div class="row">
          <div :class="[fields.ask_address.colClass]">
            <div class="form-group">
              <label for="ask_address" class="form-label">{{ fields.ask_address.label }} <sup class="text-danger" v-if="fields.ask_address.required">*</sup></label>
              <div class="form-group" v-if="fields.ask_address.choices">
                <template id="ask_address" v-for="(choice, key) in fields.ask_address.choices">
                  <input type="radio" :id="choice.value" v-model="form.ask_address" name="form.ask_address" :value="choice.label" @click="askAddress(choice.value)" :key="'radio_' + key" :disabled="isDisabled == 1">
                  <label class="ask_address-label-span mr-3" :for="choice.value" :key="'ask_address_label_'+key">{{ choice.label }}</label>
                </template>
                <span class="text-sm text-danger">{{ errors.ask_address}}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.address1.colClass]" v-if="fields.address1">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.address1.label }} <sup class="text-danger" v-if="fields.address1.required">*</sup></label>
              <input type="text" class="form-control" maxlength="100" id="address1" v-model="form.address1" name="form.address1" :disabled="isDisabled == 1">
              <span class="text-sm text-danger">{{ errors.address1}}</span>
            </div>
          </div>
          <div :class="[fields.address2.colClass]" v-if="fields.address2">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.address2.label }}</label>
              <input type="text" class="form-control" maxlength="45" id="address2" v-model="form.address2" name="form.address2" :disabled="isDisabled == 1">
              <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
            </div>
          </div>
        </div>

        <div class="row">
          <div :class="[fields.city.colClass]" v-if="fields.city">
            <div class="form-group">
              <label for="city" class="form-label">{{ fields.city.label }} <sup class="text-danger" v-if="fields.city.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="city"  v-model="form.city" name="form.city" :disabled="isDisabled == 1">
              <span class="text-sm text-danger">{{ errors.city}}</span>
            </div>
          </div>
          <div :class="[fields.state_code.colClass]" v-if="(form.country == 'USA' || form.country == '' )&& fields.state_code">
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state_code" @change="stateCode" name="form.state_code" :disabled="isDisabled == 1">
                <option value="">Select</option>
                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.state_code}}</span>
            </div>
          </div>
          <div :class="[fields.state_code.colClass]" v-else>
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state_code" @change="stateCode" name="form.state_code" :disabled="isDisabled == 1">
                <option value="">Select</option>
                <option  v-for="listOfState in fields.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.state_code}}</span>
            </div>
          </div>

          <div :class="[fields.zip.colClass]" v-if="fields.zip">
            <div class="form-group">
              <label for="zip" class="form-label">{{ fields.zip.label }} <sup class="text-danger" v-if="fields.zip.required">*</sup></label>
              <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')"  id="zip" v-model="form.zip" name="form.zip" :disabled="isDisabled == 1">
              <span class="text-sm text-danger">{{ errors.zip}}</span>
            </div>
          </div>
          <div :class="[fields.country.colClass]" v-if="fields.country">
            <div class="form-group">
              <label for="Country" class="form-label">{{ fields.country.label }} <sup class="text-danger" v-if="fields.country.required">*</sup></label>
              <select class="form-control" id="country" v-model="form.country" @change="removeStateCode" name="form.country" :disabled="isDisabled == 1">
                <option value="">Select</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in fields.country.options" :key="listOfCountry.country">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.country}}</span>
            </div>
          </div>
          <div :class="[fields.member_league.colClass]" v-if="fields.member_league">
            <div class="form-group">
              <label for="member_league" class="form-label">{{ fields.member_league.label }} <sup class="text-danger" v-if="fields.member_league.required">*</sup></label>
              <input type="text" class="form-control" maxlength="100" id="member_league" v-model="form.member_league_name" v-bind:readonly="true" name="form.member_league">
              <span class="text-sm text-danger">{{ errors.member_league}}</span>
            </div>
          </div>
          <div :class="[fields.age_division.colClass]" v-if="fields.age_division">
            <div class="form-group">
              <label for="age_division" class="form-label">{{ fields.age_division.label }} <sup class="text-danger" v-if="fields.age_division.required">*</sup></label>
              <select class="form-control" id="age_division" v-model="form.age_division" @change="getDivisionTeamList($event)" name="form.age_division" >
                <option value="">Select</option>
                <option  v-for="(listOfAgeDivision,key) in age_division_list" :key="key"  :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.age_division}}</span>
            </div>
          </div>
          <div :class="[fields.organization.colClass]" v-if="fields.organization">
            <div class="form-group">
              <label for="organization" class="form-label">{{ fields.organization.label }}<sup class="text-danger" v-if="fields.organization.required">*</sup></label>
              <Select2 v-model="form.id" :options="teamOptions" name="form.id" @change="teamChange" :settings="{ placeholder: 'Select a team'}" />
            </div>
            <span class="text-sm text-danger" v-if="fields.organization.helpText"><sup class="text-danger">*</sup>{{ fields.organization.helpText}}</span><br>
            <span class="text-sm text-danger" v-if="duplicateError.hasError">{{ duplicateError.errorMessage}}</span>
          </div>
          <span :class="[fields.optional_text.colClass]" :style="fields.optional_text.style">{{ fields.optional_text.label }}</span>
          <div :class="[fields.add_new_organization.colClass]" v-if="fields.add_new_organization">
            <div class="form-group">
              <label for="add_new_organization" class="form-label">{{ fields.add_new_organization.label }} <sup class="text-danger" v-if="fields.add_new_organization.required">*</sup></label>
              <input type="text" class="form-control" @keypress="isNewTeam" @blur="isNewTeam" maxlength="100" id="add_new_organization" v-model="form.name" name="form.add_new_organization">
              <span class="text-sm text-danger">{{ errors.add_new_organization}}</span>
            </div>
            <span class="text-sm text-danger" v-if="duplicateErrorMessage.hasError">{{ duplicateErrorMessage.errorMessage}}</span>
          </div>
          <div :class="[fields.agl_team.colClass]" v-if="fields.agl_team">
            <div class="form-group">
              <label for="agl_team" class="form-label">{{ fields.agl_team.label }} <sup class="text-danger" v-if="fields.agl_team.required">*</sup></label>
              <input type="text" class="form-control" maxlength="100" id="agl_team" v-model="form.agl_team_id" name="form.agl_team">
              <span class="text-sm text-danger">{{ errors.agl_team}}</span>
            </div>
          </div>
        </div>

        <template v-if="form.address_type == 'Work'">
          <div class="row">
            <div :class="[fields.work_address_company_name.colClass]" v-if="fields.work_address_company_name">
              <div class="form-group">
                <label for="work_address_company_name" class="form-label">{{ fields.work_address_company_name.label }}<sup class="text-danger" v-if="fields.work_address_company_name.required">*</sup></label>
                <input type="text" class="form-control" id="work_address_company_name" v-model="form.company_name">
                <span class="text-sm text-danger">{{ errors.company_name }}</span>
              </div>
            </div>
            <div :class="[fields.work_address_company_phone_extension.colClass]" v-if="fields.work_address_company_phone_extension">
              <div class="form-group">
                <label for="work_address_company_phone" class="form-label">{{ fields.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="fields.work_address_company_phone_extension.required">*</sup></label>
                <div class="row">
                  <div class="col-md-9">
                    <vue-tel-input ref="phone" @validate="validateTelinput" v-model="form.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                    <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                  </div>
                  <div class="col-md-3">
                    <input type="text" class="form-control" v-model="form.company_phone_extension" name="form.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                    <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
      <div class="col-md-12">
        <div class="text-right topspace">
          <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn" :disabled="checkingDuplicate">
            <span v-if="checkingDuplicate"><i class="fa fa-circle-notch fa-spin"></i></span>
            <span v-else>Next <i class="fas fa-chevron-right pl-2"></i></span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

// import {TheMask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';
import axios from "axios";

const validateName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  if (contactField != 'Address' && !name.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  contactText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};



const validateZip = (zipCode, country) => {
  if(!zipCode.length)
  {
    return { valid: false, error: "Zip code is required" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
    return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
  }
  else if(zipCode.length < 5 ){
    return { valid: false, error: "Zip code should be 5 character" };
  }
  return { valid: true, error: null };
}

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "This field");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }


  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
  }

  return { valid: true, error: null };
}

const validateDob = dob => {
  if (!dob.length) {
    return { valid: false, error: "This field is required" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
const validateGender = gender => {
  if (!gender) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = (select, fieldState='') => {
  let textState= ( fieldState != '' ? fieldState : "This field");
  if (!select.length) {
    return { valid: false, error: textState+" is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = (email, fieldEmail='') => {
  let emailText= (fieldEmail != '' ? fieldEmail : "This field");
  if (!email.length) {
    return { valid: false, error: emailText+" is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};
const validatePassword = password => {
  if (!password.length) {
    return { valid: false, error: "This field is required" };
  }
  if (password.length < 7) {
    return { valid: false, error: "Password is too short" };
  }
  return { valid: true, error: null };
};
const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};
const validTeamOrName = (id, name) =>{
  if ((id==undefined || id=='') && !name.length )
  {
    return { valid: false, error: "Select Team or Enter New Team" };
  }
  return { valid: true, error: null };
};

export default {
  name: "teaminfo",
  props: ['fields','currentRegContacts','isTeamRegistration','membership', 'age_division_list','league_list','updatedVerifiedDetails','isValidateMemberType', 'duplicateError','checkingDuplicate','isTeamType','duplicateErrorMessage'],
  components: {VueTelInput},
  data() {
    return {
      validTelinput:false,
      phone: "0123456789",
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:15,
          name:"telephone",
          placeholder:"Home Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }

      },
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:15,
          name:"telephone",
          placeholder:"Mobile Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }
      },
      companyPhoneExtProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Company Phone Extension",
        maxLen: 15,
      },

      homephonemax: 10,
      //  mobilemax: 10,
      form: {
        "printed_magazine" : '',
        'address_type': 'Home',
        "address": '',
        "address1": '',
        "address2": '',
        "zip": '',
        "state_code": '',
        "state": '',
        "city": '',
        "country": 'USA',
        "member_league":'',
        "member_league_name":'',
        "age_division":'',
        "age_division_name":'',
        "id":'',
        "name":'',
        "agl_team_id":'',
        'ask_address':'',
        'team_check': true
      },
      valid: false,
      success: false,
      errors: {},
      options: [],
      message: null,
      countries: null,
      teamOptions:[],
      isDisabled:0,
      fieldBackUp:{
        'address_type': 'Home',
        "address": '',
        "address1": '',
        "address2": '',
        "zip": '',
        "state_code": '',
        "state": '',
        "city": '',
        "country": 'USA',
        "member_league":'',
        "member_league_name":'',
        "age_division":'',
        "age_division_name":'',
        "id":'',
        "name":'',
        'ask_address':'',
        'agl_team_id':''
      }

    }
  },
  methods: {
    teamInfoData() {
      if(this.validate()) {
        this.form.address = this.form.address1;

        this.$emit('team-data', this.form);
        return;
      } else {
        console.log('invalid');
      }
    },
    removeStateCode(){
        this.form.state = '';
        this.form.state_code = '';
    },
    previousStep() {
      this.$emit('previous-step');
      return;
    },
    // Custom Validation Rules
    validate() {
      this.errors = {}
      this.valid = false;

      var validAddress1 = "", validCountry = "", validStateCode = "", validCity = "", validState = "", validZip = "", validMemberLeague= "" ,validAgeDivision= "";

      var validCompanyName = "", validCompanyPhone = "", validCompanyPhoneExtension = "", validAddNewOrganization="";

    if(!this.isDisabled) {
      validAddress1 = validateAddressName(this.form.address1, "Address");
      this.errors.address1 = validAddress1.error;

      //const validAddress2 = validateName(this.form.address2);
      // this.errors.address2 = validAddress2.error;

      validCountry = validateSelect(this.form.country, "Country");
      this.errors.country = validCountry.error;

      validStateCode = validateSelect(this.form.state_code, "State");
      validState = validateName(this.form.state);

      if (validCountry.valid && this.form.country != 'USA' || this.form.country != 'Canada') {
        this.errors.state_code = validStateCode.error;
      }
      validCity = validateName(this.form.city, "City");
      this.errors.city = validCity.error;

      // validZip = validateName(this.form.zip,"Postal Code");
      // this.errors.zip = validZip.error;


      validZip = validateZip(this.form.zip, this.form.country);
      this.errors.zip = validZip.error;

      validMemberLeague = validateName(this.form.member_league_name ,"Member League");
      this.errors.member_league = validMemberLeague.error;


      if (this.form.address_type == "Work") {
        validCompanyName = validateName(this.form.company_name, "Company Name");
        this.errors.company_name = validCompanyName.error;

        // validCompanyPhone = validatePhone(this.form.company_phone, this.validTelinput);
        // this.errors.company_phone = validCompanyPhone.error;

        // validCompanyPhoneExtension = validateName(this.form.company_phone_extension, "Extension");
        // this.errors.company_phone_extension = validCompanyPhoneExtension.error;
      }
    }

      validAgeDivision = validateSelect(this.form.age_division,"Age Division");
      this.errors.age_division = validAgeDivision.error;
      /*validOrganization = validateSelect(this.form.organization,"Organization");
      this.errors.organization = validOrganization.error;*/
      
      validAddNewOrganization = validTeamOrName(this.form.id, this.form.name);
      this.errors.add_new_organization = validAddNewOrganization.error; 
      console.log("validAddNewOrganization", validAddNewOrganization);
          
      if ((validAddress1.valid  && validCountry.valid && validCity.valid && validZip.valid || this.isDisabled) && validAgeDivision.valid && validAddNewOrganization.valid) {
        this.valid = true;

        if(this.form.address_type == "Work"){
          if(!validCompanyName.valid) this.valid = false;
        }

        if(this.form.name!='' && this.form.id =='' && !this.form.id.toString().length){
          this.$delete(this.form, 'id')
        }
        if(!this.isDisabled) {
          if (this.form.country == 'USA' || this.form.country == 'Canada') {
            this.form.state = '';
            if (!validStateCode.valid) {
              this.valid = false;
            }
          } else if (!validState.valid) {
            this.form.state_code = '';
            this.valid = false;
          }
        }

      }
      console.log("this.valid", this.valid);
      return this.valid;
    },
    phoneFormat() {
      let s = this.$refs.phone.value;

      var rgx = /^(\+[\d]{1,5}|0)?[7-9]\d{9}$/;
      this.$refs.phone.value = s.match(rgx);
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        this.errors.phone_1 = '';
      }
    },
    askAddress(isCoach){
      if(isCoach == 1){
        this.form.address1 = this.currentRegContacts.address1;
        this.form.address2 = this.currentRegContacts.address2;
        this.form.state_code = this.currentRegContacts.state_code;
        this.form.state = this.currentRegContacts.state;
        this.form.city = this.currentRegContacts.city;
        this.form.zip = this.currentRegContacts.zip;
        this.form.country = this.currentRegContacts.country;
        this.stateCode(this.form.state_code);
      }else {
        this.form.address1 = "";
        this.form.address2 = "";
        this.form.country = "";
        this.form.state_code = "";
        this.form.state = "";
        this.form.city = "";
        this.form.zip = "";
      }
    },
    changeAddressType(){
      this.form.address1 = "";
      this.form.address2 = "";
      this.form.country = "";
      this.form.state_code = "";
      this.form.state = "";
      this.form.city = "";
      this.form.zip = "";
      this.form.company_name = "";
      this.form.company_phone = "";
      this.form.company_phone_extension = "";
    },
    getTeam(){
      axios.get(this.basePath + "api/get_teams")
          .then((response) => {
            var team = response.data.data;
            this.options = team;
          })
          .catch(error=>{
            console.log("error getting clubs list")
          })
    },
    stateCode(){
      let state_code = this.form.state_code;
      this.league_list.forEach((leagueList, index) => {
        if(leagueList.value.includes(state_code)){
          this.form.member_league_name=leagueList.label;
          this.form.member_league=leagueList.id;
          return;
        }
      });
    },
    teamChange(){
      let team_choose = this.form.id.toString();
      this.form.team_check = false;
      if(team_choose.length){
        this.fieldBackups();
        this.form.name='';
        this.form.address1 = "";
        this.form.address2 = "";
        this.form.country = "";
        this.form.state_code = "";
        this.form.state = "";
        this.form.city = "";
        this.form.zip = "";
        this.form.company_name = "";
        this.form.company_phone = "";
        this.form.company_phone_extension = "";
        this.isDisabled = 1;
        this.form.ask_address = '';
        //this.form.id = this.fieldBackUp.id;
      }
      this.getTeamDetails(this.form.id);
    },
    isNewTeam(){
      let team = this.form.name;
      this.form.team_check = true;
      if(team.toString().length && this.form.address1 == ''){
        this.fieldBackUp.id = this.form.id;
        this.$delete(this.form, 'id');
        this.form.address1 = this.fieldBackUp.address1;
        this.form.address2 = this.fieldBackUp.address2;
        this.form.country = this.fieldBackUp.country;
        this.form.state_code = this.fieldBackUp.state_code;
        this.form.state = this.fieldBackUp.state;
        this.form.city = this.fieldBackUp.city;
        this.form.zip = this.fieldBackUp.zip;
        this.form.company_name = this.fieldBackUp.company_name;
        this.form.company_phone = this.fieldBackUp.company_phone;
        this.form.company_phone_extension = this.fieldBackUp.company_phone_extension;
        this.form.ask_address = this.fieldBackUp.ask_address;
        this.isDisabled = 0;
      }
    },
    getTeamsList(){
      axios.get(this.basePath + "api/get_teams_division?member_type_id=" + this.membership?.member_type_id)
          .then((response) => {
            let teams = response.data.data;
            let teamKey = Object.keys(teams);
            if(teamKey.length){
              this.teamList = Object.keys(teams).map((field) => {
                return {
                  age: field.slice(0, -1),
                  value: teams[field]
                }
              })
            }
          })
          .catch(error=>{
            console.log("error geting clubs list")
          })
    },
    getDivisionTeamList(event){
      console.log("event",event);
      if(event.target != undefined){
        this.form.age_division_name = event.target.options[event.target.options.selectedIndex].text;
        let age = event.target.options[event.target.options.selectedIndex].text.slice(0, -1);
        let teamOptions=[];
        let teamOptionsList=[];
        this.teamList.forEach((ageDivisionList, index) => {
          if (this.isTeamType == 4 && ageDivisionList.age != '' && (ageDivisionList.age <= age && ageDivisionList.age <= 12)) {
            teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
              if (ageDivisionList.value[field].text != null) {
                return {
                  id: ageDivisionList.value[field].id,
                  text: ageDivisionList.value[field].text
                }
              }
            });
            teamOptionsList.push(teamOptions);
          }
          if (this.isTeamType == 3 && ageDivisionList.age != '' && (ageDivisionList.age <= age && ageDivisionList.age > 12)) {
            teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
              if(ageDivisionList.value[field].text != null) {
                return {
                  id: ageDivisionList.value[field].id,
                  text: ageDivisionList.value[field].text
                }
              }
            });
            teamOptionsList.push(teamOptions);
          }
        });
        this.teamOptions = teamOptionsList.flat();
        console.log("all list of under 16", this.teamOptions);
        this.teamOptions = this.teamOptions.filter(function( element ) {
          return element !== undefined;
        });
        console.log("all list of under 16 123456", this.teamOptions);
      }else{
        console.log('event 1',event.slice(0, -1));
        let age = event.slice(0, -1);
        let teamOptions=[];
        let teamOptionsList=[];
        console.log("event 2",this.teamList);
        this.teamList.forEach((ageDivisionList, index) => {
          if(ageDivisionList.age != '' && ageDivisionList.age <= age){
            teamOptions = Object.keys(ageDivisionList.value).flatMap((field) => {
              if(ageDivisionList.value[field].text != null) {
                return {
                  id: ageDivisionList.value[field].id,
                  value: ageDivisionList.value[field].text
                }
              }
            });
            teamOptionsList.push(teamOptions);
          }
        });
        this.teamOptions = teamOptionsList.flat();
        this.teamOptions = this.teamOptions.filter(function( element ) {
          return element !== undefined;
        });
      }

    },
    fieldBackups(){
      this.fieldBackUp.name=this.form.name;
      this.fieldBackUp.address1 = this.form.address1;
      this.fieldBackUp.address2 = this.form.address2;
      this.fieldBackUp.country = this.form.country;
      this.fieldBackUp.state_code = this.form.state_code;
      this.fieldBackUp.state = this.form.state;
      this.fieldBackUp.city = this.form.city;
      this.fieldBackUp.zip = this.form.zip;
      this.fieldBackUp.company_name = this.form.company_name;
      this.fieldBackUp.company_phone = this.form.company_phone;
      this.fieldBackUp.company_phone_extension = this.form.company_phone_extension;
      this.fieldBackUp.ask_address = this.form.ask_address;
    },
    getTeamDetails(id){
      axios.get(this.basePath + "api/get_teams_league/"+id)
          .then((response) => {
            let teams = response.data.data;
            this.form.member_league_name=teams.name;
            this.form.member_league=teams.id;
           /* this.form.address1 = teams.team.address;
            this.form.address2 = teams.team.address2;
            this.form.country = teams.team.country;
            this.form.state_code = teams.team.state_code;
            this.form.state = teams.team.state;
            this.form.city = teams.team.city;
            this.form.zip = teams.team.zip;*/
          })
          .catch(error=>{
            console.log("error geting clubs list")
          })
    },
    resetFields(){
      this.form= {
            printed_magazine : '',
            address_type: 'Home',
            address: '',
            address1: '',
            address2: '',
            zip: '',
            state_code: '',
            state: '',
            city: '',
            country: 'USA',
            member_league:'',
            member_league_name:'',
            age_division:'',
            age_division_name:'',
            id:'',
            name:'',
            agl_team_id:'',
            ask_address:''
      }
    }
  },
  mounted(){
    this.getTeamsList();
    if(this.isTeamRegistration){
      this.countries= this.fields.country.options;
    }else{
      this.countries= this.listOfCountries;
    }

    if(this.updatedVerifiedDetails != undefined && this.updatedVerifiedDetails.team != undefined){
      this.form.email = this.updatedVerifiedDetails.team.email;
      this.form.retype_email = this.updatedVerifiedDetails.team.retype_email;
      this.form.printed_magazine = this.updatedVerifiedDetails.team.printed_magazine;
      this.form.address_type = this.updatedVerifiedDetails.team.address_type;
      this.form.address1 = this.updatedVerifiedDetails.team.address1;
      this.form.address = this.updatedVerifiedDetails.team.address;
      this.form.address2 = this.updatedVerifiedDetails.team.address2;
      this.form.zip = this.updatedVerifiedDetails.team.zip;
      this.form.state_code = this.updatedVerifiedDetails.team.state_code;
      this.form.city = this.updatedVerifiedDetails.team.city;
      this.form.country = this.updatedVerifiedDetails.team.country;
      this.form.phone_1 = this.updatedVerifiedDetails.team.phone_1;
      this.form.phone_2 = this.updatedVerifiedDetails.team.phone_2;
      this.form.company_name = this.updatedVerifiedDetails.team.company_name;
      this.form.company_phone = this.updatedVerifiedDetails.team.company_phone;
      this.form.company_phone_extension = this.updatedVerifiedDetails.team.company_phone_extension;
      this.form.first_name = this.updatedVerifiedDetails.team.first_name;
      this.form.last_name = this.updatedVerifiedDetails.team.last_name;
      this.form.middle_name = this.updatedVerifiedDetails.team.middle_name;
      this.form.citizenship = this.updatedVerifiedDetails.team.citizenship;
      this.form.name = this.updatedVerifiedDetails.team.name;
      this.form.member_league = this.updatedVerifiedDetails.team.member_league;
      this.form.member_league_name = this.updatedVerifiedDetails.team.member_league_name;
      this.form.age_division = this.updatedVerifiedDetails.team.age_division;
      this.form.agl_team_id = this.updatedVerifiedDetails.team.agl_team_id;
      this.form.ask_address = this.updatedVerifiedDetails.team.ask_address;
      this.form.age_division_name = this.updatedVerifiedDetails.team.age_division_name;
      this.form.id = this.updatedVerifiedDetails.team.team_id;
      setTimeout(() => this.getDivisionTeamList(this.updatedVerifiedDetails.team.age_division_name), 1000);
    }
    if(this.isValidateMemberType){
      this.resetFields();
    }
  }
}
</script>
