<template>
    <div>
        <main class="main" role="main">
            <div class=" bg-light h-100">
                <div class="container-fluid">
                    <div class="row">
                        <div class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">
                            <div class=" pt-4 mb-3 left-banner">
                                <div class="logo text-center">
                                    <!-- <img v-bind:src="'/dist/img/memberregistration/logo_waterski.png'"
                                         alt="Integrass Sports" class="img-fluid"/> -->
                                </div>
                            </div>
                        </div>
                        <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 bg-white rightside_section">
                            <div class="border-radius-0 border-0" style="padding:107px 0; background: #F2F2F2;">
                                <div class="logo-wrapper text-center mb-2">
                                       <img :src="'/'+this.getSiteProps('login.logo')" alt="logo">
                                </div>
                                <div class="login-main-wrapper">
                                    <div class="login-wrapper">
                                        <div class="card-header">
                                            <h3>Reset Password</h3>
                                        </div>
                                        <div class="card card-body">
                                            <div v-if="formEnable">
                                                <div class="form-group">
                                                    <label>Email / Member ID</label>
                                                    <div class="input-group mb-3">
                                                        <input type="text" name="email" v-model="apiEmail"
                                                               class="form-control" disabled>
                                                    </div>
                                                    <label>Password</label>
                                                    <div class="input-group mb-3">
                                                        <input type="password"  name="password" v-model="ResetPassword.password"
                                                               class="form-control">
                                                    </div>
                                                    <label>Confirm Password</label>
                                                    <div class="input-group mb-3">
                                                        <input type="password" name="password_confirmation"
                                                               v-model="ResetPassword.password_confirmation" class="form-control">
                                                    </div>
                                                    <p class="text-danger">{{errMsg}}</p>
                                                </div>

                                                <div class="form-button">
                                                    <b-button class="btn-primary btn-blue" @click="resetPassword">Reset Password</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="!formEnable">
                                                <div class="col-md-12 text-center">
                                                    <p v-html="messages"></p>
                                                </div>
                                                <div class="col-md-12" v-if="ResetPassword.type">
                                                    <div class="form-group" v-if="ResetPassword.type == 'member' && !(subdomain=='waterski' && member_type == 7) ">
                                                        <router-link to="/member/login" class="btn btn-primary btn-blue">
                                                            <i class="fa fa-lock mr-2"></i> Login now
                                                        </router-link>
                                                        <p class="mt-2 mb-2 text-center">OR</p>
                                                        <router-link to="/member/forgotpassword" class="btn btn-primary btn-blue">
                                                            <i class="fa fa-key mr-2"></i> Forgot Password
                                                        </router-link>
                                                    </div>
                                                    <div class="form-group" v-else>
                                                        <router-link to="/club/login" class="btn btn-primary btn-blue">
                                                            <i class="fa fa-lock mr-2"></i> Login now
                                                        </router-link>
                                                        <p class="mt-2 mb-2 text-center">OR</p>
                                                        <router-link to="/club/forgotpassword" class="btn btn-primary btn-blue">
                                                            <i class="fa fa-key mr-2"></i> Forgot Password
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <template v-else>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <b-button class="btn-primary btn-blue" @click="memberForgotPasswordRedirect">
                                                                <i class="fa fa-key mr-2"></i> Forgot Password (Member)
                                                            </b-button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <b-button class="btn-primary btn-blue" @click="clubForgotPasswordRedirect">
                                                                <i class="fa fa-key mr-2"></i> Forgot Password ({{this.getSiteProps('general.admin-team') || 'Club'}})
                                                            </b-button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                               <div class="p-0 mt-0"></div>
     <div class="footer-inner">
                        <div class="row footer-row">
                            <div class="col-md-7 pl-3">
                                <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">
                                    <div class="">
                                        <div class=" ">
                                            <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                                        </div>
                                    </div>
                                </div>
                            <p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p>
                            </div>
                            <div class="col-md-5 pr-4">
                                <p class="text-right mr-2">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
                            </div>
                        </div>
                    </div>
                        </div>

                    </div>

                </div>


            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "memberPasswordReset",
    data() {
        return {
            apiEmail: "",
            token: "",
            errMsg: "",
            messages:"",
            formEnable: true,
            prop_footer: {},
            member_type:'',
            ResetPassword:{
                email: "",
                member_id: "",
                type: "",
                password: "",
                password_confirmation: "",
            },
        }
    },
    methods: {
        getTokenData() {
            axios.get(this.basePath + "api/password/reset/" + this.$route.params.token)
                .then((response) => {
                    if(response.data.status=="success") {
                        var tokenData = response.data.data;
                        this.ResetPassword.member_id = tokenData.ref;
                        this.ResetPassword.email = tokenData.email;
                        this.ResetPassword.type = tokenData.type;
                        this.member_type = tokenData.member_type||'';
                        if(tokenData.type == "club"){
                          this.apiEmail = tokenData.email;
                        }else {
                          this.apiEmail = tokenData.ref;
                        }

                        if(this.ResetPassword.member_id == "" && this.ResetPassword.email == "") 
                            this.formEnable=false;
                    }
                    if(response.data.status=="error"){
                        this.messages=response.data.message;
                        this.formEnable=false;
                    }
                }).catch((error) => {
                console.log("token fail")
                this.formEnable=false;
            });
        },
        resetPassword(){
            axios.post(this.basePath + 'api/password/reset', this.ResetPassword)
                .then(response => {
                    if(response.data.status=="success") {
                        this.messages="Password has updated successfully";
                        this.formEnable=false;
                    }else if(response.data.status=="error"){
                        this.errMsg=response.data.message;
                        this.ResetPassword.type="";
                        this.ResetPassword.password="";
                        this.ResetPassword.password_confirmation="";
                        this.getTokenData(); //refresh password data again
                    }
            }).catch(error => {
                console.log("reset password fail");
            });
        },
        loginRedirect(){
            if(this.ResetPassword.type=="club") {
                this.$router.push({path: '/club/login'});
            }else{
                this.$router.push({path: '/member/login'});
            }
        },
        forgotPasswordRedirect(){
            if(this.ResetPassword.type=="club") {
                this.$router.push({path: '/club/forgotpassword'});
            }else{
                this.$router.push({path: '/member/forgotpassword'});
            }
        },
        memberForgotPasswordRedirect(){
            this.$router.push({path: '/member/forgotpassword'});
        },
        clubForgotPasswordRedirect(){
            this.$router.push({path: '/club/forgotpassword'});
        }
    },
    mounted() {
        //this.email=this.$route.params.token;
        this.getTokenData();
    },
     updated(){           
            this.prop_footer = this.getSiteProps('memberRegistration.footer');
        }
}
</script>

<style scoped>

</style>
