<template>
<b-modal id="modal-family-member-add" scrollable v-bind:hide-footer="false" @ok="AddMemberInfo" :ok-disabled="btn_submit.load" v-bind:ok-title="btn_submit.text" ref="modal-family-member-add" size="xl">
	<custom-alert v-if="displayAlert==true" :newDismissSecs="newDismissSecs" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
	<template slot="modal-title">Add Family Member Information</template>
	<form ref="form" method="post" @submit.stop.prevent>
		<h6 class="mb-0">Personal Information</h6>
		<div class="row">
			<div class="col-xl-3 col-lg-4 col-md-3">
				<b-form-group>
					<label>Relationship <span class="text-danger">*</span></label>
					<b-form-radio-group name="FamilyMember.relation" id="FamilyMember.relation" v-model="FamilyMember.relation"
						:options='relation' :class="errors.family.relation.valid == false ? 'is-invalid invalid' : ''">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.relation.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.relation.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-6 col-lg-5 col-md-6">
				<b-form-group class="mb-4">
					<label>Gender <span class="text-danger">*</span></label>
					<b-form-radio-group name="FamilyMember.gender" id="FamilyMember.gender" v-model="FamilyMember.gender" :class="errors.family.gender.valid == false ? 'is-invalid invalid' : ''"
						:options='genderChoices' value-field="label" text-field="label">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.gender.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.gender.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-3">
				<b-form-group class="mb-4">
					<label class="form-label">Birthday <span class="text-danger">*</span></label>
					<div class="birthdate" :class="{ 'border-danger' : errors.family.dob.valid == false }">
						<div class="birthdaypicker">
							<date-pick
								v-model="FamilyMember.dob"
								:format="'MM/DD/YYYY'"
								:displayFormat="'MM/DD/YYYY'"
								:isDateDisabled="isFutureDate"
								v-mask="'##/##/####'"
								:inputAttributes="{readonly: false}"
                :parseDate="parseDatePick"
							></date-pick>
						</div>
					</div>
					<input name="FamilyMember.dob" id="FamilyMember.dob" class="form-control d-none" :class="errors.family.dob.valid == false ? 'is-invalid invalid' : ''">
					<b-form-invalid-feedback v-if="errors.family.dob.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.dob.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-5 col-lg-5 col-md-5">
				<b-form-group class="mb-4">
					<label class="form-label">First Name <span class="text-danger">*</span></label>
					<b-form-input v-model="FamilyMember.first_name" name="FamilyMember.first_name" id="FamilyMember.first_name" :class="errors.family.first_name.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.first_name.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.first_name.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-2 col-lg-2 col-md-3">
				<b-form-group class="mb-4">
					<label class="form-label">Middle Initial</label>
					<b-form-input v-model="FamilyMember.middle_name" name="FamilyMember.middle_name" id="FamilyMember.middle_name" maxlength="2"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-xl-5 col-lg-5 col-md-4">
				<b-form-group class="mb-4">
					<label class="form-label">Last Name <span class="text-danger">*</span></label>
					<b-form-input v-model="FamilyMember.last_name" name="FamilyMember.last_name" id="FamilyMember.last_name" :class="errors.family.last_name.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.last_name.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.last_name.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
		</div>
		<hr/>
		<h6 class="mb-0">Contact Details</h6>
		<div class="row">
			<div class="col-xl-4 col-lg-4 col-md-12">
				<b-form-group class="mb-4">
					<label class="form-label">Email-ID <span class="text-danger">*</span></label>
					<b-form-input type="email" v-model="FamilyMember.email" name="FamilyMember.email" id="FamilyMember.email" :class="errors.family.email.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.email.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.email.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6">
				<b-form-group class="mb-4">
					<label class="form-label">Home phone with Country Code <span class="text-danger">*</span></label>
					<b-form-input type="tel" v-model="FamilyMember.phone_1" name="FamilyMember.phone_1" id="FamilyMember.phone_1" :class="errors.family.phone_1.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.phone_1.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.phone_1.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6">
				<b-form-group class="mb-4">
					<label class="form-label" for="phone_2"> Mobile with Country Code </label><br>
					<b-form-input type="tel" v-model="FamilyMember.phone_2" name="FamilyMember.phone_2" id="FamilyMember.phone_2"></b-form-input>
				</b-form-group>
			</div>
		</div>
		<hr />
		<div class="row">
			<div class="col-lg-12 pb-4" v-if="subdomain == 'waterski'">
				<label class="form-label">Affiliated Clubs/Teams &nbsp;&nbsp;
					
				</label>
				<Select2 v-model="FamilyMember.team_id" :options="clubOptions" :settings="select2MultipleOpt" name="FamilyMember.team_id" id="FamilyMember.team_id" />
			</div>
			<div class="col-lg-12 pb-4" v-if="subdomain == 'shooting'">
				<label class="form-label">Affiliated Clubs/Teams &nbsp;&nbsp;
					
				</label>
				<Select2 v-model="FamilyMember.team_id" :options="clubOptions"  name="FamilyMember.team_id" id="FamilyMember.team_id" />
			</div>
		</div>
		<hr />
		<h6 class="mb-0">Sport Discipline</h6>
		<p class="font-13" v-if="subdomain == waterski"><i>If you do not see your club/team listed, contact USA-WSWS HQ at <a href="mailto:memberservices@usawaterski.org" class="text-danger">memberservices@usawaterski.org</a></i></p>
		<div class="row">
			<div class=" col-lg-4 pb-4" v-if="subdomain == 'waterski'">
				<label class="form-label">1<sup>st</sup> Choice <span class="text-danger">*</span></label>
				<b-form-select v-model="FamilyMember.first_choice" name="FamilyMember.first_choice" id="FamilyMember.first_choice" :options="disciplines_list" value-field="id" text-field="text" :class="errors.family.first_choice.valid == false ? 'is-invalid invalid' : ''"></b-form-select>
				<b-form-invalid-feedback v-if="errors.family.first_choice.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.first_choice.message }}</b-form-invalid-feedback>
			</div>
			<div class=" col-lg-4 pb-4" v-if="subdomain == 'waterski'">
				<label class="form-label">2<sup>nd</sup> Choice</label>
				<b-form-select v-model="FamilyMember.second_choice" name="FamilyMember.second_choice" id="FamilyMember.second_choice" :options="disciplines_list" value-field="id" text-field="text"></b-form-select>
			</div>
			<div class=" col-lg-8 pb-4" v-if="subdomain == 'shooting'">
				<label class="form-label">Disciplines &nbsp;&nbsp;<span class="text-danger">*</span>
				</label>
				<Select2 v-model="FamilyMember.disciplines" :options="disciplines_list" :settings="select2MultipleOpt" name="FamilyMember.disciplines" id="FamilyMember.disciplines" />
				<div class="text-danger" style="font-size: 80%" v-if="errors.family.disciplines.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.disciplines.message }}</div>
			</div>
			<!-- <div class=" col-lg-4" >
				<b-form-group class="mb-4">
					<label class="form-label">Mailing Address <span class="text-danger">*</span></label>
					<b-form-input v-model="FamilyMember.address1" name="FamilyMember.address1" id="FamilyMember.address1" :class="errors.family.address1.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.address1.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.address1.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6" >
				<b-form-group class="mb-4">
					<label class="form-label">Address 2</label>
					<b-form-input v-model="FamilyMember.address2" name="FamilyMember.address2" id="FamilyMember.address2" maxlength="100"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6 pb-4" >
				<label class="form-label">Country <sup class="text-danger">*</sup></label>
				<b-form-select v-model="FamilyMember.country" name="FamilyMember.country" id="FamilyMember.country" :options="listOfCountries" value-field="country" text-field="country" :class="errors.family.country.valid == false ? 'is-invalid invalid' : ''"></b-form-select>
				<b-form-invalid-feedback v-if="errors.family.country.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.country.message }}</b-form-invalid-feedback>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6" >
				<div class="pb-4" v-if="FamilyMember.country == 'USA'">
					<label class="form-label">State <sup class="text-danger">*</sup></label>
					<b-form-select v-model="FamilyMember.state_code" name="FamilyMember.state_code" id="FamilyMember.state_code"  value-field="state_code" text-field="state" :class="errors.family.state_code.valid == false ? 'is-invalid invalid' : ''">
					   	<option value="">Select</option>
						<option v-for="option in listOfStates" v-bind:key="option" :value="option.state_code">
						{{ option.state }}
						</option>
					</b-form-select>
				</div>
				<div class="pb-4" v-else-if="FamilyMember.country == 'Canada'">
					<label class="form-label">State <sup class="text-danger">*</sup></label>
					<b-form-select v-model="FamilyMember.state" name="FamilyMember.state" id="FamilyMember.state" :options="listOfCAStates" value-field="state" text-field="state" :class="errors.family.state.valid == false ? 'is-invalid invalid' : ''"></b-form-select>
				</div>
				<div v-else>
					<b-form-group class="mb-4">
						<label class="form-label">State <sup class="text-danger"></sup></label>
						<b-form-input v-model="FamilyMember.state" maxlength="45" id="FamilyMember.state" name="FamilyMember.state" :class="errors.family.state.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
						<b-form-invalid-feedback v-if="errors.family.state.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.state.message }}</b-form-invalid-feedback>
					</b-form-group>
				</div>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6" >
				<b-form-group class="mb-4">
					<label class="form-label">City <sup class="text-danger">*</sup></label>
					<b-form-input v-model="FamilyMember.city" maxlength="45" id="FamilyMember.city" name="FamilyMember.city" :class="errors.family.city.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.city.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.city.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6" >
				<b-form-group class="mb-4">
					<label class="form-label">Postal Code <sup class="text-danger">*</sup></label>
					<b-form-input v-model="FamilyMember.zip" name="FamilyMember.zip" id="FamilyMember.zip" maxlength="10" :class="errors.family.zip.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.zip.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.zip.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6 pb-4" v-if="subdomain == 'waterski'">
				<label class="form-label">Federation <sup class="text-danger">*</sup></label>
				<b-form-select v-model="FamilyMember.home_federation" name="FamilyMember.home_federation" id="FamilyMember.home_federation" :options="federation" value-field="name" text-field="name"></b-form-select>
				<b-form-invalid-feedback v-if="errors.family.home_federation.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.home_federation.message }}</b-form-invalid-feedback>
			</div>
			<div class="col-xl-12 col-lg-12 col-sm-12" v-if="FamilyMember.home_federation !='USA' && subdomain == 'waterski'">
				<b-form-group class="mb-4">
					<label class="form-label">{{ this.getSiteProps('memberRegistration.family.fields.general.sport_discipline.skier_license.label') }} <sup class="text-danger">*</sup></label>
					<b-form-input v-model="FamilyMember.skier_license" maxlength="45" id="FamilyMember.skier_license" name="FamilyMember.skier_license" class="col-xl-3 col-lg-4 col-sm-6" :class="errors.family.skier_license.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.skier_license.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.skier_license.message }}</b-form-invalid-feedback>
					<div class="text-sm text-success" v-html="getSiteProps('memberRegistration.family.fields.general.sport_discipline.skier_license.description')"></div>
				</b-form-group>
			</div> -->
		</div>
		<div class="row">
			<div class="col-xl-4 col-lg-4 col-sm-12">
				<b-form-group class="mb-4">
					<h6 v-if="subdomain == 'waterski'">Race <span class="text-danger">*</span></h6>
					<h6 v-if="subdomain == 'shooting'">Ethnicity <span class="text-danger">*</span></h6>
					<b-form-radio-group v-model="FamilyMember.diversity_id" stacked name="FamilyMember.diversity_id" id="FamilyMember.diversity_id"
						:options='diversities' value-field="id" text-field="name" :class="errors.family.diversity_id.valid == false ? 'is-invalid invalid' : ''">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.diversity_id.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.diversity_id.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-sm-6" v-if="subdomain == 'waterski'">
				<b-form-group class="mb-4">
					<h6>Ethnicity <span class="text-danger">*</span></h6>
					<b-form-radio-group v-model="FamilyMember.ethnicity" stacked name="FamilyMember.ethnicity" id="FamilyMember.ethnicity" :options="ethnicityChoices" value-field="label" text-field="label" :class="errors.family.ethnicity.valid == false ? 'is-invalid invalid' : ''">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.ethnicity.valid == 'false'"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.ethnicity.message }}</b-form-invalid-feedback>
				</b-form-group>
				<b-form-group class="mb-4">
					<h6>Disability Status <span class="text-danger">*</span></h6>
					<b-form-radio-group v-model="FamilyMember.disability" name="FamilyMember.disability" stacked id="FamilyMember.disability" :options='disabilityChoices' value-field="label" text-field="label" :class="errors.family.disability.valid == false ? 'is-invalid invalid' : ''">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.disability.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.disability.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-sm-6" v-if="FamilyMember.relation != 2">
				<b-form-group class="mb-4">
					<h6>Veteran Status <span class="text-danger">*</span></h6>
					<b-form-radio-group v-model="FamilyMember.veteran_status" name="FamilyMember.veteran_status" stacked id="FamilyMember.veteran_status" :options='veteranStatusChoices' value-field="label" text-field="label" :class="errors.family.veteran_status.valid == false ? 'is-invalid invalid' : ''">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.veteran_status.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.veteran_status.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
		</div>
	</form>
</b-modal>
</template>
<script>
import moment from "moment";
import axios from 'axios';
import $ from 'jquery';
import Select2 from "v-select2-component";
import { ToastPlugin } from 'bootstrap-vue';
import { EventBus } from '@/event-bus';
import CustomAlert from "../../CustomAlert";
// import userProfile from '../../admin/UserProfile.vue';

export default {
	name: "familymemberprofileadd",
	components: {CustomAlert},
	data() {
		return {
			addSpouse:false,
			request_from : 'member',
			btn_submit:{load:false, text: 'Add' },
			select2MultipleOpt:{ multiple: true, placeholder: 'Click to select one or multiple' },
			genderChoices:[],
			relation:[{ text: "Spouse", value: "1", disabled:true }, { text: "Child", value: "2" }],
			disciplines_list:[],
			diversities: [],
			federation:[],
			ethnicityChoices:[],
			disabilityChoices:[],
			veteranStatusChoices: [],
			FamilyMember: {
				id:"",
				confirmation_code: "",
				membership_id: 5,
				member_type_id: 3,
				team_id: [],
				disciplines:[],
				relation: "",
				hoh_member_id: "",
				first_name: "",
				last_name: "",
				middle_name: "",
				dob: "",
				gender: "",
				home_federation: "",
				skier_license: "",
				diversity_id: "",
				ethnicity: "",
				disability: "",
				veteran_status:"",
				first_choice: "",
				second_choice: "",
				email: "",
				phone_1: "",
				phone_2: "",
				address1: "",
				address2: "",
				country: "",
				state_code: "",
				state: "",
				city: "",
				zip: "",
			},
			errors: {
				status: true,
				family: {
					relation: { valid:true, message:"" },
					first_name: { valid:true, message:"" },
					last_name: { valid:true, message:"" },
					dob: { valid:true, message:"" },
					gender: { valid:true, message:"" },
					email: { valid:true, message:"" },
					phone_1: { valid:true, message:"" },
					first_choice: { valid:true, message:"" },
					team_id: { valid:true, message:"" },
					disciplines: { valid:true, message:"" },
					address1: { valid:true, message:"" },
					country: { valid:true, message:"" },
					state_code: { valid:true, message:"" },
					state: { valid:true, message:"" },
					city: { valid:true, message:"" },
					zip: { valid:true, message:"" },
					home_federation: { valid:true, message:"" },
					skier_license: { valid:true, message:"" },
					diversity_id: { valid:true, message:"" },
					ethnicity: { valid:true, message:"" },
					disability: { valid:true, message:"" },
					veteran_status: { valid:true, message:"" },
				}
			}
		}
	},
	methods: {
		 getAge(birth_date) {
            let age = moment().diff(birth_date, 'years', true);
            age = parseInt(age);
        	return age;
      	},
		buildOptinos() {
			this.genderChoices = this.getSiteProps('memberRegistration.family.fields.personal.gender.choices');
			this.ethnicityChoices = this.getSiteProps('memberRegistration.family.fields.demographic.ethnicity.choices');
			this.disabilityChoices = this.getSiteProps('memberRegistration.family.fields.demographic.disability_status.choices');
			if(this.subdomain == 'waterski'){
				this.veteranStatusChoices = this.getSiteProps('memberRegistration.family.fields.demographic.veteran_status.choices');
			}else{
				this.veteranStatusChoices = this.getSiteProps('memberRegistration.family.fields.personal.veteran_status.choices');
			}
		},
		showModal(arg) {
			this.buildOptinos();
			$('.custom-control-input').prop('checked', false);
			this.relation[0].disabled = (this.$parent.spouseCount > 0 ? true : false);
			let newSpouseCount = this.$parent.spouseCount;
			for (const [key, value] of Object.entries(this.errors.family)) { this.errors.family[key]['valid'] = true; this.errors.family[key]['message'] = ""; }
			if(localStorage.getItem("member") != null) {
				let member = JSON.parse(localStorage.getItem("member"));
				if("person_id" in member && member.person_id != '' && member.person_id != null) {
					this.$refs['modal-family-member-add'].show();
					this.btn_submit.load = false;
					this.btn_submit.text = 'Add';

					this.FamilyMember.id = "";
					this.FamilyMember.confirmation_code = "";
					this.FamilyMember.team_id = [];
					this.FamilyMember.disciplines = [];
					this.FamilyMember.relation = newSpouseCount > 0 ? 2 : false;
					this.FamilyMember.hoh_member_id = member.person_id;
					this.FamilyMember.first_name = "";
					this.FamilyMember.middle_name = "";
					this.FamilyMember.last_name = "";
					this.FamilyMember.gender = "";
					this.FamilyMember.dob = "";
					this.FamilyMember.home_federation = member.home_federation;
					this.FamilyMember.skier_license = "";
					this.FamilyMember.diversity_id = "";//member.diversity.id;
					this.FamilyMember.ethnicity = "";//member.ethnicity;
					this.FamilyMember.disability = "";//member.disability;
					this.FamilyMember.veteran_status = "";//member.veteran_status;
					this.FamilyMember.first_choice = "";
					this.FamilyMember.second_choice = "";
					this.FamilyMember.email = "";
					this.FamilyMember.phone_1 = "";
					this.FamilyMember.phone_2 = "";
					this.FamilyMember.address1 = "";
					this.FamilyMember.address2 = "";
					this.FamilyMember.country = "USA";
					this.FamilyMember.state = "";
					this.FamilyMember.state_code = "";
					this.FamilyMember.city = "";
					this.FamilyMember.zip = "";
				}
			}else if(arg) {
				this.relation[0].disabled = this.addSpouse;
				this.request_from = 'admin';
				let member = arg;
				if("person_id" in member && member.person_id != '' && member.person_id != null) {
					this.$refs['modal-family-member-add'].show();
					this.btn_submit.load = false;
					this.btn_submit.text = 'Add';

					this.FamilyMember.id = "";
					this.FamilyMember.confirmation_code = "";
					this.FamilyMember.team_id = [];
					this.FamilyMember.disciplines = [];
					this.FamilyMember.relation = this.addSpouse ? 2 : false;
					this.FamilyMember.hoh_member_id = member.relation == 0 ? member.person_id : member.hoh_member_id;
					this.FamilyMember.first_name = "";
					this.FamilyMember.middle_name = "";
					this.FamilyMember.last_name = "";
					this.FamilyMember.gender = "";
					this.FamilyMember.dob = "";
					this.FamilyMember.home_federation = member.home_federation;
					this.FamilyMember.skier_license = "";
					this.FamilyMember.diversity_id = "";//member.diversity.id;
					this.FamilyMember.ethnicity = "";//member.ethnicity;
					this.FamilyMember.disability = "";//member.disability;
					this.FamilyMember.veteran_status = "";//member.veteran_status;
					this.FamilyMember.first_choice = "";
					this.FamilyMember.second_choice = "";
					this.FamilyMember.email = "";
					this.FamilyMember.phone_1 = "";
					this.FamilyMember.phone_2 = "";
					this.FamilyMember.address1 = "";
					this.FamilyMember.address2 = "";
					this.FamilyMember.country = "USA";
					this.FamilyMember.state = "";
					this.FamilyMember.state_code = "";
					this.FamilyMember.city = "";
					this.FamilyMember.zip = "";
				}
			}
			 else {
				this.setDangerBg();
				this.alertMessage = "HOH Member Information not found....";
				this.displayAlert = true;
				this.newDismissSecs = 8;
			}
		},
		isFutureDate(d) {
      let childValidAge = this.getSiteProps('memberRegistration.family.fields.family.popup.birthDay.child_age');
			let mindate = d < (this.FamilyMember.relation == '' || this.FamilyMember.relation == 2 ? new Date().setFullYear(new Date().getFullYear() - childValidAge) : new Date().setFullYear(new Date().getFullYear() - 150));
            return (d > new Date() || mindate);
        },
		DisciplinesList() {
			axios.get(this.basePath+"api/getDisciplines").then(response => {
				this.disciplines = response.data.data;
				let discipline_codes = Object.keys(this.disciplines);
				if(discipline_codes.length && this.disciplines_list.length == 0) {
					this.disciplines_list.push({ id: "", text: "Select" });
					discipline_codes.forEach(code => {
						this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
					});
				}
			}).catch(err => { });
		},
		getClubs() {
			axios.get(this.basePath + "api/get_teams").then((response) => {
				this.clubOptions = response.data.data;
			}).catch(error=>{
				console.log("error geting clubs list")
			});
		},
		getDiversity() {
			axios.get(this.basePath+"api/getDiversity").then(response => {
				this.diversities = response.data.data;
			}).catch(err => { console.log(err); });
		},
		getGroup() {
			axios.get(this.basePath+'api/getGroup').then(response => {
				this.federation = response.data.data;
			}).catch(err => { });
		},
		/*	Start :	Add Form Validation Rules	*/
		validateRelation() {
			if(this.FamilyMember.relation != 1 && this.FamilyMember.relation != 2) {
				this.errors.status = false;
				this.errors.family.relation.valid = false;
				this.errors.family.relation.message = "Select Relationship";
			} else {
				if(this.FamilyMember.relation == 1){
					// let age = this.getAge(this.FamilyMember.dob);
					
					if(this.getAge(this.FamilyMember.dob) < 18){
						this.errors.status = false;
						this.errors.family.relation.valid = false;
						this.errors.family.relation.message = "spouse must be age above 17";
					}else{
						this.errors.family.relation.valid = true;
						this.errors.family.relation.message = "";
					}
				}else{
					let childValidAge = this.getSiteProps('memberRegistration.family.fields.family.popup.birthDay.child_age');
					if(this.getAge(this.FamilyMember.dob) >= childValidAge){
						this.errors.status = false;
						this.errors.family.relation.valid = false;
						this.errors.family.relation.message = "Child must be age below "+childValidAge;
					}else{
						this.errors.family.relation.valid = true;
						this.errors.family.relation.message = "";
					}
				}
				
			}
		},
		validateFirstName() {
			if(this.FamilyMember.first_name == '') {
				this.errors.status = false;
				this.errors.family.first_name.valid = false;
				this.errors.family.first_name.message = "Enter First Name";
			} else if(!this.FamilyMember.first_name.match(/^[a-zA-Z\s]*$/)){
        this.errors.status = false;
        this.errors.family.first_name.valid = false;
        this.errors.family.first_name.message = "must be letters only. No other characters";
      }
      else {
				this.errors.family.first_name.valid = true;
				this.errors.family.first_name.message = "";
			}
		},
		validateLastName() {
			if(this.FamilyMember.last_name == '') {
				this.errors.status = false;
				this.errors.family.last_name.valid = false;
				this.errors.family.last_name.message = "Enter Last Name";
			}  else if(!this.FamilyMember.last_name.match(/^[a-zA-Z-\s]*$/)){
        this.errors.status = false;
        this.errors.family.last_name.valid = false;
        this.errors.family.last_name.message = "must be letters and hyphens only. No other characters";
      }
      else {
				this.errors.family.last_name.valid = true;
				this.errors.family.last_name.message = "";
			}
		},
		validateGender() {
			if(this.FamilyMember.gender == '' || !this.genderChoices.some(obj => obj.label === this.FamilyMember.gender)) {
				this.errors.status = false;
				this.errors.family.gender.valid = false;
				this.errors.family.gender.message = "Select Gender";
			} else {
				this.errors.family.gender.valid = true;
				this.errors.family.gender.message = "";
			}
		},
		validateDob() {
			if(this.FamilyMember.dob == '') {
				this.errors.status = false;
				this.errors.family.dob.valid = false;
				this.errors.family.dob.message = "Select Date of Birth";
			} else if(!moment(this.FamilyMember.dob, 'MM/DD/YYYY',true).isValid()) {
				this.errors.status = false;
				this.errors.family.dob.valid = false;
				this.errors.family.dob.message = "Invalid Date format";
			} else {
				this.errors.family.dob.valid = true;
				this.errors.family.dob.message = "";
			}
		},
		validateEmailID() {
			if(this.FamilyMember.email == '') {
				this.errors.status = false;
				this.errors.family.email.valid = false;
				this.errors.family.email.message = "Enter Email-ID";
			} else if(!String(this.FamilyMember.email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
				this.errors.status = false;
				this.errors.family.email.valid = false;
				this.errors.family.email.message = "Enter Valid Email-ID";
			} else {
				this.errors.family.email.valid = true;
				this.errors.family.email.message = "";
			}
		},
		validatePhone_1() {
			if(this.FamilyMember.phone_1 == '') {
				this.errors.status = false;
				this.errors.family.phone_1.valid = false;
				this.errors.family.phone_1.message = "Enter Mobile Number";
			} else {
				this.errors.family.phone_1.valid = true;
				this.errors.family.phone_1.message = "";
			}
		},
		validateFirstChoice() {
			if(this.FamilyMember.first_choice == '' && this.subdomain == 'waterski') {
				this.errors.status = false;
				this.errors.family.first_choice.valid = false;
				this.errors.family.first_choice.message = "Select first choice of sports decipline";
			} else {
				this.errors.family.first_choice.valid = true;
				this.errors.family.first_choice.message = "";
			}
		},
		validateTeam() {
			if(this.FamilyMember.team_id == '') {
				this.errors.status = false;
				this.errors.family.team_id.valid = false;
				this.errors.family.team_id.message = "Select Team/Club";
			} else {
				this.errors.family.team_id.valid = true;
				this.errors.family.team_id.message = "";
			}
		},
		validateDisciplines() {
			if(this.FamilyMember.disciplines == '' && this.subdomain == 'shooting') {
				this.errors.status = false;
				this.errors.family.disciplines.valid = false;
				this.errors.family.disciplines.message = "Select Disciplines";
			} else {
				this.errors.family.disciplines.valid = true;
				this.errors.family.disciplines.message = "";
			}
		},

		validateMailingAddress() {
			if(this.FamilyMember.address1 == '') {
				this.errors.status = false;
				this.errors.family.address1.valid = false;
				this.errors.family.address1.message = "Enter Mailing Address";
			} else {
				this.errors.family.address1.valid = true;
				this.errors.family.address1.message = "";
			}
		},
		validatePhysicalCountry() {
			if(this.FamilyMember.country == '') {
				this.errors.status = false;
				this.errors.family.country.valid = false;
				this.errors.family.country.message = "Select country";
			} else {
				this.errors.family.country.valid = true;
				this.errors.family.country.message = "";
				
				this.errors.family.state_code.valid = true;
				this.errors.family.state_code.message = "";
				this.errors.family.state.valid = true;
				this.errors.family.state.message = "";
				
			}
		},
		validatePhysicalState() {
			if(this.FamilyMember.state_code == '') {
				this.errors.status = false;
				this.errors.family.state_code.valid = false;
				this.errors.family.state_code.message = "Enter your city name";
			} else {
				this.errors.family.state_code.valid = true;
				this.errors.family.state_code.message = "";
			}
		},
		validateOtherState() {
			if(this.FamilyMember.state == '') {
				this.errors.status = false;
				this.errors.family.state.valid = false;
				this.errors.family.state.message = "Enter state name";
			} else {
				this.errors.family.state.valid = true;
				this.errors.family.state.message = "";
			}
		},
		validatePhysicalCity() {
			if(this.FamilyMember.city == '') {
				this.errors.status = false;
				this.errors.family.city.valid = false;
				this.errors.family.city.message = "Enter your city name";
			} else {
				this.errors.family.city.valid = true;
				this.errors.family.city.message = "";
			}
		},
		validatePhysicalZip() {
			if(this.FamilyMember.zip == '') {
				this.errors.status = false;
				this.errors.family.zip.valid = false;
				this.errors.family.zip.message = "Enter postal/zip code";
			} else if(!this.FamilyMember.zip.match(/^\d{5}(-\d{4})?$/) && this.FamilyMember.country == 'USA'){
				this.errors.status = false;
				this.errors.family.zip.valid = false;
				this.errors.family.zip.message = "Zip Code Must Be Atleast 5 Character";
			} else {
				this.errors.family.zip.valid = true;
				this.errors.family.zip.message = "";
			}
		},
		validateHomeFedaration() {
			if(this.FamilyMember.home_federation == '') {
				this.errors.status = false;
				this.errors.family.home_federation.valid = false;
				this.errors.family.home_federation.message = "Enter fedaration ";
			} else {
				this.errors.family.home_federation.valid = true;
				this.errors.family.home_federation.message = "";
			}
		},
		validateSkierLicense() {
			if(this.FamilyMember.skier_license == '' && this.subdomain == 'waterski') {
				this.errors.status = false;
				this.errors.family.skier_license.valid = false;
				this.errors.family.skier_license.message = "Enter Foreign Federation ID";
			} else {
				this.errors.family.skier_license.valid = true;
				this.errors.family.skier_license.message = "";
			}
		},
		validateDiversity() {
			if(this.FamilyMember.diversity_id == '') {
				this.errors.status = false;
				this.errors.family.diversity_id.valid = false;
				this.errors.family.diversity_id.message = "Select Race";
			} else {
				this.errors.family.diversity_id.valid = true;
				this.errors.family.diversity_id.message = "";
			}
		},
		validateEthnicity() {
			if(this.FamilyMember.ethnicity == '' || !this.ethnicityChoices.some(obj => obj.label === this.FamilyMember.ethnicity)) {
			this.errors.status = false;
				this.errors.family.ethnicity.valid = false;
				this.errors.family.ethnicity.message = "Select Ethnicity";
			} else {
				this.errors.family.ethnicity.valid = true;
				this.errors.family.ethnicity.message = "";
			}
		},
		validateDisability() {
			if(this.FamilyMember.disability == '' || !this.disabilityChoices.some(obj => obj.label === this.FamilyMember.disability)) {
				this.errors.status = false;
				this.errors.family.disability.valid = false;
				this.errors.family.disability.message = "Select disablity status";
			} else {
				this.errors.family.disability.valid = true;
				this.errors.family.disability.message = "";
			}
		},
		validateVeteranStatus() {
			if(this.FamilyMember.relation != 2 && this.FamilyMember.veteran_status == '' || !this.veteranStatusChoices.some(obj => obj.label === this.FamilyMember.veteran_status)) {
				this.errors.status = false;
				this.errors.family.veteran_status.valid = false;
				this.errors.family.veteran_status.message = "Select veteran status";
			} else {
				this.errors.family.veteran_status.valid = true;
				this.errors.family.veteran_status.message = "";
			}
		},
		/*	End :	Add Form Validation Rules	*/
		AddMemberValidate() {
			this.errors.status = true;
			this.validateRelation(); //Relationship validation
			if(this.FamilyMember.relation != 2 ) {
				this.validateVeteranStatus();
			} else { 
				this.errors.family.veteran_status.valid = false;
				this.errors.family.veteran_status.message = "";
			}
			this.validateFirstName(); //First name validation
			this.validateLastName(); //Last name validation
			this.validateGender(); //Gender validation
			this.validateDob(); //Date of Birth validation
			this.validateEmailID(); //Email-ID validation
			this.validatePhone_1(); //Mobile Number validation
			this.validateFirstChoice(); //Sport Discipline first Choice
			// this.validateTeam(); //Team
			this.validateDisciplines();
			// this.validateMailingAddress() // mailing Address
			// this.validatePhysicalCountry() //Physical Country Name
			// if(this.FamilyMember.country == 'USA') {
			// 	this.validatePhysicalState(); //Physical State Name
			// 	this.FamilyMember.state = '';
			// 	this.errors.family.state.valid = true;
			// 	this.errors.family.state.message = "";
			// } else if(this.FamilyMember.country == 'Canada'){
			// 	this.validateOtherState() //Other State Name
			// 	this.FamilyMember.state_code = "";
			// 	this.errors.family.state_code.valid = true;
			// 	this.errors.family.state_code.message = "";
			// }
			// this.validatePhysicalCity(); //Physical City Name
			// this.validatePhysicalZip(); //Physical postal zip code 
			// if(this.subdomain == 'waterski'){
			// 	this.validateHomeFedaration() //Home Fedaration
			// }
			// if(this.FamilyMember.home_federation != 'USA' && this.subdomain == 'waterski') { this.validateSkierLicense(); }
			// else { this.FamilyMember.skier_license = ''; }

			this.validateDiversity(); //Diversity/Race
			if(this.subdomain == 'waterski'){
			this.validateEthnicity() //Ethnicity
			this.validateDisability() //Disablity Status
			}
		},
		AddMemberInfo(bvModalEvt){
			// this.errors.status == true;
			bvModalEvt.preventDefault();
			this.AddMemberValidate();
			if(this.errors.status == false){
				this.setDangerBg();
				this.alertMessage = "Please fill all mandatory fields";
                this.displayAlert = true;
				this.newDismissSecs = 8;
			} else {
				this.btn_submit.load = true;
				this.btn_submit.text = 'Adding...';
				axios.post(this.basePath+'api/member/family/add', this.FamilyMember, { headers:this.memberHeaders })
				.then(function (response) {
					if(response.data.status == 'success') {
						
							this.alertMessage = 'Family Member added successfully';
							this.displayAlert = true;
							this.newDismissSecs = 7;
						if(this.request_from == 'admin'){
							setTimeout(() => {
								location.reload();
							}, 4000);
						}else{
							setTimeout(() => {
								this.$refs['modal-family-member-add'].hide();
								this.$emit('memfresh', {loader: true, id: null });
							}, 3000);
						}
					} else  {
						console.log('response.data.message',response.data.message);
						this.btn_submit.load = false;
						this.btn_submit.text = 'Add';
						this.setDangerBg();
						this.alertMessage = response.data.message;
						this.displayAlert = true;
						this.newDismissSecs = 7;
					}
				}.bind(this)).catch(function (error) {
					console.log("Error: "+error);
				});
			}
		},
		resetAlert(){
                this.displayAlert = false;
				this.newDismissSecs=0;
                this.removeDangerBg();
        },
		setDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-success');
                                $('#alert_box').children('div').addClass('alert-danger');
                    }, 100);
        },
		removeDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-danger');
                                $('#alert_box').children('div').addClass('alert-success');
                    }, 100);
        },
	},
	mounted() {
		this.DisciplinesList();
		this.getClubs();
		this.getDiversity();
		this.getGroup();
		EventBus.$on('addSpouse', name => {
      		this.addSpouse = name;
    	});
	}
}
</script>

<style scoped>
.select2-container {
	z-index:1999;
}
</style>