<template>
<div class="card family-members">
<div class="card-header register-title">
    <h3 class="mb-3">
        {{ fields.tab_title || 'Family Members'}}
        <b-button v-if="fields.description" v-b-tooltip.hover :title="fields.description"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></b-button>
    </h3>
</div>
<div class="card-body addnewbutn">
  <label v-if="fields.sub_title">{{fields.sub_title}}</label>
    <b-button v-b-modal.modal-center-family3 @click.prevent="familyMembersmodal" v-if="members.length <= (fields.total_members || 5) && !player_informations"> + Add New Member</b-button>
      <div class="row" v-if="fields.isVisible">
        <div class="col-md-12 text-center topspace">
          <div class="d-flex float-center ">
            <button type="button" v-b-modal.modal-center4 @click.prevent="familyMembersmodal" class="btn btn-outline-primary savebtn"><i class="fas fa-solid fa-plus"></i>Add Player
            </button>
            <button type="button" v-b-modal.modal-invite class="btn btn-outline-primary next-step-btn savebtn  mr-0"><i class="fas fa-solid fa-envelope"></i> Invite Members <span class="badge badge-dark ml-2" v-if="inviteForm.invite_players && inviteForm.invite_players.length > 0">{{ inviteForm.invite_players.length }}</span></button>
          </div>
        </div>
      </div>
    <span class="text-sm text-danger">{{ errors.primary }}</span>
    <b-modal id="modal-center-family3" size="lg" ok-only centered :title="fields.popup.title || 'Family Member Details'"
             @hidden="resetModal"
             ok-title="Save"  @ok="saveMember">
        <div class="popupbody">
          <div v-if="!player_informations">
            <div class="row">
                <div class="col-md-6" v-if="fields.popup.relation">
                    <label class="form-label">{{ fields.popup.relation.label }} <sup class="text-danger">*</sup></label><br>
                    <div class="d-flex">
                        <template v-for="(choice,k) in fields.popup.relation.choices">
                            <div :class="[choice.containerClass]" :key="'relation'+k">
                                <input type="radio" @change="removeHohEmail(choice.value)" :id="choice.id" name="form.relation" :value="choice.value" v-model="form.relation">
                                <span :for="choice.id" class="gender-label-span">{{ choice.label }}</span>
                            </div>
                        </template>
                    </div>
                    <p class="text-sm text-danger mb-1">{{ errors.relation }}</p>
                </div>
                <div class="col-md-6" v-if="fields.popup.email">
                    <div class="form-group">
                        <label for="email" class="form-label">{{ fields.popup.email.label }} <sup class="text-danger" v-if="fields.popup.email.required">*</sup></label>
                        <input type="text" class="form-control" maxlength="45" name="form.email" v-model="form.email" >
                        <span class="text-sm text-danger">{{ errors.email }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-md-4" v-if="fields.popup.firstName">
                <div class="form-group">
                    <label for="firstName" class="form-label">{{ fields.popup.firstName.label }} <sup class="text-danger" v-if="fields.popup.firstName.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" name="form.first_name" v-model="form.first_name" >
                    <span class="text-sm text-danger">{{ errors.first_name }}</span>
                </div>
            </div>
            <div class="col-md-2" v-if="fields.popup.middleInitial">
                <div class="form-group">
                    <label for="middleInitial" class="form-label">Middle Initial</label>
                    <input type="text" maxlength="3" name="form.middle_name" v-model="form.middle_name" class="form-control">
                    <span class="text-sm text-danger">{{ errors.middle_name }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.LastName">
                <div class="form-group">
                    <label for="LastName" class="form-label">{{ fields.popup.LastName.label }} <sup class="text-danger" v-if="fields.popup.LastName.required">*</sup></label>
                    <input type="text" maxlength="45" name="form.last_name" v-model="form.last_name" class="form-control">
                    <span class="text-sm text-danger">{{ errors.last_name }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.birthDay">
                <label for="birthDay" class="form-label">{{ fields.popup.birthDay.label }} <sup class="text-danger" v-if="fields.popup.birthDay.required">*</sup></label>
                <div class="input-group birthdate">
                    <div class="birthdaypicker">
                        <!-- <b-form-datepicker id="family-datepicker" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="form.dob" class="mb-2"></b-form-datepicker> -->
                        <!-- <i class="fas fa-calendar-alt"></i> -->
                        <date-pick
                            v-model="form.dob"
                            :pickTime="false"
                            name="form.dob"
                            :selectableYearRange="{from: 1900, to: 2021}"
                            :format="'MM/DD/YYYY'"
                            v-mask="'##/##/####'"
                            :isDateDisabled="isFutureDate"
                            :parseDate="parseDatePick"
                        ></date-pick>
                    </div>
                    <span class="text-sm text-danger">{{ errors.dob }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.gender">
                <label for="gender" class="form-label">{{ fields.popup.gender.label }} <sup class="text-danger" v-if="fields.popup.gender.required">*</sup></label>
                <div class="form-group">
                    <div class="row">
                        <div class="mb-1" :class="k < 3 ? 'col-md-3' : 'col-md-5'" v-for="(choice, k) in fields.popup.gender.choices" :key="'gender_'+k"   @click="messageFor(choice.id)">
                            <input type="radio" :id="choice.id" v-model="form.gender" name="form.gender" :value="choice.label">
                            <span class="gender-label-span" :for="choice.id">{{ choice.label }}</span>
                        </div>
                    </div>
                    <span class="text-sm text-danger">{{ errors.gender }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.homephone">
                <div class="form-group ">
                    <div class="phone-nu-wrapper">
                        <label for="country_code_h" class="form-label">{{ fields.popup.homephone.label }}<sup class="text-danger" v-if="fields.popup.homephone.required">*</sup></label>
                        <vue-tel-input :inputOptions="phoneOptions" @validate="validateTelinput" ref="phone" v-model="form.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
                       <!--<input type="text" class="form-control"  id="country_code_h" name="country_code_h" placeholder="+1">
                        <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1">-->
                    </div>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.mobile">
                <div class="form-group">
                    <div class="phone-nu-wrapper">
                        <label for="phone_2" class="form-label">{{ fields.popup.mobile.label }}</label>
                        <vue-tel-input :inputOptions="phoneOptions" @validate="validateTelinput2"  ref="phone" v-model="form.phone_2" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
                        <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1">
                        <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2">-->
                    </div>
                </div>
            </div>
            </div>
            <div class="row" v-if="fields.popup.sport_discipline">
            <div class="col-md-12" v-if="fields.popup.sport_discipline.label">
                <label class="form-check-label form-label">{{ fields.popup.sport_discipline.label }}</label>
            </div>
            <div :class="first_choice_class" v-if="fields.popup.sport_discipline.first_choice">
                <div class="form-group">
                    <label for="first-choice" class="form-label">{{ fields.popup.sport_discipline.first_choice.label }} <sup class="text-danger" v-if="fields.popup.sport_discipline.first_choice.required">*</sup></label>
                    <Select2 v-if="fields.popup.sport_discipline.first_choice.multiple" v-model="form.discipline_1" 
                    name="form.discipline_1" id="first-choice" :options="disciplines_list" 
                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" @change="collectDisciplines" />
                    <select class="form-control" id="first-choice" name="form.discipline_1" @change="collectDisciplines" v-model="form.discipline_1" v-else>
                        <option value="">Select</option>
                        <option v-for="(discipline, index) in disciplines" :value="index"
                                :key="'types'+index">{{ discipline }}
                        </option>
                    </select>
                    <span class="text-sm text-danger">{{ errors.discipline_1 }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.sport_discipline.second_choice">
                <div class="form-group">
                    <label for="secondchoice" class="form-label">{{ fields.popup.sport_discipline.second_choice.label }}</label>
                    <select class="form-control" id="secondchoice" name="form.discipline_2" v-model="form.discipline_2" @change="collectSecondChoice">
                        <option value="">Select</option>
                        <option v-for="(discipline, index) in disciplines" :value="index"
                                :key="'types2-'+index">{{ discipline }}
                        </option>
                    </select>
                    <span class="text-sm text-danger">{{ errors.discipline_2 }}</span>
                </div>
            </div>
            </div>
            <div class="row">
              <div :class="[fields.popup.clubandteam.colClass]" v-if="fields.popup.clubandteam">
                <div class="form-group">
                  <label for="clubandteam" class="form-label">{{ fields.popup.clubandteam.label }}<sup class="text-danger"
                                                                                                 v-if="fields.popup.clubandteam.required">*</sup></label>
                  <Select2 v-model="form.clubandteam" :options="clubOptions"
                           :placeholder="fields.popup.clubandteam.placeholder"
                           :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                  <p class="mt-2 font-13" v-if="fields.popup.clubandteam.description"><i
                      v-html="fields.popup.clubandteam.description"></i></p>
                </div>
              </div>
          <div :class="[fields.popup.address1.colClass]" v-if="fields.popup.address1">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.popup.address1.label }} <sup class="text-danger" v-if="fields.popup.address1.required">*</sup><span v-if="fields.popup.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ fields.popup.address1.charLimitText }}</span></label>
              <input type="text" class="form-control" :maxlength=fields.popup.address1.charLimitValue id="address1" v-model="form.address1" name="form.address1">
              <span class="text-sm text-danger">{{ errors.address1}}</span>
            </div>
          </div>
          <div :class="[fields.popup.address2.colClass]" v-if="fields.popup.address2">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.popup.address2.label }} <span v-if="fields.popup.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ fields.popup.address2.charLimitText }}</span></label>
              <input type="text" class="form-control" :maxlength=fields.popup.address2.charLimitValue id="address2" v-model="form.address2" name="form.address2">
              <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
            </div>
          </div>
              <div :class="[fields.popup.country.colClass]" v-if="fields.popup.country">
                <div class="form-group">
                  <label for="Country" class="form-label">{{ fields.popup.country.label }} <sup class="text-danger" v-if="fields.popup.country.required">*</sup></label>
                  <select class="form-control" id="country" @change="removeStateCode" v-model="form.country" name="form.country">
                    <option value="">Select</option>
                    <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.country}}</span>
                </div>
              </div>
              <div :class="[fields.popup.city.colClass]" v-if="fields.popup.city">
                <div class="form-group">
                  <label for="city" class="form-label">{{ fields.popup.city.label }} <sup class="text-danger" v-if="fields.popup.city.required">*</sup></label>
                  <input type="text" class="form-control" maxlength="45" id="city"  v-model="form.city" name="form.city">
                  <span class="text-sm text-danger">{{ errors.city}}</span>
                </div>
              </div>
              <div :class="[fields.popup.state_code.colClass]" v-if="form.country == 'USA' && fields.popup.state_code">
                <div class="form-group">
                  <label for="state_code" class="form-label">{{ fields.popup.state_code.label }} <sup class="text-danger" v-if="fields.popup.state_code.required">*</sup></label>
                  <select class="form-control" id="state_code" v-model="form.state_code" name="form.state_code" >
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.state_code}}</span>
                </div>
              </div>
              <div :class="[fields.popup.state_code.colClass]" v-else-if="form.country == 'Canada' && fields.popup.state_code">
                <div class="form-group">
                  <label for="state_code" class="form-label">{{ fields.popup.state_code.label }} <sup class="text-danger" v-if="fields.popup.state_code.required">*</sup></label>
                  <select class="form-control" id="state" v-model="form.state" name="form.state" >
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.state}}</span>
                </div>
              </div>
              <div :class="[fields.popup.other_state.colClass]" v-else>
                <div class="form-group" v-if="fields.popup.other_state">
                  <label for="state_code" class="form-label">{{ fields.popup.other_state.label }} </label>
                  <input type="text" class="form-control" maxlength="45" v-model="form.state" name="form.state">
                  <span class="text-sm text-danger">{{ errors.state}}</span>
                </div>
              </div>

              <div :class="[fields.popup.zip.colClass]" v-if="fields.popup.zip">
                <div class="form-group">
                  <label for="zip" class="form-label">{{ fields.popup.zip.label }} <sup class="text-danger" v-if="fields.popup.zip.required">*</sup></label>
                  <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" v-model="form.zip" name="form.zip">
                  <span class="text-sm text-danger">{{ errors.zip}}</span>
                </div>
              </div>
              <div :class="[fields.popup.home_federation.colClass]" v-if="fields.popup.home_federation">
                <div class="form-group">
                  <label for="home_federation" class="form-label">{{ fields.popup.home_federation.label }} <sup v-if="fields.popup.home_federation.required">*</sup></label>
                  <select class="form-control" id="home_federation" name="form.home_federation"
                          v-model="form.home_federation">
                    <option value="">Select</option>
                    <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.home_federation }}</span>
                </div>
              </div>
              <div :class="[fields.popup.skier_license.colClass]" v-if="fields.popup.skier_license">
                <div v-if="form.home_federation !='USA'">
                  <div class="form-group">
                    <label for="skier_license" class="form-label">{{ fields.popup.skier_license.label }}<sup v-if="fields.popup.skier_license.required">*</sup></label>
                    <input type="text" id="skier_license" class="form-control" maxlength="9" @keypress="isNumber($event)"
                           placeholder="" name="form.skier_license"
                           v-model="form.skier_license">
                    <span class="text-sm text-danger">{{ errors.skier_license }}</span><br/>
                    <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                    <div class="text-sm text-success" v-if="fields.popup.skier_license.description" v-html="fields.popup.skier_license.description"></div>
                  </div>
                </div>
              </div>
              <!-- Demographic start-->
            <div :class="[fields.popup.race.colClass]" v-if="fields.popup.race">
              <div class="form-group radiotext">
                <label for="race" class="form-label-check"><strong>{{ fields.popup.race.label }}</strong> <sup
                    class="text-danger" v-if="fields.popup.race.required">*</sup></label> <br>
                <div v-for="diversity in diversities" :key="diversity.name">
                  <label :for="'diversity_id'+diversity.id">
                    <input type="radio" name="form.diversity_id" :value="diversity.id"
                           :id="'diversity_id'+diversity.id"
                           v-model="form.diversity_id" @change="saveDiversityTxt(diversity.name)">
                    {{ diversity.name }}</label> <br>
                </div>
                <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
              </div>
            </div>
            <div :class="[fields.popup.ethnicity.colClass]" v-if="fields.popup.ethnicity">
              <div class="form-group radiotext">
                <label for="ethnicitystatus" class="form-label-check"><strong>{{ fields.popup.ethnicity.label }}</strong><sup
                    class="text-danger" v-if="fields.popup.ethnicity.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.ethnicity.choices">
                  <template v-for="(choice, k) in fields.popup.ethnicity.choices">
                    <input type="radio" :id="choice.id" name="ethnicity" :value="choice.label" v-model="form.ethnicity"
                           :key="'ethnicity_'+k">
                    <label :for="choice.id" :key="'ethnicity_label_'+k">{{ choice.label }}</label>
                    <br :key="'ethnicity_br_'+k">
                  </template>
                  <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.popup.disability_status.colClass]" v-if="fields.popup.disability_status">
              <div class="form-group radiotext">
                <label for="disabilitystatus" class="form-label-check"><strong>{{
                    fields.popup.disability_status.label
                  }}</strong> <sup class="text-danger" v-if="fields.popup.disability_status.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.disability_status.choices">
                  <template v-for="(choice, k) in fields.popup.disability_status.choices">
                    <input type="radio" :id="choice.id" name="disability" :value="choice.label"
                           v-model="form.disability" :key="'ds_'+k">
                    <label :for="choice.id" :key="'ds_label_'+k">{{ choice.label }}</label>
                    <br :key="'ds_br_'+k">
                  </template>
                  <span class="text-sm text-danger">{{ errors.disability }}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.popup.veteran_status.colClass]" v-if="fields.popup.veteran_status && form.relation == 1">
              <div class="form-group radiotext">
                <label for="veteranstatus" class="form-label-check"><strong>{{
                    fields.popup.veteran_status.label
                  }}</strong><sup class="text-danger" v-if="fields.popup.veteran_status.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.veteran_status.choices">
                  <template v-for="(choice, k) in fields.popup.veteran_status.choices">
                    <input type="radio" :id="choice.id" name="veteran_status" :value="choice.label"
                           v-model="form.veteran_status" :key="'vs_'+k">
                    <label :for="choice.id" :key="'vs_label_'+k">{{ choice.label }}</label>
                    <br :key="'vs_br_'+k">
                  </template>
                  <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                </div>
              </div>
            </div>
<!--            <div :class="[fields.primary_area_interest.colClass]" v-if="fields.primary_area_interest">
              <div class="form-group">
                <label for="area_of_interest" class="form-label">{{ fields.primary_area_interest.label }}<sup
                    class="text-danger" v-if="fields.primary_area_interest.required">*</sup></label>
                <select name="form.area_of_interest" id="area_of_interest" class="form-control"
                        v-model="form.primary_area_interest">
                  <option value="">Select</option>
                  <option v-for="(item, k) in fields.primary_area_interest.options" :key="'pai'+k" :value="item">{{
                      item
                    }}
                  </option>
                </select>
                <span class="text-sm text-danger">{{ errors.primary_area_interest }}</span>
              </div>
            </div>
            <div :class="[fields.category.colClass]" v-if="fields.category">
              <div class="form-group">
                <label for="category" class="form-label">{{ fields.category.label }}<sup class="text-danger"
                                                                                         v-if="fields.category.required">*</sup></label>
                <select name="form.category" id="category" class="form-control" v-model="form.category"
                        @change="updateCategoryName($event)">
                  <option value="">Select</option>
                  &lt;!&ndash; <option v-for="(item, k) in fields.category.options" :key="'catd-'+k" :value="item">{{ item }}</option> &ndash;&gt;
                  <option v-for="(item, k) in categories" :key="'catd-'+k" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.category }}</span>
              </div>
            </div>
            <div :class="[fields.issf.colClass]" v-if="fields.issf">
              <div class="form-group">
                <label for="issf" class="form-label">{{ fields.issf.label }}<sup class="text-danger"
                                                                                 v-if="fields.issf.required">*</sup></label>
                <input type="text" class="form-control" name="form.issf" v-model="form.issf">
                <span class="text-sm text-danger">{{ errors.issf }}</span>
              </div>
            </div>
            <div :class="[fields.hear_about.colClass]" v-if="fields.hear_about">
              <div class="form-group">
                <label for="hear_about" class="form-label">{{ fields.hear_about.label }} <sup class="text-danger"
                                                                                              v-if="fields.hear_about.required">*</sup></label>
                <select id="hear_about" class="form-control" name="form.hear_about" v-model="form.hear_about">
                  <option value="">Select</option>
                  <option v-for="(item, k) in fields.hear_about.options" :key="'hear-about-'+k" :value="item">{{
                      item
                    }}
                  </option>
                </select>
                <span class="text-sm text-danger">{{ errors.hear_about }}</span>
              </div>
            </div>
            <div :class="[fields.shirt_size.colClass]" v-if="fields.shirt_size && fields.shirt_size.visible">
              <div class="form-group">
                <label for="shirt_size" class="form-label">{{ fields.shirt_size.label }} <sup class="text-danger"
                                                                                              v-if="fields.shirt_size.required">*</sup></label>
                <select id="shirt_size" class="form-control" name="form.shirt_size" v-model="form.shirt_size">
                  <option value="">Select</option>
                  <option v-for="(item, k) in fields.shirt_size.options" :key="'shirt-size-'+k" :value="item">{{
                      item
                    }}
                  </option>
                </select>
                <span class="text-sm text-danger">{{ errors.shirt_size }}</span>
              </div>
            </div>
            <div :class="[fields.code_of_conduct.colClass]"
                 v-if="fields.code_of_conduct && fields.code_of_conduct.visible" id="code_of_conduct">
              <div class="form-group">
                <label for="code_of_conduct" class="form-label">{{ fields.code_of_conduct.label }}<sup
                    class="text-danger" v-if="fields.code_of_conduct.required">*</sup></label>
                <br>
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" id="code_of_conduct_yes" name="form.code_of_conduct"
                         value="1" v-model="form.code_of_conduct" @change="updateCodeConduct($event)">
                  <label class="form-check-label" for="code_of_conduct_yes">Yes</label>
                </div>
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" id="code_of_conduct_no" name="form.code_of_conduct"
                         value="0" v-model="form.code_of_conduct" @change="updateCodeConduct($event)">
                  <label class="form-check-label" for="code_of_conduct_no">No</label>
                </div>
                <br>
                <span class="text-sm text-danger">{{ errors.code_of_conduct }}</span>
              </div>
            </div>-->
              <!-- Demographic end -->
             <div class="col-md-12" v-show="overAllError != 0">
                <p class="text-danger text-right">Please fill the above missing fields.</p>
            </div>
          </div>
            
          </div>
        </div>
    </b-modal>
  <b-modal id="modal-center4" size="lg" ok-only centered :title="fields.popup.title || 'Player Details'"
           @hidden="resetModal"
           ok-title="Save"  @ok="saveMember">
    <div class="popupbody">
      <div v-if="player_informations">
        <div class="row">
          <div :class="[fields.popup.firstName.colClass]" v-if="fields.popup.firstName">
            <div class="form-group">
              <label for="firstName" class="form-label">
                {{ fields.popup.firstName.label }}<sup class="text-danger" v-if="fields.popup.firstName.required">*</sup>
              </label>
              <input type="text" class="form-control" :placeholder="fields.popup.firstName.placeholder" maxlength="45" id="firstName" v-model="form.first_name" name="form.first_name" value="">
              <span class="text-sm text-danger">{{ errors.first_name}}</span>
              <span class="text-sm text-danger" v-if="duplicateError.hasError">{{ duplicateError.errorMessage}}</span>
            </div>
          </div>
          <div :class="[fields.popup.middleInitial.colClass]" v-if="fields.popup.middleInitial">
            <div class="form-group">
              <label for="middleInitial" class="form-label">
                {{ fields.popup.middleInitial.label }}<sup class="text-danger" v-if="fields.popup.middleInitial.required">*</sup>
              </label>
              <input type="text" class="form-control" :placeholder="fields.popup.middleInitial.placeholder" maxlength="3" id="middleInitial" v-model="form.middle_name" name="form.middle_name" value="">
              <span class="text-sm text-danger">{{ errors.middle_name }}</span>
            </div>
          </div>
          <div :class="[fields.popup.LastName.colClass]" v-if="fields.popup.LastName">
            <div class="form-group">
              <label for="lastname" class="form-label">{{ fields.popup.LastName.label }}<sup class="text-danger" v-if="fields.popup.LastName.required">*</sup></label>
              <input type="text" class="form-control" :placeholder="fields.popup.LastName.placeholder" maxlength="45" id="lastname" v-model="form.last_name" name="form.last_name" value="">
              <span class="text-sm text-danger">{{ errors.last_name}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div :class="[fields.popup.email.colClass]" v-if="fields.popup.email">
            <div class="form-group">
              <label for="email" class="form-label">{{ fields.popup.email.label }} <sup class="text-danger" v-if="fields.popup.email.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="email" v-model="form.email" name="form.email">
              <span class="text-sm text-danger">{{ errors.email}}</span>
            </div>
          </div>
          <div :class="[fields.popup.retype_email.colClass]" v-if="fields.popup.retype_email">
            <div class="form-group">
              <label for="retype_email" class="form-label">{{ fields.popup.retype_email.label }} <sup class="text-danger" v-if="fields.popup.retype_email.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="form.retype_email" name="form.retype_email">
              <span class="text-sm text-danger">{{ errors.retype_email}}</span>
            </div>
          </div>
          <div :class="[fields.popup.birthDay.colClass]" v-if="fields.popup.birthDay">
            <div class="form-group">
              <label for="birthday" class="form-label">{{ fields.popup.birthDay.label }} <sup class="text-danger" v-if="fields.popup.birthDay.required">*</sup></label>
              <div class="input-group birthdate">
                <div class="birthdaypicker">
                  <date-pick
                      v-model="form.dob"
                      :pickTime="false"
                      name="form.dob"
                      :format="'MM/DD/YYYY'"
                      :selectableYearRange="{from: 1900, to: 2021}"
                      v-mask="'##/##/####'"
                      :isDateDisabled="isFutureDate"
                      :inputAttributes="{placeholder: 'mm/dd/yyyy'}"
                      :parseDate="parseDatePick"
                  ></date-pick>
                </div>
                <span class="text-sm text-danger">{{ errors.dob}}</span></div>
            </div>
          </div>
          <div :class="[fields.popup.homephone.colClass]" v-if="fields.popup.homephone">
            <div class="form-group">
              <div class="phone-nu-wrapper">
                <label for="phone" class="form-label">{{ fields.popup.homephone.label }}<sup class="text-danger" v-if="fields.popup.homephone.required">*</sup><span class="help-text-font-size-11">{{ '('+ fields.popup.homephone.helpText +')' }}</span></label>
                <vue-tel-input :inputOptions="phoneOptions" @validate="validateTelinput" ref="phone" v-model="form.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.popup.address1.colClass]" v-if="fields.popup.address1">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.popup.address1.label }} <sup class="text-danger" v-if="fields.popup.address1.required">*</sup></label>
              <input type="text" class="form-control" maxlength="100" id="address1" v-model="form.address1" name="form.address1">
              <span class="text-sm text-danger">{{ errors.address1}}</span>
            </div>
          </div>
          <div :class="[fields.popup.address2.colClass]" v-if="fields.popup.address2">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.popup.address2.label }} <sup class="text-danger" v-if="fields.popup.address2.required">*</sup></label>
              <input type="text" class="form-control" maxlength="100" id="address2" v-model="form.address2" name="form.address1">
              <span class="text-sm text-danger">{{ errors.address2}}</span>
            </div>
          </div>
          <div :class="[fields.popup.city.colClass]" v-if="fields.popup.city">
            <div class="form-group">
              <label for="city" class="form-label">{{ fields.popup.city.label }} <sup class="text-danger" v-if="fields.popup.city.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="city"  v-model="form.city" name="form.city">
              <span class="text-sm text-danger">{{ errors.city}}</span>
            </div>
          </div>
          <div :class="[fields.popup.country.colClass]" v-if="fields.popup.country">
            <div class="form-group">
              <label for="Country" class="form-label">{{ fields.popup.country.label }} <sup class="text-danger" v-if="fields.popup.country.required">*</sup></label>
              <select class="form-control" id="country" @change="removeStateCode" v-model="form.country" name="form.country">
                <option value="">Select</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in fields.popup.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.country}}</span>
            </div>
          </div>
          <div :class="[fields.popup.state_code.colClass]" v-if="form.country == 'USA'&& fields.popup.state_code">
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.popup.state_code.label }} <sup class="text-danger" v-if="fields.popup.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state_code" @change="stateCode" name="form.state_code">
                <option value="">Select</option>
                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.state_code}}</span>
            </div>
          </div>
          <div :class="[fields.popup.state_code.colClass]" v-else>
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.popup.state_code.label }} <sup class="text-danger" v-if="fields.popup.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state_code" @change="stateCode" name="form.state_code">
                <option value="">Select</option>
                <option  v-for="listOfState in fields.popup.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.state_code}}</span>
            </div>
          </div>
          <div :class="[fields.popup.zip.colClass]" v-if="fields.popup.zip">
            <div class="form-group">
              <label for="zip" class="form-label">{{ fields.popup.zip.label }} <sup class="text-danger" v-if="fields.popup.zip.required">*</sup></label>
              <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')"  id="zip" v-model="form.zip" name="form.zip">
              <span class="text-sm text-danger">{{ errors.zip}}</span>
            </div>
          </div>
          <div :class="[fields.popup.agl_player_id.colClass]" v-if="fields.popup.agl_player_id">
            <div class="form-group">
              <label for="agl_player_id" class="form-label">
                {{ fields.popup.agl_player_id.label }}<sup class="text-danger" v-if="fields.popup.agl_player_id.required">*</sup>
              </label>
              <input type="text" class="form-control" :placeholder="fields.popup.agl_player_id.placeholder" maxlength="45" id="agl_player_id" v-model="form.agl_player_id" name="form.agl_player_id" value="">
              <span class="text-sm text-danger">{{ errors.agl_player_id}}</span>
            </div>
          </div>
          <div :class="[fields.popup.graduation_year.colClass]" v-if="fields.popup.graduation_year">
            <div class="form-group">
              <label for="graduation_year" class="form-label">
                {{ fields.popup.graduation_year.label }}<sup class="text-danger" v-if="fields.popup.graduation_year.required">*</sup>
              </label>
              <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="form.graduation_year">
                <option value="">Select Graduation Year</option>
                <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.graduation_year}}</span>
            </div>
          </div>
          <div :class="[fields.popup.jersey_number.colClass]" v-if="fields.popup.jersey_number">
            <div class="form-group">
              <label for="jersey_number" class="form-label">
                {{ fields.popup.jersey_number.label }}<sup class="text-danger" v-if="fields.popup.jersey_number.required">*</sup>
              </label>
              <input class="form-control" :placeholder="fields.popup.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="form.jersey_number" name="form.jersey_number" value="">
              <span class="text-sm text-danger">{{ errors.jersey_number}}</span>
            </div>
          </div>
          <div :class="[fields.popup.primary_position.colClass]" v-if="fields.popup.primary_position">
            <div class="form-group">
              <label for="primary_position" class="form-label">{{ fields.popup.primary_position.label }} <sup class="text-danger" v-if="fields.popup.primary_position.required">*</sup></label>
              <select class="form-control" id="primary_position" v-model="form.primary_position" name="form.primary_position">
                <option value="">Select</option>
                <option  v-for="(listOfPrimaryPosition,key) in fields.popup.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.primary_position}}</span>
            </div>
          </div>
          <div :class="[fields.popup.secondary_position.colClass]" v-if="fields.popup.secondary_position">
            <div class="form-group">
              <label for="secondary_position" class="form-label">{{ fields.popup.secondary_position.label }} <sup class="text-danger" v-if="fields.popup.secondary_position.required">*</sup></label>
              <select class="form-control" id="secondary_position" v-model="form.secondary_position" name="form.secondary_position">
                <option value="">Select</option>
                <option  v-for="(listOfSecondaryPosition,key) in fields.popup.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.secondary_position}}</span>
            </div>
          </div>
          <div :class="[fields.popup.throw.colClass]">
            <div class="form-group">
              <label for="throw" class="form-label">{{ fields.popup.throw.label }} <sup class="text-danger" v-if="fields.popup.throw.required">*</sup></label>
              <div class="form-group" v-if="fields.popup.throw.choices">
                <template v-for="(choice, key) in fields.popup.throw.choices">
                  <input type="radio" :id="choice.value" v-model="form.throws" name="form.throws" :value="choice.value" :key="'radio_' + key">
                  <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                </template>
                <span class="text-sm text-danger">{{ errors.throw}}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.popup.bats.colClass]">
            <div class="form-group">
              <label for="bats" class="form-label">{{ fields.popup.bats.label }} <sup class="text-danger" v-if="fields.popup.bats.required">*</sup></label>
              <div class="form-group" v-if="fields.popup.bats.choices">
                <template v-for="(choice, key) in fields.popup.bats.choices">
                  <input type="radio" :id="choice.value" v-model="form.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                  <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                </template>
                <span class="text-sm text-danger">{{ errors.bats}}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div :class="[fields.popup.college_committed.colClass]">
              <div class="form-group">
                <label for="college_committed" class="form-label">{{ fields.popup.college_committed.label }} <sup class="text-danger" v-if="fields.popup.college_committed.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.college_committed.choices">
                  <template v-for="(choice, key) in fields.popup.college_committed.choices">
                    <input type="radio" :id="choice.value" v-model="form.college_list" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                    <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.college_committed}}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.popup.college_committed.yes_fields.college_list.colClass]" v-if="fields.popup.college_committed.yes_fields.college_list && collegeCommitted == 1">
              <div class="form-group">
                <label for="college_list" class="form-label" v-if="fields.popup.college_committed.yes_fields.college_list.label !=''">{{ fields.popup.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="fields.popup.college_committed.yes_fields.college_list.required">*</sup></label>
                <Select2 id="college_list" v-model="form.college_committed" :options="college_commited_list" name="form.college_list" :settings="{ placeholder: fields.popup.college_committed.yes_fields.college_list.placeholder}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
    <b-modal id="modal-invite" ref="modal-invitePlayer" size="lg" ok-title="" hide-footer centered title="Player Details">
    <div class="form-body">
      <form>
        <template>
          <div v-if="fields.popup.invitation_email">
            <label for="invite_user">{{fields.popup.invitation_email.label}}<sup class="text-danger" v-if="fields.popup.invitation_email.required">*</sup></label>
            <br><em for="invite_user">{{ fields.popup.invitation_email.helpText }}</em>
            <b-form-tags
                input-id="invite_user"
                type="email"
                v-model="inviteForm.invite_players"
                placeholder=" "
                :tag-validator="emailValidator"
                tagClass="invite_tag"
                separator=" ,"
                @tag-state="onTagState"
            ></b-form-tags>
          </div>
        </template>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm btn-alfa" @click="playerDetails">
        Send
      </button>
    </div>
  </b-modal>
    
    
<!--    <div class="row">

    </div>-->
    <!-- <div class="row" v-if="members.length > 1">
        <div class="col-md-12">
            <h3 class="mb-3">{{ fields.popup.title || 'Family Member Details'}} </h3>
        </div>
    </div> -->
  <div v-if="inviteForm.invite_players && inviteForm.invite_players.length > 0">
    <h3 class="card-title">Invited Members</h3>
    <template v-for="(player, index) in inviteForm.invite_players">
      <b-card no-body class="mb-1 clear-fix" :key="'propType'+index">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <h3 class="card-title">{{ index+1 }}. {{ player }}</h3>
          <div class="d-flex float-right">
            <button type="button" class="btn-remove ml-1 right-size" @click="removeInvite(player)"><img v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon">Remove</button>
          </div>
        </b-card-header>
      </b-card>
    </template>
    <h3 class="card-title" v-if="members && members.length > 1">Players</h3>
  </div>
    <template v-for="(member, index) in members">
          <b-card no-body class="mb-1 players-toggle clear-both" :key="'propType'+index" v-if="index != 0">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'mType-' + index" variant="info">
             {{ index }}. {{ member.first_name+' '+member.last_name }}{{(!player_informations)?'('+ relations[member.relation]+')':'('+ teamRelations[member.relation]+')'}}
            </b-button>
             <div class="d-flex float-right">
                <button type="button" class="btn-edit mr-1" @click="editFamily(index)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</button>
                <button type="button" class="btn-remove ml-1 " @click="removeFamily(index)"><img v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon">Remove</button>
            </div>
        </b-card-header>
        <b-collapse :id="'mType-' + index" accordion="mtype-accordion" role="tabpanel" :visible="openCollapse == index">
          <b-card-body>
            <b-card-text>
              <div class="row">
 <div class="col-lg-6" v-if="fields.popup.middleInitial">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.middleInitial.label || 'Middle Initial'}}</strong></label>
            <span class="title">{{member.middle_name || 'N/A'}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.birthDay">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.birthDay.label || 'Birthday'}}</strong></label>
            <span class="title">{{member.dob}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.gender">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.gender.label || 'Gender'}}</strong></label>
            <span class="title">{{member.gender}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.email">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.email.label || 'E-Mail'}}</strong></label>
            <span class="title">{{member.email}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.homephone">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.homephone.label || 'Home Phone'}}</strong></label>
            <span class="title">{{member.phone_1}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.mobile">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.mobile.label || 'Mobile Phone'}}</strong></label>
            <span class="title">{{member.phone_2 || 'N/A'}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.sport_discipline && fields.popup.sport_discipline.first_choice && member.displines_names">
            <div class="form-group">
                <label class="form-label"><strong>{{ fields.popup.sport_discipline.first_choice.label || 'Disciplines'}}</strong></label>
                <template v-if="member.displines_names.length > 1">
                    <ul class="pl-3" v-if="member.displines_names.length">
                        <li v-for="(item, k) in member.displines_names" :key="'dn-'+k"><span class="title">{{ item }}</span></li>
                    </ul>
                </template>
                <span class="title" v-else>{{ member.displines_names | joinArray }}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.sport_discipline && fields.popup.sport_discipline.second_choice && member.secondChoiceNames">
            <div class="form-group">
                <label class="form-label"><strong>{{ fields.popup.sport_discipline.second_choice.label || '2nd Choice'}}</strong></label>
                <template v-if="member.secondChoiceNames.length > 1">
                    <ul class="pl-3" v-if="member.secondChoiceNames.length">
                        <li v-for="(item, k) in member.secondChoiceNames" :key="'dn-'+k"><span class="title">{{ item }}</span></li>
                    </ul>
                </template>
                <span class="title" v-else>{{ member.secondChoiceNames | joinArray }}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.race && member.diversity_id">
            <div class="form-group">
                <label class="form-label">
                    <strong>{{ fields.popup.race.label || 'Race'}}</strong>
                </label>
                <span class="title">{{ member.diversity_txt || 'N/A' }}</span>
            </div>
        </div>
      <div class="col-lg-6" v-if="fields.popup.agl_player_id && member.agl_player_id">
        <div class="form-group">
          <label class="form-label">
            <strong>{{ fields.popup.agl_player_id.label || 'AGL Player ID'}}</strong>
          </label>
          <span class="title">{{ member.agl_player_id || 'N/A' }}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.graduation_year && member.graduation_year">
        <div class="form-group">
          <label class="form-label">
            <strong>{{ fields.popup.graduation_year.label || 'Graduation Year'}}</strong>
          </label>
          <span class="title">{{ member.graduation_year || 'N/A' }}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.jersey_number && member.jersey_number">
        <div class="form-group">
          <label class="form-label">
            <strong>{{ fields.popup.jersey_number.label || 'Jersey Number'}}</strong>
          </label>
          <span class="title">{{ member.jersey_number || 'N/A' }}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.primary_position && member.primary_position">
        <div class="form-group">
          <label class="form-label"><strong>{{ fields.popup.primary_position.label || 'Primary Position'}}</strong></label>
          <span class="title">{{ member.primary_position }}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.secondary_position && member.secondary_position">
        <div class="form-group">
          <label class="form-label"><strong>{{ fields.popup.secondary_position.label || 'Secondary Position'}}</strong></label>
          <span class="title">{{ member.secondary_position }}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.throw">
        <div class="form-group">
          <label class="form-label"><strong>{{ fields.popup.throw.label || 'Throws'}}</strong></label>
          <span class="title">{{member.throws == 1 ? 'Left':'Right' }}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.bats">
        <div class="form-group">
          <label class="form-label"><strong>{{ fields.popup.bats.label || 'Bats'}}</strong></label>
          <span class="title">{{member.bats == 1 ? 'Left':'Right'}}</span>
        </div>
      </div>
      <div class="col-lg-6" v-if="fields.popup.college_committed">
        <div class="form-group">
          <label class="form-label"><strong>{{ fields.popup.college_committed.label || 'College Committed'}}</strong></label>
          <span class="title">{{member.college_name || 'NA'}}</span>
        </div>
      </div>
                 <div class="col-lg-6" v-if="fields.popup.address1">
                  <div class="form-group">
                    <label class="form-label"><strong>{{ fields.popup.address1.label || 'Mailing Address'}}</strong></label>
                    <span class="title">{{member.address1 || 'NA'}}</span>
                  </div>
                </div>
                <div class="col-lg-6" v-if="fields.popup.address2">
                  <div class="form-group">
                    <label class="form-label"><strong>{{ fields.popup.address2.label || 'Address 2'}}</strong></label>
                    <span class="title">{{member.address2 || 'NA'}}</span>
                  </div>
                </div>
                <div class="col-lg-6" v-if="fields.popup.city">
                  <div class="form-group">
                    <label class="form-label"><strong>{{ fields.popup.city.label || 'City'}}</strong></label>
                    <span class="title">{{member.city || 'NA'}}</span>
                  </div>
                </div>
                <div class="col-lg-6" v-if="fields.popup.country">
                  <div class="form-group">
                    <label class="form-label"><strong>{{ fields.popup.country.label || 'Country'}}</strong></label>
                    <span class="title">{{member.country || 'NA'}}</span>
                  </div>
                </div>
                <div class="col-lg-6" v-if="fields.popup.state_code">
                  <div class="form-group">
                    <label class="form-label"><strong>{{ fields.popup.state_code.label || 'State'}}</strong></label>
                    <span class="title">{{member.state_code || 'NA'}}</span>
                  </div>
                </div>
                <div class="col-lg-6" v-if="fields.popup.zip">
                  <div class="form-group">
                    <label class="form-label"><strong>{{ fields.popup.zip.label || 'Postal Code'}}</strong></label>
                    <span class="title">{{member.zip || 'NA'}}</span>
                  </div>
                </div>
      </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

    </template>
  <span class="text-sm text-danger">{{ errors.age_division_check }}</span>
    <div class="row">
        <div class="col-md-12 text-right topspace ">
            <div class="d-flex float-right ">
                <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn">Previous</button>
                <button type="button" @click="ClubContactCheck" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next</button>
            </div>
        </div>
    </div>
</div>
</div>
</template>


<script>
import { VueTelInput } from 'vue-tel-input';
// import {TheMask} from 'vue-the-mask'
import DatePick from 'vue-date-pick';
import Select2 from "v-select2-component";
import * as $ from 'jquery';
import axios from "axios";

$('#college_list').select2({
  dropdownParent: $('#modal-center4')
});

const validateName = (name, fieldTxt) => {
  console.log('name lenghth='+name.length);
  if (!name.length) {
    return { valid: false, error: fieldTxt + " is required" };
  }
  if (fieldTxt != 'Address' && !name.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldTxt+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (!name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  if (!name.match(/^[a-zA-Z-\s]*$/))
  {

    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, fieldTxt ) => {

    if (!phone.length) {
    return { valid: false, error: fieldTxt + ' is required.' };
}

if(validTelinput == false && phone.length){
  return { valid: false, error: 'Enter the Exact phone number(Example: +XX XX XXXX XXXX).' };
}
    return { valid: true, error: null };
};
const validateSelect = (select, fieldTxt) => {
    console.log('seclec lenghth='+select.length);
    if (select=='') {       
        return { valid: false, error: fieldTxt + " is required" };
    }
    return { valid: true, error: null };
};
const validateRadio = (radio, fieldTxt,isCheck=true) => {
    console.log('radio lenghth='+radio.length);
    if (!radio.length && isCheck) {
        return { valid: false, error: fieldTxt + " is required" };
    }
    return { valid: true, error: null };
};
const validateEmail = (email, fieldTxt) => {
    if (!email.length) {
        return { valid: false, error: fieldTxt + " is required" };
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      console.log("email valid format");
        return { valid: false, error: "Please enter a valid email." };
    }
    return { valid: true, error: null };
};

const validateJerseyNumber = number => {
  if(!number){
    return {valid: false,error:"This field is required"};
  }
  return {valid: true, error: null};
};
const validateGraduationYear = number => {
  if(!number){
    return {valid: false,error:"This field is required"};
  }
  return {valid: true, error: null};
};
const validateFedration = (name, fieldTxt) => {
  if (!name) {
    return {valid: false, error: fieldTxt + " is required"};
  } else if (isNaN(name.trim())) {
    return {valid: false, error: fieldTxt + " must be a numeric value"};
  }
  //  else if (name.length != 9) {
  //   return {valid: false, error: "Foreign federation ID must be 9 characters"};
  // }
  return {valid: true, error: null};
};

const validateDob = (dob, fieldDob = '',isPlayer,age_divisionArr,age_division_id,age,membershipType) => {
    let fieldDobText = (fieldDob != '' ? fieldDob : "This field");
    if (!dob.length) {
        return { valid: false, error: fieldDobText+" is required" };
    }

    if(isPlayer && age_division_id !=0){
      var valObj = age_divisionArr.filter(function(elem){
        if(elem.id == age_division_id) return elem.value;
      });
      if(valObj.length > 0) {
        age = Math.floor(age);
        const division = valObj[0].validAge;

        if(age > division){
          return { valid: false, error: fieldDobText+" must be under the age of "+division };
        }
        
        if(!Object.is(Math.abs(age), +age)){
          return { valid: false, error: "Invalid Date" };
        }

      }
    }
    /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please, enter a valid data of birth." };
    }*/
    return { valid: true, error: null };
};

const validateZip = (zipCode, country) => {
  if(!zipCode.length)
  {
       return { valid: false, error: "Zip code is required" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
    return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
  }
  else if(zipCode.length < 5 ){       
    return { valid: false, error: "Zip code should be 5 character" };
  }
  return { valid: true, error: null };
};
const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};
const validateOptionalTextFields = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error: fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validatePhone2 = (phone, validTelinput2, fieldTxt ) => {
  if(phone!=undefined && validTelinput2 == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number.(Example: +XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
};
export default {
    name: "familymembers",
    components: {VueTelInput, DatePick, Select2},
    props: ['disciplines','members','currentRegContacts', 'fields', 'groups', 'diversities', 'player_informations', 'age_division', 'age_divisionArr', 'membersList', 'college_commited_list','updatedVerifiedDetails', 'isValidateMemberType','membershipType','currentSeason'],

    data() {
        return {
            validTelinput:false,
            phoneOptions: { placeholder: "Phone Number" },
            startDate: '2015-01-01',
            showModal: false,

            phone: "0123456789",
            validTelinput2:false,
            homeProps: {
              mode: "international",
              defaultCountry: "US",
              inputOptions:{
                autocomplete:"on",
                autofocus:false,
                id:"",
                maxlength:20,
                name:"telephone",
                placeholder:"Home Number",
                readonly:false,
                required:false,
                showDialCode:false,
                styleClasses:"",
                tabindex:0,
                type:"tel"
              }
            },
            mobileProps: {
              mode: "international",
              defaultCountry: "US",
              inputOptions:{
                autocomplete:"on",
                autofocus:false,
                id:"",
                maxlength:20,
                name:"telephone",
                placeholder:"Mobile Number",
                readonly:false,
                required:false,
                showDialCode:false,
                styleClasses:"",
                tabindex:0,
                type:"tel"
              }
            },
            form: {
                first_name: '',
                middle_name: '',
                last_name: '',
                email: '',
                gender: '',
                dob: '',
                relation: '0',
                citizenship: '',
                clubandteam: [],
                discipline_1: '',
                discipline_2: '',
                index: 1,
                phone_1: '',
                phone_2: '',
                displines_names: [],
                secondChoiceNames: [],
                home_federation: 'USA',
                skier_license: '',
                diversity_id: '',
                diversity_txt: '',
                ethnicity: '',
                disability: '',
                veteran_status: '',
                address1: '',
                address2: '',
                zip: '',
                state_code: '',
                state: '',
                city: '',
                country: '',
                league_age: '',
                agl_player_id: '',
                jersey_number: '',
                primary_position: '',
                secondary_position: '',
                graduation_year: '',
                bats: '',
                throws: '',
                retype_email:'',
                college_list:'',
                invite:false,
                college_committed:'',
                college_name:'',
                college_nameArray:''
            },
            inviteForm:{
              invite:true,
              invite_players:''
            },
            familyMembers: [],
            yearArr: [],
            valid: false,
            success: false,
            errors: {},
            message: null,
            spouseAdded: {},
            relations: {
                1: 'Spouse',
                2: 'Child'
            },
            teamRelations: {
              1: 'Player'
            },
            disciplines_list: [],
            action: '',
            collegeCommitted: null,
            clubOptions:[],
            overAllError:0,
            duplicateError:{
              hasError:true,
              isError:true,
              errorMessage:''
            },
            duplicateCount:1,
            checkingDuplicate:false
        }
    },

    methods: {
        familyMembersmodal() {
            //this.$refs['my-modal'].show();
          this.form.index = this.members.length;
          if(this.fields.isVisible == undefined) {

            this.form.email = this.currentRegContacts.email;
            this.form.phone_1 = this.currentRegContacts.phone_1;
            this.form.phone_2 = this.currentRegContacts.phone_2;
            this.form.discipline_1 = this.currentRegContacts.discipline_1;
            this.form.discipline_2 = this.currentRegContacts.discipline_2;
            this.form.address1 = this.currentRegContacts.address1;
            this.form.address2 = this.currentRegContacts.address2 !== undefined ? this.currentRegContacts.address2 : "";
            this.form.state_code = this.currentRegContacts.state_code;
            this.form.state = this.currentRegContacts.state;
            this.form.city = this.currentRegContacts.city;
            this.form.zip = this.currentRegContacts.zip;
            this.form.country = this.currentRegContacts.country;

            this.collectDisciplines();
            this.collectSecondChoice();
          }
          this.errors = {};
          this.action = 1;
        },
        async saveMember(bvModalEvt) {
          console.log("index of player",this.members.length);
            bvModalEvt.preventDefault();
          const validate = await this.validate();
          if (!validate) {
            return;
          }


            // alert(this.form.relation != this.members[this.form.index].relation);
            if(
                (this.action == 1 && this.form.relation == 1) || 
                (this.action == 2 && this.form.relation == 1 && this.form.relation != this.members[this.form.index].relation)
            ) {
                this.spouseAdded.valid = true;
            }

            if(this.action == 2 && this.form.relation != 1 && this.members[this.form.index].relation == 1){
                this.spouseAdded.valid = false;
            }
            if(this.form.jersey_number != undefined && this.form.jersey_number != '') {
              this.form.relation = 1;
              this.form.college_nameArray = this.college_commited_list.filter(college => {
                if(college.id == this.form.college_committed){
                  return college.text;
                }
              });
              if(this.form.college_nameArray[0] != undefined) {
                this.form.college_name = this.form.college_nameArray[0].text;
              }
            }
            if(this.valid) {
              this.$emit('family-data', this.form);
              this.form.indexs = this.members.length;
              this.errors = {};

              if (this.form.jersey_number != undefined && this.form.jersey_number != '') {
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-center4')
                });
                this.duplicateError.hasError = true;
                this.duplicateError.errorMessage = '';
              } else {
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-center-family3')
                });
              }
            }
        },
      async checkTeamDuplicate()
      {
        let checkData = this.form;

        console.log("Data View of Check duplicate",checkData);
        this.checkingDuplicate = true;
       await axios.post(this.basePath + "api/checkDuplicate/Teammember", checkData)
            .then((response) => {
              let count = response.data.data.count;

              if(count > 0){
                this.duplicateError.hasError = true;
                this.valid = false;
                this.duplicateError.errorMessage = 'Looks like this person is already registered for current year. For more information please contact member service.';
              }else{
                this.duplicateError.hasError = false;
                this.valid = true;
                this.duplicateError.isError = false;
                this.duplicateError.errorMessage ='';
              }
              this.checkingDuplicate = false;
              document.getElementById('app').scrollIntoView();
            })
            .catch(error=>{
              console.log("error geting clubs list")
              this.checkingDuplicate = false;
            })
        return this.valid;
      },
        resetModal() {
            this.form = {
                first_name: '',
                middle_name: '',
                last_name: '',
                email: '',
                gender: '',
                dob: '',
                relation: '',
                citizenship: '',
                clubandteam: [],
                discipline_1: '',
                discipline_2: '',
                home_federation: 'USA',
                skier_license: '',
                diversity_id: '',
                ethnicity: '',
                disability: '',
                veteran_status: '',
                address1: '',
                address2: '',
                zip: '',
                state_code: '',
                state: '',
                city: '',
                country: '',
                agl_player_id: '',
                jersey_number: '',
                primary_position: '',
                secondary_position: '',
                graduation_year: '',
                bats: '',
                throws: '',
                retype_email:'',
                college_list: ''
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        removeFamily(i) {
            if(this.members[i].relation == 1) {
                this.spouseAdded.valid = false;
            }
            this.$emit('family-remove-data', i);
        },
        removeInvite(email){
          this.inviteForm.invite_players = this.inviteForm.invite_players.filter(player => player != email);
        },
        editFamily(i) {
            console.log('update famildy' + i);
            this.errors = {};
            this.form.index = i;
            this.form = {
                first_name: this.members[i].first_name,
                middle_name: this.members[i].middle_name,
                last_name: this.members[i].last_name,
                email: this.members[i].email,
                gender: this.members[i].gender,
                dob: this.members[i].dob,
                relation: this.members[i].relation,
                citizenship: '',
                clubandteam: this.members[i].clubandteam,
                discipline_1: this.members[i].discipline_1,
                discipline_2: this.members[i].discipline_2,
                phone_1: this.members[i].phone_1,
                phone_2: this.members[i].phone_2,
                index: this.form.index,
                home_federation: this.members[i].home_federation,
                skier_license: this.members[i].skier_license,
                diversity_id: this.members[i].diversity_id,
                diversity_txt: this.members[i].diversity_txt,
                ethnicity: this.members[i].ethnicity,
                disability: this.members[i].disability,
                veteran_status: this.members[i].veteran_status,
                address1: this.members[i].address1,
                address2: this.members[i].address2,
                zip: this.members[i].zip,
                state_code: this.members[i].state_code,
                state: this.members[i].state,
                city: this.members[i].city,
                country: this.members[i].country,
                agl_player_id: this.members[i].agl_player_id,
                jersey_number: this.members[i].jersey_number,
                primary_position: this.members[i].primary_position,
                secondary_position: this.members[i].secondary_position,
                graduation_year: this.members[i].graduation_year,
                bats: this.members[i].bats,
                throws: this.members[i].throws,
                retype_email: this.members[i].retype_email,
                college_committed: this.members[i].college_committed
            }
            if(this.members[i].college_committed != ''){
              this.collegeCommitted = 1;
            }
            console.log(this.form);
          if(this.members[i].jersey_number != undefined && this.members[i].jersey_number != ''){
              this.$bvModal.show('modal-center4')
            }else{
              this.$bvModal.show('modal-center-family3')
            }

            this.action = 2;
            this.collectDisciplines();
            this.collectSecondChoice();
        },
        ClubContactCheck: function () {
            this.errors = {}
            console.log('this contact check');
            console.log(this.valid);
            console.log("lengthvc" + this.members.length);
            if (this.members.length > 1) {
              if(!this.player_informations) {
                console.log("length" + this.members.length);
                this.errors.primary = '';
                this.$emit('next-step');
                return;
              }else{
                let isTarget = true;
                this.members.forEach((value, index) => {
                if(index != 0 && this.age_division !=0) {
                  var age = this.isUnderAgeDivision(value.dob);
                  var age_division = this.age_division;
                  var valObj = this.age_divisionArr.filter(function (elem) {
                    if (elem.id == age_division) return elem.value;
                  });
                  if (valObj.length > 0) {
                    age = Math.floor(age);
                    const division = valObj[0].validAge;
                    if (age > division) {
                      this.errors.age_division_check = "Players must be under the age of "+division;
                      isTarget = false;
                    }
                  }
                }
                });
                if (this.inviteForm.invite_players.length < 5) {
                    this.errors.age_division_check = "Minimum of five invite emails is required";
                    isTarget = false;
                }
                if(isTarget){
                  this.errors.primary = '';
                  this.errors.age_division_check = '';
                  this.$emit('next-step');
                  return;
                }
              }
            } else {
                console.log("2" + this.valid);
                if(!this.player_informations) {
                  this.errors.primary = 'You must have at least one dependent to qualify for the Head of Household Membership Type.';
                }else{
                  if (this.inviteForm.invite_players.length < 5) {
                    this.errors.age_division_check = "Minimum of five invite emails is required";
                    return;
                  }
                  //this.errors.primary = this.fields.primary_error;
                  // to skip the validation to no player are added in page
                  this.errors.primary = '';
                  this.errors.age_division_check = '';
                  this.$emit('next-step');
                  return;
                }
            }
        },
        // Custom Validation Rules
      async validate() {
            if(this.player_informations){
              const isDuplicate = await this.checkTeamDuplicate();
              if (!isDuplicate) {
                return false;
              }
            }
            this.errors = {}
            this.valid = false;
            this.overAllError = 1;
            console.log('-------------');
            console.log(this.form);
            const ageDivision = this.isUnderAgeDivision(this.form.dob);
            var validMiddleName = {valid:true};

            const validFirstName = validateName(this.form.first_name, "First Name");
            this.errors.first_name = validFirstName.error;

            validMiddleName = validateOptionalTextFields(this.form.middle_name, "Middle name");
            this.errors.middle_name = validMiddleName.error;
            
            const validLastName = validateLastName(this.form.last_name, "Last Name");
            this.errors.last_name = validLastName.error;                       

            const validEmail = validateEmail(this.form.email, "Email");
            this.errors.email = validEmail.error;                        

            const validDob = validateDob(this.form.dob, "Dob",this.player_informations,this.age_divisionArr,this.age_division,ageDivision,this.membershipType);
            this.errors.dob = validDob.error;                       

            const validGender = validateRadio(this.form.gender, "Gender");
            this.errors.gender = validGender.error;                        

            const validDiscipline1 = validateSelect(this.form.discipline_1, "Sport Discipline");
            this.errors.discipline_1 = validDiscipline1.error;
                    

            const validRelation = validateRadio(this.form.relation, "Relationship");
            // console.log("test"+this.form.relation);
            this.errors.relation = validRelation.error;
                    
            // console.log("what"+this.errors.relation);
            console.log("Player Phone",this.form.phone_1);
            const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput, "Phone Number");
            this.errors.phone_1 = validPhone1.error;

            const validPhone2 = validatePhone2(this.form.phone_2, this.validTelinput2);
            this.errors.phone_2 = validPhone2.error;

            const validFederation = validateSelect(this.form.home_federation,"Federation");
            this.errors.home_federation = validFederation.error;

            const validSafeSportForeignFederationId = validateFedration(this.form.skier_license, "Federation ID");
            this.errors.skier_license = validSafeSportForeignFederationId.error;

            const validDiversity = validateRadio(this.form.diversity_id.toString(), "This Field");
            this.errors.diversity_id = validDiversity.error;

          const validEthnicity = validateRadio(this.form.ethnicity.toString(), "This Field",this.subdomain === 'waterski',);
          this.errors.ethnicity = validEthnicity.error;

          const validDisability = validateRadio(this.form.disability.toString(), "This Field",this.subdomain === 'waterski',);
          this.errors.disability = validDisability.error;

         const validVeteranStatus = validateRadio(this.form.veteran_status.toString(), "This Field",this.subdomain === 'waterski',);
         this.errors.veteran_status = validVeteranStatus.error;

            const validAddress1 = validateAddressName(this.form.address1,"Address");
            this.errors.address1 = validAddress1.error;

          //const validAddress2 = validateName(this.form.address2);
          // this.errors.address2 = validAddress2.error;

          const validCountry = validateSelect(this.form.country,"Country");
          this.errors.country = validCountry.error;
          

          const validStateCode = validateSelect(this.form.state_code,"State");
          const validState = validateName(this.form.state,"State");

          if(validCountry.valid && this.form.country != 'USA' && this.subdomain !='alliancefastpitch') {
            this.errors.state = validState.error;
          } else {
            this.errors.state_code = validStateCode.error;
          }
          const validCity = validateName(this.form.city,"City");
          this.errors.city = validCity.error;
          

          // validZip = validateName(this.form.zip,"Postal Code");
          // this.errors.zip = validZip.error;


          const validZip = validateZip(this.form.zip,this.form.country);
          this.errors.zip = validZip.error;
          
          const validPrimaryPosition = validateSelect(this.form.primary_position,"Primary");
          this.errors.primary_position = validPrimaryPosition.error;

          const validThrow = validateRadio(this.form.throws.toString(),'Throws');
          this.errors.throw = validThrow.error;

          const validBats = validateRadio(this.form.bats.toString(),'Bats');
          this.errors.bats = validBats.error;

          const validRetypeEmail = validateEmail(this.form.retype_email,"Email");
          this.errors.retype_email = validRetypeEmail.error;

          const validJerseyNumber = validateJerseyNumber(this.form.jersey_number);
          this.errors.jersey_number = validJerseyNumber.error;

          const validGraduationYear = validateGraduationYear(this.form.graduation_year);
          this.errors.graduation_year = validGraduationYear.error;

          /*const validInviteEmail = validateInviteEmail(this.form.invite_players);
          this.errors.email = validInviteEmail.error;*/


          if(this.action == 1 && this.spouseAdded.valid == true && this.form.relation == 1) {
                this.errors.relation = 'You can add only one spouse';
                this.spouseAdded.current = false;
                validRelation.valid = false;
            } else if(this.action == 2 && this.form.relation == 1 && this.members[this.form.index].relation != this.form.relation == 1){
                let spouseFound = this.members.filter(item => item.relation == 1);
                if(spouseFound.length){
                    this.errors.relation = 'You can add only one spouse';
                    this.spouseAdded.current = false;
                    validRelation.valid = false;
                }
            } else {
                this.spouseAdded.current = true;
            }

            if(this.form.relation == 0){
                this.errors.relation = 'Relationship is required.';                
                validRelation.valid = false;    
            }

            let dob = this.getAge(this.form.dob);
             console.log('dob_age='+dob);
            if(!this.player_informations) {
              var range = this.fields.popup.birthDay.min_range || 18;
              var childRange = this.fields.popup.birthDay.child_age || 18;
              // console.log('range'+range);
              if (this.form.relation == 1 && dob < range) {
                // console.log('dob1_age='+dob);
                this.errors.dob = 'Spouse age should be ' + range + ' and above';
                validDob.valid = false;

              } else if (this.form.relation == 2 && dob >= childRange) {
                // console.log('dob1_age='+dob);
                this.errors.dob = 'Child age should be less than ' + childRange;
                validDob.valid = false;
              } else if (this.form.relation == 2 && !Object.is(Math.abs(dob), +dob)) {
                // console.log('dob1_age='+dob);
                this.errors.dob = 'Invalid Date';
                validDob.valid = false;
              }
            }

            if(!this.validateDate(this.form.dob)){
                this.errors.dob = 'Invalid Date';
                validDob.valid = false;
            }

            if (this.form.home_federation != 'USA' && !validSafeSportForeignFederationId.valid) {
                this.errors.skier_license = 'Federation ID is required';
            } else if (this.form.home_federation == 'USA') {
                this.form.skier_license = '';
            //   this.valid = true;
            } else {
            //   this.valid = true; 
            }


            if (validFirstName.valid && validLastName.valid && validEmail.valid && validDob.valid && validGender.valid
                && validDiscipline1.valid && validRelation.valid
                && validPhone1.valid && validPhone2.valid  && this.spouseAdded.current && (this.form.home_federation != 'USA' ? validSafeSportForeignFederationId.valid:true) && validFederation.valid && validDiversity.valid && validAddress1.valid  && validCountry.valid && validCity.valid && validZip.valid && validMiddleName.valid && (this.subdomain === 'waterski' ? validEthnicity.valid : true) &&
                (this.subdomain === 'waterski' ? validDisability.valid : true) &&
                (this.subdomain != 'waterski' || (this.form.relation != 1 || validVeteranStatus.valid))  ||
                (validEmail.valid && validRetypeEmail.valid && validFirstName.valid && validLastName.valid  && validDob.valid  && validPrimaryPosition.valid
                    && validThrow.valid && validBats.valid
                    && validJerseyNumber.valid && validGraduationYear.valid && validPhone1.valid && validPhone2.valid && validMiddleName.valid &&  validAddress1.valid && validCountry.valid && validCity.valid && validZip.valid && (validStateCode.valid || validState.valid))) {

                this.valid = true;
                 this.overAllError= 0;

              if(this.player_informations && this.form.email != this.form.retype_email) {
                this.errors.retype_email = 'The email id does not match';
                this.valid = false;
                this.overAllError = 1;
              }
            }

            // if(Object.keys(this.errors).length == 0) this.valid = false;
            // else this.valid = true;

            // var errorOnly = Object.keys(this.errors).filter(k => this.errors[k] != null);
            // console.log(errorOnly);
             console.log('true=22=+'+this.valid);
          if(!this.player_informations) {
            if (this.form.country == 'USA') {
              this.form.state = '';
              if (!validStateCode.valid) {
                this.valid = false;
                this.overAllError = 1;
              }
            } else if (!validState.valid) {
              this.form.state_code = '';
              this.valid = false;
              this.overAllError = 1;
            }
          }
          var count =0
          if(this.membersList.length > 1) {
            this.membersList.forEach(info => {
              console.log("form",info);
              if(info.address1 === '' && this.form.index != info.index) {
                if (info.first_name === this.form.first_name && info.last_name === this.form.last_name && info.dob === this.form.dob) {
                  count++;
                }
              }
            });
            console.log("form",count);
            if(count > 0){
              this.valid = false;
              this.errors.first_name = 'Looks like this person is already registered for current year. For more information please contact member service.';
              count = 0;
            }
          }
            return this.valid;
        },
        getAge(birth_date) {
            let birth_date_arr = birth_date.split('/');
            console.log(birth_date_arr);
            let today_date = new Date();
            let today_year = today_date.getFullYear();
            let today_month = today_date.getMonth();
            let today_day = today_date.getDate();
            let age = today_year - birth_date_arr[2];

            if ( today_month < (birth_date_arr[0] - 1))
            {
                age--;
            }
            if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1]))
            {
                age--;
            }
            return age;
        },
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },
        validateTelinput(obj){            
            this.validTelinput = obj.valid;
            if(obj.valid){
                this.errors.phone_1 = '';
            }
        },
        collectDisciplines(){
            this.form.displines_names = [];
            if(Array.isArray(this.form.discipline_1) && this.form.discipline_1.length){
                this.form.discipline_1.forEach(id => {
                    if(this.disciplines[id] != undefined) this.form.displines_names.push(this.disciplines[id]);
                });
            } else {
                if(this.form.discipline_1 != ''){
                    if(this.disciplines[this.form.discipline_1] != undefined)
                        this.form.displines_names.push(this.disciplines[this.form.discipline_1]);
                }
            }
        },
        collectSecondChoice(){
            this.form.secondChoiceNames = [];
            if(this.form.discipline_2 != ''){
                if(this.disciplines[this.form.discipline_2] != undefined)
                    this.form.secondChoiceNames.push(this.disciplines[this.form.discipline_2]);
            }
        },
        saveDiversityTxt(txt){
            this.form.diversity_txt = txt;
        },
        removeHohEmail(relation) {
          if(relation == 1) {
            this.form.email = '';
          }
        },
      removeStateCode(){
        this.form.state = '';
        this.form.state_code = '';
      },
        playerDetails(){
          console.log("Form",this.inviteForm);
          this.$emit('family-data', this.inviteForm);
          this.$nextTick(() => {
            this.$bvModal.hide('modal-invite')
          });
        },
        onTagState(valid, invalid, duplicate) {
          this.validTags = valid
          this.invalidTags = invalid
          this.duplicateTags = duplicate
        },
        emailValidator(email) {
          return email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) && email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
        },
        validateTelinput2(obj){
          this.validTelinput2 = obj.valid;
          if(obj.valid){
            this.errors.phone_2 = '';
          }
        },
        isUnderAgeDivision(birth_date,valid_age){
          var today = new Date(this.currentSeason.start_date);
          var birthDate = new Date(birth_date);
          let today_year = today.getFullYear();
          let birthDate_year = birthDate.getFullYear();
          var age = today.getFullYear() - birthDate.getFullYear();
          /*var m = today.getMonth() - birthDate.getMonth();
          var number = '';
          var validBDYear = today_year - valid_age;

          if (((validBDYear == birthDate_year) && m < 0 ) || (m === 0 && today.getDate() < birthDate.getDate()))
          {
            age--;
          }
          if(m > 0){
            number ='' + age + m;
            age =  Number(number) / 10;
          }*/
          return age;
      },
       messageFor(val){
         this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
      },
    },
    updated() {
        // this.disciplines_list = this.disciplines.m
        let discipline_codes = Object.keys(this.disciplines);
        if(discipline_codes.length && this.disciplines_list.length == 0){
            discipline_codes.forEach(code => {
                this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
            });
        }
    },
    filters: {
        joinArray(array){
            return array.join(', ');
        }
    },
    mounted() {
      this.form.index = this.members.length;
      this.getClubs();

      const year = new Date().getFullYear();
      const yrs = year+20;
      const twoDigitYear = yrs.toString().substr(-2);
      const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
      this.yearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
      if(this.isValidateMemberType){
        this.resetModal();
      }
    },
    computed: {
        first_choice_class: function(){
            return this.fields.popup && this.fields.popup.sport_discipline && this.fields.popup.sport_discipline.first_choice.multiple ? 'col-md-12' : 'col-md-6';
        }
    }
}
</script>

<style scoped>
.card.mb-1.clear-fix {
  clear: both;
  color: black;
  margin-left: 16px;
}
.family-members h3 {
  font-size: 20px;
}
.card.mb-1.clear-both {
  clear: both;
}
.right-size{
  margin-bottom: 0px !important;
}
</style>
