<template>
        <div>     
        <div class="">
        <router-view></router-view>
        </div>
        
        </div>
</template>

<script>

export default {
    name: "adminauthlayouts",
}
</script>