import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import HomeNew from "../components/HomeNew.vue";
import PageNotFound from "../components/404.vue";
import HelpAndSupport from "../components/HelpAndSupport.vue";
import AdminLogin from "../components/admin/AdminLogin.vue";
import SupportNewDesign from "../components/SupportNewDesign.vue";

import Forgot from "../components/ForogotPassword.vue";
import PasswordReset from "../components/ResetPassword.vue";
import MemberRegistration from "../components/memberregistration/MemberRegistration.vue";
import camporschool from "../components/memberregistration/CampOrSchool.vue";
import PrivacyPolicy from "../components/memberregistration/PrivacyPolicy.vue";
import TermsAndConditions from "../components/memberregistration/TermsAndConditions.vue";
import DigitalMembership from "../components/memberregistration/DigitalMembership";
import InviteLinkExpired from "../components/memberregistration/InviteLinkExpired";


import VueTheMask from "vue-the-mask";
import memberregistraionforgotpw from "../components/memberregistration/MemberRegistrationForgotPassword.vue";
import ClubRosterRatings from "../components/club/dashboard/ClubRosterRatings.vue";
import memberPasswordReset from "../components/passwordReset";
import FamilyProfile from "../components/Member/Dashboard/FamilyProfile.vue";
import MemberGroups from "../components/admin/MemberGroups.vue";
import AdminPasswordReset from "../components/admin/AdminPasswordReset";
import MemberLayout from "../components/Member/Dashboard/layouts/MemberLayout";
import MemberAuthLayout from "../components/Member/Dashboard/layouts/MemberAuthLayout";
import ClubLayout from "../components/club/dashboard/layouts/ClubLayout";
import ClubAuthLayout from "../components/club/dashboard/layouts/ClubAuthLayout";
import AdminCommonLayout from "../components/admin/AdminLayout/AdminCommonLayout";
import AdminAuthLayout from "../components/admin/AdminLayout/AdminAuthLayout";
import ClubRatingDetails from "../components/club/dashboard/UserRatingDetails";
import MemberRatingDetails from "../components/Member/Dashboard/pages/MemberRatingDetails";
import axios from "axios";
import Axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: function() {
      const host = window.location.host;
      if (host.toLowerCase().includes("alliance")) {
        return import("../components/landingpage/AFPLandingPage");
      } else {
        return import("../components/HomeNew") ;
      }
    },
    meta: {
      breadcrumb: "Home",
    },
  },
  { path: "*", component: PageNotFound },
  {
    path: "/home-new",
    name: "homenew",
    component: Home,
    meta: {
      breadcrumb: "",
    },
  },

  {
    path: "/help-and-support1",
    name: "HelpAndSupport",
    component: HelpAndSupport,
  },
  {
    path: "/help-and-support",
    name: "SupportNewDesign",
    component: SupportNewDesign,
  },
  { path: "/", component: AdminLogin },
  { path: "/login", component: AdminLogin },
  { path: "/forgot-password", component: Forgot },
  { path: "/api/forgot_password", component: PasswordReset },
  { path: "/memberregistration", component: MemberRegistration },
  { path: "/csregistrations", component: camporschool },
  { path: "/registration/forgotpassword", component: memberregistraionforgotpw },
  { path: "/invitelinkexpired", component: InviteLinkExpired },
  
  // { path: "/admin/user/user-roles", component: UserRole },
  { path: "/admin/user/member-groups", component: MemberGroups },
  { path: "/admin/admin-password-reset", component: AdminPasswordReset},
  { path: "/privacy-policy", component: PrivacyPolicy },
  // { path: "/member/family-profile", component:  },
  { path: "/password_reset/:token", component: memberPasswordReset },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path:"/digital-registration", component: DigitalMembership},
  {
    path: "/unsubscribe/:id",
    name: "unsubscribe",
    component: () =>
      import("../components/Member/Dashboard/settings/unsubscribe.vue"),
      props: true 
  },

  {
    path: "/",
    component: AdminAuthLayout,
    children: [
      {
        path: "login",
        name: "adminlogin",
        component: () => import("../components/admin/AdminLogin.vue"),
        meta: {
          breadcrumb: "Admin Login",        
        },
      },

    ]
  },

  {
    path: "/admin",
    component: AdminCommonLayout,
    children: [
      {
        path: "dashboard",
        name: "adminDashboard",
        component: () => import("../components/admin/Dashboard.vue"),
        meta: {
          title: "Admin Dashboard",
          breadcrumb: "Dashboard",        
        },
      },
      {
        path: "reports/member-registrations",
        name: "mmeberregistrationsreport",
        component: () => import("../components/admin/reports/PreparedReportRegistrations.vue"),
        meta: {
          title: "Member Registration Reports",
          breadcrumb: "Member Registration Reports",        
        },
      },
      {
        path: "reports/registration",
        name: "reportsregistration",
        component: () => import("../components/admin/ReportsRegistration.vue"),
        meta: {
          title: "Member Registration Reports",
          breadcrumb: "Member Registration Reports",
        },
      },
      {
        path: "club/reports",
        name: "clubreports",
        component: () => import("../components/admin/ClubReports.vue"),
        meta: {
          title:"Club Registration Reports",
          breadcrumb: "Club Registration Reports",        
        },
      },
      {
        path: "campski-registrations/reports",
        name: "campskiregistrationsreport",
        component: () => import("../components/admin/reports/PreparedReportCampSkiRegistrations.vue"),
        meta: {
          title: "Campski Registration Reports",
          breadcrumb: "Campski Registration Reports",        
        },
      },
      {
        path: "team/reports",
        name: "teamreports",
        component: () => import("../components/admin/ClubReports.vue"),
        meta: {
          title:"Team Registration Reports",
          breadcrumb: "Team Registration Reports",        
        },
      },
      {
        path: "reports/bgscreening",
        name: "bgscreeningreports",
        component: () => import("../components/admin/BgScreeningReports.vue"),
        meta: {
          title:"Background Screening Reports",
          breadcrumb: "Background Screening Reports",
        },
      },
      {
        path: "reports/mvr",
        name: "mvrreports",
        component: () => import("../components/admin/MvrReports.vue"),
        meta: {
          title:"MVR Reports",
          breadcrumb: "MVR Reports",
        },
      },
      {
        path: "registration/settings",
        name: "registrationsettings",
        component: () => import("../components/admin/RegistrationSetting.vue"),
        meta: {
          title:"Settings",
          breadcrumb: "Settings",
        },
      },
      {
        path: "recruiting-member/reports",
        name: "ncsamemberreports",
        component: () => import("../components/admin/NcsaReports.vue"),
        meta: {
          title:"Recruiting Player Registration Reports",
          breadcrumb: "Recruiting Player Registration Reports",
        },
      },
      {
        path: "recruiting-team/reports",
        name: "ncsateamreports",
        component: () => import("../components/admin/NcsaTeamReports.vue"),
        meta: {
          title:"Recruiting Team Registration Reports",
          breadcrumb: "Recruiting Team Registration Reports",
        },
      },
      {
        path: "report/summary-report",
        redirect: 'reports/summary',
      },
      {
        path: "reports/summary",
        name: "summaryreport",
        component: () => import("../components/admin/SummaryReport.vue"),
        meta: {
          title: "Summary Reports",
          breadcrumb: "Summary Reports",        
        },
      },
      {
        path: "report/summary-report-view/:viewType",
        name: "summaryreportview",
        component: () => import("../components/admin/reports/SummaryReportsView.vue"),
        meta: {
          title: "Summary Reports",
          breadcrumb: "Summary Reports",
        },
      },
      {
        path: "reports/donation",
        name: "DonationReport",
        component: () => import("../components/admin/reports/DonationReport"),
        meta: {
          title:"Donations Report",
          breadcrumb: "Dashboard / Reports / Donations",
        },
      },
      {
        path: "reports/summary/financials",
        name: "Financials Summary",
        component: () => import("../components/admin/reports/SummaryFinancial.vue"),
        meta: {
          breadcrumb: "Financials Summary",
        },
      },
      {
        path: "reports/summary/disciplines",
        name: "Membership Disciplines Summary",
        component: () => import("../components/admin/reports/SummaryMembershipReport.vue"),
        meta: {
          breadcrumb: "Memberships Summary",
        },
      },
      {
        path: "reports/summary/memberships",
        name: "Memberships Summary",
        component: () => import("../components/admin/reports/SummaryMembershipCountsReport.vue"),
        meta: {
          breadcrumb: "Memberships Summary",
        },
      },
      {
        path: "reports/financials",
        name: "Financials Membership",
        component: () => import("../components/admin/ReportMembershipFinancial.vue"),
        meta: {
          breadcrumb: "Financials Reports",
        },
      },
      {
        path: "financials-membership",
        name: "FinancialsMembership",
        component: () => import("../components/admin/ReportMembershipFinancial.vue"),
        meta: {
          breadcrumb: "Membership Financials Reports",
        },
      },
      {
        path: "financials-club",
        name: "FinancialsClub",
        component: () => import("../components/admin/ReportClubFinancial.vue"),
        meta: {
          breadcrumb: "Club Financials Reports",
        },
      },
      {
        path: "admin-user-role",
        name: "adminuserrole",
        component: () => import("../components/admin/AdminUserRole.vue"),
        meta: {
          title:"Admin User Role",
          breadcrumb: "Admin User Role",        
        },
      },

      
      {
        path: "reports/merchandise-accounting-report",
        name: "MerchandiseAccountingReport",
        component: () => import("../components/admin/reports/MerchandiseAccountingReport.vue"),
        meta: {
          title:"Merchandise Accounting Report",
          breadcrumb: "Dashboard / Reports / Merchandise Accounting Report",
        },
      },

      
      {
        path: "reports/merchandise-fulfillment-report",
        name: "MerchandiseFulfillmentReport",
        component: () => import("../components/admin/reports/MerchandiseFulfillmentReport.vue"),
        meta: {
          title:"Merchandise Fulfillment Report",
          breadcrumb: "Dashboard / Reports / Merchandise Fulfillment Report",
        },
      },


      {
        path: "registrations",
        name: "registrationsList",
        component: () => import("../components/admin/Registrations.vue"),
        meta: {
          title: "Individual Registrations List",
          breadcrumb: "Individual Registrations List",        
        },
      },
      {
        path: "family-registrations",
        name: "familyregistration",
        component: () => import("../components/admin/FamilyRegistrations.vue"),
        meta: {
          title: "Family Registrations List",
          breadcrumb: "Family Registrations List",        
        },
      },
      {
        path: "club-registrations",
        name: "clubregistrations",
        component: () => import("../components/admin/ClubRegistrations.vue"),
        meta: {
          title: "Club Registrations List",
          breadcrumb: "Club Registrations List",        
        },
      },
      {
        path: "fanclub-registrations",
        name: "fanclubregistrations",
        component: () => import("../components/admin/FanClubRegistrations.vue"),
        meta: {
          title: "Fan Club Registrations List",
          breadcrumb: "Fan Club Registrations List",        
        },
      },
      {
        path: "coach-registrations",
        name: "coachregistrations",
        component: () => import("../components/admin/CoachRegistrations.vue"),
        meta: {
          title: "Coach Registrations List",
          breadcrumb: "Coach Registrations List",
        },
      },
      {
        path: "fan-registrations",
        name: "fanregistrations",
        component: () => import("../components/admin/FanRegistrations.vue"),
        meta: {
          title: "Fan Registrations List",
          breadcrumb: "Fan Registrations List",
        },
      },
      {
        path: "ambassador-registrations",
        name: "ambassadorregistrations",
        component: () => import("../components/admin/AmbassadorRegistrations.vue"),
        meta: {
          title: "Ambassador Registrations List",
          breadcrumb: "Ambassador Registrations List",
        },
      },
      {
        path: "campski-registrations",
        name: "campski-registrations",
        component: () => import("../components/admin/CampSkiRegistrations"),
        meta: {
          title: "Camp/Ski School Registrations List",
          breadcrumb: "Camp/Ski School Registrations List",
        },
      },
      {
        path: "digital-registrations",
        name: "digital-registrations",
        component: () => import("../components/admin/DigitalRegistrations.vue"),
        meta: {
          title: "Digital Free Membership List",
          breadcrumb: "Digital Free Membership List",
        },
      },
      {
        path: "clubs",
        name: "clubs",
        component: () => import("../components/admin/Club.vue"),
        meta: {
          title: "Manage Clubs",
          breadcrumb: "Manage Clubs",        
        },
      },
      {
        path: "members",
        name: "members",
        component: () => import("../components/admin/Member.vue"),
        meta: {
          title: "Manage members",
          breadcrumb: "Manage members",        
        },
      },
      {
        path: "merchandise",
        name: "merchandise",
        component: () => import("../components/admin/Merchandise.vue"),
        meta: {
          title:"Manage Merchandise Products",
          breadcrumb: "Manage Merchandise Products",        
        },
      },
      {
        path: "coupons",
        name: "coupons",
        component: () => import("../components/admin/Coupons.vue"),
        meta: {
          title: "Manage Coupons",
          breadcrumb: "Manage Coupons",        
        },
      },
      {
        path: "member-type",
        name: "member-type",
        component: () => import("../components/admin/MemberType.vue"),
        meta: {
          breadcrumb: "Member Type",        
        },
      },
      {
        path: "membership-type",
        name: "membership-type",
        component: () => import("../components/admin/MembershipType.vue"),
        meta: {
          breadcrumb: "Member Type",
        },
      },
      {
        path: "membership-pricing",
        name: "membership-pricing",
        component: () => import("../components/admin/pricing/PricingList.vue"),
        meta: {
          title: "Membership Pricing",
          breadcrumb: "Membership Pricing",
        },
      },
      {
        path: "other-pricing",
        name: "other-pricing",
        component: () => import("../components/admin/pricing/OtherPricingList.vue"),
        meta: {
          title: "Membership Pricing",
          breadcrumb: "Membership Pricing",
        },
      },
      {
        path: "group-types",
        name: "groupTypes",
        component: () => import("../components/admin/CommitteeGroup.vue"),
        meta: {
          title: "Group Types List",
          breadcrumb: "Group Types List",
        },
      },
      {
        path: "groups",
        name: "groups",
        component: () => import("../components/admin/Committee.vue"),
        meta: {
          title: "Groups List",
          breadcrumb: "Groups List",
        },
      },
      {
        path: "groups-position",
        name: "committeePosition",
        component: () => import("../components/admin/CommitteePosition.vue"),
        meta: {
          title: "Groups Position List",
          breadcrumb: "Groups Position List",
        },
      },
      {
        path: "groups-member",
        name: "committeeMember",
        component: () => import("../components/admin/CommitteeMember.vue"),
        meta: {
          title: "Groups Member List",
          breadcrumb: "Groups Member List",
        },
      },
      {
        path: "seasons",
        name: "seasons",
        component: () => import("../components/admin/Season.vue"),
        meta: {
          title:"Manage Seasons",
          breadcrumb: "Manage Seasons",        
        },
      },
      {
        path: "admin-user-list",
        name: "adminuserlist",
        component: () => import("../components/admin/AdminUserList.vue"),
        meta: {
          title: "Admin User List",
          breadcrumb: "Admin User List",        
        },
      },
      {
        path: "create-faq",
        name: "CreateFaq",
        component: () => import("../components/admin/CreateFaq.vue"),
        meta: {
          title: "Create FAQ",
          breadcrumb: "Create FAQ",        
        },
      },
      {
        path: "faq-sections",
        name: "Faq Sections",
        component: () => import("../components/admin/FaqSections.vue"),
        meta: {
          title: "Faq Sections",
          breadcrumb: "Faq Sections",        
        },
      },
      {
        path: "faq-list",
        name: "Faq List",
        component: () => import("../components/admin/FaqManagementlist.vue"),
        meta: {
          title: "Faq List",
          breadcrumb: "Faq List",        
        },
      },
       {
        path: "add-faq",
        name: "Add FAQ",
        component: () => import("../components/admin/AddFaqmanagement.vue"),
        meta: {
          title: "Add FAQ",
          breadcrumb: "Add FAQ",
        },
      },
      {
        path: "edit-faq/:id",
        name: "Edit FAQ",
        component: () => import("../components/admin/AddFaqmanagement.vue"),
        meta: {
          title: "Edit FAQ",
          breadcrumb: "Edit FAQ",
        },
      },
      {
        path: "member-dashboard",
        name: "Member Dashboard",
        component: () => import("../components/admin/MemberDashboard.vue"),
        meta: {
          title: "Member Dashboard",
          breadcrumb: "Member Dashboard",        
        },
      },
      {
        path: "landingpage",
        name: "Member Landing Page",
        component: () => import("../components/admin/LandingPage.vue"),
        meta: {
          title: "Member Landing Page",
          breadcrumb: "Member Landing Page",        
        },
      },
      {
        path: "registrations/:regId",
        name: "userprofile",
        component: () => import("../components/admin/UserProfile.vue"),
        meta: {
          title: "User Profile",
          // requiresAdminAuth : true,
          breadcrumb: "User Profile",        
        },
      },
      {
        path: "family-registrations/:regId",
        name: "familyUserProfile",
        component: () => import("../components/admin/UserProfile.vue"),
        meta: {
          title: "User profile",
          requiresAdminAuth : true,
          breadcrumb: "User Profile",        
        },
      },
      {
        path: "family-member/:memId",
        name: "familyMemberProfile",
        component: () => import("../components/admin/familyMemberProfile.vue"),
        meta: {
          requiresAdminAuth : true,
          breadcrumb: "Family Member Profile",
        },
      },
      {
        path: "club-registrations/:regId",
        name: "ClubUserProfile",
        component: () => import("../components/admin/ClubUserProfile.vue"),
        meta: {
          title: "Club Profile",
          breadcrumb: "Club Profile",
        },
      },
      {
        path: "campski-registrations/:regId",
        name: "CampSkiUserProfile",
        component: () => import("../components/admin/CampSkiUserProfile.vue"),
        meta: {
          title: "Camp Ski Profile",
          breadcrumb: "Camp Ski Profile",
        },
      },
      {
        path: "fanclub-registrations/:regId",
        name: "fanClubUserProfile",
        component: () => import("../components/admin/UserProfile.vue"),
        meta: {
          title: "Fan Club Profile",
          requiresAdminAuth : true,
          breadcrumb: "Fan Club Profile",
        },
      },
      {
        path: "coach-registrations/:regId",
        name: "CoachUserProfile",
        component: () => import("../components/admin/UserProfile.vue"),
        meta: {
          title: "Coach Profile",
          requiresAdminAuth : true,
          breadcrumb: "Coach Profile",
        },
      },
      {
        path: "fan-registrations/:regId",
        name: "CoachUserProfile",
        component: () => import("../components/admin/UserProfile.vue"),
        meta: {
          title: "Fan Profile",
          requiresAdminAuth : true,
          breadcrumb: "Fan Profile",
        },
      },
      {
        path: "import_data",
        name: "import-data",
        component: () => import("../components/admin/import.vue"),
        meta: {
          title: "Import Data",
          breadcrumb: "Import Data",
        },
      },
      {
        path: "reports/club_insurance",
        name: "ClubInsuranceReport",
        component: () => import("../components/admin/reports/ClubInsurance"),
        meta: {
          title:"Club Insurance",
          breadcrumb: "Dashboard / Reports / Club Insurance",
        },
      },
      {
        path: "reports/insurance_certificate",
        name: "InsuranceCertificateReport",
        component: () => import("../components/admin/reports/InsuranceCertificate"),
        meta: {
          title:"Insurance Certificate",
          breadcrumb: "Dashboard / Reports / Insurance Certificate",
        },
      },
      {
        path: "search/:qs",
        name: "GlobalSearch",
        component: () => import("../components/admin/GlobalSearch.vue"),
        meta: {
          title: "Search",
          breadcrumb: "Search",
        },
      },
      {
        path: "imports/background-screening",
        name: "BackgroundScreeningImport",
        component: () => import("../components/admin/imports/BackgroundScreeningImport.vue"),
        meta: {
          title:"Background Screening Import",
          breadcrumb: "Imports / Background Screening Import",
        },
      },
      {
        path: "imports/safe-sport",
        name: "SafesportImport",
        component: () => import("../components/admin/imports/SafesportImport.vue"),
        meta: {
          title:"Safe Sport Import",
          breadcrumb: "Imports / Safe Sport Import",
        },
      },
      {
        path: "member/:member_id/score/:regId",
        name: "Evetns List",
        component: () => import("../components/admin/Score.vue"),
        meta: {
          title:"Member Score",
          breadcrumb: "Member Score",
        },
      },      
      {
        path: "group",
        name: "Group List",
        component: () => import("../components/admin/group/List.vue"),
        meta: {
          title:"Group",
          breadcrumb: "Group",
        },
      },
      {
        path: "group/add",
        name: "Add Group",
        component: () => import("../components/admin/group/Add.vue"),
        meta: {
          title:"Add Group",
          breadcrumb: "Add Group",
        },
      },
      {
        path: "mergeDelete",
        name: "mergeDelete",
        component: () => import("../components/admin/mergeDelete.vue"),
        meta: {
          title: "Member Consolidate",
          breadcrumb: "Member Consolidate",
        }
      },
      {
        path: "clubMergeDelete",
        name: "clubMergeDelete",
        component: () => import("../components/admin/clubMergeDelete.vue"),
        meta: {
          title: "Club Consolidate",
          breadcrumb: "Club Consolidate",
        }
      },
      {
        path: "group/add-members/:gid",
        name: "Add Members to Group",
        component: () => import("../components/admin/group/AddMembers.vue"),
        meta: {
          title:"Add Members to Group",
          breadcrumb: "Add Members to Group",
        },
      },
      {
        path: "group/edit/:gid",
        name: "Edit Group",
        component: () => import("../components/admin/group/Add.vue"),
        meta: {
          title:"Edit Group",
          breadcrumb: "Edit Group",
        },
      },
      {
        path: "member/travel-information/:memberId/add",
        name: "admin-travel-informatio-add",
        component: () => import("../components/admin/TravelInformation.vue"),
        meta: {
          title: "Travel Information",
          breadcrumb: "Travel Information Add",
        },
      },
      {
        path: "member/travel-information/:id/edit",
        name: "admin-travel-information-edit",
        component: () => import("../components/admin/TravelInformation.vue"),
        meta: {
          title: "Travel Information Edit",
          breadcrumb: "Travel Information Edit",
        },        
      },
      //Team's Menu for Admin
      {
        path: "team-registrations",
        name: "teamregistrations",
        component: () => import("../components/admin/ClubRegistrations.vue"),
        meta: {
          title: "Team Registrations List",
          breadcrumb: "Team Registrations List",        
        },
      },
      {
        path: "staff-registrations",
        name: "staffregistrations",
        component: () => import("../components/admin/StaffRegistrations.vue"),
        meta: {
          title: "Staff Registrations List",
          breadcrumb: "Staff Registrations List",        
        }
      },
      {
        path: "team-registrations/:regId",
        name: "TeamUserProfile",
        component: () => import("../components/admin/ClubUserProfile.vue"),
        meta: {
          title: "Team user profile",
          breadcrumb: "Team User Profile",        
        },
      },
      {
        path: "staff-registrations/:regId",
        name: "StaffUserProfile",
        component: () => import("../components/admin/UserProfile.vue"),
        meta: {
          title: "Staff user profile",
          breadcrumb: "Staff User Profile",        
        },
      },
      {
        path: "free-agent",
        name: "freeagent",
        component: () => import("../components/admin/FreeAgent.vue"),
        meta: {
          title: "Free Agent Player List",
          breadcrumb: "Free Agent Player List",
        },
      },
      {
        path: "staff-free-agent",
        name: "stafffreeagent",
        component: () => import("../components/admin/StaffFreeAgent.vue"),
        meta: {
          title: "Free Agent Coach List",
          breadcrumb: "Free Agent Coach List",
        },
      },
      {
        path: "playerTransfer/request",
        name: "playerTransferRequest",
        component: () => import("../components/admin/PlayerTransferRequest.vue"),
        meta: {
          title: "Player Transfer Request List",
          breadcrumb: "Player Transfer / Player Transfer Request List",
        },
      },
      {
        path: "playerTransfer/history",
        name: "playerTransferHistory",
        component: () => import("../components/admin/PlayerTransferHistory.vue"),
        meta: {
          title: "Player Transfer History List",
          breadcrumb: "Player Transfer / Player Transfer History List",
        },
      },
      {
        path: "playerTransfer/enable",
        name: "playerTransferEnable",
        component: () => import("../components/admin/PlayerTransferEnable.vue"),
        meta: {
          title: "Player Transfer Enable",
          breadcrumb: "Player Transfer / Player Transfer Enable",
        },
      },
      {
        path: "template-types",
        name: "TemplateTypes",
        component: () => import("../components/admin/TemplateType.vue"),
        meta: {
          title: "Template Types",
          breadcrumb: "Template Types",
        },
      },
      {
        path: "email-templates",
        name: "EmailTemplates",
        component: () => import("../components/admin/EmailTemplates.vue"),
        meta: {
          title: "Email Templates",
          breadcrumb: "Email Templates",
        },
      },
      {
        path: "create-campaign",
        name: "CreateCampaign",
        component: () => import("../components/admin/CreateCampaign.vue"),
        meta: {
          title: "Create Campaign",
          breadcrumb: "Create Campaign",
        },
      },
      {
        path: "campaign-history-reports",
        name: "CampaignHistoryReports",
        component: () => import("../components/admin/CampaignHistoryReports.vue"),
        meta: {
          title: "Campaign History Reports",
          breadcrumb: "Campaign History Reports",
        },
      },
      {
        path: "resource",
        name: "Resource",
        component: () => import("../components/admin/cmsResource.vue"),
        meta: {
          title: "Resource",
          breadcrumb: "Resource",
        },
      },
      {
        path: "certificate-of-insurances",
        name: "CertificateOfInsurances",
        component: () => import("../components/admin/cmsCertificateOfInsurances.vue"),
        meta: {
          title: "Certificate Of Insurances",
          breadcrumb: "Certificate Of Insurances",
        },
      },
      {
        path: "magazine",
        name: "Magazines",
        component: () => import("../components/admin/cmsMagazines.vue"),
        meta: {
          title: "Magazines",
          breadcrumb: "Magazines",
        },
      },
    ],
  },
  {
    path: "/club",
    component: ClubAuthLayout,
    children: [
      {
        path: "login",
        name: "clublogin",
        component: () => import("../components/club/dashboard/ClubLogin.vue"),
        meta: {
          title:"Club Login",
          guest: true,
          breadcrumb: "",        
        },
      },
      {
        path: "forgotpassword",
        name: "clubforgotpassword",
        component: () => import("../components/club/dashboard/ClubForgotPassword.vue"),
        meta: {
          title:"Forgot Password",
          guest: true,
          breadcrumb: "",        
        },
      },
      {
        path: "password_reset/:token",
        name: "passwordreset",
        component: () => import("../components/club/dashboard/ClubForgotPassword.vue"),
        meta: {
          breadcrumb: "",        
        },
      },
      {
        path: "find-username",
        name: "clubFindUsername",
        component: () => import("../components/FindUsername"),
        meta: {
          guest: true,
        },
      }
    ]

  },
  {
    path: "/club",
    component: ClubLayout,
    children: [
      {
        path: "dashboard",
        name: "clubDashboard",
        component: () =>
          import("../components/club/dashboard/clubdashboard.vue"),
        meta: {
          title:"Club Dashboard",
          breadcrumb: "Dashboard",
          requiresCLubAuth: true,
        },
      },
      {
        path: "preference",
        name: "preference",
        component: () =>
          import("../components/club/dashboard/settings/ClubSettings.vue"),
        meta: {
          title:"Preferences",
          breadcrumb: "Preferences",
          requiresCLubAuth: true,
        },
      },
      {
        path: "membership",
        name: "membership",
        component: () =>
          import("../components/club/dashboard/ClubMembership.vue"),
        meta: {
          title:"Membership History",
          requiresCLubAuth: true,
          breadcrumb: "Membership History",
        },
      },
      {
        path: "clubofficials",
        name: "clubofficials",
        component: () =>
          import("../components/club/dashboard/ClubOfficials.vue"),
        meta: {
          title:"Club Leadership",
          requiresCLubAuth: true,
          breadcrumb: "Club Leadership",
        },
      },
      {
        path: "club-official-roster",
        name: "clubofficialroster",
        component: () =>
          import("../components/club/dashboard/ClubOfficialRoster.vue"),
        meta: {
          title:"Club Official Roster",
          requiresCLubAuth: true,
          breadcrumb: "Clubofficialroster",
        },
      },
      {
        path: "clubroaster",
        name: "clubroaster",
        component: () => import("../components/club/dashboard/ClubRoaster.vue"),
        meta: {
          title:"Club Member Roster",
          requiresCLubAuth: true,
          breadcrumb: "Club Member Roster",
        },
      },
      {
        path: "guest-roster",
        name: "guest-roster",
        component: () => import("../components/club/dashboard/ClubRoaster.vue"),
        meta: {
          title:"Club Guest Member Roster",
          requiresCLubAuth: true,
          breadcrumb: "Club Guest Member Roster",
          listGuestOnly: true
        },
      },
      {
        path: "clubmyprofile",
        name: "Clubmyprofile",
        component: () =>
          import("../components/club/dashboard/profile/ClubMyProfile.vue"),
        meta: {
          title:"Club Profile",
          requiresCLubAuth: true,
          breadcrumb: "Club Profile",
        },
      },
      {
        path: "club-insurance-information",
        name: "ClubInsuranceInformation",
        component: () =>
          import("../components/club/dashboard/ClubInsuranceInformation.vue"),
        meta: {
          title:"Club Insurance Information",
          requiresCLubAuth: true,
          breadcrumb: "Club Insurance Information",
        },
      },
      {
        path: "quick-link-resources",
        name: "quicklinkResource",
        component: () =>
          import("../components/club/dashboard/QuickLinkResource.vue"),
        meta: {
          title:"Quick Link Resource",
          requiresCLubAuth: true,
          breadcrumb: "Quick Link Resource",
        },
      },
      {
        path: "practice-event-sanctioning",
        name: "Practice Event Sanctioning",
        component: () =>
          import("../components/club/dashboard/PracticeEventSanctioning.vue"),
        meta: {
          title:"Practice/Event Sanctioning",
          requiresCLubAuth: true,
          breadcrumb: "Practice Event Sanctioning",
        },
      },
      {
        path: "club-event-merchandise",
        name: "Clubeventmerchandise",
        component: () =>
          import("../components/club/dashboard/ClubEventMerchandise.vue"),
        meta: {
          title:"Club/Event Merchandise",
          requiresCLubAuth: true,
          breadcrumb: "Club/Event Merchandise",
        },
      },
      {
        path: "award-trophy-discounts",
        name: "awardtrophy",
        component: () =>
          import("../components/club/dashboard/AwardTrophyDiscounts.vue"),
        meta: {
          title:"Award/Trophy Discounts",
          requiresCLubAuth: true,
          breadcrumb: "Award/Trophy Discounts",
        },
      },
      {
        path: "faq",
        name: "faq",
        component: () => import("../components/club/dashboard/Faq.vue"),
        meta: {
          title:"Club FAQ",
          requiresCLubAuth: true,
          breadcrumb: "Club FAQ",
        },
      },
      {
        path: "clubrenewal",
        name: "clubrenewal",
        // component: () => import("../components/club/dashboard/ClubRenewal.vue"),
        // meta: {
        //   title:"Club Renewal",
        //   requiresCLubAuth: true,
        //   breadcrumb: "Club Renewal",
        // },
        redirect: { name: 'renew' }
      },
      {
        path: "renew",
        name: "renew",
        component: () => import("../components/club/dashboard/ClubRenewal.vue"),
        meta: {
          title:"Club Renewal",
          requiresCLubAuth: true,
          breadcrumb: "Club Renewal",
        },
      },
      {
        path: "registrations",
        name: "clubRegistrationsForRenew",
        component: () =>
          import("../components/club/dashboard/Registrations.vue"),
        meta: {
          requiresCLubAuth: true,
          breadcrumb: "Club Renewal",
        },
      },
      {
        path: "purchase-insurance",
        name: "purchase-insurance",
        component: () =>
          import("../components/club/dashboard/PurchaseClubInsurance.vue"),
        meta: {
          requiresCLubAuth: true,
          breadcrumb: "Purchase Insurance",
        },
      },
      {
        path: "purchase-sanctioning",
        name: "purchase-sanctioning",
        component: () =>
            import("../components/club/dashboard/PurchaseClubSanctioning.vue"),
        meta: {
          requiresCLubAuth: true,
          breadcrumb: "Purchase Sanctioning",
        },
      },
    ],
  },
  {
    path: "/member",
    component: MemberAuthLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import("../components/Member/Dashboard/MemberLogin.vue"),
        meta: {
          title:"Member Login",
          breadcrumb: "Member Login",
        },
      },
      {
        path: "forgotpassword",
        name: "memberforgotpw",
        component: () =>
          import("../components/Member/Dashboard/MemberForgotPassword.vue"),
        meta: {
          breadcrumb: "",
        },
      },
      {
        path: "find-username",
        name: "memberFindUsername",
        component: () => import("../components/FindUsername.vue"),
        meta: {
          breadcrumb: "",
        },
      }
    ],
  },
  {
    path: "/member",
    component: MemberLayout,
    children: [
      {
        path: "dashboard",
        name: "memberDashboard",
        component: () => import("../components/Member/Dashboard/dashboard.vue"),
        meta: {
          title: "Member Dashboard",
          breadcrumb: "Dashboard",
        },
      },
      {
        path: "ncsi",
        name: "backgroundscreening",
        component: () => import("../components/Member/Dashboard/BackgroundScreening.vue"),
        meta: {
          title: "Background Screening",
          breadcrumb: "Background Screening",
        },
      },
      {
        path: "collegiate-links",
        name: "collegiatelinks",
        component: () => import("../components/Member/Dashboard/pages/CollegiateLinks.vue"),
        meta: {
          title: "Collegiate Links",
          breadcrumb: "Collegiate Links",
        },
      },
      {
        path: "mvr-review",
        name: "backgroundscreening",
        component: () => import("../components/Member/Dashboard/MvrReview.vue"),
        meta: {
          title: "MVR Review",
          breadcrumb: "MVR Review",
        },
      },
      {
        path: "travel-information",
        name: "travelinformation-list",
        component: () => import("../components/Member/Travel/index.vue"),
        meta: {
          title: "Travel Information",
          breadcrumb: "Travel Information List",
        },
      },
      {
        path: "travel-information/add",
        name: "travelinformation-add",
        component: () => import("../components/Member/Travel/TravelInformation.vue"),
        meta: {
          title: "Travel Information",
          breadcrumb: "Travel Information Add",
        },
      },
      {
        path: "travel-information/:id/edit",
        name: "travelinformation-edit",
        component: () => import("../components/Member/Travel/TravelInformation.vue"),
        meta: {
          title: "Travel Information",
          breadcrumb: "Travel Information Edit",
        },
      },
      {
        path: "memberfaq",
        name: "memberfaq",
        component: () => import("../components/Member/Dashboard/MemberFaq.vue"),
        meta: {
          title:"Member Faq",
          breadcrumb: "Member FAQ",
        },
      },
      {
        path: "online-testing",
        name: "onlinetesting",
        component: () =>
          import(
            "../components/Member/Dashboard/pages/InstructorCoachPrograms.vue"
          ),
        meta: {
          title:"Instructor/Coach Programs",
          breadcrumb: "Instructor/Coach Programs",
        },
      },
      {
        path: "drivers-program",
        name: "driversprogram",
        component: () =>
          import("../components/Member/Dashboard/pages/DriversProgram.vue"),
        meta: {
          title: "Driver Programs / MVR Review",
          breadcrumb: "Driver Programs / MVR Review",
        },
      },
      {
        path: "safesports",
        name: "membersafesports",
        component: () =>
          import("../components/Member/Dashboard/pages/SafeSports.vue"),
        meta: {
          title:"SafeSport Training / Background Screen",
          breadcrumb: "SafeSport Training / Background Screen",
        },
      },
      {
        path: "my-officials-ratings-certifications",
        name: "myofficialsratingscertification",
        component: () =>
          import(
            "../components/Member/Dashboard/pages/MyofficialratingandCertification.vue"
          ),
        meta: {
          title:"My Officials Ratings/Certifications",
          breadcrumb: "My Officials Ratings/Certifications",
        },
      },{
        path: "/roster/ratings_details/:personId/:eventId/:ratingTypeId",
        component: MemberRatingDetails,
        meta: {
          breadcrumb: "Roster - Rating Details",
        },
      },
      {
        path: "water-skier-magazine",
        name: "magazine",
        component: () =>
          import("../components/Member/Dashboard/pages/WaterSkierMagazine.vue"),
        meta: {
          title:"The Water Skier Magazine",
          breadcrumb: "The Water Skier Magazine",
        },
      },
      {
        path: "member-donation",
        name: "memberdonation",
        component: () =>
          import("../components/Member/Dashboard/pages/MemberDonation.vue"),
        meta: {
          breadcrumb: "Member Donation",
        },
      },
      {
        path: "membership-history",
        name: "membershiphistory",
        component: () =>
          import("../components/Member/Dashboard/MyMembership.vue"),
        meta: {
          breadcrumb: "Membership History",
        },
      },
      {
        path: "preferences",
        name: "preferences",
        component: () =>
          import("../components/Member/Dashboard/settings/Settings.vue"),
        meta: {
          breadcrumb: "Preferences",
        },
      },
      {
        path: "myprofile",
        name: "myprofile",
        component: () =>
          import("../components/Member/Dashboard/profile/MyProfile.vue"),
        meta: {
          breadcrumb: "My Profile",
        },
      },
      {
        path: "campskimyprofile",
        name: "campskimyprofile",
        component: () =>
            import("../components/Member/Dashboard/profile/CampSkiMyProfile.vue"),
        meta: {
          breadcrumb: "My Profile",
        },
      },
      {
        path: "family-profile",
        name: "familyprofile",
        component: () =>        
          import("../components/Member/Dashboard/FamilyProfile.vue"),
        meta: {
          breadcrumb: "Family Profile",
        },
      },
      {
        path: "renew",
        name: "renewmembership",
        component: () =>
          import("../components/Member/Dashboard/renew/Renew.vue"),
        meta: {
          breadcrumb: "Renew Membership",
        },
      },
      {
        path: "renewal",
        name: "renewal",
        component: () =>
            import("../components/Member/renewal/RenewDataView"),
        meta: {
          breadcrumb: "Renew Membership",
        },
      },
      {
        path: "campski-renewal",
        name: "campski-renewal",
        component: () =>
            import("../components/CampSki/RenewDataView"),
        meta: {
          title:"Camp / Ski School Renewal",
          breadcrumb: "Renew Membership",
        },
      },
      {
        path: "upgrade",
        name: "upgrade",
        component: () =>
          import("../components/Member/Dashboard/renew/Renew.vue"),
        meta: {
          breadcrumb: "Upgrade Membership",
        },
      },
      {
        path: "shooting-result",
        name: "Shooting Result",
        component: () =>
          import("../components/Member/Dashboard/ShootingScore.vue"),
        meta: {
          breadcrumb: "Shooting Result",
        },
      },
      {
        path: "volunteering",
        name: "Volunteering",
        component: () =>
          import("../components/Member/Dashboard/volunteering/List.vue"),
        meta: {
          breadcrumb: "Volunteering",
        },
      },
      {
        path: "partners",
        name: "partners",
        component: () =>
            import("../components/Member/Dashboard/partner.vue"),
        meta: {
          breadcrumb: "Partners",
        },
      },
    ],
  },
  {
    path: "/team",
    component: ClubLayout,
    children: [
      {
        path: "dashboard",
        name: "TeamDashboard",
        component: () =>
            import("../components/club/dashboard/clubdashboard.vue"),
        meta: {
          title:"Team Dashboard",
          breadcrumb: "Dashboard",
          requiresCLubAuth: true,
        },
      },
      {
        path: "preference",
        name: "preference",
        component: () =>
            import("../components/club/dashboard/settings/ClubSettings.vue"),
        meta: {
          title:"Preferences",
          breadcrumb: "Preferences",
          requiresCLubAuth: true,
        },
      },
      {
        path: "membership",
        name: "membership",
        component: () =>
            import("../components/club/dashboard/ClubMembership.vue"),
        meta: {
          title:"Membership History",
          requiresCLubAuth: true,
          breadcrumb: "Membership History",
        },
      },
      {
        path: "clubofficials",
        name: "clubofficials",
        component: () =>
            import("../components/club/dashboard/ClubOfficials.vue"),
        meta: {
          title:"Club Leadership",
          requiresCLubAuth: true,
          breadcrumb: "Club Leadership",
        },
      },
      {
        path: "staff",
        name: "staff",
        component: () =>
            import("../components/club/dashboard/Staffs.vue"),
        meta: {
          title:"Staff/Coach",
          requiresCLubAuth: true,
          breadcrumb: "Staff/Coach",
        },
      },
      {
        path: "teamroaster",
        name: "Teamroaster",
        component: () => import("../components/club/dashboard/ClubRoaster.vue"),
        meta: {
          title:"Team Member Roster",
          requiresCLubAuth: true,
          breadcrumb: "Team Member Roster",
        },
      },
      {
        path: "teammyprofile",
        name: "Teammyprofile",
        component: () =>
            import("../components/club/dashboard/profile/ClubMyProfile.vue"),
        meta: {
          title:"Team Profile",
          requiresCLubAuth: true,
          breadcrumb: "Team Profile",
        },
      },
      {
        path: "player_transfer_request",
        name: "Player Transfer Request",
        component: () => import("../components/club/dashboard/ClubPlayerTransferRequest.vue"),
        meta: {
          title:"Player Transfer Request",
          requiresCLubAuth: true,
          breadcrumb: "Player Transfer Request",
        },
      },

      {
        path: "faq",
        name: "faq",
        component: () => import("../components/club/dashboard/Faq.vue"),
        meta: {
          title:"Club FAQ",
          requiresCLubAuth: true,
          breadcrumb: "Club FAQ",
        },
      },
      {
        path: "renew",
        name: "renew",
        component: () => import("../components/club/renewal/RenewalDataView"),
        meta: {
          title:"Team Renewal",
          requiresCLubAuth: true,
          breadcrumb: "Team Renewal",
        },
      },
      {
        path: "renew",
        name: "renew",
        component: () => import("../components/club/dashboard/ClubRenewal.vue"),
        meta: {
          title:"Club Renewal",
          requiresCLubAuth: true,
          breadcrumb: "Club Renewal",
        },
      },
      {
        path: "registrations",
        name: "clubRegistrationsForRenew",
        component: () =>
            import("../components/club/dashboard/Registrations.vue"),
        meta: {
          requiresCLubAuth: true,
          breadcrumb: "Club Renewal",
        },
      },
      {
        path: "purchase-insurance",
        name: "purchase-insurance",
        component: () =>
            import("../components/club/dashboard/PurchaseClubInsurance.vue"),
        meta: {
          requiresCLubAuth: true,
          breadcrumb: "Purchase Insurance",
        },
      },
      {
        path: "purchase-sanctioning",
        name: "purchase-sanctioning",
        component: () =>
            import("../components/club/dashboard/PurchaseClubSanctioning.vue"),
        meta: {
          requiresCLubAuth: true,
          breadcrumb: "Purchase Sanctioning",
        },
      },
      {
        path: "partners",
        name: "partners-team",
        component: () =>
            import("../components/Member/Dashboard/partner.vue"),
        meta: {
          breadcrumb: "Partners",
        },
      },
      {
        path: "insurance",
        name: "insurance",
        component: () =>
            import("../components/club/insurance.vue"),
        meta: {
          title:"Team Insurance",
          requiresCLubAuth: true,
          breadcrumb: "Insurance",
        },
      },
    ],
  },
  {
    path: "/team",
    component: ClubAuthLayout,
    children: [
      {
        path: "login",
        name: "Teamlogin",
        component: () => import("../components/club/dashboard/ClubLogin.vue"),
        meta: {
          title:"Team Login",
          guest: true,
          breadcrumb: "",
        },
      },
      {
        path: "forgotpassword",
        name: "Teamforgotpassword",
        component: () => import("../components/club/dashboard/ClubForgotPassword.vue"),
        meta: {
          title:"Forgot Password",
          guest: true,
          breadcrumb: "",
        },
      },
      {
        path: "password_reset/:token",
        name: "passwordreset",
        component: () => import("../components/club/dashboard/ClubForgotPassword.vue"),
        meta: {
          breadcrumb: "",
        },
      },
      {
        path: "find-username",
        name: "TeamFindUsername",
        component: () => import("../components/FindUsername"),
        meta: {
          guest: true,
        },
      }

    ]

  },

  //Club routes


  {
    path: "/club/roster/officialratings/:personId/:withCerts?",
    component: ClubRosterRatings,
    meta: {
      requiresCLubAuth: true,
      breadcrumb: "Club Roster - Official Ratings",
    },
  },{
    path: "/clubroster/ratings_details/:personId/:eventId/:ratingTypeId",
    component: ClubRatingDetails,
    meta: {
      requiresCLubAuth: true,
      breadcrumb: "Club Roster - Rating Details",
    },
  },
  {
    path: "https://www.teamusa.org/usa-water-ski/safe-sport",
    name: "atheletsafetysport",
  },
  {
    path:
      "https://usawaterski.perkspot.com/login/!2f!3futm_medium!3demail!26utm_id!3dwelcome!26promotionId!3d",
    name: "membershipdiscounts",
  },
  { path: "https://shop.usawaterski.org/", name: "usamerchandise" },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const host = window.location.host;
  if(host.toLowerCase().includes("waterski")) Axios.defaults.headers.common['ClientId'] = 1;
  else if(host.toLowerCase().includes("shooting")) Axios.defaults.headers.common['ClientId'] = 2;
  else if(host.toLowerCase().includes("alliance")) Axios.defaults.headers.common['ClientId'] = 3;
  else Axios.defaults.headers.common['ClientId'] = -1;

  document.title = to.meta.title || 'iSportz';
  if (to.matched.some((record) => record.meta.requiresCLubAuth)) {
    if (localStorage.getItem("clubToken") == null) {
      next({
        path: "/club/login",
        query: { "returnurl": to.fullPath },
      });
    } else {
      // let user = localStorage.getItem('clubToken');
      axios
        .get(process.env.VUE_APP_URL + "api/club/user_verify", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("clubToken")
          },
        })
        .then((response) => {
          const resp = response.data.data;
          if (resp.team_id > 0) {
            next();
          } else {
            next({
              path: "/club/login",
              query: { "returnurl": to.fullPath },
            });
          }
        })
        .catch((error) => {
          console.log("requiresCLubAuthValidation" + error);
          next({
            path: "/club/login",
            query: { "returnurl": to.fullPath },
          });
        });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("membertoken") == null) {
      next();
    } else {
      // let user = localStorage.getItem('membertoken');
      axios
        .get(process.env.VUE_APP_URL + "api/club/user_verify", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("membertoken"),
          },
        })
        .then((response) => {
          const resp = response.data.data;
          if (resp.team_id > 0) {
            next({
              path: "/club/dashboard",
              query: { "returnurl": to.fullPath },
            });
          } else {
            next();
          }
        })
        .catch((error) => {
          console.log("GuestUserValidation" + error);
          next();
        });
    }
  } else if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
      if (localStorage.getItem("admintoken") == null) {
          next({
              path: "/login",
              query: { "returnurl": to.fullPath },
          });
      } else {
          next();
          axios.get(process.env.VUE_APP_URL + "api/admin/verify", {
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("admintoken"),
              }
          }).then((response) => {
              const resp = response.data.data;
              if (resp.id > 0) {
                  next();
              } else {
                  next({
                      path: "/login",
                    query: { "returnurl": to.fullPath },
                  });
              }
          }).catch((error) => {
              console.log("requiresAdminAuthValidation" + error);
              next({
                  path: "/login",
                query: { "returnurl": to.fullPath },
              });
          });
      }
  } else {
    next();
  }
});

export default router;
