<template>
<div class="card club-info">
   <div class="card-header register-title">
      <h3 class="pb-2">{{ fields.tab_title || 'Show Director(s) / Assistant Show Director(s)'}}</h3>
       <div v-if="clubInfoData.type == 6 && fields.show_ski">
           <span class="text-justify d-block mb-2"><i>{{ fields.show_ski.label }}</i></span>
           <template v-if="fields.show_ski.info">
            <ul class="skdinfo" v-for="(item,k) in fields.show_ski.info" :key="'show_2_ski_info_' + k">
                <li v-html="item"></li>
            </ul>
           </template>
       </div>

         <div v-else>
            <template v-if="fields.other">
                <span class="text-justify d-block mb-2"><i>{{ fields.other.label }}</i></span>
                <template v-if="fields.other.info">
                    <ul class="skdinfo" v-for="(item,k) in fields.other.info" :key="'other_' + k">
                        <li v-html="item"></li>
                    </ul>
                </template>
           </template>
        </div>
   </div>
<div class="card-body addnewbutn">
<b-button v-b-modal.modal-center-ski-director v-if="skiDirectorContactType.skiDirectorContactAdded == false" @click="captureCurrentContact(contactTypes.skiDirector)">+ Add Show Director</b-button>
    <span class="text-sm text-danger">{{ errors.primary }}</span>
    <div v-for="(skiMember, t) in skiDirectorMemberContacts" :key="t" class="memberdetail-section">
        <div  class="row">
            <div  class="col-md-6">
                <h3  class="mb-3">{{ fields.add.title }}</h3>
            </div>
            <div  class="d-flex col-md-6 text-right">
                <button type="button" class="btn-remove" @click="removeSkiDirectorContact(t,skiMember.contact_type,skiMember.index)"><img  src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove</button>
            </div>
        </div>
        <div  class="row juniordevlopment tab-cont">
            <div  class="col-md-12">
                <div class="form-group">
                    <label class="form-label">{{ fields.add.search_by_member.member_id.label }}</label>
                    <span class="title">{{ skiMember.membership_id }}</span>
                </div>
            </div>
            <div  class="col-md-6">
                <div class="form-group">
                    <label class="form-label">{{ fields.add.search_by_name.first_name.label }}</label>
                    <span class="title">{{ skiMember.first_name }}</span>
                </div>
            </div>
            <div  class="col-md-6">
                <div class="form-group">
                    <label class="form-label">{{ fields.add.search_by_name.last_name.label }}</label>
                    <span class="title">{{ skiMember.last_name }}</span>
                </div>
            </div>
        </div>
    </div>
    <b-button v-b-modal.modal-center-ski-director v-if="skiDirectorContactType.assistantSkiDirectorContactAdded == false" @click="captureCurrentContact(contactTypes.assistantSkiDirector)">+ Add Assistant Show Director</b-button>
    <div v-for="(askiMember, t) in assistantSkiDirectorMemberContacts" :key="t" class="memberdetail-section">
        <div  class="row">
            <div  class="col-md-6">
                <h3  class="mb-3">{{ fields.add.title }}</h3>
            </div>
            <div  class="d-flex col-md-6 text-right">
                <button type="button" class="btn-remove" @click="removeSkiDirectorContact(t,askiMember.contact_type,askiMember.index)"><img  src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove</button>
            </div>
        </div>
        <div  class="row juniordevlopment tab-cont">
            <div  class="col-md-12">
                <div class="form-group">
                    <label class="form-label">{{ fields.add.search_by_member.member_id.label }}</label>
                    <span class="title">{{ askiMember.membership_id }}</span>
                </div>
            </div>
            <div  class="col-md-6">
                <div class="form-group">
                    <label class="form-label">{{ fields.add.search_by_name.first_name.label }}</label>
                    <span class="title">{{ askiMember.first_name }}</span>
                </div>
            </div>
            <div  class="col-md-6">
                <div class="form-group">
                    <label class="form-label">{{ fields.add.search_by_name.last_name.label }}</label>
                    <span class="title">{{ askiMember.last_name }}</span>
                </div>
            </div>
        </div>
    </div>
<b-modal ref="modal"
         @show="resetModal"
         @hidden="resetModal" id="modal-center-ski-director" size="xl"  v-bind:hide-footer="true" centered :title="fields.title || 'Show SKI Director(s)'">
<div class="card-body">
    <div class="row tab-cont">
        <div class="col-md-4">
            <label><strong>{{ fields.add.search_by_member.label }}</strong></label>
            <div class="form-group">
                <div class="search-left">
                    <label class="form-label"> {{ fields.add.search_by_member.member_id.label }}</label>
                    <input type="text" class="form-control" maxlength="20" id="searchbyname"  name="juniorMemberLookupForm.member_id" v-model="juniorMemberLookupForm.member_id">
                    <span class="text-sm text-danger">{{ errors.member_id }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center">
            <strong>OR</strong>
        </div>
        <div class="col-md-12">
            <label><strong>{{ fields.add.search_by_name.label }}</strong></label>
        </div>
        <div class="col-md-3" v-if="fields.add.search_by_name.first_name">

            <div class="form-group">
                <label for="firstname" class="form-label">{{ fields.add.search_by_name.first_name.label }}</label>
                <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="juniorMemberLookupForm.first_name" v-model="juniorMemberLookupForm.first_name">
                <span class="text-sm text-danger">{{ errors.first_name }}</span>
            </div>
        </div>
        <div class="col-md-3" v-if="fields.add.search_by_name.last_name">
            <div class="form-group">
                <label for="lastname" class="form-label">{{ fields.add.search_by_name.last_name.label }}</label>
                <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="juniorMemberLookupForm.last_name" v-model="juniorMemberLookupForm.last_name">
                <span class="text-sm text-danger">{{ errors.last_name }}</span>
            </div>
        </div>
        <div class="col-md-3" v-if="fields.add.search_by_name.city">
            <div class="form-group">
                <label for="city" class="form-label">{{ fields.add.search_by_name.city.label }}</label>
                <input type="text" class="form-control" maxlength="45" placeholder="New Alessiaview" name="juniorMemberLookupForm.city" v-model="juniorMemberLookupForm.city">
                <span class="text-sm text-danger">{{ errors.city }}</span>
            </div>
        </div>
        <div class="col-md-3" v-if="fields.add.search_by_name.state">
            <div class="form-group">
                <label for="state" class="form-label">{{ fields.add.search_by_name.state.label }}</label>
                <select class="form-control" id="state" name="juniorMemberLookupForm.state" v-model="juniorMemberLookupForm.state">
                    <option value="">Please select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.state }}</span>
            </div>
        </div>
        <div class="col-md-12 modal-searchbtn text-center">
            <div class="form-group">
                <button type="button" @click="memberSearch();" class="btn-primary btn btn-blue">{{ button.SearchButton }}</button>
            </div>
            <div class="text-sm text-danger">{{ errors.not_found }}</div>
        </div>
    </div>
</div>
    <div>
        <div v-if="juniorMemberSerchResultStatus == true" class="card-body primary-contact">
            <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Member#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                  <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(member, r) in juniorMemberSearchResults" :key="r">
                    <th scope="row">{{ member.confirmation_code }}</th>
                    <td>{{ member.personal_details.first_name }}</td>
                    <td>{{ member.personal_details.last_name }}</td>
                    <td>{{ member.contact_details.email }}</td>
                    <td>{{ member.contact_details.phone_1 }}</td>
                    <td>{{ member.contact_details.city }}</td>
                    <td>{{ member.contact_details.state_code }}</td>
                  <td>{{ member.membership_status_text }}</td>
                    <td><button class="btn btn-primary btn-success" @click="pushSelectedMemberSkiShowDirector(member)">Add</button></td>
                </tr>

                </tbody>
            </table>
            </div>
        </div>
    </div>
</b-modal>


</div>
    <div class="">
        <div class="col-md-12">
            <div class="text-right topspace">
                <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                <button type="button" @click="ClubContactCheck" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
import moment from "moment";
const validateName = name => {
    if (name == undefined) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateSelect = select => {
    console.log('lenghth='+select);
    if (select == undefined) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
export default {
    name: "skishowdirector",
    props: ['clubMemberContactLength', 'clubInfoData', 'fields'],
    data() {
        return{

            form: {
                "first_name": '',
                "last_name": '',
                "membership_id": '',
                "address1": '',
                "city": '',
                "state": '',
                "country": 'USA',
                "zip": '',
                "phone1": '',
                "phone2": '',
                "fax": '',
                "mobile": '',
                "email": '',
                "contact_type": '0'
            },
            button: {
                SearchButton: 'Search'
            },
            juniorMemberLookupForm: {
                member_id: '',
                first_name: '',
                last_name: '',
                city: '',
                state: ''
            },
            juniorMemberSerchResults: [],
            juniorMemberSerchResultStatus: false,
            juniorContactAdded: false,
            skiDirectorContactAdded: false,
            assistantSkiDirectorContactAdded: false,
            skiDirector: {
                membership_id: '',
                first_name: '',
                last_name: '',
                index: '',
                contact_type: ''
            },
            contactTypes: {
                skiDirector: 6,
                assistantSkiDirector: 7
            },
            skiDirectorContact : {
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                membership_id: '',
                contact_type: 6
            },
            assistantSkiDirectorContact : {
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                membership_id: '',
                contact_type: 7
            },
            skiDirectorMemberContacts: [],
            assistantSkiDirectorMemberContacts: [],
            skiDirectorContactType: {
                skiDirectorContactAdded: false,
                assistantSkiDirectorContactAdded: false
            },
            currentContact: '',
            valid: true,
            success: false,
            errors: {},
            message: null,
          editContactType: [6,7],
        }
    },

    methods: {
        memberSearch: function () {
            if(!this.validate()) {
                return;
            }
            console.log(this.juniorMemberLookupForm);
            this.button.SearchButton = 'Searching...';
            this.errors= {};
            axios.get(this.basePath+'api/member_lookup?member_id='+this.juniorMemberLookupForm.member_id+'&first_name='+this.juniorMemberLookupForm.first_name+'&last_name='+this.juniorMemberLookupForm.last_name+'&city='+this.juniorMemberLookupForm.city+'&state='+this.juniorMemberLookupForm.state+'&editContactType='+this.editContactType)
                .then((res) => {
                    console.log('status='+res.data.status);
                    if(res.data.status == 'success') {
                        this.button.SearchButton = 'Search';
                        this.juniorMemberSerchResultStatus = true;
                        console.log(res.data.data);
                        console.log('-------------');
                        this.juniorMemberSearchResults = res.data.data;
                        console.log('-------1------');
                        console.log(this.juniorMemberSearchResults);
                        //Perform Success Action
                        //alert('success')
                        /*console.log('verify success');
                        this.verifyData = res.data.data;
                        //this.merchandiseDataApi();
                        //console.log(this.verifyData);
                        return res;*/
                    } else {
                      if (res.data.errors == true) {
                        this.juniorMemberSerchResultStatus = false;
                        this.errors.not_found = res.data.message;
                      }else{
                        this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                      }
                        this.button.SearchButton = 'Search';
                        return false;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                    //this.step--;
                    this.button.SearchButton = 'Search';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {


            });
        },
        pushSelectedMemberSkiShowDirector: function(selectedMember){
            console.log('----my member child----1111----');
            console.log(selectedMember);
            this.getSkiDirectorContact(selectedMember);
            this.$emit('club-ski-directors-data', this.skiDirector);
            this.skiDirector= {};
            console.log('------clubMemberContactLength after-------');
            console.log('club-ski-directors-data'+this.clubMemberContactLength.length);
            this.valid = true;
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-ski-director')
            })
            this.errors.primary = '';
        },
        ClubContactCheck: function(){
            this.errors = {}
            console.log('this contact check');
            console.log(this.valid);
            if(this.valid) {
                console.log("1"+this.valid);
                this.errors.primary = '';
                this.$emit('next-step');
                return;
            } else {
                console.log("2"+this.valid);
                this.errors.primary = 'Ski directors contact is required!';
            }
        },
        removeSkiDirectorContact(index,contactType,contactIndex) {
            console.log('ddddd'+index,contactIndex,contactType);
            if(contactType == 6) {
                this.skiDirectorMemberContacts.splice(index, 1);
            } else if(contactType == 7) {
                this.assistantSkiDirectorMemberContacts.splice(index, 1);
            }
            this.$emit('club-remove-data', contactIndex);
            console.log('dev club-ski-directors-data'+this.clubMemberContactLength.length);
        },
        getSkiDirectorContact(memberDetail) {
            console.log('member=');
            console.log(memberDetail);
            let memberContact = memberDetail.contact_details;
            this.skiDirector.membership_id = memberDetail.confirmation_code;
            this.skiDirector.first_name = memberDetail.personal_details.first_name;
            this.skiDirector.last_name = memberDetail.personal_details.last_name;
            this.skiDirector.email = memberContact.email;
            this.skiDirector.address1 = memberContact.address1;
            this.skiDirector.address2 = memberContact.address2;
            this.skiDirector.city = memberContact.city;
            this.skiDirector.state = memberContact.state;
            this.skiDirector.state_code = memberContact.state_code;
            this.skiDirector.country = memberContact.country;
            this.skiDirector.zip = memberContact.zip;
            this.skiDirector.phone_1 = memberContact.phone_1;
            this.skiDirector.phone_2 = memberContact.phone_2;
            this.skiDirector.contact_type = this.currentContact;
            this.skiDirector.index = this.clubMemberContactLength.length;
            console.log(this.skiDirector);
            if(this.currentContact == 6) {
                this.skiDirectorMemberContacts.push(this.skiDirector);
                console.log('current contatn'+this.currentContact);
                console.log(this.skiDirectorMemberContacts);
            } else if(this.currentContact == 7) {
                this.assistantSkiDirectorMemberContacts.push(this.skiDirector);
                console.log('current contatn'+this.currentContact);
                console.log(this.assistantSkiDirectorMemberContacts);
            }
        },
        resetModal() {
            this.errors={};
            this.juniorMemberSerchResultStatus = false;
            this.juniorMemberSearchResults = [];
            this.juniorMemberLookupForm = {}
        },
        captureCurrentContact(currentContactType) {
            console.log('current contact+'+currentContactType);
            this.currentContact = currentContactType;
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        // Custom Validation Rules
        validate() {
            this.errors = {}
            console.log('-------------');
            console.log(this.juniorMemberLookupForm);
            const member_id = this.juniorMemberLookupForm.member_id;
            const first_name = this.juniorMemberLookupForm.first_name;
            const last_name = this.juniorMemberLookupForm.last_name;
            const city = this.juniorMemberLookupForm.city;
            const state = this.juniorMemberLookupForm.state;
            if(member_id == undefined && first_name == undefined && last_name == undefined && city == undefined && state == undefined) {
                this.errors.member_id = 'Plesse Enter Either Member Id or Below Member Details';
                this.valid = false;
                return this.valid;
            }else{
              this.valid = true;
            }
            if(member_id == undefined && (first_name == undefined || last_name == undefined || city == undefined || state == undefined)) {
                const validFirstName = validateName(this.juniorMemberLookupForm.first_name);
                this.errors.first_name = validFirstName.error;

                const validLastName = validateName(this.juniorMemberLookupForm.last_name);
                this.errors.last_name = validLastName.error;

                const validCity = validateSelect(this.juniorMemberLookupForm.city);
                this.errors.city = validCity.error;

                const validStateCode = validateSelect(this.juniorMemberLookupForm.state);
                this.errors.state = validStateCode.error;

                return this.valid;
            }

            this.valid = true;
            this.errors= {}

            return this.valid;
        }
    }

}
</script>
<style scoped>

</style>
