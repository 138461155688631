<template>
<div class="card club-info">
   <div class="card-header register-title">
      <h3>{{ fields.tab_title || 'Primary Contact Information'}}</h3>
   </div>
<div class="card-body addnewbutn pr-4 pl-4">
    <div v-if="subdomain == 'shooting'">
        <div class="row">
            <div :class="[fields.email.colClass]" v-if="fields.email">
                <div class="form-group">
                    <label for="email" class="form-label">{{ fields.email.label }} <sup class="text-danger" v-if="fields.email.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" id="email" v-model="form.email" name="form.email">
                    <span class="text-sm text-danger">{{ errors.email}}</span>
                </div>
            </div>
            <div :class="[fields.retype_email.colClass]" v-if="fields.retype_email">
                <div class="form-group">
                    <label for="retype_email" class="form-label">{{ fields.retype_email.label }} <sup class="text-danger" v-if="fields.retype_email.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="form.retype_email" name="form.retype_email">
                    <span class="text-sm text-danger">{{ errors.retype_email}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="[fields.club_address.colClass]" v-if="fields.club_address">
                <div class="form-group">
                    <label for="firstname" class="form-label">{{ fields.club_address.label }} <sup class="text-danger" v-if="fields.club_address.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="100" id="club_address" v-model="form.club_address" name="form.club_address">
                    <span class="text-sm text-danger">{{ errors.club_address }}</span>
                </div>
            </div>
            <div :class="[fields.club_city.colClass]" v-if="fields.club_city">
                <div class="form-group">
                    <label for="club_city" class="form-label">{{ fields.club_city.label }} <sup class="text-danger" v-if="fields.club_city.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" id="club_city"  v-model="form.club_city" name="form.club_city">
                    <span class="text-sm text-danger">{{ errors.club_city }}</span>
                </div>
            </div>
            <div :class="[fields.club_state.colClass]" v-if="fields.club_state">
                <div class="form-group">
                    <label for="club_state" class="form-label">{{ fields.club_state.label }} <sup class="text-danger" v-if="fields.club_state.required">*</sup></label>
                    <select class="form-control" id="club_state" v-model="form.club_state" name="form.club_state" >
                        <option value="">Select</option>
                        <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                    </select>
                    <span class="text-sm text-danger">{{ errors.club_state }}</span>
                </div>
            </div>
            <div :class="[fields.club_zip.colClass]" v-if="fields.club_zip">
                <div class="form-group">
                    <label for="club_zip" class="form-label">{{ fields.club_zip.label }} <sup class="text-danger" v-if="fields.club_zip.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="15" id="club_zip" v-model="form.club_zip" name="form.club_zip">
                    <span class="text-sm text-danger">{{ errors.club_zip }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="[fields.club_physical_address.colClass]" v-if="fields.club_physical_address">
                <div class="form-group">
                    <label for="club_physical_address" class="form-label">{{ fields.club_physical_address.label }} <sup class="text-danger" v-if="fields.club_physical_address.required">*</sup></label>
                    <div class="form-check mt-2 mb-3" v-if="fields.club_physical_address.same_check">
                        <input type="checkbox" id="same_as_mailing_address" name="form.same_as_mailing_address" class="form-check-input" value="1" v-model="form.same_as_mailing_address" @change="changeSameAddress">
                        <label for="same_as_mailing_address" class="d-flex mr-1">Same as Mailing Address</label>
                    </div>
                    <input type="text" class="form-control" maxlength="100" id="club_physical_address" v-model="form.club_physical_address" name="form.club_physical_address" :readonly="form.same_as_mailing_address">
                    <span class="text-sm text-danger">{{ errors.club_physical_address }}</span>
                </div>
            </div>
            <div :class="[fields.club_physical_city.colClass]" v-if="fields.club_physical_city">
                <div class="form-group">
                    <label for="club_physical_city" class="form-label">{{ fields.club_physical_city.label }} <sup class="text-danger" v-if="fields.club_physical_city.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" id="club_physical_city"  v-model="form.club_physical_city" name="form.club_physical_city" :readonly="form.same_as_mailing_address">
                    <span class="text-sm text-danger">{{ errors.club_physical_city }}</span>
                </div>
            </div>
            <div :class="[fields.club_physical_state.colClass]" v-if="fields.club_physical_state">
                <div class="form-group">
                    <label for="club_physical_state" class="form-label">{{ fields.club_physical_state.label }} <sup class="text-danger" v-if="fields.club_physical_state.required">*</sup></label>
                    <select class="form-control" id="club_physical_state" v-model="form.club_physical_state" name="form.club_physical_state" :disabled="form.same_as_mailing_address">
                        <option value="">Select</option>
                        <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                    </select>
                    <span class="text-sm text-danger">{{ errors.club_physical_state }}</span>
                </div>
            </div>
            <div :class="[fields.club_physical_zip.colClass]" v-if="fields.club_physical_zip">
                <div class="form-group">
                    <label for="club_physical_zip" class="form-label">{{ fields.club_physical_zip.label }} <sup class="text-danger" v-if="fields.club_physical_zip.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="15" id="club_physical_zip" v-model="form.club_physical_zip" name="form.club_physical_zip" :readonly="form.same_as_mailing_address">
                    <span class="text-sm text-danger">{{ errors.club_physical_zip }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="[fields.mobilephone.colClass]" v-if="fields.mobilephone">
                <div class="form-group ">
                    <div class="phone-nu-wrapper">
                        <label for="country_code_h" class="form-label">{{ fields.mobilephone.label }} <sup class="text-danger" v-if="fields.mobilephone.required">*</sup></label>
                        <vue-tel-input v-model="form.mobilephone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.mobilephone }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
<b-button v-b-modal.modal-center-primary v-if="primaryContactAdded == false">+ Add Contact</b-button>
    <span class="text-sm text-danger">{{ errors.primary }}</span>
<div v-if="primaryContactAdded == true" class="memberdetail-section">
    <div  class="row oneline-style">
        <div  class="col-md-6 pr-0" v-if="fields.section_title">
            <h3 class="mb-3">{{ fields.section_title }} </h3>
        </div>
        <div  class="d-flex col-md-6 text-right pl-0">
            <button type="button"  class="btn-remove" @click="removePrimaryContact()"><img  src="/dist/img/memberregistration/remove.png" alt="edit_icon">Remove</button>
        </div>
    </div>
    <div  class="row juniordevlopment">
        <div  class="col-md-12">
            <div class="form-group">
            <label class="form-label">{{ fields.search_by_member.member_id.label }}</label>
            <span class="title">{{ primaryContact.membership_id }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
            <label class="form-label">{{ fields.search_by_name.first_name.label }}</label>
            <span class="title">{{ primaryContact.first_name }}</span>
            </div>
        </div>
        <div  class="col-md-6">
            <div class="form-group">
            <label class="form-label">{{ fields.search_by_name.last_name.label }}</label>
            <span class="title">{{ primaryContact.last_name }}</span>
            </div>
        </div>
    </div>
</div>

<b-modal ref="modal"
         @show="resetModal"
         @hidden="resetModal" id="modal-center-primary" size="xl"  v-bind:hide-footer="true" centered :title="fields.title">
<div class="card-body">
    <form ref="form" method="post" id="MemerSearchForm">
    <div class="row">
        <div class="col-md-4">
            <label><strong>{{ fields.search_by_member.label }}</strong></label>
            <div class="form-group">
                <div class="search-left">
                    <label class="form-label"> {{ fields.search_by_member.member_id.label }}</label>
                    <input type="text" class="form-control" maxlength="20" name="memberLookupForm.member_id" v-model="memberLookupForm.member_id" id="searchbyname"  >
                    <span class="text-sm text-danger">{{ errors.member_id }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center">
            <strong>OR</strong>
        </div>
        <div class="col-md-12">
        <label><strong>{{ fields.search_by_name.label }}</strong></label>
        </div>
        <div class="col-md-3" v-if="fields.search_by_name.first_name">

            <div class="form-group">
                <label for="firstname" class="form-label">{{ fields.search_by_name.first_name.label }}</label>
                <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="memberLookupForm.first_name" v-model="memberLookupForm.first_name" value="">
                <span class="text-sm text-danger">{{ errors.first_name }}</span>
            </div>
        </div>
        <div class="col-md-3" v-if="fields.search_by_name.last_name">
            <div class="form-group">
                <label for="lastname" class="form-label">{{ fields.search_by_name.last_name.label }}</label>
                <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="memberLookupForm.last_name" v-model="memberLookupForm.last_name">
                <span class="text-sm text-danger">{{ errors.last_name }}</span>
            </div>
        </div>
        <div class="col-md-3" v-if="fields.search_by_name.city">
            <div class="form-group">
                <label for="city" class="form-label">{{ fields.search_by_name.city.label }}</label>
                <input type="text" class="form-control" maxlength="45" placeholder="New Alessiaview" name="memberLookupForm.city" v-model="memberLookupForm.city">
                <span class="text-sm text-danger">{{ errors.city }}</span>
            </div>
        </div>
        <div class="col-md-3" v-if="fields.search_by_name.state">
            <div class="form-group">
            <label for="state" class="form-label">{{ fields.search_by_name.state.label }}</label>
                <select class="form-control" id="state" name="memberLookupForm.state" v-model="memberLookupForm.state">
                    <option value="">Please select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.state }}</span>
            </div>
        </div>
        <div class="col-md-12 modal-searchbtn text-center">
            <div class="form-group">
            <button type="button" @click="memberSearch();" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
            </div>
            <div class="text-sm text-danger">{{ errors.not_found }}</div>
        </div>
        </div>
    </form>
</div>
<div>
<div v-if="memberSerchResultStatus == true" class="card-body primary-contact">
<div class="table-responsive">
<table class="table">
  <thead>
    <tr>
      <th scope="col">Member#</th>
      <th scope="col">First Name</th>
      <th scope="col">Last Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">City</th>
      <th scope="col">State</th>
      <th scope="col">Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(member, r) in memberSearchResults" :key="r">
      <th scope="row">{{ member.confirmation_code }}</th>
      <td>{{ member.personal_details.first_name }}</td>
      <td>{{ member.personal_details.last_name }}</td>
        <td>{{ member.contact_details.email }}</td>
        <td>{{ member.contact_details.phone_1 }}</td>
        <td>{{ member.contact_details.city }}</td>
      <td>{{ member.contact_details.state_code }}</td>
      <td>{{ member.membership_status_text }}</td>
      <td><button class="btn btn-primary btn-success" @click="pushSelectedMember(member)">Add</button></td>
    </tr>
  </tbody>
</table>
</div>
</div>
</div>

</b-modal>

</div>

</div>
        <div class="">
            <div class="col-md-12" v-show="overAllError != 0">
            <p class="text-danger text-right">Please fill the above missing fields.</p>
            </div>
            <div class="col-md-12">
                <div class="text-right topspace">
                    <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                    <button type="button" @click="ClubContactCheck" class="btn btn-outline-primary savebtn next-step-btn">Next<i class="fas fa-chevron-right pl-2"></i></button>
                </div>
            </div>
        </div>
</div>


</template>

<script>
import axios from 'axios';
import { VueTelInput } from 'vue-tel-input';

const validateName = name => {
    if (name == undefined || !name.length) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateZip = (zipCode, fieldName = '') => {
  let zipText= (fieldName != '' ? fieldName : "Zip code");
  if(zipCode == undefined || !zipCode.length || zipCode.trim() == "")
  {
    return { valid: false, error: zipText+" is required" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: zipText+" Should Be 5 Number Values for USA." };
  }
  return { valid: true, error: null };
};

const validateNonMandatoryZip = (zipCode, fieldName = '') => {
  let zipText= (fieldName != '' ? fieldName : "Zip code");
  if(zipCode != undefined && zipCode.length && zipCode.trim() != "" && !zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: zipText+" Should Be 5 Number Values for USA." };
  }
  return { valid: true, error: null };
};

const validateSelect = select => {
    console.log('lenghth='+select);
    if (select == undefined || !select.length) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validatePhone = (phone, validTelinput, phoneField= '' ) => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (!phone.length) {
        return { valid: false, error: phoneText+' is required .' };
    }
    if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
    }
    return { valid: true, error: null };
}

const validateEmail = (email, fieldEmail='') => {
    let emailText= (fieldEmail != '' ? fieldEmail : "This field");
    if (!email.length) {
        return { valid: false, error: emailText+" is required" };
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
        return { valid: false, error: "Please enter a valid email." };
    }
    return { valid: true, error: null };
};

export default {
    name: "PrimaryContact",
    props: ['fields'],
    data() {
        return{

            form: {
                "first_name": '',
                "last_name": '',
                "membership_id": '',
                "address1": '',
                "city": '',
                "state": '',
                "country": 'USA',
                "zip": '',
                "phone1": '',
                "phone2": '',
                "fax": '',
                "mobile": '',
                "email": '',
                "contact_type": '0',

                retype_email: "",
                club_address: "",
                club_city: "",
                club_state: "",
                club_zip: "",
                same_as_mailing_address: 0,
                club_physical_address: "",
                club_physical_city: "",
                club_physical_state: "",
                club_physical_zip: "",
                mobilephone: ""
            },
            button: {
                SearchButton: 'Search'
            },
            memberLookupForm: {
                member_id: '',
                first_name: '',
                last_name: '',
                city: '',
                state: ''
            },
            memberSerchResults: [],
            memberSerchResultStatus: false,
            primaryContactAdded: false,
            primaryContact: {
                membership_id: '',
                first_name: '',
                last_name: '',
                phone_1: '',
                phone_2: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                contact_type: 0,
            },
            valid: false,
            success: false,
            errors: {},
            message: null,
            contactAdded: false,

            validTelinput: false,
            mobileProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Mobile Number",
                maxLen: 20,
            },
            overAllError:0,
        }
    },
     methods: {
        memberSearch: function () {
            if(!this.validate()) {
                return;
            }
            console.log(this.memberLookupForm);
            this.button.SearchButton = 'Searching...';
            this.errors= {};
            axios.get(this.basePath+'api/member_lookup?member_id='+this.memberLookupForm.member_id+'&first_name='+this.memberLookupForm.first_name+'&last_name='+this.memberLookupForm.last_name+'&city='+this.memberLookupForm.city+'&state='+this.memberLookupForm.state)
                .then((res) => {
                    console.log('status='+res.data.status);
                    if(res.data.status == 'success') {
                        this.button.SearchButton = 'Search';
                        this.memberSerchResultStatus = true;
                        console.log(res.data.data);
                        console.log('-------------');
                        this.memberSearchResults = res.data.data;
                        console.log('-------1------');
                        console.log(this.memberSearchResults);
                        //Perform Success Action
                        //alert('success')
                        /*console.log('verify success');
                        this.verifyData = res.data.data;
                        //this.merchandiseDataApi();
                        //console.log(this.verifyData);
                        return res;*/
                    } else {
                        this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                        this.button.SearchButton = 'Search';
                        return false;
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                    this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                    //this.step--;
                    this.button.SearchButton = 'Search';
                    return false;
                    // error.response.status Check status code
                }).finally(() => {


            });
        },
         pushSelectedMember: function(member){
             console.log('----my m;embetd---------');
             console.log(member);
             console.log(this.primaryContactAdded);
             let memberContact = member.contact_details;
             this.primaryContactAdded = true;
             this.primaryContact.membership_id = member.confirmation_code;
             this.primaryContact.first_name = member.personal_details.first_name;
             this.primaryContact.last_name = member.personal_details.last_name;
             this.primaryContact.email = memberContact.email;
             this.primaryContact.address1 = memberContact.address1;
             this.primaryContact.address2 = memberContact.address2;
             this.primaryContact.city = memberContact.city;
             this.primaryContact.state = memberContact.state;
             this.primaryContact.state_code = memberContact.state_code;
             this.primaryContact.country = memberContact.country;
             this.primaryContact.zip = memberContact.zip;
             this.primaryContact.phone_1 = memberContact.phone_1;
             this.primaryContact.phone_2 = memberContact.phone_2;
             this.primaryContact.contact_type = 0;
             console.log(this.primaryContact);
             this.$emit('club-primary-data', this.primaryContact);
             this.valid = true;
             this.contactAdded = true;
             this.$nextTick(() => {
                 this.$bvModal.hide('modal-center-primary')
             })
             this.errors.primary = '';
             console.log("added="+this.primaryContactAdded);
         },
         ClubContactCheck: function(){
            this.errors = {}
            console.log('this contact check');
            console.log(this.valid);

            if(this.subdomain == "shooting"){
                if(this.shooting_validate()){
                    console.log(this.form);
                }
            } else {
                if(this.valid && this.contactAdded == true) {
                    console.log("1"+this.valid);
                    this.errors.primary = '';
                    this.$emit('next-step');
                    return;
                } else {
                    console.log("2"+this.valid);
                    this.errors.primary = 'Primary contact is required!';
                }
            }
         },
         removePrimaryContact() {
             console.log('ddddd');
             this.primaryContactAdded = false;
             this.primaryContact = {};
             this.$emit('club-primary-data', this.primaryContact);
             this.valid = false;
             this.contactAdded = false;
         },
         resetModal() {
            this.memberSerchResultStatus = false;
             this.memberSearchResults = [];
             this.memberLookupForm = {}
         },
         previousStep() {
             this.$emit('previous-step');
             return;
         },
         changeSameAddress(event){
             if(this.form.same_as_mailing_address){
                 this.form.club_physical_address = this.form.club_address;
                 this.form.club_physical_city = this.form.club_city;
                 this.form.club_physical_state = this.form.club_state;
                 this.form.club_physical_zip = this.form.club_zip;
             }
         },
         shooting_validate(){
            this.errors = {}   
            this.overAllError = 1;         
            const validEmail = validateEmail(this.form.email,"E-Mail");
            this.errors.email = validEmail.error;

            const validRetypeEmail = validateEmail(this.form.retype_email, "Retype E-Mail");
            this.errors.retype_email = validRetypeEmail.error;

            const validAddress1 = validateName(this.form.club_address, "Club Address");
            this.errors.club_address = validAddress1.error;

            const validCity = validateName(this.form.club_city,"City");
            this.errors.club_city = validCity.error;

            const validState = validateSelect(this.form.club_state,"State");
            this.errors.club_state = validState.error;

            const validZip = validateZip(this.form.club_zip,"Zip/Postal Code");
            this.errors.club_zip = validZip.error;

           const validPhysicalZip = validateNonMandatoryZip(this.form.club_physical_zip,"Zip/Postal Code");
           this.errors.club_physical_zip = validPhysicalZip.error;

            const validPhone1 = validatePhone(this.form.mobilephone, this.validTelinput);
            this.errors.mobilephone = validPhone1.error;

            if(
                this.errors.email == null && 
                this.errors.retype_email == null && 
                this.errors.club_address == null && 
                this.errors.club_city == null && 
                this.errors.club_state == null && 
                this.errors.club_zip == null && 
                this.errors.mobilephone == null &&
                this.errors.club_physical_zip == null
            ){
                if(this.form.email != this.form.retype_email) {
                    this.errors.retype_email = 'The email id does not match';
                    return;
                }

                let data = {};
                data.email = this.form.email;
                data.club_address = this.form.club_address;
                data.club_city = this.form.club_city;
                data.club_state = this.form.club_state;
                data.club_zip = this.form.club_zip;
                data.club_physical_address = this.form.club_physical_address;
                data.club_physical_city = this.form.club_physical_city;
                data.club_physical_state = this.form.club_physical_state;
                data.club_physical_zip = this.form.club_physical_zip;
                data.mobilephone = this.form.mobilephone;

                this.$emit('club-contact-info', data);
                this.overAllError = 0;
                return;
            }
         },
         // Custom Validation Rules
         validate() {
             this.errors = {}            
                console.log('-------------');
                console.log(this.memberLookupForm);
             const member_id = this.memberLookupForm.member_id;
             const first_name = this.memberLookupForm.first_name;
             const last_name = this.memberLookupForm.last_name;
             const city = this.memberLookupForm.city;
             const state = this.memberLookupForm.state;
             if(member_id == undefined && first_name == undefined && last_name == undefined && city == undefined && state == undefined) {
                 this.errors.member_id = 'Plesse Enter Either Member Id or Below Member Details';

                 return this.valid;
             }
             if(member_id == undefined && (first_name == undefined || last_name == undefined || city == undefined || state == undefined)) {
                 const validFirstName = validateName(this.memberLookupForm.first_name);
                 this.errors.first_name = validFirstName.error;

                 const validLastName = validateName(this.memberLookupForm.last_name);
                 this.errors.last_name = validLastName.error;

                 const validCity = validateSelect(this.memberLookupForm.city);
                 this.errors.city = validCity.error;

                 const validStateCode = validateSelect(this.memberLookupForm.state);
                 this.errors.state = validStateCode.error;

                 return this.valid;
             }

             this.valid = true;
             this.errors= {}             

             return this.valid;
         },
         validateTelinput(obj){            
            this.validTelinput = obj.valid;
            console.log(obj);
            if(obj.valid){
                this.errors.mobilephone = '';
            }
        }
     }

}
</script>

<style scoped>

</style>
