<template>
  <aside
      class="main-sidebar sidebar-dark-primary">
    <div class="sidebar-logo">
      <a href="#" class="brand-link">
        <img
            class="padding_top"
            style="opacity: 1;"
            :src="'/' + this.getSiteProps('clubdashboard.logo')"
            v-if="'/' + this.getSiteProps('clubdashboard.logo')"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
    </div>
    <div class="sidebar pl-0 pr-0">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex align-items-baseline">
        <div class="image">
          <img :src="getClubLogoImg()" alt="User Image" class="img-circle" />
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ clubInfoData.name }}</a>
        </div>
      </div>
      <!-- <sidebar-menu
          :menu="clubmenu"
          :collapsed="collapsed"
          theme="default-theme"
          :show-one-child="true"
          @toggle-collapse="onToggleCollapse"
          @item-click="onItemClick"
          relative         
          :key="clubmenu"
        >
        </sidebar-menu> -->

<!--      <nav class="mt-2">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
          <li class="nav-item has-treeview ">
            <router-link class="nav-link" to="/club/dashboard">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/club/clubmyprofile">
              <i class="nav-icon far fa-address-card"></i>
              <p>
                Club Details
              </p>
            </router-link>
          </li>
                            
                              <li class="nav-item has-treeview">
                                  <router-link class="nav-link" to="/club/clubroaster">
                                  <i class="nav-icon fas fa-chevron-circle-right"></i>
                                  <p>Club Member Roster</p>
                                  </router-link>
                              </li>
                              <template v-if="subdomain != 'shooting'">
                              <li class="nav-item has-treeview">
                                  <router-link class="nav-link" to="/club/club-guest-roaster">
                                  <i class="nav-icon fas fa-chevron-circle-right"></i>
                                  <p>Club Member Guest Roster</p>
                                  </router-link>
                              </li>
                              <li class="nav-item has-treeview">
                                  <router-link class="nav-link" to="/club/club-official-roster">
                                  <i class="nav-icon fas fa-table"></i>
                                  <p>Club Officials Roster</p>
                                  </router-link>
                              </li>
                              <li class="nav-item has-treeview">
                                  <router-link class="nav-link" to="/club/clubofficials">
                                      <i class="nav-icon fas fa-chevron-circle-right"></i>
                                      <p>Club Leadership</p>
                                  </router-link>
                              </li>
                            </template>
          <li class="nav-item has-treeview">
            <router-link class="nav-link" to="/club/membership">
              <i class="nav-icon fas fa-table"></i>
              <p>Club Membership History</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link class="nav-link" to="/club/preference">
              <i class="nav-icon fas fa-table"></i>
              <p>Preferences</p>
            </router-link>
          </li>
          <template v-if="subdomain != 'shooting'">
            <li class="nav-item has-treeview">
              <a class="nav-link" :href="sanctioningUrl" target="_blank" title="Event Sanctioning">
                <i class="nav-icon fas fa-table"></i>
                <p>Event Sanctioning</p>
              </a>
            </li>
          </template>
        </ul>
      </nav>-->

      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item" v-for="(pm,k1) in menuItems" :key="k1">
            <template v-if="pm.href">
              <a v-if="pm.external" :href="pm.href" target="_blank" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]" v-if="pm.icon"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
                <img v-if="pm.badge && pm.badge.element && pm.badge.element == 'img'" :src="pm.badge.attributes.src" :class="pm.badge.class">
              </a>
              <a class="nav-link" :href="sanctioningUrl" target="_blank" title="Event Sanctioning" v-else-if="pm.sso">
                <i class="nav-icon fas fa-table"></i>
                <p>Event Sanctioning</p>
              </a>
              <router-link :to="pm.href" :class="['nav-link', pm.class || '']" active-class="active" v-else>
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </router-link>
            </template>
            <template v-else>
              <a href="#" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </a>
            </template>
            <ul class="nav nav-treeview" v-if="pm.child">
              <li class="nav-item" v-for="(pmc,k2) in pm.child" :key="k2">
                <a
                    v-if="pmc.external"
                    :href="pmc.form_post == undefined || pmc.form_post == false ? pmc.href : 'javascript:void(0);'"
                    :data-href="pmc.form_post != undefined && pmc.form_post == true ? pmc.href : ''"
                    :target="pmc.form_post == undefined || pmc.form_post == false ? '_blank' : '_self'"
                    @click="linkPost(pmc.href, pmc.form_post)"
                    :class="['nav-link', pmc.class || '']">
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                </a>
                <router-link :to="pmc.href" :class="['nav-link', pmc.class || '']" active-class="active" v-else>
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
// import { SidebarMenu } from "vue-sidebar-menu";
import moment from "moment";
import axios from "axios";

export default {
  name: "clubsidebar",
  // components: {
  // SidebarMenu,
  //  },
  data() {
    return {
      clubInfoData: [],
      menuItems: [],
      sanctioningUrl: "#",
      clubmenu: [
        {
          href: "/club/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/club/clubmyprofile",
          title: " Club Details",
          icon: "fas fa-address-card",
        },
        {
          href: "/club/clubroaster",
          title: "Club Member Roster",
          icon: "fas fa-chevron-circle-right",
        },
        {
          href: "/club/guest-roster",
          title: "Club Guest Roster",
          icon: "fas fa-chevron-circle-right",
          permission: ['waterski']
        },
        {
          href: "/club/club-official-roster",
          title: "Club Officials Roster",
          icon: "fas fa-table",
          permission: ['waterski']
        },
        {
          href: "/club/clubofficials",
          title: "Club Leadership",
          icon: "fas fa-address-card",
          permission: ['waterski']
        },
        {
          href: "/club/membership",
          title: "Club Membership History",
          icon: "fas fa-table",
        },
        {
          href: "/club/preference",
          title: "Preference",
          icon: "fas fa-table",
        },
        {
          href: "sanctioningUrl",
          title: "Event Sanctioning",
          icon: "fas fa-table",
          permission: ['waterski'],
          sso: true
        },
      ],
      alliancefastpitch:{
        clubmenu: [
          {
            href: "/team/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
          },
          {
            href: "/team/teammyprofile",
            title: "Team Details",
            icon: "fas fa-address-card",
          },
          {
            href: "/team/staff",
            title: "Staff/Coach",
            icon: "fas fa-table",
          },
          {
            href: "/team/teamroaster",
            title: "Team Member Roster",
            icon: "fas fa-chevron-circle-right",
          },
          {
            href: "/team/membership",
            title: "Team Membership History",
            icon: "fas fa-table",
          },
          {
            href: "/team/preference",
            title: "Preference",
            icon: "fas fa-table",
          },{
            href: "/team/player_transfer_request",
            title: "Player Transfer Request",
            icon: "fas fa-rocket",
          },
          {
            href: "/team/insurance",
            title: "Insurance",
            icon: "fas fa-file",
          },
        ],
      },
      partnersStatus:false
    };
  },
  methods: {
    getClubLogoImg() {
      return (
          (this.clubInfoData.logo != null
              ? process.env.VUE_APP_URL + this.clubInfoData.logo
              : "/dist/img/avatarimg.png") +
          "?v=" +
          moment().format("x")
      );
    },
    onToggleCollapse(collapsed) {
      console.log(collapsed);
      this.collapsed = collapsed;
    },
    onItemClick(event, item, node) {
      console.log("onItemClick");
    },
    partnerStatus(){
      axios.get(this.basePath+'api/partners/getPartnerStatus',{ headers:this.memberHeaders })
          .then(function (response) {
            if (response.data.status == 'success') {
              this.partnersStatus = response.data.data;
            }
            else
              console.log(response);
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    partnerMenu(){
      let clubmenu = {
        href: "/team/partners",
        title: "Partners",
        icon: "fas fa-handshake",
        permission: "partners"
      };
      console.log(this.menuItems);
      this.menuItems.push(clubmenu);
      console.log(this.menuItems);
    }
  },
  mounted() {
    this.clubInfoData = JSON.parse(localStorage.getItem("team"));
    this.sanctioningUrl = localStorage.getItem("ssoToken");
    if(this.subdomain == "alliancefastpitch") this.menuItems = this.alliancefastpitch.clubmenu;
    else this.menuItems = this.clubmenu.filter(item => {
      return item.permission != undefined ? (item.permission.includes(this.subdomain) ? true : false) : true;
    });
    this.partnerStatus();
  },
  watch :{
    partnersStatus:function (val){
      this.partnersStatus = val;
      if(this.partnersStatus==true)
        this.partnerMenu();
    }
  }
};
</script>

<style scoped>
.v-sidebar-menu.vsm_expanded {
  max-width: 250px !important;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: transparent;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px hsla(0, 0%, 100%, 0.1) inset;
  box-shadow: 3px 0px 0px 0px hsl(0deg 0% 100% / 10%) inset;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
}

.v-sidebar-menu .vsm--icon {
  background-color: transparent !important;
}
.vsm--dropdown .vsm--list {
  padding-left: 0 !important;
}
.v-sidebar-menu .vsm--dropdown .vsm--list .vsm--item a {
  padding-left: 20px !important;
}
.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #b33132 !important;
  color: #fff;
}
/* .nav-sidebar a.nav-link.router-link-exact-active.router-link-active {
    font-weight: 700;
    color: #fff;
    border-left: 3px solid #FF0000;
    border-radius: 0;
} */
</style>


