<template>
    <form @submit.prevent="demographicData" method="post">
    <div class="card demographic-info">
       <div class="card-header register-title">
          <h3>{{ fields.tab_title || 'Demographic Information'}}  <b-button v-if="fields.tooltip" v-b-tooltip.hover :title="fields.tooltip"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></b-button></h3>
       </div>
       <div class="card-body border-none pl-4 pr-4">
          <div class="row">
             <div :class="[fields.race.colClass]" v-if="fields.race&&membership.id !=11">
                <div class="form-group radiotext">
                <label for="race" class="form-label-check"><strong>{{ fields.race.label }}</strong> <sup class="text-danger" v-if="fields.race.required">*</sup></label> <br>
                    <div v-for="diversity in diversities" :key="diversity.name">
                        <label :for="'diversity_id'+diversity.id">
                          <input type="radio" name="form.diversity_id" :value="diversity.id"
                                 :id="'diversity_id'+diversity.id"
                                 v-model="form.diversity_id">
                            {{diversity.name}}</label> <br>
                    </div>
                    <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                </div>
             </div>

             <div :class="[fields.ethnicity.colClass]" v-if="fields.ethnicity">
                <div class="form-group radiotext">
                   <label for="ethnicitystatus" class="form-label-check"><strong>{{ fields.ethnicity.label }}</strong><sup  class="text-danger" v-if="fields.ethnicity.required">*</sup></label>
                   <div class="form-group" v-if="fields.ethnicity.choices">
                       <template v-for="(choice, k) in fields.ethnicity.choices">
                            <input type="radio" :id="choice.id" name="ethnicity" :value="choice.label" v-model="form.ethnicity" :key="'ethnicity_'+k">
                            <label :for="choice.id" :key="'ethnicity_label_'+k">{{ choice.label }}</label>
                            <br :key="'ethnicity_br_'+k">
                       </template>
                       <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                   </div>
                </div>
             </div>


             <div :class="[fields.disability_status.colClass]" v-if="fields.disability_status">
                <div class="form-group radiotext">
                   <label for="disabilitystatus" class="form-label-check"><strong>{{ fields.disability_status.label }}</strong> <sup  class="text-danger" v-if="fields.disability_status.required">*</sup></label>
                   <div class="form-group" v-if="fields.disability_status.choices">
                       <template v-for="(choice, k) in fields.disability_status.choices">
                            <input type="radio" :id="choice.id" name="disability" :value="choice.label" v-model="form.disability" :key="'ds_'+k">
                            <label :for="choice.id" :key="'ds_label_'+k">{{ choice.label }}</label>
                            <br :key="'ds_br_'+k">
                       </template>
                       <span class="text-sm text-danger">{{ errors.disability }}</span>
                   </div>
                </div>
             </div>            
             <template v-if="age > 18">
                <div :class="[fields.veteran_status.colClass]" v-if="fields.veteran_status">
                    <div class="form-group radiotext">
                    <label for="veteranstatus" class="form-label-check"><strong>{{ fields.veteran_status.label }}</strong><sup class="text-danger" v-if="fields.veteran_status.required">*</sup></label>
                    <div class="form-group" v-if="fields.veteran_status.choices">
                        <template v-for="(choice, k) in fields.veteran_status.choices">
                                <input type="radio" :id="choice.id" name="veteran_status" :value="choice.label" v-model="form.veteran_status" :key="'vs_'+k">
                                <label :for="choice.id" :key="'vs_label_'+k">{{ choice.label }}</label>
                                <br :key="'vs_br_'+k">
                        </template>
                        <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                    </div>
                    </div>
                </div>
             </template>
             <div :class="[fields.primary_area_interest.colClass]" v-if="fields.primary_area_interest">
                    <div class="form-group">
                        <label for="area_of_interest" class="form-label">{{ fields.primary_area_interest.label }}<sup class="text-danger" v-if="fields.primary_area_interest.required">*</sup></label>
                        <select name="form.area_of_interest" id="area_of_interest" class="form-control" v-model="form.primary_area_interest">
                            <option value="">Select</option>
                            <option v-for="(item, k) in fields.primary_area_interest.options" :key="'pai'+k" :value="item">{{ item }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.primary_area_interest }}</span>
                    </div>
                </div>
                <div :class="[fields.category.colClass]" v-if="fields.category">
                    <div class="form-group">
                        <label for="category" class="form-label">{{ fields.category.label }}<sup class="text-danger" v-if="fields.category.required">*</sup></label>
                        <select name="form.category" id="category" class="form-control" v-model="form.category" @change="updateCategoryName($event)">
                            <option value="">Select</option>
                            <!-- <option v-for="(item, k) in fields.category.options" :key="'catd-'+k" :value="item">{{ item }}</option> -->
                            <option v-for="(item, k) in categories" :key="'catd-'+k" :value="item.id">{{ item.name }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.category }}</span>
                    </div>
                </div>
                <div :class="[fields.issf.colClass]" v-if="fields.issf">
                    <div class="form-group">
                        <label for="issf" class="form-label">{{ fields.issf.label }}<sup class="text-danger" v-if="fields.issf.required">*</sup></label>
                        <input type="text" class="form-control" name="form.issf" v-model="form.issf">
                        <span class="text-sm text-danger">{{ errors.issf }}</span>
                    </div>
                </div>
                <div :class="[fields.cmp.colClass]" v-if="fields.cmp">
                  <div class="form-group">
                    <label for="cmp" class="form-label">{{ fields.cmp.label }}<sup class="text-danger" v-if="fields.cmp.required">*</sup></label>
                    <input type="text" class="form-control" name="form.cmp_no" v-model="form.cmp_no">
                    <span class="text-sm text-danger">{{ errors.cmp_no }}</span>
                  </div>
                </div>
                <div :class="[fields.nra.colClass]" v-if="fields.nra">
                  <div class="form-group">
                    <label for="nra" class="form-label">{{ fields.nra.label }}<sup class="text-danger" v-if="fields.nra.required">*</sup></label>
                    <input type="text" class="form-control" name="form.nra_no" v-model="form.nra_no">
                    <span class="text-sm text-danger">{{ errors.nra_no }}</span>
                  </div>
                </div>
                <div :class="[fields.sasp.colClass]" v-if="fields.sasp">
                  <div class="form-group">
                    <label for="sasp" class="form-label">{{ fields.sasp.label }}<sup class="text-danger" v-if="fields.sasp.required">*</sup></label>
                    <input type="text" class="form-control" name="form.sasp_no" v-model="form.sasp_no">
                    <span class="text-sm text-danger">{{ errors.sasp_no }}</span>
                  </div>
                </div>
                <div :class="[fields.sctp.colClass]" v-if="fields.sctp">
                  <div class="form-group">
                    <label for="sctp" class="form-label">{{ fields.sctp.label }}<sup class="text-danger" v-if="fields.sctp.required">*</sup></label>
                    <input type="text" class="form-control" name="form.sctp_no" v-model="form.sctp_no">
                    <span class="text-sm text-danger">{{ errors.sctp_no }}</span>
                  </div>
                </div>
                <div :class="[fields.hear_about.colClass]" v-if="fields.hear_about">
                    <div class="form-group">
                        <label for="hear_about" class="form-label">{{ fields.hear_about.label }} <sup class="text-danger" v-if="fields.hear_about.required">*</sup></label>
                        <select id="hear_about" class="form-control" name="form.hear_about" v-model="form.hear_about">
                            <option value="">Select</option>
                            <option v-for="(item, k) in fields.hear_about.options" :key="'hear-about-'+k" :value="item">{{ item }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.hear_about }}</span>
                    </div>
                </div>
                <div :class="[fields.shirt_size.colClass]" v-if="fields.shirt_size && fields.shirt_size.visible">
                    <div class="form-group">
                        <label for="shirt_size" class="form-label">{{ fields.shirt_size.label }} <sup class="text-danger" v-if="fields.shirt_size.required">*</sup></label>
                        <select id="shirt_size" class="form-control" name="form.shirt_size" v-model="form.shirt_size">
                            <option value="">Select</option>
                            <option v-for="(item, k) in fields.shirt_size.options" :key="'shirt-size-'+k" :value="item">{{ item }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.shirt_size }}</span>
                    </div>
                </div>
                <div :class="[fields.code_of_conduct.colClass]" v-if="fields.code_of_conduct && fields.code_of_conduct.visible" id="code_of_conduct">
                    <div class="form-group">
                        <label for="code_of_conduct" class="form-label">{{ fields.code_of_conduct.label }}<sup class="text-danger" v-if="fields.code_of_conduct.required">*</sup></label>
                        <br>
                        <div class="form-check-inline">
                            <input class="form-check-input" type="radio" id="code_of_conduct_yes" name="form.code_of_conduct" value="1" v-model="form.code_of_conduct" @change="updateCodeConduct($event)">
                            <label class="form-check-label" for="code_of_conduct_yes">Yes</label>
                        </div>
                        <div class="form-check-inline">
                            <input class="form-check-input" type="radio" id="code_of_conduct_no" name="form.code_of_conduct" value="0" v-model="form.code_of_conduct" @change="updateCodeConduct($event)">
                            <label class="form-check-label" for="code_of_conduct_no">No</label>
                        </div>
                        <br>
                        <span class="text-sm text-danger">{{ errors.code_of_conduct }}</span>
                    </div>
                </div>
          </div>
       </div>
        <div class="col-md-12" v-show="overAllError != 0">
            <p class="text-danger text-right">Please fill the above missing fields.</p>
        </div>
        <div class="col-md-12">
            <div class="text-right topspace">
                <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                <button type="submit" class="btn btn-outline-primary savebtn next-step-btn">Next<i class="fas fa-chevron-right pl-2"></i></button>
            </div>
        </div>
    </div>
    </form>
</template>

<script>
import axios from 'axios';

const validateName = name => {
    if (!name.length) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateRadio =(radio, fieldTxt)  => {    
    if (!radio) {        
        return { valid: false, error: fieldTxt +' is required .'};
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    if (!select) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateEmail = email => {
    if (!email.length) {
        return { valid: false, error: "This field is required" };
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
        return { valid: false, error: "Please enter a valid email." };
    }
    return { valid: true, error: null };
};
export default {
    name: "demographicinformation",
    props: ['diversities', 'fields', 'categories', 'membership', 'age'],
    data() {
        return {
            form: {
                diversity_id: '',
                ethnicity: '',
                disability: '',
                veteran_status: '',
                primary_area_interest: '',
                category: '',
                category_name: '',
                hear_about: '',
                issf: '',
                cmp_no: '',
                nra_no: '',
                sasp_no: '',
                sctp_no: '',
                shirt_size: '',
                code_of_conduct: '',
                code_of_conduct_label: 'No'
            },
            id:'',
            valid: false,
            success: false,
            errors: {},            
            message: null,
            overAllError: 0,
        }
    },
    methods: {
        demographicData() {
            if(this.validate()) {
                console.log('valid');
                this.$emit('demographic-data', this.form);
                return;
            } else {
                console.log('un valid');
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        // Custom Validation Rules
        validate() {
            this.errors = {}
            this.valid = false;
             this.overAllError = 1;
            const validDiversity = validateRadio(this.form.diversity_id,"Race");
            this.errors.diversity_id = validDiversity.error;
            
            const validEthnicity = validateRadio(this.form.ethnicity, "Ethnicity");
            this.errors.ethnicity = validEthnicity.error;
           
            const validDisability = validateRadio(this.form.disability, "Disability Status");
            this.errors.disability = validDisability.error;   
            
            const validVeteranStatus = validateRadio(this.form.veteran_status, "Veteran Status");
            this.errors.veteran_status = validVeteranStatus.error;
            
            if(document.getElementsByName('ethnicity').length == 0) validEthnicity.valid = true;
            if(document.getElementsByName('disability').length == 0) validDisability.valid = true;

            if(document.getElementsByName('veteran_status').length == 0 || this.age <= 18) validVeteranStatus.valid = true;
            
            // if (this.membership.id != 2 && this.membership.id != 3) {
            //     if (validVeteranStatus.valid) {  
            //         this.valid = true;
            //     }
            // }

            if (validDiversity.valid && validEthnicity.valid && validDisability.valid && validVeteranStatus.valid) {
                this.valid = true;                
                this.overAllError= 0;
            }

            if(document.querySelector('.demographic-info #area_of_interest') != null){                
                const validPrimaryAreaInterest = validateSelect(this.form.primary_area_interest);
                this.errors.primary_area_interest = validPrimaryAreaInterest.error;
                if(!validPrimaryAreaInterest.valid){
                this.valid = false;
                this.overAllError= 1;
                } 
            }

            if(document.querySelector('.demographic-info #category') != null){
                const validCategory = validateSelect(this.form.category.toString());
                this.errors.category = validCategory.error;
                if(!validCategory.valid){
                this.valid = false;
                this.overAllError= 1;
                } 
            }

            if(document.querySelector('.demographic-info #hear_about') != null){
                const validHearAbout = validateSelect(this.form.hear_about.toString());
                this.errors.hear_about = validHearAbout.error;
                if(!validHearAbout.valid){
                this.valid = false;
                this.overAllError= 1;
                }
            }

            const validShirtSize = validateSelect(this.form.shirt_size);
            this.errors.shirt_size = validShirtSize.error;

            const validCodeConduct = validateRadio(this.form.code_of_conduct.toString());
            this.errors.code_of_conduct = validCodeConduct.error;

            var flag = "";
            if(!validShirtSize.valid && document.querySelector('.demographic-info #shirt_size') != null){
                flag = "stop";
            }
            if(!validCodeConduct.valid && document.querySelector('.demographic-info #code_of_conduct') != null){
                flag = "stop";
            }

            if(flag == "stop"){
            this.valid = false;
            this.overAllError= 1;
            } 
            
            return this.valid;
           
        },
        getDiversity()
        {
            axios.get(process.env.VUE_APP_URL+"api/getDiversity")
                .then(response => {
                    this.diversities = response.data.data;
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        updateCategoryName(event){
            this.form.category_name = event.target.options[event.target.options.selectedIndex].text;
        },
        updateCodeConduct(event){
            this.form.code_of_conduct_label = event.target.value ? 'Yes' : 'No';
        }
    }
}
</script>
