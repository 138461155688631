<template>
<div>
  <aside class="main-sidebar sidebar-default elevation-4">
    <div class="sidebar-logo">
      <a href="#" class="brand-link">
        <img :src="this.getSiteProps('admin.logo')" alt="Logo" style="opacity: 1">
        <span class="brand-text font-weight-bolder"></span>
      </a>
    </div>

    <div class="sidebar sidebarscorll">
      <nav class="mt-2 pt-3 pb-3 ">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
          <li class="nav-item" v-for="(pm,k1) in menuItems" :key="k1">
            <template v-if="pm.href">
              <a v-if="pm.external" :href="pm.href" target="_blank" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]" v-if="pm.icon"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
                <img v-if="pm.badge && pm.badge.element && pm.badge.element == 'img'" :src="pm.badge.attributes.src" :class="pm.badge.class">
              </a>
              <router-link :to="pm.href" :class="['nav-link', pm.class || '']" active-class="active" v-else>
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </router-link>
            </template>
            <template v-else>
              <a href="#" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </a>
            </template>
            <ul class="nav nav-treeview" v-if="pm.child">
              <li v-for="(pmc,k2) in pm.child" :key="k2" class="nav-item child" @click="childClose(pmc.subChild)">
                <a v-if="pmc.external" :href="pmc.href" target="_blank" :class="['nav-link', pmc.class || '']">
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                  <i class="right child fas fa-angle-left" v-if="pmc.subChild"></i>
                </a>
                <router-link :to="pmc.href" :class="['nav-link', pmc.class || '']" active-class="active" v-else>
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                  <i class="right child fas fa-angle-left" v-if="pmc.subChild"></i>
                </router-link>
                <ul class="nav nav-treeview sub-child" v-if="pmc.subChild">
                  <li class="nav-item" style="margin-left: 20px" v-for="(pmcs,k3) in pmc.subChild" :key="k3" @click="subChild()">
                    <a v-if="pmc.external" :href="pmcs.href" target="_blank" :class="['nav-link', pmcs.class || '']">
                      <i class="far fa-circle nav-icon"></i>
                      <p>{{ pmcs.title }}</p>
                    </a>
                    <router-link :to="pmcs.href" :class="['nav-link', pmcs.class || '']" active-class="active" v-else>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{{ pmcs.title }}</p>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>

</div>
</template>

<script>
import $ from 'jquery';
// import { SidebarMenu } from "vue-sidebar-menu";
// import sidebarlogo from "./SidebarLogo.vue";

export default {
  name: "Sidebar",
  components: {
    // SidebarMenu,
    // sidebarlogo,
  },

  data() {
    return {
      adminInfoData: "",
      role: 0,
      menu1: [
        {
          href: "/admin/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "Registrations",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/registrations",
              title: "Individual",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/family-registrations",
              title: "Family",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club-registrations",
              title: "Club",
              icon: "far fa-circle",
            },
            {
              href: "/admin/ambassador-registrations",
              title: "Ambassador",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations",
              title: "Camp/Ski School",
              icon: "far fa-circle",
            },
          ],
        },
        {
          href: process.env.VUE_APP_WATERSKI_EMS_URL,
          external: true,
          title: "Events & Clinics",
          icon: "fas fa-child ",
        },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL,
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },
        {
          href: "/admin/merchandise",
          title: "Merchandise",
          icon: "fas fa-dolly",
        },
        {
          title: "Groups",
          icon: "fas fa-users",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/group-types",
              title: "Group Types",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups",
              title: "Groups",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-position",
              title: "Positions",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-member",
              title: "Members",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          title: "Reports",
          icon: "fas fa-chart-pie",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/reports/member-registrations",
              title: "Membership Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club/reports",
              title: "Club Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/reports/bgscreening",
              title: "BG Screening Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/mvr",
              title: "MVR Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations/reports",
              title: "Camp/Ski School Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/financials",
              title: "Financials Report",
              icon: "far fa-circle",
            },
            // {
            //   href: "/admin/financials-membership",
            //   title: "Membership Financials Reports",
            //   icon: "far fa-circle",
            // },
            // {
            //   href: "/admin/financials-club",
            //   title: "Club Financials Reports",
            //   icon: "far fa-circle",
            // },
            {
              href: "/admin/reports/donation",
              title: "Donations",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/club_insurance",
              title: "Club Insurance",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/insurance_certificate",
              title: "Insurance Certificate",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-accounting-report",
              title: "Merchandise Accounting",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-fulfillment-report",
              title: "Merchandise Fulfillment",
              icon: "far fa-circle",
            },
            {
              href: "/admin/report/summary-report",
              title: "Summary Reports",
              icon: "far fa-circle",
            },
          ],
        }
      ],
      menu2: [
        {
          href: "/admin/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "Registrations",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/registrations",
              title: "Individual",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/family-registrations",
              title: "Family",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club-registrations",
              title: "Club",
              icon: "far fa-circle",
            },
            {
              href: "/admin/ambassador-registrations",
              title: "Ambassador",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations",
              title: "Camp/Ski School",
              icon: "far fa-circle",
            },
          ],
        },
        {
          title: "Groups",
          icon: "fas fa-users",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/group-types",
              title: "Group Types",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups",
              title: "Groups",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-position",
              title: "Positions",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-member",
              title: "Members",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          title: "Reports",
          icon: "fas fa-chart-pie",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/reports/member-registrations",
              title: "Membership Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/club/reports",
              title: "Club Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/reports/financials",
              title: "Financials Report",
              icon: "far fa-circle",
            },
            // {
            //   href: "/admin/financials-membership",
            //   title: "Membership Financials Reports",
            //   icon: "far fa-circle",
            // },
            // {
            //   href: "/admin/financials-club",
            //   title: "Club Financials Reports",
            //   icon: "far fa-circle",
            // },
            {
              href: "/admin/reports/donation",
              title: "Donations",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/club_insurance",
              title: "Club Insurance",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/insurance_certificate",
              title: "Insurance Certificate",
              icon: "far fa-circle",
            },
            {
              href: "/admin/report/summary-report",
              title: "Summary Reports",
              icon: "far fa-circle",
            },
          ],
        }
      ],
      collapsed: false,
      menuItems: [],
      menu: [
        {
          href: "/admin/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
          meta: "forAuth",
        },
        {
          title: "Registrations",
          icon: "fas fa-address-card",
          class: "registrations-list-menu",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/registrations",
              title: "Individual",
              icon: "far fa-circle ",
              class: "individual-registrations-menu",
            },
            {
              href: "/admin/family-registrations",
              title: "Family",
              icon: "far fa-circle ",
              class: "family-registrations-menu",
            },
            {
              href: "/admin/club-registrations",
              title: "Club",
              icon: "far fa-circle",
              class: "club-registrations-menu",
            },
            {
              href: "/admin/ambassador-registrations",
              title: "Ambassador",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations",
              title: "Camp/Ski School",
              icon: "far fa-circle",
              class: "campski-registrations-menu",
            },
            {
              href: "/admin/digital-registrations",
              title: "Digital Membership",
              icon: "far fa-circle",
            }

          ],
        },
        // {
        //   href: "#",
        //   title: "Members",
        //   icon: "fas fa-address-card",
        //   child: [
        //     {
        //       href: "#",
        //       title: "External Links",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "#",
        //       title: "Registration",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "#",
        //       title: "Waiver",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "#",
        //       title: "Insurance",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "#",
        //       title: "SafeSport",
        //       icon: "far fa-circle",
        //     },
        //   ],
        // },
            {
          title: "Reports",
          icon: "fas fa-chart-pie",
           hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/reports/member-registrations",
              title: "Membership Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/club/reports",
              title: "Club Reports",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/reports/bgscreening",
              title: "BG Screening Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/mvr",
              title: "MVR Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/campski-registrations/reports",
              title: "Camp/Ski School Reports",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/financials",
              title: "Financials Report",
              icon: "far fa-circle",
            },
            // {
            //   href: "/admin/financials-membership",
            //   title: "Membership Financials Reports",
            //   icon: "far fa-circle",
            // },
            // {
            //   href: "/admin/financials-club",
            //   title: "Club Financials Reports",
            //   icon: "far fa-circle",
            // },
            {
              href: "/admin/reports/donation",
              title: "Donations",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/club_insurance",
              title: "Club Insurance",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/insurance_certificate",
              title: "Insurance Certificate",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-accounting-report",
              title: "Merchandise Accounting",
              icon: "far fa-circle",
            },
            {
              href: "/admin/reports/merchandise-fulfillment-report",
              title: "Merchandise Fulfillment",
              icon: "far fa-circle",
            },
            {
              href: "/admin/report/summary-report",
              title: "Summary Reports",
              icon: "far fa-circle",
            },
          ],
        },
        {
          href: process.env.VUE_APP_WATERSKI_EMS_URL,
          external: true,
          title: "Events & Clinics",
          icon: "fas fa-child ",
        },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL,
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },
        {
          href: "/admin/merchandise",
          title: "Merchandise",
          icon: "fas fa-dolly",
        },
        {
          href: "/admin/coupons",
          title: "Coupons",
          icon: "fas fa-money-bill nav-icon",
        },
        {
          href: "/admin/member-type",
          title: "Member Types",
          icon: "fas fa-address-card",
        },
        {
          title: "Pricing",
          icon: "fas fa-dollar-sign",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/membership-pricing",
              title: "Membership Pricing",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/other-pricing",
              title: "Other Pricing",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          title: "Groups",
          icon: "fas fa-users",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/group-types",
              title: "Group Types",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups",
              title: "Groups",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-position",
              title: "Positions",
              icon: "fas fa-users nav-icon",
            },
            {
              href: "/admin/groups-member",
              title: "Members",
              icon: "fas fa-users nav-icon",
            }
          ]
        },
        {
          href: "/admin/seasons",
          hidden: true,
          title: "Seasons",
          icon: "fas fa-calendar",
        },
        // {
        //   href: "#",
        //   title: "Settings",
        //   icon: "fas fa-tools",
        //   hidden: this.role == 1,
        //   child: [
        //     {
        //       href: "/",
        //       title: "CMS",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "/",
        //       title: "Coupons",
        //       icon: "far fa-circle ",
        //     },
        //     {
        //       href: "/",
        //       title: "Donations",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "/",
        //       title: "Merchandize",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "/",
        //       title: "Fees",
        //       icon: "far fa-circle",
        //     },
        //     {
        //       href: "/",
        //       title: "Others",
        //       icon: "far fa-circle",
        //     },
        //   ],
        // },
        {
          title: "Security",
          icon: "fas fa-key",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/admin-user-list",
              title: "Manage users",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/admin-user-role",
              title: "Manage Roles",
              icon: "far fa-circle ",
            },
            // {
            //   href: "/",
            //   title: "Manage Features",
            //   icon: "far fa-circle",
            // },
          ],
        },
        {
          href: "/admin/registration/settings",
          title: "Settings",
          icon: "fas fa-cogs",
        },
        {
          title: "Consolidate",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/mergeDelete",
              title: "Members Consolidate",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/clubMergeDelete",
              title: "Clubs Consolidate",
              icon: "far fa-circle ",
            },

            // {
            //   href: "/",
            //   title: "Manage Features",
            //   icon: "far fa-circle",
            // },
          ],
        },
        {
          title: "Campaign Manager",
          icon: "fas fa-envelope",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/template-types",
              title: "Template Types",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/email-templates",
              title: "Email Templates",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/create-campaign",
              title: "Create Campaign",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/campaign-history-reports",
              title: "Campaign History & Reports",
              icon: "far fa-circle ",
            },

          ],
        },
        {
          title: "Content Management",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "#",
              title: "FAQ",
              icon: "far fa-circle ",
              hiddenOnCollapse: true,
              subChild: [
                {
                  href: "/admin/faq-sections",
                  title: "Section",
                  icon: "far fa-circle ",
                },
                {
                  href: "/admin/faq-list",
                  title: "FAQ List",
                  icon: "far fa-circle",
                },
              ]
            },
            {
              href: "/admin/member-dashboard",
              title: "Member Dashboard",
              icon: "far fa-circle "
            },
            {
              href: "/admin/landingpage",
              title: "Landing Page",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/resource",
              title: "Resources",
              icon: "far fa-circle "
            },
            {
              href: "/admin/certificate-of-insurances",
              title: "Certificate of Insurances",
              icon: "far fa-circle "
            },
            {
              href: "/admin/magazine",
              title: "Magazines",
              icon: "far fa-circle "
            },
          ],
        },
        {
          href: process.env.VUE_APP_WATERSKI_EMS_URL,
          external: true,
          title: "",
          class: "ems_logo",
          badge: {
            text: "lms",
            class: "",
            attributes: { src: "/dist/img/ems_button1.png" },
            element: "img",
          },
        },
        {
          href: process.env.VUE_APP_WATERSKI_LMS_URL,
          external: true,
          title: "",
          class: "lms_logo",
          badge: {
            text: "lms",
            class: "",
            attributes: { src: "/dist/img/lms_button1.png" },
            element: "img",
          },
        },
        // {
        //   href: "",
        //   title: "Download App",
        //   icon: "fas fa-download",
        //   hidden: this.role == 1,
        // },
        // {
        //   href: "http://usawseventreg.integrass.com/",
        //   external: true,
        //   title: "",
        //   class: "appstore_logo",
        //   badge: {
        //     text: "lms",
        //     class: "",
        //     attributes: { src: "/dist/img/mac_store.png" },
        //     element: "img",
        //   },
        // },
        // {
        //   href: "http://usawseventreg.integrass.com/",
        //   external: true,
        //   title: "",
        //   badge: {
        //     text: "lms",
        //     class: "",
        //     attributes: { src: "/dist/img/app_store.png" },
        //     element: "img",
        //   },
        // },
      ],
      shooting: {
        admin: [
          {
            href: "/admin/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
            meta: "forAuth",
          },
          {
            title: "Registrations",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/registrations",
                title: "Individual",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/family-registrations",
                title: "Family",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club-registrations",
                title: "Club",
                icon: "far fa-circle",
              },

              {
                href: "/admin/coach-registrations",
                title: "Coach",
                icon: "far fa-circle",
              },
              {
                href: "/admin/fan-registrations",
                title: "Fan",
                icon: "far fa-circle",
              }
            ],
          },
          {
            href: "/admin/coupons",
            title: "Coupons",
            icon: "fas fa-money-bill nav-icon",
          },
          {
            title: "Pricing",
            icon: "fas fa-dollar-sign",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/membership-pricing",
                title: "Membership Pricing",
                icon: "fas fa-users nav-icon",
              },
              {
                href: "/admin/other-pricing",
                title: "Other Pricing",
                icon: "fas fa-users nav-icon",
              }
            ]
          },
          {
            title: "Reports",
            icon: "fas fa-chart-pie",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/reports/member-registrations",
                title: "Membership Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club/reports",
                title: "Club Reports",
                icon: "far fa-circle ",
              },
              {
              href: "/admin/reports/bgscreening",
              title: "BG Screening Reports",
              icon: "far fa-circle",
              },
              {
                href: "/admin/reports/donation",
                title: "Donations",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/financials",
                title: "Financials Report",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/summary",
                title: "Summary Reports",
                icon: "far fa-circle",
              },
              // {
              //   href: "/admin/reports/financials",
              //   title: "Financials Report",
              //   icon: "far fa-circle",
              // },
              // {
              //   href: "/admin/report/summary-report",
              //   title: "Summary Reports",
              //   icon: "far fa-circle",
              // },
            ],
          },
          {
            title: "Security",
            icon: "fas fa-key",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/admin-user-list",
                title: "Manage users",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/admin-user-role",
                title: "Manage Roles",
                icon: "far fa-circle ",
              }
            ],
          },
          {
            href: "/admin/registration/settings",
            title: "Settings",
            icon: "fas fa-cogs",
          },
          {
            title: "Imports",
            icon: "fas fa-upload",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/imports/background-screening",
                title: "Background Screening",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/imports/safe-sport",
                title: "Safe Sport",
                icon: "far fa-circle ",
              }
            ],
          },
          {
            href: "/admin/group",
            title:"Groups",
            icon: "fas fa-object-group",
          },
          {
          title: "Consolidate",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "/admin/mergeDelete",
              title: "Members Consolidate",
              icon: "far fa-circle ",
            },
            // {
            //   href: "/admin/clubMergeDelete",
            //   title: "Clubs Consolidate",
            //   icon: "far fa-circle ",
            // },

            // {
            //   href: "/",
            //   title: "Manage Features",
            //   icon: "far fa-circle",
            // },
          ],
          },
          {
            title: "Campaign Manager",
            icon: "fas fa-envelope",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/template-types",
                title: "Template Types",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/email-templates",
                title: "Email Templates",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/create-campaign",
                title: "Create Campaign",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/campaign-history-reports",
                title: "Campaign History & Reports",
                icon: "far fa-circle ",
              },
            ],
          },
          {
          title: "Content Management",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "#",
              title: "FAQ",
              icon: "far fa-circle ",
              hiddenOnCollapse: true,
              subChild: [
                {
                  href: "/admin/faq-sections",
                  title: "Section",
                  icon: "far fa-circle ",
                },
                {
                  href: "/admin/faq-list",
                  title: "FAQ List",
                  icon: "far fa-circle",
                },
              ]
            },
            {
              href: "/admin/member-dashboard",
              title: "Member Dashboard",
              icon: "far fa-circle "
            },
            {
              href: "/admin/landingpage",
              title: "Landing Page",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/resource",
              title: "Resources",
              icon: "far fa-circle "
            },
          ],
        },
          {
            href: process.env.VUE_APP_SHOOTING_EMS_URL,
            external: true,
            title: "",
            class: "ems_logo",
            badge: {
              text: "ems",
              class: "",
              attributes: { src: "/dist/img/ems_button1.png" },
              element: "img",
            },
          },
        ],
        manager: [
          {
            href: "/admin/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
          },
          {
            title: "Registrations",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/registrations",
                title: "Individual",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/family-registrations",
                title: "Family",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club-registrations",
                title: "Club",
                icon: "far fa-circle",
              },
            ],
          },
          {
            title: "Reports",
            icon: "fas fa-chart-pie",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/reports/member-registrations",
                title: "Membership Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/club/reports",
                title: "Club Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/reports/donation",
                title: "Donations",
                icon: "far fa-circle",
              },
              // {
              //   href: "/admin/reports/financials",
              //   title: "Financials Report",
              //   icon: "far fa-circle",
              // },
              // {
              //   href: "/admin/report/summary-report",
              //   title: "Summary Reports",
              //   icon: "far fa-circle",
              // },

            ],
          },
          {
          title: "Content Management",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "#",
              title: "FAQ",
              icon: "far fa-circle ",
              hiddenOnCollapse: true,
              subChild: [
                {
                  href: "/admin/faq-sections",
                  title: "Section",
                  icon: "far fa-circle ",
                },
                {
                  href: "/admin/faq-list",
                  title: "FAQ List",
                  icon: "far fa-circle",
                },
              ]
            },
            {
              href: "/admin/member-dashboard",
              title: "Member Dashboard",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/landingpage",
              title: "Landing Page",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/resource",
              title: "Resources",
              icon: "far fa-circle "
            },
          ],
        },
          // {
          //   title: "Security",
          //   icon: "fas fa-key",
          //   hiddenOnCollapse: true,
          //   child: [
          //     {
          //       href: "/admin/admin-user-list",
          //       title: "Manage users",
          //       icon: "far fa-circle ",
          //     },
          //     {
          //       href: "/admin/admin-user-role",
          //       title: "Manage Roles",
          //       icon: "far fa-circle ",
          //     }
          //   ],
          // },
        ],
      },
      alliancefastpitch:
      {
        admin: [
          {
            href: "/admin/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
            meta: "forAuth",
          },
          {
            title: "Registrations",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/registrations",
                title: "Player",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/staff-registrations",
                title: "Staff",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/team-registrations",
                title: "Team",
                icon: "far fa-circle",
              },
            ],
          },
          {
            title: "Free Agent",
            icon: "fas fa-street-view",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/free-agent",
                title: "Player",
                icon: "far fa-circle",
              },
              {
                href: "/admin/staff-free-agent",
                title: "Coach",
                icon: "far fa-circle",
              },
            ],
          },
          {
            href: "/admin/coupons",
            title: "Coupons",
            icon: "fas fa-money-bill nav-icon",
          },
          {
            href: "/admin/membership-pricing",
            title: "Membership Pricing",
            icon: "fas fa-dollar-sign nav-icon",
          },
          {
            title: "Reports",
            icon: "fas fa-chart-pie",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/reports/registration",
                title: "Membership Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/team/reports",
                title: "Team Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/reports/donation",
                title: "Donations",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/financials",
                title: "Financials Report",
                icon: "far fa-circle",
              },
              {
                href: "/admin/reports/summary",
                title: "Summary Reports",
                icon: "far fa-circle",
              },
              // {
              //   href: "/admin/recruiting-member/reports",
              //   title: "Recruiting Player Reports",
              //   icon: "far fa-circle ",
              // },
              {
                href: "/admin/Recruiting-team/reports",
                title: "Recruiting Team Reports",
                icon: "far fa-circle ",
              },
            ],
          },
          {
            title: "Security",
            icon: "fas fa-key",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/admin-user-list",
                title: "Manage users",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/admin-user-role",
                title: "Manage Roles",
                icon: "far fa-circle ",
              }
            ],
          },
          {
            href: "/admin/registration/settings",
            title: "Settings",
            icon: "fas fa-cogs",
          },
          {
            title: "Player Transfer",
            icon: "fas fa-share nav-icon",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/playerTransfer/request",
                title: "Player Transfer Request",
                icon: "far fa-circle",
              },
              {
                href: "/admin/playerTransfer/enable",
                title: "Player Transfer Enable",
                icon: "far fa-circle",
              },
              {
                href: "/admin/playerTransfer/history",
                title: "Player Transfer History",
                icon: "far fa-circle",
              },
            ],
          },
          {
            title: "Campaign Manager",
            icon: "fas fa-envelope",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/template-types",
                title: "Template Types",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/email-templates",
                title: "Email Templates",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/create-campaign",
                title: "Create Campaign",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/campaign-history-reports",
                title: "Campaign History & Reports",
                icon: "far fa-circle ",
              },
            ],
          },
          {
            title: "Content Management",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "#",
                title: "FAQ",
                icon: "far fa-circle ",
                hiddenOnCollapse: true,
                subChild: [
                  {
                    href: "/admin/faq-sections",
                    title: "Section",
                    icon: "far fa-circle ",
                  },
                  {
                    href: "/admin/faq-list",
                    title: "FAQ List",
                    icon: "far fa-circle",
                  },
                ]
              },
              {
                href: "/admin/member-dashboard",
                title: "Member Dashboard",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/landingpage",
                title: "Landing Page",
                icon: "far fa-circle ",
                },
              {
                href: "/admin/resource",
                title: "Resources",
                icon: "far fa-circle "
              },
            ],
          },
        ],
        manager: [
          {
            href: "/admin/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
          },
          {
            title: "Registrations",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/registrations",
                title: "Player",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/staff-registrations",
                title: "Staff",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/team-registrations",
                title: "Team",
                icon: "far fa-circle",
              },
            ],
          },
          {
            title: "Reports",
            icon: "fas fa-chart-pie",
            hiddenOnCollapse: true,
            child: [
              {
                href: "/admin/reports/registration",
                title: "Membership Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/team/reports",
                title: "Team Reports",
                icon: "far fa-circle ",
              },
              {
                href: "/admin/reports/donation",
                title: "Donations",
                icon: "far fa-circle",
              },
            ],
          },
          {
          title: "Content Management",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            {
              href: "#",
              title: "FAQ",
              icon: "far fa-circle ",
              subChild: [
                {
                  href: "/admin/faq-sections",
                  title: "Section",
                  icon: "far fa-circle ",
                },
                {
                  href: "/admin/faq-list",
                  title: "FAQ List",
                  icon: "far fa-circle",
                },
              ]
            },
            {
              href: "/admin/member-dashboard",
              title: "Member Dashboard",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/landingpage",
              title: "Landing Page",
              icon: "far fa-circle ",
            },
            {
              href: "/admin/resource",
              title: "Resources",
              icon: "far fa-circle "
            },
          ],
        },
        ],
      },
      subChildStatus:false
    };
  },
  methods: {
    onToggleCollapse(collapsed) {
      console.log(collapsed);
      this.collapsed = collapsed;
    },
    onItemClick(event, item, node) {
      console.log("onItemClick");
    },
    childClose(subChild){
      if(this.subChildStatus == false && subChild==undefined) {
          $('.sub-child').css("display", "none");
          $('.child').removeClass("menu-open");
      }
      this.subChildStatus = false;
    },
    subChild(){
       this.subChildStatus = true;
    }
  },

  mounted() {
    this.adminInfoData = JSON.parse(localStorage.getItem("admin"));
    this.role = JSON.parse(localStorage.getItem("role"));
    // console.log(this.role);

    if(this.subdomain == "waterski" && this.role == 1) this.menuItems = this.menu;
    if(this.subdomain == "waterski" && this.role == 2) this.menuItems = this.menu1;
    if(this.subdomain == "waterski" && this.role > 2) this.menuItems = this.menu2;

    if(this.subdomain == "shooting" && this.role == 1) this.menuItems = this.shooting.admin;
    if(this.subdomain == "shooting" && this.role != 1) this.menuItems = this.shooting.manager;

    if(this.subdomain == "alliancefastpitch" && this.role == 1) this.menuItems = this.alliancefastpitch.admin;
    if(this.subdomain == "alliancefastpitch" && this.role != 1) this.menuItems = this.alliancefastpitch.manager;
  },
};
</script>

<style scoped>
/* .v-sidebar-menu.vsm_expanded {
  max-width: 250px !important;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: transparent;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px hsla(0, 0%, 100%, 0.1) inset;
  box-shadow: 3px 0px 0px 0px hsl(0deg 0% 100% / 10%) inset;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
}

.v-sidebar-menu .vsm--icon {
  background-color: transparent !important;
}
.vsm--dropdown .vsm--list {
  padding-left: 0 !important;
}
.v-sidebar-menu .vsm--dropdown .vsm--list .vsm--item a {
  padding-left: 20px !important;
} */

</style>
