<template>
  <div>
    <main class="main" role="main">
      <div class=" bg-light h-100">
        <div class="container-fluid h-100">
          <div class="row">
            <div
                class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">

            </div>
            <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-0 rightside_section">
              <div class="card-header bg-white border-0 p-0">
                <div class="th_clr pt-4 pb-4 season_year text-center">
                  Become An Affiliate Camp Or School
                </div>
              </div>

              <div class="card-body bg-white  form-flds rm-shadow pb-4 mb-3 mbv-height-pad ml-1 mr-1">
                <template v-if="loading">
                  <div class="row">
                    <div class="col-md-12 p-5 text-center">
                      <i class="fa fa-circle-notch fa-2x fa-spin"></i>
                    </div>
                  </div>
                </template>
                <template v-else-if="camp_price === 0 && ski_school_price === 0">
                  <div class="row">
                    <div class="col-md-12 p-5 text-center">
                      <p class="mb-0">Camp and School Registrations closed now, come back later </p>
                    </div>
                  </div>
                </template>
                <template v-else>
                <form class="" data-vv-scope="form-1">
                  <div class="row" v-show="this.step == 1">

                    <div class="col-md-12">
                      <div class="alert alert-warning text-center" v-if="camp_price === 0 || ski_school_price === 0">
                        <b>{{ camp_price === 0 ? 'Camp' : 'School' }}</b> Registrations closed now, come back later
                      </div>
                    </div>

                    <div class="col-md-6">

                      <div class="form-group">
                        <label class="form-label">Camp/School Name<sup class="text-danger">*</sup></label>
                        <input type="text" v-model="form.company" data-vv-as="camp/school name" v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.company') }" class="form-control"
                               id="company" name="company" @change="checkDuplicate"/>
                        <div v-show="formErrors.has('form-1.company')" class="invalid-feedback">
                          {{ formErrors.first('form-1.company') }}
                        </div>
                        <span class="text-sm text-danger">{{ duplicateErrorForPersonal}}</span>
                        <a href="/member/login" v-if="duplicateError.showLink" target="_blank"> Click here</a>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">We are a<sup class="text-danger">*</sup></label>
                      </div>
                      <div class="form-group">
                        <input type="radio" data-vv-as="we are a"
                               :class="{ 'is-invalid': formErrors.has('form-1.memberType') }" id="Camp"
                               v-validate="'required'" v-model="form.memberType" name="memberType" value="Camp" :disabled="camp_price === 0">
                        <label for="Camp" class="gender-label-span">Camp</label>
                        <input type="radio" id="School" v-model="form.memberType" name="memberType"
                               value="Ski School" class="ml-1" :disabled="ski_school_price === 0">
                        <label for="School" class="gender-label-span">School</label>
                        <div v-show="formErrors.has('form-1.memberType')" class="invalid-feedback">
                          {{ formErrors.first('form-1.memberType') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-label">Contact Name (Director)<sup class="text-danger">*</sup></label>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <input type="text" placeholder="First *" data-vv-as="first name" v-validate="'required'"
                                   :class="{ 'is-invalid': formErrors.has('form-1.first_name') }" class="form-control"
                                   v-model="form.first_name"
                                   name="first_name">
                            <div v-show="formErrors.has('form-1.first_name')" class="invalid-feedback">
                              {{ formErrors.first('form-1.first_name') }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <input type="text" placeholder="Last *" data-vv-as="last name" v-validate="'required'"
                                   :class="{ 'is-invalid': formErrors.has('form-1.last_name') }" class="form-control"
                                   v-model="form.last_name"
                                   name="last_name">
                            <div v-show="formErrors.has('form-1.last_name')" class="invalid-feedback">
                              {{ formErrors.first('form-1.last_name') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Email<sup class="text-danger">*</sup></label>
                        <input type="text" class="form-control" data-vv-as="email" v-validate="'required|email'"
                               :class="{ 'is-invalid': formErrors.has('form-1.email') }" v-model="form.email"
                               name="email">
                        <div v-show="formErrors.has('form-1.email')" class="invalid-feedback">
                          {{ formErrors.first('form-1.email') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Phone Number<sup class="text-danger">*</sup></label>
                        <vue-tel-input v-model="form.phone_1" ref="phone" v-bind="phoneConfig" @validate="validateTelinput" :validCharactersOnly="true"></vue-tel-input>
                        <div class="invalid-feedback" v-show="phone_1_error">{{ phone_1_error }}</div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Website<sup class="text-danger">*</sup></label>
                        <input type="text" class="form-control" v-validate="'required|url'"
                               :class="{ 'is-invalid': formErrors.has('form-1.website') }" v-model="form.website"
                               name="website">
                        <div v-show="formErrors.has('form-1.website')" class="invalid-feedback">
                          {{ formErrors.first("form-1.website") }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Camp/School Site Address <sup class="text-danger">*</sup></label>
                        <div class="form-group">
                          <input type="text" placeholder="Line 1" data-vv-as="Address" v-validate="'required'"
                                 :class="{ 'is-invalid': formErrors.has('form-1.address1') }" class="form-control"
                                 v-model="form.contact[0].address1"
                                 name="address1">
                          <div v-show="formErrors.has('form-1.address1')" class="invalid-feedback">
                            {{ formErrors.first("form-1.address1") }}
                          </div>
                        </div>
                        <div class="form-group">
                          <input type="text" placeholder="Line 2" data-vv-as="line 2" class="form-control"
                                 v-model="form.contact[0].address2"
                                 name="address1">
                          <!-- <div v-show="formErrors.has('form-1.address2')" class="invalid-feedback">
                            {{ formErrors.first("form-1.address2") }}
                          </div> -->
                        </div>
                        <div class="row">
                          <div class="col-md-6 d-none">
                            <div class="form-group">
                              <select v-validate="'required'"
                                      :class="{ 'is-invalid': formErrors.has('form-1.country') }" class="form-control"
                                      name="country"
                                      v-model="form.contact[0].country">
                                <option value="">Select Country</option>
                                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                                        :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">
                                  {{ listOfCountry.country }}
                                </option>
                              </select>
                              <div v-show="formErrors.has('form-1.country')" class="invalid-feedback">
                                {{ formErrors.first("form-1.country") }}
                              </div>
                            </div>

                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <input type="text" placeholder="City" v-validate="'required'"
                                     :class="{ 'is-invalid': formErrors.has('form-1.city') }" class="form-control"
                                     v-model="form.contact[0].city"
                                     name="city">
                              <div v-show="formErrors.has('form-1.city')" class="invalid-feedback">
                                {{ formErrors.first("form-1.city") }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div v-if="form.contact[0].country =='USA'">
                              <div class="form-group">
                                <select v-validate="'required'" name="state_code" data-vv-as="State"
                                        :class="{ 'is-invalid': formErrors.has('form-1.state_code') }" class="form-control"
                                        v-model="form.contact[0].state_code">
                                  <option value="">Select State</option>
                                  <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                          :value="listOfState.state_code">{{ listOfState.state }}
                                  </option>
                                </select>

                                <div v-show="formErrors.has('form-1.state_code')" class="invalid-feedback">
                                  {{ formErrors.first("form-1.state_code") }}
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <input type="text" placeholder="State" v-validate="'required'"
                                       :class="{ 'is-invalid': formErrors.has('form-1.state') }" class="form-control"
                                       v-model="form.contact[0].state"
                                       name="state">
                                <div v-show="formErrors.has('form-1.state')" class="invalid-feedback">
                                  {{ formErrors.first("form-1.state") }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <input type="text" placeholder="Zip Code"
                                     v-validate="{ required: true, regex: /^\d{5}(-\d{4})?$/ }"
                                     :class="{ 'is-invalid': formErrors.has('form-1.zip') }" class="form-control"
                                     v-model="form.contact[0].zip"
                                     name="zip">
                              <div v-show="formErrors.has('form-1.zip')" class="invalid-feedback">
                                {{ formErrors.first("form-1.zip") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="form-label">Closest Airport</label>
                        <input type="text" class="form-control" v-model="form.closest_airport"
                               name="closest_airport">
                      </div>
                      <div class="form-group tooltipcss">
                        <label class="form-label">Type of Camp/School</label> <b-button  v-b-tooltip.hover title="Please describe the type of camp or school (e.g. boys, girls, overnight, etc.)."> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></b-button>
                        <input type="text" class="form-control" v-model="form.type" name="type">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Mailing Address <sup class="text-danger"> * </sup> <input  name="same" style="margin-left: 30px" type="checkbox" v-on:change="isSame()" id='same' v-model="form.same" value=true> Same as Site address</label>
                        <div class="form-group">
                          <input type="text" placeholder="Line 1" v-model="form.contact[1].address1" data-vv-as="Mailing Address" v-validate="'required'" :class="{ 'is-invalid': formErrors.has('form-1.mailAddress') }"  class="form-control" name="mailAddress">
                          <div v-show="formErrors.has('form-1.mailAddress')" class="invalid-feedback">
                            {{ formErrors.first("form-1.mailAddress") }}
                          </div>
                        </div>
                        <div class="form-group">
                          <input type="text" placeholder="Line 2" v-model="form.contact[1].address2" class="form-control" name="line2">
                        </div>
                        <div class="row">

                          <div class="col-md-6 d-none">
                            <div class="form-group">
                              <select
                                      :class="{ 'is-invalid': formErrors.has('form-1.country') }" class="form-control"
                                      name="country2"
                                      v-model="form.contact[1].country">
                                <option value="">Select Country</option>
                                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                                        :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">
                                  {{ listOfCountry.country }}
                                </option>
                              </select>
                              <div v-show="formErrors.has('form-1.country')" class="invalid-feedback">
                                {{ formErrors.first("form-1.country") }}
                              </div>
                            </div>

                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <input type="text" placeholder="City" v-validate="'required'" data-vv-as="City"
                                     v-model="form.contact[1].city"
                                     :class="{ 'is-invalid': formErrors.has('form-1.city2') }"
                                     class="form-control"
                                     name="city2">
                              <div v-show="formErrors.has('form-1.city2')" class="invalid-feedback">
                                {{ formErrors.first("form-1.city2") }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div v-if="form.contact[1].country =='USA'">
                              <div class="form-group">
                                <select name="state2"
                                        v-validate="'required'"
                                        data-vv-as="State"
                                        :class="{ 'is-invalid': formErrors.has('form-1.state2') }" class="form-control"
                                        v-model="form.contact[1].state_code">
                                  <option value="">Select State</option>
                                  <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                          :value="listOfState.state_code">{{ listOfState.state }}
                                  </option>
                                </select>
                                <div v-show="formErrors.has('form-1.state2')" class="invalid-feedback">
                                  {{ formErrors.first("form-1.state2") }}
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <input type="text" placeholder="State"
                                       :class="{ 'is-invalid': formErrors.has('form-1.state') }" class="form-control"
                                       v-model="form.contact[1].state"
                                       name="state2">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <input type="text" placeholder="Zip Code" class="form-control" v-model="form.contact[1].zip" name="zip2"
                                     v-validate="{ required: true, regex: /^\d{5}(-\d{4})?$/ }"
                                     :class="{ 'is-invalid': formErrors.has('form-1.zip2') }"
                                     data-vv-as="Zip">
                              <div v-show="formErrors.has('form-1.zip2')" class="invalid-feedback">
                                {{ formErrors.first("form-1.zip2") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="form-label">Number of Instructors</label>
                        <input type="number" class="form-control" v-validate="{ regex: /^([0-9]+)$/ }"
                               v-model="form.number_of_instructor"
                               name="number_of_instructor">
                      </div>
                      <div class="form-group">
                        <label class="form-label">Months of Operation <sup class="text-danger">*</sup></label>
                        <input type="text" data-vv-as="months of operation" class="form-control"
                               v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.month_of_operation') }"
                               v-model="form.month_of_operation"
                               name="month_of_operation">
                        <div v-show="formErrors.has('form-1.month_of_operation')" class="invalid-feedback">
                          {{ formErrors.first("form-1.month_of_operation") }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="form-label">Disciplines Taught<sup class="text-danger">*</sup></label>

                      </div>
                      <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-1.equipment') }">
                        <div v-for="(dt, index) in disciplineTaught" :key="index">
                          <input v-validate="'required'" name="discipline_taught" data-vv-as="discipline taught" type="checkbox" :id="'dtaught'+index" v-model="form.discipline_taught" :value="dt.value">
                          <label :for="'dtaught'+index" tabindex="0" @keyup="doCheckViaKeyboard"> {{ dt.text }}</label>
                        </div>
                        <div >
                        </div>


                      </div>
                      <div v-show="formErrors.has('form-1.discipline_taught')" class="invalid-feedback">
                        {{ formErrors.first("form-1.discipline_taught") }}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label">Equipment<sup class="text-danger">*</sup></label>
                        <div class="form-group form-check"
                             :class="{ 'is-invalid': formErrors.has('form-1.equipment') }">
                          <div v-for="(eq, index) in equipment" :key="index">
                            <input v-validate="'required'" name="equipment" type="checkbox" :id="'equipment'+index" v-model="form.equipment" :value="eq.value">
                            <label :for="'equipment'+index" tabindex="0" @keyup="doCheckViaKeyboard"> {{ eq.text }}</label>
                          </div>
                        </div>
                        <div v-show="formErrors.has('form-1.equipment')" class="invalid-feedback">
                          {{ formErrors.first("form-1.equipment") }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="form-label">Skill Levels Taught<sup class="text-danger">*</sup></label>
                        <div class="form-group form-check"
                             :class="{ 'is-invalid': formErrors.has('form-1.skill_level_taught') }">
                          <div v-for="(st, index) in skillTaught" :key="index">
                            <input v-validate="'required'" name="skill_level_taught" data-vv-as="skill levels taught" type="checkbox" :id="'skilllevel'+index" v-model="form.skill_level_taught" :value="st.value">
                            <label :for="'skilllevel'+index" tabindex="0" @keyup="doCheckViaKeyboard"> {{ st.text }}</label>
                          </div>
                        </div>
                        <div v-show="formErrors.has('form-1.skill_level_taught')" class="invalid-feedback">
                          {{ formErrors.first("form-1.skill_level_taught") }}
                        </div>

                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label">Boats<sup class="text-danger">*</sup></label>
                      <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-1.boats') }">

                        <div v-for="(bt, index) in boats" :key="index">
                          <input v-validate="'required'" name="boats"  type="checkbox" :id="'boats'+index" v-model="form.boats" :value="bt.value">
                          <label :for="'boats'+index" tabindex="0" @keyup="doCheckViaKeyboard"> {{ bt.text }}</label>
                        </div>

                      </div>
                      <div v-show="formErrors.has('form-1.boats')" class="invalid-feedback">
                        {{ formErrors.first("form-1.boats") }}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="">
                        <label for="clubandteam" class="form-label">Waterway Type <sup
                            class="text-danger">*</sup></label>
                      </div>
                      <div class="form-group">

                        <select class="form-control" data-vv-as="waterway Type" v-validate="'required'"
                                :class="{ 'is-invalid': formErrors.has('form-1.waterway_type') }"
                                v-model="form.waterway_type" name="waterway_type">
                          <option value="">Select</option>
                          <option value="1">Natural Freshwater Lake</option>
                          <option value="2">Man-made Freshwater Lake</option>
                          <option value="3">River</option>
                          <option value="4">Reservoir</option>
                          <option value="5">Intracoastal</option>
                          <option value="6">Saltwater</option>
                        </select>
                        <div v-show="formErrors.has('form-1.waterway_type')" class="invalid-feedback">
                          {{ formErrors.first("form-1.waterway_type") }}
                        </div>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Waterfront Site Description<sup class="text-danger">*</sup></label>
                        <select class="form-control" data-vv-as="water front" v-validate="'required'"
                                :class="{ 'is-invalid': formErrors.has('form-1.water_front') }"
                                v-model="form.water_front" name="water_front">
                          <option value="">Select</option>
                          <option value="1">Private</option>
                          <option value="2">Semi Private</option>
                          <option value="3">Public</option>
                        </select>
                        <div v-show="formErrors.has('form-1.water_front')" class="invalid-feedback">
                          {{ formErrors.first("form-1.water_front") }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-label">Additional Information</label>
                        <textarea class="form-control" v-model="form.additional_information"
                                  name="additional_information"/>
                      </div>
                    </div>

                  </div>
                </form>
                <form data-vv-scope="form-2">
                  <div class="row" v-show="this.step == 2">
                    <div class="col-md-12">
                      <div class="row payment-view">
                        <b-overlay :show="showLoader" no-wrap></b-overlay>
                        <div class="col-md-6 mb-view-pr mb-view-pl">
                          <div class="card-body card">
                            <div class="row">
                              <div class="col-md-12">
                                <h3>Total Amount</h3>
                              </div>
                              <div class="col-md-12">
                                <ul class="list-group mb-3 payment-details">
                                  <li class="d-flex justify-content-between lh-sm">
                                    <div>
                                      <h6 class="my-0"><strong>{{ membership.type | filterBr }}</strong></h6>
                                    </div>
                                    <span class="text-muted"><strong>${{ membership.fee }}</strong></span>
                                  </li>
                                  <!--<li class=" d-flex justify-content-between lh-sm">
                                  <div>
                                  <h6 class="my-0">Affiliate</h6>
                                  </div>
                                  <span class="text-muted"><strong>$10.00</strong></span>
                                  </li>-->
                                  <li v-if="donated.amount != ''" class=" d-flex justify-content-between lh-sm">
                                    <div class="cartitemcard">
                                    <span class="remove" @click="removeDonation()"><i
                                        class="fas fa-trash-alt"></i></span>
                                      <!-- <h6 class="my-0" v-if="donated.type != ''">{{ listOfDonationSportDiscipline[donated.type] }}</h6> -->
                                      <h6 class="my-0"
                                          v-if="donated.type != '' && fields['donation']['disciplines']['options'][donated.type]">
                                        {{ fields['donation']['disciplines']['options'][donated.type] }}</h6>
                                      <h6 class="my-0" v-else>Donation</h6>
                                    </div>
                                    <span class="text-muted"><strong>${{ donated.amount | fullNumber }} </strong></span>
                                  </li>

                                  <hr>
                                  <li class=" d-flex justify-content-between  ">
                                    <div class="coupon-inner mb-3">
                                      <h6 class="my-0"><strong>Coupon</strong></h6>
                                    </div>
                                    <div class="">
                                      <input type="text" class="form-control coupon-text"
                                             v-model="couponApplied.coupon_code">
                                      <button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{
                                          button.coupon
                                        }}
                                      </button>
                                      <span class="text-sm text-danger">{{ couponErrors.error }}</span>
                                      <span class="text-sm text-success">{{ couponErrors.success }}</span>
                                    </div>
                                  </li>
                                  <!-- <hr v-if="cartProductData.length >= 1"> -->
                                  <!-- <li v-if="this.placeOrderData.registration_id >= 1" class="d-flex justify-content-between lh-sm">
                                      <div>
                                          <h6 class="my-0 "><strong>Merchandise Total</strong></h6>
                                      </div>
                                      <span class="text-muted"><strong>${{ totals.merchandise_total }}</strong></span>
                                  </li> -->
                                  <hr v-if="this.placeOrderData.registration_id >= 1">
                                  <li v-if="this.placeOrderData.registration_id >= 1"
                                      class="d-flex justify-content-between lh-sm">
                                    <div>
                                      <h6 class="my-0"><strong>Shipping Charge - First Item</strong></h6>
                                    </div>
                                    <span class="text-muted"><strong>${{ totals.shipping_first_item }}</strong></span>
                                  </li>
                                  <li v-if="this.placeOrderData.registration_id >= 1"
                                      class="d-flex justify-content-between lh-sm">
                                    <div>
                                      <h6 class="my-0"><strong>Shipping Charge - Additional Items</strong></h6>
                                    </div>
                                    <span class="text-muted"><strong>${{
                                        totals.shipping_additional_item
                                      }}</strong></span>
                                  </li>
                                  <hr v-if="this.placeOrderData.registration_id >= 1">
                                  <li v-if="couponApplied.status == 1" class="d-flex justify-content-between lh-sm">
                                    <div>
                                      <h6 class="my-0">
                                        <strong>
                                          <a href="javascript:void(0)" class="mr-2 text-danger" @click="removeCoupon">
                                            <i class="fa fa-trash"></i>
                                          </a>
                                          Discount - {{ couponApplied.text }}
                                        </strong>
                                      </h6>
                                    </div>
                                    <span class="text-muted"><strong>-${{ couponApplied.amount }}</strong></span>
                                  </li>
                                  <hr v-if="couponApplied.status == 1">
                                  <li class="d-flex justify-content-between lh-sm">
                                    <div>
                                      <h6 class="my-0"><strong>Sub Total</strong></h6>
                                    </div>
                                    <span class="text-muted"><strong>${{ totals.sub_total }}</strong></span>
                                  </li>
                                  <hr>
                                  <template v-if="processingFeePercent">
                                    <li class=" d-flex justify-content-between lh-sm">
                                      <div class="processing-tooltip">
                                        <h6 class="my-0"><strong>Technology/Processing Fee
                                          ({{ this.processingFeePercent }}%)
                                          <b-button v-b-tooltip.hover
                                                    title="This small fee enables USA-WSWS to make technology upgrades to enhance your membership experience.">
                                            <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                                 alt="tooltipicon"/></b-button>
                                        </strong></h6>
                                      </div>
                                      <span class="text-danger"><strong>${{ processingFee }}</strong></span>
                                    </li>
                                    <hr>
                                  </template>
                                  <li class=" d-flex justify-content-between lh-sm">
                                    <div>
                                      <h6 class="my-0"><strong>Grand Total</strong></h6>
                                    </div>
                                    <span class="text-danger"><strong>${{ totals.grand_total }}</strong></span>
                                  </li>
                                </ul>
                              </div>


                            </div>

                          </div>

                          <div class="card-body card" v-if="membership.member_type_id != 4">
                            <div class="row">
                              <div class="col-md-6">
                                <h3>Member Details</h3>
                              </div>

                            </div>
                            <div class="row">
                              <div class="col-md-6 col-sm-6">
                                <label>First Name</label>
                                <span>{{ form.first_name }}</span>
                              </div>

                              <div class="col-md-6 col-sm-6">
                                <label>Last Name</label>
                                <span>{{ form.last_name }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <label>Email</label>
                                <span>{{ form.email }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <label>Phone Number</label>
                                <span>{{ form.phone_1 }}</span>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <label>Website</label>
                                <span>{{ form.website }}</span>
                              </div>


                            </div>

                          </div>

                        </div>
                        <div class="col-md-6 mb-view-pl mb-view-pr">
                          <div class="card-body card card-wrapper">
                            <div class="row">
                              <template v-if="paymentForm.visible">
                              <div class="col-md-12 card-info">
                                <h3>Card Information</h3>
                                <div class="text-right">
                                  <!--                                                                            <img v-bind:src="'/dist/img/memberregistration/stripe.png'" alt="stripe">-->
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                                  <input type="hidden" name="paymentForm.amount" v-model="paymentForm.amount">
                                  <input type="hidden" name="paymentForm.registration_id"
                                         v-model="paymentForm.registration_id">
                                  <input type="text" :class="{ 'is-invalid': formErrors.has('form-2.cardNumber') }"
                                         maxlength="16" placeholder="#### #### #### ####" class="form-control"
                                         name="cardNumber" v-validate="'required|credit_card'"
                                         v-model="paymentForm.cardNumber"
                                         @keypress="isNumber($event)">
                                  <!--                                                                                <input class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" v-cardformat:formatCardNumber>-->
                                  <div v-show="formErrors.has('form-2.cardNumber')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.cardNumber') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-5">
                                <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                                <select class="form-control form-select"
                                        :class="{ 'is-invalid': formErrors.has('form-2.expiration_month') }"
                                        v-validate="'required'" id="expiration-month" required=""
                                        name="expiration_month" v-model="paymentForm.expiration_month">
                                  <option value="">Month</option>
                                  <option>01</option>
                                  <option>02</option>
                                  <option>03</option>
                                  <option>04</option>
                                  <option>05</option>
                                  <option>06</option>
                                  <option>07</option>
                                  <option>08</option>
                                  <option>09</option>
                                  <option>10</option>
                                  <option>11</option>
                                  <option>12</option>
                                </select>
                                <div v-show="formErrors.has('form-2.expiration_month')" class="invalid-feedback">
                                  {{ formErrors.first('form-2.expiration_month') }}
                                </div>
                              </div>
                              <div class="col-md-3 mp-l-r pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
                                <select class="form-select form-control" id="expiration-year"
                                        :class="{ 'is-invalid': formErrors.has('form-2.expiration_year') }"
                                        v-validate="'required'"
                                        name="expiration_year" v-model="paymentForm.expiration_year">
                                  <option value="">Year</option>
                                  <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                                </select>
                                <div v-show="formErrors.has('form-2.expiration_year')" class="invalid-feedback">
                                  {{ formErrors.first('form-2.expiration_year') }}
                                </div>
                              </div>
                              <div class="col-md-3 classcvv">
                                <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                                <input type="password" class="form-control"
                                       :class="{ 'is-invalid': formErrors.has('form-2.cvv') }" v-validate="{ required: true, regex: /^([0-9]+)$/ }"
                                       name="cvv" maxlength="4"
                                       v-model="paymentForm.cvv" @keypress="isNumber($event)">
                                <div v-show="formErrors.has('form-2.cvv')" class="invalid-feedback">
                                  {{ formErrors.first('form-2.cvv') }}
                                </div>
                              </div>
                              <div class="col-md-12" style="margin-top: 10px;">
                                <div class="form-group">
                                  <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                                  <input type="text"
                                         :class="{ 'is-invalid': formErrors.has('form-2.billing_firstname') }"
                                         v-validate="'required'" placeholder="William" class="form-control"
                                         id="billing_firstname"
                                         name="billing_firstname" v-model="paymentForm.billing_firstname">
                                  <div v-show="formErrors.has('form-2.billing_firstname')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_firstname') }}
                                  </div>
                                </div>
                              </div>
                              <hr/>
                              <div class="col-md-12">
                                <h3>Billing Address</h3>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label>Mailing Address<sup class="text-danger">*</sup></label>
                                  <input type="text" class="form-control" maxlength="45"
                                         :class="{ 'is-invalid': formErrors.has('form-2.billing_addressline1') }"
                                         v-validate="'required'"
                                         name="billing_addressline1"
                                         v-model="paymentForm.billing_addressline1">
                                  <div v-show="formErrors.has('form-2.billing_addressline1')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_addressline1') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>City<sup class="text-danger">*</sup></label>
                                  <input type="text" class="form-control" maxlength="45" name="billing_city"
                                         :class="{ 'is-invalid': formErrors.has('form-2.billing_city') }"
                                         v-validate="'required'"
                                         v-model="paymentForm.billing_city">
                                  <div v-show="formErrors.has('form-2.billing_city')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_city') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 pr-0" v-if="paymentForm.billing_country == 'USA'">
                                <div class="form-group">
                                  <label>State<sup class="text-danger">*</sup></label>
                                  <select class="form-control" id="state_code" name="billing_state_code"
                                          :class="{ 'is-invalid': formErrors.has('form-2.billing_state_code') }"
                                          v-validate="'required'"
                                          v-model="paymentForm.billing_state_code">
                                    <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                            :value="listOfState.state_code"
                                            :selected="paymentForm.billing_state_code == listOfState.state_code">
                                      {{ listOfState.state }}
                                    </option>
                                  </select>
                                  <div v-show="formErrors.has('form-2.billing_state_code')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_state_code') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6" v-else>
                                <div class="form-group">
                                  <label for="state_code" class="form-label">Other State <sup
                                      class="text-danger">*</sup></label>
                                  <input type="text" class="form-control" maxlength="45"
                                         :class="{ 'is-invalid': formErrors.has('form-2.billing_state') }"
                                         v-validate="'required'"
                                         v-model="paymentForm.billing_state" name="billing_state">
                                  <div v-show="formErrors.has('form-2.billing_state')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_state') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 pr-0">
                                <div class="form-group">
                                  <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                                  <input type="text" v-if="paymentForm.billing_country == 'USA'" class="form-control" maxlength="15" name="billing_zip"
                                         :class="{ 'is-invalid': formErrors.has('form-2.billing_zip') }"
                                         v-validate="{ required: true, regex: /^\d{5}(-\d{4})?$/ }"
                                         v-model="paymentForm.billing_zip">
                                  <input type="text" v-else class="form-control" maxlength="15" name="billing_zip"
                                         :class="{ 'is-invalid': formErrors.has('form-2.billing_zip') }"
                                         v-validate="'required'"
                                         v-model="paymentForm.billing_zip">
                                  <div v-show="formErrors.has('form-2.billing_zip')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_zip') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Country<sup class="text-danger">*</sup></label>
                                  <select class="form-control" id="country" v-model="paymentForm.billing_country"
                                          :class="{ 'is-invalid': formErrors.has('form-2.billing_country') }"
                                          v-validate="'required'"
                                          name="billing_country">
                                    <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                            :value="listOfCountry.country"
                                            :selected="paymentForm.billing_country == listOfCountry.country">
                                      {{ listOfCountry.country }}
                                    </option>
                                  </select>
                                  <div v-show="formErrors.has('form-2.billing_country')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.billing_country') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12" v-if="this.getSiteProps('general.payment_fee_info')">
                                <!-- <p class="mt-3 font-13" style="font-size:11px;margin-bottom:0;"><i>All transactions also include a 3% processing/technology fee.</i></p> -->
                              </div>
                              <hr/>
                              </template>
                              <div class="col-md-12 payment-conf confirmsc">
                                <h3>Confirm</h3>
                                <!-- <p style="font-13">By submitting this information, you agree to the <router-link class-active="active" target="_blank" to="/privacy-policy">privacy policy</router-link> and <router-link target="_blank" class-active="active" to="/terms-and-conditions">Terms &amp; Conditions</router-link> policy, including
                                AUTO-RENEWAL and understand that there are NO REFUNDS. </p> -->
                                <p v-if="prop_confirmation.label" v-html="prop_confirmation.label"></p>
                                <div class="form-check "><input type="checkbox" id="payment_agree"
                                                                class="form-check-input" v-model="paymentForm.agree"
                                                                :class="{ 'is-invalid': formErrors.has('form-2.agree') }"
                                                                v-validate="'required'"
                                                                name="agree">
                                  <label for="payment_agree" class="form-check-label"> <input type="text"
                                                                                              class="chkboxinput"
                                                                                              v-model="paymentForm.sign"
                                                                                              :class="{ 'is-invalid': formErrors.has('form-2.sign') }"
                                                                                              v-validate="'required'"
                                                                                              name="sign"
                                                                                              maxlength="2"
                                                                                              @keypress="isAlphabets($event)">
                                    <span :style="{ 'display': 'inline-block' }" v-if="prop_confirmation.agree"
                                          v-html="prop_confirmation.agree"></span>
                                  </label>
                                  <span v-if="prop_confirmation.note" v-html="prop_confirmation.note"></span>
                                  <div v-show="formErrors.has('form-2.agree')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.agree') }}
                                  </div>
                                  <div v-show="formErrors.has('form-2.sign')" class="invalid-feedback">
                                    {{ formErrors.first('form-2.sign') }}
                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="row" v-show="this.step == 3">
                  <div class="col-md-12">
                    <div class="card-body confirmation">
                      <div class="row">
                        <div class="col-md-12 pt-5">
                          <h3 class="text-center text-success">Thanks for the Registration.</h3>
                          <div class="form-group text-center">
                            <!--                                                        <label class="form-label">Transaction Id</label>-->
                            <span>{{ paymentData }}</span>
                            <p>You will receive an email shortly regarding this transaction along with your receipt and
                              membership information.</p>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12 text-center">


                          <a href="/csregistrations" class="btn btn-outline-dark">
                            <i class="fas fa-user-plus"></i> Register New Member
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-show="this.step != 3">
                <div class="col-md-12">
                  <div class="text-right topspace">
                    <button type="submit" @click.prevent="registrationNextstep"
                            class="btn btn-outline-primary savebtn next-step-btn mr-0" :disabled="duplicateError.showLink" style="margin-bottom: 65px">
                      {{ button.nextButton }}
                    </button>
                  </div>
                </div>
              </div>
                </template>
              </div>


              <div class="footer-inner">
                <div class="footeremail col-md-12 mb-1 pl-0">
                  <div  class="">
                    <div class=" ">
                      <p class="footer-quest">Questions? Contact Member Services at <a href="tel:863-324-4341">863-324-4341</a> or <a href="mailto:memberservices@usawaterski.org">memberservices@usawaterski.org</a></p>
                    </div>
                  </div>
                </div>
                <div class="row footer-row">
                  <div class="col-md-7">
                    <ul class="footerulli">
                      <li><p class="text-danger">USA Water Ski &amp; Wake Sports, Inc. {{ new Date().getFullYear() }}</p></li>
                      <li><div v-html="this.getSiteProps('login.faq')"></div></li>
                    </ul>
                  </div>
                  <div class="col-md-5">
                    <p class="text-right">Powered by <a href="https://www.isportz.co/" target="_blank"><img
                        v-bind:src="'/dist/img/logo.svg'" style="width:80px" alt="footer-img"/></a>
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </main>
  </div>


</template>
<script>
import axios from "axios";
import { VueTelInput } from 'vue-tel-input';
import * as $ from 'jquery';

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter valid phone number.' };
  }
  return { valid: true, error: "" };
}

export default {

  name: "camporschool",
  components: {VueTelInput},
  data() {
    return {
      duplicateError:{
        showLink:false,
      },
      duplicateErrorForPersonal:'',
      step: 1,
      button: {
        nextButton: 'Proceed To Payment',
        coupon: 'Apply Coupon'
      },
      phone_1_error: "",
      validTelinput:false,
      phoneConfig: {
        mode: "international",
        defaultCountry: "US",
        styleClasses: this.phone_1_error !== "" ? "is-invalid" : "",
        inputOptions: {
          autocomplete: "on",
          autofocus: false,
          id: "phone_1",
          maxlength: 15,
          name: "phone_number",
          placeholder: "",
          readonly: false,
          required: false,
          showDialCode: false,
          tabindex: 0,
          type: "tel"
        }
      },
      disciplineTaught: [
        {text: 'Slalom', value: 'Slalom'},
        {text: 'Trick', value: 'Trick'},
        {text: 'Wakeboard', value: 'Wakeboard'},
        {text: 'Barefoot', value: 'Barefoot'},
        {text: 'Hydrofoil', value: 'Hydrofoil'},
        {text: 'Jump', value: 'Jump'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Show Ski', value: 'Show Ski'},
        {text: 'Adaptive', value: 'Adaptive'},
        {text: 'Kneeboard', value: 'Kneeboard'},
      ],
      equipment: [
        {text: 'Slalom Skis', value: 'Slalom Skis'},
        {text: 'Trick Skis', value: 'Trick Skis'},
        {text: 'Jump Skis', value: 'Jump Skis'},
        {text: 'Beginner Combo Skis', value: 'Beginner Combo Skis'},
        {text: 'Sit Skis (Adaptive)', value: 'Sit Skis (Adaptive)'},
        {text: 'Show Swivel Skis', value: 'Show Swivel Skis'},
        {text: 'Shoe Skis', value: 'Shoe Skis'},
        {text: 'Wake Surf', value: 'Wake Surf'},        
        {text: 'Kneeboards', value: 'Kneeboards'},
        {text: 'Wakeboards', value: 'Wakeboards'},
        {text: 'Hydrofoils', value: 'Hydrofoils'},
        {text: 'Students Bring Their Own Equipment', value: 'Students Bring Their Own Equipment'},
      ],
      skillTaught: [
        {text: 'Beginning', value: 'Beginning'},
        {text: 'Intermediate', value: 'Intermediate'},
        {text: 'Advanced', value: 'Advanced'}
      ],
      boats: [
        {text: 'Competition Inboard', value: 'Competition Inboard'},
        {text: 'Competition Outboard', value: 'Competition Outboard'},
        {text: 'Recreational Inboard/Outboard', value: 'Recreational Inboard/Outboard'},
        {text: 'Jet Boat', value: 'Jet Boat'}
      ],
      yearArr: [],
      processingFeePercent: 3,
      membership: {
        id: '',
        type: '',
        fee: 0.00,
        age_criteria: "",
        member_type_id: ''
      },
      regId: '',
      totals: {
        merchandise_total: 0.00,
        shipping_total: 0.00,
        shipping_first_item: 0.00,
        shipping_additional_item: 0.00,
        sub_total: 0.00,
        grand_total: 0.00,
      },
      couponApplied: {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        text: ''
      },
      paymentData: '',
      couponErrors: {},
      donated: {
        type: '',
        amount: 0.00,

      },
      placeOrderData: {
        registration_id: '',
      },
      prop_confirmation: {},
      paymentForm: {
        "cardNumber": '',
        "expiration_year": '',
        "expiration_month": '',
        "cvv": '',
        "amount": '',
        "owner": "",
        "name_on_card": "",
        "registration_id": '',
        "billing_firstname": "",
        "billing_lastname": "",
        "billing_company": "USA Waterski",
        "billing_addressline1": "",
        "billing_zip": "",
        "billing_state": "",
        "billing_state_code": "",
        "billing_city": "",
        "billing_country": "",
        "billing_phone1": "",
        auto_renew: 1
      },
      form: {
        'company': '',
        'same': false,
        'closest_airport': '',
        'type': '',
        'number_of_instructor': '',
        'month_of_operation': '',
        'discipline_taught': [],
        'equipment': [],
        'skill_level_taught': [],
        'waterway_type': '',
        'water_front': '',
        'additional_information': '',
        'boats': [],
        "memberType": '',
        "email": '',
        "contact":[
          {
            "address1": '',
            "address2": '',
            "zip": '',
            "state_code": '',
            "state": '',
            "city": '',
            "country": 'USA',
          },
          {
            "address1": '',
            "address2": '',
            "zip": '',
            "state_code": '',
            "state": '',
            "city": '',
            "country": 'USA',
          }
        ],
        "phone_1": '',
        "phone_2": '',
        'first_name': '',
        'last_name': '',

      },
      camp_price: 0,
      ski_school_price: 0,
      loading: true
    }
  },
  methods: {
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        this.phone_1_error = '';
      }
    },
    isSame(){
      if(this.form.same){
        this.form.contact[1] = Object.assign({},this.form.contact[0]);
      }
      else{
        this.form.contact[1].address1 = "";
        this.form.contact[1].address2 = "";
        this.form.contact[1].zip = "";
        this.form.contact[1].state_code = "";
        this.form.contact[1].state = "";
        this.form.contact[1].city = "";
        this.form.contact[1].country = "USA";
      }
    },
    doCheckViaKeyboard(event){
      if (event.keyCode === 32) {
        $(event.target).closest("div").find('[type="checkbox"]').click();
      }
    },
    checkDuplicate(event) {
      this.duplicateErrorForPersonal = "";
      this.duplicateError.showLink = false;
      if (this.form?.company) {
        axios.post(this.basePath+"api/campski/check-duplicate", { name: this.form?.company })
        .then(res => {
          let duplicateFound = res?.data?.data?.duplicate;
          if (duplicateFound) {
            this.duplicateErrorForPersonal = res.data.data.message;
            this.duplicateError.showLink = true;
          }
        })
        .catch(err => console.log(err));
      }
    },
    registrationNextstep: function () {

      if (this.step == 1) {
        this.phone_1_error = "";
        if(this.form.phone_1==="") this.phone_1_error = "Phone Number is required";
        else {
          const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput);
          this.phone_1_error = validPhone1.error;
        }
        this.$validator.validateAll('form-1').then((result) => {
          if (result) {
            if(this.phone_1_error !== "") return;
            this.button.nextButton = 'Registration is in progress...';

            axios.post(this.basePath + 'api/campski', this.form)
                .then((res) => {
                    this.membership.id = res.data.data.membership_type_id;
                    this.membership.fee = parseFloat(res.data.data.price).toFixed(2);
                    this.membership.type = res.data.data.membership_type;
                    console.log(this.membership)
                    this.step = 2;
                    this.regId = res.data.data.id;
                    this.calculateTotals();
                    this.button.nextButton = 'Pay Now';
                    document.getElementById('app').scrollIntoView();
                })
                .catch((error) => {
                  console.log('Error:' + error);
                  //this.step--;
                  this.button.nextButton = 'Proceed To Payment';
                  // return false;
                  // error.response.status Check status code
                }).finally(() => {
              console.log('Finally');
            });

          } else {
            document.getElementById('app').scrollIntoView();
          }

        });


      }

      if (this.step == 2) {
        this.$validator.validateAll('form-2').then((result) => {
          if (result) {
            this.paymentApi();
          } else {
            document.getElementById('app').scrollIntoView();
          }

        });

      }


    },
    calculateTotals() {
      let membershipFee = this.membership.fee;
      if (this.couponApplied.status == 1) {
        membershipFee = this.membership.fee - this.couponApplied.amount;
      }
      this.totals.sub_total = parseFloat(this.removeCommaFromNumber(membershipFee) + this.removeCommaFromNumber(this.donated.amount) + this.removeCommaFromNumber(this.totals.merchandise_total) + this.removeCommaFromNumber(this.totals.shipping_total)).toFixed(2);

      this.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.totals.sub_total)) / 100 * this.removeCommaFromNumber(this.processingFeePercent)).toFixed(2);
      this.totals.grand_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total) + this.removeCommaFromNumber(this.processingFee)).toFixed(2);

      if(this.totals.grand_total == 0.0){
        this.paymentForm.visible = false;
        this.paymentForm.auto_renew = 0;
      } else {
        this.paymentForm.visible = true;
        this.paymentForm.auto_renew = 0;
      }
    },
    applyCoupon() {
      let regData = {};
      regData.registration_id = this.regId;
      regData.coupon_code = this.couponApplied.coupon_code;
      regData.coupon_type_id = 1;
      this.button.coupon = 'Applying Coupon...';
      this.couponErrors = {};

      axios.post(this.basePath + 'api/apply_coupon', regData)
          .then((res) => {
            if (res.data.status == 'success') {
              this.loadWaiver = false;
              this.couponApplied.status = 1;
              this.couponApplied.amount = parseFloat(res.data.data.value).toFixed(2);
              this.couponApplied.text = res.data.data.text + ' off membership (' + this.couponApplied.coupon_code + ')';
              this.couponErrors.success = res.data.message;
              this.calculateTotals();
            } else {
              this.couponErrors.error = res.data.message;
              this.couponApplied.status = 0;
              this.couponApplied.amount = 0.00;
              this.couponApplied.text = '';
              this.calculateTotals();
            }
            this.button.coupon = 'Apply Coupon';
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            this.button.coupon = 'Apply Coupon';
            return false;
          }).finally(() => {
      });
    },
    removeCoupon(){
      this.couponApplied = {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        text: ''
      };
      let data = {
        registration_id : this.regId
      }
      axios.post(this.basePath+'api/remove-coupon', data, { headers: this.memberHeaders })
          .then((res) => {
          })
          .catch((err) => {
            console.log(err)
          });
      this.couponErrors = {};
      this.calculateTotals();
    },
    removeCommaFromNumber(number) {
      return parseFloat(number.toString().replace(/,(?=.*\d+)/g, ''));
    },
    mountPropFooter() {
      this.prop_confirmation = this.getSiteProps('memberRegistration.campskiConfirmation');

    },
    paymentApi() {
      this.showLoader = true;
      this.button.nextButton = 'Payment is in progress...';
      this.paymentForm.cardNumber = this.paymentForm.cardNumber.replace(/\s/g, '');
      this.paymentForm.name_on_card = this.paymentForm.billing_firstname;
      axios.post(this.basePath + 'api/paynow/' + this.regId, this.paymentForm)
          .then((res) => {
            if (res.data.status == 'success') {
              this.showLoader = false;
              this.button.nextButton = 'Next Step';
              this.paymentData = res.data.message;
              this.step = 3;
              document.getElementById('app').scrollIntoView();
              return res;
            } else {
              this.showLoader = false;
              this.errors.payment_agree = res.data.errors;
              this.button.nextButton = 'Pay Now';
            }
          })
          .catch((error) => {
            this.showLoader = false;
            console.log('Error:' + error);
            this.errors.payment_agree = error.errors;
            //this.step--;
            this.button.nextStepButton = 'Pay Now';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    getMemberships(){
      axios.get(this.basePath + "api/get_memberships")
      .then(response => {
        let data = response.data.data;
        if(data.length){
          data.forEach(row => {
            if(row.id === 11) this.camp_price = row.price;
            if(row.id === 12) this.ski_school_price = row.price;
          });
          this.loading = false;
        }
      })
      .catch(err => {
        console.log(err);
      })
    },
  },
  updated() {
    this.mountPropFooter();
  },
  filters: {
    filterBr(value) {
      return value.replace("<br>", " ");
    },
    fullNumber(value) {
      return value.toFixed(2);
    }
  },
  mounted() {
    const year = new Date().getFullYear();
    this.yearArr = Array.from({length: 12}, (value, index) => year + index);
    this.getMemberships();
  },
}
</script>