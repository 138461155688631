<template>
  <ul class="sidebarlink">
    <li v-if="links.magazine == true">
      <router-link class="" to="/member/water-skier-magazine">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-book-reader"></i> Water Skier Magazine
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.makedonation == true">
      <router-link class="" to="/member/member-donation">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-donate"></i> Make a Donation
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.instructorcoach == true">
      <router-link v-if="this.data.reg_type_id!=7" to="/member/online-testing">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-chalkboard-teacher"></i> Instructor/Coach
              Programs
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.driverprogram == true">
      <router-link v-if="this.data.reg_type_id!=7" to="/member/drivers-program">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-dharmachakra"></i> Driver Programs / MVR Review
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.safesports == true">
      <router-link v-if="this.data.reg_type_id!=7" to="/member/safesports">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-user-shield"></i> Safe Sport Training / Background Screening
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.myofficials == true">
      <router-link v-if="this.data.reg_type_id!=7" to="/member/my-officials-ratings-certifications">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-star-half-alt"></i> My Official Ratings /
              Certifications
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.membershidiscount == true">
      <router-link
        class=""
        :to="{ name: 'membershipdiscounts' }"
        target="_blank"
      >
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-tag"></i> Membership Discounts
            </h3>
          </div>
        </div>
      </router-link>
    </li>
    <li v-if="links.membershidiscount == true">
      <router-link :to="{ name: 'usamerchandise' }" target="_blank">
        <div class="card mb-2">
          <div class="card-header rm-border bggrdient">
            <h3 class="text-white">
              <i class="fas fa-shopping-cart"></i> USA-WSWS Merchandise
            </h3>
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "pagesidebar",
  props: ["links"],

  data() {
    return {
      prop_links: null,
      data:null
    };
  },
  mounted() {
    this.prop_links = this.links;
    this.data = JSON.parse(localStorage.getItem("member"));
  },
};
</script>
