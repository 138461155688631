<template>
  <aside class="main-sidebar sidebar-dark-primary">
    <div class="sidebar-logo">
      <a href="#" class="brand-link" v-if="this.getSiteProps('member.logo') == undefined">
        <img
          :src="'/' + this.getSiteProps('login.logo')"
          v-if="'/' + this.getSiteProps('login.logo')"
          class="padding_top"
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
      <a href="#" class="brand-link" v-else>
        <img
          :src="'/' + this.getSiteProps('member.logo')"
          v-if="'/' + this.getSiteProps('member.logo')"
          class="padding_top"
          style="opacity: 1;"
        />
        <span class="brand-text font-weight-bolder"></span>
      </a>
    </div>

    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex align-items-baseline">
        <div class="image">
          <img
            :src="getProfilePicImg()"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a href="#" class="d-block text-capitalize">{{ (subdomain=='waterski'&&memberInfoData.reg_type_id === 7) ? memberInfoData.campski_name : memberInfoData.full_name }}</a>
        </div>
      </div>
     
      <!-- <div v-if="memberInfoData.reg_type_id==3">
       <sidebar-menu
        :menu="familyMenu"
        :collapsed="collapsed"
        theme="default-theme"
        :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
        relative
        :key="familyMenu">
      </sidebar-menu> 
      </div>
      <div v-else>
       <sidebar-menu
        :menu="membermenu"
        :collapsed="collapsed"
        theme="default-theme"
        :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
        relative
        :key="membermenu">
      </sidebar-menu> 
      </div>
      <div v-if="subdomain == 'shooting'">
       <sidebar-menu
        :menu="shootingMenu"
        :collapsed="collapsed"
        theme="default-theme"
        :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
        relative
        :key="shootingMenu">
      </sidebar-menu> 
      </div>
       <div v-if="memberInfoData.reg_type_id==3">
       <sidebar-menu
        :menu="FamilyMenu"
        :collapsed="collapsed"
        theme="default-theme"
        :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
        relative
        :key="FamilyMenu">
      </sidebar-menu> 
      </div>  -->

<!-- <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item has-treeview ">
                <router-link class="nav-link" to="/member/dashboard">
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/member/myprofile">
                <i class="nav-icon far fa-address-card"></i>
                <p>My Profile</p>
                </router-link>
            </li>
            <li class="nav-item has-treeview" v-if="memberInfoData.reg_type_id==3">
                <router-link class="nav-link" to="/member/family-profile">
                    <i class="nav-icon far fa-address-card"></i>
                    <p>Family Profile</p>
                </router-link>
            </li>
            <li class="nav-item has-treeview">
                <router-link class="nav-link" to="/member/membership-history">
                <i class="nav-icon far fa-address-book "></i>
                    <p>Membership History</p>
                </router-link>
            </li>
            <li class="nav-item has-treeview">
                <router-link class="nav-link" to="/member/preferences">
                     <i class="nav-icon fas fa-cog"></i>                  
                    <p>Preferences</p>
                </router-link>
            </li>
            <li class="nav-item has-treeview" v-if="subdomain == 'shooting' && groupCommittee.screening == true">
                <router-link class="nav-link" to="/member/shooting-result">
                    <i class="nav-icon fas fa-table"></i>
                    <p>Shooting Result</p>
                </router-link>
            </li>
                <router-link class="nav-link" to="/member/ncsi">
                    <i class="nav-icon fas fa-table"></i>
                    <p>Background Screening</p>
                </router-link>
            </li>
          <ul>        
          
            <li class="nav-item has-treeview" v-if="subdomain == 'shooting'">
                <router-link class="nav-link" to="/member/travel-information">
                    <i class="nav-icon fas fa-plane-departure"></i>
                    <p>Travel Information</p>
                </router-link>
            </li>

          </ul>

          <li class="nav-item has-treeview" v-if="subdomain != 'shooting'">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-edit"></i>
              <p>
                Collegiate Registration
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
             <li class="nav-item">
            <a class="nav-link" :href="teamCaption" target="_blank">
              <i class="far fa-circle nav-icon"></i>
              <p>Team Captain Tools</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="individualEvent" target="_blank">
              <i class="far fa-circle nav-icon"></i>
              <p>Individual Event Waivers</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="registrar" target="_blank">
              <i class="far fa-circle nav-icon"></i>
              <p>Registrar Tools</p>
            </a>
          </li>
            </ul>
          </li>

        </ul>
</nav>  -->


      <nav class="mt-2 pt-3 pb-3 ">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
          <li class="nav-item" v-for="(pm,k1) in menuItems" :key="k1">
            <template v-if="pm.href">
              <a v-if="pm.external" :href="pm.href" target="_blank" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]" v-if="pm.icon"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
                <img v-if="pm.badge && pm.badge.element && pm.badge.element == 'img'" :src="pm.badge.attributes.src" :class="pm.badge.class">
              </a>
              <router-link :to="pm.href" :class="['nav-link', pm.class || '']" active-class="active" v-else>
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </router-link>
            </template>
            <template v-else>
              <a href="#" :class="['nav-link', pm.class || '']">
                <i :class="['nav-icon fas', pm.icon]"></i>
                <p>
                  {{ pm.title }}
                  <i class="right fas fa-angle-left" v-if="pm.child"></i>
                </p>
              </a>
            </template>
            <ul class="nav nav-treeview" v-if="pm.child">
              <li class="nav-item" v-for="(pmc,k2) in pm.child" :key="k2">
                <a 
                  v-if="pmc.external" 
                  :href="pmc.form_post == undefined || pmc.form_post == false ? pmc.href : 'javascript:void(0);'" 
                  :data-href="pmc.form_post != undefined && pmc.form_post == true ? pmc.href : ''" 
                  :target="pmc.form_post == undefined || pmc.form_post == false ? '_blank' : '_self'" 
                  @click="linkPost(pmc.href, pmc.form_post)"               
                  :class="['nav-link', pmc.class || '']">
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                </a>
                <router-link :to="pmc.href" :class="['nav-link', pmc.class || '']" active-class="active" v-else>
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ pmc.title }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>




      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<style scoped>
/* .nav-sidebar a.nav-link.router-link-exact-active.router-link-active {
    font-weight: 700;
    color: #fff;
    border-left: 3px solid #FF0000;
    border-radius: 0;
} */
</style>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import moment from "moment";
import { EventBus } from '@/event-bus';
import VueCookies from 'vue-cookies';
import axios from "axios";

export default {
  name: "sidebar",
  // components: { SidebarMenu },
  data() {
    return {
      memberInfoData: [], 
      role: 0,
      menuItems: [],          
      waterskifamilyMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        }, 
         {                   
          href: "/member/family-profile",
          title: "Family Profile",
          icon: "far fa-address-card",          
        },       
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        {
          href: "/member/mvr-review",
          title: "MVR Review",
          icon: "fas fa-star",
        },
        {
          title: "Collegiate Registration",
          icon: "fas fa-address-card",
          hiddenOnCollapse: true,
          child: [
            { 
              href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp",
              title: "Team Captain Tools",
              external: true,
              icon: "far fa-circle ",
            },
            {
              href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List",
              title: "Individual Event Waivers",
              external: true,
              icon: "far fa-circle ",
            },
            {
              href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp",
              title: "Registrar Tools",
              external: true,
              icon: "far fa-circle",
            },
          ],
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        }
      ],
      digitalMembermenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        }
      ],
      membermenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },    
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        // {
        //   href: "/member/mvr-review",
        //   title: "Background Screening",
        //   icon: "fas fa-star",
        // },
         {
            title: "Collegiate Registration",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              { 
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp",
                title: "Team Captain Tools",
                external: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List",
                title: "Individual Event Waivers",
                external: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp",
                title: "Registrar Tools",
                external: true,
                icon: "far fa-circle",
              },
            ],
          },
          {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs", 
        }
      ],
      campskimembermenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/campskimyprofile",
          title: "Camp or School Details",
          icon: "far fa-address-card",
        },
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        }
      ],
      membermenu1: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },    
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },        
        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        {
          href: "/member/mvr-review",
          title: "MVR Review",
          icon: "fas fa-star",
        },
         {
            title: "Collegiate Registration",
            icon: "fas fa-address-card",
            hiddenOnCollapse: true,
            child: [
              { 
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp",
                title: "Team Captain Tools",
                external: true,
                form_post: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List",
                title: "Individual Event Waivers",
                external: true,
                form_post: true,
                icon: "far fa-circle ",
              },
              {
                href: "https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp",
                title: "Registrar Tools",
                external: true,
                form_post: true,
                icon: "far fa-circle",
              },
            ],
          },
        /*{
          href: "https://staging-lms.usawaterski.org/my",
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },*/
          {
          href: "/member/preferences",
         title: "Preferences",
          icon: "fas fa-cogs", 
        }
      ],
       shooting: {
      memberMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },       
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },                          
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
        },
        {
          href: "/member/shooting-result",
          title: "Shooting Result",
          icon: "fas fa-trophy",
        },
        {
          href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
          external: true,
          title: "Safesport Training",
          icon: "fas fa-clipboard",
        },

        {
          href: "/member/ncsi",
          title: "Background Screening",
          icon: "fas fa-table",
        },
        {
          href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
          external: true,
          title: "Courses",
          icon: "fas fa-clipboard",
        },
        {
          href: "/member/volunteering",
          title: "Volunteering",
          icon: "fas fa-hands-helping",
          permission: "volunteering",
        },
        {
          href: "/member/travel-information",
          title: "Travel Information",
          icon: "fas fa-plane-departure",
          permission: "travel_form"
        }
      ],
      familyMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },   
        {                   
          href: "/member/family-profile",
          title: "Family Profile",
          icon: "far fa-address-card",          
        },    
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        },        
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs", 
        },
        {
          href: "/member/shooting-result",
          title: "Shooting Result",
          icon: "fas fa-trophy",
        },
          {
            href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
            external: true,
            title: "Safesport Training",
            icon: "fas fa-clipboard",
          },
        {
            href: "/member/ncsi",
            title: "Background Screening",
            icon: "fas fa-table", 
        },
        {
            href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
            external: true,
            title: "Courses",
            icon: "fas fa-clipboard",
        },
        {
          href: "/member/volunteering",
          title: "Volunteering",
          icon: "fas fa-hands-helping",
          permission: "volunteering",
        },
        {
          href: "/member/travel-information",
          title: "Travel Information",
          icon: "fas fa-plane-departure",
          permission: "travel_form"
        }
      ],
       fanClubMenu: [
        {
          href: "/member/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/member/myprofile",
          title: "My Profile",
          icon: "far fa-address-card",
        },       
        {
          href: "/member/membership-history",
          title: "Membership History",
          icon: "far fa-address-book",
        }, 
        {
          href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
          title: "Events",
          external: true,
          icon: "fas fa-table",
        },
        {
          href: "/member/shooting-result",
          title: "Shooting Result",
          icon: "fas fa-trophy",
        },
        {
          href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
          external: true,
          title: "Safesport Training",
          icon: "fas fa-clipboard",
        },
        {
            href: "/member/ncsi",
            title: "Background Screening",
            icon: "fas fa-table", 
        },
        {
            href: process.env.VUE_APP_SHOOTING_LMS_URL + "login/index.php",
            external: true,
            title: "Courses",
            icon: "fas fa-clipboard",
        },
        {
          href: "/member/volunteering",
          title: "Volunteering",
          icon: "fas fa-hands-helping",
          permission: "volunteering",
        },
        {
          href: "/member/preferences",
          title: "Preferences",
          icon: "fas fa-cogs",
          },
        {
          href: "/member/travel-information",
          title: "Travel Information",
          icon: "fas fa-plane-departure",
          permission: "travel_form"
        }
      ],
         fanMenu: [
           {
             href: "/member/dashboard",
             title: "Dashboard",
             icon: "fas fa-tachometer-alt",
           },
           {
             href: "/member/myprofile",
             title: "My Profile",
             icon: "far fa-address-card",
           },
           {
             href: "/member/membership-history",
             title: "Membership History",
             icon: "far fa-address-book",
           },
           {
             href: process.env.VUE_APP_SHOOTING_EMS_URL + "event_list",
             title: "Events",
             external: true,
             icon: "fas fa-table",
           },
           {
             href: "/member/preferences",
             title: "Preferences",
             icon: "fas fa-cogs",
           }
         ]
       },
       alliancefastpitch: {
        memberMenu: [
          {
            href: "/member/dashboard",
            title: "Dashboard",
            icon: "fas fa-tachometer-alt",
          },
          {
            href: "/member/myprofile",
            title: "My Profile",
            icon: "far fa-address-card",
          },       
          {
            href: "/member/membership-history",
            title: "Membership History",
            icon: "far fa-address-book",
          }
        ]
       },
      partnersStatus:false
    };
  },
  methods: {
    getProfilePicImg() {
      return (
        (this.memberInfoData.profile_pic != null
          ? process.env.VUE_APP_URL + this.memberInfoData.profile_pic
          : "/dist/img/avatarimg.png") +
        "?v=" +
        moment().format("x")
      );
    },
    linkPost(link, ispost){
      if(ispost != undefined && ispost == true){
        var form = document.createElement("form");
        form.method = "POST";
        form.action = link;
        form.target = "_blank";
        var id_element = document.createElement("input");
        id_element.type = "hidden";
        id_element.value = localStorage.id;
        id_element.name = "id";
        form.appendChild(id_element);
        var auth_element = document.createElement("input");
        auth_element.type = "hidden";
        auth_element.value = localStorage.auth;
        auth_element.name = "auth";
        form.appendChild(auth_element);

        document.body.appendChild(form);

        form.submit(); 
      }
    },
    partnerStatus(){
      axios.get(this.basePath+'api/partners/getPartnerStatus',{ headers:this.memberHeaders })
          .then(function (response) {
            if (response.data.status == 'success') {
              this.partnersStatus = response.data.data;
            }
             else
              console.log(response);
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    partnerMenu(){
      let memberMenu = {
        href: "/member/partners",
        title: "Partners",
        icon: "fas fa-handshake",
        permission: "partners"
      };
      console.log(this.menuItems);
      this.menuItems.push(memberMenu);
      console.log(this.menuItems);
    }
  },
  mounted() {
    this.memberInfoData = JSON.parse(localStorage.getItem("member"));
    this.role = JSON.parse(localStorage.getItem("role")); 

    EventBus.$on('changeProfileName', name => {
      if(this.memberInfoData.reg_type_id == 7)
        this.memberInfoData.campski_name = name;
      else
        this.memberInfoData.full_name = name;
    });
    EventBus.$on('getGroupCommittee', name => {
      this.groupCommittee = name;
    });
    let sso_cookie = {
      name : 'id',
      token: this.memberInfoData.person_id,
      domain: '.usawaterski.org'
    };
    let d = new Date();
    d.setTime(d.getTime() + (30 * 60 * 1000));
    let expires = "expires=" + d;
    this.$cookies.set(sso_cookie.name, sso_cookie.token, expires, "/", sso_cookie.domain, true,"None");
    this.$cookies.set("auth", true, expires, "/", sso_cookie.domain, true,"None");
    //document.cookie = sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    //document.cookie = "auth=" + true + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    console.log(sso_cookie.name+"=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/");
    console.log("auth=" + true + ";" + expires + ";domain="+sso_cookie.domain+";path=/");
    console.log('id');
    this.$cookies.get('id');
    console.log('auth');
    this.$cookies.get('auth');
    console.log('---------------');
    document.cookie = "_id=" + sso_cookie.token + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    document.cookie = "_auth=" + true + ";" + expires + ";domain="+sso_cookie.domain+";path=/";
    console.log(document.cookie);
    console.log('=============');
    localStorage.setItem("id", sso_cookie.token);
    localStorage.setItem("auth", true);

    if(this.subdomain == "waterski" && this.memberInfoData.reg_type_id == 3) this.menuItems = this.waterskifamilyMenu;
    else if(this.subdomain == "waterski" && this.memberInfoData.reg_type_id == 7) this.menuItems = this.campskimembermenu;
    else if(this.subdomain == "waterski" && this.memberInfoData.reg_membership_id == 13 || this.memberInfoData.reg_type_id == 5)this.menuItems = this.digitalMembermenu;    
    else if(this.subdomain == "waterski"  && this.groupCommittee.screening == true) this.menuItems = this.membermenu1;
    else if(this.subdomain == "waterski") this.menuItems = this.membermenu;  

    if(this.subdomain == "shooting" && this.memberInfoData.reg_type_id == 3) this.menuItems = this.shooting.familyMenu;
    else if(this.subdomain == "shooting" && this.memberInfoData.reg_type_id == 6) this.menuItems = this.shooting.fanClubMenu;
    else if(this.subdomain == "shooting" && this.memberInfoData.reg_type_id == 7) this.menuItems = this.shooting.fanMenu;
    else if(this.subdomain == "shooting") this.menuItems = this.shooting.memberMenu; 
    else if(this.subdomain == "alliancefastpitch") this.menuItems = this.alliancefastpitch.memberMenu;  
    

    
    this.menuItems = this.menuItems.filter((menu,i) => {
        if(!((menu.permission=="travel_form" && this.memberInfoData.travel_form==false)) )
        if(!((menu.permission=="volunteering" && this.memberInfoData.volunteering==false)) )
        return menu;
     });
    this.partnerStatus();
  },
  watch :{
    partnersStatus:function (val){
      this.partnersStatus = val;
      if(this.partnersStatus==true)
        this.partnerMenu();
    }
  }
};
</script>
<style >
.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #b33132 !important;
    color: #fff;
}
 .user-panel .info{
       white-space: initial !important;
 }
</style>