<template>
  <div>
    <main class="main" role="main">
      <div class=" bg-light h-100">
        <div class="container-fluid h-100">
          <div class="row">
            <div
                class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">

            </div>
            <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-0 rightside_section">
              <div class="card-header bg-white border-0 p-0">
                <div class="th_clr pt-4 pb-4 season_year text-center">
                  Become a Digital Supporting Member
                </div>
              </div>
              <div class="card-body bg-white  form-flds rm-shadow pb-4 mb-3 mbv-height-pad ml-1 mr-1">
                <form v-show="step == 1" class="" data-vv-scope="form-1">
                  <div class="row">

                    <div :class="[fields.firstname.colClass]" v-if="fields.firstname">
                      <div class="form-group">
                        <label for="first_name" class="form-label">
                          {{ fields.firstname.label }}<sup class="text-danger" v-if="fields.firstname.required">*</sup>
                        </label>
                        <input type="text" v-validate="'required'"
                               data-vv-as="first name"
                               :class="{ 'is-invalid': formErrors.has('form-1.first_name') }"
                               class="form-control" :placeholder="fields.firstname.placeholder"
                               maxlength="45" id="first_name" v-model="form.first_name"
                               name="first_name">
                        <div v-show="formErrors.has('form-1.first_name')" class="invalid-feedback">
                          {{ formErrors.first('form-1.first_name') }}
                        </div>
                        <span class="text-sm text-danger">{{ duplicateErrorForPersonal}}</span>
                        <a href="/member/login" v-if="duplicateError.showLink" target="_blank"> Click here</a>
                      </div>
                    </div>
                    <div :class="[fields.middlename.colClass]" v-if="fields.middlename">
                      <div class="form-group">
                        <label for="middlename" class="form-label">
                          {{ fields.middlename.label }}<sup class="text-danger"
                                                            v-if="fields.middlename.required">*</sup>
                        </label>
                        <input type="text" class="form-control" :placeholder="fields.middlename.placeholder"
                               maxlength="3" id="middlename" v-model="form.middle_name" name="form.middle_name"
                               value="">
                      </div>
                    </div>
                    <div :class="[fields.lastname.colClass]" v-if="fields.lastname">
                      <div class="form-group">
                        <label for="lastname" class="form-label">{{ fields.lastname.label }}<sup class="text-danger"
                                                                                                 v-if="fields.lastname.required">*</sup></label>
                        <input type="text" class="form-control"
                               v-validate="'required'"
                               data-vv-as="last name"
                               :class="{ 'is-invalid': formErrors.has('form-1.first_name') }"
                               :placeholder="fields.lastname.placeholder" maxlength="45" id="lastname"
                               v-model="form.last_name" name="last_name" value="">
                        <div v-show="formErrors.has('form-1.last_name')" class="invalid-feedback">
                          {{ formErrors.first('form-1.last_name') }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.suffix.colClass]" v-if="fields.suffix">
                      <div class="form-group">
                        <label for="suffix" class="form-label">{{ fields.suffix.label }}<sup class="text-danger"
                                                                                             v-if="fields.suffix.required">*</sup></label>
                        <input type="text" class="form-control" :placeholder="fields.suffix.placeholder" maxlength="45"
                               id="suffix" name="suffix" value="" v-model="form.suffix">
                      </div>
                    </div>
<!--                    <div :class="[fields.clubandteam.colClass]" v-if="fields.clubandteam">
                      <div class="form-group">
                        <label for="clubandteam" class="form-label">{{ fields.clubandteam.label }}<sup
                            class="text-danger" v-if="fields.clubandteam.required">*</sup></label>
                        <Select2 v-model="form.club_affiliate" :options="options"
                                 :placeholder="fields.clubandteam.placeholder"
                                 :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"/>
                        <p class="mt-2 font-13" v-if="fields.clubandteam.description"><i
                            v-html="fields.clubandteam.description"></i></p>
                      </div>
                    </div>-->
                    <div :class="[fields.birthday.colClass]" v-if="fields.birthday">
                      <div class="form-group">
                        <label for="birthday" class="form-label">{{ fields.birthday.label }} <sup class="text-danger"
                                                                                                  v-if="fields.birthday.required">*</sup></label>
                        <div class="input-group birthdate">
                          <div class="birthdaypicker">
                            <date-pick
                                v-model="form.dob"
                                :pickTime="false"
                                name="dob"
                                :format="'MM/DD/YYYY'"
                                :selectableYearRange="{from: 1900, to: 2021}"
                                v-mask="'##/##/####'"
                                :isDateDisabled="isFutureDate"
                                :inputAttributes="{placeholder: 'mm/dd/yyyy', 'v-validate':'required', 'data-vv-as':'dob'}"
                                :parseDate="parseDatePick"
                            ></date-pick>
                          </div>
                          <div v-show="formErrors.has('form-1.dob')" class="invalid-feedback">
                            {{ formErrors.first('form-1.dob') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.gender.colClass]">
                      <div class="form-group">
                        <label for="gender" class="form-label">{{ fields.gender.label }} <sup class="text-danger"
                                                                                              v-if="fields.gender.required">*</sup></label>
                        <div class="form-group" v-if="fields.gender.choices">
                          <template v-for="(choice, key) in fields.gender.choices">
                            <input :class="{ 'is-invalid': formErrors.has('form-1.gender') }" type="radio"
                                   v-validate="'required'" :id="choice.value" v-model="form.gender" name="gender"
                                   :value="choice.label" :key="'radio_' + key">
                            <label class="gender-label-span mr-1" :for="choice.value"
                                   :key="'gender_label_'+key">{{ choice.label }}</label>

                          </template>
                          <div v-show="formErrors.has('form-1.gender')" class="invalid-feedback">
                            {{ formErrors.first("form-1.gender") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.email.colClass]" v-if="fields.email">
                      <div class="form-group">
                        <label for="email" class="form-label">{{ fields.email.label }} <sup class="text-danger"
                                                                                            v-if="fields.email.required">*</sup></label>
                        <input type="text" class="form-control" v-validate="'required|email'"
                               :class="{ 'is-invalid': formErrors.has('form-1.email') }"
                               maxlength="45" id="email" v-model="form.email" name="email">
                        <div v-show="formErrors.has('form-1.email')" class="invalid-feedback">
                          {{ formErrors.first("form-1.email") }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.retype_email.colClass]" v-if="fields.retype_email">
                      <div class="form-group">
                        <label for="retype_email" class="form-label">{{ fields.retype_email.label }} <sup
                            class="text-danger" v-if="fields.retype_email.required">*</sup></label>
                        <input type="text" v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.retype_email') }"
                               class="form-control" maxlength="45" id="retype_email" v-model="form.retype_email"
                               name="retype_email">
                        <div v-show="formErrors.has('form-1.retype_email')" class="invalid-feedback">
                          {{ formErrors.first("form-1.retype_email") }}
                        </div>
                      </div>
                    </div>

                    <div :class="[fields.address1.colClass]" v-if="fields.address1">
                      <div class="form-group">
                        <label for="firstname" class="form-label">{{ fields.address1.label }} <sup class="text-danger"
                                                                                                   v-if="fields.address1.required">*</sup></label>
                        <input type="text" class="form-control" v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.address1') }"
                               maxlength="100" id="address1" v-model="form.address1" name="address1">
                        <div v-show="formErrors.has('form-1.address1')" class="invalid-feedback">
                          {{ formErrors.first("form-1.address1") }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.address2.colClass]" v-if="fields.address2">
                      <div class="form-group">
                        <label for="firstname" class="form-label">{{ fields.address2.label }}</label>
                        <input type="text" class="form-control" maxlength="45" id="address2" v-model="form.address2"
                               name="form.address2">
                        <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
                      </div>
                    </div>

                    <div :class="[fields.country.colClass]" v-if="fields.country">
                      <div class="form-group">
                        <label for="Country" class="form-label">{{ fields.country.label }} <sup class="text-danger"
                                                                                                v-if="fields.country.required">*</sup></label>
                        <select class="form-control" v-validate="'required'"
                                :class="{ 'is-invalid': formErrors.has('form-1.country') }" id="country"
                                v-model="form.country" name="country">
                          <option value="">Select</option>
                          <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                                  :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">
                            {{ listOfCountry.country }}
                          </option>
                        </select>
                        <div v-show="formErrors.has('form-1.country')" class="invalid-feedback">
                          {{ formErrors.first("form-1.country") }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.city.colClass]" v-if="fields.city">
                      <div class="form-group">
                        <label for="city" class="form-label">{{ fields.city.label }} <sup class="text-danger"
                                                                                          v-if="fields.city.required">*</sup></label>
                        <input type="text" class="form-control"
                               v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.city') }"
                               maxlength="45" id="city" v-model="form.city" name="city">
                        <div v-show="formErrors.has('form-1.city')" class="invalid-feedback">
                          {{ formErrors.first("form-1.city") }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.state_code.colClass]" v-if="form.country == 'USA' && fields.state_code">
                      <div class="form-group">
                        <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup
                            class="text-danger" v-if="fields.state_code.required">*</sup></label>
                        <select class="form-control" id="state_code"
                                v-validate="'required'"
                                data-vv-as="state"
                                :class="{ 'is-invalid': formErrors.has('form-1.state_code') }"
                                v-model="form.state_code" name="state_code">
                          <option value="">Select</option>
                          <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                  :value="listOfState.state_code">{{ listOfState.state }}
                          </option>
                        </select>
                        <div v-show="formErrors.has('form-1.state_code')" class="invalid-feedback">
                          {{ formErrors.first("form-1.state_code") }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.other_state.colClass]" v-else>
                      <div class="form-group" v-if="fields.other_state">
                        <label for="state_code" class="form-label">{{ fields.other_state.label }} <sup
                            class="text-danger" v-if="fields.other_state.required">*</sup></label>
                        <input type="text" class="form-control"
                               v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.state') }"
                               maxlength="45" v-model="form.state" name="state">
                        <div v-show="formErrors.has('form-1.state')" class="invalid-feedback">
                          {{ formErrors.first("form-1.state") }}
                        </div>
                      </div>
                    </div>

                    <div :class="[fields.zip.colClass]" v-if="fields.zip">
                      <div class="form-group">
                        <label for="zip" class="form-label">{{ fields.zip.label }} <sup class="text-danger"
                                                                                        v-if="fields.zip.required">*</sup></label>
                        <input type="text" class="form-control"
                               v-if="form.country == 'USA'"
                               v-validate="{ required: true, regex: /^\d{5}(-\d{4})?$/ }"
                               :class="{ 'is-invalid': formErrors.has('form-1.zip') }"
                                id="zip" v-model="form.zip" name="zip">
                        <input type="text" class="form-control" v-else
                               v-validate="'required'"
                               :class="{ 'is-invalid': formErrors.has('form-1.zip') }"
                                id="zip" v-model="form.zip" name="zip">
                        <div v-show="formErrors.has('form-1.zip')" class="invalid-feedback">
                          {{ formErrors.first("form-1.zip") }}
                        </div>
                      </div>
                    </div>

                    <div :class="[fields.homephone.colClass]" v-if="fields.homephone">
                      <div class="form-group ">
                        <div class="phone-nu-wrapper">
                          <label for="country_code_h" class="form-label">{{ fields.homephone.label }} <sup
                              class="text-danger" v-if="fields.homephone.required">*</sup></label>
                          <vue-tel-input v-model="form.phone_1" v-validate="'required'"
                                         :class="{ 'is-invalid': formErrors.has('form-1.phone_1') }" ref="phone"
                                         @validate="validateTelinput" v-bind="homeProps"
                                         :validCharactersOnly="true"></vue-tel-input>                          <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
                           <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                          <div v-show="formErrors.has('form-1.phone_1')" class="invalid-feedback">
                            {{ formErrors.first("form-1.phone_1") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.mobilephone.colClass]" v-if="fields.mobilephone">
                      <div class="form-group">
                        <div class="phone-nu-wrapper">
                          <label for="phone_2" class="form-label">{{ fields.mobilephone.label }}</label>
                          <vue-tel-input v-model="form.phone_2" ref="phone" v-bind="mobileProps"
                                         :validCharactersOnly="true"></vue-tel-input>
                          <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
                          <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                          <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
                        </div>
                      </div>
                    </div>

                    <div :class="[fields.sport_discipline.first_choice.colClass]"
                         v-if="fields.sport_discipline.first_choice">
                      <div class="form-group">
                        <label for="first-choice" class="form-label">{{ fields.sport_discipline.first_choice.label }}
                          <sup v-if="fields.sport_discipline.first_choice.required">*</sup></label>
                        <Select2 v-if="fields.sport_discipline.first_choice.multiple"
                                 v-validate="'required'"
                                 :class="{ 'is-invalid': formErrors.has('form-1.first_choice') }"
                                 v-model="form.first_choice" name="form.first_choice" id="disciplines"
                                 :options="disciplines_list"
                                 :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"/>
                        <select class="form-control" id="first-choice" name="first_choice"
                                v-validate="'required'"
                                :class="{ 'is-invalid': formErrors.has('form-1.first_choice') }"
                                v-model="form.first_choice" v-else>
                          <option value="">Select</option>
                          <option v-for="(discipline, index) in disciplines" :value="index"
                                  :key="'types'+index">{{ discipline }}
                          </option>
                        </select>
                        <div v-show="formErrors.has('form-1.first_choice')" class="invalid-feedback">
                          {{ formErrors.first("form-1.first_choice") }}
                        </div>
                      </div>
                    </div>
                    <div :class="[fields.sport_discipline.second_choice.colClass]"
                         v-if="fields.sport_discipline.second_choice">
                      <div class="form-group">
                        <label for="secondchoice" class="form-label">{{ fields.sport_discipline.second_choice.label }}
                          <sup v-if="fields.sport_discipline.second_choice.required">*</sup></label>
                        <select class="form-control" id="secondchoice" name="form.second_choice"
                                v-model="form.second_choice">
                          <option value="">Select</option>
                          <option v-for="(discipline, index) in disciplines" :value="index"
                                  :key="'types2-'+index">{{ discipline }}
                          </option>
                        </select>

                        <span class="font-13 text-danger">{{ errors.second_choice }}</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <span class="mt-2 font-13 font-italic">
                          <a target="_blank" href="https://www.teamusa.org/usa-water-ski/about-us/sport-discipline-descriptions">Click here</a> to learn more about the different sport disciplines
                        </span>
                      </div>
                    </div>
                    <div :class="[fields.race.colClass]" v-if="fields.race">
                      <div class="form-group radiotext">
                        <label for="race" class="form-label-check"><strong>{{ fields.race.label }}</strong> <sup
                            class="text-danger" v-if="fields.race.required">*</sup></label> <br>
                        <div v-for="diversity in diversities" :key="diversity.name">
                          <label :for="'diversity_id'+diversity.id">
                            <input type="radio" name="diversity_id" :value="diversity.id"
                                   v-validate="'required'"
                                   :class="{ 'is-invalid': formErrors.has('form-1.diversity_id') }"
                                   :id="'diversity_id'+diversity.id"
                                   v-model="form.diversity_id">
                            {{ diversity.name }}</label> <br>
                        </div>
                        <div v-show="formErrors.has('form-1.diversity_id')" class="invalid-feedback">
                          {{ formErrors.first("form-1.diversity_id") }}
                        </div>
                      </div>
                    </div>

                    <div :class="[fields.ethnicity.colClass]" v-if="fields.ethnicity">
                      <div class="form-group radiotext">
                        <label for="ethnicitystatus" class="form-label-check"><strong>{{
                            fields.ethnicity.label
                          }}</strong><sup class="text-danger" v-if="fields.ethnicity.required">*</sup></label>
                        <div class="form-group" v-if="fields.ethnicity.choices">
                          <template v-for="(choice, k) in fields.ethnicity.choices">
                            <input type="radio" :id="choice.id" name="ethnicity"
                                   v-validate="'required'"
                                   :class="{ 'is-invalid': formErrors.has('form-1.ethnicity') }"
                                   :value="choice.label" v-model="form.ethnicity" :key="'ethnicity_'+k">
                            <label :for="choice.id" :key="'ethnicity_label_'+k">{{ choice.label }}</label>
                            <br :key="'ethnicity_br_'+k">
                          </template>
                          <div v-show="formErrors.has('form-1.ethnicity')" class="invalid-feedback">
                            {{ formErrors.first("form-1.ethnicity") }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div :class="[fields.disability_status.colClass]" v-if="fields.disability_status">
                      <div class="form-group radiotext">
                        <label for="disabilitystatus" class="form-label-check"><strong>{{
                            fields.disability_status.label
                          }}</strong> <sup class="text-danger" v-if="fields.disability_status.required">*</sup></label>
                        <div class="form-group" v-if="fields.disability_status.choices">
                          <template v-for="(choice, k) in fields.disability_status.choices">
                            <input type="radio"
                                   v-validate="'required'"
                                   :class="{ 'is-invalid': formErrors.has('form-1.disability') }"
                                   :id="choice.id" name="disability" :value="choice.label" v-model="form.disability"
                                   :key="'ds_'+k">
                            <label :for="choice.id" :key="'ds_label_'+k">{{ choice.label }}</label>
                            <br :key="'ds_br_'+k">
                          </template>
                          <div v-show="formErrors.has('form-1.disability')" class="invalid-feedback">
                            {{ formErrors.first("form-1.disability") }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="text-right topspace">
                        <button type="submit" @click.prevent="registrationNextstep"
                                class="btn btn-outline-primary savebtn next-step-btn mr-0">
                          {{ button.nextButton }}
                        </button>
                      </div>
                    </div>

                  </div>
                </form>
                <div v-show="step == 2" class="col-md-12">
                  <div class="card-body confirmation">

                    <div class="col-md-12 pt-5">
                      <h3 class="text-center text-success">Thanks for the Registration.</h3>
                      <div class="form-group text-center">
                        <!--                                                        <label class="form-label">Transaction Id</label>-->

                        <p>You will receive an email shortly regarding this transaction along with your
                          membership information.</p>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12 text-center">
                      <!-- <a :href="receipt_url" class="btn btn-outline-primary mr-3">
                          <i class="far fa-file-pdf"></i> Download PDF
                      </a> -->
                      <router-link to="/member/login" class="btn btn-outline-dark mr-3">
                        <i class="fas fa-lock"></i> Login as Member
                      </router-link>

                      <a href="/digital-registration" class="btn btn-outline-dark">
                        <i class="fas fa-user-plus"></i> Register New Digital Supporting Member
                      </a>
                    </div>
                  </div>
                </div>

              </div>

              <div class="footer-inner">

                <div class="row footer-row">
                  <div class="col-md-7">
                    <p class="text-danger">USA Water Ski &amp; Wake Sports, Inc. {{ new Date().getFullYear() }}</p>

                  </div>
                  <div class="col-md-5">
                    <p class="text-right">Powered by<img
                        v-bind:src="'/dist/img/logo.svg'" style="width:80px" alt="footer-img"/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import DatePick from 'vue-date-pick';

export default {
  name: "DigitalMembership",
  data() {
    return {
      duplicateError:{
        showLink:false,
      },
      duplicateErrorForPersonal:'',
      button: {
        nextButton: 'Register',
      },
      step: 1,
      form: {
        membership_id: '13',
        title: 'Mr.',
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        gender: '',
        dob: '',
        relation: 0,
        citizenship: '',
        affiliate: '',
        veteran_status: '',
        club: '',
        new_club_name: '',
        new_club_email: '',
        new_club_phone: '',
        club_affiliate: '',
        diversity_id: '',
        ethnicity: '',
        disability: '',
        email: '',
        retype_email: '',
        printed_magazine: '',
        address_type: 'Home',
        address1: '',
        address2: '',
        zip: '',
        state_code: '',
        state: '',
        city: '',
        country: 'USA',
        phone_1: '',
        phone_2: '',
        company_name: '',
        company_phone: '',
        company_phone_extension: ''
      },
      fields: {},
      options: [],
      diversities: [],
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Mobile Number",
        // maxLen: 30,
      },
      companyPhoneExtProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Company Phone Extension",
        // maxLen: 30,
      },
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Home Number",
        // maxLen: 30,
      }
    }
  },
  methods: {
    registrationNextstep: function () {
      this.$validator.validateAll('form-1').then((result) => {
        if (result) {
          this.button.nextButton = 'Registration is in progress...';
          axios.post(this.basePath + 'api/saveDigital', this.form)
              .then((res) => {
                if (res.data.message == 'Duplicate member found') {
                  this.duplicateErrorForPersonal = 'Your account is already in the system, please login and renew using the link given below. ';
                  this.duplicateError.showLink = true;
                  this.button.nextButton = 'Register';
                } else {
                  this.button.nextButton = 'Register';
                  this.step++;
                }
              })
              .catch((error) => {
                console.log('Error:' + error);
                //this.step--;
                this.button.nextButton = 'Register';
                return false;
                // error.response.status Check status code
              }).finally(() => {
            console.log('Finally');
          });

        } else {
          console.log(this.formErrors.first('form-1.first_name'));
          document.getElementById('app').scrollIntoView();
        }

      });
    },
    getClubs() {
      axios.get(this.basePath + "api/get_teams")
          .then((response) => {
            var clubs = response.data.data;
            this.options = clubs;
          })
          .catch(error => {
            console.log("error geting clubs list")
          })
    },
    getDiscipline() {
      axios.get(this.basePath + "api/getDisciplines")
          .then(response => {
            this.disciplines = response.data.data;
            let discipline_codes = Object.keys(this.disciplines);
            if (discipline_codes.length && this.disciplines_list.length == 0) {
              discipline_codes.forEach(code => {
                this.disciplines_list.push({
                  id: code,
                  text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code]
                });
              });
            }
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getDiversity() {
      axios.get(this.basePath + "api/getDiversity")
          .then(response => {
            this.diversities = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
  },
  mounted() {
    const retry = setInterval(() => {
      let steps = this.getSiteProps('memberRegistration.digital');

      if (steps != "" && steps.fields != undefined) {
        let sections = Object.keys(steps.fields);


        if (sections && sections.length) {
          sections.forEach(name => {
            let obj = this.getSiteProps('memberRegistration.digital.fields.' + name);
            this.fields = Object.assign({}, this.fields, obj);
          });
        }
        clearInterval(retry);
      }
    }, 200);

    this.getClubs();
    this.getDiscipline();
    this.getDiversity();

  }
}
</script>

<style scoped>

</style>