<template>
    <div class="d-flex justify-content-center" style="position: absolute; top: 75px; right: 20px; z-index: 99999;">
        <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="variant || 'success'"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
        >
            <p v-html="message+customMsg"></p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
        </b-alert>
    </div>
</template>

<script>
export default {
    name: "CustomAlert",
    props:['message','newDismissSecs','variant'],
    data(){
        return {
            dismissSecs: 10 - (parseInt(this.newDismissSecs) || 0),
            dismissCountDown: 0,
            showDismissibleAlert: false,
            customMsg:""
        }
    },
    methods:{
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
            if(this.dismissCountDown==0){
                this.$emit('showedAlert');
            }
        },
        showAlerts() {
            this.dismissCountDown = this.dismissSecs;
        }
    },
    mounted() {
        this.showAlerts();
    },
}
</script>

<style scoped>
.alert{
    position: fixed;
    top:100px;
    right:50px;
}
</style>
