<template>
    <div class="login-box">
        <Logo/>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <h4 class="login-box-msg ">Enterprise Platform</h4>
                <div class="alert alert-success fade in alert-dismissible show" style="margin-top:18px;"
                     v-bind:class="{ 'd-none': isActiveMessage }">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true" style="font-size:20px">×</span>
                    </button>
                    <strong>Success!</strong> {{ message }}
                    <p>
                        <router-link to="/"><a>Click here to Login</a></router-link>
                    </p>
                </div>
                <form @submit.prevent="checkReset" method="post" :class="showDone == true ? 'd-none' : ''">
                    <div class="input-group mb-3">
                        <input v-model="email" placeholder="Email" class="form-control" disabled
                               name="email">

                    </div>
                    <input type="hidden" v-model="token"/>
                    <div class="input-group mb-3">
                        <input v-model="password" placeholder="Password" class="form-control" :type="passwordType"
                               v-bind:class="{ 'is-invalid': isActivePassword }" autocomplete="off"
                               name="email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span :class="showPass" v-on:click="showPassword('password')"
                                      class="fas "></span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="{ error: isActivePassword }">{{ errors_pass }}</div>
                    <div class="input-group mb-3">
                        <input v-model="confirm_password" placeholder="Confirm Password" class="form-control"
                               :type="confirmPasswordType" v-bind:class="{ 'is-invalid': isActivecPassword }"
                               name="email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas" :class="showPassConfirm" v-on:click="showPassword('')"></span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="{ error: isActivecPassword }">{{ errors_cpass }}</div>
                    <div v-bind:class="{ error: isActivemPassword }">{{ errors_mpass }}</div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block">Submit</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
                <div class="alert alert-info alert-dismissible mt-3" :class="showDone == true ? 'd-none' : ''">
                    <h5><i class="icon fas fa-info"></i> Rules</h5>
                    <ul class="list-unstyled">
                        <li>One lowercase letter required.</li>
                        <li>One uppercase letter required.</li>
                        <li>One special character required.</li>
                        <li>8 characters minimum.</li>
                        <li>One number required.</li>
                    </ul>
                </div>
                <Footer></Footer>
            </div>
            <!-- /.login-card-body -->

        </div>
    </div>
</template>

<script>
import Logo from './AuthHead.vue';
import Footer from './AuthFoot.vue';
import axios from 'axios';

export default {
    name: 'MyComponent',
    components: {
        Logo, Footer
    },
    data() {
        return {
            email: this.$route.query.email,
            token: this.$route.query.token,
            passwordType: 'password',
            password: '',
            confirm_password: '',
            confirmPasswordType: 'password',
            isActivePassword: false,
            isActivecPassword: false,
            isActivemPassword: false,
            errors_pass: '',
            errors_cpass: '',
            errors_mpass: '',
            isActiveMessage: true,
            isCloseform: false,
            showDone:false,
            message: '',
            showPass: 'fa-eye',
            showPassConfirm: 'fa-eye',
            regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?=.{8,}).*$/
        }
    },
    methods: {
        checkReset: function () {
            if (this.password != '') {
                if (!this.regex.test(this.password)) {
                    this.errors_pass = 'Enter valid password.';
                    this.isActivePassword = true;
                } else {
                    if (this.password !== '' && this.confirm_password !== '') {
                        if (this.password === this.confirm_password) {
                            this.errors_mpass = '';
                            this.isActivemPassword = false;
                            axios.post(process.env.VUE_APP_URL + 'api/password_reset', {
                                email: this.email,
                                token: this.token,
                                password: this.password,
                                password_confirmation: this.confirm_password
                            }).then(response => {
                                if (response.data.status == 'success') {
                                    this.isActiveMessage = false;
                                    this.email = '';
                                    this.password = '';
                                    this.isCloseform = true;
                                    this.showDone=true;
                                    this.confirm_password = '';
                                    this.message = response.data.message;
                                    this.$router.push({path: '/login'});
                                } else {
                                    this.errors_cpass = 'Unable to reset password.';
                                    this.isActivecPassword = true;
                                }
                            }).catch(error => {

                                this.loaded = true;
                                if (error.response.status === 422) {
                                    this.errors = error.response.data.errors || {};
                                }
                            });

                        } else {
                            this.isActivemPassword = true;
                            this.errors_mpass = "The password and confirm password does not match.";
                        }
                    } else {
                        this.errors_mpass = '';
                        this.isActivemPassword = false;
                    }
                    this.isActivePassword = false;
                    this.errors_pass = "";
                }
            } else {
                this.errors_pass = 'The password field is required.';
                this.isActivePassword = true;
            }
            if (this.confirm_password == '') {
                this.isActivecPassword = true;
                this.errors_cpass = "The confirm password field is required.";
            } else {
                this.errors_cpass = '';
                this.isActivecPassword = false;
            }


        },
        showPassword(val) {
            if (val == 'password') {
                if (this.passwordType == 'password') {
                    this.passwordType = 'text';
                    this.showPass = 'fa-eye-slash'
                } else {
                    this.passwordType = 'password';
                    this.showPass = 'fa-eye';
                }
            } else {
                if (this.confirmPasswordType == 'password') {
                    this.confirmPasswordType = 'text';
                    this.showPassConfirm = 'fa-eye-slash'
                } else {
                    this.confirmPasswordType = 'password';
                    this.showPassConfirm = 'fa-eye';
                }
            }
        }

    }
}
</script>

