<template>
<b-modal id="modal-family-update" scrollable size="xl" v-bind:hide-footer="false" @ok="UpdateMemberInfo" :ok-disabled="btn_submit.load" v-bind:ok-title="btn_submit.text" ref="modal-family-member-update">
	<custom-alert v-if="displayAlert==true" id="alert_box" :newDismissSecs="newDismissSecs" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
	<template slot="modal-title">Edit Family Member Contact Information</template>
	<form ref="form" method="post" @submit.stop.prevent>
		<div class="row">
			<div class="col-xl-4 col-lg-4 col-md-12">
				<b-form-group class="mb-4">
					<label class="form-label">Email-ID <span class="text-danger">*</span></label>
					<b-form-input type="email" v-model="FamilyMember.email" name="FamilyMember.email" id="FamilyMember.email" :class="errors.family.email.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.email.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.email.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6">
				<b-form-group class="mb-4">
					<label class="form-label">Home phone with Country Code <span class="text-danger">*</span></label>
					<vue-tel-input :inputOptions="phoneOptions" name="FamilyMember.phone_1" id="FamilyMember.phone_1" v-model="FamilyMember.phone_1" :validCharactersOnly="true" v-bind="mobileProps" :class="errors.family.phone_1.valid == false ? 'is-invalid invalid border-danger' : ''" @validate="validateTelinput"></vue-tel-input>
					<b-form-invalid-feedback v-if="errors.family.phone_1.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.phone_1.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6">
				<b-form-group class="mb-4">
					<label class="form-label" for="phone_2"> Mobile with Country Code </label><br>
					<!-- <b-form-input type="tel" v-model="FamilyMember.phone_2" name="FamilyMember.phone_2" id="FamilyMember.phone_2"></b-form-input> -->
					<vue-tel-input :inputOptions="phoneOptions" v-model="FamilyMember.phone_2" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6">
				<b-form-group class="mb-4">
					<label class="form-label">Mailing Address <span class="text-danger">*</span><span v-if="props_fields.contact.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ props_fields.contact.address1.charLimitText }}</span></label>
					<b-form-input v-model="FamilyMember.address1" :maxlength=props_fields.contact.address1.charLimitValue name="FamilyMember.address1" id="FamilyMember.address1" :class="errors.family.address1.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.address1.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.address1.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6">
				<b-form-group class="mb-4">
					<label class="form-label">Address 2 <span v-if="props_fields.contact.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ props_fields.contact.address2.charLimitText }}</span></label>
					<b-form-input v-model="FamilyMember.address2" :maxlength=props_fields.contact.address2.charLimitValue name="FamilyMember.address2" id="FamilyMember.address2"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6 pb-4">
				<label class="form-label">Country <sup class="text-danger">*</sup></label>
				<b-form-select v-model="FamilyMember.country" name="FamilyMember.country" id="FamilyMember.country" :options="listOfCountries" value-field="country" text-field="country" :class="errors.family.country.valid == false ? 'is-invalid invalid' : ''" v-on:change="validatePhysicalCountry()"></b-form-select>
				<b-form-invalid-feedback v-if="errors.family.country.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.country.message }}</b-form-invalid-feedback>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6">
				<div class="pb-4" v-if="FamilyMember.country == 'USA'">
					<label class="form-label">State <sup class="text-danger">*</sup></label>
					<b-form-select v-model="FamilyMember.state_code" name="FamilyMember.state_code" id="FamilyMember.state_code" :options="listOfStates" value-field="state_code" text-field="state" :class="errors.family.state_code.valid == false ? 'is-invalid invalid' : ''"></b-form-select>
				</div>
				<div class="pb-4" v-else-if="FamilyMember.country == 'Canada'">
					<label class="form-label">State <sup class="text-danger">*</sup></label>
					<b-form-select v-model="FamilyMember.state" name="FamilyMember.state" id="FamilyMember.state" :options="listOfCAStates" value-field="state" text-field="state" :class="errors.family.state.valid == false ? 'is-invalid invalid' : ''"></b-form-select>
				</div>
				<div v-else>
					<b-form-group class="mb-4">
						<label class="form-label">State <sup class="text-danger"></sup></label>
						<b-form-input v-model="FamilyMember.state" maxlength="45" id="FamilyMember.state" name="FamilyMember.state" :class="errors.family.state.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
						<b-form-invalid-feedback v-if="errors.family.state.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.state.message }}</b-form-invalid-feedback>
					</b-form-group>
				</div>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6">
				<b-form-group class="mb-4">
					<label class="form-label">City <sup class="text-danger">*</sup></label>
					<b-form-input v-model="FamilyMember.city" maxlength="45" id="FamilyMember.city" name="FamilyMember.city" :class="errors.family.city.valid == false ? 'is-invalid invalid border-danger' : ''" v-on:change="validatePhysicalCity()"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.city.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.city.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-4 col-lg-6 col-sm-6">
				<b-form-group class="mb-4">
					<label class="form-label">Postal Code <sup class="text-danger">*</sup></label>
					<b-form-input v-model="FamilyMember.zip" name="FamilyMember.zip" id="FamilyMember.zip" maxlength="10" :class="errors.family.zip.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.zip.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.zip.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
		</div>
	</form>
</b-modal>
</template>
<script>
import moment from "moment";
import axios from 'axios';
import $ from 'jquery';
import Select2 from "v-select2-component";
import { ToastPlugin } from 'bootstrap-vue';
import { VueTelInput } from 'vue-tel-input';
import CustomAlert from "../../CustomAlert";
export default {
	name: "familymemberprofile",
	components: {CustomAlert},
	data() {
		return {
			btn_submit:{load:false, text: 'Update' },
			disciplines_list:[],
			homeProps: {
				mode: "international",
				defaultCountry: "US",
				placeholder: "Home Number",
        maxLen: 20,
			},
			props_fields: null,
			mobileProps: {
				mode: "international",
				defaultCountry: "US",
				placeholder: "Mobile Number",
				maxLen: 20,
			},
			FamilyMember: {
				id:"",
				confirmation_code: "",
				membership_id: 5,
				member_type_id: 3,
				hoh_member_id: "",
				email: "",
				phone_1: "",
				phone_2: "",
				address1: "",
				address2: "",
				country: "",
				state_code: "",
				state: "",
				city: "",
				zip: "",
			},
			errors: {
				status: true,
				family: {
					email: { valid:true, message:"" },
					phone_1: { valid:true, message:"" },
					first_choice: { valid:true, message:"" },
					address1: { valid:true, message:"" },
					country: { valid:true, message:"" },
					state_code: { valid:true, message:"" },
					state: { valid:true, message:"" },
					city: { valid:true, message:"" },
					zip: { valid:true, message:"" },
				}
			}
		}
	},
	methods: {
		resetAlert(){
			this.newDismissSecs = 0;
                this.displayAlert = false;
                this.removeDangerBg();
        },
		setDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-success');
                                $('#alert_box').children('div').addClass('alert-danger');
                    }, 100);
        },
		removeDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-danger');
                                $('#alert_box').children('div').addClass('alert-success');
                    }, 100);
        },
		showEditModal(arg) {
			for (const [key, value] of Object.entries(this.errors.family)) { this.errors.family[key]['valid'] = true; this.errors.family[key]['message'] = ""; }
			if(Object.keys(arg).length > 0) {
				this.$refs['modal-family-member-update'].show();
				this.btn_submit.load = false;
				this.btn_submit.text = 'Update';

				this.FamilyMember.id = arg.id;
				this.FamilyMember.confirmation_code = arg.confirmation_code;
				this.FamilyMember.hoh_member_id = arg.personal_details.hoh_member_id;
				this.FamilyMember.email = arg.contact_details.email;
				this.FamilyMember.phone_1 = arg.contact_details.phone_1;
				this.FamilyMember.phone_2 = arg.contact_details.phone_2;
				this.FamilyMember.address1 = arg.contact_details.address1;
				this.FamilyMember.address2 = arg.contact_details.address2;
				this.FamilyMember.country = arg.contact_details.country;
				this.FamilyMember.state = arg.contact_details.state;
				this.FamilyMember.state_code = arg.contact_details.state_code;
				this.FamilyMember.city = arg.contact_details.city;
				this.FamilyMember.zip = arg.contact_details.zip;
			} else {
				this.setDangerBg();
				this.alertMessage = "Member Information not found....";
                this.displayAlert = true;
				this.newDismissSecs = 8;
			}
		},
		DisciplinesList() {
			axios.get(this.basePath+"api/getDisciplines").then(response => {
				this.disciplines = response.data.data;
				let discipline_codes = Object.keys(this.disciplines);
				if(discipline_codes.length && this.disciplines_list.length == 0) {
					this.disciplines_list.push({ id: "", text: "Select" });
					discipline_codes.forEach(code => {
						this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
					});
				}
			}).catch(err => { });
		},
		validateEmailID() {
			if(this.FamilyMember.email == '' || this.FamilyMember.email == null) {
				this.errors.status = false;
				this.errors.family.email.valid = false;
				this.errors.family.email.message = "Enter Email-ID";
			} else if(!String(this.FamilyMember.email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
				this.errors.status = false;
				this.errors.family.email.valid = false;
				this.errors.family.email.message = "Enter Valid Email-ID";
			} else {
				this.errors.family.email.valid = true;
				this.errors.family.email.message = "";
			}
		},
		validateTelinput(obj) {
			if(!obj.valid) {
				this.errors.status = false;
				this.errors.family.phone_1.valid = false;
				this.errors.family.phone_1.message = "Enter the Exact phone number(Example:+XX XX XXXX XXXX).";
			}
		},
		validatePhone_1() {
			if(this.FamilyMember.phone_1 == '' || this.FamilyMember.phone_1 == null) {
				this.errors.status = false;
				this.errors.family.phone_1.valid = false;
				this.errors.family.phone_1.message = "Enter the Exact phone number(Example:+XX XX XXXX XXXX).";
			} else {
				this.errors.family.phone_1.valid = true;
				this.errors.family.phone_1.message = "";
			}
		},
		validateMailingAddress() {
			if(this.FamilyMember.address1 == '' || this.FamilyMember.address1 == null) {
				this.errors.status = false;
				this.errors.family.address1.valid = false;
				this.errors.family.address1.message = "Enter Mailing Address";
			} else {
				this.errors.family.address1.valid = true;
				this.errors.family.address1.message = "";
			}
		},
		validatePhysicalCountry() {
			if(this.FamilyMember.country == '' || this.FamilyMember.country == null) {
				this.errors.status = false;
				this.errors.family.country.valid = false;
				this.errors.family.country.message = "Selectr country";
			} else {
				this.errors.family.country.valid = true;
				this.errors.family.country.message = "";
				
				this.errors.family.state_code.valid = true;
				this.errors.family.state_code.message = "";
				this.errors.family.state.valid = true;
				this.errors.family.state.message = "";
				
			}
		},
		validatePhysicalState() {
			if(this.FamilyMember.state_code == '') {
				this.errors.status = false;
				this.errors.family.state_code.valid = false;
				this.errors.family.state_code.message = "Enter your city name";
			} else {
				this.errors.family.state_code.valid = true;
				this.errors.family.state_code.message = "";
			}
		},
		validateOtherState() {
			if(this.FamilyMember.state == '' || this.FamilyMember.state == null) {
				this.errors.status = false;
				this.errors.family.state.valid = false;
				this.errors.family.state.message = "Enter state name";
			} else {
				this.errors.family.state.valid = true;
				this.errors.family.state.message = "";
			}
		},
		validatePhysicalCity() {
			if(this.FamilyMember.city == '' || this.FamilyMember.city == null) {
				this.errors.status = false;
				this.errors.family.city.valid = false;
				this.errors.family.city.message = "Enter your city name";
			} else {
				this.errors.family.city.valid = true;
				this.errors.family.city.message = "";
			}
		},
		validatePhysicalZip() {
			if(this.FamilyMember.zip == '' || this.FamilyMember.zip == null) {
				this.errors.status = false;
				this.errors.family.zip.valid = false;
				this.errors.family.zip.message = "Enter postal/zip code";
			}  else if(!this.FamilyMember.zip.match(/^\d{5}(-\d{4})?$/) && this.FamilyMember.country == 'USA'){
				this.errors.status = false;
				this.errors.family.zip.valid = false;
				this.errors.family.zip.message = "Zip Code Must Be Atleast 5 Character";
			} else {
				this.errors.family.zip.valid = true;
				this.errors.family.zip.message = "";
			}
		},
		/*	End :	Add/Edit Form Validation Rules	*/
		UpdateMemberValidate() {
			this.errors.status = true;
			this.validateEmailID(); //Email-ID validation
			this.validatePhone_1({}); //Mobile Number validation
			this.validateMailingAddress() // mailing Address
			this.validatePhysicalCountry() //Physical Country Name
			if(this.FamilyMember.country == 'USA') {
				this.validatePhysicalState(); //Physical State Name
				this.FamilyMember.state = '';
				this.errors.family.state.valid = true;
				this.errors.family.state.message = "";
			} else if(this.FamilyMember.country == 'Canada'){
				this.validateOtherState() //Other State Name
				this.FamilyMember.state_code = "";
				this.errors.family.state_code.valid = true;
				this.errors.family.state_code.message = "";
			}
			this.validatePhysicalCity(); //Physical City Name
			this.validatePhysicalZip(); //Physical postal zip code
		},
		UpdateMemberInfo(bvModalEvt){
			bvModalEvt.preventDefault();
			this.UpdateMemberValidate();
			if(this.errors.status == false){
				this.setDangerBg();
				this.alertMessage = "Please fill all mandatory fields";
                this.displayAlert = true;
				this.newDismissSecs = 8;
			} else {
				this.btn_submit.load = true;
				this.btn_submit.text = 'Updating...';
				axios.post(this.basePath+"api/member/family/editcontact", this.FamilyMember, { headers:this.memberHeaders })
				.then(function (response) {
					if(response.data.status == 'success') {
						this.alertMessage = "Family Member Contact Information updated successfully";
                		this.displayAlert = true;
						this.newDismissSecs = 7;
						setTimeout(() => {
						this.$refs['modal-family-member-update'].hide();
						this.$emit('memfresh', {loader: true, id: this.FamilyMember.id });
						}, 3000);
					} else  {
						alert("Error");
					}
				}.bind(this)).catch(function (error) {
					console.log("Error: "+error);
				});
			}
		},
	},
	mounted() {
		this.DisciplinesList();
		const retry = setInterval(() => {
            this.props_fields = this.getSiteProps('memberRegistration.individual.fields');
            if(this.props_fields) clearInterval(retry);
        }, 200);
	}
}
</script>

<style scoped>
.select2-container {
	z-index:1999;
}
</style>