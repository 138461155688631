<template>
    <form @submit.prevent="donationAmountData" method="post">
        <div class="card donation">
           <div class="card-header register-title">
              <h3>{{ fields.tab_title || 'Donation'}}</h3>
           </div>
           <div class="card-body pr-4 pl-4">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group radiotext">
                        <label for="sport-trained" class="form-label-check">
                            <strong>{{ fields.confirm_text }}<sup class="text-danger">*</sup></strong>
                        </label><br>
                        <div>
                            <input type="radio" name="form.confirm_text" id="donation_confirm_text_yes" v-model="form.donation_confirm" value="1" @change="validateConfirmation">
                            <label for="donation_confirm_text_yes">Yes</label>
                        </div>
                        <div>
                            <input type="radio" name="form.confirm_text" id="donation_confirm_text_no" v-model="form.donation_confirm" value="0" @change="validateConfirmation">
                            <label for="donation_confirm_text_no">No</label>
                        </div>
                        <span class="text-sm text-danger">{{ errors.donation_confirm }}</span>
                    </div>
                </div>
              </div>
              <div class="row" v-if="form.donation_confirm == '1'">
                <div class="col-md-12" v-if="fields.disciplines">
                    <label for="select-team" class="form-label text-justify">{{ fields.disciplines.label }}</label>
                    <!--<small class="text-danger">You will be e-mailed a receipt at the end of transaction tax purposes</small>-->
                   <div class="form-group">
                       <!-- <label for="select-team" class="form-label">Make a donation to the following sport discipline fund</label> -->
                       <select class="form-control" id="select-team" v-model="form.donation_discipline" name="form.donation_discipline">
                           <!-- <option v-for="(SportDiscipline, id) in listOfDonationSportDiscipline" :value="id" :key="id">{{ SportDiscipline }}</option> -->
                           <option v-for="(SportDiscipline, id) in sortedDonations(fields.disciplines.options)" :value="SportDiscipline.id" :key="'donations-'+id">{{ SportDiscipline.name }}</option>
                       </select>
                       <span class="text-sm text-danger">{{ errors.donation_discipline }}</span>
                    </div>
                 </div>
                 <div class="col-md-12" v-if="fields.amount">
                    <label for="donation-amount" class="form-label">{{ fields.amount.label }}</label>
                     <input type="number" class="form-control" maxlength="13" id="donation-amount" v-model="form.donation_amount"
                            name="form.donation_amount" placeholder="10" @keyup="amountFormat" min="1">
                     <span class="text-sm text-danger">{{ errors.donation_amount }}</span>
                 </div>
              </div>
               <div class="col-md-12 pr-0">
                   <div class="text-right topspace">
                       <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                       <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">{{ button.nextButton }}<i class="fas fa-chevron-right pl-2"></i></button>
                   </div>
               </div>
           </div>
        </div>
    </form>
</template>
<script>
const validateAmount = name => {
    if (!name.length) {
        return { valid: false, error: "This field is required" };
    } else if(name == '') {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateSelect = select => {
    if (!select) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
export default {
    name: "donation",
    props: ['button', 'fields'],
    data() {
        return {
            form: {
                donation_amount: '',
                donation_discipline: '0',
                donation_confirm: ""
            },
            valid: false,
            success: false,
            errors: {},
            message: null
        }
    },
    methods: {
        validateConfirmation(){
            this.errors.donation_confirm = "";
            if(this.form.donation_confirm == ""){
                this.errors.donation_confirm = "Please confirm that you want to make a donation";
                return false;
            }
            if(this.form.donation_confirm == "0"){
              this.form.donation_discipline = '0';
              this.form.donation_amount = '';
            }
            return true;
        },
        donationAmountData() {
            if(this.validate()) {
                console.log('valid');
                //this.button.nextStepButton = 'Payment is in progress...';
                this.$emit('donation-amount-data', this.form);
                //this.button = 'Next';
                return;
            } else {
                console.log('un valid');
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        // Custom Validation Rules
        validate() {
            this.errors = {};
            this.valid = false;

            const validDonationDiscipline = validateSelect(this.form.donation_discipline);
            const validDonationAmount = validateAmount(this.form.donation_amount);
   
            if(!this.validateConfirmation()) return false;


            if (validDonationDiscipline.valid && this.form.donation_discipline != "0") {
                if (!validDonationAmount.valid) {
                    this.errors.donation_amount = validDonationAmount.error;
                    this.valid = false;
                } else {
                    this.valid = true;
                }
            } else {
                if(validDonationAmount.valid && !validDonationDiscipline.valid) {
                    this.errors.donation_discipline = validDonationDiscipline.error;
                    this.valid = false;
                } else {
                    this.valid = true;
                }
            }

            if(  this.form.donation_amount.length && this.form.donation_amount != '' && this.form.donation_discipline == "0" ){
                this.errors.donation_discipline = 'This field is required';
                this.valid = false;
            }

            return this.valid;
        },
        amountFormat() {
            let s = this.form.donation_amount;
            var rgx = /^[0-9]*\.?[0-9]*$/;
            this.form.donation_amount = s.match(rgx);
        }
    }
}
</script>