<template>
<div class="card club-info">
   <div class="card-header register-title">
      <h3>{{ fields.tab_title || 'Purchase Certificate of Insurance (Optional)'}}</h3>
   </div>
   <div class="card-body">
      <div class="row">
         <div class="col-md-12" >
            <p><strong v-if="fields.subtitle">{{ fields.subtitle }}</strong></p>
            <p  class="font-13" v-if="fields.description" v-html="fields.description"></p>
            <small class="text-small" v-if="fields.note">
                <b>Note</b>: <span v-html="fields.note"></span>
            </small>


            <!--<p class="font-13"><i>Complete this information for each third-party requiring a certificate of insurance from your club ($50 each) with respect to USA Water Ski &amp; Wake Sports sanctioned events. All certificates of insurance will be emailed to the club contact person provided. Note: Coverage only applies with respect to tournaments, practices, exhibitions, clinics and related activities sanctioned and
approved by USA Water Ski &amp; Wake Sports.Complete for each third party requiring a Certificate of Insurance from your club with respect to USA Water Ski-sanctioned events.</i></p>-->
         </div>
      </div>
      <!--  <div class="row insureddescription">
                <div class="col-md-6">
                    <span class="title">Insured Name</span>
                <span class="ins-description">John William Doe</span>
                </div>
                <div class="col-md-6 text-right">
                    <span class="title">Action</span>
                    <div class="d-flex text-right action-css">
                    <span class="ins-description preview">P</span>
                    <span class="ins-description remove">X</span>
                </div>
            </div>-->

            <div v-if="form.name != ''" class=" insureddescription">
                <div class="row forborder" v-for="(Insurance, y) in myInsurances" :key="y" >
                    <div class="col-md-6">
                        <span class="title" v-if="Insurance.company_name">{{ Insurance.company_name }}</span>
                        <span class="title" v-else>{{ Insurance.first_name+' '+Insurance.last_name }}</span>
                    </div>
                    <div class="col-md-6 text-right">
                        <a class="btn-edit btn float-right mt-0" @click="editInsurance(y)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</a>
                        <a class="btn-remove btn float-right mt-0" @click="removeInsurance(y)"><img v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon"> Remove</a>
                    </div>
                </div>
                <div class="col-md-12">
                <span v-if="form.name == ''" class="ins-description">you currently have no certificate of Insurance set up</span>
                </div>
            </div>

                    <div class="row">
                        <div class="col-md-12 addnewname">
                            <b-button v-b-modal.modal-center6 :disabled="insuranceFee === 0" @click.prevent="insurenceInfoModal">Purchase Certificate</b-button>
                            <p class="text-sm text-danger" v-if="insuranceFee === 0"><br>Certificate of Insurance purchase is disabled now.</p>
                            <span class="text-sm text-danger"><br>{{ errors.primary }}</span>
                                <b-modal ref="modal"
                                        ok-only
                                        ok-title="submit"
                                         @show="resetModal"
                                         @hidden="resetModal"
                                         @ok="handleOk" id="modal-center6" size="lg" centered :title="fields.add.title || 'Certificate of Insurance Request'">
                                    <form ref="form" @submit.stop.prevent="handleSubmit">
                                        <div class="popupbody">
                                            <!--<h5>Certificate of Insurance Request</h5>-->
                                            <p class="font-13 mb-1 text-justify" v-if="fields.add.description" v-html="fields.add.description"></p>
                                            <p class="font-13 text-danger text-justify" v-if="fields.add.note">
                                                <i>Note: <span v-html="fields.add.note"></span></i>
                                            </p>
                                            <div class="newname-inner">
                                                <div class="row">
                                                    <div class="col-md-6" v-if="fields.add.fields.companyname && !notACompany">
                                                      <div class="row">
                                                        <div class="col-md-12">
                                                          <div class="form-group">
                                                            <label for="companyName" class="form-label">{{ fields.add.fields.companyname.label }} <sup class="text-danger" v-if="fields.add.fields.companyname.required">*</sup></label>
                                                            <input id="companyName" type="text" maxlength="250" class="form-control" name="form.name" value="" v-model="form.company_name">
                                                            <span class="text-sm text-danger">{{ errors.company_name }}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  <div class="col-md-3" v-if="fields.add.fields.firstname && notACompany">
                                                    <div class="form-group">
                                                      <label for="firstName" class="form-label">{{ fields.add.fields.firstname.label }} <sup class="text-danger" v-if="fields.add.fields.firstname.required">*</sup></label>
                                                      <input id="firstName" type="text" class="form-control" maxlength="90" name="form.first_name" value=""  v-model="form.first_name">
                                                      <span class="text-sm text-danger">{{ errors.first_name }}</span>
                                                    </div>

                                                  </div>
                                                  <div class="col-md-3" v-if="fields.add.fields.lastname && notACompany">
                                                    <div class="form-group">
                                                      <label for="lastName" class="form-label">{{ fields.add.fields.lastname.label }}<sup class="text-danger" v-if="fields.add.fields.lastname.required">*</sup></label>
                                                      <input id="lastName" type="text" class="form-control" maxlength="90" name="form.last_name" value=""  v-model="form.last_name">
                                                      <span class="text-sm text-danger">{{ errors.last_name }}</span>
                                                    </div>
                                                  </div>
                                                    <div class="col-md-3 mt-4">
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="notACompany"
                                                               name="notACompany" v-model="notACompany" value="1">
                                                        <label class="d-flex mr-1" for="notACompany">Not a company</label>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.add.fields.address1">
                                                        <div class="form-group">
                                                        <label for="address1" class="form-label">{{ fields.add.fields.address1.label }}<sup class="text-danger" v-if="fields.add.fields.address1.required">*</sup> </label>
                                                        <input id="address1" type="text" class="form-control" maxlength="45" name="form.address1" value="" v-model="form.address1">
                                                            <span class="text-sm text-danger">{{ errors.address1 }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.add.fields.address2">
                                                        <div class="form-group">
                                                            <label for="address2" class="form-label">{{ fields.add.fields.address2.label }}</label>
                                                            <input id="address2" type="text" class="form-control" maxlength="45" name="form.address2" value="" v-model="form.address2">
                                                            <span class="text-sm text-danger">{{ errors.address2 }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" v-if="fields.add.fields.city">
                                                        <div class="form-group">
                                                            <label for="city" class="form-label">{{ fields.add.fields.city.label }}<sup class="text-danger" v-if="fields.add.fields.city.required">*</sup></label>
                                                            <input id="city" type="text" class="form-control" maxlength="45" name="form.city" value="" v-model="form.city">
                                                            <span class="text-sm text-danger">{{ errors.city }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" v-if="fields.add.fields.state">
                                                    <div class="form-group">
                                                    <label for="state" class="form-label">{{ fields.add.fields.state.label }} <sup class="text-danger" v-if="fields.add.fields.state.required">*</sup> </label>
                                                        <select class="form-control" name="form.state_code" v-model="form.state_code">
                                                            <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                                        </select>
                                                        <span class="text-sm text-danger">{{ errors.state_code }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2" v-if="fields.add.fields.zip">
                                                    <div class="form-group">
                                                        <label for="zipCode" class="form-label">{{ fields.add.fields.zip.label }} <sup class="text-danger" v-if="fields.add.fields.zip.required">*</sup> </label>
                                                        <input id="zipCode" type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" name="form.zip" v-model="form.zip">
                                                        <span class="text-sm text-danger">{{ errors.zip }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="fields.add.fields.homephone">
                                                    <div class="form-group">
                                                    <label for="homePhone" class="form-label">{{ fields.add.fields.homephone.label }}  <sup class="text-danger" v-if="fields.add.fields.homephone.required">*</sup> </label>
                                                        <vue-tel-input :inputOptions="options" v-model="form.home_phone" ref="phone" @validate="validateTelinput" maxlength="20" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                                                        <span class="text-sm text-danger">{{ errors.home_phone }}</span>
                                                        </div>
                                                    </div>
                                                   <!-- <div class="col-md-4">
                                                    <div class="form-group">
                                                    <label for="workPhone" class="form-label">Work Phone</label>
                                                        <vue-tel-input :inputOptions="options" v-model="form.work_phone" ref="phone" maxlength="20" v-bind="workProps" :validCharactersOnly="true"></vue-tel-input>
                                                        <span class="text-sm text-danger">{{ errors.work_phone_country_code }}</span>
                                                    </div>
                                                    </div>-->

                                                    <div class="col-md-12" v-if="fields.add.fields.certificateFor">
                                                    <div class="form-group">
                                                        <label for="certificateFor" class="form-label">{{ fields.add.fields.certificateFor.label }}<sup class="text-danger" v-if="fields.add.fields.certificateFor.required">*</sup> </label>
                                                        <div class="form-group">
                                                            <template v-if="fields.add.fields.certificateFor.choices">
                                                                <label class="form-label mr-1" v-for="(item,k) in fields.add.fields.certificateFor.choices" :key="'et'+k" :for="item.id">
                                                                    <input class="" type="radio" :id="item.id" maxlength="20"  name="form.certificate_type" v-model="form.certificate_type" :value="item.value"> 
                                                                    {{ item.label }}
                                                                </label>
                                                            </template>
                                                            <br>
                                                            <span class="text-sm text-danger">{{ errors.certificate_type }}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-12" v-if="fields.add.fields.specificEvent">
                                                         <div class="form-group certificatefor" v-if="form.certificate_type == 'specificEvent'">
                                                            <span class="font-13 text-danger"><i>Note: <span v-html="fields.add.fields.specificEvent.note"></span></i></span><br>
                                                            <label for="eventType" class="form-label">{{ fields.add.fields.specificEvent.label }}<sup class="text-danger" v-if="fields.add.fields.specificEvent.required">*</sup> </label>
                                                            <input type="text"  class="form-control" maxlength="45" name="form.specific_event_title" v-model="form.specific_event_title">
                                                            <span class="text-sm text-danger">{{ errors.specific_event_title }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                    <div class="form-group specificeventtype">
                                                        <template v-if="fields.add.fields.specificeventtype && form.certificate_type == 'specificEvent'">
                                                            <label for="eventType" class="form-label">{{ fields.add.fields.specificeventtype.label }} <sup class="text-danger" v-if="fields.add.fields.specificeventtype.required">*</sup></label>
                                                            <div class="form-group">
                                                                <template v-if="fields.add.fields.specificeventtype.choices">
                                                                    <label v-for="(item, k) in fields.add.fields.specificeventtype.choices" :key="'set'+k" class="form-label" :for="item.id">
                                                                        <input type="radio" :id="item.id" name="form.sanctioned_type" v-model="form.sanctioned_type" :value="item.value"> {{ item.label }}
                                                                    </label>
                                                                </template>
                                                                <label class="form-label" for="tournament">
                                                                     <input type="radio" id="tournament" name="form.sanctioned_type" v-model="form.sanctioned_type" value="tournament"> Tournament
                                                                </label>
                                                                 <div v-show="form.sanctioned_type == 'tournament'">
                                                                    <p class="font-13 mb-1" v-html="fields.add.fields.specificeventtype.note"></p>
                                                                </div>
                                                                <div v-show="form.sanctioned_type == 'practice'">
                                                                    <p class="font-13 mb-1" v-html="fields.add.fields.specificeventtype.note"></p>
                                                                </div>
                                                                <div v-show="form.sanctioned_type == 'basicskillclinic'">
                                                                    <p class="font-13 mb-1" v-html="fields.add.fields.specificeventtype.note"></p>
                                                                </div>
                                                                <div v-show="form.sanctioned_type == 'exhibition'">
                                                                    <p class="font-13 mb-1" v-html="fields.add.fields.specificeventtype.note"></p>
                                                                </div>
                                                              <span class="text-sm text-danger">{{ errors.sanctioned_type }}</span>
                                                            </div>
                                                       
                                                            <div class="row" v-if="form.certificate_type == 'specificEvent'">
                                                                <div class="col-md-6" v-if="fields.add.fields.event_sanction">
                                                                <div class="form-group">
                                                                <label for="eventType" class="form-label">{{ fields.add.fields.event_sanction.label }}  <sup class="text-danger" v-if="fields.add.fields.event_sanction.required">*</sup></label>
                                                                <input id="eventSanction" type="text" name="form.event_sanction" maxlength="45" v-model="form.event_sanction" class="form-control">
                                                                    <span class="text-sm text-danger">{{ errors.event_sanction }}</span>
                                                                </div>
                                                                </div>
                                                                <div class="col-md-6" v-if="fields.add.fields.specific_event_start_date">
                                                                <div class="form-group insurence-datepicker">
                                                                <label for="eventType" class="form-label">{{ fields.add.fields.specific_event_start_date.label }} </label><br>

                                                                <date-pick
                                                                class="form-control"
                                                                v-model="form.specific_event_start_date"
                                                                :pickTime="false"
                                                                name="form.specific_event_start_date"
                                                                :selectableYearRange="{from: new Date().getFullYear(), to: new Date().getFullYear()}"
                                                                @input="specialEventStartDateValidation(form.specific_event_start_date)"
                                                                :format="'MM/DD/YYYY'"
                                                                v-mask="'##/##/####'"
                                                                :isDateDisabled="isFutureDate"
                                                                placeholder="MM/DD/YYYY"
                                                                :parseDate="parseDatePick"
                                                                ></date-pick>

                                                                <!--<input id="specificeventdate" type="text" name="form.specific_event_start_date" maxlength="45" v-model="form.specific_event_start_date" class="form-control">-->
                                                                    <span class="text-sm text-danger">{{ errors.specific_event_start_date }}</span>
                                                                </div>
                                                                </div>
                                                                <div class="col-md-6" v-if="fields.add.fields.specific_event_end_date">
                                                                <div class="form-group insurence-datepicker">
                                                                <label for="eventType" class="form-label">{{ fields.add.fields.specific_event_end_date.label }} </label><br>

                                                                <date-pick
                                                                class="form-control"
                                                                v-model="form.specific_event_end_date"
                                                                :pickTime="false"
                                                                name="form.specific_event_end_date"
                                                                :selectableYearRange="{from: new Date().getFullYear(), to: new Date().getFullYear()}"
                                                                @input="specialEventEndDateValidation(form.specific_event_end_date)"
                                                                :format="'MM/DD/YYYY'"
                                                                v-mask="'##/##/####'"
                                                                :isDateDisabled="isFutureDate"
                                                                placeholder="MM/DD/YYYY"
                                                                :parseDate="parseDatePick"
                                                                ></date-pick>

                                                                <!--<input id="specificeventdate" type="text" name="form.specific_event_end_date" maxlength="45" v-model="form.specific_event_end_date" class="form-control">-->
                                                                    <span class="text-sm text-danger">{{ errors.specific_event_end_date }}</span>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.add.fields.team_has_any_agreement">
                                                    <div class="form-group ">
                                                        <label class="form-label">{{ fields.add.fields.team_has_any_agreement.label }} <sup class="text-danger" v-if="fields.add.fields.team_has_any_agreement.required">*</sup></label>
                                                        <template v-if="fields.add.fields.team_has_any_agreement.choices">
                                                            <div v-for="(item, k) in fields.add.fields.team_has_any_agreement.choices" :key="'agreement-'+k">
                                                                <label class="form-label" :for="item.id">
                                                                    <input type="radio" :id="item.id" name="form.team_has_any_agreement" v-model="form.team_has_any_agreement" :value="item.value"> {{ item.label }}
                                                                </label>
                                                                <br>
                                                            </div>
                                                        </template>
                                                      <span class="text-sm text-danger">{{ errors.team_has_any_agreement }}</span>
                                                      <span class="font-13 text-danger"><i v-html="fields.add.fields.event_sanction.note"></i></span>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="fields.add.fields.additional_insurance">
                                                        <div class="form-group ">
                                                            <label class="form-label">{{ fields.add.fields.additional_insurance.label }} <sup class="text-danger" v-if="fields.add.fields.additional_insurance.required">*</sup></label>
                                                                <template v-if="fields.add.fields.additional_insurance.choices">
                                                                    <div v-for="(item, k) in fields.add.fields.additional_insurance.choices" :key="'insurance-'+k">
                                                                        <label class="form-label" :for="item.id">
                                                                            <input type="radio" :id="item.id" name="form.additional_insurance" v-model="form.additional_insurance" :value="item.value"> {{ item.label }}
                                                                        </label>
                                                                        <br>
                                                                    </div>
                                                                </template>
                                                            <span class="text-sm text-danger">{{ errors.additional_insurance }}</span>
                                                        </div>
                                                      <div class="form-group " v-show="form.additional_insurance=='Y'">
                                                        <b-form-group :label="fields.add.fields.additional_insurance.additional_insurance_member.label">
                                                          <b-form-checkbox-group
                                                              id="additional_insurance_member"
                                                              v-model="form.additional_insurance_member"
                                                              :options="additionalInsuranceMemberOptions"
                                                              name="additional_insurance_member"
                                                          ></b-form-checkbox-group>
                                                        </b-form-group>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </b-modal>
                        </div>
                    </div>

   </div>
    <div class="">
        <div class="col-md-12">
            <div class="text-right topspace">
                <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                <button type="button" @click="ClubContactCheck" class="btn btn-outline-primary savebtn mr-0 next-step-btn">{{ button.nextButton }}<i class="fas fa-chevron-right pl-2"></i></button>
                <span class="text-sm text-danger"><br>{{ clubRegError }}</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DatePick from 'vue-date-pick';
import moment from "moment";
import {TheMask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';

const validateFirstName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateName = name => {
    if (name == undefined) {
      return { valid: false, error: "This field is required" };
    } else if(!name.length || name == "") {
      return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }


  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
  }

  return { valid: true, error: null };
}
const validateLastName = name => {
  if (name == undefined) {
    return { valid: false, error: "This field is required" };
  } else if(!name.length || name == "") {
    return { valid: false, error: "This field is required" };
  }else if(!name.match(/^[a-zA-Z-\s]*$/)){
    return { valid: false, error: " must be letters and hyphens only. No other characters" };
  }

  return { valid: true, error: null };
};

const validateSelect = select => {
    console.log('lenghth='+select);
    if (select == undefined) {
        console.log('lenght3333h='+select);
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateRadio = radio => {
    if (radio == undefined) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateCheck = choices => {
  console.log('v='+choices);
  if(choices == undefined) return { valid: true, error: null };
  return { valid: false, error: "This field is required" };
}
const validateZip = zip => {
  if (zip == undefined) {
        return { valid: false, error: "This field is required" };
    }
  if(!zip.length)
  {
    return { valid: false, error: "Zip code is required" };
  }
  else if(zip.length < 5 ){
         return { valid: false, error: "Zip code should be 5 character" };
    }
  return { valid: true, error: null };
}

export default {
    name: "insurenceinformation",
    props: ['clubInfoData','button','clubRegError', 'fields', 'insuranceFee'],
    components: {DatePick},
    data(){
        return{
          dateVar:'12/31/'+new Date().getFullYear(),
            options: { placeholder: "Phone Number" },
            homeProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Home Number",
                maxLen: 20,
            },
            workProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Mobile Number",
                maxLen: 20,
            },
            faxProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Mobile Number",
                maxLen: 20,
            },
            showModal: false,
            nameState: null,
            validTelinput:false,
            form: {
                first_name: '',
                last_name: '',
                company_name: '',
                insurance_type: '',
                home_phone: '',
                home_phone_country_code: '',
                home_phone_extension: '',
                work_phone: '',
                work_phone_country_code: '',
                work_phone_extension: '',
                fax: '',
                address1: '',
                address2: '',
                city: '',
                state_code: '',
                state: '',
                zip: '',
                country: '',
                certificate_type: '',
                sanctioned_type: '',
                event_sanction: '',
                specific_event_title: '',
                specific_event_start_date: '',
                specific_event_end_date: '',
                team_has_any_agreement: '',
                additional_insurance: '',
                additional_insurance_member: '',
                index: ''
            },
            member: {
                first_name: '',
                last_name: ''
            },
            company: {
                company_name: ''
            },
            valid: true,
            success: false,
            errors: {},
            message: null,
            edit: false,
            myInsurances: [],
           notACompany: false,
        }
    },
    methods: {
        specialEventStartDateValidation(value){
            if(new Date(moment(this.dateVar)).getTime() < new Date(value).getTime()){
                this.valid = false;
                this.errors.specific_event_start_date = "Your selected date is exceed of your Expire date";
            }else if(new Date().getTime() > new Date(value).getTime()){
                this.valid = false;
                this.errors.specific_event_start_date= "Your selected date is Invalid";
            }else{
                this.errors.specific_event_start_date = "";
            }
        },
        specialEventEndDateValidation(value){
            if(new Date(moment(this.dateVar)).getTime() < new Date(value).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date = "Your selected date is exceed of your Expire date";
            }else if(new Date().getTime() > new Date(value).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date= "Your selected date is Invalid";
            }else if(new Date(value).getTime() < new Date(this.form.specific_event_start_date).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date= "Your selected end date is earlier than start date";
            }else{
                this.errors.specific_event_end_date = "";
            }
        },
        insurenceInfoModal() {
            this.edit = false;
        //this.$refs['my-modal'].show();
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.form = {}
            this.company = {}
            this.member = {}
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        ClubContactCheck: function(){
            this.errors = {}
            /*console.log('this contact check');
            console.log(this.valid);
            console.log('===========');
            console.log(this.clubInfoData.insured_members.length);*/
            /*if(this.valid &&  this.clubInfoData.insured_members.length > 0) {
                console.log("1"+this.valid);
                this.errors.primary = '';
                //this.button.nextButton = 'Registration is in progress...';
                this.$emit('next-step');
                //this.button.nextButton = 'Next Step';
                return;
            } else {
                console.log("2"+this.valid);
                this.errors.primary = 'Insurance is required!';
            }*/
            this.$emit('next-step');
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.validate()) {
                return;
            }
            if(this.form.first_name !='' && this.form.last_name !='') {
                this.form.insurance_type = 1;
            } else if(this.form.company_name != '') {
                this.form.insurance_type = 2;
            }
            if(this.edit == false) {
                this.form.index = this.clubInfoData.insured_members.length;
            }
            console.log(this.form.index);
            console.log("*************");
            console.log(this.clubInfoData.insured_members.length);
            console.log("-----------");
            console.log(this.form);
            this.$emit('insurance-data', this.form);
            this.myInsurances[this.form.index] = this.form;
            this.valid = true;
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center6')
            })
        },
        validate() {
            this.errors = {}
            this.valid = false;
            console.log('------validate-------');
            console.log(this.form);
            const validFirstName = validateFirstName(this.form.first_name);
            const validLastName = validateLastName(this.form.last_name);
            const validCompanyName = validateName(this.form.company_name);
            const validAddress1 = validateName(this.form.address1);
            const validStateCode = validateName(this.form.state_code);
            const validCity = validateName(this.form.city);
            const validZip = validateZip(this.form.zip);
            //const validHomePhoneCode = validateName(this.form.home_phone_country_code);
            const validHomePhone = validatePhone(this.form.home_phone,this.validTelinput);
            const validCertificateType = validateName(this.form.certificate_type);
            const validSpecificEventTitle = validateName(this.form.specific_event_title);
            const validSanctionedType = validateRadio(this.form.sanctioned_type);
            const validEventSanctionNo = validateName(this.form.event_sanction);
            const validEventSanctionStartDate = validateName(this.form.specific_event_start_date);
            const validEventSanctionEndDate = validateName(this.form.specific_event_end_date);
          const validTeamHasAnyAgreement = validateRadio(this.form.team_has_any_agreement);
          const validAdditionalInsurance = validateRadio(this.form.additional_insurance);
          const validNotACompany = validateCheck(this.notACompany);
          console.log('valid compay');
          console.log(validNotACompany);

            this.errors.first_name = validFirstName.error; //'Please Enter First Name';
            this.errors.last_name = validLastName.error; //'Please Enter Last Name';
            this.errors.company_name = validCompanyName.error;//'Please Company Name';
            this.errors.address1 = validAddress1.error;
            this.errors.state_code = validStateCode.error;
            this.errors.city = validCity.error;
            this.errors.zip = validZip.error;
            //this.errors.home_phone_country_code = validHomePhoneCode.error;
            this.errors.home_phone = validHomePhone.error;
            this.errors.certificate_type = validCertificateType.error;
            this.errors.specific_event_title = validSpecificEventTitle.error;
            this.errors.sanctioned_type = validSanctionedType.error;
            this.errors.event_sanction = validEventSanctionNo.error;
            this.errors.team_has_any_agreement = validTeamHasAnyAgreement.error;
            this.errors.additional_insurance = validAdditionalInsurance.error;
            this.errors.specific_event_start_date = validEventSanctionStartDate.error;
            this.errors.specific_event_end_date = validEventSanctionEndDate.error;

            /*if(validFirstName.valid || validLastName.valid) {
                this.errors.company_name = '';
            }
            if(validCompanyName.valid) {
                this.errors.first_name = '';
                this.errors.last_name = '';
            }*/
            if(this.form.certificate_type == 'year') {
              this.errors.sanctioned_type = '';
            }
            
      if(this.form.certificate_type == 'specificEvent' && (this.form.specific_event_start_date != '' || this.form.specific_event_end_date != '')){
        if(new Date(moment(this.dateVar)).getTime() < new Date(this.form.specific_event_start_date).getTime()){
              this.valid = false;
              this.errors.specific_event_start_date = "Your selected date is exceed of your expire date";
            }
            if(new Date().getTime() > new Date(this.form.specific_event_start_date).getTime()){
                this.valid = false;
                this.errors.specific_event_start_date= "Your selected date is Invalid";
            }
            if(new Date(moment(this.dateVar)).getTime() < new Date(this.form.specific_event_end_date).getTime()){
              this.valid = false;
              this.errors.specific_event_end_date = "Your selected date is exceed of your expire date";
            }
            if(new Date().getTime() > new Date(this.form.specific_event_end_date).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date= "Your selected date is Invalid";
            }
            if(new Date(this.form.specific_event_start_date).getTime() > new Date(this.form.specific_event_end_date).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date= "Your selected end date is earlier than start date";
            }
      }
            if ((validCompanyName.valid || (validFirstName.valid && validLastName.valid)) && validAddress1.valid
                && validStateCode.valid && validCity.valid && validZip.valid && validHomePhone.valid
                && validCertificateType.valid
                && validTeamHasAnyAgreement.valid && validAdditionalInsurance.valid) {
                this.valid = true;

                if(this.notACompany && (!validFirstName.valid || !validLastName.valid)) {
                  this.valid = false;
                } else if(!this.notACompany && !validCompanyName.valid) {
                  this.valid = false;
                }
                if(this.form.certificate_type == 'specificEvent' && (!validSpecificEventTitle.valid || !validSanctionedType.valid || !validEventSanctionNo.valid || !validEventSanctionStartDate.valid || !validEventSanctionEndDate.valid)) {
                    this.valid = false;
                } else {
                  if(validEventSanctionStartDate.valid && !this.validateDate(this.form.specific_event_start_date)) {
                    this.valid = false;
                    this.errors.specific_event_start_date = "Invalid Date";
                  }
                  if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_start_date != '' && new Date(moment(this.dateVar)).getTime() < new Date(this.form.specific_event_start_date).getTime()){
                    this.valid = false;
                    this.errors.specific_event_start_date = "Your selected date is exceed of your expire date";
                   }
                    if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_start_date != '' && new Date().getTime() > new Date(this.form.specific_event_start_date).getTime()){
                this.valid = false;
                this.errors.specific_event_start_date= "Your selected date is Invalid";
            }

             if(validEventSanctionEndDate.valid && !this.validateDate(this.form.specific_event_end_date)) {
                    this.valid = false;
                    this.errors.specific_event_end_date = "Invalid Date";
                  }
                  if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_end_date != '' && new Date(moment(this.dateVar)).getTime() < new Date(this.form.specific_event_end_date).getTime()){
                    this.valid = false;
                    this.errors.specific_event_end_date = "Your selected date is exceed of your expire date";
                   }
                    if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_end_date != '' && new Date().getTime() > new Date(this.form.specific_event_end_date).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date= "Your selected date is Invalid";
            }
            if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_end_date != '' && new Date(this.form.specific_event_end_date).getTime() < new Date(this.form.specific_event_start_date).getTime()){
                this.valid = false;
                this.errors.specific_event_end_date= "Your selected end date is earlier than start date";
            }
                }
                if(this.valid) {
                  this.errors = {}
                }
            }

            return this.valid;
        },
      validateTelinput(obj){
        this.validTelinput = obj.valid;
        if(obj.valid){
          this.errors.home_phone = '';
        }
      },
        removeInsurance(i) {
            console.log("remove index="+i);
            this.$emit('insurance-remove-data', i);
            console.log("remove index="+i);
            console.log(this.clubInfoData.insured_members.length);
            this.myInsurances.splice(i,1);
            if(this.myInsurances.length == 0) {
                this.valid = false;
            }
        },
        editInsurance(i) {
            console.log('update insurance' + i);
            console.log(this.clubInfoData.insured_members[i]);
            this.edit = true;
            //this.form.index = i;
            this.$bvModal.show('modal-center6');
            this.form = {
                first_name: this.clubInfoData.insured_members[i].first_name,
                last_name: this.clubInfoData.insured_members[i].last_name,
                company_name: this.clubInfoData.insured_members[i].company_name,
                insurance_type: this.clubInfoData.insured_members[i].insurance_type,
                home_phone: this.clubInfoData.insured_members[i].home_phone,
                //home_phone_country_code: this.clubInfoData.insured_members[i].home_phone_country_code,
                //home_phone_extension: this.clubInfoData.insured_members[i].home_phone_extension,
                work_phone: this.clubInfoData.insured_members[i].work_phone,
                //work_phone_country_code: this.clubInfoData.insured_members[i].work_phone_country_code,
                //work_phone_extension: this.clubInfoData.insured_members[i].work_phone_extension,
                fax: this.clubInfoData.insured_members[i].fax,
                address1: this.clubInfoData.insured_members[i].address1,
                address2: this.clubInfoData.insured_members[i].address2,
                city: this.clubInfoData.insured_members[i].city,
                state_code: this.clubInfoData.insured_members[i].state_code,
                state: this.clubInfoData.insured_members[i].state,
                zip: this.clubInfoData.insured_members[i].zip,
                country: this.clubInfoData.insured_members[i].country,
                certificate_type: this.clubInfoData.insured_members[i].certificate_type,
                sanctioned_type: this.clubInfoData.insured_members[i].sanctioned_type,
                event_sanction: this.clubInfoData.insured_members[i].event_sanction,
                specific_event_title: this.clubInfoData.insured_members[i].specific_event_title,
                specific_event_start_date: this.clubInfoData.insured_members[i].specific_event_start_date,
                specific_event_end_date: this.clubInfoData.insured_members[i].specific_event_end_date,
                team_has_any_agreement: this.clubInfoData.insured_members[i].team_has_any_agreement,
                additional_insurance: this.clubInfoData.insured_members[i].additional_insurance,
                additional_insurance_member: this.clubInfoData.insured_members[i].additional_insurance_member,
                index: i
            };
            console.log(this.form);
            console.log(this.form.first_name);
        },
        isFutureDate(date) {
            const currentDate = new Date();
            return date < currentDate;
        }
    },
    mounted(){
        let year = new Date().getFullYear();
            if(new Date() > new Date("10/31/"+year)){
                this.dateVar = "12/31/"+(parseInt(year)+1);
            }
    }
}


</script>

<style scoped>

</style>
