<template>
  <div class="z-value">    
      <div class=" bg-light h-100">
        <div class="container-fluid">
          <div class="row">
            <div
              class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top"
            >
              <banner></banner>
            </div>
            <div
              class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 p-5 bg-white rightside_section"
            >
              <div
                class=" mb-3  border-radius-0 border-0 hvertical"
                style="padding:100px 0 0 0;background: #fff;"
              >
                <div class="logo-wrapper text-center mb-2">
                  <img
                    :src="'/' + this.getSiteProps('login.logo')"
                    alt="logo"
                  />
                </div>
                <div class="">
                  <div class="card-body">
                    <h2 class="text-center">
                      Page Not Found
                    </h2>
                    <h3 class="text-center">404</h3>
                    <div class="text-center">
                      <a class="text-danger" href="/" target="_self"
                        >Back to Home
                      </a>
                    </div>
                  </div>
                </div>
               </div>
            </div>
            <div  class="main-footer">
              <div class="footer-inner">
                <div class="row footer-row">
                  <div class="col-md-7 pl-3">
                    <div class="footeremail col-md-12 mb-1 pl-0" v-if="prop_footer.phone || prop_footer.email">                    
                    <p class="footer-quest">Questions? Contact Member Services at <a :href="'tel:'+prop_footer.phone"> {{ prop_footer.phone }}</a> or <a :href="'mailto:'+prop_footer.email">{{ prop_footer.email }}</a></p>
                   </div>
                    <p class="text-danger">{{ this.getSiteProps('login.copyright') +' '+ new Date().getFullYear() }}</p>
                  </div>
                  <div class="col-md-5 pr-4">
                   <p class="text-right mr-2">Powered by <a href="https://www.isportz.co/" target="_blank"><img class="ml-1" style="width:80px" v-bind:src="'/dist/img/logo.svg'" alt="footer-img" /></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>
<script>
import axios from "axios";
import banner from "././memberregistration/banner.vue";
export default {
  name: "memberlogin",
  data() {
    return {
      email: "",
      password: "",
      errors_email: "",
      errors_password: "",
      error_class: "",
      isActiveEmail: false,
      isActivePassword: false,
       prop_footer: {},
    };
  },
  methods: {
    forgotPassword: function() {
      window.open("/member/forgotpassword", "_self");
    },
    register: function() {
      window.open("/memberregistration", "_self");
    },
    checkLogin: function() {
      if (this.email != "" && this.password != "") {
        this.isActiveEmail = false;
        this.isActivePassword = false;
        this.errors_email = "";
        this.errors_password = "";
        axios
          .post(process.env.VUE_APP_URL + "api/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.data.status == "success") {
              localStorage.setItem("id", response.data.data.id);
              localStorage.setItem("admintoken", response.data.data.token);
              localStorage.setItem(
                "admin",
                JSON.stringify(response.data.data.admin_name)
              );
              this.$router.push({ path: "/admin/dashboard" });
            } else {
              this.errors_password =
                "These credentials do not match our records.";
              this.isActivePassword = true;
            }
          })
          .catch((error) => {
            this.loaded = true;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
            }
          });
      } else {
        if (!this.email) {
          this.errors_email = "The email field is required.";
          this.isActiveEmail = true;
        } else {
          this.errors_email = "";
          this.isActiveEmail = false;
        }
        if (!this.password) {
          this.errors_password = "The password field is required.";
          this.isActivePassword = true;
        } else {
          this.errors_password = "";
          this.isActivePassword = false;
        }
      }
    },
  },
   updated(){
           
            this.prop_footer = this.getSiteProps('memberRegistration.footer');
        }
};
</script>
<style scoped>

</style>