<template>
   <div>
        <sidebar></sidebar>
        <navigation></navigation>
            <div class="content-wrapper">
               <router-view></router-view>
            </div>
        <loggedindashboardFooter></loggedindashboardFooter>
       
    </div>
</template>

<script>
 import navigation from "../components/Navigation";
import sidebar from "../components/Sidebar";
import loggedindashboardFooter from "../components/LoggedInFooter.vue";


export default {
    name: "memberlayouts",
     components: {navigation, sidebar,loggedindashboardFooter},
}
</script>
