<template>
    <form @submit.prevent="generalInfoData" method="post">
        <div class=" generalinformation">
            <div class="card-header register-title">
                <h3>{{ fields.tab_title || 'General Information' }}</h3>
            </div>
            <div class="card-body pr-4 pl-4">
                <div class="row" v-if="age > 18 && (fields.description || fields.safe_sport_status)">
                    <div class="col-md-12">

                        <p class="font-13 text-justify" v-for="(desc,i) in fields.description" :key="'desc'+i" v-html="desc"></p>
                    
                        <div class="form-group" v-if="fields.safe_sport_status">
                            <label for="sport-trained" class="form-label">{{ fields.safe_sport_status.label }}<sup v-if="fields.safe_sport_status.required">*</sup></label><br>
                            <template v-if="fields.safe_sport_status.choices">
                                <template v-for="(choice,k) in fields.safe_sport_status.choices">
                                    <input type="radio" :id="'safe-'+choice.value" name="form.safe_sport_status"
                                        v-model="form.safe_sport_status" :value="choice.value" @click="safeSports = choice.value" :key="'safe-sport-status-'+k">
                                    <label :for="'safe-'+choice.value" :key="'safe-sport-status-label-'+k">{{ choice.label }}</label>
                                    <br :key="'safe-sport-status-br'+k">
                                </template>
                            </template>
                            
                            <span class="font-13 text-danger text-justify">{{ errors.safe_sport_status }}</span>

                            <template v-for="(desc,k) in fields.safe_sport_status.no_fields">
                                <span class="font-13 text-danger " v-if="form.safe_sport_status == 3" :key="'safe_sport_status_no' +k">
                                   <span class="text-justify" v-html="desc"></span>
                                </span>
                            </template>
                            
                        </div>


                        <div class="row" v-if="fields.safe_sport_status.yes_fields && safeSports == 1 ">
                            <div class="col-md-12">
                                <div class="form-group" v-if="fields.safe_sport_status.yes_fields.name">
                                    <label for="notsurename" class="form-label font-13">
                                        {{ fields.safe_sport_status.yes_fields.name.label }} <sup v-if="fields.safe_sport_status.yes_fields.name.required">*</sup>
                                    </label>
                                    <input type="text" class="form-control" maxlength="45" id="notsurename"
                                           name="form.safe_sport_user_name" v-model="form.safe_sport_user_name">
                                    <span class="font-13 text-danger clearfix">{{ errors.safe_sport_user_name }}</span>
                                    <span class="text-success font-13">{{ fields.safe_sport_status.yes_fields.name.description }}</span>
                                </div>
                                <div class="form-group" v-if="fields.safe_sport_status.yes_fields.email">
                                    <label for="notsuremail" class="form-label">{{ fields.safe_sport_status.yes_fields.email.label }} <sup v-if="fields.safe_sport_status.yes_fields.email.required">*</sup></label>
                                    <input type="email" class="form-control" maxlength="45" id="notsuremail"
                                           name="form.safe_sport_email" v-model="form.safe_sport_email">
                                    <span class="font-13 text-danger clearfix">{{ errors.safe_sport_email }}</span>
                                    <span class="text-success font-13">{{ fields.safe_sport_status.yes_fields.email.description }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-else-if="safeSports == 3">
                            <p class="font-13"><i>SafeSports training by <a class="text-danger" target="_blank"
                                                                            href="https://safesport.org/authentication/register?token=6a8f3654-bd10-45c4-a2b4-927709856f56">clicking
                                this link</a></i></p>
                            <p class="font-13"><strong>Enter Access Code – TVQQ-GZNL-SEBT-INS8</strong></p>
                            <p class="font-13"><i>Members have 30 days upon registration to complete the basic or
                                refresher SafeSport training. If training is not completed within 30 days, membership
                                will be suspended and
                                you will be ineligible to compete in any sanctioned USA-WSWS event.</i></p>
                        </div> -->


                    </div>
                </div>

                <template v-if="fields.sport_discipline && fields.ncsa == undefined">
                    <span class="inner-title" v-if="fields.sport_discipline.label"><strong>{{ fields.sport_discipline.label }}</strong></span>
                    <p class="font-13 text-justify" v-if="fields.sport_discipline.description" v-html="fields.sport_discipline.description"></p>
                    <div class="row">
                        <div :class="[fields.sport_discipline.first_choice.colClass]" v-if="fields.sport_discipline.first_choice">
                            <div class="form-group">
                                <label for="first-choice" class="form-label">{{ fields.sport_discipline.first_choice.label }} <sup v-if="fields.sport_discipline.first_choice.required">*</sup></label>
                                <Select2 v-if="fields.sport_discipline.first_choice.multiple" v-model="form.first_choice" name="form.first_choice" id="disciplines" :options="disciplines_list" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                <select class="form-control" id="first-choice" name="form.first_choice"
                                        v-model="form.first_choice" v-else>
                                    <option value="">Select</option>
                                    <option v-for="(discipline, index) in disciplines" :value="index"
                                            :key="'types'+index">{{ discipline }}
                                    </option>
                                </select>
                                <span class="font-13 text-danger">{{ errors.first_choice }}</span>
                            </div>
                        </div>
                        <div :class="[fields.sport_discipline.second_choice.colClass]" v-if="fields.sport_discipline.second_choice">
                            <div class="form-group">
                                <label for="secondchoice" class="form-label">{{ fields.sport_discipline.second_choice.label }} <sup v-if="fields.sport_discipline.second_choice.required">*</sup></label>
                                <select class="form-control" id="secondchoice" name="form.second_choice"
                                        v-model="form.second_choice">
                                    <option value="">Select</option>
                                    <option v-for="(discipline, index) in disciplines" :value="index"
                                            :key="'types2-'+index">{{ discipline }}
                                    </option>
                                </select>

                                <span class="font-13 text-danger">{{ errors.second_choice }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="membership.id != 4">
                        <div :class="[fields.sport_discipline.home_federation.colClass]" v-if="fields.sport_discipline.home_federation">
                            <div class="form-group">
                                <label for="home_federation" class="form-label">{{ fields.sport_discipline.home_federation.label }} <sup v-if="fields.sport_discipline.home_federation.required">*</sup></label>
                                <select class="form-control" id="home_federation" name="form.home_federation"
                                        v-model="form.home_federation">
                                    <option value="">Select</option>
                                    <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                                </select>
                                <span class="font-13 text-danger">{{ errors.home_federation }}</span>
                            </div>
                        </div>
                        <div :class="[fields.sport_discipline.skier_license.colClass]" v-if="fields.sport_discipline.skier_license">
                            <div v-if="form.home_federation !='USA'">
                                <div class="form-group">
                                    <label for="skier_license" class="form-label">{{ fields.sport_discipline.skier_license.label }} <sup v-if="fields.sport_discipline.skier_license.required">*</sup></label>
                                    <input type="text" id="skier_license" class="form-control" maxlength="9"  @keypress="isNumber($event)"
                                        placeholder="" name="form.skier_license"
                                        v-model="form.skier_license">
                                    <span class="font-13 text-danger">{{ errors.skier_license }}</span><br/>
                                    <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                                    <div class="font-13 text-success text-justify mt-3" v-html="fields.sport_discipline.skier_license.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

              <div class="row" v-if="fields.ncsa && ncsaEnable">
                <div :class="[fields.ncsa_logo.colClass]">
                  <img v-if="fields.ncsa_logo && fields.ncsa_logo.element && fields.ncsa_logo.element == 'img'" :src="fields.ncsa_logo.attributes.src" :class="fields.ncsa_logo.class">
                </div>
                <div class="col-md-12">
                  <div :class="[fields.ncsa_description.colClass]">
                    <div class="form-group">
                      <p class="form-label" v-html="fields.ncsa_description.text"></p>
                      <ul v-for="point in fields.ncsa_description.points" v-bind:key="point.text">
                          <li v-html="point.text"></li>
                      </ul>
                      <p class="form-label" v-html="fields.ncsa_description.text2"></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" v-show="fields.ncsa && fields.ncsa.visible">
                  <div :class="[fields.ncsa.colClass]">
                    <div class="form-group">
                      <label for="ncsa" class="form-label ncsa-title"><span v-html="fields.ncsa.label"></span><sup class="text-danger" v-if="fields.ncsa.required">*</sup></label>
                      <div class="row form-group ncsa-label" v-if="fields.ncsa.choices">
                        <template v-for="(choice, key) in fields.ncsa.choices">
                          <div class="col-md-2" v-bind:key="choice.value">
                          <input type="radio" :id="choice.value" v-model="form.membership_benefits" name="form.membership_benefits" :value="choice.value" :key="'radio_' + key">
                          <label class="ncsa-label-span" :for="choice.value" :key="'ncsa_label_'+key">{{ choice.label }}</label>
                          </div>
                        </template>
                        <span class="text-sm text-danger">{{ errors.ncsa}}</span>
                        <span class="text-sm d-block mt-2 disclaimer" v-if="fields.ncsa.disclaimer"><i class="fas fa-info-circle text-danger"></i> {{fields.ncsa.disclaimer}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                    <div :class="[fields.passport_info.colClass]" v-if="fields.passport_info" id="passport_info">
                        <div class="form-group">
                            <label for="passport_info" class="form-label">{{ fields.passport_info.label }}<sup v-if="fields.passport_info.required">*</sup></label>
                            <br>
                            <div class="form-check-inline">
                                <input class="form-check-input" type="radio" id="passport_info_yes" name="form.passport_info" value="1" v-model="form.passport_info" @change="triggerPassportFields()">
                                <label class="form-check-label" for="passport_info_yes">Yes</label>
                            </div>
                            <div class="form-check-inline">
                                <input class="form-check-input" type="radio" id="passport_info_no" name="form.passport_info" value="0" v-model="form.passport_info" @change="triggerPassportFields()">
                                <label class="form-check-label" for="passport_info_no">No</label>
                            </div>
                            <br>
                            <span class="font-13 text-danger">{{ errors.passport_info }}</span>
                        </div>
                    </div>
                    <template v-if="form.passport_info == '1'">
                        <div :class="[fields.passport_number.colClass]" v-if="fields.passport_number">
                            <div class="form-group">
                                <label for="passport_number" class="form-label">{{ fields.passport_number.label }}<sup v-if="fields.passport_number.required">*</sup></label>
                                <input type="text" class="form-control" id="passport_number" name="form.passport_number" v-model="form.passport_number">
                                <span class="font-13 text-danger">{{ errors.passport_number }}</span>
                            </div>
                        </div>
                        <div :class="[fields.passport_issue_date.colClass]" v-if="fields.passport_issue_date">
                            <div class="form-group">
                                <label for="passport_issue_date" class="form-label">{{ fields.passport_issue_date.label }}<sup v-if="fields.passport_issue_date.required">*</sup></label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="form.passport_issue_date"
                                            :pickTime="false"
                                            name="form.passport_issue_date"
                                            :format="'MM/DD/YYYY'"
                                            :selectableYearRange="{from: 1900, to: 2021}"
                                            v-mask="'##/##/####'"
                                            :isDateDisabled="isFutureDate"
                                            placeholder="MM/DD/YYYY"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                                <span class="font-13 text-danger">{{ errors.passport_issue_date }}</span>
                            </div>
                        </div>
                        <div :class="[fields.passport_expiry_date.colClass]" v-if="fields.passport_expiry_date">
                            <div class="form-group">
                                <label for="passport_expiry_date" class="form-label">{{ fields.passport_expiry_date.label }}<sup v-if="fields.passport_expiry_date.required">*</sup></label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="form.passport_expiry_date"
                                            :pickTime="false"
                                            name="form.passport_expiry_date"
                                            :format="'MM/DD/YYYY'"
                                            :selectableYearRange="{from: 1900, to: 2021}"
                                            v-mask="'##/##/####'"
                                            :isDateDisabled="isFutureDate"
                                            placeholder="MM/DD/YYYY"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                                <span class="font-13 text-danger">{{ errors.passport_expiry_date }}</span>
                            </div>
                        </div>
                        <div :class="[fields.passport_nationality.colClass]" v-if="fields.passport_nationality">
                            <div class="form-group">
                                <label for="passport_nationality" class="form-label">{{ fields.passport_nationality.label }}<sup v-if="fields.passport_nationality.required">*</sup></label>
                                <select name="form.passport_nationality" id="passport_nationality" class="form-control" v-model="form.passport_nationality">
                                    <option value="">Select</option>
                                    <option v-for="(item,i) in listOfCountries" :key="'nat'+i" :value="item.country">{{ item.country }}</option>
                                </select>
                                <span class="font-13 text-danger">{{ errors.passport_nationality }}</span>
                            </div>
                        </div>
                    </template>
                </div>
              <div class="row" v-if="fields.camp">
                <h4 class="subtitle">{{ fields.camp.title || 'USSSA Membership Package'}}</h4>
                <div class="imgwithinline">
                  <img v-if="fields.camp.banner && fields.camp.banner.element && fields.camp.banner.element == 'img'" :src="fields.camp.banner.attributes.src" :class="fields.camp.banner.class">
                  <div class="form-text mb-2">
                    <span v-html="fields.camp.content"></span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div :class="[fields.camp.colClass]">
                    <div class="form-group">
                      <label for="camp" class="form-label">{{ fields.camp.label }}</label>
                      <div class="form-group" v-if="fields.camp.choices">
                        <div class="row">
                          <div class="mb-1" :class="'col-md-2'" v-for="(choice, key) in fields.camp.choices" :key="'camp_'+key">
                            <input type="radio" :id="choice.value" v-model="form.camp_signup" name="form.camp" :value="choice.value" :key="'radio_' + key">
                            <label class="camp-label-span" :for="choice.value" :key="'camp_'+key">{{ choice.label }}</label>
                          </div>
                        </div>
                        <span class="text-sm text-danger">{{ errors.camp}}</span>
                      </div>
                    </div>
                  </div>
                  <!--                  <div :class="[fields.camp.colClass]">
                                      <input class="custom-input" :key="'alfa_donation_input_'+key" type="text" v-model="form.added_donation_amount">
                                      <span class="text-sm text-danger">{{ errors.alfa_donation}}</span>
                                    </div>-->
                </div>
              </div>
            </div>

            <div class="col-md-12" v-show="overAllError != 0">
                <p class="text-danger text-right">Please fill the above missing fields.</p>
            </div>
            <div class="col-md-12">
                <div class="text-right topspace">
                    <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous
                    </button>
                    <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
const validateFedration = name => {
    if (!name) {
        return {valid: false, error: "Federation ID is required"};
    } else if (isNaN(name.trim())) {
      return {valid: false, error: "Federation ID must be a numeric value"};
    }
    //  else if ( name.trim() == "") {
    //     return {valid: false, error: "Foreign federation ID must be 9 characters"};
    // }
    return {valid: true, error: null};
};
const validateName = name => {
    if (!name) {
        return {valid: false, error: "Name is required"};
    }
    return {valid: true, error: null};
};
const validateRadio = radio => {
    if (!radio) {
        return {valid: false, error: "SafeSport Trained is required"};
    }
    return {valid: true, error: null};
};
const validateSelect = select => {
    console.log('lenghth=' + select.length);
    console.log('select=' + select);
    if (select == '') {
        return {valid: false, error: "Sport Discipline is required"};
    }
    return {valid: true, error: null};
};
const validateEmail = email => {
    if (!email.length) {
        return {valid: false, error: "Email is required"};
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
        return {valid: false, error: "Please enter a valid email."};
    }
    return {valid: true, error: null};
};

const validateMembershipBenefit = (memberShipBenefits, ncsaEnable, subdomain, fieldMemberShipBenefits = '', fields = '') => {
  if (fields.ncsa && fields.ncsa.visible === true) {
    let memberShipBenefitsText = (fieldMemberShipBenefits != '' ? fieldMemberShipBenefits : "This field");
    if (!memberShipBenefits && (subdomain != "alliancefastpitch" || ncsaEnable)) {
      return {valid: false, error: memberShipBenefitsText + " is required"};
    }
  }
  return {valid: true, error: null};
};

import DatePick from 'vue-date-pick';

export default {
    name: "generalinfo",
    props: ['disciplines', 'groups', 'membership', 'fields', 'age', 'isValidateMemberType'],
    components: { DatePick },
    data() {
        return {
            form: {
                safe_sport_status: '',
                safe_sport_user_name: '',
                safe_sport_email: '',
                first_choice: '',
                second_choice: '',
                home_federation: 'USA',
                skier_license: '',
                shirt_size: '',
                passport_info: 0,
                passport_number: '',
                passport_issue_date: '',
                passport_expiry_date: '',
                passport_nationality: '',
                code_of_conduct: 0,
                membership_benefits:'',
                camp_signup:''
            },
            safeSports: null,
            valid: false,
            success: false,
            errors: {},
            message: null,
            checkValue: '',
            checkChoice: {},
            socialSupport: false,
            disciplines_list:[],
            overAllError:0
        }
    },
    methods: {
      triggerPassportFields(){
            this.form.passport_number = '';
            this.form.passport_issue_date = '';
            this.form.passport_expiry_date = '';
            this.form.passport_nationality = '';
        },
        generalInfoData() {
            if (this.validate()) {
                console.log('valid');
                if (this.membership.id == 4) {
                    this.form.safe_sport_status = 3;
                    this.form.home_federation = '';
                }
                this.$emit('general-info-data', this.form);
                return;
            } else {
                console.log('un valid');
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        // Custom Validation Rules
        validate() {
            this.errors = {}
            this.valid = false;
            this.overAllError= 1;
            const validSafeSport = validateRadio(this.form.safe_sport_status.toString());
            this.errors.safe_sport_status = validSafeSport.error;
            

            const validSafeSportUserName = validateName(this.form.safe_sport_user_name);
            this.errors.safe_sport_user_name = validSafeSportUserName.error;

            const validSafeSportEmail = validateEmail(this.form.safe_sport_email);
            this.errors.safe_sport_email = validSafeSportEmail.error;

            const validateMembershipBenefits = validateMembershipBenefit(this.form.membership_benefits.toString(), this.ncsaEnable, this.subdomain, '', this.fields);
            this.errors.ncsa = validateMembershipBenefits.error;

            let validFirstChoice = validateSelect(this.form.first_choice);
            if(Array.isArray(this.form.first_choice)){
                if(this.form.first_choice.length == 0 || this.form.first_choice ==""){
                    validFirstChoice.valid = false;
                    validFirstChoice.error = "This field is required";
                    this.errors.first_choice = validFirstChoice.error;                   
                } else {
                    validFirstChoice.valid = true;
                    validFirstChoice.error = "";
                    this.errors.first_choice = validFirstChoice.error;                   
                }
            } else {
                this.errors.first_choice = validFirstChoice.error;
            }

            const validSafeSportForeignFederationId = validateFedration(this.form.skier_license);
            this.errors.skier_license = validSafeSportForeignFederationId.error;           
            
            
            

            const validFederation = validateSelect(this.form.home_federation);
            this.errors.home_federation = validFederation.error;            
            
            if(document.getElementById("home_federation") == null) validFederation.valid = true;

            if (this.membership.id == 4) {
                validSafeSport.valid = true;
                this.socialSupport = true;
                validFederation.valid = true;
            }
          console.log("Membership Benefits Valid",this.form.membership_benefits.toString());
          if (this.form.membership_benefits.toString()) {
            validateMembershipBenefits.valid = true;
          }
            if(document.querySelector("[name='form.safe_sport_status']") != null){
                if (validSafeSport.valid && validFirstChoice.valid && validFederation.valid) {
                    this.valid = true;
                    this.overAllError = 0;
                    if (this.socialSupport == false && (this.safeSports == 1 && (!validSafeSportUserName.valid || !validSafeSportEmail.valid))) {
                        this.valid = false;
                    } else if (this.safeSports != 1) {
                        this.form.safe_sport_user_name = '';
                        this.form.safe_sport_email = '';
                        this.valid = true;                       
                        this.overAllError = 0;
                    } else {
                        this.valid = true;                        
                        this.overAllError = 0;
                    }

                    if (this.socialSupport == false && this.form.home_federation != 'USA' && !validSafeSportForeignFederationId.valid) {
                        this.valid = false;
                        this.overAllError = 1;
                    } else if (this.form.home_federation == 'USA') {
                        this.form.skier_license = '';                        
                        this.valid = true;
                        this.overAllError = 0;                        
                    } else {
                        this.valid = true;                        
                        this.overAllError = 0;
                    }
                }
            } else if(validFirstChoice.valid || validateMembershipBenefits.valid) {
                this.valid = true;                
                this.overAllError = 0;
                this.form.safe_sport_status = 0;
            }

            const validPassportNumber = validateName(this.form.passport_number);
            this.errors.passport_number = validPassportNumber.error;

            const validPassportIssueDate = validateName(this.form.passport_issue_date);
            this.errors.passport_issue_date = validPassportIssueDate.error;

            const validPassportExpiryDate = validateName(this.form.passport_expiry_date);
            this.errors.passport_expiry_date = validPassportExpiryDate.error;

            const validNationality = validateSelect(this.form.passport_nationality);
            this.errors.passport_nationality = validNationality.error;


            var flag = "";
            if(this.form.passport_info == "1" && document.getElementById('passport_info') != null){
                if(!validPassportNumber.valid || !validPassportIssueDate.valid || !validPassportExpiryDate.valid || !validNationality.valid || !validateMembershipBenefits.valid){
                    // console.log("==========>",flag);
                    flag = "stop";
                }
            }

            if(flag) this.valid = false;

            return this.valid;
        },
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },
      resetFields(){
          this.errors = {};
          this.form={
            membership_benefits:''
          }
          this.overAllError = 0;
      }
    },
    updated() {
        // this.disciplines_list = this.disciplines.m
        let discipline_codes = Object.keys(this.disciplines);
        if(discipline_codes.length && this.disciplines_list.length == 0){
            discipline_codes.forEach(code => {
                this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
            });
        }
    },
  mounted() {
    if(this.isValidateMemberType){
      this.resetFields();
    }
  },
  computed: {
    ncsaEnable: function(){
      return this.getSiteProps("general.ncsa_enable");
    },
  }
}
</script>
<style scoped>
.inl-item{
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.95rem;
  vertical-align: middle;;
}
h4.subtitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.inl-item label {
  margin-bottom: 0;
}
.imgwithinline img {
  width: 100% !important;
  float: left;
  max-width: 163px;
  height: 100px;
  margin-right: 19px;
}
.imgwithinline span p {
  text-align: justify;
}
.ncsa-label div:first-child {
  display: flex;
  align-items: baseline;
}
.ncsa-title{
  margin-bottom: 15px;
}
.disclaimer{
  font-size: 13px !important;
}
</style>
