<template>
    <form @submit.prevent="contactData" method="post">
<div class=" Contact-info">
   <div class="card-header register-title">       
   <h3>{{ fields.tab_title || 'Contact Information' }} {{isChildUsers?'(Parent / Guardian)':''}}</h3>
   </div>
   <div class="card-body pr-4 pl-4">
      <div class="row">
          <div :class="[fields.firstname.colClass]" v-if="isChildUsers && fields.firstname">
            <div class="form-group">
              <label for="firstName" class="form-label">
                {{ fields.firstname.label }}<sup class="text-danger" v-if="fields.firstname.required">*</sup>
              </label>
              <input type="text" class="form-control" :placeholder="fields.firstname.placeholder" maxlength="45" id="firstName" v-model="form.first_name" name="form.first_name" value="">
              <span class="text-sm text-danger">{{ errors.first_name}}</span>
<!--              <span class="text-sm text-danger" v-if="duplicateError.hasError">{{ duplicateError.errorMessage}}</span>-->
            </div>
          </div>
          <div :class="[fields.middlename.colClass]" v-if="isChildUsers && fields.middlename">
            <div class="form-group">
              <label for="middlename" class="form-label">
                {{ fields.middlename.label }}<sup class="text-danger" v-if="fields.middlename.required">*</sup>
              </label>
              <input type="text" class="form-control" :placeholder="fields.middlename.placeholder" maxlength="3" id="middlename" v-model="form.middle_name" name="form.middle_name" value="">
            </div>
          </div>
          <div :class="[fields.lastname.colClass]" v-if="isChildUsers && fields.lastname">
            <div class="form-group">
              <label for="lastname" class="form-label">{{ fields.lastname.label }}<sup class="text-danger" v-if="fields.lastname.required">*</sup></label>
              <input type="text" class="form-control" :placeholder="fields.lastname.placeholder" maxlength="45" id="lastname" v-model="form.last_name" name="form.last_name" value="">
              <span class="text-sm text-danger">{{ errors.last_name}}</span>
            </div>
          </div>
         <div :class="[fields.email.colClass]" v-if="fields.email">
            <div class="form-group">
               <label for="email" class="form-label">{{ fields.email.label }} <sup class="text-danger" v-if="fields.email.required">*</sup></label>
               <input type="text" class="form-control" maxlength="45" id="email" v-model="form.email" name="form.email" :disabled="disableEmail">
                <span class="text-sm text-danger">{{ errors.email}}</span>
               </div>
         </div>
         <div :class="[fields.retype_email.colClass]" v-if="fields.retype_email">
             <div class="form-group">
                 <label for="retype_email" class="form-label">{{ fields.retype_email.label }} <sup class="text-danger" v-if="fields.retype_email.required">*</sup></label>
                 <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="form.retype_email" name="form.retype_email" :disabled="disableEmail">
                 <span class="text-sm text-danger">{{ errors.retype_email}}</span>
             </div>
         </div>
         <div :class="[fields.acceptemail.colClass]" v-if="fields.acceptemail">
               <div class="form-group grossrootcheck">
                  <div class="form-check">
                     <input type="checkbox" class="form-check-input" id="acceptemail" v-model="form.printed_magazine" value="1" name="form.printed_magazine">
                     <label class="form-check-label font-13" for="acceptemail">{{ fields.acceptemail.label }}</label>
                  </div>
               </div>
         </div>
         <div :class="[fields.address_type.colClass]" v-if="fields.address_type">
             <div class="form-group">
                 <label for="address_type" class="form-label">{{ fields.address_type.label }}<sup class="text-danger" v-if="fields.address_type.required">*</sup></label>
                 <select name="form.address_type" id="address_type" class="form-control" v-model="form.address_type" @change="changeAddressType">
                     <option v-for="(item, k) in fields.address_type.options" :key="'address-type-'+k" :value="item.value">{{ item.label }}</option>
                 </select>
               <span class="text-sm text-danger">{{ errors.address_type}}</span>
             </div>
         </div>

        <div :class="[fields.address1.colClass]" v-if="fields.address1">
            <div class="form-group">
            <label for="firstname" class="form-label">{{ fields.address1.label }} <sup class="text-danger" v-if="fields.address1.required">*</sup><span  v-if="fields.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ fields.address1.charLimitText }}</span></label>
            <input type="text" class="form-control" :maxlength=fields.address1.charLimitValue id="address1" v-model="form.address1" name="form.address1">
                <span class="text-sm text-danger">{{ errors.address1}}</span>
            </div>
        </div>
        <div :class="[fields.address2.colClass]" v-if="fields.address2">
            <div class="form-group">
            <label for="firstname" class="form-label">{{ fields.address2.label }} <span v-if="fields.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ fields.address2.charLimitText }}</span></label>
            <input type="text" class="form-control" :maxlength=fields.address2.charLimitValue id="address2" v-model="form.address2" name="form.address2">
                <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
            </div>
        </div>

      </div>

        <div class="row">
            <div :class="[fields.country.colClass]" v-if="!listOfCountry && fields.country">
                <div class="form-group">
                <label for="Country" class="form-label">{{ fields.country.label }} <sup class="text-danger" v-if="fields.country.required">*</sup></label>
                <select class="form-control" id="country"  @change="removeStateCode" v-model="form.country" name="form.country">
                    <option value="">Select</option>
                    <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                    <span class="text-sm text-danger">{{ errors.country}}</span>
                </div>
            </div>
            <div :class="[fields.country.colClass]" v-if="listOfCountry && fields.country && fields.country.options">
              <div class="form-group">
                <label for="Country" class="form-label">{{ fields.country.label }} <sup class="text-danger" v-if="fields.country.required">*</sup></label>
                <select class="form-control" id="country-1" @change="removeStateCode"  v-model="form.country" name="form.country">
                  <option value="">Select</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in fields.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.country}}</span>
              </div>
            </div>
            <div :class="[fields.city.colClass]" v-if="fields.city">
                <div class="form-group">
                    <label for="city" class="form-label">{{ fields.city.label }} <sup class="text-danger" v-if="fields.city.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" id="city"  v-model="form.city" name="form.city">
                    <span class="text-sm text-danger">{{ errors.city}}</span>
                </div>
            </div>
            <div :class="[fields.state_code.colClass]" v-if="(form.country == 'USA' || form.country == '') && fields.state_code">
                <div class="form-group">
                <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
                <select class="form-control" id="state_code" v-model="form.state_code" name="form.state_code" >
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </select>
                    <span class="text-sm text-danger">{{ errors.state_code}}</span>
                </div>
            </div>
            <div :class="[fields.state_code.colClass]" v-else-if="listOfCountry && form.country == 'Canada' && fields.state_code">
              <div class="form-group">
                <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
                <select class="form-control" id="state_code" v-model="form.state_code" name="form.state_code" >
                  <option value="">Select</option>
                  <option  v-for="listOfState in fields.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.state_code}}</span>
              </div>
            </div>
            <div :class="[fields.state_code.colClass]" v-else-if="form.country == 'Canada' && fields.state_code">
              <div class="form-group">
                <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
                <select class="form-control" id="state_code" v-model="form.state" name="form.state" >
                  <option value="">Select</option>
                  <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.state}}</span>
              </div>
            </div>
            <div :class="[fields.other_state.colClass]" v-else>
                <div class="form-group" v-if="fields.other_state">
                    <label for="state_code" class="form-label">{{ fields.other_state.label }} </label>
                    <input type="text" class="form-control" maxlength="45" v-model="form.state" name="form.state">
                    <span class="text-sm text-danger">{{ errors.state}}</span>
                </div>
            </div>

            <div :class="[fields.zip.colClass]" v-if="fields.zip">
            <div class="form-group">
                <label for="zip" class="form-label">{{ fields.zip.label }} <sup class="text-danger" v-if="fields.zip.required">*</sup></label>
                <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')"  id="zip" v-model="form.zip" name="form.zip">
                    <span class="text-sm text-danger">{{ errors.zip}}</span>
                </div>
            </div>
            <div :class="[fields.citizenship.colClass]" v-if="listOfCountry && fields.citizenship">
              <div class="form-group">
                <label for="citizenship" class="form-label">{{ fields.citizenship.label }} <sup class="text-danger" v-if="fields.citizenship.required">*</sup></label>
                <select class="form-control" id="citizenship" v-model="form.citizenship" name="form.country">
                  <option value="">Select</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.citizenship}}</span>
              </div>
            </div>
            <div :class="[fields.parent_citizenship.colClass]" v-if="isChild && fields.parent_citizenship">
              <div class="form-group">
                <label for="parent_citizenship" class="form-label">{{ fields.parent_citizenship.label }} <sup class="text-danger" v-if="fields.parent_citizenship.required">*</sup></label>
                <div class="form-group" v-if="fields.parent_citizenship.choices">
                  <template v-for="(choice, key) in fields.parent_citizenship.choices">
                    <input type="radio" :id="choice.value" v-model="form.is_parent_citizenship" name="form.parent_citizenship" :value="choice.value" @click="parentCitizenship = choice.value" :key="'radio_' + key">
                    <label class="parent_citizenship-label-span mr-3" :for="choice.value" :key="'parent_citizenship_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.is_parent_citizenship}}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.parent_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && fields.parent_citizenship && fields.parent_citizenship.yes_fields.citizenship && parentCitizenship == 1">
              <div class="form-group">
                <label for="citizenship" class="form-label" v-if="fields.parent_citizenship.yes_fields.citizenship.label !=''">{{ fields.parent_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.parent_citizenship.yes_fields.citizenship.required">*</sup></label>
                <select class="form-control" id="parent_citizenship" v-model="form.parent_citizenship" name="form.parent_citizenship">
                  <option value="">Select citizenship</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.parent_citizenship}}</span>
              </div>
            </div>
            <div :class="[fields.athlete_citizenship.colClass]" v-if="isChild && fields.athlete_citizenship">
              <div class="form-group">
                <label for="athlete_citizenship" class="form-label">{{ fields.athlete_citizenship.label }} <sup class="text-danger" v-if="fields.athlete_citizenship.required">*</sup></label>
                <div class="form-group" v-if="fields.athlete_citizenship.choices">
                  <template v-for="(choice, key) in fields.athlete_citizenship.choices">
                    <input type="radio" :id="choice.value" v-model="form.is_athlete_citizenship" name="form.athlete_citizenship" :value="choice.value" @click="athleteCitizenship = choice.value" :key="'radio_' + key">
                    <label class="athlete_citizenship-label-span mr-3" :for="choice.value" :key="'athlete_citizenship_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.is_athlete_citizenship}}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.athlete_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && fields.athlete_citizenship && fields.athlete_citizenship.yes_fields.citizenship && athleteCitizenship == 1">
              <div class="form-group">
                <label for="citizenship" class="form-label" v-if="fields.athlete_citizenship.yes_fields.citizenship.label !=''">{{ fields.athlete_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.athlete_citizenship.yes_fields.citizenship.required">*</sup></label>
                <select class="form-control" id="athlete_citizenship" v-model="form.athlete_citizenship" name="form.athlete_citizenship">
                  <option value="">Select citizenship</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.athlete_citizenship}}</span>
              </div>
            </div>
            <div :class="[fields.eligible_citizenship.colClass]" v-if="isChild && fields.eligible_citizenship" v-show="parentCitizenship == 2 || athleteCitizenship == 2">
              <div class="form-group">
                <label for="eligible_citizenship" class="form-label">{{ fields.eligible_citizenship.label }} <sup class="text-danger" v-if="fields.eligible_citizenship.required">*</sup></label>
                <div class="form-group" v-if="fields.eligible_citizenship.choices">
                  <template v-for="(choice, key) in fields.eligible_citizenship.choices">
                    <input type="radio" :id="choice.value" v-model="form.is_eligible_citizenship" name="form.eligible_citizenship" :value="choice.value" @click="eligibleCitizenship = choice.value" :key="'radio_' + key">
                    <label class="eligible_citizenship-label-span mr-3" :for="choice.value" :key="'eligible_citizenship_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.is_eligible_citizenship}}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.eligible_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && fields.eligible_citizenship && fields.eligible_citizenship.yes_fields.citizenship && eligibleCitizenship == 1 && (parentCitizenship == 2 || athleteCitizenship == 2)">
              <div class="form-group">
                <label for="citizenship" class="form-label" v-if="fields.eligible_citizenship.yes_fields.citizenship.label !=''">{{ fields.eligible_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="fields.eligible_citizenship.yes_fields.citizenship.required">*</sup></label>
                <select class="form-control" id="eligible_citizenship" v-model="form.eligible_citizenship" name="form.eligible_citizenship">
                  <option value="">Select citizenship</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.eligible_citizenship}}</span>
              </div>
            </div>
        </div>

      <template v-if="form.address_type == 'Work'">
        <div class="row">
            <div :class="[fields.work_address_company_name.colClass]" v-if="fields.work_address_company_name">
                <div class="form-group">
                    <label for="work_address_company_name" class="form-label">{{ fields.work_address_company_name.label }}<sup class="text-danger" v-if="fields.work_address_company_name.required">*</sup></label>
                    <input type="text" class="form-control" id="work_address_company_name" v-model="form.company_name">
                    <span class="text-sm text-danger">{{ errors.company_name }}</span>
                </div>
            </div>
            <div :class="[fields.work_address_company_phone_extension.colClass]" v-if="fields.work_address_company_phone_extension">
                <div class="form-group">
                    <label for="work_address_company_phone" class="form-label">{{ fields.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="fields.work_address_company_phone_extension.required">*</sup></label>
                    <div class="row">
                        <div class="col-md-9">
                            <vue-tel-input ref="phone" @validate="validateTelinput" v-model="form.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                            <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" v-model="form.company_phone_extension" name="form.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                            <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </template>
      <div class="row">
         <div :class="[fields.homephone.colClass]" v-if="fields.homephone">
            <div class="form-group ">
               <div class="phone-nu-wrapper">
               <label for="country_code_h" class="form-label">{{ fields.homephone.label }} <sup class="text-danger" v-if="fields.homephone.required">*</sup></label>
               <vue-tel-input v-model="form.phone_1" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
              <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
               <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                   <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
               </div>
            </div>
         </div>
         <div :class="[fields.mobilephone.colClass]" v-if="fields.mobilephone">
            <div class="form-group">
            <div class="phone-nu-wrapper">
               <label for="phone_2" class="form-label">{{ fields.mobilephone.label }}<sup class="text-danger" v-if="fields.mobilephone.required && subdomain == 'alliancefastpitch'">*</sup></label>
               <vue-tel-input v-model="form.phone_2" ref="phone" @validate="validateTelinput2" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
               <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
               <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
            </div>
            </div>
         </div>
      </div>
   </div>
      <div class="col-md-12"  v-show="overAllError != 0">
         <p class="text-right text-danger">Please fill the above missing fields.</p>
      </div>
    <div class="col-md-12">
        <div class="text-right topspace">
            <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
            <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
        </div>
    </div>
</div>
</form>
</template>

<script>

// import {TheMask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';

    const validateName = (name, contactField='' ) => {
    let contactText = ( contactField != '' ? contactField : "This field");
    if (!name || !name.length) {
      return { valid: false, error: contactText+ " is required " };
    }
    if (!name.match(/^[a-zA-Z\s]*$/))
    {
      return { valid: false, error:  contactText+ " must be letters only. No other characters" };
    }
    return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!name || !names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (!name || !name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};
const validatePhone2 = (phone, validTelinput2) => {
  if(validTelinput2 == false && phone != null){
    return { valid: false, error: 'Enter the Exact phone number.(Example: +XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}
const validateZip = (zipCode, country) => {
    if(!zipCode || !zipCode.length)
    {
         return { valid: false, error: "Zip code is required" };
    }
    else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
        return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
    }
    else if(zipCode.length < 5 ){
         return { valid: false, error: "Zip code should be 5 character" };
    }
      return { valid: true, error: null };
}

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
    if (!phone || !phone.length) {
    return { valid: false, error: phoneText+' is required .' };
}


if(validTelinput == false && phone.length){
  return { valid: false, error: 'Enter the Exact phone number(Example: +XX XX XXXX XXXX).' };
}

    return { valid: true, error: null };
}

    const validateDob = dob => {
    if (!dob.length) {
    return { valid: false, error: "This field is required" };
}
    /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please, enter a valid data of birth." };
    }*/
    return { valid: true, error: null };
};
    const validateGender = gender => {
    if (!gender) {
    return { valid: false, error: "This field is required" };
}
    return { valid: true, error: null };
};
    const validateSelect = (select, fieldState='') => {
    let textState= ( fieldState != '' ? fieldState : "This field");
    if (!select || !select.length || select =='') {
    return { valid: false, error: textState+" is required" };
}
    return { valid: true, error: null };
};
    const validateEmail = (email, fieldEmail='') => {
    let emailText= (fieldEmail != '' ? fieldEmail : "This field");
    if (!email || !email.length) {
      return { valid: false, error: emailText+" is required" };
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      return { valid: false, error: "Please enter a valid email." };
    }
    return { valid: true, error: null };
};
    const validatePassword = password => {
    if (!password.length) {
    return { valid: false, error: "This field is required" };
}
    if (password.length < 7) {
    return { valid: false, error: "Password is too short" };
}
    return { valid: true, error: null };
};

const validateRadio = (radio, fieldName='') => {
  let fieldText= (fieldName != '' ? fieldName : "This field");
  if (!radio) {
    return {valid: false, error: fieldText+" is required"};
  }
  return {valid: true, error: null};
};

export default {
    name: "contactinfo",
    props: ['fields','listOfCountry', 'age', 'isChildUsers', 'isValidateMemberType','invitedEmail','updatedVerifiedDetails', 'isChild','isAlliance','memberContactLookup'],
    components: {VueTelInput},
    data() {
        return {
          validTelinput:false,
          phone: "0123456789",
          homeProps: {
            mode: "international",
            defaultCountry: "US",
            inputOptions:{
              autocomplete:"on",
              autofocus:false,
              id:"",
              maxlength:20,
              name:"telephone",
              placeholder:"Home Number",
              readonly:false,
              required:false,
              showDialCode:false,
              styleClasses:"",
              tabindex:0,
              type:"tel"
            }

          },
          mobileProps: {
            mode: "international",
            defaultCountry: "US",
            inputOptions:{
              autocomplete:"on",
              autofocus:false,
              id:"",
              maxlength:20,
              name:"telephone",
              placeholder:"Mobile Number",
              readonly:false,
              required:false,
              showDialCode:false,
              styleClasses:"",
              tabindex:0,
              type:"tel"
            }
          },
          companyPhoneExtProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Company Phone Extension",
            // maxLen: 30,
          },

         homephonemax: 10,
        //  mobilemax: 10,
            form: {
                "email": '',
                "retype_email": '',
                "printed_magazine" : '',
                'address_type': 'Home',
                "address1": '',
                "address": '',
                "address2": '',
                "zip": '',
                "state_code": '',
                "state": '',
                "city": '',
                "country": 'USA',
                "phone_1": '',
                "phone_2": '',
                'company_name': '',
                'company_phone': '',
                'company_phone_extension': '',
                'first_name':'',
                'last_name':'',
                'middle_name':'',
                'citizenship':'',
                'parent_citizenship':'',
                'is_parent_citizenship':'',
                'athlete_citizenship':'',
                'is_athlete_citizenship':'',
                'eligible_citizenship':'',
                'is_eligible_citizenship':''
            },
            valid: false,
            success: false,
            validTelinput2:false,
            errors: {},
            message: null,
            overAllError: 0,
            disableEmail:false,
            parentCitizenship:0,
            athleteCitizenship:0,
            eligibleCitizenship:0
      }
    },
    methods: {
        contactData() {
            if(this.validate()) {
                if(this.isChild){
                  if(this.parentCitizenship==2)
                    this.form.parent_citizenship ='';

                  if(this.athleteCitizenship==2)
                    this.form.athlete_citizenship ='';

                  if(this.eligibleCitizenship==2)
                    this.form.eligible_citizenship ='';
                }
                console.log('valid');
                console.log(this.form);
                this.$emit('contact-data', this.form);
                return;
            } else {
                console.log('invalid');
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        // Custom Validation Rules
        validate() {
            this.errors = {}
            this.valid = false;
            this.overAllError= 1 ;
            const validEmail = validateEmail(this.form.email,"Email");
            this.errors.email = validEmail.error;

            const validRetypeEmail = validateEmail(this.form.retype_email, "Email");
            this.errors.retype_email = validRetypeEmail.error;

            var validAddress1 = "", validCountry = "", validStateCode = "", validCity = "", validState = "", validZip = "";

            var validCompanyName = "", validCompanyPhone = "", validCompanyPhoneExtension = "";
            var validFirstName = {valid:true}, validLastName = {valid:true}, validCheckParentCitizenship = {valid:true}, validCheckAthleteCitizenship = {valid:true}, validCheckEligibleCitizenship = {valid:true};
            var validParentCitizenship = {valid:true}, validAthleteCitizenship = {valid:true}, validEligibleCitizenship = {valid:true};


                validAddress1 = validateAddressName(this.form.address1,"Address");
                this.errors.address1 = validAddress1.error;

                //const validAddress2 = validateName(this.form.address2);
            // this.errors.address2 = validAddress2.error;

                validCountry = validateSelect(this.form.country,"Country");
                this.errors.country = validCountry.error;

                validStateCode = validateSelect(this.form.state_code,"State");
                if(this.subdomain == 'alliancefastpitch' && this.form.country != 'USA' && this.form.country != 'Canada') {
                  validState = validateName(this.form.state);
                }else if(this.subdomain != 'alliancefastpitch' && this.form.country != 'USA') {
                  validState = validateName(this.form.state);
                }else{
                  validStateCode = validateName(this.form.state_code);
                }
                
                if(this.subdomain == 'alliancefastpitch' && this.form.country != 'USA' && this.form.country != 'Canada'){
                  this.errors.state = validState.error;
                } else if(this.subdomain != 'alliancefastpitch' && this.form.country != 'USA'){
                  this.errors.state = validState.error;
                } else{
                    this.errors.state_code = validStateCode.error;
                }
                validCity = validateName(this.form.city,"City");
                this.errors.city = validCity.error;


                // validZip = validateName(this.form.zip,"Postal Code");
                // this.errors.zip = validZip.error;


                validZip = validateZip(this.form.zip,this.form.country);
                this.errors.zip = validZip.error;

            if(this.form.address_type == "Work"){
                validCompanyName = validateName(this.form.company_name, "Company Name");
                this.errors.company_name = validCompanyName.error;

                // validCompanyPhone = validatePhone(this.form.company_phone, this.validTelinput);
                // this.errors.company_phone = validCompanyPhone.error;

                // validCompanyPhoneExtension = validateName(this.form.company_phone_extension, "Extension");
                // this.errors.company_phone_extension = validCompanyPhoneExtension.error;
            }

            const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput);
            this.errors.phone_1 = validPhone1.error;

          const validPhone2 = validatePhone2(this.form.phone_2, this.validTelinput2);
          this.errors.phone_2 = validPhone2.error;

          if(this.isChild && this.isChildUsers){
            validFirstName = validateName(this.form.first_name,"First Name");
            this.errors.first_name = validFirstName.error;
            validLastName = validateLastName(this.form.last_name,"Last Name");
            this.errors.last_name = validLastName.error;
          }
          if(this.isChild){
            validCheckParentCitizenship = validateRadio(this.form.is_parent_citizenship.toString(),"Parent/Guardian");
            this.errors.is_parent_citizenship = validCheckParentCitizenship.error;

            validCheckAthleteCitizenship = validateRadio(this.form.is_athlete_citizenship.toString(),"Child/Athlete");
            this.errors.is_athlete_citizenship = validCheckAthleteCitizenship.error;
            if (this.parentCitizenship==1) {
              validParentCitizenship = validateSelect(this.form.parent_citizenship, "Parent/Guardian");
              this.errors.parent_citizenship = validParentCitizenship.error;
            }
            if (this.athleteCitizenship==1) {
              validAthleteCitizenship = validateSelect(this.form.athlete_citizenship, "Child/Athlete");
              this.errors.athlete_citizenship = validAthleteCitizenship.error;
            }

            if(this.athleteCitizenship == 2 || this.parentCitizenship == 2) {
              validCheckEligibleCitizenship = validateRadio(this.form.is_eligible_citizenship.toString(),"Child/Athlete Eligible");
              this.errors.is_eligible_citizenship = validCheckEligibleCitizenship.error;

              if (this.eligibleCitizenship==1) {
                validEligibleCitizenship = validateSelect(this.form.eligible_citizenship, "Child/Athlete Eligible");
                this.errors.eligible_citizenship = validEligibleCitizenship.error;
              }
            }
          }

            if (!this.isChild &&validEmail.valid && validRetypeEmail.valid && validPhone1.valid && validPhone2.valid && validAddress1.valid  && validCountry.valid && validCity.valid && validZip.valid && (validStateCode.valid || validState.valid)) {
              this.valid = true;

                this.overAllError = 0;
                if(this.form.address_type == "Work"){
                    if(!validCompanyName.valid) this.valid = false;
                }

                // console.log(this.form.address_type, validAddress1.valid, validCountry.valid, validCity.valid, validZip.valid);

                if(this.form.email != this.form.retype_email) {
                    this.errors.retype_email = 'The email id does not match';
                    this.valid = false;
                    this.overAllError = 1;
                }

                if(this.subdomain == 'alliancefastpitch' && (this.form.country == 'USA'|| this.form.country == 'Canada')) {
                    this.form.state = '';
                  if(!validStateCode.valid){
                    this.valid = false;
                    this.overAllError = 1;
                  }
                }else if(this.form.country == 'USA') {
                    this.form.state = '';
                  if(!validStateCode.valid){
                    this.valid = false;
                    this.overAllError = 1;
                  }
                } else if(!validState.valid) {
                  this.form.state_code = '';
                  this.valid = false;
                  this.overAllError = 1;
                }
                
            }
            if (this.isChild && validEmail.valid && validRetypeEmail.valid && validPhone1.valid && validAddress1.valid  && validCountry.valid && validCity.valid && validZip.valid && validFirstName.valid && validLastName.valid &&
                validCheckParentCitizenship.valid && validCheckAthleteCitizenship.valid && validCheckEligibleCitizenship.valid && validParentCitizenship.valid && validAthleteCitizenship.valid && validEligibleCitizenship.valid && validPhone2.valid) {
              this.valid = true;

                this.overAllError = 0;
                if(this.form.address_type == "Work"){
                    if(!validCompanyName.valid) this.valid = false;
                }

                // console.log(this.form.address_type, validAddress1.valid, validCountry.valid, validCity.valid, validZip.valid);

                if(this.form.email != this.form.retype_email) {
                    this.errors.retype_email = 'The email id does not match';
                    this.valid = false;
                    this.overAllError = 1;
                }


                if(this.subdomain == 'alliancefastpitch' && (this.form.country == 'USA' || this.form.country == 'Canada')) {
                    this.form.state = '';
                    if(!validStateCode.valid) {
                        this.valid = false;
                        this.overAllError = 1;
                    }
                }else if( this.form.country == 'USA') {
                    this.form.state = '';
                    if(!validStateCode.valid) {
                        this.valid = false;
                        this.overAllError = 1;
                    }
                }else if(!validState.valid) {
                    this.form.state_code = '';
                    this.valid = false;
                    this.overAllError = 1;
                }

            }

            return this.valid;           
        },
        phoneFormat() {
            let s = this.$refs.phone.value;
            console.log('this='+s);
            var rgx = /^(\+[\d]{1,5}|0)?[7-9]\d{9}$/;
            this.$refs.phone.value = s.match(rgx);
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateTelinput(obj){            
            this.validTelinput = obj.valid;
            if(obj.valid){
                this.errors.phone_1 = '';
            }
        },
        changeAddressType(){
            this.form.address1 = "";
            this.form.address2 = "";
            this.form.country = "";
            this.form.state_code = "";
            this.form.state = "";
            this.form.city = "";
            this.form.zip = "";
            this.form.company_name = "";
            this.form.company_phone = "";
            this.form.company_phone_extension = "";
        },
        removeStateCode(){
          this.form.state = '';
          this.form.state_code = '';
        },
        resetFields(){
          this.errors = {};
          this.form= {
            "email": '',
            "retype_email": '',
            "printed_magazine" : '',
            'address_type': 'Home',
            "address1": '',
            "address": '',
            "address2": '',
            "zip": '',
            "state_code": '',
            "state": '',
            "city": '',
            "country": 'USA',
            "phone_1": '',
            "phone_2": '',
            'company_name': '',
            'company_phone': '',
            'company_phone_extension': '',
            'first_name':'',
            'last_name':'',
            'middle_name':'',
            'citizenship':''
          }
        },
        validateTelinput2(obj){
          this.validTelinput2 = obj.valid;
          if(obj.valid){
            this.errors.phone_2 = '';
          }
        },
        contactLookupMount(){
          if(this.memberContactLookup != undefined&&Object.keys(this.memberContactLookup).length>0){
            var list = this.memberContactLookup;
            var caStateCheck = this.listOfCAStates.filter(function (elm){
              console.log("data view",elm.state_code , list.state_code);
              if (elm.state_code == list.state_code)
              {
                return elm; // returns length = 1 (object exists in array)
              }
            });
            var country = 'USA';
            if(this.memberContactLookup.country == null && caStateCheck.length > 0){
               country ='Canada';
            }else if(this.memberContactLookup.country == null){
                country= 'USA';
            }
            this.form.email = this.memberContactLookup.email;
            this.form.retype_email = this.memberContactLookup.email;
            this.form.printed_magazine = this.memberContactLookup.printed_magazine;
            this.form.address_type = this.memberContactLookup.address_type;
            this.form.address1 = this.memberContactLookup.address1;
            this.form.address = this.memberContactLookup.address;
            this.form.address2 = this.memberContactLookup.address2;
            this.form.zip = this.memberContactLookup.zip;
            this.form.state_code = this.memberContactLookup.state_code;
            this.form.city = this.memberContactLookup.city;
            this.form.country = (this.memberContactLookup.country == null)?country:this.memberContactLookup.country;
            this.form.phone_1 = this.memberContactLookup.phone_1;
            this.form.phone_2 = this.memberContactLookup.phone_2??'';
            this.form.company_name = this.memberContactLookup.company_name;
            this.form.company_phone = this.memberContactLookup.company_phone;
            this.form.company_phone_extension = this.memberContactLookup.company_phone_extension;
            this.form.first_name = this.memberContactLookup.first_name;
            this.form.last_name = this.memberContactLookup.last_name;
            this.form.middle_name = this.memberContactLookup.middle_name;
            this.form.citizenship = this.memberContactLookup.citizenship;
          }
        },
    },
    mounted() {
      this.contactLookupMount();
    if(this.updatedVerifiedDetails != undefined && this.updatedVerifiedDetails.contact != undefined){
      this.form.email = this.updatedVerifiedDetails.contact.email;
      this.form.retype_email = this.updatedVerifiedDetails.contact.retype_email;
      this.form.printed_magazine = this.updatedVerifiedDetails.contact.printed_magazine;
      this.form.address_type = this.updatedVerifiedDetails.contact.address_type;
      this.form.address1 = this.updatedVerifiedDetails.contact.address1;
      this.form.address = this.updatedVerifiedDetails.contact.address;
      this.form.address2 = this.updatedVerifiedDetails.contact.address2;
      this.form.zip = this.updatedVerifiedDetails.contact.zip;
      this.form.state_code = this.updatedVerifiedDetails.contact.state_code;
      this.form.city = this.updatedVerifiedDetails.contact.city;
      this.form.country = this.updatedVerifiedDetails.contact.country;
      this.form.phone_1 = this.updatedVerifiedDetails.contact.phone_1;
      this.form.phone_2 = this.updatedVerifiedDetails.contact.phone_2;
      this.form.company_name = this.updatedVerifiedDetails.contact.company_name;
      this.form.company_phone = this.updatedVerifiedDetails.contact.company_phone;
      this.form.company_phone_extension = this.updatedVerifiedDetails.contact.company_phone_extension;
      this.form.first_name = this.updatedVerifiedDetails.contact.first_name;
      this.form.last_name = this.updatedVerifiedDetails.contact.last_name;
      this.form.middle_name = this.updatedVerifiedDetails.contact.middle_name;
      this.form.citizenship = this.updatedVerifiedDetails.contact.citizenship;
    }
    if(this.isValidateMemberType){
      this.resetFields();
    }
  },
    updated() {
      if(this.invitedEmail){   
        this.form.email = this.invitedEmail;
        this.form.retype_email = this.invitedEmail;
        this.disableEmail=true;
      }
    }
}
</script>
