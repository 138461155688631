<template>
    <div class="dashboard-wrapper">
        <Sidebar></Sidebar>
        <Navigation></Navigation>
        <!-- Content Header (Page header) -->
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark">Member Group</h1>
                        </div>
                        <div class="col-md-6 text-right mt-3">
                            <h3><b-button class="btn-primary btn-darkblue btn btnaddgroup" v-b-modal.add-role>Add Group</b-button></h3>
                        </div>
                        <!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable pl-4 pr-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <b-form inline>
                                            <label class="mr-sm-2 font-weight-normal">Show</label>
                                            <b-form-select
                                            id="inline-form-custom-select-pref"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            v-model="perPage"
                                            :options="viewEntryOptions"
                                            size="sm"
                                            ></b-form-select>entries
                                            </b-form>
                                        </div>
                                         <div class="col-md-3 text-right">
                                            <b-input-group size="sm">
                                            <b-form-input
                                            id="filter-input"
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to search..."
                                            ></b-form-input>

                                            <!-- <b-input-group-append>
                                            <b-button variant="primary">Search</b-button>
                                            </b-input-group-append> -->
                                            </b-input-group>
                                         </div>
                                    </div>
                                  <b-table

                                  id="my-table"
                                  :per-page="perPage"
                                  :current-page="currentPage"
                                  :items="items"
                                  :fields="primarycontactfields"
                                  :busy="isBusy"
                                  class="mt-3"
                                  :filter="filter"
                                  :filter-included-fields="filterOn"
                                  @filtered="onFiltered"
                                  hover
                                  show-empty
                                  >
                                  <template
                                  :fields="items"
                                  >
                                      <div class="text-left py-0 align-middle">
                                          <div class="btn-group btn-group-sm">
                                          <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                          <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i></button>
                                          <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                          </div>
                                      </div>
                                  </template>
                                  <template #empty="scope">
                                      <h4>{{ scope.emptyText }}</h4>
                                  </template>
                                  <template #cell(actions)="row">
                                      <div class="text-left py-0 align-middle">
                                          <div class="btn-group btn-group-sm">
                                          <!--<button class="btn btn-info" @click="viewRow(row.item, row.index, $event.target)"><i class="fas fa-eye"></i></button>-->
                                          <!--<button class="btn btn-primary ml-1" @click="editRow(row.item.id)"><i class="fas fa-pencil-alt"></i></button>-->
                                          <b-button v-b-modal.modal-edit class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i></b-button>
                                          <button class="btn btn-danger ml-1"  @click="deleteRow(row.item.id)"><i class="fas fa-trash"></i></button>
                                          </div>
                                      </div>
                                  </template>
                                  <!--<template #table-busy>
                                      <div class="text-center text-danger my-2">
                                      <b-spinner class="align-middle"></b-spinner>
                                      <strong>Loading...</strong>
                                      </div>
                                  </template>-->
                                  </b-table>

                                  <b-modal id="add-role" size="lg" ok-only title="Membership Role">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="form-label">Type</label>
                                        <select class="form-control">
                                          <option>Select</option>
                                          <option>Manager/Volunteer</option>
                                          <option>Official/Referee</option>
                                          <option>Player/Coach</option>
                                          <option>Player/Coach</option>
                                        </select>
                                      </div>
                                        <div class="form-group">
                                            <label class="form-label">Description</label>
                                           <textarea class="form-control"></textarea>
                                        </div>
                                    </div>
                                  </div>
                                  </b-modal>

                                <b-modal id="modal-edit" size="lg" ok-only title="Edit User">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="form-label">Member Id</label>
                                        <input type="text" class="form-control">
                                      </div>
                                      <div class="form-group">
                                        <label class="form-label">Type</label>
                                        <select class="form-control">
                                          <option>Select</option>
                                          <option>Manager/Volunteer</option>
                                          <option>Official/Referee</option>
                                          <option>Player/Coach</option>
                                          <option>Player/Coach</option>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                      <label class="form-label">Description</label>
                                      <textarea class="form-control"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </b-modal>
                            </div>
                            </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
        <Footer></Footer>
    </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Sidebar from "./components/Sidebar";
import Footer from "../Member/Dashboard/components/Footer";

  export default {
      name: "memberGroups",
      components: { Sidebar, Navigation, Footer },
    data() {
      return {
          infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
            isBusy: false,
          primarycontactfields: [
           {
            key: 'committee_code',
            sortable: true
          },
          {
            key: 'committee_description',
            sortable: true
          },

          {
            key: 'InceptDate',
            sortable: true,
          },
          {
            key: 'entered_By',
            sortable: true,
          },
          {
            key: 'datemodified',
            sortable: true,
          },
            {
            key: 'modified_by',
            sortable: true,
          },
          {
            key: 'actions',
            sortable: false,
          },
        ],
        items: [
          {committee_code:'52', committee_description: 'AWSA Board of Directors	',InceptDate:'11/24/1998', entered_By :'Admin', datemodified:'11/24/1998  3:40:57 PM', modified_by: 'Admin'},
           { committee_code:'53', committee_description: 'AWSA Awards', InceptDate:'11/24/1998', entered_By :'Admin', datemodified:'11/24/1998  3:40:57 PM', modified_by: 'Admin'},
            { committee_code:'54', committee_description: 'AWSA Boat Drivers', InceptDate:'11/24/1998', entered_By :'Admin', datemodified:'11/24/1998  3:40:57 PM', modified_by: 'Admin'},
             { committee_code:'55', committee_description: 'AWSA Bylaws', InceptDate:'11/24/1998', entered_By :'Admin', datemodified:'11/24/1998  3:40:57 PM', modified_by: 'Admin'},

                  ]
        }
     },
     methods: {
      // editRow(id){

      //     console.log('Editing row...' + id);
      //     this.infoModal.title = item.first_name + ' ' + item.last_name;
      //     this.infoModal.content = item;
      //     this.$root.$emit('bv::show::modal', this.infoModal.id, button);
      // },
     }
    }

</script>
