<template>
    <div class="">
        <clubsidebar></clubsidebar>
        <clubnavigation></clubnavigation>
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12 mt-3">
                    <b-card :title="personData.full_name">
                        <b-card-text class="float-left">{{ personData.FirstName }} {{ personData.LastName }}</b-card-text>
                        <b-card-text class="float-left">{{ personData.Email }}</b-card-text>
                        <b-button @click="backToClubRoster" class="float-right">Back to Roster</b-button>
                        <b-overlay :show="showLoader" no-wrap></b-overlay>
                    </b-card>
                </div>
                <div :class="(withCerts?'col-md-6':'col-md-12')+' mt-3 mb-3'">
                    <b-card title="Official Ratings">
                        <b-overlay :show="showLoader" no-wrap></b-overlay>
                        <b-table
                            id="my-rating-table"
                            :items="ratingData"
                            :fields="ratingTblFields"
                            hover
                            show-empty
                            :sort-by.sync="ratingSortBy"
                            :sort-desc.sync="ratingSortDesc"
                        >
                            <template #empty="scope">
                                <h4>{{ scope.emptyText }}</h4>
                            </template>
                            <template #cell(division)="row">
                                {{ row.item.Rating.split(": ")[2] }}
                            </template>
                            <template #cell(rating_type)="row">
                                {{ row.item.Rating.split(": ")[0] }}
                            </template>
                            <template #cell(level)="row">
                                {{ row.item.Rating.split(": ")[1] }}
                            </template>
                            <template #cell(EventAbbreviation)="row">
                                {{ eventName[row.value] }}
                            </template>
                            <template #cell(ExpirationDate)="row">
                                <span v-html="row.value"></span>
                            </template>
                            <template #cell(Active)="row">
                              <span>{{ row.value==1?"No":"Yes" }}  </span>
                              <b-link :href='"/clubroster/ratings_details/"+row.item.PersonID+"/"+row.item.Event_ID+"/"+row.item.RatingType_ID'>Details</b-link>
                            </template>
                        </b-table>
                    </b-card>
                </div>
                <div class="col-md-6 mt-3 mb-3" v-if="withCerts">
                    <b-card title="Certifications">
                      <b-overlay :show="showLoader" no-wrap></b-overlay>
                        <b-table
                            id="my-certs-table"
                            :items="certsData"
                            :fields="certsTblFields"
                            hover
                            show-empty
                            :sort-by.sync="certsSortBy"
                            :sort-desc.sync="certsSortDesc"
                        >
                            <template #empty="scope">
                                <h4>{{ scope.emptyText }}</h4>
                            </template>
                          <template #cell(data)="row">
                            <span v-html="row.value"></span>
                          </template>
                        </b-table>
                    </b-card>
                </div>
            </div>
        </div>
        <clubdboardfooter></clubdboardfooter>
    </div>
</template>

<script>
import clubnavigation from "./components/Navigation";
import clubsidebar from "./components/Sidebar";
import clubdboardfooter from "./components/Footer";
import moment from "moment";
import axios from 'axios';

export default {
    name: "ClubRosterRatings",
    components: {clubnavigation, clubsidebar, clubdboardfooter},
    data() {
        return {
            personId: '',
            ratingData:[],
            personData:[],
            ratingTblFields:[
                { key: 'division', sortable: true, label: 'Division' },
                { key: 'rating_type', sortable: true, label: 'Rating' },
                { key: 'level', sortable: true, label: 'Level' },
                { key: 'EventAbbreviation', sortable: true, label: 'Event' },
                { key: 'Effective_Date', sortable: true, label: 'Effective Date', formatter: "usdate" },
                { key: 'ExpirationDate', sortable: true, label: 'Expiration Date', formatter: "chkDateExp" },
                { key: "Active", sortable: false, label: 'Eligible to renew' },
            ],
            ratingSortBy:'expiration_date',
            ratingSortDesc:'false',
            certsData:[],
            certsTblFields:[
              {key: 'name', sortable: true, label: 'Certification'},
              {key: 'data', sortable: false, label: 'From - To'},
            ],
            certsSortBy:"name",
            certsSortDesc:"false",
            eventName:{
                '':'None',
                'N':'None',
                'S':'Slalom',
                'T':'Trick',
                'J':'Jump'
            },
            showLoader:true,
          withCerts:false,
        }
    },
    methods: {
        loadRostersRatingsInfo(){
            axios.get(this.basePath + 'api/officialratings/'+this.personId, {headers: this.clubHeaders})
                .then(function (response) {
                    if(response.data.status!='error') {
                        const resp = response.data.data;
                        this.ratingData = resp.rating;
                        this.certsData = resp.certs;
                        this.personData = resp.personInfo;
                    }else{
                        this.personData.Email = response.data.message;
                    }
                    this.showLoader=false;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
        chkDateExp(value){
            let expDt = moment(value);
            if(value == null) {
              return "N/A";
            }else if(moment().isAfter(expDt)){
                return '<span class="text-danger">'+expDt.format("MM/DD/YYYY")+'</span>';
            }else{
                return expDt.format("MM/DD/YYYY");
            }
        },
        backToClubRoster(){
          if(this.withCerts) {
            this.$router.push({path:'/club/club-official-roster'})
          }else{
            this.$router.push({path: '/club/clubroaster'});
          }
        },
    },
    mounted() {
        this.personId=this.$route.params.personId;
        this.withCerts=!!this.$route.params.withCerts;
        this.loadRostersRatingsInfo();
    }
}
</script>

<style scoped>

</style>
