<template>
<b-modal id="modal-family-personal" scrollable v-bind:hide-footer="false" @ok="UpdateMemberInfo" :ok-disabled="btn_submit.load" v-bind:ok-title="btn_submit.text" ref="modal-family-member-personal" size="xl">
	<custom-alert v-if="displayAlert==true"  :newDismissSecs="newDismissSecs" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
	<template slot="modal-title">Edit Family Member Personal Informations</template>
	<form ref="form" method="post" @submit.stop.prevent>
		<div class="row">
			<div class="col-xl-5 col-lg-5 col-md-5">
				<b-form-group class="mb-4">
					<label class="form-label">First Name <span class="text-danger">*</span></label>
					<b-form-input v-model="FamilyMember.first_name" name="FamilyMember.first_name" id="FamilyMember.first_name" :class="errors.family.first_name.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.first_name.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.first_name.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-2 col-lg-2 col-md-3">
				<b-form-group class="mb-4">
					<label class="form-label">Middle Initial</label>
					<b-form-input v-model="FamilyMember.middle_name" name="FamilyMember.middle_name" id="FamilyMember.middle_name" maxlength="2"></b-form-input>
				</b-form-group>
			</div>
			<div class="col-xl-5 col-lg-5 col-md-4">
				<b-form-group class="mb-4">
					<label class="form-label">Last Name <span class="text-danger">*</span></label>
					<b-form-input v-model="FamilyMember.last_name" name="FamilyMember.last_name" id="FamilyMember.last_name" :class="errors.family.last_name.valid == false ? 'is-invalid invalid border-danger' : ''"></b-form-input>
					<b-form-invalid-feedback v-if="errors.family.last_name.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.last_name.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-3">
				<b-form-group>
					<label>Relationship <span class="text-danger">*</span></label>
					<b-form-radio-group name="FamilyMember.relation" id="FamilyMember.relation" v-model="FamilyMember.relation"
						:options='relation' :class="errors.family.relation.valid == false ? 'is-invalid invalid' : ''">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.relation.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.relation.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-6 col-lg-5 col-md-6">
				<b-form-group class="mb-4">
					<label>Gender <span class="text-danger">*</span></label>
					<b-form-radio-group name="FamilyMember.gender" id="FamilyMember.gender" v-model="FamilyMember.gender" :class="errors.family.gender.valid == false ? 'is-invalid invalid' : ''"
						:options='genderChoices' value-field="label" text-field="label">
					</b-form-radio-group>
					<b-form-invalid-feedback v-if="errors.family.gender.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.gender.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-3">
				<b-form-group class="mb-4">
					<label class="form-label">Birthday <span class="text-danger">*</span></label>
					<div class="birthdate" :class="{ 'border-danger' : errors.family.dob.valid == false }">
						<div class="birthdaypicker">
							<date-pick
								v-model="FamilyMember.dob"
								:format="'MM/DD/YYYY'"
								:displayFormat="'MM/DD/YYYY'"
								:isDateDisabled="isFutureDate"
								v-mask="'##/##/####'"
								:inputAttributes="{readonly: false}"
                :parseDate="parseDatePick"
							></date-pick>
						</div>
					</div>
					<input name="FamilyMember.dob" id="FamilyMember.dob" class="form-control d-none" :class="errors.family.dob.valid == false ? 'is-invalid invalid' : ''">
					<b-form-invalid-feedback v-if="errors.family.dob.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.dob.message }}</b-form-invalid-feedback>
				</b-form-group>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-4 col-lg-4 col-md-12 pb-4">
				<label class="form-label">Affiliated Clubs/Teams &nbsp;&nbsp;
					<i class="fa fa-exclamation-circle" v-b-tooltip.hover title="First, you have to select your Club/Team and then press and hold the Ctrl key. While holding Ctrl, click each of the other clubs/teams you want to select."></i>
				</label>
				<Select2 v-model="FamilyMember.team_id" :options="clubOptions" :settings="select2MultipleOpt" name="FamilyMember.team_id" id="FamilyMember.team_id" />
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6 pb-4" v-if="subdomain == 'waterski'">
				<label class="form-label">Federation <sup class="text-danger">*</sup></label>
				<b-form-select v-model="FamilyMember.home_federation" name="FamilyMember.home_federation" id="FamilyMember.home_federation" :options="federation" value-field="name" text-field="name"></b-form-select>
				<b-form-invalid-feedback v-if="errors.family.home_federation.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.home_federation.message }}</b-form-invalid-feedback>
			</div>
      <div class="col-xl-4 col-lg-4 col-md-6 pb-4" v-if="FamilyMember.home_federation != 'USA' && subdomain == 'waterski'">
        <div class="mb-4">
          <label class="form-label" for="FamilyMember_skier_license">
            {{ getSiteProps('memberRegistration.family.fields.general.sport_discipline.skier_license.label') }}
            <sup class="text-danger">*</sup>
          </label>
          <input
              type="text"
              v-model="FamilyMember.skier_license"
              maxlength="9"
              id="FamilyMember_skier_license"
              name="FamilyMember_skier_license"
              @keypress="isNumber($event)"
              class="form-control"
              :class="errors.family.skier_license.valid == false ? 'is-invalid invalid border-danger' : ''"
          />
          <div v-if="errors.family.skier_license.valid == false" class="invalid-feedback">
            <i class="fa fa-exclamation-triangle"></i> {{ errors.family.skier_license.message }}
          </div>
          <div class="text-sm text-success" v-html="getSiteProps('memberRegistration.family.fields.general.sport_discipline.skier_license.description')"></div>
        </div>
      </div>
    </div>
		<p class="font-13"><i>If you do not see your club/team listed, contact USA-WSWS HQ at <a href="mailto:memberservices@usawaterski.org" class="text-danger">memberservices@usawaterski.org</a></i></p>
		<div class="row">
			<div class="col-xl-4 col-lg-4 col-md-6 pb-4">
				<label class="form-label">1<sup>st</sup> Choice <span class="text-danger">*</span></label>
				<b-form-select v-model="FamilyMember.first_choice" name="FamilyMember.first_choice" id="FamilyMember.first_choice" :options="disciplines_list" value-field="id" text-field="text" :class="errors.family.first_choice.valid == false ? 'is-invalid invalid' : ''"></b-form-select>
				<b-form-invalid-feedback v-if="errors.family.first_choice.valid == false"><i class="fa fa-exclamation-triangle"></i> {{ errors.family.first_choice.message }}</b-form-invalid-feedback>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-6 pb-4" v-if="subdomain == 'waterski'">
				<label class="form-label">2<sup>nd</sup> Choice</label>
				<b-form-select v-model="FamilyMember.second_choice" name="FamilyMember.second_choice" id="FamilyMember.second_choice" :options="disciplines_list" value-field="id" text-field="text"></b-form-select>
			</div>
		</div>
	</form>
</b-modal>
</template>
<script>
import moment from "moment";
import axios from 'axios';
import Select2 from "v-select2-component";
import { ToastPlugin } from 'bootstrap-vue';
import { VueTelInput } from 'vue-tel-input';
import $ from 'jquery';
import CustomAlert from "../../CustomAlert";

export default {
	name: "familymemberprofile",
	components: {CustomAlert},
	data() {
		return {
			btn_submit:{load:false, text: 'Update' },
			select2MultipleOpt:{ multiple: true, placeholder: 'Click to select one or multiple' },
			genderChoices:[],
			relation:[{ text: "Spouse", value: "1", disabled:true }, { text: "Child", value: "2" }],
			disciplines_list:[],
			federation:[],
			props_membership:null,
			FamilyMember: {
				id:"",
				confirmation_code: "",
				membership_id: 5,
				member_type_id: 3,
				relation: "",
				hoh_member_id: "",
				first_name: "",
				last_name: "",
				middle_name: "",
				dob: "",
				gender: "",
				team_id: new Array(),
				home_federation: "",
				skier_license: "",
				first_choice: "",
				second_choice: "",
			},
			errors: {
				status: true,
				family: {
					relation: { valid:true, message:"" },
					first_name: { valid:true, message:"" },
					last_name: { valid:true, message:"" },
					dob: { valid:true, message:"" },
					gender: { valid:true, message:"" },
					team_id: { valid:true, message:"" },
					home_federation: { valid:true, message:"" },
					skier_license: { valid:true, message:"" },
					first_choice: { valid:true, message:"" },
				}
			}
		}
	},
	methods: {
		resetAlert(){
			this.newDismissSecs = 0;
                this.displayAlert = false;
                this.removeDangerBg();
        },
		setDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-success');
                                $('#alert_box').children('div').addClass('alert-danger');
                    }, 100);
        },
		removeDangerBg(){
            setTimeout(() => {
                                $('#alert_box').children('div').removeClass('alert-danger');
                                $('#alert_box').children('div').addClass('alert-success');
                    }, 100);
        },
		 getAge(birth_date) 
        {
            let age = moment().diff(birth_date, 'years', true);
            age = parseInt(age);
        	return age;
      	},
		buildOptinos() {
			this.genderChoices = this.getSiteProps('memberRegistration.family.fields.personal.gender.choices');
		},
		showEditModal(arg) {
			this.buildOptinos();
			this.relation[0].disabled = (this.$parent.spouseCount > 0 ? true : false);
			for (const [key, value] of Object.entries(this.errors.family)) { this.errors.family[key]['valid'] = true; this.errors.family[key]['message'] = ""; }
			if(Object.keys(arg).length > 0) {
				this.$refs['modal-family-member-personal'].show();
				this.btn_submit.load = false;
				this.btn_submit.text = 'Update';

				if("affiliated_clubs_dropdown" in arg && Object.keys(arg.affiliated_clubs_dropdown).length > 0) {
					this.FamilyMember.team_id = (Object.keys(arg.affiliated_clubs_dropdown));
				}
				this.FamilyMember.id = arg.id;
				this.FamilyMember.confirmation_code = arg.confirmation_code;
				this.FamilyMember.relation = arg.personal_details.relation;
				this.relation[0].disabled = (arg.personal_details.relation == 1 ? false : this.relation[0].disabled);
				this.FamilyMember.hoh_member_id = arg.personal_details.hoh_member_id;
				this.FamilyMember.first_name = arg.personal_details.first_name;
				this.FamilyMember.middle_name = arg.personal_details.middle_name;
				this.FamilyMember.last_name = arg.personal_details.last_name;
				this.FamilyMember.gender = arg.personal_details.gender;
				this.FamilyMember.dob = arg.personal_details.dob;
				this.FamilyMember.home_federation = arg.personal_details.home_federation;
				this.FamilyMember.skier_license = arg.personal_details.skier_license;
				this.FamilyMember.first_choice = arg.personal_details.first_choice;
				this.FamilyMember.second_choice = (arg.personal_details.second_choice == null ? "" : arg.personal_details.second_choice);
			} else {
				this.setDangerBg();
				this.alertMessage = "HOH Member Information not found....";
                this.displayAlert = true;
				this.newDismissSecs = 8;
			}
		},
		isFutureDate(d) {
			let mindate = d < (this.FamilyMember.relation == '' || this.FamilyMember.relation == 2 ? new Date().setFullYear(new Date().getFullYear() - 18) : new Date().setFullYear(new Date().getFullYear() - 150));
            return (d > new Date() || mindate);
        },
		DisciplinesList() {
			axios.get(this.basePath+"api/getDisciplines").then(response => {
				this.disciplines = response.data.data;
				let discipline_codes = Object.keys(this.disciplines);
				if(discipline_codes.length && this.disciplines_list.length == 0) {
					this.disciplines_list.push({ id: "", text: "Select" });
					discipline_codes.forEach(code => {
						this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
					});
				}
			}).catch(err => { });
		},
		getClubs() {
			axios.get(this.basePath + "api/get_teams").then((response) => {
				this.clubOptions = response.data.data;
			}).catch(error=>{
				console.log("error geting clubs list")
			});
		},
		getGroup() {
			axios.get(this.basePath+'api/getGroup').then(response => {
				this.federation = response.data.data;
			}).catch(err => { });
		},
		/*	Start :	Add/Edit Form Validation Rules	*/
		validateRelation() {
			if(this.FamilyMember.relation != 1 && this.FamilyMember.relation != 2) {
				this.errors.status = false;
				this.errors.family.relation.valid = false;
				this.errors.family.relation.message = "Select Relationship";
			} else {
				if(this.FamilyMember.relation == 1){
					// let age = this.getAge(this.FamilyMember.dob);
					
					if(this.getAge(this.FamilyMember.dob) < 18){
						this.errors.family.relation.valid = false;
						this.errors.status = false;
						this.errors.family.relation.message = "spouse must be above 17";
					}else{
						this.errors.family.relation.valid = true;
						this.errors.family.relation.message = "";
					}
				}else{
					// let age = this.getAge(this.FamilyMember.dob);
					if(this.getAge(this.FamilyMember.dob) > 17){
						this.errors.family.relation.valid = false;
						this.errors.status = false;
						this.errors.family.relation.message = "Child must be below 18";
					}else{
						this.errors.family.relation.valid = true;
						this.errors.family.relation.message = "";
					}
				}
			}
		},
		validateFirstName() {
			if(this.FamilyMember.first_name == '') {
				this.errors.status = false;
				this.errors.family.first_name.valid = false;
				this.errors.family.first_name.message = "Enter First Name";
			} else if(!this.FamilyMember.first_name.match(/^[a-zA-Z\s]*$/)){
        this.errors.status = false;
        this.errors.family.first_name.valid = false;
        this.errors.family.first_name.message = "must be letters only. No other characters";
      }
      else {
				this.errors.family.first_name.valid = true;
				this.errors.family.first_name.message = "";
			}
		},
		validateLastName() {
			if(this.FamilyMember.last_name == '') {
				this.errors.status = false;
				this.errors.family.last_name.valid = false;
				this.errors.family.last_name.message = "Enter Last Name";
			} else if(!this.FamilyMember.last_name.match(/^[a-zA-Z-\s]*$/)){
        this.errors.status = false;
        this.errors.family.last_name.valid = false;
        this.errors.family.last_name.message = "must be letters and hyphens only. No other characters";
      }
      else {
				this.errors.family.last_name.valid = true;
				this.errors.family.last_name.message = "";
			}
		},
		validateGender() {
			if(this.FamilyMember.gender == '' || !this.genderChoices.some(obj => obj.label === this.FamilyMember.gender)) {
				this.errors.status = false;
				this.errors.family.gender.valid = false;
				this.errors.family.gender.message = "Select Gender";
			} else {
				this.errors.family.gender.valid = true;
				this.errors.family.gender.message = "";
			}
		},
		validateDob() {
			if(this.FamilyMember.dob == '') {
				this.errors.status = false;
				this.errors.family.dob.valid = false;
				this.errors.family.dob.message = "Select Date of Birth";
			} else if(!moment(this.FamilyMember.dob, 'MM/DD/YYYY',true).isValid()) {
				this.errors.status = false;
				this.errors.family.dob.valid = false;
				this.errors.family.dob.message = "Invalid Date format";
			} else {
				this.errors.family.dob.valid = true;
				this.errors.family.dob.message = "";
			}
		},
		validateFirstChoice() {
			if(this.FamilyMember.first_choice == '') {
				this.errors.status = false;
				this.errors.family.first_choice.valid = false;
				this.errors.family.first_choice.message = "Select first choice of sports decipline";
			} else {
				this.errors.family.first_choice.valid = true;
				this.errors.family.first_choice.message = "";
			}
		},
		// validateTeam() {
		// 	if(this.FamilyMember.team_id == '') {
		// 		this.errors.status = false;
		// 		this.errors.family.team_id.valid = false;
		// 		this.errors.family.team_id.message = "Select Team/Club";
		// 	} else {
		// 		this.errors.family.team_id.valid = true;
		// 		this.errors.family.team_id.message = "";
		// 	}
		// },
		validateHomeFedaration() {
			if(this.FamilyMember.home_federation == '' && this.subdomain == 'waterski') {
				this.errors.status = false;
				this.errors.family.home_federation.valid = false;
				this.errors.family.home_federation.message = "Select fedaration";
			} else {
				this.errors.family.home_federation.valid = true;
				this.errors.family.home_federation.message = "";
			}
		},
		validateSkierLicense() {
			if(this.FamilyMember.skier_license == '') {
        this.errors.status = false;
        this.errors.family.skier_license.valid = false;
        this.errors.family.skier_license.message = "Enter Foreign Federation ID";
      } else if(isNaN(this.FamilyMember.skier_license)) {
        this.errors.status = false;
        this.errors.family.skier_license.valid = false;
        this.errors.family.skier_license.message = "Foreign Federation ID must be a numeric value";
      } else {
				this.errors.family.skier_license.valid = true;
				this.errors.family.skier_license.message = "";
			}
		},
		/*	End :	Add/Edit Form Validation Rules	*/
		UpdateMemberValidate() {
			this.errors.status = true;
			this.validateRelation(); //Relationship validation
			this.validateFirstName(); //First name validation
			this.validateLastName(); //Last name validation
			this.validateGender(); //Gender validation
			this.validateDob(); //Date of Birth validation
			this.validateFirstChoice(); //Sport Discipline first Choice
			// this.validateTeam(); //Team
			this.validateHomeFedaration() //Home Fedaration

			if(this.FamilyMember.home_federation != 'USA' && this.subdomain == 'waterski') { this.validateSkierLicense(); }
			else { this.FamilyMember.skier_license = ''; }
		},
		UpdateMemberInfo(bvModalEvt){
			bvModalEvt.preventDefault();
			this.UpdateMemberValidate();
			if(this.errors.status == false){
				this.setDangerBg();
				this.alertMessage = "Please fill all mandatory fields";
				this.newDismissSecs = 8;
                this.displayAlert = true;
			} else {
				this.btn_submit.load = true;
				this.btn_submit.text = 'Updating...';
				axios.post(this.basePath+"api/member/family/editpersonal", this.FamilyMember, { headers:this.memberHeaders })
				.then(function (response) {
					if(response.data.status == 'success') {
						this.alertMessage = "Family Member Personal Information updated successfully";
                		this.displayAlert = true;
						this.newDismissSecs=7;
						setTimeout(() => {
                            this.$refs['modal-family-member-personal'].hide();
							this.$emit('memfresh', {loader: true, id: this.FamilyMember.id });
                    	}, 3000);
					} else  {
						alert("Error");
					}
				}.bind(this)).catch(function (error) {
					console.log("Error: "+error);
				});
			}
		},
	},
	mounted() {
		this.DisciplinesList();
		this.getClubs();
		this.getGroup();
	}
}
</script>

<style scoped>
.select2-container {
	z-index:1999;
}
.vue-tel-input.is-invalid{
	border-color:#dc3545 !important;
}
</style>