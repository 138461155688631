<template>
  <div class="">
      <div class="row">
        <div class="col-md-8 mb-3 mt-4">
          <div class="col-md-12 mb-3 clearfix">
            <b-button @click="backToRatings" class="float-right">Back to Ratings</b-button>
          </div>
          <div class="col-md-12">
            <b-card header="Renewal Requirements" header-tag="header" no-body >
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <div class="text-center mt-3 mb-3" style="padding-left:10px">
                <b-button variant="primary">
                  Division <b-badge variant="light">{{ detailsData.DivisionCode }}</b-badge>
                </b-button>
                <b-button variant="primary" class="ml-2">
                  Rating <b-badge variant="light">{{ detailsData.RatingType }}</b-badge>
                </b-button>
                <b-button variant="primary" class="ml-2">
                  Event <b-badge variant="light">{{ detailsData.Event }}</b-badge>
                </b-button>
                <b-button variant="primary" class="ml-2">
                  Level <b-badge variant="light">{{ detailsData.Level }}</b-badge>
                </b-button>
              </div>
              <table class="table" style="border: 1px solid;border-radius: 10px;">
                <thead style="background:black;color:white">
                  <th>Category</th>
                  <th>Met</th>
                  <th>Required</th>
                  <th>Fulfilled</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].CategoryName }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].Met }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].Required }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.ClinicInfobypersonId[0].Fulfilled }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].CategoryName }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].Met }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].Required }}</b>
                    </td>
                    <td>
                      <b>{{ detailsData.EventInfobypersonid[0].Fulfilled }}</b>
                    </td>
                  </tr>
                  <tr v-for="details in detailsData.EventlistpersonId" :key="details.Event_ID">
                    <td >{{ usdate(details.ParticpationDate) }}</td>
                    <td colspan="3">{{ details.SanctionedEventName }}</td>
                  </tr>
                  <tr>
                    <td><b>Certifications</b></td>
                    <td colspan="3"><b>{{ detailsData.CertificationInfopersonid[0].RequirementsStatusMessage }}</b></td>
                  </tr>
                  <tr>
                    <td><p>Membership</p></td>
                    <td colspan="3"><p><b>No</b> as of: {{ splitter(detailsData.MemberInfopersonid[0].MembershipStatusDetails) }}</p></td>
                  </tr>
                  <tr>
                    <td colspan="4"><p>{{ detailsData.MemberInfopersonid[0].MembershipStatusDetails }}</p></td>
                  </tr>
                </tbody>
              </table>
              <!-- <b-table
                  id="my-table"
                  :items="detailsData.EventlistpersonId"
                  :fields="detailsTblFields"
                  hover
                  show-empty
                  :sort-by.sync="detailsSortBy"
                  :sort-desc.sync="detailsSortDesc"
              >
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                </template>
              </b-table> -->
            </b-card>
          </div>
        </div>
        <div class="col-md-4 status_bar mt-4">
          <div class=" right-sidebarlink">
            <pagesidebar :links="this.getSiteProps('memberdashboard.links')"></pagesidebar>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import pagesidebar from "../components/PageSidebar";
import moment from "moment";
import axios from 'axios';

export default {
  name: "MemberRatingDetails",
  components: {pagesidebar},
  mounted() {
    this.personId=this.$route.params.personId;
    this.eventId=this.$route.params.eventId;
    this.ratingTypeId=this.$route.params.ratingTypeId;
    this.loadRatingDetailsInfo();
  },
  data() {
    return {
      personId: '',
      eventId: '',
      ratingTypeId: '',
      detailsData:{},
      detailsTblFields:[
        { key: 'ParticpationDate', sortable: true, label: 'Date', formatter: "usdate" },
        { key: 'SanctionedEventName', sortable: true, label: 'Event' },
      ],
      detailsSortBy:'ParticpationDate',
      detailsSortDesc:'true',
      showLoader:true,
    }
  },
  methods: {
    splitter(value){
      let val = value.split('of');
      return val[1];
    },
    loadRatingDetailsInfo(){
      this.showLoader=true;
      axios.get(this.basePath + 'api/roster/renewal_requirements/'+this.personId+'/'+this.eventId+'/'+this.ratingTypeId, {headers: this.clubHeaders})
          .then(function (response) {
            if(response.data.status!='error') {
              this.detailsData = response.data.data;
            }else{
              this.detailsData = response.data.message;
            }
            this.showLoader=false;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    backToRatings(){
      this.$router.push({ path: '/member/my-officials-ratings-certifications'});
    },
  }
}
</script>

<style scoped>

</style>