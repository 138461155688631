<template>
  <div class="">
    <clubsidebar></clubsidebar>
    <clubnavigation></clubnavigation>
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 mt-3">
        <b-button @click="backToRatings" class="float-right">Back to Ratings</b-button>
        </div>
        <div class="col-md-12 mt-3">
          <b-card header="Renewal Requirements" header-tag="header" no-body >
            <b-overlay :show="showLoader" no-wrap></b-overlay>
            <div class="text-center mt-2 mb-2">
              <b-button variant="primary">
                Division <b-badge variant="light">{{ detailsData[0].DivisionCode }}</b-badge>
              </b-button>
              <b-button variant="primary" class="ml-2">
                Rating <b-badge variant="light">{{ detailsData[0].RatingType }}</b-badge>
              </b-button>
              <b-button variant="primary" class="ml-2">
                Event <b-badge variant="light">{{ detailsData[0].EventTitle }}</b-badge>
              </b-button>
              <b-button variant="primary" class="ml-2">
                Level <b-badge variant="light">-</b-badge>
              </b-button>
            </div>
            <b-table
                id="my-table"
                :items="detailsData"
                :fields="detailsTblFields"
                hover
                show-empty
                :sort-by.sync="detailsSortBy"
                :sort-desc.sync="detailsSortDesc"
            >
              <template #empty="scope">
                <h4>{{ scope.emptyText }}</h4>
              </template>
            </b-table>
          </b-card>
        </div>
      </div>
    </div>
    <clubdboardfooter></clubdboardfooter>
  </div>
</template>

<script>
import clubnavigation from "./components/Navigation";
import clubsidebar from "./components/Sidebar";
import clubdboardfooter from "./components/Footer";
import moment from "moment";
import axios from 'axios';

export default {
  name: "UserRatingDetails",
  components: {clubnavigation, clubsidebar, clubdboardfooter},
  mounted() {
    this.personId=this.$route.params.personId;
    this.eventId=this.$route.params.eventId;
    this.ratingTypeId=this.$route.params.ratingTypeId;
    this.loadRatingDetailsInfo();
  },
  data() {
    return {
      personId: '',
      eventId: '',
      ratingTypeId: '',
      detailsData:{},
      detailsTblFields:[
        { key: 'ParticpationDate', sortable: true, label: 'Date', formatter: "usdate" },
        { key: 'SanctionedEventName', sortable: true, label: 'Event' },
      ],
      detailsSortBy:'ParticpationDate',
      detailsSortDesc:'true',
      showLoader:true,
    }
  },
  methods: {
    loadRatingDetailsInfo(){
      this.showLoader=true;
      axios.get(this.basePath + 'api/roster/ratings_details/'+this.personId+'/'+this.eventId+'/'+this.ratingTypeId, {headers: this.clubHeaders})
          .then(function (response) {
            if(response.data.status!='error') {
              this.detailsData = response.data.data;
            }else{
              this.detailsData = response.data.message;
            }
            this.showLoader=false;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    backToRatings(){
      this.$router.push({ path: '/club/roster/officialratings/'+this.personId});
    },
  }
}
</script>

<style scoped>

</style>