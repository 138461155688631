<template>
        <div class="  ">
               <img :src="banner" alt="login image" class="login-img" v-if="banner">        
        </div>
</template>

<script>
export default {
    data(){
        return {
            banner: ""
        }
    },
    mounted(){
        this.banner = this.getSiteProps('login.banner');
    }
}
</script>


<style scoped>
.left-banner .logo {
    padding-top: 40px;
}
</style>

